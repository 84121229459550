import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import DateInput from '../../components/DateInput';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import EquipamentoController from '../../controllers/Neos/EquipamentoController';
import { buildQueryString, isNumeric, replaceAll, retirarAcentos, updateState } from '../../utils/Functions';
import Button from '../../components/Button';
import { showError } from '../../components/Messages';
import EmpresaView from './EmpresaView';
import ClienteView from '../Empresa/ClienteView';

export default class EquipamentoView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true };
      this.controller = new EquipamentoController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeEquipamento = lang.telaDeCadastroDeEquipamento;
      return (
         <React.Fragment>
            <React.Fragment>
               <Row>
                  <Col sm={3} md={3} lg={3}>
                     <FormGroup>
                        <Label>{lang.codigo}</Label>
                        <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                     </FormGroup>
                  </Col>

                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeEquipamento.numeroDeSerie}</Label>
                        <TextInput defaultValue={this.state.itemSelecionado.numeroDeSerie} />
                     </FormGroup>
                  </Col>

                  <Col>
                     <Label>{telaDeCadastroDeEquipamento.dataDeFabricacao}</Label>
                     <DateInput defaultValue={this.state.itemSelecionado.dataDeFabricacao} />
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeEquipamento.tipoDeEquipamento}</Label>
                        <TextInput defaultValue={this.state.itemSelecionado.tipoDeEquipamento} />
                     </FormGroup>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeEquipamento.modelo}</Label>
                        <TextInput defaultValue={this.state.itemSelecionado.modelo} />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeEquipamento.fabricante}</Label>
                        <TextInput defaultValue={this.state.itemSelecionado.fabricante} />
                     </FormGroup>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeEquipamento.empresa}</Label>
                        <TextInput
                           defaultValue={
                              this.state.itemSelecionado.empresa
                                 ? this.state.itemSelecionado.empresa.nomeFantasia
                                 : null
                           }
                        />
                     </FormGroup>
                  </Col>
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeEquipamento.cliente}</Label>
                        <TextInput
                           defaultValue={
                              this.state.itemSelecionado.cliente
                                 ? this.state.itemSelecionado.cliente.apelidoOuNomeFantasia
                                 : null
                           }
                        />
                     </FormGroup>
                  </Col>
               </Row>
            </React.Fragment>
         </React.Fragment>
      );
   };

   renderizarFiltros(sender) {
      return (
         <Fragment>
            <Row>
               <Col>
                  <Form.Group>
                     <Label>{this.props.lang.telaDeCadastroDeEquipamento.filtro}</Label>
                     <TextInput
                        onChange={(e) => {
                           let filtro = sender.getState().filtro;
                           filtro.texto = e.target.value;
                           if (filtro.texto) {
                              filtro.texto = ('' + retirarAcentos(filtro.texto)).toUpperCase();
                              var charsToRemove = ['@', ',', '.', ';', "'", '\\', '/', '-', '_', '+'];
                              charsToRemove.forEach((c) => {
                                 filtro.texto = replaceAll(filtro.texto, c, '');
                              });
                           }
                           sender.setState({ filtro: filtro });
                        }}
                     />
                  </Form.Group>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeEquipamento.empresa}</Label>
                     <Select
                        name={'empresa'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.empresa = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <EmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/empresa/fast' + buildQueryString(2, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeEquipamento.cliente}</Label>
                     <Select
                        name={'cliente'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.apelidoOuNomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.cliente = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <ClienteView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/cliente/fast' + buildQueryString(2, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <Button onClick={sender.filtrar} text={this.props.lang.pesquisar} style={{ width: 200 }} />
                  <br />
               </Col>
            </Row>
         </Fragment>
      );
   }

   getFiltro = (filtro) => {
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (filtro && filtro.texto) {
         let texto = filtro.texto.toString();
         if (texto[0] === '#') {
            if (!isNumeric(texto.substring(1))) {
               showError(this.props.lang.formularioPadrao.codigoInvalido + ': "' + texto.substring(1) + '"');
               return result;
            }
            result.id = texto.substring(1);
         } else {
            result.searchable = texto;
         }
      }
      if (this.state.empresa) {
         result.empresa = this.state.empresa.id;
      }
      if (this.state.cliente) {
         result.cliente = this.state.cliente.id;
      }
      return result;
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeEquipamento.titulo}
            url={'/consultadeequipamento'}
            ref={(formRef) => (this.formRef = formRef)}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'numeroDeSerie'}
            permissoes={[1391, null, null, null]}
            renderizarFiltros={this.renderizarFiltros}
            getFiltro={this.getFiltro}
            filtroExtra={this.props.filtroExtra}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               tipoDeEquipamento: [],
               cliente: this.props.cliente,
            }}
         />
      );
   }
}
