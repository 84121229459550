import {
   faChartBar,
   faEnvelope,
   faHdd,
   faIdCard,
   faClipboard,
   faCheckSquare,
} from '@fortawesome/free-regular-svg-icons';
import {
   faBars,
   faCogs,
   faDigitalTachograph,
   faDownload,
   faHome,
   faInfoCircle,
   faListAlt,
   faListOl,
   faStore,
   faTools,
   faUser,
   faUsers,
} from '@fortawesome/free-solid-svg-icons';

export class Menus {
   constructor() {
      this.temAcessoARotina = this.temAcessoARotina.bind(this);
   }

   temAcessoARotina(sessionManager, rotina) {
      let result = sessionManager && rotina ? sessionManager.temAcessoARotina(rotina) : false;
      return result;
   }

   getMenusParaAceiteDeTermosDeUso(sessionManager, lang) {
      let result = [
         { key: 10, descricao: lang.menu.inicio, icone: faHome, fontWeight: 500, rota: '/', estaHabilitado: true },
         {
            key: 30,
            descricao: lang.menu.sobre,
            icone: faInfoCircle,
            fontWeight: 500,
            estaHabilitado: true,
            rota: '/about',
         },
      ];
      return result;
   }

   getMenusNeos(sessionManager, lang) {
      let result = [
         { key: 10, descricao: lang.menu.inicio, icone: faHome, fontWeight: 500, rota: '/', estaHabilitado: true },
         {
            key: 20,
            descricao: lang.menu.cadastro,
            icone: faListAlt,
            fontWeight: 500,
            rota: null,
            estaHabilitado: true,
            subMenu: [
               {
                  key: 1011,
                  descricao: lang.menu.perfilDeUsuario,
                  icone: faListAlt,
                  rota: '/1011',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1011),
               },
               {
                  key: 1021,
                  descricao: lang.menu.funcionarios,
                  icone: faIdCard,
                  rota: '/1021',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1021),
               },
               {
                  key: 1151,
                  descricao: lang.menu.analisadorSimulador,
                  icone: faHdd,
                  rota: '/1151',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1151),
               },
               {
                  key: 1101,
                  descricao: lang.menu.empresas,
                  icone: faStore,
                  rota: '/1101',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1101),
               },
               {
                  key: 'sequencia',
                  descricao: lang.menu.sequencias,
                  icone: faListOl,
                  rota: '/sequencia',
                  estaHabilitado: true,
               },
               {
                  key: 1421,
                  descricao: lang.menu.equipamentos,
                  icone: faDigitalTachograph,
                  rota: '/1421',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1421),
               },
            ],
         },
         {
            key: 27,
            descricao: lang.menu.relatorios,
            icone: faChartBar,
            fontWeight: 500,
            estaHabilitado: true,
            rota: '/report',
         },
         {
            key: 25,
            descricao: lang.menu.configuracoes,
            icone: faCogs,
            fontWeight: 500,
            estaHabilitado: true,
            subMenu: [
               {
                  key: 1041,
                  descricao: lang.menu.parametrosDoSistema,
                  icone: faCogs,
                  rota: '/1041',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1041),
               },
               {
                  key: 1031,
                  descricao: lang.menu.rotinasDoSistema,
                  icone: faListAlt,
                  rota: '/1031',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1031),
               },
               {
                  key: 1081,
                  descricao: lang.menu.tiposDeContaDeEmpresa,
                  icone: faListAlt,
                  rota: '/1081',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1081),
               },
               {
                  key: 1051,
                  descricao: lang.menu.tiposDeEquipamento,
                  icone: faListAlt,
                  rota: '/1051',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1051),
               },
               {
                  key: 1071,
                  descricao: lang.menu.tiposDeEmpresa,
                  icone: faListAlt,
                  rota: '/1071',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1071),
               },
               {
                  key: 1061,
                  descricao: lang.menu.cargos,
                  icone: faListAlt,
                  rota: '/1061',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1061),
               },
               {
                  key: 1381,
                  descricao: lang.menu.Termo,
                  icone: faListAlt,
                  rota: '/1381',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1381),
               },
               {
                  key: 1411,
                  descricao: lang.menu.tipoDeEnsaio,
                  icone: faListAlt,
                  rota: '/1411',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 1411),
               },
            ],
         },
         {
            key: 26,
            descricao: lang.menu.manutencao,
            icone: faTools,
            fontWeight: 500,
            estaHabilitado: true,
            subMenu: [
               {
                  key: 9001,
                  descricao: lang.menu.logs,
                  icone: faBars,
                  rota: '/9001',
                  estaHabilitado: this.temAcessoARotina(sessionManager, 9001),
               },
            ],
         },
         {
            key: 30,
            descricao: lang.menu.sobre,
            icone: faInfoCircle,
            fontWeight: 500,
            estaHabilitado: true,
            rota: '/about',
         },
      ];
      return result;
   }

   getMenusDeEmpresa(sessionManager, lang) {
      let result = [
         { key: 10, descricao: lang.menu.inicio, icone: faHome, fontWeight: 500, rota: '/', estaHabilitado: true },
         {
            key: 'panel',
            descricao: lang.menu.painelDeControle,
            icone: faChartBar,
            rota: '/panel',
            estaHabilitado: true,
         },
         {
            key: 1011,
            descricao: lang.menu.perfilDeUsuario,
            icone: faListAlt,
            rota: '/1011',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1011),
         },
         {
            key: 1121,
            descricao: lang.menu.usuariosDeEmpresa,
            icone: faUser,
            rota: '/1121',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1121),
         },
         {
            key: 1151,
            descricao: lang.menu.analisadorSimulador,
            icone: faHdd,
            rota: '/1151',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1151),
         },
         {
            key: 1091,
            descricao: lang.menu.clientes,
            icone: faUsers,
            rota: '/1091',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1091),
         },    
         {
            key: 1171,
            descricao: lang.menu.departamentos,
            icone: faListAlt,
            rota: '/1171',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1171),
         },
         {
            key: 1161,
            descricao: lang.menu.filiais,
            icone: faStore,
            rota: '/1161',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1161),
         },     
         {
            key: 1391,
            descricao: lang.menu.equipamentosDeEmpresa,
            icone: faDigitalTachograph,
            rota: '/1391',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1391),
         },
         {
            key: 1411,
            descricao: lang.menu.tipoDeEnsaio,
            icone: faListAlt,
            rota: '/1411',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1411),
         },
         {
            key: 1431,
            descricao: lang.menu.checkList,
            icone: faCheckSquare,
            rota: '/1431',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1431),
         },
         {
            key: 'sequencia',
            descricao: lang.menu.sequencias,
            icone: faListOl,
            rota: '/sequencia',
            estaHabilitado: true,
         },
         {
            key: 1041,
            descricao: lang.menu.parametrosDoSistema,
            icone: faCogs,
            rota: '/1041',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1041),
         },
         {
            key: 1311,
            descricao: lang.menu.ordemDeServico,
            icone: faClipboard,
            rota: '/1311',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1311),
         },
         {
            key: 1301,
            descricao: lang.menu.notificacoes,
            icone: faEnvelope,
            rota: '/1301',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1301),
         },
         {
            key: 1461,
            descricao: lang.menu.importacaoDeTestes,
            icone: faDownload,
            rota: '/1461',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1461),
         },
      ];
      return result;
   }

   getMenusDeCliente(sessionManager, lang) {
      let result = [
         { key: 10, descricao: lang.menu.inicio, icone: faHome, fontWeight: 500, rota: '/', estaHabilitado: true },

         {
            key: 1311,
            descricao: lang.menu.ordensDeServico,
            icone: faClipboard,
            rota: '/1311',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1311),
         },

         {
            key: 1131,
            descricao: lang.menu.certificados,
            icone: faDigitalTachograph,
            rota: '/1131',
            estaHabilitado: this.temAcessoARotina(sessionManager, 1131),
         },
      ];
      return result;
   }
}
