import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import CheckBox from '../../components/CheckBox';
import PerfilDeUsuarioController from '../../controllers/Neos/PerfilDeUsuarioController';
import { updateState } from '../../utils/Functions';

export default class PerfilDeUsuarioView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new PerfilDeUsuarioController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDePerfilDeUsuario = lang.telaDeCadastroDePerfilDeUsuario;
      return (
         <React.Fragment>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col sm={9} md={9} lg={9}>
                  <FormGroup>
                     <Label>{telaDeCadastroDePerfilDeUsuario.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.situacaoDePerfilDeUsuario.ativo },
                           { id: 2, descricao: lang.situacaoDePerfilDeUsuario.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                        disabled={
                           this.state.itemSelecionado.nome && this.state.itemSelecionado.nome.toUpperCase() === 'MASTER'
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDePerfilDeUsuario.tipoDePerfil}</Label>
                     <Select
                        as='select'
                        name='tipoDePerfil'
                        defaultValue={
                           this.state.itemSelecionado.tipoDePerfil && this.state.itemSelecionado.tipoDePerfil.id
                        }
                        options={
                           this.state.itemSelecionado.nome && this.state.itemSelecionado.nome.toUpperCase() === 'MASTER'
                              ? [{ id: 1, descricao: lang.tipoDePerfil.neos }]
                              : [
                                   { id: 1, descricao: lang.tipoDePerfil.neos },
                                   { id: 2, descricao: lang.tipoDePerfil.empresa },
                                   { id: 3, descricao: lang.tipoDePerfil.cliente },
                                ]
                        }
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.tipoDePerfil = i;
                              state.itemSelecionado.acessos = [];
                           }).then(() => {
                              this.controller.inserirTodas();
                           });
                        }}
                        disabled={
                           this.state.itemSelecionado.nome && this.state.itemSelecionado.nome.toUpperCase() === 'MASTER'
                        }
                     />
                  </FormGroup>
               </Col>

               <Col sm={9} md={9} lg={9}>
                  <FormGroup>
                     <Label>{telaDeCadastroDePerfilDeUsuario.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        readOnly={
                           this.state.itemSelecionado.nome && this.state.itemSelecionado.nome.toUpperCase() === 'MASTER'
                        }
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.state.itemSelecionado.tipoDePerfil &&
               this.state.itemSelecionado.tipoDePerfil.id !== 0 &&
               (!this.state.itemSelecionado.nome ||
                  (this.state.itemSelecionado.nome && this.state.itemSelecionado.nome.toUpperCase() !== 'MASTER')) && (
                  <SubCadastro
                     titulo={telaDeCadastroDePerfilDeUsuario.acessos}
                     itens={this.state.itemSelecionado.acessos}
                     tituloDasAcoes={() => {
                        return (
                           <div style={{ maxWidth: 26, margin: 'auto', paddingTop: 3 }}>
                              <CheckBox
                                 defaultChecked={this.state.permitirTudo}
                                 onChange={(checked) => {
                                    let acessos = this.state.itemSelecionado.acessos;
                                    for (var i = 0; i < acessos.length; i++) {
                                       acessos[i].acessoPermitido = checked;
                                    }
                                    updateState(this, (state) => {
                                       state.permitirTudo = checked;
                                       state.itemSelecionado.acessos = [];
                                    }).then(() => {
                                       updateState(this, (state) => {
                                          state.itemSelecionado.acessos = acessos;
                                       });
                                    });
                                 }}
                              />
                           </div>
                        );
                     }}
                     lang={lang}
                     colunas={() => {
                        return [
                           { titulo: 'Código', width: '20%', className: null },
                           { titulo: 'Descrição', width: '80%', className: null },
                        ];
                     }}
                     linha={(item) => {
                        return [item.rotina.id, item.rotina.descricao];
                     }}
                     acoes={(sender, index) => {
                        let item = this.state.itemSelecionado.acessos[index];
                        return (
                           <div style={{}}>
                              <CheckBox
                                 defaultChecked={item.acessoPermitido}
                                 onChange={(checked) => {
                                    item.acessoPermitido = checked;
                                    updateState(this, (state) => {
                                       state.itemSelecionado.acessos[index] = item;
                                    });
                                 }}
                              />
                           </div>
                        );
                     }}
                  />
               )}
            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDePerfilDeUsuario.titulo}
            url={'/perfildeusuario'}
            fastUrl={'/perfildeusuario/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1011, 1012, 1013, 1014]}
            getFiltro={this.controller.getFiltro}
            filtroExtra={this.props.filtroExtra}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
               acessos: [],
            }}
         />
      );
   }
}
