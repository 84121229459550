import { showError } from '../../components/Messages';
import { dateTimeToString, toEntityReference } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class ImportacaoDeTesteController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'ImportacaoDeTesteController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeImportacaoDeTeste.dataDeCadastro, width: '100%', orderby: 'Nome' },
         { titulo: this.lang.telaDeImportacaoDeTeste.tipoDeAnalisador, width: '100%', orderby: 'Nome' },
         { titulo: this.lang.telaDeImportacaoDeTeste.numeroDeSerie, width: '100%', orderby: 'Nome' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         dateTimeToString(item.dataDeCadastro),
         item.analisador ? item.analisador.sigla : '',
         item.analisador ? item.analisador.numeroDeSerie : '',
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.analisador) {
            showError(this.lang.telaDeImportacaoDeTeste.mensagens.informeOAnalisador);
            reject();
            return;
         }

         var input = {
            situacao: item.situacao,
            analisador: toEntityReference(item.analisador),
            testesASE: item.testesASE,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
