import React from 'react';
import TextInput from '../components/TextInput';
import CepInput from '../components/CepInput';
import CheckBox from '../components/CheckBox';
import CnpjInput from '../components/CnpjInput';
import CpfInput from '../components/CpfInput';
import DateInput from '../components/DateInput';
import DecimalInput from '../components/DecimalInput';
import IntegerInput from '../components/IntegerInput';
import RadioInput from '../components/RadioInput';
import Select from '../components/Select';
import TelefoneCelularInput from '../components/TelefoneCelularInput';
import TelefoneFixoInput from '../components/TelefoneFixoInput';
import TimeInput from '../components/TimeInput';
import Panel from '../components/Panel';
import Label from '../components/Label';
import Button from '../components/Button';
import SubCadastro from '../components/SubCadastro';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import { updateState, buildQueryString } from '../utils/Functions';
import TextArea from '../components/TextArea';
import Api from '../utils/Api';
import Avatar from '../components/Avatar';
import SessionManager from '../models/SessionManager';
import Image from '../components/Image';
import Document from '../components/Document';
import { getLang } from '../models/Lang.js';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
};

export const options = {
   responsive: true,
   plugins: {
      legend: {
         position: 'right',
      },
      title: {
         display: true,
         text: 'Chart.js Line Chart',
      },
   },
   elements: {
      point: {
         radius: 0,
      },
   },
   scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',

      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
   labels,
   datasets: [
      {
         label: 'Canal 1',
         data: [78, 55, 32, 44, 21],
         borderColor: getRandomColor(),
         yAxisID: 'y',
      },
      {
        label: 'Canal 2',
        data: [2, 38, 787, 454, 87],
        borderColor: getRandomColor(),
        yAxisID: 'y',
     },
     {
        label: 'Canal 3',
        data: [54, 32, 87, 78, 54],
        borderColor: getRandomColor(),
        yAxisID: 'y',
     },
      {
         label: 'Pressão',
         data: [351, 564, 98, 516, 165],
         borderColor: getRandomColor(),
         yAxisID: 'y1',
      },
   ],
};

export default class DemonstracaoDeComponentes extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         itens: [
            { id: 1, descricao: 'item 1' },
            { id: 2, descricao: 'item 2' },
            { id: 3, descricao: 'item 3' },
         ],
         image: null,
         file: { id: 1, name: 'Nome Arquivo' },
      };
      this.sessionManager = new SessionManager();
      this.lang = getLang('pt-BR');
   }

   render() {
      return (
         <Panel>
            <Line options={options} data={data} />

            <Button
               text='Testar'
               onClick={() => {
                  try {
                     buildQueryString(1, null, null, { searchable: 'RAMON DA SIL', idade: 56 });
                  } catch (e) {
                     console.error(e);
                  }
               }}
            />
            <Label>Document</Label>
            <Document
               defaultValue={this.state.file}
               api={new Api()}
               lang={this.lang}
               onChange={(file) => {
                  this.setState({ file: file });
               }}
            />
            <br />
            <Container>
               <SubCadastro
                  lang={this.lang}
                  titulo='Sub cadastro'
                  exibirTitulos={true}
                  itens={this.state.itens}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.itemSelecionado = {};
                        }).then(() => resolve('novo item criado...'));
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.itens.push({
                              descricao: state.itemSelecionado.descricao,
                           });
                        }).then(() => resolve('item inserido...'));
                     });
                  }}
                  selecionarParaAlteracao={(index) => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           let itemSelecionado = state.itens[index];
                           state.itemSelecionado = itemSelecionado;
                        }).then(() => resolve('inclusão/alteração iniciada...'));
                     });
                  }}
                  alterar={(index) => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           console.log(state.itemSelecionado);
                           state.itens[index].descricao = state.itemSelecionado.descricao;
                        }).then(() => resolve('item alterado...'));
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        resolve('inclusão/alteração cancelada...');
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itens.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [
                        { titulo: 'Código', width: '20%', className: null },
                        { titulo: 'Descrição', width: '80%', className: null },
                     ];
                  }}
                  linha={(item) => {
                     return [item.id, item.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{'Descrição'}</Label>
                                 <TextInput
                                    name='TextInput'
                                    defaultValue={this.state.itemSelecionado.descricao}
                                    onChange={(e) =>
                                       updateState(this, (state) => (state.itemSelecionado.descricao = e.target.value))
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     );
                  }}
               />
            </Container>

            <br />
            <br />
            <Label>Avatar</Label>
            <Avatar
               image={this.state.image}
               api={new Api()}
               lang={this.lang}
               onChange={(image) => {
                  this.setState({ image: image });
               }}
            />
            <br />
            <Label>Image</Label>
            <Image
               image={this.state.image}
               api={new Api()}
               lang={this.lang}
               onChange={(image) => {
                  this.setState({ image: image });
               }}
            />
            <br />

            <Label>TextInput</Label>
            <TextInput name='TextInput' />
            <br />
            <Label>TextArea</Label>
            <TextArea name='TextArea' rows={4} />
            <br />
            <Label>CepInput</Label>
            <CepInput />
            <br />
            <CheckBox label='CheckBox' name='CheckBox' />
            <br />
            <Label>CnpjInput</Label>
            <CnpjInput />
            <br />
            <Label>CpfInput</Label>
            <CpfInput />
            <br />
            <Label>DateInput</Label>
            <DateInput />
            <br />
            <Label>DecimalInput</Label>
            <DecimalInput />
            <br />
            <Label>IntegerInput</Label>
            <IntegerInput />
            <br />
            <RadioInput label='RadioInput' name='CheckBox' />
            <br />
            <Label>Select</Label>
            <Select
               options={[
                  { id: 1, nome: 'Fernando Silva' },
                  { id: 2, nome: 'Adriano Fonseca' },
                  { id: 3, nome: 'Ana Ribeiro' },
               ]}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  console.log(i);
               }}
            />
            <br />
            <Label>TelefoneCelularInput</Label>
            <TelefoneCelularInput />
            <br />
            <Label>TelefoneFixoInput</Label>
            <TelefoneFixoInput />
            <br />
            <Label>TimeInput</Label>
            <TimeInput />
            <br />
            <Button text='Button' inProgressText='Working...' onClickAsync={this.onClickAsync} />
         </Panel>
      );
   }

   onClickAsync() {
      var result = new Promise((resolve, reject) => {
         console.log('OnClick started...');
         setTimeout(() => {
            console.log('OnClick finished...');
            resolve();
         }, 3000);
      });
      return result;
   }
}
