import { showError } from '../../components/Messages';
import { dateToString, stringToDate, updateState } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class EquipamentoDeEmpresaController extends BaseController {
    constructor(props) {
        super(props);
        this.name = 'EquipamentoDeEmpresaController';
    }

    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
            { titulo: this.lang.telaDeCadastroDeEquipamento.tipoDeEquipamento, width: '25%', orderby: 'tipoDeEquipamento' },
            { titulo: this.lang.telaDeCadastroDeEquipamento.numeroDeSerie, width: '15%', orderby: 'numeroDeSerie' },          
            { titulo: this.lang.telaDeCadastroDeEquipamento.fabricante, width: '30%', orderby: 'fabricante' },
            { titulo: this.lang.telaDeCadastroDeEquipamento.modelo, width: '30%', orderby: 'modelo' },
        ];
    };

    getDadosDaTabela = (item) => {
        return [item.id, item.tipoDeEquipamento, item.numeroDeSerie, item.fabricante, item.modelo];
    };

    getObjetoDeDados = () => {
        return new Promise((resolve, reject) => {
            let state = this.view.state;
            let item = state.itemSelecionado;

            if (!item.numeroDeSerie) {
                showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeONumeroDeSerie);
                reject();
                return;
            }

            if (item.numeroDeSerie.length > 50) {
                showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeUmTamanhoMenorQue50CaracteresNoNumeroDeSerie);
                reject();
                return;
            }

            if (!item.tipoDeEquipamento) {
                showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeOTipoDeEquipamento);
                reject();
                return;
            }

            var input = {
                numeroDeSerie: item.numeroDeSerie,              
                fabricante: item.fabricante,
                modelo: item.modelo,
                dataDeFabricacao: item.dataDeFabricacao,
                tipoDeEquipamento: item.tipoDeEquipamento,
                cliente: item.cliente,               
                filial: item.filial,               
                departamento: item.departamento,               
            };

            if (state.alterando) {
                input.id = parseInt(item.id);
            }
            resolve(input);
        });
    };

    inserirCertificadoDeCalibracao = () => {
        return new Promise((resolve, reject) => {
           if (!this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.arquivo) {
              showError(this.lang.telaDeCadastroDeEquipamento.mensagens.selecioneOArquivo);
              reject();
              return false;
           }
  
           updateState(this.view, (state) => {
              if (!state.itemSelecionado.certificadosDeCalibracao) {
                 state.itemSelecionado.certificadosDeCalibracao = [];
              }
              state.itemSelecionado.certificadosDeCalibracao.push({
                 
                 certificadoDeCalibracao: {
                    arquivo: this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.arquivo,                  
                    dataDeValidade: this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                       .dataDeValidade
                       ? this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDeValidade
                       : new Date(),
                 },
              });
           }).then(() => resolve());
        });
     };
  
     alterarCertificadoDeCalibracao = (index) => {
        return new Promise((resolve) => {
           updateState(this.view, (state) => {
              state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.arquivo =
                 this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.arquivo;            
              state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.dataDeValidade =
                 this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDeValidade;
           }).then(() => resolve());
        });
     };
  
     getDescricaoDaSituacaoDoCertificado = (dataDeValidade) => {
        let dataAtual = new Date();
        let result;      
        if (dataAtual > stringToDate(dateToString(dataDeValidade, this.lang)))
           result = this.lang.telaDeCadastroDeEquipamento.vencido;
        else result = this.lang.telaDeCadastroDeEquipamento.ativo;      
        return result;
     };
}
