import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import OrdemDeServicoController from '../../controllers/Cliente/OrdemDeServicoParaClienteController';
import { dateToString, formatDate, updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Button from '../../components/Button';
import IntegerInput from '../../components/IntegerInput';

export default class OrdemDeServicoParaClienteView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         mostrar: true,
         dataInicial: new Date(),
         dataFinal: new Date(),
      };
      this.controller = new OrdemDeServicoController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeOrdemDeServico = lang.telaDeCadastroDeOrdemDeServico;
      return !this.state.mostrar ? (
         <div />
      ) : (
         <React.Fragment>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col sm={4} xs={4} md={4}>
                  <Label>{telaDeCadastroDeOrdemDeServico.dataDeAgendamento}</Label>
                  <DateInput
                     defaultValue={
                        this.state.itemSelecionado.dataDeAgendamento
                           ? this.state.itemSelecionado.dataDeAgendamento
                           : new Date()
                     }
                     readOnly={true}
                  />
               </Col>

               <Col sm={5} xs={5} md={5}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.situacao}</Label>
                     <TextInput
                        readOnly
                        defaultValue={
                           this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.descricao
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.usuarioResponsavel}</Label>
                     <Select
                        name={'usuarioresponsavel'}
                        defaultValue={this.state.itemSelecionado.usuarioResponsavel}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        readOnly={true}
                        nullText={''}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.usuarioExecutor}</Label>
                     <Select
                        name={'usuarioexecutor'}
                        defaultValue={this.state.itemSelecionado.usuarioExecutor}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        readOnly={true}
                        nullText={''}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.cliente}</Label>
                     <Select
                        name={'cliente'}
                        defaultValue={this.state.itemSelecionado.cliente}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.apelidoOuNomeFantasia}
                        readOnly={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.state.itemSelecionado.cliente && (
               <React.Fragment>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.nomeDoCliente}</Label>
                           <TextInput defaultValue={this.state.itemSelecionado.nomeDoCliente} readOnly={true} />
                        </FormGroup>
                     </Col>

                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.cpfCnpjDoCliente}</Label>
                           <TextInput defaultValue={this.state.itemSelecionado.cpfCnpjDoCliente} readOnly={true} />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.telefoneDoCliente}</Label>
                           <TextInput defaultValue={this.state.itemSelecionado.telefoneDoCliente} readOnly={true} />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.emailDoCliente}</Label>
                           <TextInput defaultValue={this.state.itemSelecionado.emailDoCliente} readOnly={true} />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.enderecoDoCliente}</Label>
                           <TextInput defaultValue={this.state.itemSelecionado.enderecoDoCliente} readOnly={true} />
                        </FormGroup>
                     </Col>
                  </Row>
               </React.Fragment>
            )}

            <Row>
               {this.state.itemSelecionado.cliente && this.state.itemSelecionado.cliente.id > 0 && (
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeOrdemDeServico.equipamentoSobTeste}</Label>
                        <Select
                           name={'equipamentodecliente'}
                           defaultValue={this.state.itemSelecionado.equipamentoSobTeste}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) =>
                              i.tipoDeEquipamento + ' / ' + i.numeroDeSerie + ' / ' + i.numeroDePatrimonio
                           }
                           readOnly={true}
                           nullText={''}
                        />
                     </FormGroup>
                  </Col>
               )}

               {!this.state.itemSelecionado.cliente && (
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeOrdemDeServico.equipamentoSobTeste}</Label>
                        <Select
                           name={'equipamentodeempresa'}
                           defaultValue={this.state.itemSelecionado.equipamentoSobTeste}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.tipoDeEquipamento + ' / ' + i.numeroDeSerie}
                           readOnly={true}
                           nullText={''}
                        />
                     </FormGroup>
                  </Col>
               )}
            </Row>

            {this.state.itemSelecionado.id && this.resultados()}

            <br />
         </React.Fragment>
      );
   };

   resultados = () => {
      let telaDeCadastroDeOrdemDeServico = this.props.lang.telaDeCadastroDeOrdemDeServico;
      return this.state.itemSelecionado.resultados && this.state.itemSelecionado.resultados.length ? (
         <Fragment>
            <SubCadastro
               titulo={telaDeCadastroDeOrdemDeServico.resultadosDeTesteDeOrdemDeServico}
               itens={this.state.itemSelecionado.resultados}
               lang={this.props.lang}
               podeInserir={false}
               novo={() => {}}
               cancelar={() => {}}
               inserir={() => {}}
               selecionarParaAlteracao={(index) => {}}
               podeExcluir={false}
               colunas={() => {
                  return [
                     { titulo: telaDeCadastroDeOrdemDeServico.data, width: '10%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.padrao, width: '10%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.sequencia, width: '50%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.situacao, width: '30%', className: null },
                  ];
               }}
               linha={(item) => {
                  return [
                     dateToString(item.data, this.props.lang),
                     item.tipoDeEquipamento.sigla,
                     item.sequencia.nome,
                     <div>
                        {item.situacao.descricao}
                        {item.situacao.id === 4 && (
                           <div>
                              <span>{telaDeCadastroDeOrdemDeServico.motivo}:&nbsp;</span>
                              <span>{item.observacao}</span>
                           </div>
                        )}
                     </div>,
                  ];
               }}
               formulario={() => {
                  return <div></div>;
               }}
            />
            <br />
         </Fragment>
      ) : null;
   };

   renderizarFiltros = (sender) => {
      let telaDeCadastroDeOrdemDeServico = this.props.lang.telaDeCadastroDeOrdemDeServico;
      return (
         <Fragment>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.situacao}
                        options={[
                           {
                              id: 1,
                              descricao: telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico.cadastrada,
                           },
                           {
                              id: 2,
                              descricao: telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico.executada,
                           },
                           {
                              id: 3,
                              descricao: telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico.liberada,
                           },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.situacao = i;
                           });
                        }}
                        nullText={''}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.numero}</Label>
                     <IntegerInput onChange={(e) => this.setState({ numeroDaOrdemDeServico: e.floatValue })} />
                  </FormGroup>
               </Col>

               <Col style={{ maxWidth: 400, paddingRight: 0 }}>
                  <Label>{telaDeCadastroDeOrdemDeServico.dataDeAgendamento}</Label>
                  <div style={{ display: 'flex' }}>
                     <div style={{ display: 'table-cell', width: 150 }}>
                        <DateInput
                           defaultValue={formatDate(this.state.dataInicial)}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataInicial = value;
                              });
                           }}
                        />
                     </div>
                     <div
                        style={{
                           display: 'table-cell',
                           width: 20,
                           marginTop: 10,
                           marginLeft: 5,
                           marginRight: 5,
                        }}
                     >
                        <span>{telaDeCadastroDeOrdemDeServico.ate}</span>
                     </div>
                     <div style={{ display: 'table-cell', width: 150 }}>
                        <DateInput
                           defaultValue={formatDate(this.state.dataFinal)}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataFinal = value;
                              });
                           }}
                        />
                     </div>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.numeroDeSerie}</Label>
                     <TextInput onChange={(e) => this.setState({ numeroDeSerie: e.target.value })} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.patrimonio}</Label>
                     <TextInput onChange={(e) => this.setState({ patrimonio: e.target.value })} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.modelo}</Label>
                     <TextInput onChange={(e) => this.setState({ modelo: e.target.value })} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.fabricante}</Label>
                     <TextInput onChange={(e) => this.setState({ fabricante: e.target.fabricante })} />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <Button onClick={sender.filtrar} text={this.props.lang.pesquisar} style={{ width: 200 }} />
                  <br />
               </Col>
            </Row>
         </Fragment>
      );
   };

   getFiltro = (filtro) => {
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (this.state.empresa) {
         result.empresa = this.state.empresa.id;
      }
      if (this.state.equipamento) {
         result.equipamento = this.state.equipamento.id;
      }
      if (this.state.situacao) {
         result.situacao = this.state.situacao.id;
      }
      result.id = this.state.numeroDaOrdemDeServico;
      result.dataInicial = formatDate(this.state.dataInicial);
      result.dataFinal = formatDate(this.state.dataFinal);
      result.numeroDeSerie = this.state.numeroDeSerie;
      result.patrimonio = this.state.patrimonio;
      result.modelo = this.state.modelo;
      result.fabricante = this.state.fabricante;
      return result;
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <Fragment>
            <Row>
               {(this.state.itemSelecionado.situacao.id === 2 || this.state.itemSelecionado.situacao.id === 3) && (
                  <Button
                     style={{ width: 200, marginLeft: 14 }}
                     text='Relatório em PDF'
                     inProgressText='Gerando...'
                     onClickAsync={() =>
                        new Promise((resolve, reject) => {
                           this.props.api
                              .post('/ordemdeservico/baixarrelatorio', { id: this.state.itemSelecionado.id })
                              .then((data) => {
                                 var byteCharacters = atob(data);
                                 var byteNumbers = new Array(byteCharacters.length);
                                 for (var i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                 }
                                 var byteArray = new Uint8Array(byteNumbers);
                                 var file = new Blob([byteArray], { type: 'application/pdf;base64' });

                                 //

                                 var win = window.open('', 'name', 'height=300, width=300'),
                                    iframe = document.createElement('iframe'),
                                    title = document.createElement('title'),
                                    fileUrl = URL.createObjectURL(file);

                                 title.appendChild(document.createTextNode('Nice title :)'));

                                 iframe.src = fileUrl;
                                 iframe.width = '100%';
                                 iframe.height = '100%';
                                 iframe.style.border = 'none';

                                 win.document.head.appendChild(title);
                                 win.document.body.appendChild(iframe);
                                 win.document.body.style.margin = 0;

                                 /*
                              var byteCharacters = atob(data);
                              var byteNumbers = new Array(byteCharacters.length);
                              for (var i = 0; i < byteCharacters.length; i++) {
                                 byteNumbers[i] = byteCharacters.charCodeAt(i);
                              }
                              var byteArray = new Uint8Array(byteNumbers);
                              var file = new Blob([byteArray], { type: 'application/pdf;base64' });

                              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                 // For IE
                                 window.navigator.msSaveOrOpenBlob(file, 'mypdf.pdf');
                              } else {
                                 // For non-IE
                                 var fileURL = URL.createObjectURL(file);
                                 window.open(fileURL);
                              }
                              */
                                 resolve();
                              })
                              .catch((e) => reject(e));
                        })
                     }
                  />
               )}

               {this.state.itemSelecionado.situacao.id === 3 &&
                  !this.state.itemSelecionado.testeAqt &&
                  !this.state.itemSelecionado.testeTin && (
                     <Button
                        style={{ width: 250 }}
                        text='Certificado de Calibração'
                        inProgressText='Gerando...'
                        onClickAsync={() =>
                           new Promise((resolve, reject) => {
                              this.props.api
                                 .post('/ordemdeservico/baixarcertificado', { id: this.state.itemSelecionado.id })
                                 .then((data) => {
                                    var byteCharacters = atob(data);
                                    var byteNumbers = new Array(byteCharacters.length);
                                    for (var i = 0; i < byteCharacters.length; i++) {
                                       byteNumbers[i] = byteCharacters.charCodeAt(i);
                                    }
                                    var byteArray = new Uint8Array(byteNumbers);
                                    var file = new Blob([byteArray], { type: 'application/pdf;base64' });

                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                       // For IE
                                       window.navigator.msSaveOrOpenBlob(file, 'mypdf.pdf');
                                    } else {
                                       // For non-IE
                                       var fileURL = URL.createObjectURL(file);
                                       window.open(fileURL);
                                    }
                                    resolve();
                                 })
                                 .catch((e) => reject(e));
                           })
                        }
                     />
                  )}
            </Row>
            <br />
         </Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeOrdemDeServico.titulo}
            url={'/ordemdeservico/ordemdeservicoparacliente'}
            api={this.props.api}
            lang={this.props.lang}
            iniciarVazio={true}
            ordenacaoPadrao={'id'}
            permissoes={[1311, null, null, null]}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            renderizarFiltros={this.renderizarFiltros}
            getFiltro={this.getFiltro}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1, descricao: 'Cadastrada' },
            }}
         />
      );
   }
}
