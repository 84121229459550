import React, { Component } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { LayoutParams } from '../../config/LayoutParams';
import Label from '../../components/Label';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import { Link } from 'react-router-dom';
import PasswordInput from '../../components/PasswordInput';
import './../../contents/css/login-logo.css';
import RecoverPasswordController from '../../controllers/User/RecoverPasswordController';
import Select from '../../components/Select';
import { updateState } from '../../utils/Functions';
import CnpjInput from '../../components/CnpjInput';
import { LogoLogin } from '../../components/LogoLogin';

export default class RecoverPasswordView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         codigoDeSeguranca: '',
         novaSenha: '',
         confirmacaoDaSenha: '',
         codigoDeSegurancaEnviado: false,
         tipoDeIdentificacaoComplementar: { id: 'CNPJ' },
         identificacaoComplementar: null,
      };
      this.controller = new RecoverPasswordController(this);
   }

   render() {
      return (
         <Container
            fluid
            style={{
               fontSize: 15,
               height: '100%',
               display: 'flex',
               position: 'fixed',
               justifyContent: 'center',
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
            }}
         >
            <Row className='justify-content-md-center'>
               <Col
                  xs
                  lg='3'
                  style={{
                     minHeight: 424,
                     maxHeight: 424,
                     minWidth: 350,
                     maxWidth: 350,
                     borderRadius: 10,
                     color: 'white',
                     paddingTop: 20,
                  }}
               >
                  <br />

                  <LogoLogin />

                  <div
                     style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: -30,
                        fontStyle: 'italic',
                     }}
                  >
                     <span>{this.props.lang.telaDeRecuperacaoDeSenha.titulo}</span>
                  </div>
                  <br />

                  <div className='justify-content-md-center'>
                     <React.Fragment>
                        {this.state.codigoDeSegurancaEnviado === false && (
                           <div>
                              {(this.props.tipoDeAcesso === 'EMPRESA' || this.props.tipoDeAcesso === 'CLIENTE') && (
                                 <Form.Group>
                                    <Label>{this.props.lang.telaDeLogin.identificacaoDaEmpresa}</Label>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                       <div style={{ width: 130 }}>
                                          <Select
                                             defaultValue={this.state.tipoDeIdentificacaoComplementar}
                                             options={[
                                                { id: 'CNPJ', descricao: 'CNPJ' },
                                                { id: 'OUTROS', descricao: 'Outros' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             onSelect={(i) => {
                                                updateState(this, (state) => (state.tipoDeDocumento = i));
                                             }}
                                             allowEmpty={false}
                                          />
                                       </div>

                                       <div style={{ marginLeft: 6, width: '100%' }}>
                                          {this.state.tipoDeIdentificacaoComplementar &&
                                             this.state.tipoDeIdentificacaoComplementar.id === 'CNPJ' && (
                                                <CnpjInput                                                 
                                                   name={'tipoDeDocumento'}
                                                   onChange={(e) => {
                                                      updateState(
                                                         this,
                                                         (state) => (state.identificacaoComplementar = e.formattedValue)
                                                      );
                                                   }}
                                                />
                                             )}

                                          {this.state.tipoDeIdentificacaoComplementar &&
                                             this.state.tipoDeIdentificacaoComplementar.id === 'OUTROS' && (
                                                <TextInput
                                                   defaultValue={this.state.identificacaoComplementar}
                                                   name={'tipoDeDocumento'}
                                                   onChange={(e) =>
                                                      updateState(
                                                         this,
                                                         (state) => (state.identificacaoComplementar = e.target.value)
                                                      )
                                                   }
                                                />
                                             )}
                                       </div>
                                    </div>
                                 </Form.Group>
                              )}

                              <Form.Group>
                                 <Label>{this.props.lang.telaDeRecuperacaoDeSenha.usuario}</Label>
                                 <TextInput
                                    type='text'
                                    onChange={(e) => this.setState({ nomeDeUsuario: e.target.value })}
                                 />
                                 <br />
                                 <Button
                                    text={this.props.lang.telaDeRecuperacaoDeSenha.enviarCodigoDeSeguranca}
                                    inProgressText={this.props.lang.telaDeRecuperacaoDeSenha.enviandoCodigoDeSeguranca}
                                    onClickAsync={this.controller.enviarCodigoDeSeguranca}
                                 />
                              </Form.Group>
                           </div>
                        )}

                        {this.state.codigoDeSegurancaEnviado && (
                           <Form.Group>
                              <Label>{this.props.lang.telaDeRecuperacaoDeSenha.codigoRecebidoPorEmail}</Label>
                              <TextInput
                                 type='text'
                                 onChange={(e) => this.setState({ codigoDeSeguranca: e.target.value })}
                              />

                              <Label>{this.props.lang.telaDeRecuperacaoDeSenha.novaSenha}</Label>
                              <PasswordInput
                                 type='password'
                                 onChange={(e) => this.setState({ novaSenha: e.target.value })}
                              />
                              <Label>{this.props.lang.telaDeRecuperacaoDeSenha.confirmacaoDeSenha}</Label>
                              <PasswordInput
                                 type='password'
                                 onChange={(e) => this.setState({ confirmacaoDaSenha: e.target.value })}
                              />
                              <br />
                              <Button
                                 text={this.props.lang.telaDeRecuperacaoDeSenha.recuperarSenha}
                                 inProgressText={this.props.lang.processando}
                                 onClickAsync={this.controller.recuperarSenha}
                              />
                           </Form.Group>
                        )}
                        <Link to={'/login'} tabIndex={-1}>
                           <Button text={this.props.lang.telaDeRecuperacaoDeSenha.voltarParaLogin} />
                        </Link>
                     </React.Fragment>
                  </div>
               </Col>
            </Row>
         </Container>
      );
   }
}
