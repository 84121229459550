import { getUnidadesDeDesvioASE, } from "./UnidadesDeDesvioASE";
import { getUnidadesDeDesvioAVM, } from "./UnidadesDeDesvioAVM";

export const getUnidadesDeDesvio = (lang, sigla, teste) => {
    let result = [];
    if (sigla === 'ASE100') {
       result = getUnidadesDeDesvioASE(lang, teste);
    } else
    if (sigla === 'AVM100') {
      result = getUnidadesDeDesvioAVM(lang, teste);
   }
    return result;
 };
