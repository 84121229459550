import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { LayoutParams } from '../../config/LayoutParams';
import LoginView from '../User/LoginView';
import LogoffView from '../User/LogoffView';
import NotFoundView from '../NotFoundView';
import ErrorView from '../ErrorView';
import MainLayout from '../../components/MainLayout';
import DemonstracaoDeComponentesView from '../DemonstracaoDeComponentesView';
import HomeView from '../HomeView';
import ClienteView from '../Empresa/ClienteView';
import UsuarioDeEmpresaView from './UsuarioDeEmpresaView';
import AnalisadorSimuladorView from './AnalisadorSimuladorView';
import ParametroDoSistemaView from '../Empresa/ParametroDoSistemaView';
import CargoDeEmpresaView from './CargoDeEmpresaView';
import PerfilDeUsuarioView from './PerfilDeUsuarioView';
import NotificacaoView from './NotificacaoView';
import AboutView from '../AboutView';
import OrdemDeServicoView from './OrdemDeServicoView';
import SequenciaABIView from './../Sequencias/SequenciaABIView';
import SequenciaAPEView from './../Sequencias/SequenciaAPEView';
import SequenciaSOPView from './../Sequencias/SequenciaSOPView';
import SequenciaJAUMPView from './../Sequencias/SequenciaJAUMPView';
import SequenciaAVMView from './../Sequencias/SequenciaAVMView';
import SequenciaJAUView from './../Sequencias/SequenciaJAUView';
import SequenciaSIMView from './../Sequencias/SequenciaSIMView';
import SequenciaASEView from './../Sequencias/SequenciaASEView';
import SequenciaSMPView from './../Sequencias/SequenciaSMPView';
import SequenciaAQTView from './../Sequencias/SequenciaAQTView';
import SequenciaSPKView from './../Sequencias/SequenciaSPKView';
import SequenciaMDCView from './../Sequencias/SequenciaMDCView';
import SequenciaView from './../Sequencias/SequenciaView';
import TermosDeUsoView from '../TermosDeUso';
import EquipamentoDeEmpresaView from '../Empresa/EquipamentoDeEmpresaView';
import AccountView from './../AccountView';
import PainelDeControleView from './PainelDeControleView';
import TipoDeEnsaioView from './../Sequencias/TipoDeEnsaioView';
import DepartamentoView from '../Empresa/DepartamentoView';
import FilialView from '../Empresa/FilialView';
import CheckListView from '../Empresa/CheckListView';
import ImportacaoDeTesteView from './ImportacaoDeTesteView';

export default class LayoutEmpresa extends React.Component {
   render() {
      let sessionManager = this.props.sessionManager;
      var lang = this.props.lang;
      this.api = this.props.api;
      return (
         <HashRouter>
            <MainLayout
               menuItems={this.props.menus.getMenusDeEmpresa(sessionManager, lang)}
               imgHomeLogo={LayoutParams.imgHomeLogo}
               login={this.props.login}
               lang={lang}
               mostrarDadosDaConta={true}
               alterarIdioma={this.props.alterarIdioma}
               api={this.api}
            >
               <Switch>
                  <Route exact path='/' render={() => <HomeView api={this.api} lang={lang} />} />
                  <Route
                     path='/login'
                     render={() => <LoginView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route path='/logoff' render={() => <LogoffView api={this.api} lang={lang} />} />
                  <Route
                     path='/error'
                     render={() => <ErrorView api={this.api} lang={lang} error={this.state.error} />}
                  />
                  <Route path='/components' render={() => <DemonstracaoDeComponentesView api={this.api} />} />
                  <Route path='/about' render={() => <AboutView api={this.api} lang={lang} />} />
                  <Route
                     path='/account'
                     render={() => (
                        <AccountView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1151'
                     render={() => (
                        <AnalisadorSimuladorView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1091'
                     render={() => (
                        <ClienteView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1121'
                     render={() => <UsuarioDeEmpresaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/sequencia'
                     render={() => <SequenciaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/9999'
                     render={() => (
                        <DemonstracaoDeComponentesView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1041'
                     render={() => (
                        <ParametroDoSistemaView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1141'
                     render={() => <CargoDeEmpresaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1011'
                     render={() => <PerfilDeUsuarioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1161'
                     render={() => (
                        <FilialView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1171'
                     render={() => <DepartamentoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1301'
                     render={() => <NotificacaoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1311'
                     render={() => <OrdemDeServicoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1461'
                     render={() => (
                        <ImportacaoDeTesteView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1181'
                     render={() => <SequenciaABIView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1191'
                     render={() => <SequenciaAPEView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1221'
                     render={() => <SequenciaAVMView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1281'
                     render={() => <SequenciaSOPView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1241'
                     render={() => <SequenciaJAUMPView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1231'
                     render={() => <SequenciaJAUView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1261'
                     render={() => <SequenciaSIMView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1211'
                     render={() => <SequenciaASEView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1271'
                     render={() => <SequenciaSMPView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1201'
                     render={() => <SequenciaAQTView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1441'
                     render={() => <SequenciaSPKView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1451'
                     render={() => <SequenciaMDCView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/90003'
                     render={() => <TermosDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1391'
                     render={() => (
                        <EquipamentoDeEmpresaView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/panel'
                     render={() => <PainelDeControleView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1411'
                     render={() => <TipoDeEnsaioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1431'
                     render={() => <CheckListView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route render={() => <NotFoundView lang={lang} />} />
               </Switch>
            </MainLayout>
         </HashRouter>
      );
   }
}
