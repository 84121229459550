import { FormGroup } from 'react-bootstrap';
import IntegerInput from '../../components/IntegerInput';
import DecimalInput from '../../components/DecimalInput';
import Label from '../../components/Label';
import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';
import TextArea from '../../components/TextArea';
import Image from '../../components/Image';
import { updateState } from '../../utils/Functions';

export default class ParametroDoSistemaController extends BaseController {
   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.telaDeCadastroDeParametrosDoSistema.descricao, width: '50%', orderby: 'Descricao' },
         { titulo: this.lang.telaDeCadastroDeParametrosDoSistema.valor, width: '50%', orderby: 'Valor' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.descricao, this.getDescricaoDoValor(item)];
   };

   getDescricaoDoValor = (item) => {
      var camposObjeto = [];
      var camposArquivo = ['LogoDoCertificado'];
      var descricao = null;
      const getDescricaoParaObjeto = () => {
         var result = null;
         if (item.preenchido) {
            result = JSON.parse(item.valor);
            result = result.id + ' - ' + result.nome;
         }
         return result;
      };

      if (camposObjeto.filter((i) => i === item.nome).length) {
         descricao = getDescricaoParaObjeto();
      } else if (camposArquivo.filter((i) => i === item.nome).length) {
         descricao = this.getDescricaoParaArquivo();
      } else {
         descricao =
            item.protegido && item.preenchido ? this.lang.telaDeCadastroDeParametrosDoSistema.protegido : item.valor;
      }
      return descricao;
   };

   getDescricaoParaArquivo = () => {
      var result = null;
      result = "[Arquivo]";
      return result;
   }

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let item = this.view.state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeParametrosDoSistema.mensagens.selecioneOParametro);
            reject();
            return;
         }

         var input = {
            grupo: item.grupo,
            ordem: item.ordem ? item.ordem : 0,
            nome: item.nome,
            descricao: item.descricao,
            valor: item.valor,
            protegido: item.protegido,
         };
         if (this.view.state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   comboParametros = () => {
      let comboParametros = this.lang.telaDeCadastroDeParametrosDoSistema.comboParametros;
      let result = [
         {
            nome: 'NumeroDeMesesDeValidadeDoCertificado',
            descricao: comboParametros.numeroDeMesesDeValidadeDoCertificado,
            grupo: 1,
            ordem: 1,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>{comboParametros.informe}</Label>
                     <IntegerInput
                        defaultValue={defaultValue}
                        onChange={(e) => {
                           setValor(e.value);
                        }}
                     />
                  </FormGroup>
               );
            },
         },
         {
            nome: 'RodapeDoRelatorioDeExecucao',
            descricao: comboParametros.rodapeDoRelatorioDeExecucao,
            grupo: 1,
            ordem: 2,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>{comboParametros.informe}</Label>
                     <TextArea
                        defaultValue={defaultValue}
                        onChange={(e) => {
                           setValor(e.target.value);
                        }}
                     />
                  </FormGroup>
               );
            },
         },
         {
            nome: 'RodapeDoCertificado',
            descricao: comboParametros.rodapeDoCertificado,
            grupo: 1,
            ordem: 2,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>{comboParametros.informe}</Label>
                     <TextArea
                        defaultValue={defaultValue}
                        onChange={(e) => {
                           setValor(e.target.value);
                        }}
                     />
                  </FormGroup>
               );
            },
         },
         {
            nome: 'RodapeDoRelatorioDeExecucao',
            descricao: comboParametros.rodapeDoRelatorioDeExecucao,
            grupo: 1,
            ordem: 3,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>{comboParametros.informe}</Label>
                     <TextArea
                        defaultValue={defaultValue}
                        onChange={(e) => {
                           setValor(e.target.value);
                        }}
                     />
                  </FormGroup>
               );
            },
         },
         {
            nome: 'TextoParaTesteConforme',
            descricao: comboParametros.textoParaTesteConforme,
            grupo: 1,
            ordem: 4,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>{comboParametros.informe}</Label>
                     <TextArea
                        defaultValue={defaultValue}
                        onChange={(e) => {
                           setValor(e.target.value);
                        }}
                     />
                  </FormGroup>
               );
            },
         },
         {
            nome: 'TextoParaTesteNaoConforme',
            descricao: comboParametros.textoParaTesteNaoConforme,
            grupo: 1,
            ordem: 5,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>{comboParametros.informe}</Label>
                     <TextArea
                        defaultValue={defaultValue}
                        onChange={(e) => {
                           setValor(e.target.value);
                        }}
                     />
                  </FormGroup>
               );
            },
         },
         {
            nome: 'LogoDoCertificado',
            descricao: comboParametros.logoDoCertificado,
            grupo: 1,
            ordem: 6,
            componente: (defaultValue, setValor) => {
               console.log(defaultValue);
               return (
                  <FormGroup>
                     <Image
                        lang={this.lang}
                        api={this.api}
                        // image={ this.view.state.itemSelecionado.logoDoCertificado}
                        image={defaultValue ? JSON.parse(defaultValue) : null}
                        onChange={(i) => {
                           updateState(this.view, (state) => (state.itemSelecionado.logoDoCertificado = i));
                           //this.view.state.itemSelecionado;
                           if (i) {
                              setValor(JSON.stringify({ id: i.id, nome: i.nome }));
                           } else {
                              setValor(null);
                           }
                        }}
                     />
                  </FormGroup>
               );
            },
         },
         {
            nome: 'confidenceInterval',
            descricao: comboParametros.intervaloDeConfianca,
            grupo: 1,
            ordem: 1,
            componente: (defaultValue, setValor) => {
               defaultValue = defaultValue ? JSON.parse(defaultValue) : null;
               return (
                  <FormGroup>
                     <Label>{comboParametros.informe}</Label>
                     <DecimalInput
                        lang={this.lang}
                        defaultValue={defaultValue}
                        decimalPlaces={0}
                        onChange={(e) => {
                           setValor(e.value);
                        }}
                     />
                  </FormGroup>
               );
            },
         },
      ];
      return result;
   };
}
