import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import '../contents/css/checkbox-input.css';

export default class CheckBox extends React.Component {
    render() {
        return (
            <CheckBoxStyled
                className={'checkbox-input' + (this.props.className ? (' ' + this.props.className) : '')}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={this.props.ref}                
            >
                <Form.Label
                    style={{
                        display: 'flex',
                        textAlign: 'left',
                        cursor: 'pointer',
                        marginBottom: 0,
                        marginTop: this.props.marginTop
                    }}
                >
                    <Form.Check
                        type='checkbox'
                        name={this.props.name}
                        defaultChecked={this.props.defaultChecked}
                        onChange={(e) => {                            
                            if (this.props.onChange) {
                                this.props.onChange(e.target.checked ? true : false);
                            }
                        }}
                        style={this.props.style}
                        disabled={this.props.disabled}
                    />
                    <span>{this.props.label}</span>
                </Form.Label>
            </CheckBoxStyled>
        );
    }
}

const CheckBoxStyled = styled.div`
    .form-label {
        display: flex;
    }

    .form-check {
        display: flex;
        text-align: center;
        padding-left: 3px;
        padding-right: 5px;
    }

    .form-check-input {
        margin: auto;
        cursor: pointer;
        height: 16px;
        width: 16px;
    }

    span:disabled, span[disabled=disabled], span[disabled] {
        cursor: not-allowed !important;
    }
`;
