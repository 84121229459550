import imgHomeLogo from './../contents/img/home-logo.svg';
import imgLoginLogo from './../contents/img/login-logo.svg';

export const LayoutParams = {
    colors: {
        corDoTemaPrincipal: '#13346B',
        corSecundaria: '#FFF',
        corDoTextoPadrao: '#535b64',
        fundoCinza: 'rgb(222, 226, 230)',
        tableItemhHighlightBackgroundColor: 'rgb(222, 226, 230)',
    },
    imgHomeLogo: imgHomeLogo,
    imgLoginLogo: imgLoginLogo,
};
