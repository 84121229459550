import React, { Component } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import Label from '../../../components/Label';
import DateInput from '../../../components/DateInput';
import { dateToString, updateState, buildQueryString, formatDate } from '../../../utils/Functions';
import ListaPadrao from '../../ListaPadrao';
import { Link } from 'react-router-dom';
import EmpresaView from '../../Neos/EmpresaView';
import Select from '../../../components/Select';

export default class RelatorioDeCertificadosAVencer extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   getFiltro = () => {
      return new Promise((resolve, reject) => {
         resolve({
            empresa: this.state.empresa ? this.state.empresa.id : null,
            dataInicial: formatDate(this.state.dataInicial),
            dataFinal: formatDate(this.state.dataFinal),
         });
      });
   };

   renderizarFiltros = () => {
      return (
         <div style={{ maxWidth: 800 }}>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeCertificadosAVencer.empresa}</Label>
                     <Select
                        name={'empresa'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.empresa = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <EmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/empresa/fast' + buildQueryString(2, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col style={{ maxWidth: 200 }}>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeCertificadosAVencer.dataInicial}</Label>
                     <DateInput
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataInicial = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col style={{ maxWidth: 200 }}>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeCertificadosAVencer.dataFinal}</Label>
                     <DateInput
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataFinal = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </div>
      );
   };

   getTitulosDaTabela = () => {
      return [
         { titulo: this.props.lang.relatorioDeCertificadosAVencer.empresa, width: '60%' },
         { titulo: this.props.lang.relatorioDeCertificadosAVencer.numeroDeSerie, width: '10%' },
         { titulo: this.props.lang.relatorioDeCertificadosAVencer.siglaDoTipoDeEquipamento, width: '15%' },
         { titulo: this.props.lang.relatorioDeCertificadosAVencer.dataDeValidadeDoCertificado, width: '15%' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.nomeFantasia,
         item.numeroDeSerie,
         item.siglaDoTipoDeEquipamento,
         dateToString(item.dataDeValidadeDoCertificado, this.props.lang),
      ];
   };

   render() {
      return (
         <ListaPadrao
            titulo={
               <div>
                  <Link
                     to={'/report'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',

                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.relatorios.titulo}</span>
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.relatorioDeCertificadosAVencer.titulo}</span>
               </div>
            }
            estruturaPadrao={false}
            url={'/relatorio/RelatorioDeCertificadosAVencer'}
            api={this.props.api}
            lang={this.props.lang}
            getFiltro={this.getFiltro}
            renderizarFiltros={this.renderizarFiltros}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            getFormState={() => this.state}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
         />
      );
   }
}
