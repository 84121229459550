const getApiUrl = () => {
   // return 'https://sunweb.neos.ind.br/api';

    let url = null;
    if (process && process.env && process.env.NODE_ENV === 'development') {
        url = 'http://192.168.10.14:5005';
    } else {
        return 'https://sunweb.neos.ind.br/api';
    }    
    if (!url) {
        throw new Error('Url da API não foi definida.');
    }
        return url;
};

export const ConnectionParams = {
    apiUrl: getApiUrl(),
};
