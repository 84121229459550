import { BaseController } from './BaseController';

export default class CidadeController extends BaseController {
    constructor(props) {
        super(props);
        this.name = 'CidadeController';
    }

    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.telaDeCadastroDeTipoDeEmpresa.codigo, orderby: 'Id', className: 'codigo' },            
            { titulo: 'Cidade', width: '60%', orderby: null },
            { titulo: 'Estado', width: '30%', orderby: null },
            { titulo: 'UF', width: '10%', orderby: null },
        ];
    };

    getDadosDaTabela = (item) => {
        return [item.id, item.nome, item.estado.nome, item.estado.uf];
    };

    getObjetoDeDados = () => {        
    };
}
