export const getTiposDeOperacaoABI = (lang) => {
   var result = [
      { id: 0, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MONO_CUT },
      { id: 1, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MONO_BLEND },
      { id: 2, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MONO_COAG },
      { id: 3, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_BIPO_CUT },
      { id: 4, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_BIPO_COAG },
      { id: 5, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_1 },
      { id: 6, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_2 },
      { id: 7, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_3 },
      { id: 8, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_4 },
      { id: 9, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_5 },
      { id: 10, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_6 },
      { id: 11, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_7 },
      { id: 12, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_8 },
      { id: 13, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_9 },
      { id: 14, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_10 },
      { id: 15, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_11 },
      { id: 16, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_12 },
      { id: 17, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_13 },
      { id: 18, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_14 },
      { id: 19, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_15 },
      { id: 20, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_16 },
      { id: 21, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_17 },
      { id: 22, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_18 },
      { id: 23, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_19 },
      { id: 24, descricao: lang.tipoDeOperacaoABI.SEQ_WINDOW_REGISTER_CB_ABIMODO_MODO_20 },
   ];
   return result;
};

export const getTiposDeTestesABI = (lang) => {
   let result = [
      { id: 0x00, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_DISTPOT },
      { id: 0x11, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_1A },
      { id: 0x21, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_2A },
      { id: 0x31, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_3A },
      { id: 0x32, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_3B },
      { id: 0x41, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_4A },
      { id: 0x42, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_4B },
      { id: 0x43, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_4C },
      { id: 0x44, descricao: lang.tipoDeTestesABI.SEQ_WINDOW_REGISTER_CB_ABITIPOTESTE_CORRENTEFUGA_4D },
   ];
   return result;
};

export const getTiposDeUnidadeDesvioABI = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaABI.porcentagem },
      { id: 1, descricao: lang.cadastroDeSequenciaABI.wPontencia },
   ];
   return result;
};

export const getTiposDeUnidadeDesvioAPE = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaAPE.porcentagem },
      { id: 1, descricao: lang.cadastroDeSequenciaAPE.milimetrosDeMercurio },
   ];
   return result;
};

export const getTiposDeUnidadeDesvioSMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaSMP.porcentagem },
      { id: 1, descricao: lang.cadastroDeSequenciaSMP.milimetrosDeMercurio },
   ];
   return result;
};

export const getTiposDeUnidadeDesvioSPK = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaSPK.porcentagem },
      { id: 1, descricao: lang.cadastroDeSequenciaSPK.milimetrosDeMercurio },
   ];
   return result;
};

export const getTiposDeTestesAPE = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaAPE.manometro },
      { id: 2, descricao: lang.cadastroDeSequenciaAPE.vazamento },
   ];
   return result;
};

export const getTiposDeTestesSOP = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaSOP.spO2_Saturacao },
      { id: 1, descricao: lang.cadastroDeSequenciaSOP.spO2_Freq },
   ];
   return result;
};

export const getTiposDeUnidadeDeLimiteSOPSaturacao = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaSOP.porcentagem },
      { id: 1, descricao: lang.cadastroDeSequenciaSOP.unidade },
   ];
   return result;
};

export const getTiposDeUnidadeDeLimiteSOPFrequencia = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaSOP.porcentagem },
      { id: 1, descricao: lang.cadastroDeSequenciaSOP.bpm },
   ];
   return result;
};

export const getTiposDeTransmissaoSOP = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaSOP.transmissaoFino },
      { id: 1, descricao: lang.cadastroDeSequenciaSOP.transmissaoMedio },
      { id: 2, descricao: lang.cadastroDeSequenciaSOP.transmissaoGrosso },
   ];
   return result;
};

export const getTiposDeAmplitudeSOP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeAmplitude.amplitude1 },
      { id: 1, descricao: lang.tipoDeAmplitude.amplitude2 },
      { id: 2, descricao: lang.tipoDeAmplitude.amplitude3 },
      { id: 3, descricao: lang.tipoDeAmplitude.amplitude4 },
      { id: 4, descricao: lang.tipoDeAmplitude.amplitude5 },
   ];
   return result;
};

export const getTiposDeTiposDeCargasABI = (lang) => {
   let result = [
      { id: 0, descricao: lang.cadastroDeSequenciaABI.interna },
      { id: 1, descricao: lang.cadastroDeSequenciaABI.externa },
   ];
   return result;
};

//----------------------------------------------------------------------------------------------------------------------

//---- AVM -------------------------------------------------------------------------------------------------------------

// Tipos de teste

export const getTiposDeTestesAVM = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesAVM.manualDeVentilador },
      { id: 1, descricao: lang.tipoDeTestesAVM.manualDePressao },
      { id: 2, descricao: lang.tipoDeTestesAVM.normaDeVolume },
      { id: 3, descricao: lang.tipoDeTestesAVM.normaDePressao },
      { id: 4, descricao: lang.tipoDeTestesAVM.normaDeFiO2 },
   ];
   return result;
};

// Tipos de parâmetro

export const getTiposDeParametrosManualVolumeMLAVM = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.volumeML }];
   return result;
};
export const getTiposDeParametrosManualMBARAVM = (lang) => {
   let result = [{ id: 0, descricao: lang.cadastroDeSequenciaAVM.pressaoInspMBAR }];
   return result;
};
export const getTiposDeParametrosManualVolumePEPAVM = (lang) => {
   let result = [{ id: 1, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.pressaoPEEP }];
   return result;
};
export const getTiposDeParametrosManualFIO2AVM = (lang) => {
   let result = [{ id: 2, descricao: lang.cadastroDeSequenciaAVM.normaDePorcentagem }];
   return result;
};

export const getTiposDeParametrosManualDeVentiladoresAVM = (lang) => {
   let result = [
      { id: 5, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.volumeInsp },
      { id: 6, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.volumeExp },
      { id: 7, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.volumeDeMinuto },
      { id: 8, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.pressaoCanal },
      { id: 9, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.pressaoPico },
      { id: 10, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.pressaoMedia },
      { id: 11, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.pressaoPEEP },
      { id: 12, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.pressaoDePausa },
      { id: 15, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.fluxo },
      { id: 16, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.fluxoDePicoInsp },
      { id: 17, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.fluxoDePicoExp },
      { id: 18, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.vazamento },
      { id: 19, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.taxaDeResp },
      { id: 20, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.tempoDeInsp },
      { id: 21, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.tempoDeExp },
      { id: 22, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.relacaoI_E },
      { id: 23, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.tempoInspCiclo },
      { id: 24, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.oxigenio },
      { id: 25, descricao: lang.tipoDeParametrosManualDeVentiladoresAVM.complacencia },
   ];
   return result;
};
export const getTiposDeParametrosManualDeVentiladoresFIO2AVM = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.tempoOxigenio }];
   return result;
};
export const getTiposDeParametrosDeManualDePressaoAVM = (lang) => {
   let result = [
      { id: 13, descricao: lang.tipoDeParametrosDeManualDePressaoAVM.pressaoDiferencia },
      { id: 14, descricao: lang.tipoDeParametrosDeManualDePressaoAVM.pressaoAlta },
   ];
   return result;
};

// Unidade de medida

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_volumeInsp_volumeExp = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.mlMililitro },
      { id: 1, descricao: lang.tipoDeUnidadeDeMedidaAVM.litros },
      { id: 2, descricao: lang.tipoDeUnidadeDeMedidaAVM.mmMilimetroCubico },
   ];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_volumeDeMinuto = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.litros }];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.mbarMilibar },
      { id: 1, descricao: lang.tipoDeUnidadeDeMedidaAVM.mmhgMilimetroDeMercurio },
      { id: 2, descricao: lang.tipoDeUnidadeDeMedidaAVM.psiLibraForca },
      { id: 3, descricao: lang.tipoDeUnidadeDeMedidaAVM.kpaKilopascal },
      { id: 4, descricao: lang.tipoDeUnidadeDeMedidaAVM.cmh20 },
   ];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_vazamento = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.mlMililitro },
      { id: 1, descricao: lang.tipoDeUnidadeDeMedidaAVM.litros },
      { id: 2, descricao: lang.tipoDeUnidadeDeMedidaAVM.mmMilimetroCubico },
   ];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_fluxos = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.litrosPorMinuto },
      { id: 1, descricao: lang.tipoDeUnidadeDeMedidaAVM.litrosPorSegundo },
      { id: 2, descricao: lang.tipoDeUnidadeDeMedidaAVM.mililitroPorMinuto },
      { id: 3, descricao: lang.tipoDeUnidadeDeMedidaAVM.mililitroPorSegundo },
   ];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_taxaDeResp = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.rpm }];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_tempoDeInsp_tempoDeExp = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.segundos }];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_oxigenio = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.porcentagem }];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualVentilador_complacencia = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.complacencia }];
   return result;
};

export const getTiposDeUnidadeDeMedidaAVM_manualPressao = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeMedidaAVM.mbarMilibar },
      { id: 1, descricao: lang.tipoDeUnidadeDeMedidaAVM.mmhgMilimetroDeMercurio },
      { id: 2, descricao: lang.tipoDeUnidadeDeMedidaAVM.psiLibraForca },
      { id: 3, descricao: lang.tipoDeUnidadeDeMedidaAVM.kpaKilopascal },
      { id: 4, descricao: lang.tipoDeUnidadeDeMedidaAVM.cmh20 },
   ];
   return result;
};

// Unidade de desvio

// --
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeInsp_volumeExp = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mlMililitro },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.litros },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.mmMilimetroCubico },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeDeMinuto = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.litros },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mbarMilibar },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.mmhgMilimetroDeMercurio },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.psiLibraForca },
      { id: 4, descricao: lang.tipoDeUnidadeDeDesvioAVM.kpaKilopascal },
      { id: 5, descricao: lang.tipoDeUnidadeDeDesvioAVM.cmh20 },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_vazamento = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mlMililitro },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.litros },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.mmMilimetroCubico },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_fluxos = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.litrosPorMinuto },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.litrosPorSegundo },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.mililitroPorMinuto },
      { id: 4, descricao: lang.tipoDeUnidadeDeDesvioAVM.mililitroPorSegundo },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_taxaDeResp = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.rpm },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoDeInsp_tempoDeExp = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.segundos },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_relacao_IE = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem }];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoInspCiclo = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem }];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_oxigenio = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem }];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_manualVentilador_complacencia = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mililitroPorMBar },
   ];
   return result;
};

// --

export const getTiposDeUnidadeDeDesvioAVM_manualPressao = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mbarMilibar },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.mmhgMilimetroDeMercurio },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.psiLibraForca },
      { id: 4, descricao: lang.tipoDeUnidadeDeDesvioAVM.kpaKilopascal },
      { id: 5, descricao: lang.tipoDeUnidadeDeDesvioAVM.cmh20 },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro1 = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mlMililitro },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.litros },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.mmMilimetroCubico },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro2 = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mbarMilibar },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro3 = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem }];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro1 = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mbarMilibar },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.mmhgMilimetroDeMercurio },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.psiLibraForca },
      { id: 4, descricao: lang.tipoDeUnidadeDeDesvioAVM.kpaKilopascal },
      { id: 5, descricao: lang.tipoDeUnidadeDeDesvioAVM.cmh20 },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro2 = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioAVM.mbarMilibar },
      { id: 2, descricao: lang.tipoDeUnidadeDeDesvioAVM.mmhgMilimetroDeMercurio },
      { id: 3, descricao: lang.tipoDeUnidadeDeDesvioAVM.psiLibraForca },
      { id: 4, descricao: lang.tipoDeUnidadeDeDesvioAVM.kpaKilopascal },
      { id: 5, descricao: lang.tipoDeUnidadeDeDesvioAVM.cmh20 },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro3 = (lang) => {
   let result = [{ id: 0, descricao: lang.tipoDeUnidadeDeDesvioAVM.porcentagem }];
   return result;
};
//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
export const getTiposDoTipoTesteJAUMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesJAUMP.frequenciaPPM },
      { id: 1, descricao: lang.tipoDeTestesJAUMP.amplitudeA },
      { id: 2, descricao: lang.tipoDeTestesJAUMP.larguraMS },
      { id: 3, descricao: lang.tipoDeTestesJAUMP.sensibilidadeMV },
      { id: 4, descricao: lang.tipoDeTestesJAUMP.periodoDeRefratarioMS },
   ];
   return result;
};
export const getTiposDeCargasJAUMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeCargasJAUMP.carga50 },
      { id: 1, descricao: lang.tipoDeCargasJAUMP.carga100 },
      { id: 2, descricao: lang.tipoDeCargasJAUMP.carga200 },
      { id: 3, descricao: lang.tipoDeCargasJAUMP.carga500 },
      { id: 4, descricao: lang.tipoDeCargasJAUMP.carga600 },
      { id: 5, descricao: lang.tipoDeCargasJAUMP.carga1000 },
      { id: 6, descricao: lang.tipoDeCargasJAUMP.carga1500 },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioJAUMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioJAUMP.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioJAUMP.pulsoPorMinuto },
   ];
   return result;
};

export const getTiposDeFormaDeOndasJAUMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeFormaDeOndaGeladaJAUMP.quadrada },
      { id: 1, descricao: lang.tipoDeFormaDeOndaGeladaJAUMP.triangular },
      { id: 2, descricao: lang.tipoDeFormaDeOndaGeladaJAUMP.senoidal },
   ];
   return result;
};

export const getTiposDeTestesJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesJAU.energia },
      { id: 1, descricao: lang.tipoDeTestesJAU.sincronismo },
      { id: 2, descricao: lang.tipoDeTestesJAU.tempoDeCargaCapacitor },
      { id: 3, descricao: lang.tipoDeTestesJAU.ecgFrequencia },
      { id: 4, descricao: lang.tipoDeTestesJAU.ecgAmplitude },
      { id: 5, descricao: lang.tipoDeTestesJAU.arritmias_Performance },
      { id: 6, descricao: lang.tipoDeTestesJAU.respiracao_Frequencia },
      { id: 7, descricao: lang.tipoDeTestesJAU.respiracao_Amplitude },
      { id: 8, descricao: lang.tipoDeTestesJAU.respiracao_Apneia },
   ];
   return result;
};
export const getTiposDeResistenciaJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeResistenciaJAU.cinquenta },
      { id: 1, descricao: lang.tipoDeResistenciaJAU.vinteCinco },
      { id: 2, descricao: lang.tipoDeResistenciaJAU.cinquenta2 },
      { id: 3, descricao: lang.tipoDeResistenciaJAU.setentaECinto },
      { id: 4, descricao: lang.tipoDeResistenciaJAU.cem },
      { id: 5, descricao: lang.tipoDeResistenciaJAU.centoEVinteCinco },
      { id: 6, descricao: lang.tipoDeResistenciaJAU.centroECinquenta },
      { id: 7, descricao: lang.tipoDeResistenciaJAU.centroESetentaECinco },
   ];
   return result;
};
export const getTiposDeSinalJAU = (lang) => {
   let result = [
      { id: 200, descricao: lang.tipoDeSinalJAU.BPM20 },
      { id: 201, descricao: lang.tipoDeSinalJAU.BPM30 },
      { id: 202, descricao: lang.tipoDeSinalJAU.BPM40 },
      { id: 203, descricao: lang.tipoDeSinalJAU.BPM50 },
      { id: 204, descricao: lang.tipoDeSinalJAU.BPM60 },
      { id: 205, descricao: lang.tipoDeSinalJAU.BPM70 },
      { id: 206, descricao: lang.tipoDeSinalJAU.BPM80 },
      { id: 207, descricao: lang.tipoDeSinalJAU.BPM90 },
      { id: 208, descricao: lang.tipoDeSinalJAU.BPM100 },
      { id: 209, descricao: lang.tipoDeSinalJAU.BPM110 },
      { id: 210, descricao: lang.tipoDeSinalJAU.BPM120 },
      { id: 211, descricao: lang.tipoDeSinalJAU.BPM130 },
      { id: 212, descricao: lang.tipoDeSinalJAU.BPM140 },
      { id: 213, descricao: lang.tipoDeSinalJAU.BPM150 },
      { id: 214, descricao: lang.tipoDeSinalJAU.BPM160 },
      { id: 215, descricao: lang.tipoDeSinalJAU.BPM170 },
      { id: 216, descricao: lang.tipoDeSinalJAU.BPM180 },
      { id: 217, descricao: lang.tipoDeSinalJAU.BPM190 },
      { id: 218, descricao: lang.tipoDeSinalJAU.BPM200 },
      { id: 219, descricao: lang.tipoDeSinalJAU.BPM210 },
      { id: 220, descricao: lang.tipoDeSinalJAU.BPM220 },
      { id: 221, descricao: lang.tipoDeSinalJAU.BPM230 },
      { id: 222, descricao: lang.tipoDeSinalJAU.BPM240 },
      { id: 223, descricao: lang.tipoDeSinalJAU.BPM250 },
      { id: 224, descricao: lang.tipoDeSinalJAU.BPM260 },
      { id: 225, descricao: lang.tipoDeSinalJAU.BPM270 },
      { id: 226, descricao: lang.tipoDeSinalJAU.BPM280 },
      { id: 227, descricao: lang.tipoDeSinalJAU.BPM290 },
      { id: 228, descricao: lang.tipoDeSinalJAU.BPM300 },
   ];
   return result;
};

export const getTiposDeSinalSMP = (lang) => {
   let result = [
      { id: 200, descricao: lang.tipoDeSinalJAU.BPM20 },
      { id: 201, descricao: lang.tipoDeSinalJAU.BPM30 },
      { id: 202, descricao: lang.tipoDeSinalJAU.BPM40 },
      { id: 203, descricao: lang.tipoDeSinalJAU.BPM50 },
      { id: 204, descricao: lang.tipoDeSinalJAU.BPM60 },
      { id: 205, descricao: lang.tipoDeSinalJAU.BPM70 },
      { id: 206, descricao: lang.tipoDeSinalJAU.BPM80 },
      { id: 207, descricao: lang.tipoDeSinalJAU.BPM90 },
      { id: 208, descricao: lang.tipoDeSinalJAU.BPM100 },
      { id: 209, descricao: lang.tipoDeSinalJAU.BPM110 },
      { id: 210, descricao: lang.tipoDeSinalJAU.BPM120 },
      { id: 211, descricao: lang.tipoDeSinalJAU.BPM130 },
      { id: 212, descricao: lang.tipoDeSinalJAU.BPM140 },
      { id: 213, descricao: lang.tipoDeSinalJAU.BPM150 },
      { id: 214, descricao: lang.tipoDeSinalJAU.BPM160 },
      { id: 215, descricao: lang.tipoDeSinalJAU.BPM170 },
      { id: 216, descricao: lang.tipoDeSinalJAU.BPM180 },
      { id: 217, descricao: lang.tipoDeSinalJAU.BPM190 },
      { id: 218, descricao: lang.tipoDeSinalJAU.BPM200 },
      { id: 219, descricao: lang.tipoDeSinalJAU.BPM210 },
      { id: 220, descricao: lang.tipoDeSinalJAU.BPM220 },
      { id: 221, descricao: lang.tipoDeSinalJAU.BPM230 },
      { id: 222, descricao: lang.tipoDeSinalJAU.BPM240 },
      { id: 223, descricao: lang.tipoDeSinalJAU.BPM250 },
      { id: 224, descricao: lang.tipoDeSinalJAU.BPM260 },
      { id: 225, descricao: lang.tipoDeSinalJAU.BPM270 },
      { id: 226, descricao: lang.tipoDeSinalJAU.BPM280 },
      { id: 227, descricao: lang.tipoDeSinalJAU.BPM290 },
      { id: 228, descricao: lang.tipoDeSinalJAU.BPM300 },
   ];
   return result;
};

export const getTiposDeSinalArritimiaSIM = (lang) => {
   let result = [
      { id: 9, descricao: lang.tipoDeSinalArritimiaJAU.afib },
      { id: 10, descricao: lang.tipoDeSinalArritimiaJAU.vfib },
      { id: 11, descricao: lang.tipoDeSinalArritimiaJAU.fVF },
      { id: 12, descricao: lang.tipoDeSinalArritimiaJAU.vtach },
      { id: 13, descricao: lang.tipoDeSinalArritimiaJAU.bV },
      { id: 14, descricao: lang.tipoDeSinalArritimiaJAU.bVL },
      { id: 15, descricao: lang.tipoDeSinalArritimiaJAU.bVR },
      { id: 16, descricao: lang.tipoDeSinalArritimiaJAU.sBID },
      { id: 17, descricao: lang.tipoDeSinalArritimiaJAU.tV1 },
      { id: 18, descricao: lang.tipoDeSinalArritimiaJAU.tV2 },
      { id: 19, descricao: lang.tipoDeSinalArritimiaJAU.bV2 },
      { id: 20, descricao: lang.tipoDeSinalArritimiaJAU.pAC },
      { id: 21, descricao: lang.tipoDeSinalArritimiaJAU.bLKII },
      { id: 22, descricao: lang.tipoDeSinalArritimiaJAU.pVCSTD },
      { id: 23, descricao: lang.tipoDeSinalArritimiaJAU.ePVC },
      { id: 24, descricao: lang.tipoDeSinalArritimiaJAU.pVCRonT },
      { id: 25, descricao: lang.tipoDeSinalArritimiaJAU.rUNSPVC },
      { id: 26, descricao: lang.tipoDeSinalArritimiaJAU.mfPVC },
      { id: 27, descricao: lang.tipoDeSinalArritimiaJAU.sVTA90 },
      { id: 28, descricao: lang.tipoDeSinalArritimiaJAU.mTV140 },
      { id: 29, descricao: lang.tipoDeSinalArritimiaJAU.mTV160 },
      { id: 30, descricao: lang.tipoDeSinalArritimiaJAU.pTV140 },
      { id: 31, descricao: lang.tipoDeSinalArritimiaJAU.pTV160 },
      { id: 32, descricao: lang.tipoDeSinalArritimiaJAU.bRD },
   ];
   return result;
};

export const getTiposDeSinalArritimiaJAU = (lang) => {
   let result = [
      { id: 9, descricao: lang.tipoDeSinalArritimiaJAU.afib },
      { id: 10, descricao: lang.tipoDeSinalArritimiaJAU.vfib },
      { id: 11, descricao: lang.tipoDeSinalArritimiaJAU.fVF },
      { id: 12, descricao: lang.tipoDeSinalArritimiaJAU.vtach },
      { id: 13, descricao: lang.tipoDeSinalArritimiaJAU.bV },
      { id: 14, descricao: lang.tipoDeSinalArritimiaJAU.bVL },
      { id: 15, descricao: lang.tipoDeSinalArritimiaJAU.bVR },
      { id: 16, descricao: lang.tipoDeSinalArritimiaJAU.sBID },
      { id: 17, descricao: lang.tipoDeSinalArritimiaJAU.tV1 },
      { id: 18, descricao: lang.tipoDeSinalArritimiaJAU.tV2 },
      { id: 19, descricao: lang.tipoDeSinalArritimiaJAU.bV2 },
      { id: 20, descricao: lang.tipoDeSinalArritimiaJAU.pAC },
      { id: 21, descricao: lang.tipoDeSinalArritimiaJAU.bLKII },
      { id: 22, descricao: lang.tipoDeSinalArritimiaJAU.pVCSTD },
      { id: 23, descricao: lang.tipoDeSinalArritimiaJAU.ePVC },
      { id: 24, descricao: lang.tipoDeSinalArritimiaJAU.pVCRonT },
      { id: 25, descricao: lang.tipoDeSinalArritimiaJAU.rUNSPVC },
      { id: 26, descricao: lang.tipoDeSinalArritimiaJAU.mfPVC },
      { id: 27, descricao: lang.tipoDeSinalArritimiaJAU.sVTA90 },
      { id: 28, descricao: lang.tipoDeSinalArritimiaJAU.mTV140 },
      { id: 29, descricao: lang.tipoDeSinalArritimiaJAU.mTV160 },
      { id: 30, descricao: lang.tipoDeSinalArritimiaJAU.pTV140 },
      { id: 31, descricao: lang.tipoDeSinalArritimiaJAU.pTV160 },
      { id: 32, descricao: lang.tipoDeSinalArritimiaJAU.bRD },
   ];
   return result;
};

export const getTiposDeSinalArritimiaSMP = (lang) => {
   let result = [
      { id: 9, descricao: lang.tipoDeSinalArritimiaJAU.afib },
      { id: 10, descricao: lang.tipoDeSinalArritimiaJAU.vfib },
      { id: 11, descricao: lang.tipoDeSinalArritimiaJAU.fVF },
      { id: 12, descricao: lang.tipoDeSinalArritimiaJAU.vtach },
      { id: 13, descricao: lang.tipoDeSinalArritimiaJAU.bV },
      { id: 14, descricao: lang.tipoDeSinalArritimiaJAU.bVL },
      { id: 15, descricao: lang.tipoDeSinalArritimiaJAU.bVR },
      { id: 16, descricao: lang.tipoDeSinalArritimiaJAU.sBID },
      { id: 17, descricao: lang.tipoDeSinalArritimiaJAU.tV1 },
      { id: 18, descricao: lang.tipoDeSinalArritimiaJAU.tV2 },
      { id: 19, descricao: lang.tipoDeSinalArritimiaJAU.bV2 },
      { id: 20, descricao: lang.tipoDeSinalArritimiaJAU.pAC },
      { id: 21, descricao: lang.tipoDeSinalArritimiaJAU.bLKII },
      { id: 22, descricao: lang.tipoDeSinalArritimiaJAU.pVCSTD },
      { id: 23, descricao: lang.tipoDeSinalArritimiaJAU.ePVC },
      { id: 24, descricao: lang.tipoDeSinalArritimiaJAU.pVCRonT },
      { id: 25, descricao: lang.tipoDeSinalArritimiaJAU.rUNSPVC },
      { id: 26, descricao: lang.tipoDeSinalArritimiaJAU.mfPVC },
      { id: 27, descricao: lang.tipoDeSinalArritimiaJAU.sVTA90 },
      { id: 28, descricao: lang.tipoDeSinalArritimiaJAU.mTV140 },
      { id: 29, descricao: lang.tipoDeSinalArritimiaJAU.mTV160 },
      { id: 30, descricao: lang.tipoDeSinalArritimiaJAU.pTV140 },
      { id: 31, descricao: lang.tipoDeSinalArritimiaJAU.pTV160 },
      { id: 32, descricao: lang.tipoDeSinalArritimiaJAU.bRD },
   ];
   return result;
};

export const getTiposDeSinalSenoideJAU = (lang) => {
   let result = [
      { id: 100, descricao: lang.tipoDeSinalSenoideJAU.off },
      { id: 101, descricao: lang.tipoDeSinalSenoideJAU.senoide025 },
      { id: 102, descricao: lang.tipoDeSinalSenoideJAU.senoide05 },
      { id: 103, descricao: lang.tipoDeSinalSenoideJAU.senoide1 },
      { id: 104, descricao: lang.tipoDeSinalSenoideJAU.senoide125 },
      { id: 105, descricao: lang.tipoDeSinalSenoideJAU.senoide2 },
      { id: 106, descricao: lang.tipoDeSinalSenoideJAU.senoide2_5 },
      { id: 107, descricao: lang.tipoDeSinalSenoideJAU.senoide5 },
      { id: 108, descricao: lang.tipoDeSinalSenoideJAU.senoide10 },
      { id: 109, descricao: lang.tipoDeSinalSenoideJAU.senoide15 },
      { id: 110, descricao: lang.tipoDeSinalSenoideJAU.senoide20 },
      { id: 111, descricao: lang.tipoDeSinalSenoideJAU.senoide25 },
      { id: 112, descricao: lang.tipoDeSinalSenoideJAU.senoide30 },
      { id: 113, descricao: lang.tipoDeSinalSenoideJAU.senoide35 },
      { id: 114, descricao: lang.tipoDeSinalSenoideJAU.senoide50 },
      { id: 115, descricao: lang.tipoDeSinalSenoideJAU.senoide60 },
      { id: 116, descricao: lang.tipoDeSinalSenoideJAU.senoide100 },
      { id: 117, descricao: lang.tipoDeSinalSenoideJAU.quadrada2 },
      { id: 118, descricao: lang.tipoDeSinalSenoideJAU.quadrada10 },
      { id: 119, descricao: lang.tipoDeSinalSenoideJAU.triangular2 },
      { id: 120, descricao: lang.tipoDeSinalSenoideJAU.triangular10 },
   ];
   return result;
};

export const getTiposDeSinalSenoideSMP = (lang) => {
   let result = [
      { id: 100, descricao: lang.tipoDeSinalSenoideJAU.off },
      { id: 101, descricao: lang.tipoDeSinalSenoideJAU.senoide025 },
      { id: 102, descricao: lang.tipoDeSinalSenoideJAU.senoide05 },
      { id: 103, descricao: lang.tipoDeSinalSenoideJAU.senoide1 },
      { id: 104, descricao: lang.tipoDeSinalSenoideJAU.senoide125 },
      { id: 105, descricao: lang.tipoDeSinalSenoideJAU.senoide2 },
      { id: 106, descricao: lang.tipoDeSinalSenoideJAU.senoide2_5 },
      { id: 107, descricao: lang.tipoDeSinalSenoideJAU.senoide5 },
      { id: 108, descricao: lang.tipoDeSinalSenoideJAU.senoide10 },
      { id: 109, descricao: lang.tipoDeSinalSenoideJAU.senoide15 },
      { id: 110, descricao: lang.tipoDeSinalSenoideJAU.senoide20 },
      { id: 111, descricao: lang.tipoDeSinalSenoideJAU.senoide25 },
      { id: 112, descricao: lang.tipoDeSinalSenoideJAU.senoide30 },
      { id: 113, descricao: lang.tipoDeSinalSenoideJAU.senoide35 },
      { id: 114, descricao: lang.tipoDeSinalSenoideJAU.senoide50 },
      { id: 115, descricao: lang.tipoDeSinalSenoideJAU.senoide60 },
      { id: 116, descricao: lang.tipoDeSinalSenoideJAU.senoide100 },
      { id: 117, descricao: lang.tipoDeSinalSenoideJAU.quadrada2 },
      { id: 118, descricao: lang.tipoDeSinalSenoideJAU.quadrada10 },
      { id: 119, descricao: lang.tipoDeSinalSenoideJAU.triangular2 },
      { id: 120, descricao: lang.tipoDeSinalSenoideJAU.triangular10 },
   ];
   return result;
};

export const getTiposDeUnidadeDeDesvioJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioJAU.joules },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioJAU.porcentagem },
   ];
   return result;
};

export const getTiposDeArritimiaJAU = (lang) => {
   let result = [{ id: 1, descricao: lang.tipoDeArritimiaJAU.mvpp10 }];
   return result;
};

export const getTiposDeArritmiasPerformanceJAU = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeArritmiasPerformanceJAU.arritmias },
      { id: 2, descricao: lang.tipoDeArritmiasPerformanceJAU.perfomance },
   ];
   return result;
};

export const getTiposDeArritmiasPerformanceSMP = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeArritmiasPerformanceJAU.arritmias },
      { id: 2, descricao: lang.tipoDeArritmiasPerformanceJAU.perfomance },
   ];
   return result;
};

export const getTiposDeAmplitudeJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeAmplitudeJAU.mvpp05 },
      { id: 1, descricao: lang.tipoDeAmplitudeJAU.mvpp10 },
      { id: 2, descricao: lang.tipoDeAmplitudeJAU.mvpp15 },
      { id: 3, descricao: lang.tipoDeAmplitudeJAU.mvpp20 },
      { id: 4, descricao: lang.tipoDeAmplitudeJAU.mvpp25 },
   ];
   return result;
};

export const getTiposDeAmplitudeSMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeAmplitudeJAU.mvpp05 },
      { id: 1, descricao: lang.tipoDeAmplitudeJAU.mvpp10 },
      { id: 2, descricao: lang.tipoDeAmplitudeJAU.mvpp15 },
      { id: 3, descricao: lang.tipoDeAmplitudeJAU.mvpp20 },
      { id: 4, descricao: lang.tipoDeAmplitudeJAU.mvpp25 },
   ];
   return result;
};

export const getTiposDeAmplitudeSIM = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeAmplitudeJAU.mvpp05 },
      { id: 1, descricao: lang.tipoDeAmplitudeJAU.mvpp10 },
      { id: 2, descricao: lang.tipoDeAmplitudeJAU.mvpp15 },
   ];
   return result;
};

export const getTiposDeTipoDeSinalSicronismoJAU = (lang) => {
   let result = [{ id: 5, descricao: lang.cadastroDeSequenciaJAU.tipoDeSinalECG }];
   return result;
};

export const getTiposDeTipoDeSinalSicronismoSMP = (lang) => {
   let result = [{ id: 5, descricao: lang.cadastroDeSequenciaJAU.tipoDeSinalECG }];
   return result;
};

export const getTiposDeUnidadeDeDesvioECGFrequenciaJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioJAU.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioJAU.ecgFrequencia },
   ];
   return result;
};

export const getTiposDeAmplitudeRespiracaoJAU = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeAmplitudeRespiracaoJAU.amplitude1 },
      { id: 2, descricao: lang.tipoDeAmplitudeRespiracaoJAU.amplitude2 },
      { id: 3, descricao: lang.tipoDeAmplitudeRespiracaoJAU.amplitude3 },
   ];
   return result;
};

export const getTiposDeAmplitudeRespiracaoSMP = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeAmplitudeRespiracaoJAU.amplitude1 },
      { id: 2, descricao: lang.tipoDeAmplitudeRespiracaoJAU.amplitude2 },
      { id: 3, descricao: lang.tipoDeAmplitudeRespiracaoJAU.amplitude3 },
   ];
   return result;
};

export const getTiposDeTaxaDeInspJAU = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp1 },
      { id: 2, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp2 },
      { id: 3, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp3 },
      { id: 4, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp4 },
      { id: 5, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp5 },
   ];
   return result;
};

export const getTiposDeTaxaDeInspSMP = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp1 },
      { id: 2, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp2 },
      { id: 3, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp3 },
      { id: 4, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp4 },
      { id: 5, descricao: lang.tipoDeTaxaDeInspJAU.taxaDeInsp5 },
   ];
   return result;
};

export const getTiposDeUnidadeDeDesvioRespiracaoJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioRespiracaoJAU.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioRespiracaoJAU.ohms },
   ];
   return result;
};

export const getTiposDeUnidadeDeDesvioRespiracaoBRPMJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioRespiracaoJAU.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioRespiracaoJAU.brpm },
   ];
   return result;
};
export const getTiposDeUnidadeDeDesvioECGAmplitudeJAU = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioJAU.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioJAU.ecgAmplitude },
   ];
   return result;
};

export const getTiposDeTestesSIM = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesSIM.ecgFrequencia },
      { id: 1, descricao: lang.tipoDeTestesSIM.ecgAmplitude },
      { id: 2, descricao: lang.tipoDeTestesSIM.arritmias_Performance },
      { id: 3, descricao: lang.tipoDeTestesSIM.respiracao_Frequencia },
      { id: 4, descricao: lang.tipoDeTestesSIM.respiracao_Amplitude },
      { id: 5, descricao: lang.tipoDeTestesSIM.respiracao_Apneia },
   ];
   return result;
};

export const getTiposDeTestesSMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesSMP.ecgFrequencia },
      { id: 1, descricao: lang.tipoDeTestesSMP.ecgAmplitude },
      { id: 2, descricao: lang.tipoDeTestesSMP.arritmias_Performance },
      { id: 3, descricao: lang.tipoDeTestesSMP.respiracao_Frequencia },
      { id: 4, descricao: lang.tipoDeTestesSMP.respiracao_Amplitude },
      { id: 5, descricao: lang.tipoDeTestesSMP.respiracao_Apneia },
      { id: 6, descricao: lang.tipoDeTestesSMP.temperatura },
      { id: 7, descricao: lang.tipoDeTestesSMP.pressaoInvasiva },
      { id: 8, descricao: lang.tipoDeTestesSMP.pressaoNaoInvasiva },
      { id: 9, descricao: lang.tipoDeTestesSMP.vazamento },
      { id: 10, descricao: lang.tipoDeTestesSMP.pressaoEstatica },
      { id: 11, descricao: lang.tipoDeTestesSMP.manometro },
      { id: 12, descricao: lang.cadastroDeSequenciaSOP.spO2_Saturacao },
      { id: 13, descricao: lang.cadastroDeSequenciaSOP.spO2_Freq },
   ];
   return result;
};

export const getTiposDeTestesSPK = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesSMP.ecgFrequencia },
      { id: 1, descricao: lang.tipoDeTestesSMP.ecgAmplitude },
      { id: 2, descricao: lang.tipoDeTestesSMP.arritmias_Performance },
      { id: 3, descricao: lang.tipoDeTestesSMP.respiracao_Frequencia },
      { id: 4, descricao: lang.tipoDeTestesSMP.respiracao_Amplitude },
      { id: 5, descricao: lang.tipoDeTestesSMP.respiracao_Apneia },
      { id: 6, descricao: lang.tipoDeTestesSMP.temperatura },
      { id: 7, descricao: lang.tipoDeTestesSMP.pressaoInvasiva },
      { id: 9, descricao: lang.tipoDeTestesSMP.vazamento },
      { id: 10, descricao: lang.tipoDeTestesSMP.pressaoEstatica },
      { id: 11, descricao: lang.tipoDeTestesSMP.manometro },
      { id: 12, descricao: lang.cadastroDeSequenciaSOP.spO2_Saturacao },
      { id: 13, descricao: lang.cadastroDeSequenciaSOP.spO2_Freq },
   ];
   return result;
};

export const getASE_TipoDeTeste = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesASE.resistenciaDeTerra },
      { id: 5, descricao: lang.tipoDeTestesASE.redeDeTensao },
      { id: 6, descricao: lang.tipoDeTestesASE.redeDeCorrente },
      { id: 1, descricao: lang.tipoDeTestesASE.resistenciaDeIsolacao },
      { id: 2, descricao: lang.tipoDeTestesASE.correnteDeFugaManual },
      { id: 3, descricao: lang.tipoDeTestesASE.correnteDeFugaNormal },
   ];
   return result;
};

export const getTiposDeNormaASE = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeNormaASE.nenhuma },
      { id: 1, descricao: lang.tipoDeNormaASE.norma62353 },
      { id: 2, descricao: lang.tipoDeNormaASE.norma60601 },
   ];
   return result;
};

export const getTiposDeNorma2ASE = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeNormaASE.nenhuma },
      { id: 1, descricao: lang.tipoDeNormaASE.norma62353 },
   ];
   return result;
};

export const getTiposDeNorma3ASE = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeNormaASE.norma62353 },
      { id: 2, descricao: lang.tipoDeNormaASE.norma60601 },
   ];
   return result;
};

export const getTiposDeUnidadeDeDesvioASE_RedeDeTensao = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeUnidadeDeDesvioRedeDeTensaoASE.porcentagem },
      { id: 1, descricao: lang.tipoDeUnidadeDeDesvioRedeDeTensaoASE.tensao },
   ];
   return result;
};

export const getASE_ResistenciaDeIsolacao_MD1 = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeFasesASE.faseNeutro },
      { id: 1, descricao: lang.tipoDeFasesASE.partesAplicadas },
   ];
   return result;
};

export const getASE_ResistenciaDeIsolacao_MD2 = (lang) => {
   let result = [
      { id: 2, descricao: lang.tipoDeMDSEDM2ASE.aterramentoDeProtecao },
      { id: 3, descricao: lang.tipoDeMDSEDM2ASE.pontaKelvin },
      { id: 1, descricao: lang.tipoDeMDSEDM2ASE.parteAplicadaTodas },
   ];
   return result;
};

export const getTiposDeFigurasASE_NORMA_62353 = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeFigurasASE.figura6 },
      { id: 1, descricao: lang.tipoDeFigurasASE.figura7 },
      { id: 2, descricao: lang.tipoDeFigurasASE.figura9 },
      { id: 3, descricao: lang.tipoDeFigurasASE.figura10 },
      { id: 4, descricao: lang.tipoDeFigurasASE.figura11 },
   ];
   return result;
};

export const getTiposDeFigurasASE_NORMA_60601 = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeFigurasASE.figura13 },
      { id: 1, descricao: lang.tipoDeFigurasASE.figura14 },
      { id: 2, descricao: lang.tipoDeFigurasASE.figura15 },
      { id: 3, descricao: lang.tipoDeFigurasASE.figura16 },
      { id: 4, descricao: lang.tipoDeFigurasASE.figura17 },
      { id: 5, descricao: lang.tipoDeFigurasASE.figura18 },
      { id: 6, descricao: lang.tipoDeFigurasASE.figura19 },
      { id: 7, descricao: lang.tipoDeFigurasASE.figura20 },
   ];
   return result;
};

export const getTiposDeParteAplicadasPAASE = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeParteAplicadasPAASE.todas },
      { id: 1, descricao: lang.tipoDeParteAplicadasPAASE.paPa1 },
      { id: 2, descricao: lang.tipoDeParteAplicadasPAASE.paPa2 },
      { id: 3, descricao: lang.tipoDeParteAplicadasPAASE.paPa3 },
      { id: 4, descricao: lang.tipoDeParteAplicadasPAASE.paPa4 },
      { id: 5, descricao: lang.tipoDeParteAplicadasPAASE.paPa5 },
      { id: 6, descricao: lang.tipoDeParteAplicadasPAASE.paPa6 },
      { id: 7, descricao: lang.tipoDeParteAplicadasPAASE.paPa7 },
      { id: 8, descricao: lang.tipoDeParteAplicadasPAASE.paPa8 },
      { id: 9, descricao: lang.tipoDeParteAplicadasPAASE.paPa9 },
      { id: 10, descricao: lang.tipoDeParteAplicadasPAASE.paPa10 },
   ];
   return result;
};

export const getTiposDeDinamicaSMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeDinamica.dinamicaArterial },
      { id: 1, descricao: lang.tipoDeDinamica.dinamicaArterialRadial },
      { id: 2, descricao: lang.tipoDeDinamica.dinamicaArterialVentriculoEsquerdo },
      { id: 3, descricao: lang.tipoDeDinamica.dinamicaArterialAtrio },
      { id: 4, descricao: lang.tipoDeDinamica.dinamicaArterialVentriculoDireito },
      { id: 5, descricao: lang.tipoDeDinamica.dinamicaArterialAtrioDireito },
      { id: 6, descricao: lang.tipoDeDinamica.dinamicaArterialArteriaPulmonar },
      { id: 7, descricao: lang.tipoDeDinamica.dinamicaArterialCunhaArteria },
      { id: 8, descricao: lang.tipoDeDinamica.dinamicaEstatica },
   ];
   return result;
};

export const getTiposDeSensibilidadeSMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeSensibilidadeSMP.sensibilidade1 },
      { id: 1, descricao: lang.tipoDeSensibilidadeSMP.sensibilidade2 },
   ];
   return result;
};
export const getTiposDeFaixaEtariaSMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeFaixaEtariaSMP.adulto },
      { id: 1, descricao: lang.tipoDeFaixaEtariaSMP.neonatal },
   ];
   return result;
};
export const getTiposDeFaixaEtariaCanalSMP = (lang) => {
   let result = [
      { id: 1, descricao: lang.tipoDeFaixaEtariaSMP.canal1 },
      { id: 2, descricao: lang.tipoDeFaixaEtariaSMP.canal2 },
   ];
   return result;
};

export const getTiposDeMDASECorrenteDeFugaManual = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA },
      { id: 1, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA1 },
      { id: 2, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA2 },
      { id: 3, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA3 },
      { id: 4, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA4 },
      { id: 5, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA5 },
      { id: 6, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA6 },
      { id: 7, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA7 },
      { id: 8, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA8 },
      { id: 9, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA9 },
      { id: 10, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA10 },
      { id: 11, descricao: lang.tipoDeMDSEDMASE.parteAplicadaTodas },
      { id: 12, descricao: lang.tipoDeMDSEDMASE.pontaKelvin },
      { id: 13, descricao: lang.tipoDeMDSEDMASE.aterramentoDeProtecao },
      { id: 14, descricao: lang.tipoDeMDSEDMASE.aterramentoFuncional01 },
      { id: 15, descricao: lang.tipoDeMDSEDMASE.aterramentoFuncional02 },
   ];
   return result;
};

export const getTiposDeMD2ASECorrenteDeFugaManual = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA },
      { id: 1, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA1 },
      { id: 2, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA2 },
      { id: 3, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA3 },
      { id: 4, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA4 },
      { id: 5, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA5 },
      { id: 6, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA6 },
      { id: 7, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA7 },
      { id: 8, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA8 },
      { id: 9, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA9 },
      { id: 10, descricao: lang.tipoDeMDSEDMASE.parteAplicadaPA10 },
      { id: 11, descricao: lang.tipoDeMDSEDMASE.parteAplicadaTodas },
      { id: 12, descricao: lang.tipoDeMDSEDMASE.pontaKelvin },
      { id: 13, descricao: lang.tipoDeMDSEDMASE.aterramentoDeProtecao },
      { id: 14, descricao: lang.tipoDeMDSEDMASE.aterramentoFuncional01 },
      { id: 15, descricao: lang.tipoDeMDSEDMASE.aterramentoFuncional02 },
   ];
   return result;
};

export const getFonteDeTensaoExtASECorrenteDeFugaManual = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeFonteDeTensaoExtASE.desligado },
      { id: 1, descricao: lang.tipoDeFonteDeTensaoExtASE.ligado },
      { id: 2, descricao: lang.tipoDeFonteDeTensaoExtASE.emSerieComMD },
   ];
   return result;
};

export const getTensaoExtASECorrenteDeFugaManual = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeFonteDeTensaoExtASE.direto },
      { id: 1, descricao: lang.tipoDeFonteDeTensaoExtASE.reverso },
   ];
   return result;
};

export const getTiposDePAEKelvinASE = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDePAEKelvinASE.aberto },
      { id: 1, descricao: lang.tipoDePAEKelvinASE.ligadoAoTE },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_ECG_FREQUENCIA.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_ECG_FREQUENCIA.bpm },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_ECG_FREQUENCIA = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_ECG_FREQUENCIA.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_ECG_FREQUENCIA.bpm },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_ECG_AMPLITUDE = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_ECG_AMPLITUDE.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_ECG_AMPLITUDE.mv },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_RESPIRACAO_FREQUENCIA = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_RESPIRACAO_FREQUENCIA.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_RESPIRACAO_FREQUENCIA.brpm },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_APNEIA = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_APNEIA.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_APNEIA.ohms },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_RESPIRACAO_AMPLITUDE = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_RESPIRACAO_AMPLITUDE.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_RESPIRACAO_AMPLITUDE.ohms },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_TEMPERATURA = (lang) => {
   let result = [{ id: 0, descricao: lang.unidadeDesvioSMP_TEMPERATURA.porcentagem }];
   return result;
};

export const getUnidadeDeDesvioSMP_PRESSAO_INVASIVA = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_PRESSAO_INVASIVA.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_PRESSAO_INVASIVA.mmhgMilimetroDeMercurio },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_PRESSAO_NAO_INVASIVA = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_PRESSAO_NAO_INVASIVA.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_PRESSAO_NAO_INVASIVA.mmhgMilimetroDeMercurio },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_PRESSAO_ESTATICA = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_PRESSAO_ESTATICA.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_PRESSAO_ESTATICA.mmhgMilimetroDeMercurio },
   ];
   return result;
};

export const getUnidadeDeDesvioSMP_MANOMETRO = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeDesvioSMP_MANOMETRO.porcentagem },
      { id: 1, descricao: lang.unidadeDesvioSMP_MANOMETRO.mmhgMilimetroDeMercurio },
   ];
   return result;
};

export const getAmplitudeSMPPerformance = (lang) => {
   let result = [
      { id: 0, descricao: lang.amplitudeSMPArritmiaPerformance.mvpp05 },
      { id: 1, descricao: lang.amplitudeSMPArritmiaPerformance.mvpp10 },
      { id: 2, descricao: lang.amplitudeSMPArritmiaPerformance.mvpp15 },
      { id: 3, descricao: lang.amplitudeSMPArritmiaPerformance.mvpp20 },
      { id: 4, descricao: lang.amplitudeSMPArritmiaPerformance.mvpp25 },
   ];
   return result;
};

export const getAmplitudeSMPArritmia = (lang) => {
   let result = [{ id: 1, descricao: lang.amplitudeSMPArritmiaPerformance.mvpp10 }];
   return result;
};

export const getTiposDeTestesAQT = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoDeTestesAQT.vazamento },
      { id: 1, descricao: lang.tipoDeTestesAQT.distribuicaoDeTemperatura },
      { id: 2, descricao: lang.tipoDeTestesAQT.penetracaoDeTemperatura },
      { id: 3, descricao: lang.tipoDeTestesAQT.aberturaDePorta },
      { id: 4, descricao: lang.tipoDeTestesAQT.quedaDeEnergia },
      { id: 5, descricao: lang.tipoDeTestesAQT.manual },
      { id: 6, descricao: lang.tipoDeTestesAQT.pressaoDinamica },
      { id: 7, descricao: lang.tipoDeTestesAQT.ciclosAjustaveis },
      { id: 8, descricao: lang.tipoDeTestesAQT.textoIntroducao },
      { id: 9, descricao: lang.tipoDeTestesAQT.textoNormas },
      { id: 10, descricao: lang.tipoDeTestesAQT.textoTermos },
      { id: 11, descricao: lang.tipoDeTestesAQT.textoObjetivos },
      { id: 12, descricao: lang.tipoDeTestesAQT.textoProcedimento },
      { id: 13, descricao: lang.tipoDeTestesAQT.textoCritetioDeAceitacao },
      { id: 14, descricao: lang.tipoDeTestesAQT.textoConclusao },
      { id: 15, descricao: lang.tipoDeTestesAQT.textoIndicador },
      { id: 16, descricao: lang.tipoDeTestesAQT.textoEquacao },
   ];
   return result;
};

export const getNormasAQT_Vazamento = (lang) => {
   let result = [
      { id: 0, descricao: lang.normasAQT.nenhuma },
      { id: 1, descricao: lang.normasAQT.norma17665 },
      { id: 2, descricao: lang.normasAQT.norma16328 },
   ];
   return result;
};

export const getUnidadeMedidaAQT_Vazamento = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeMedidaAQT.kpa },
      { id: 1, descricao: lang.unidadeMedidaAQT.mmhg },
      { id: 2, descricao: lang.unidadeMedidaAQT.cmh2O },
   ];
   return result;
};

export const getNormasAQT_DistribuicaoDeTemperatura = (lang) => {
   let result = [
      { id: 0, descricao: lang.normasAQT.nenhuma },
      { id: 1, descricao: lang.normasAQT.norma17665 },
      { id: 2, descricao: lang.normasAQT.norma16328 },
   ];
   return result;
};

export const getNormasAQT_PressaoDinamica = (lang) => {
   let result = [
      { id: 0, descricao: lang.normasAQT.nenhuma },
      { id: 1, descricao: lang.normasAQT.norma17665 },
   ];
   return result;
};

export const getUnidadeMedidaAQT_PressaoDinamica = (lang) => {
   let result = [
      { id: 0, descricao: lang.unidadeMedidaAQT.kpa },
      { id: 1, descricao: lang.unidadeMedidaAQT.mmhg },
      { id: 2, descricao: lang.unidadeMedidaAQT.cmh2O },
   ];
   return result;
};

export const getTipoEquipamentoAQT = (lang) => {
   let result = [
      { id: 0, descricao: lang.tipoEquipamentoAQT.autoclaves },
      { id: 1, descricao: lang.tipoEquipamentoAQT.lavadorasEDesinfetadoras },
      { id: 2, descricao: lang.tipoEquipamentoAQT.estufasDeEsterilizacaoEDespirogenizacao },
      { id: 3, descricao: lang.tipoEquipamentoAQT.estufasDeIncubacao },
      { id: 4, descricao: lang.tipoEquipamentoAQT.refrigeradoresCongeladoresECamarasFrias },
      { id: 5, descricao: lang.tipoEquipamentoAQT.camarasClimaticas },
   ];
   return result;
};

export const getTiposDeTipoDeSinalJAUECG = (lang) => {
   let result = [{ id: 0, descricao: 'ECG' }];
   return result;
};
