import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Button as BoottrapButton } from 'react-bootstrap';
import '../../../contents/css/button-input.css';
import { LayoutParams } from '../../../config/LayoutParams';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import IconButton from '../../../components/IconButton';

export default class RelatoriosView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   render() {
      return (
         <div>
            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
               }}
            >
               <div style={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, height: 45, display: 'flex' }}>
                  <div
                     style={{
                        display: 'table-cell',
                        width: '0',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{this.props.lang.relatorios.titulo}</span>
                  </div>
               </div>
            </div>
            <div style={{ textAlign: 'center', maxWidth: 800, margin: 'auto' }}>
               <CustomButton rota='/equipamento-de-cliente-report' descricao='Equipamentos de Cliente' />
               <CustomButton rota='/utilizacao-report' descricao='Relatório de Utilização' />
               <CustomButton rota='/certificados-a-vencer-report' descricao='Relatório de Certificados a Vencer' />
            </div>
         </div>
      );
   }
}

const CustomButton = ({ rota, descricao }) => {
   return (
      <Link to={rota}>
         <BoottrapButton
            style={{
               height: 80,
               width: 200,
               margin: 4,
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               borderBlockColor: LayoutParams.colors.corSecundaria,
               color: LayoutParams.colors.corSecundaria,
               borderColor: LayoutParams.colors.corSecundaria,
            }}
         >
            <div>
                <div><IconButton icon={faChartBar}/> </div>
               <div>{descricao}</div>
            </div>
         </BoottrapButton>
      </Link>
   );
};
