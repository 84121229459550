import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Button from '../components/Button';
import CheckBox from '../components/CheckBox';
import { Filler } from '../components/Filler';
import { showConfirm } from '../components/Messages';
import TextArea from '../components/TextArea';

export default class TermosDeUsoView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         termosDeUso: null,
         iniciado: false,
      };
   }

   componentDidMount() {
      this.props.api.get('/login/termosdeuso').then((result) => {
         this.setState({ termosDeUso: result.termo, iniciado: true });
      });
   }

   render() {
      return this.state.iniciado ? (
         <Container className='container-fluid'>
            <Row>
               <Col>
                  <Filler height={5} />
                  <TextArea readOnly defaultValue={this.state.termosDeUso} />
               </Col>
            </Row>
            <br />
            <Row>
               <Col>
                  <CheckBox
                     name='aceitarTermosDeUso' 
                     label={this.props.lang.telaTermosDeUso.confirmoQueLiEAceitoOsTermosDeUso}
                     onChange={(checked) => {
                        this.setState({ aceitouTermosDeUso: checked });
                     }}
                  />
               </Col>
            </Row>
            <br />
            <Row>
               <Col md={5} lg={3} xl={3}>
                  <Button
                     disabled={!this.state.aceitouTermosDeUso}
                     text={this.props.lang.telaTermosDeUso.aceitarTermosDeUso}
                     inProgressText={this.props.lang.telaTermosDeUso.enviando}
                     onClickAsync={() =>
                        new Promise((resolve, reject) => {
                           showConfirm(
                              this.props.lang.telaTermosDeUso.confirmaRealmenteQueAceitaOsTermosDeUso,
                              () => {
                                 this.props.api
                                    .post('/login/aceitartermosdeuso')
                                    .then(() => {
                                       let login = this.props.sessionManager.getLogin();
                                       login.aceitouTermosDeUso = true;
                                       this.props.sessionManager.setLogin(login);
                                       resolve();
                                       window.location = './';
                                    })
                                    .catch(reject);
                              },
                              () => reject(),
                              this.props.lang.sim,
                              this.props.lang.nao
                           );
                        })
                     }
                  />
               </Col>
            </Row>
         </Container>
      ) : (
         <div />
      );
   }
}
