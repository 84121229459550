import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from './../FormularioPadrao';
import DateInput from '../../components/DateInput';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import EquipamentoDeClienteController from '../../controllers/Cliente/EquipamentoDeClienteController';
import { updateState } from '../../utils/Functions';

export default class EquipamentoDeClienteView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true };
      this.controller = new EquipamentoDeClienteController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeEquipamento = lang.telaDeCadastroDeEquipamento;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col sm={4} md={4} lg={4}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeEquipamento.numeroDeSerie}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.numeroDeSerie}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.numeroDeSerie = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeEquipamento.numeroDePatrimonio}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.numeroDePatrimonio}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.numeroDePatrimonio = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col >
                  <Label>{telaDeCadastroDeEquipamento.dataDeFabricacao}</Label>
                  <DateInput
                     defaultValue={this.state.itemSelecionado.dataDeFabricacao}
                     onChange={(value) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.dataDeFabricacao = value;
                        });
                     }}
                  />
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeEquipamento.tipoDeEquipamento}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.tipoDeEquipamento}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.tipoDeEquipamento = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeEquipamento.modelo}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.modelo}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.modelo = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeEquipamento.fabricante}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.fabricante}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.fabricante = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeEquipamento.titulo}
            url={'/equipamentodecliente'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'numeroDeSerie'}
            permissoes={[1131, 1132, 1133, 1134]}
            getFiltro={this.controller.getFiltro}
            filtroExtra={this.props.filtroExtra}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               tipoDeEquipamento: [],
               cliente: this.props.cliente,
            }}
         />
      );
   }
}
