import React from 'react';
import { LayoutParams } from '../config/LayoutParams';
import './../contents/css/login-logo.css';

export const LogoLogin = () => {
   return (
      <div className='div-login-logo'>
         <img alt='' src={LayoutParams.imgLoginLogo} className='login-logo' />
      </div>
   );
};
