import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import { showConfirm } from '../../components/Messages';
import FormularioPadrao from './../FormularioPadrao';
import Label from '../../components/Label';
import Avatar from '../../components/Avatar';
import TextInput from '../../components/TextInput';
import ClienteController from '../../controllers/Empresa/ClienteController';
import { updateState, getEnderecoCompleto, buildQueryString, generatePassword } from '../../utils/Functions';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import CpfInput from '../../components/CpfInput';
import SubCadastro from '../../components/SubCadastro';
import CheckBox from '../../components/CheckBox';
import CnpjInput from '../../components/CnpjInput';
import CepInput from '../../components/CepInput';
import { IconeWhatsApp } from '../../components/IconeWhatsApp';
import CidadeView from './../CidadeView';
import { Filler } from '../../components/Filler';
import EmailInput from '../../components/EmailInput';
import PasswordInput from '../../components/PasswordInput';
import { faKey, faEraser, faTrash } from '@fortawesome/free-solid-svg-icons';
import PaisView from '../PaisView';

const url = '/cliente';

export default class ClienteView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarEndereco: true, url: url, enviarNovaSenhaPorEmail: false };
      this.controller = new ClienteController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeCliente = lang.telaDeCadastroDeCliente;

      return (
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col sm={2} xs={2} md={2}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.codigo}</Label>
                           <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.pais}</Label>
                           <Select
                              name={'pais'}
                              defaultValue={this.state.itemSelecionado.pais}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.pais = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return <PaisView api={this.props.api} select={select} lang={this.props.lang} />;
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) => '/pais' + buildQueryString(2, null, 'id', { Searchable: text })}
                           />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.situacao}</Label>
                           <Select
                              as='select'
                              name='situacao'
                              defaultValue={
                                 this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id
                              }
                              options={[
                                 {
                                    id: 1,
                                    descricao: lang.situacaoDeCliente.ativo,
                                 },
                                 {
                                    id: 2,
                                    descricao: lang.situacaoDeCliente.inativo,
                                 },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = i;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>

                     <Col sm={3} xs={3} md={3}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.dataDeCadastro}</Label>
                           <DateInput
                              defaultValue={
                                 this.state.itemSelecionado.dataDeCadastro
                                    ? this.state.itemSelecionado.dataDeCadastro
                                    : new Date()
                              }
                              onChange={(value) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.dataDeCadastro = value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  {this.state.itemSelecionado.tipoDePessoa && this.state.itemSelecionado.tipoDePessoa.id === 1 && (
                     <Row>
                        <Col sm={3} md={3} lg={3}>
                           <FormGroup>
                              <Label>{telaDeCadastroDeCliente.tipoDePessoa}</Label>
                              <Select
                                 defaultValue={
                                    this.state.itemSelecionado.tipoDePessoa
                                       ? this.state.itemSelecionado.tipoDePessoa.id
                                       : 0
                                 }
                                 options={[
                                    { id: 1, descricao: lang.tipoDePessoa.fisica },
                                    { id: 2, descricao: lang.tipoDePessoa.juridica },
                                 ]}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.tipoDePessoa = i;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <Label>{telaDeCadastroDeCliente.nome}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.nomeCompleto}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.nomeCompleto = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </Col>

                        <Col sm={3} md={3} lg={3}>
                           <Label>{telaDeCadastroDeCliente.apelido}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.apelido}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.apelido = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </Col>
                     </Row>
                  )}

                  {this.state.itemSelecionado.tipoDePessoa && this.state.itemSelecionado.tipoDePessoa.id === 2 && (
                     <Row>
                        <Col sm={3} md={3} lg={3}>
                           <FormGroup>
                              <Label>{telaDeCadastroDeCliente.tipoDePessoa}</Label>
                              <Select
                                 defaultValue={
                                    this.state.itemSelecionado.tipoDePessoa
                                       ? this.state.itemSelecionado.tipoDePessoa.id
                                       : 0
                                 }
                                 options={[
                                    { id: 1, descricao: lang.tipoDePessoa.fisica },
                                    { id: 2, descricao: lang.tipoDePessoa.juridica },
                                 ]}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.tipoDePessoa = i;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>

                        <Col>
                           <Label>{telaDeCadastroDeCliente.razaoSocial}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.razaoSocial}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.razaoSocial = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </Col>
                     </Row>
                  )}
               </Col>

               <Col style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20 }}>
                  <Avatar
                     lang={this.props.lang}
                     api={this.props.api}
                     image={this.state.itemSelecionado.foto}
                     onChange={(image) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.foto = image;
                        });
                     }}
                  />
               </Col>
            </Row>

            <Filler marginTop={10} />

            {this.state.itemSelecionado.tipoDePessoa && this.state.itemSelecionado.tipoDePessoa.id === 1 && (
               <React.Fragment>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.cpf + ' (Login)'}</Label>
                           <CpfInput
                              defaultValue={this.state.itemSelecionado.cpf}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.cpf = e.formattedValue;
                                 });
                              }}
                              onBlur={this.controller.verificarSeCpfJaExiste}
                           />
                        </FormGroup>
                     </Col>
                     <Col sm={4} md={4} lg={4}>
                        <FormGroup>
                           <Label>{this.props.lang.telaDeCadastroDeCliente.senha}</Label>
                           {!this.state.esconderSenha && (
                              <React.Fragment>
                                 <PasswordInput
                                    defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                                    readOnly={
                                       this.state.itemSelecionado.senha && !this.state.itemSelecionado.senhaAlterada
                                    }
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.itemSelecionado.senha = e.target.value;
                                          state.itemSelecionado.senhaAlterada = true;
                                          state.itemSelecionado.enviarNovaSenhaPorEmail = false;
                                       });
                                    }}
                                    appendIcon={this.state.itemSelecionado.senha ? faTrash : faKey}
                                    appendTitle={this.props.lang.gerarNovaSenha}
                                    onAppendClick={() => {
                                       if (this.state.itemSelecionado.senha) {
                                          showConfirm(
                                             this.props.lang.desejaLimparASenha,
                                             () => {
                                                updateState(this, (state) => {
                                                   state.itemSelecionado.senha = null;
                                                   state.itemSelecionado.senhaAlterada = true;
                                                   state.esconderSenha = true;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderSenha = false;
                                                   });
                                                });
                                             },
                                             null,
                                             this.props.lang.sim,
                                             this.props.lang.nao
                                          );
                                       } else {
                                          showConfirm(
                                             this.props.lang.desejaRealmenteGerarUmaNovaSenha,
                                             () => {
                                                updateState(this, (state) => {
                                                   state.itemSelecionado.senha = generatePassword(true, 8);
                                                   state.itemSelecionado.senhaAlterada = true;
                                                   state.itemSelecionado.enviarNovaSenhaPorEmail = true;
                                                   state.esconderSenha = true;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderSenha = false;
                                                   });
                                                });
                                             },
                                             null,
                                             this.props.lang.sim,
                                             this.props.lang.nao
                                          );
                                       }
                                    }}
                                 />
                                 {this.state.itemSelecionado.senhaAlterada && this.state.itemSelecionado.senha && (
                                    <CheckBox
                                       defaultChecked={
                                          this.state.itemSelecionado.enviarNovaSenhaPorEmail ? true : false
                                       }
                                       name='enviarNovaSenhaPorEmail'
                                       label={this.props.lang.enviarANovaSenhaPorEmail}
                                       onChange={(checked) => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.enviarNovaSenhaPorEmail = checked;
                                          });
                                       }}
                                    />
                                 )}
                              </React.Fragment>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.rg}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.documentoDeIdentidade}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.documentoDeIdentidade = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                     <Col sm={2} md={2} lg={2}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.orgaoExpedidor}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.orgaoExpedidorDoDocumentoDeIdentidade}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.orgaoExpedidorDoDocumentoDeIdentidade = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.dataDeNascimento}</Label>
                           <DateInput
                              defaultValue={this.state.itemSelecionado.dataDeNascimento}
                              onChange={(value) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.dataDeNascimento = value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.sexo}</Label>
                           <Select
                              defaultValue={this.state.itemSelecionado.sexo ? this.state.itemSelecionado.sexo.id : 0}
                              options={[
                                 { id: 1, descricao: lang.masculino },
                                 { id: 2, descricao: lang.feminino },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.sexo = i;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </React.Fragment>
            )}

            {this.state.itemSelecionado.tipoDePessoa && this.state.itemSelecionado.tipoDePessoa.id === 2 && (
               <React.Fragment>
                  <Row>
                     <Col>
                        <Label>{telaDeCadastroDeCliente.nomeFantasia}</Label>
                        <TextInput
                           defaultValue={this.state.itemSelecionado.nomeFantasia}
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.nomeFantasia = e.target.value;
                              });
                           }}
                           upperCase={true}
                        />
                     </Col>
                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>
                              {!this.state.itemSelecionado.pais || this.state.itemSelecionado.pais.codigo === '+55'
                                 ? telaDeCadastroDeCliente.cnpj
                                 : telaDeCadastroDeCliente.documentoDeIdentificacaoDaEmpresa}
                           </Label>

                           {!this.state.itemSelecionado.pais || this.state.itemSelecionado.pais.codigo === '+55' ? (
                              <CnpjInput
                                 defaultValue={this.state.itemSelecionado.cnpj}
                                 name={'tipoDeDocumento'}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.cnpj = e.formattedValue;
                                    });
                                 }}
                              />
                           ) : (
                              <TextInput
                                 defaultValue={this.state.itemSelecionado.cnpj}
                                 name={'tipoDeDocumento'}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.cnpj = e.target.value;
                                    });
                                 }}
                                 onBlur={this.controller.verificarSeCnpjJaExiste}
                              />
                           )}
                        </FormGroup>
                     </Col>
                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeCliente.senha}</Label>
                           {!this.state.esconderSenha && (
                              <React.Fragment>
                                 <PasswordInput
                                    defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.itemSelecionado.senha = e.target.value;
                                          state.itemSelecionado.senhaAlterada = true;
                                          state.itemSelecionado.enviarNovaSenhaPorEmail = false;
                                       });
                                    }}
                                    appendIcon={this.state.itemSelecionado.senha ? faEraser : faKey}
                                    appendTitle={
                                       this.state.itemSelecionado.senha
                                          ? telaDeCadastroDeCliente.limpar
                                          : telaDeCadastroDeCliente.gerarNovaSenha
                                    }
                                    onAppendClick={() => {
                                       if (this.state.itemSelecionado.senha) {
                                          showConfirm(
                                             this.props.lang.desejaLimparASenha,
                                             () => {
                                                updateState(this, (state) => {
                                                   state.itemSelecionado.senha = null;
                                                   state.itemSelecionado.senhaAlterada = true;
                                                });
                                             },
                                             () => {
                                                updateState(this, (state) => {
                                                   state.itemSelecionado.senhaAlterada = true;
                                                });
                                             },
                                             lang.sim,
                                             lang.nao
                                          );
                                       } else {
                                          showConfirm(
                                             this.props.lang.desejaLimparASenha,
                                             () => {
                                                updateState(this, (state) => {
                                                   state.itemSelecionado.senha = generatePassword(true, 8);
                                                   state.itemSelecionado.senhaAlterada = true;
                                                   state.itemSelecionado.enviarNovaSenhaPorEmail = true;
                                                   state.esconderSenha = true;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderSenha = false;
                                                   });
                                                });
                                             },
                                             null,
                                             lang.sim,
                                             lang.nao
                                          );
                                       }
                                    }}
                                 />
                                 {this.state.itemSelecionado.senhaAlterada && (
                                    <CheckBox
                                       defaultChecked={
                                          this.state.itemSelecionado.enviarNovaSenhaPorEmail ? true : false
                                       }
                                       name='enviarNovaSenhaPorEmail'
                                       label='Enviar senha por e-mail'
                                       onChange={(checked) => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.enviarNovaSenhaPorEmail = checked;
                                          });
                                       }}
                                    />
                                 )}
                              </React.Fragment>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>
               </React.Fragment>
            )}
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeCliente.email}</Label>
                     <EmailInput
                        defaultValue={this.state.itemSelecionado.email}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.email = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.telefones()}

            <br />

            {this.enderecos()}

            {/* <br />

            {this.filiais()}

            <br />

            {this.departamentos()} */}

            <br />

            {this.equipamentos()}

            <br />
         </React.Fragment>
      );
   };

   telefones = () => {
      let lang = this.props.lang;
      return (
         <SubCadastro
            titulo={lang.telefones}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.telefones}
            lang={lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = {};
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirTelefone}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.telefones[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarTelefone}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.telefones.splice(index, 1);
               });
            }}
            colunas={() => {
               return [{ titulo: '', width: '100%', className: null }];
            }}
            linha={(item) => {
               return [
                  <div style={{ display: 'flex' }}>
                     <div>{item.pais + ' ' + item.ddd + ' ' + item.numero + ' '} </div>
                     <div>{item.temWhatsApp ? <IconeWhatsApp key={'iconeWhatsApp'} /> : null}</div>
                  </div>,
               ];
            }}
            formulario={() => {
               return (
                  this.state.telefoneSelecionado && (
                     <div>
                        <Row>
                           <Col>
                              <div style={{ display: 'flex' }}>
                                 <FormGroup>
                                    <Label>{lang.telefone.pais}</Label>
                                    <TextInput
                                       name='pais'
                                       defaultValue={this.state.telefoneSelecionado.pais}
                                       style={{ width: '60px' }}
                                       maxLength={3}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.pais = e.target.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>

                                 <FormGroup>
                                    <Label>{lang.telefone.ddd}</Label>
                                    <TextInput
                                       name='ddd'
                                       defaultValue={this.state.telefoneSelecionado.ddd}
                                       style={{ width: '60px' }}
                                       maxLength={2}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.ddd = e.target.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                                 <FormGroup>
                                    <Label>{lang.telefone.numero}</Label>
                                    <TextInput
                                       name='telefone'
                                       defaultValue={this.state.telefoneSelecionado.numero}
                                       maxLength={9}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.numero = e.target.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                                 <FormGroup
                                    controlId='chktemWhatsApp'
                                    style={{
                                       margin: '37px auto auto',
                                       paddingLeft: '5px',
                                       fontWeight: 'normal',
                                       width: '100%',
                                    }}
                                 >
                                    <CheckBox
                                       defaultChecked={this.state.telefoneSelecionado.temWhatsApp ? true : false}
                                       name='WhatsApp'
                                       label='WhatsApp'
                                       onChange={(checked) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.temWhatsApp = checked;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  )
               );
            }}
         />
      );
   };

   enderecos = () => {
      let lang = this.props.lang;
      return (
         <SubCadastro
            titulo={lang.enderecos}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.enderecos}
            lang={lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = {
                        endereco: {},
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirEndereco}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.enderecos[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.inserirEndereco}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.enderecos.splice(index, 1);
               });
            }}
            colunas={() => {
               return [{ titulo: 'Endereço Completo', width: '100%', className: null }];
            }}
            linha={(item) => {
               return [getEnderecoCompleto(item.endereco)];
            }}
            formulario={() => {
               return this.state.mostrarEndereco && this.state.enderecoSelecionado ? (
                  <div>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{lang.endereco.pais}</Label>
                              <Select
                                 name={'pais'}
                                 defaultValue={this.state.enderecoSelecionado.endereco.pais}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.enderecoSelecionado.endereco.pais = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return <PaisView api={this.props.api} select={select} lang={this.props.lang} />;
                                 }}
                                 asws={true}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/pais' + buildQueryString(2, null, 'id', { Searchable: text })
                                 }
                              />
                           </FormGroup>
                        </Col>
                        {(!this.state.enderecoSelecionado.endereco.pais ||
                           this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                           <Fragment>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{lang.endereco.cep}</Label>
                                    <CepInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.cep}
                                       onChange={(value) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cep = value.formattedValue;
                                          });
                                       }}
                                       onBlur={() => {
                                          this.controller.consultarCep();
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{lang.endereco.logradouro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.logradouro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.logradouro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col style={{ maxWidth: 100 }}>
                                 <FormGroup>
                                    <Label>{lang.endereco.numero}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.numero}
                                       maxLength={10}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.numero = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{lang.endereco.complemento}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.complemento}
                                       maxLength={30}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.complemento = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                           </Fragment>
                        )}
                     </Row>
                     {(!this.state.enderecoSelecionado.endereco.pais ||
                        this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                        <Fragment>
                           <Row>
                              <Col>
                                 <FormGroup>
                                    <Label>{lang.endereco.bairro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.bairro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.bairro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{lang.endereco.cidade}</Label>
                                    <Select
                                       name={'cidade'}
                                       defaultValue={this.state.enderecoSelecionado.endereco.cidade}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cidade = i;
                                          });
                                       }}
                                       formularioPadrao={(select) => {
                                          return (
                                             <CidadeView api={this.props.api} select={select} lang={this.props.lang} />
                                          );
                                       }}
                                       noDropDown={true}
                                       readOnlyColor='#ffff'
                                       getFilterUrl={(text) =>
                                          '/cidade' + buildQueryString(2, null, 'id', { Searchable: text })
                                       }
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        </Fragment>
                     )}

                     {this.state.enderecoSelecionado.endereco.pais &&
                        this.state.enderecoSelecionado.endereco.pais.codigo !== '+55' && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.endereco.linha1}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha1}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha1 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.endereco.linha2}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha2 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.endereco.linha3}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha3 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                  </div>
               ) : (
                  <div />
               );
            }}
         />
      );
   };

   equipamentos = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeCliente = lang.telaDeCadastroDeCliente;
      return (
         <SubCadastro
            titulo={telaDeCadastroDeCliente.equipamentosDeCliente}
            itens={this.state.itemSelecionado.equipamentos}
            lang={lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.equipamentoSelecionado = {};
                  }).then(resolve);
               });
            }}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.equipamentoSelecionado = null;
                  }).then(resolve);
               });
            }}
            inserir={this.controller.inserirEquipamento}
            alterar={this.controller.alterarEquipamento}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.equipamentoSelecionado = state.itemSelecionado.equipamentos[index];
                  }).then(resolve);
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.equipamentos.splice(index, 1);
               });
            }}
            colunas={() => {
               return [
                  { titulo: 'Nº de série', width: '15%', className: null },
                  { titulo: 'Nº de patrimônio', width: '15%', className: null },
                  { titulo: 'Tipo de equipamento', width: '30%', className: null },
                  { titulo: 'Fabricante', width: '20%', className: null },
                  { titulo: 'Modelo', width: '20%', className: null },
               ];
            }}
            linha={(item) => {
               return [
                  item.numeroDeSerie,
                  item.numeroDePatrimonio,
                  item.tipoDeEquipamento,
                  item.fabricante,
                  item.modelo,
               ];
            }}
            formulario={() => {
               let telaDeCadastroDeEquipamento = this.props.lang.telaDeCadastroDeEquipamento;
               return (
                  <React.Fragment>
                     <Row>
                        <Col sm={5} md={5} lg={5}>
                           <FormGroup>
                              <Label>{telaDeCadastroDeEquipamento.numeroDeSerie}</Label>
                              <TextInput
                                 defaultValue={this.state.equipamentoSelecionado.numeroDeSerie}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.equipamentoSelecionado.numeroDeSerie = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>

                        <Col>
                           <FormGroup>
                              <Label>{telaDeCadastroDeEquipamento.numeroDePatrimonio}</Label>
                              <TextInput
                                 defaultValue={this.state.equipamentoSelecionado.numeroDePatrimonio}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.equipamentoSelecionado.numeroDePatrimonio = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>

                        <Col>
                           <Label>{telaDeCadastroDeEquipamento.dataDeFabricacao}</Label>
                           <DateInput
                              defaultValue={this.state.equipamentoSelecionado.dataDeFabricacao}
                              onChange={(value) => {
                                 updateState(this, (state) => {
                                    state.equipamentoSelecionado.dataDeFabricacao = value;
                                 });
                              }}
                           />
                        </Col>
                     </Row>

                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{telaDeCadastroDeEquipamento.tipoDeEquipamento}</Label>
                              <TextInput
                                 defaultValue={this.state.equipamentoSelecionado.tipoDeEquipamento}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.equipamentoSelecionado.tipoDeEquipamento = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>
                     </Row>

                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{telaDeCadastroDeEquipamento.modelo}</Label>
                              <TextInput
                                 defaultValue={this.state.equipamentoSelecionado.modelo}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.equipamentoSelecionado.modelo = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{telaDeCadastroDeEquipamento.fabricante}</Label>
                              <TextInput
                                 defaultValue={this.state.equipamentoSelecionado.fabricante}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.equipamentoSelecionado.fabricante = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>
                     </Row>

                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{telaDeCadastroDeEquipamento.filial}</Label>
                              <TextInput
                                 defaultValue={this.state.equipamentoSelecionado.filial}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.equipamentoSelecionado.filial = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{telaDeCadastroDeEquipamento.departamento}</Label>
                              <TextInput
                                 defaultValue={this.state.equipamentoSelecionado.departamento}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.equipamentoSelecionado.departamento = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                  </React.Fragment>
               );
            }}
         />
      );
   };

   filiais = () => {
      let lang = this.props.lang;
      return (
         <SubCadastro
            titulo={this.props.lang.telaDeCadastroDeFilial.filiais}
            itens={this.state.itemSelecionado.filiais}
            lang={lang}
            exibirTitulos={false}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.filialSelecionada = {};
                  }).then(resolve);
               });
            }}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.filialSelecionada = null;
                  }).then(resolve);
               });
            }}
            inserir={this.controller.inserirFilial}
            alterar={this.controller.alterarFilial}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.filialSelecionada = index !== 0 ? state.itemSelecionado.filiais[index] : null;
                  }).then(resolve);
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.filiais.splice(index, 1);
               });
            }}
            colunas={() => {
               return [
                  { titulo: 'Código', width: '20%', className: null },
                  { titulo: 'Nome', width: '80%', className: null },
               ];
            }}
            linha={(item) => {
               return [item.id, item.nome];
            }}
            formulario={() => {
               return (
                  <React.Fragment>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{this.props.lang.telaDeCadastroDeFilial.nome}</Label>
                              <TextInput
                                 defaultValue={this.state.filialSelecionada.nome}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.filialSelecionada.nome = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>
                     </Row>

                     <br />
                  </React.Fragment>
               );
            }}
         />
      );
   };

   departamentos = () => {
      let lang = this.props.lang;
      return (
         <SubCadastro
            titulo={this.props.lang.telaDeCadastroDeDepartamento.departamentos}
            itens={this.state.itemSelecionado.departamentos}
            lang={lang}
            exibirTitulos={false}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.departamentoSelecionado = {};
                  }).then(resolve);
               });
            }}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.departamentoSelecionado = null;
                  }).then(resolve);
               });
            }}
            inserir={this.controller.inserirDepartamento}
            alterar={this.controller.alterarDepartamento}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.departamentoSelecionado = index !== 0 ? state.itemSelecionado.departamentos[index] : null;
                  }).then(resolve);
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.departamentos.splice(index, 1);
               });
            }}
            colunas={() => {
               return [
                  { titulo: 'Código', width: '20%', className: null },
                  { titulo: 'Nome', width: '80%', className: null },
               ];
            }}
            linha={(item) => {
               return [item.id, item.nome];
            }}
            formulario={() => {
               return (
                  <React.Fragment>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{this.props.lang.telaDeCadastroDeDepartamento.nome}</Label>
                              <TextInput
                                 defaultValue={this.state.departamentoSelecionado.nome}
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.departamentoSelecionado.nome = e.target.value;
                                    });
                                 }}
                                 upperCase={true}
                              />
                           </FormGroup>
                        </Col>
                     </Row>

                     <br />
                  </React.Fragment>
               );
            }}
         />
      );
   };

   editar = (item) => {
      if (this.form) {
         this.form.editarClick(item);
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeCliente.titulo}
            url={url}
            fastUrl={'/cliente/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'NomeCompletoOuRazaoSocial'}
            permissoes={[1091, 1092, 1093, 1094]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            ref={(c) => (this.form = c)}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve, reject) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               telefones: [],
               enderecos: [],
               redesSociais: [],
               situacao: { id: 1 },
               tipoDePessoa: { id: 1 },
            }}
         />
      );
   }
}
