import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LayoutParams } from '../../config/LayoutParams';
import Button from '../../components/Button';
import './../../contents/css/login-logo.css';
import { Filler } from '../../components/Filler';
import { Link } from 'react-router-dom';

export default class SelectEnviromentView extends Component {
   render() {
      return (
         <Container
            fluid
            style={{
               fontSize: 15,
               height: '100%',
               display: 'flex',
               position: 'fixed',
               justifyContent: 'center',
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
            }}
         >
            <Row className='justify-content-md-center'>
               <Col
                  xs
                  lg='3'
                  style={{
                     minHeight: 424,
                     maxHeight: 424,
                     minWidth: 350,
                     maxWidth: 350,
                     borderRadius: 10,
                     color: 'white',
                     paddingTop: 20,
                  }}
               >
                  <br />
                  <div className='div-login-logo'>
                     <img alt='' src={LayoutParams.imgLoginLogo} className='login-logo' />
                  </div>
                  <div style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
                     <span>Tipo de acesso</span>
                  </div>
                  <Filler height={4} />
                  <Link to={'/neos'} tabIndex={-1}>
                     <Button text={this.props.lang.tipoDeAcesso.neos} />
                  </Link>
                  <Filler height={4} />
                  <Link to={'/empresa'} tabIndex={-1}>
                     <Button text={this.props.lang.tipoDeAcesso.empresa} />
                  </Link>
                  <Filler height={4} />
                  <Link to={'/cliente'} tabIndex={-1}>
                     <Button text={this.props.lang.tipoDeAcesso.cliente} />
                  </Link>
               </Col>
            </Row>
         </Container>
      );
   }
}
