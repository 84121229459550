import { showError, showInfo } from '../../components/Messages';
import { BaseController } from '../BaseController';
import { updateState, dateToString, stringToDate } from '../../utils/Functions';

export default class AnalisadorSimuladorController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'AnalisadorSimuladorController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         {
            titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.tipoDeEquipamento,
            width: '25%',
            orderby: 'TipoDeEquipamento.Sigla',
         },
         {
            titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDeSerie,
            width: '25%',
            orderby: 'numeroDeSerie',
         },
         {
            titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDePatrimonio,
            width: '25%',
            orderby: 'numeroDePatrimonio',
         },
         {
            titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeValidadeDoCertificado,
            width: '25%',
            orderby: 'dataDeValidadeDoCertificado',
         },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.tipoDeEquipamento ? item.tipoDeEquipamento.sigla : null,
         item.numeroDeSerie,
         item.numeroDePatrimonio,
         dateToString(item.dataDeValidadeDoCertificado),
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.calibradoPor) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeQuemCalibrou);
            reject();
            return;
         }

         if (!item.dataDeCalibracao) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeADataDeCalibracao);
            reject();
            return;
         }

         if (!item.tempoDeDuracaoDaCalibracao) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeOTempoDeDuracaoDaCalibracao);
            reject();
            return;
         }

         if (!item.numeroDoCertificado) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeONumeroDoCertificadoDeCalibracao);
            reject();
            return;
         }

         var input = {
            numeroDePatrimonio: item.numeroDePatrimonio,
            dataDeCalibracao: item.dataDeCalibracao,
            calibradoPor: item.calibradoPor,
            tempoDeDuracaoDaCalibracao: item.tempoDeDuracaoDaCalibracao,
            certificadosDeCalibracao: item.certificadosDeCalibracao,
            numeroDoCertificado: item.numeroDoCertificado,
            situacaoNaEmpresa: item.situacaoNaEmpresa,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   inserirCertificadoDeCalibracao = () => {
      return new Promise((resolve, reject) => {
         if (!this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.arquivo) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.selecioneOArquivo);
            reject();
            return false;
         }

         if (!this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDeValidade) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeADataDeValidade);
            reject();
            return false;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.certificadosDeCalibracao) {
               state.itemSelecionado.certificadosDeCalibracao = [];
            }
            let certificado = this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao;
            state.itemSelecionado.certificadosDeCalibracao.push({
               certificadoDeCalibracao: {
                  numeroDoCertificado: certificado.numeroDoCertificado,
                  arquivo: certificado.arquivo,
                  dataDeValidade: certificado.dataDeValidade,
                  calibradoPor: certificado.calibradoPor,
                  dataDaCalibracao: certificado.dataDaCalibracao,
                  localDaCalibracao: certificado.localDaCalibracao,
                  resultados: certificado.resultados,
               },
            });
         }).then(() => resolve());
      });
   };

   alterarCertificadoDeCalibracao = (index) => {
      return new Promise((resolve, reject) => {
         if (!this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDeValidade) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeADataDeValidade);
            reject();
            return false;
         }

         let certificado = this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao;
         updateState(this.view, (state) => {
            state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.numeroDoCertificado =
               certificado.numeroDoCertificado;
            state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.arquivo = certificado.arquivo;
            state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.dataDeValidade =
               certificado.dataDeValidade;
            state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.calibradoPor =
               certificado.calibradoPor;
            state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.dataDaCalibracao =
               certificado.dataDaCalibracao;
            state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.localDaCalibracao =
               certificado.localDaCalibracao;
            state.itemSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.resultados =
               certificado.resultados;
         }).then(() => resolve());
      });
   };

   getDescricaoDaSituacaoDoCertificado = (dataDeValidade) => {
      let dataAtual = new Date();
      let result;
      if (dataAtual > stringToDate(dateToString(dataDeValidade, this.lang)))
         result = this.lang.telaDeCadastroDeAnalisadorSimulador.vencido;
      else result = this.lang.telaDeCadastroDeAnalisadorSimulador.ativo;
      return result;
   };

   inserirResultadoDeCertificadoDeCalibracao = () => {
      return new Promise((resolve, reject) => {
         updateState(this.view, (state) => {
            if (!state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados) {
               state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados = [];
            }
            let resultado = state.resultadoSelecionado;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados.push({
               parameterType: resultado.parameterType,
               parameterTypeDesc: resultado.parameterTypeDesc,
               referenceValue: resultado.referenceValue,
               referenceValue2: resultado.referenceValue2,
               referenceValue3: resultado.referenceValue3,
               tendency: resultado.tendency,
               measuredValue: resultado.measuredValue,
               percentTendency: resultado.percentTendency,
               uncertainty: resultado.uncertainty,
               coverageFactor: resultado.coverageFactor,
            });
         }).then(() => resolve());
      });
   };

   alterarResultadoDeCertificadoDeCalibracao = (index) => {
      return new Promise((resolve) => {
         let resultado = this.view.state.resultadoSelecionado;
         updateState(this.view, (state) => {
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].parameterType =
               resultado.parameterType;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].parameterTypeDesc =
               resultado.parameterTypeDesc;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].referenceValue =
               resultado.referenceValue;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].referenceValue2 =
               resultado.referenceValue2;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].referenceValue3 =
               resultado.referenceValue3;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].measuredValue =
               resultado.measuredValue;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].tendency =
               resultado.tendency;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].percentTendency =
               resultado.percentTendency;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].uncertainty =
               resultado.uncertainty;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].coverageFactor =
               resultado.coverageFactor;
         }).then(() => resolve());
      });
   };

   importarResultados = () => {
      if (this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha != null) {
         this.api
            .get(
               '/analisador/lerplanilhadecertificados?file=' +
                  this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha.id +
                  '&sigla=' +
                  this.view.state.itemSelecionado.tipoDeEquipamento.sigla
            )
            .then((result) => {
               updateState(this.view, (state) => {
                  state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados = result;
               }).then(() => {
                  showInfo(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.resultadosImportadosComSucesso).then(
                     () => {
                        updateState(this.view, (state) => {
                           state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha = null;
                           state.mostrarPlanilha = false;
                        }).then(() => {
                           updateState(this.view, (state) => {
                              state.mostrarPlanilha = true;
                           });
                        });
                     }
                  );
               });
            });
      }
   };
}
