export const TIPO_DE_TESTE_ASE = {
    resistenciaDeTerra: 0,
    redeDeTensao: 5,
    redeDeCorrente: 6,
    resistenciaIsolacao: 1,
    correnteDeFugaManual: 2,
    correnteDeFugaNorma: 3,
 };


 export const TIPO_DE_TESTE_AVM = {
    manualDeVentilador: 0,
    manualDePressao: 1,
    normaDeVolume: 2,
    normaDePressao: 3,
    normaDeFiO2: 4,
 }; 