import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import CheckListController from '../../controllers/Empresa/CheckListController';
import { updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import { showError } from '../../components/Messages';

export default class CheckListView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true };
      this.controller = new CheckListController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeCheckList = lang.telaDeCadastroDeCheckList;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeCheckList.descricao}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.descricao}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.descricao = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={this.props.lang.telaDeCadastroDeCheckList.itens}
               itens={this.state.itemSelecionado.itens}               
               lang={this.props.lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.itemDeCheckListSelecionado = {};
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                return new Promise((resolve, reject) => {
                    if (!this.state.itemDeCheckListSelecionado.descricao)
                    {
                        showError(this.props.lang.telaDeCadastroDeCheckList.mensagens.informeADescricao);
                        reject();
                        return;
                    }

                    updateState(this, (state) => {
                       if (!state.itemSelecionado.itens) {
                          state.itemSelecionado.itens = [];
                       }
                       state.itemSelecionado.itens.push({
                          descricao: this.state.itemDeCheckListSelecionado.descricao,                         
                       });
                    }).then(() => resolve());
                 });
               }}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.itemDeCheckListSelecionado = JSON.parse(
                           JSON.stringify(state.itemSelecionado.itens[index])
                        );
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                return new Promise((resolve, reject) => {
                    if (!this.state.itemDeCheckListSelecionado.descricao)
                    {
                        showError(this.props.lang.telaDeCadastroDeCheckList.mensagens.informeADescricao);
                        reject();
                        return;
                    }
           
                    updateState(this, (state) => {
                       state.itemSelecionado.itens[index].descricao = this.state.itemDeCheckListSelecionado.descricao;                       
                    }).then(() => resolve());
                 });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.itemDeCheckListSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.itens.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: this.props.lang.telaDeCadastroDeCheckList.descricao, width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.descricao];
               }}
               formulario={() => {
                  return (
                     <div>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{this.props.lang.telaDeCadastroDeCheckList.descricao}</Label>
                                 <TextArea
                                    defaultValue={this.state.itemDeCheckListSelecionado.descricao}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.itemDeCheckListSelecionado.descricao = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     </div>
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeCheckList.titulo}
            url={'/checklist'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'descricao'}
            permissoes={[1431, 1432, 1433, 1434]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
               acessos: [],
            }}
         />
      );
   }
}
