import { showError } from '../../components/Messages';
import { toEntityReference } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class TipoDeEquipamentoController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'TipoDeEquipamentoController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeTipoDeEquipamento.nome, width: '70%', orderby: 'Nome' },
         { titulo: this.lang.telaDeCadastroDeTipoDeEquipamento.sigla, width: '15%', orderby: 'Sigla' },
         { titulo: this.lang.telaDeCadastroDeTipoDeEquipamento.situacao, width: '15%', orderby: 'Situacao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nome, item.sigla, item.situacao?.descricao];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.id) {
            showError(this.lang.telaDeCadastroDeTipoDeEquipamento.mensagens.informeOCodigoDoTipoDeEquipamento);
            reject();
            return;
         }

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeTipoDeEquipamento.mensagens.informeONomeDoTipoDeEquipamento);
            reject();
            return;
         }

         if (!item.sigla) {
            showError(this.lang.telaDeCadastroDeTipoDeEquipamento.mensagens.informeASiglaDoTipoDeEquipamento);
            reject();
            return;
         }

         if (item.sigla && item.sigla.length > 10) {
            showError(this.lang.telaDeCadastroDeTipoDeEquipamento.mensagens.informeUmTamanhoMenorQue10Siglas);
            reject();
            return;
         }

         if (item.tipoDeSequencia === null || item.tipoDeSequencia === undefined) {
            showError(this.lang.telaDeCadastroDeTipoDeEquipamento.mensagens.informeOTipoDeSequencia);
            reject();
            return;
         }

         if (item.descricao && item.descricao.length > 500) {
            showError(
               this.lang.telaDeCadastroDeTipoDeEquipamento.mensagens.descricaoDoTipoDeEquipamentoAceita500Caracteres
            );
            reject();
            return;
         }

         var input = {
            id: item.id,
            nome: item.nome,
            situacao: item.situacao ? item.situacao.id : 0,
            descricao: item.descricao,
            sigla: item.sigla,
            tipoDeSequencia: item.tipoDeSequencia,
            manual: toEntityReference(item.manual),
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   antesDeInserir = () => {
      return new Promise((resolve, reject) => {
         this.view.setState({ podeAlterarCodigo: false });
         resolve();
      });
   };

   antesDeEditar = () => {
      return new Promise((resolve, reject) => {
         this.view.setState({ podeAlterarCodigo: false });
         resolve();
      });
   };
}
