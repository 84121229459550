import React, { Component } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import Label from '../../../components/Label';
import Select from '../../../components/Select';
import DateInput from '../../../components/DateInput';
import { buildQueryString, formatDate, updateState } from '../../../utils/Functions';
import EmpresaView from '../../Neos/EmpresaView';
import TipoDeEquipamentoView from '../../Empresa/TipoDeEquipamentoView';
import ListaPadrao from '../../ListaPadrao';
import AnalisadorSimuladorView from '../../Empresa/AnalisadorSimuladorView';
import { Link } from 'react-router-dom';
import CheckBox from '../../../components/CheckBox';

export default class RelatorioDeUtilizacao extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   getFiltro = () => {
      return new Promise((resolve, reject) => {
         resolve({
            dataInicial: formatDate(this.state.dataInicial),
            dataFinal: formatDate(this.state.dataFinal),
            empresa: this.state.empresa ? this.state.empresa.id : null,
            tipoDeEquipamento: this.state.tipoDeEquipamento ? this.state.tipoDeEquipamento.id : null,
            analisador: this.state.analisador ? this.state.analisador.id : null,
            detalharPorAnalisador: this.state.detalharPorAnalisador,
         });
      });
   };

   renderizarFiltros = () => {
      return (
         <div style={{ maxWidth: 800 }}>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeUtilizacao.empresa}</Label>
                     <Select
                        name={'empresa'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.empresa = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <EmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/empresa/fast' + buildQueryString(2, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeUtilizacao.tipoDeEquipamento}</Label>
                     <Select
                        name={'tipoDeEquipamento'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.tipoDeEquipamento = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <TipoDeEquipamentoView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/tipodeequipamento' + buildQueryString(2, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeUtilizacao.analisador}</Label>
                     <Select
                        name={'analisador'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.tipoDeEquipamento.sigla + '_' + i.numeroDeSerie}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.analisador = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return (
                              <AnalisadorSimuladorView
                                 api={this.props.api}
                                 select={select}
                                 lang={this.props.lang}
                                 filtroExtra={() => {
                                    return {
                                       empresa: this.state.empresa ? this.state.empresa.id : null,
                                    };
                                 }}
                              />
                           );
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/analisador' +
                           buildQueryString(2, null, 'id', {
                              Searchable: text,
                              empresa: this.state.empresa ? this.state.empresa.id : null,
                           })
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col style={{ maxWidth: 200 }}>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeUtilizacao.dataInicial}</Label>
                     <DateInput
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataInicial = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col style={{ maxWidth: 200 }}>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeUtilizacao.dataFinal}</Label>
                     <DateInput
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataFinal = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col style={{ maxWidth: 200 }}>
                  <FormGroup>
                     <Label>&nbsp;</Label>
                     <CheckBox
                        marginTop={10}
                        label={'Detalhar por analisador'}
                        onChange={(checked) => {
                           updateState(this, (state) => {
                              state.detalharPorAnalisador = checked;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </div>
      );
   };

   getTitulosDaTabela = () => {
      return this.state.detalharPorAnalisador
         ? [
              { titulo: this.props.lang.relatorioDeUtilizacao.empresa, width: '35%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.analisador, width: '15%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.sigla, width: '5%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.quantidadeDeEnsaiosExecutados, width: '15%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.quantidadeDeEnsaiosConfirmados, width: '15%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.quantidadeDeCertificadosEmitidos, width: '15%' },
           ]
         : [
              { titulo: this.props.lang.relatorioDeUtilizacao.empresa, width: '40%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.quantidadeDeEnsaiosExecutados, width: '20%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.quantidadeDeEnsaiosConfirmados, width: '20%' },
              { titulo: this.props.lang.relatorioDeUtilizacao.quantidadeDeCertificadosEmitidos, width: '20%' },
           ];
   };

   getDadosDaTabela = (item) => {
      return this.state.detalharPorAnalisador
         ? [
              item.nomeFantasia,
              item.numeroDeSerie,
              item.siglaDoTipoDeEquipamento,
              item.quantidadeDeEnsaiosExecutados,
              item.quantidadeDeEnsaiosConfirmados,
              item.quantidadeDeCertificadosEmitidos,
           ]
         : [
              item.nomeFantasia,
              item.quantidadeDeEnsaiosExecutados,
              item.quantidadeDeEnsaiosConfirmados,
              item.quantidadeDeCertificadosEmitidos,
           ];
   };

   render() {
      return (
         <ListaPadrao
            titulo={
               <div>
                  <Link
                     to={'/report'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',

                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.relatorios.titulo}</span>
                  </Link>
                     <span>{' - '}</span>
                  <span>{this.props.lang.relatorioDeUtilizacao.titulo}</span>
               </div>
            }
            estruturaPadrao={false}
            url={'/relatorio/RelatorioDeUtilizacao'}
            api={this.props.api}
            lang={this.props.lang}
            getFiltro={this.getFiltro}
            renderizarFiltros={this.renderizarFiltros}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            getFormState={() => this.state}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
         />
      );
   }
}
