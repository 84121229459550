import React, { Component } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { LayoutParams } from '../../config/LayoutParams';
import Label from '../../components/Label';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import CnpjInput from '../../components/CnpjInput';
import PasswordInput from '../../components/PasswordInput';
import Select from '../../components/Select';
import { LogoLogin } from '../../components/LogoLogin';
import './../../contents/css/login-logo.css';
import { Link } from 'react-router-dom';
import LoginController from '../../controllers/User/LoginController';
import { updateState } from '../../utils/Functions';

export default class LoginView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         lembrar: localStorage.getItem('lembrar-login') ? true : false,
         nomeDeUsuario: localStorage.getItem('lembrar-login'),
         tipoDeIdentificacaoComplementar: { id: 'CNPJ' },
         identificacaoComplementar: null,
      };
      this.api = props.api;
      this.lang = props.lang;
      this.sessionManager = props.sessionManager;
      this.controller = new LoginController(this);
   }

   getDescricaoDoTipoDeAcesso = () => {
      let result = null;
      if (this.props.tipoDeAcesso === 'NEOS') {
         result = this.props.lang.tipoDeAcesso.neos;
      } else if (this.props.tipoDeAcesso === 'EMPRESA') {
         result = this.props.lang.tipoDeAcesso.empresa;
      } else if (this.props.tipoDeAcesso === 'CLIENTE') {
         result = this.props.lang.tipoDeAcesso.cliente;
      }
      return result;
   };

   render() {
      return (
         <Container
            fluid
            style={{
               fontSize: 15,
               height: '100%',
               display: 'flex',
               position: 'fixed',
               justifyContent: 'center',
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
            }}
         >
            <Row className='justify-content-md-center'>
               <Col
                  xs
                  lg='3'
                  style={{
                     minHeight: 424,
                     maxHeight: 424,
                     minWidth: 350,
                     maxWidth: 350,
                     borderRadius: 10,
                     color: 'white',
                     paddingTop: 20,
                  }}
               >
                  <br />

                  <LogoLogin />

                  <div
                     style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: -30,
                        fontStyle: 'italic',
                     }}
                  >
                     <span>Tipo de acesso:&nbsp;</span>
                     <span>{this.getDescricaoDoTipoDeAcesso()}</span>
                  </div>
                  <br />

                  <Form
                     onSubmit={(event) => {
                        event.preventDefault();
                        this.controller.efetuarLogin().catch(() => {});
                     }}
                     action='#'
                     id='formLogin'
                     className='justify-content-md-center'
                  >
                     <React.Fragment>
                        {(this.props.tipoDeAcesso === 'EMPRESA') && (
                           <Form.Group>
                              <Label>{this.props.lang.telaDeLogin.identificacaoDaEmpresa}</Label>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ width: 130 }}>
                                    <Select
                                       defaultValue={this.state.tipoDeIdentificacaoComplementar}
                                       options={[
                                          { id: 'CNPJ', descricao: 'CNPJ' },
                                          { id: 'OUTROS', descricao: 'Outros' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       onSelect={(i) => {
                                          updateState(this, (state) => (state.tipoDeIdentificacaoComplementar = i));
                                       }}
                                       allowEmpty={false}
                                    />
                                 </div>

                                 <div style={{ marginLeft: 6, width: '100%' }}>
                                    {this.state.tipoDeIdentificacaoComplementar &&
                                       this.state.tipoDeIdentificacaoComplementar.id === 'CNPJ' && (
                                          <CnpjInput
                                             defaultValue={this.state.identificacaoComplementar}
                                             name={'tipoDeDocumento'}
                                             onChange={(e) => {
                                                updateState(
                                                   this,
                                                   (state) => (state.identificacaoComplementar = e.formattedValue)
                                                );
                                             }}
                                          />
                                       )}

                                    {this.state.tipoDeIdentificacaoComplementar &&
                                       this.state.tipoDeIdentificacaoComplementar.id === 'OUTROS' && (
                                          <TextInput
                                             defaultValue={this.state.identificacaoComplementar}
                                             name={'tipoDeDocumento'}
                                             onChange={(e) =>
                                                updateState(
                                                   this,
                                                   (state) => (state.identificacaoComplementar = e.target.value)
                                                )
                                             }
                                          />
                                       )}
                                 </div>
                              </div>
                           </Form.Group>
                        )}

                        <Form.Group>
                           <Label>{this.props.lang.telaDeLogin.nomeDeUsuario}</Label>
                           <TextInput
                              defaultValue={this.state.nomeDeUsuario}
                              name={'nomeDeUsuario'}
                              onChange={(e) => updateState(this, (state) => (state.nomeDeUsuario = e.target.value))}
                           />
                        </Form.Group>
                        <Form.Group>
                           <div style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: '100%' }}>
                                 <Label>{this.props.lang.telaDeLogin.senha}</Label>
                              </div>
                              <div
                                 style={{
                                    display: 'flex',
                                    color: LayoutParams.colors.corSecundaria,
                                 }}
                              >
                                 <Link to={'/recoverpassword/' + this.props.tipoDeAcesso.toLowerCase()} tabIndex={-1}>
                                    <Label style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                                       <span>{this.props.lang.telaDeLogin.esqueceuASenha}</span>
                                    </Label>
                                 </Link>
                              </div>
                           </div>
                           <PasswordInput
                              name={'senha'}
                              type='password'
                              onChange={(e) => updateState(this, (state) => (state.senha = e.target.value))}
                           />
                        </Form.Group>
                        {/* <Form.Group>
                                    <CheckBox
                                        className='fs-12'
                                        label='Lembrar'
                                        defaultValue={this.state.lembrarNomeDeUsuario}
                                        onChange={(checked) =>
                                            updateState(this, (state) => (state.lembrarNomeDeUsuario = checked))
                                        }
                                    ></CheckBox>
                                </Form.Group> */}
                        <Button
                           submit={true}
                           text={this.props.lang.telaDeLogin.entrar}
                           inProgressText={this.props.lang.telaDeLogin.entrando}
                        />
                     </React.Fragment>
                  </Form>
               </Col>
            </Row>
         </Container>
      );
   }
}
