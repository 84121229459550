import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from './../FormularioPadrao';
//import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import TextArea from '../../components/TextArea';
import TipoDeEquipamentoController from '../../controllers/Neos/TipoDeEquipamentoController';
import { updateState } from '../../utils/Functions';
import Select from '../../components/Select';
import Document from '../../components/Document';

export default class TipoDeEquipamentoView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true, podeAlterarCodigo: true };
      this.controller = new TipoDeEquipamentoController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeTipoDeEquipamento = lang.telaDeCadastroDeTipoDeEquipamento;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <IntegerInput
                        readOnly={this.state.podeAlterarCodigo}
                        defaultValue={this.state.itemSelecionado.id}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.id = e.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeEquipamento.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeEquipamento.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.telaDeCadastroDeTipoDeEquipamento.ativo },
                           { id: 2, descricao: lang.telaDeCadastroDeTipoDeEquipamento.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeEquipamento.sigla}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.sigla}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.sigla = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeEquipamento.tipoDeSequencia}</Label>
                     <Select
                        as='select'
                        name='tipoDeSequencia'
                        defaultValue={this.state.itemSelecionado.tipoDeSequencia}
                        options={[
                           { id: 0, descricao: 'ABI' },
                           { id: 1, descricao: 'JAU' },
                           { id: 2, descricao: 'JAUMP' },
                           { id: 3, descricao: 'SMP' },
                           { id: 4, descricao: 'SIM' },
                           { id: 7, descricao: 'SEG' },
                           { id: 6, descricao: 'TIN' },
                           { id: 5, descricao: 'ASE' },
                           { id: 8, descricao: 'SOP/MOX' },
                           { id: 9, descricao: 'AVM' },
                           { id: 10, descricao: 'APE' },
                           { id: 11, descricao: 'AQT' },
                           { id: 12, descricao: 'MMD' },
                           { id: 13, descricao: 'SMP/SPK' },
                           { id: 14, descricao: 'MDC' },
                        ]}
                        acceptZero={true}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.tipoDeSequencia = i ? i.id : null;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeEquipamento.descricao}</Label>
                     <TextArea
                        defaultValue={this.state.itemSelecionado.descricao}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.descricao = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col  >
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeEquipamento.manual}</Label>
                     <Document
                        lang={this.props.lang}
                        api={this.props.api}
                        defaultValue={this.state.itemSelecionado.manual}
                        onChange={(file) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.manual = file;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeTipoDeEquipamento.titulo}
            url={'/tipodeequipamento'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1051, 1052, 1053, 1054]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
               acessos: [],
            }}
         />
      );
   }
}
