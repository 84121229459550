import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import {
   buildQueryString,
   converterHorasParaMinutos,
   converterMinutosParaHora,
   numberToString,
   updateState,
} from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import SequenciaAQTController from '../../controllers/Sequencias/SequenciaAQTController';
import {
   getNormasAQT_DistribuicaoDeTemperatura,
   getNormasAQT_Vazamento,
   getTipoEquipamentoAQT,
   getTiposDeTestesAQT,
   getUnidadeMedidaAQT_PressaoDinamica,
   getUnidadeMedidaAQT_Vazamento,
} from '../Descricoes';
import { showError } from '../../components/Messages';
import { Link } from 'react-router-dom';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';
import TipoDeEnsaioView from './TipoDeEnsaioView';
import Button from '../../components/Button';
import CheckListView from '../Empresa/CheckListView';
import NumberFormat from 'react-number-format';
import TextArea from '../../components/TextArea';

const TIPO_DE_TESTE = {
   vazamento: 0,
   distribuicaoDeTemperatura: 1,
   penetracaoDeTemperatura: 2,
   aberturaDePorta: 3,
   quedaDeEnergia: 4,
   manual: 5,
   pressaoDinamica: 6,
   ciclosAjustaveis: 7,
   textoIntroducao: 8,
   textoNormas: 9,
   textoTermos: 10,
   textoObjetivos: 11,
   textoProcedimento: 12,
   textoCritetioDeAceitacao: 13,
   textoConclusao: 14,
   textoIndicador: 15,
   textoEquacao: 16,
};

export default class SequenciaAQTView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new SequenciaAQTController(this);
   }

   setUltimoItemCriado = (item) => {
      updateState(this, (state) => {
         state.ultimoItemCriado = {};
         state.ultimoItemCriado.numero = item.numero;
         state.ultimoItemCriado.repeticoes = item.repeticoes;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.limiteSuperior = item.limiteSuperior;
         state.ultimoItemCriado.limiteInferior = item.limiteInferior;
         state.ultimoItemCriado.temperatura = item.temperatura;
         state.ultimoItemCriado.deltaE = item.deltaE;
         state.ultimoItemCriado.deltaT = item.deltaT;
         state.ultimoItemCriado.deltaTemperatura = item.deltaTemperatura;
         state.ultimoItemCriado.erroTemperatura = item.erroTemperatura;
         state.ultimoItemCriado.indice = item.indice;
         state.ultimoItemCriado.nomeCiclo = item.nomeCiclo;
         state.ultimoItemCriado.nomeFase = item.nomeFase;
         state.ultimoItemCriado.norma = item.norma;
         state.ultimoItemCriado.descTipoCarga = item.descTipoCarga;
         state.ultimoItemCriado.descTexto = item.descTexto;
         state.ultimoItemCriado.descTitulo = item.descTitulo;
         state.ultimoItemCriado.numeroFases = item.numeroFases;
         state.ultimoItemCriado.populacaoInicial = item.populacaoInicial;
         state.ultimoItemCriado.populacaoInicialExpoente = item.populacaoInicialExpoente;
         state.ultimoItemCriado.populacaoInicialMantissa = item.populacaoInicialMantissa;
         state.ultimoItemCriado.pressaoInicial = item.pressaoInicial;
         state.ultimoItemCriado.realizarCalculos = item.realizarCalculos;
         state.ultimoItemCriado.temperaturaD = item.temperaturaD;
         state.ultimoItemCriado.temperaturaInicial = item.temperaturaInicial;
         state.ultimoItemCriado.temperaturaLimite = item.temperaturaLimite;
         state.ultimoItemCriado.temperaturaOperacao = item.temperaturaOperacao;
         state.ultimoItemCriado.temperaturaRef = item.temperaturaRef;
         state.ultimoItemCriado.tempoDuracao = item.tempoDuracao;
         state.ultimoItemCriado.tempoEntreMedidas = item.tempoEntreMedidas;
         state.ultimoItemCriado.tempoEsperado = item.tempoEsperado;
         state.ultimoItemCriado.tempoEsterilizacao = item.tempoEsterilizacao;
         state.ultimoItemCriado.tempoEstimado = item.tempoEstimado;
         state.ultimoItemCriado.tempoEstimadoMinimo = item.tempoEstimadoMinimo;
         state.ultimoItemCriado.tempoFase1Esperado = item.tempoFase1Esperado;
         state.ultimoItemCriado.tempoFase2Esperado = item.tempoFase2Esperado;
         state.ultimoItemCriado.tempoFase3Esperado = item.tempoFase3Esperado;
         state.ultimoItemCriado.tempoFase4Esperado = item.tempoFase4Esperado;
         state.ultimoItemCriado.tempoLimite = item.tempoLimite;
         state.ultimoItemCriado.tempoMaximoParaEstabilidade = item.tempoMaximoParaEstabilidade;
         state.ultimoItemCriado.tempoRetorno = item.tempoRetorno;
         state.ultimoItemCriado.tempoSecagem = item.tempoSecagem;
         state.ultimoItemCriado.testarTemperatura = item.testarTemperatura;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.tipoEquipamento = item.tipoEquipamento;
         state.ultimoItemCriado.tipoLetalidade = item.tipoLetalidade;
         state.ultimoItemCriado.umidade1 = item.umidade1;
         state.ultimoItemCriado.umidade2 = item.umidade2;
         state.ultimoItemCriado.umidade3 = item.umidade3;
         state.ultimoItemCriado.unidadeMedida = item.unidadeMedida;
         state.ultimoItemCriado.valorD = item.valorD;
         state.ultimoItemCriado.vazamentoMaximo = item.vazamentoMaximo;
      });
   };

   getDescricao = (opcoes, id) => {
      let result = opcoes.filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeTeste = (id) => {
      let result = getTiposDeTestesAQT(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{lang.sequencia.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.sequencia.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.sequencia.ativa },
                           { id: 2, descricao: lang.sequencia.inativa },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={lang.sequencia.tiposDeEnsaio}
               modal={true}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.tiposDeEnsaio}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = {
                           tipoDeEnsaio: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio) {
                        showError(lang.sequencia.mensagens.informeOTipoDeEnsaio);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.tiposDeEnsaio) {
                           state.itemSelecionado.tiposDeEnsaio = [];
                        }
                        state.itemSelecionado.tiposDeEnsaio.push({
                           tipoDeEnsaio: this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.tiposDeEnsaio.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: '', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.tipoDeEnsaio.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{lang.sequencia.tipoDeEnsaio}</Label>
                           <Select
                              name={'tipoDeEnsaio'}
                              modal={true}
                              defaultValue={this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.tipoDeEnsaioSelecionado.tipoDeEnsaio = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <TipoDeEnsaioView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/tipodeensaio' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />

            <br />
            {!this.state.itemSelecionado.sequenciaNeos && (
               <SubCadastro
                  titulo={lang.sequencia.checkList}
                  modal={true}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.checkList}
                  lang={lang}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = {
                              checkList: null,
                           };
                        }).then(() => resolve());
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve, reject) => {
                        if (!this.state.checkListSelecionado.checkList) {
                           showError(lang.sequencia.mensagens.informeOCheckList);
                           reject();
                           return false;
                        }

                        updateState(this, (state) => {
                           if (!state.itemSelecionado.checkList) {
                              state.itemSelecionado.checkList = [];
                           }
                           state.itemSelecionado.checkList.push({
                              checkList: this.state.checkListSelecionado.checkList,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = null;
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.checkList.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: lang.sequencia.checkList, width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.checkList.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <Label>{lang.sequencia.checkList}</Label>
                              <Select
                                 name={'checkList'}
                                 modal={true}
                                 defaultValue={this.state.checkListSelecionado.checkList}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.checkListSelecionado.checkList = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <CheckListView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/checklist' + buildQueryString(2, null, 'descricao', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     );
                  }}
               />
            )}

            <br />

            <SubCadastro
               titulo={lang.sequencia.testes}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.itens}
               gradeSempreVisivel={true}
               tituloDoBotaoCancelar={lang.sequencia.finalizarCadastroDeTeste}
               lang={lang}
               novo={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? () => {
                          return new Promise((resolve) => {
                             let maiorNumero = 0;
                             if (this.state.itemSelecionado.itens) {
                                this.state.itemSelecionado.itens.map((i, index) => {
                                   if (i.numero > maiorNumero) {
                                      maiorNumero = i.numero;
                                   }
                                   return true;
                                });
                             }
                             maiorNumero++;
                             updateState(this, (state) => {
                                state.configuracaoSelecionada = null;
                                let configuracaoSelecionada = { tipoDeTeste: 0 };
                                this.setValoresDefault(configuracaoSelecionada);
                                configuracaoSelecionada.numero = maiorNumero;
                                state.configuracaoSelecionada = configuracaoSelecionada;
                             }).then(() => resolve());
                          });
                       }
                     : null
               }
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.itens) {
                           state.itemSelecionado.itens = [];
                        }

                        let item = {
                           numero: this.state.configuracaoSelecionada.numero,
                           repeticoes: 1,
                           tipoDeTeste: this.state.configuracaoSelecionada.tipoDeTeste,
                           limiteSuperior: this.state.configuracaoSelecionada.limiteSuperior,
                           limiteInferior: this.state.configuracaoSelecionada.limiteInferior,
                           temperatura: this.state.configuracaoSelecionada.temperatura,
                           deltaE: this.state.configuracaoSelecionada.deltaE,
                           deltaT: this.state.configuracaoSelecionada.deltaT,
                           deltaTemperatura: this.state.configuracaoSelecionada.deltaTemperatura,
                           erroTemperatura: this.state.configuracaoSelecionada.erroTemperatura,
                           indice: this.state.configuracaoSelecionada.indice,
                           nomeCiclo: this.state.configuracaoSelecionada.nomeCiclo,
                           nomeFase: this.state.configuracaoSelecionada.nomeFase,
                           norma: this.state.configuracaoSelecionada.norma,
                           descTipoCarga: this.state.configuracaoSelecionada.descTipoCarga,
                           descTexto: this.state.configuracaoSelecionada.descTexto,
                           descTitulo: this.state.configuracaoSelecionada.descTitulo,
                           numeroFases: this.state.configuracaoSelecionada.numeroFases,
                           populacaoInicial: this.state.configuracaoSelecionada.populacaoInicial,
                           populacaoInicialExpoente: this.state.configuracaoSelecionada.populacaoInicialExpoente,
                           populacaoInicialMantissa: this.state.configuracaoSelecionada.populacaoInicialMantissa,
                           pressaoInicial: this.state.configuracaoSelecionada.pressaoInicial,
                           realizarCalculos: this.state.configuracaoSelecionada.realizarCalculos,
                           temperaturaD: this.state.configuracaoSelecionada.temperaturaD,
                           temperaturaInicial: this.state.configuracaoSelecionada.temperaturaInicial,
                           temperaturaLimite: this.state.configuracaoSelecionada.temperaturaLimite,
                           temperaturaOperacao: this.state.configuracaoSelecionada.temperaturaOperacao,
                           temperaturaRef: this.state.configuracaoSelecionada.temperaturaRef,
                           tempoDuracao: this.state.configuracaoSelecionada.tempoDuracao,
                           tempoEntreMedidas: this.state.configuracaoSelecionada.tempoEntreMedidas,
                           tempoEsperado: this.state.configuracaoSelecionada.tempoEsperado,
                           tempoEsterilizacao: this.state.configuracaoSelecionada.tempoEsterilizacao,
                           tempoEstimado: this.state.configuracaoSelecionada.tempoEstimado,
                           tempoEstimadoMinimo: this.state.configuracaoSelecionada.tempoEstimadoMinimo,
                           tempoFase1Esperado: this.state.configuracaoSelecionada.tempoFase1Esperado,
                           tempoFase2Esperado: this.state.configuracaoSelecionada.tempoFase2Esperado,
                           tempoFase3Esperado: this.state.configuracaoSelecionada.tempoFase3Esperado,
                           tempoFase4Esperado: this.state.configuracaoSelecionada.tempoFase4Esperado,
                           tempoLimite: this.state.configuracaoSelecionada.tempoLimite,
                           tempoMaximoParaEstabilidade: this.state.configuracaoSelecionada.tempoMaximoParaEstabilidade,
                           tempoRetorno: this.state.configuracaoSelecionada.tempoRetorno,
                           tempoSecagem: this.state.configuracaoSelecionada.tempoSecagem,
                           testarTemperatura: this.state.configuracaoSelecionada.testarTemperatura,
                           tipoEquipamento: this.state.configuracaoSelecionada.tipoEquipamento,
                           tipoLetalidade: this.state.configuracaoSelecionada.tipoLetalidade,
                           umidade1: this.state.configuracaoSelecionada.umidade1,
                           umidade2: this.state.configuracaoSelecionada.umidade2,
                           umidade3: this.state.configuracaoSelecionada.umidade3,
                           unidadeMedida: this.state.configuracaoSelecionada.unidadeMedida,
                           valorD: this.state.configuracaoSelecionada.valorD,
                           vazamentoMaximo: this.state.configuracaoSelecionada.vazamentoMaximo,
                        };

                        state.itemSelecionado.itens.push(item);
                        if (item.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis && state.finalizandoCiclo) {
                           state.ultimoItemCriado = null;
                        } else {
                           this.setUltimoItemCriado(item);
                        }
                     }).then(() => resolve());
                  });
               }}
               aposSalvar={this.aposSalvar}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     updateState(this, (state) => {
                        state.itemSelecionado.itens[index].numero = this.state.configuracaoSelecionada.numero;
                        state.itemSelecionado.itens[index].repeticoes = this.state.configuracaoSelecionada.repeticoes;
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;
                        state.itemSelecionado.itens[index].limiteSuperior =
                           this.state.configuracaoSelecionada.limiteSuperior;
                        state.itemSelecionado.itens[index].limiteInferior =
                           this.state.configuracaoSelecionada.limiteInferior;
                        state.itemSelecionado.itens[index].temperatura = this.state.configuracaoSelecionada.temperatura;
                        state.itemSelecionado.itens[index].deltaE = this.state.configuracaoSelecionada.deltaE;
                        state.itemSelecionado.itens[index].deltaT = this.state.configuracaoSelecionada.deltaT;
                        state.itemSelecionado.itens[index].deltaTemperatura =
                           this.state.configuracaoSelecionada.deltaTemperatura;
                        state.itemSelecionado.itens[index].erroTemperatura =
                           this.state.configuracaoSelecionada.erroTemperatura;
                        state.itemSelecionado.itens[index].indice = this.state.configuracaoSelecionada.indice;
                        state.itemSelecionado.itens[index].nomeCiclo = this.state.configuracaoSelecionada.nomeCiclo;
                        state.itemSelecionado.itens[index].nomeFase = this.state.configuracaoSelecionada.nomeFase;
                        state.itemSelecionado.itens[index].norma = this.state.configuracaoSelecionada.norma;
                        state.itemSelecionado.itens[index].descTipoCarga =
                           this.state.configuracaoSelecionada.descTipoCarga;
                        state.itemSelecionado.itens[index].descTexto = this.state.configuracaoSelecionada.descTexto;
                        state.itemSelecionado.itens[index].descTitulo = this.state.configuracaoSelecionada.descTitulo;
                        state.itemSelecionado.itens[index].numeroFases = this.state.configuracaoSelecionada.numeroFases;
                        state.itemSelecionado.itens[index].populacaoInicial =
                           this.state.configuracaoSelecionada.populacaoInicial;
                        state.itemSelecionado.itens[index].populacaoInicialExpoente =
                           this.state.configuracaoSelecionada.populacaoInicialExpoente;
                        state.itemSelecionado.itens[index].populacaoInicialMantissa =
                           this.state.configuracaoSelecionada.populacaoInicialMantissa;
                        state.itemSelecionado.itens[index].pressaoInicial =
                           this.state.configuracaoSelecionada.pressaoInicial;
                        state.itemSelecionado.itens[index].realizarCalculos =
                           this.state.configuracaoSelecionada.realizarCalculos;
                        state.itemSelecionado.itens[index].temperaturaD =
                           this.state.configuracaoSelecionada.temperaturaD;
                        state.itemSelecionado.itens[index].temperaturaInicial =
                           this.state.configuracaoSelecionada.temperaturaInicial;
                        state.itemSelecionado.itens[index].temperaturaLimite =
                           this.state.configuracaoSelecionada.temperaturaLimite;
                        state.itemSelecionado.itens[index].temperaturaOperacao =
                           this.state.configuracaoSelecionada.temperaturaOperacao;
                        state.itemSelecionado.itens[index].temperaturaRef =
                           this.state.configuracaoSelecionada.temperaturaRef;
                        state.itemSelecionado.itens[index].tempoDuracao =
                           this.state.configuracaoSelecionada.tempoDuracao;
                        state.itemSelecionado.itens[index].tempoEntreMedidas =
                           this.state.configuracaoSelecionada.tempoEntreMedidas;
                        state.itemSelecionado.itens[index].tempoEsperado =
                           this.state.configuracaoSelecionada.tempoEsperado;
                        state.itemSelecionado.itens[index].tempoEsterilizacao =
                           this.state.configuracaoSelecionada.tempoEsterilizacao;
                        state.itemSelecionado.itens[index].tempoEstimado =
                           this.state.configuracaoSelecionada.tempoEstimado;
                        state.itemSelecionado.itens[index].tempoEstimadoMinimo =
                           this.state.configuracaoSelecionada.tempoEstimadoMinimo;
                        state.itemSelecionado.itens[index].tempoFase1Esperado =
                           this.state.configuracaoSelecionada.tempoFase1Esperado;
                        state.itemSelecionado.itens[index].tempoFase2Esperado =
                           this.state.configuracaoSelecionada.tempoFase2Esperado;
                        state.itemSelecionado.itens[index].tempoFase3Esperado =
                           this.state.configuracaoSelecionada.tempoFase3Esperado;
                        state.itemSelecionado.itens[index].tempoFase4Esperado =
                           this.state.configuracaoSelecionada.tempoFase4Esperado;
                        state.itemSelecionado.itens[index].tempoLimite = this.state.configuracaoSelecionada.tempoLimite;
                        state.itemSelecionado.itens[index].tempoMaximoParaEstabilidade =
                           this.state.configuracaoSelecionada.tempoMaximoParaEstabilidade;
                        state.itemSelecionado.itens[index].tempoRetorno =
                           this.state.configuracaoSelecionada.tempoRetorno;
                        state.itemSelecionado.itens[index].tempoSecagem =
                           this.state.configuracaoSelecionada.tempoSecagem;
                        state.itemSelecionado.itens[index].testarTemperatura =
                           this.state.configuracaoSelecionada.testarTemperatura;
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;
                        state.itemSelecionado.itens[index].tipoEquipamento =
                           this.state.configuracaoSelecionada.tipoEquipamento;
                        state.itemSelecionado.itens[index].tipoLetalidade =
                           this.state.configuracaoSelecionada.tipoLetalidade;
                        state.itemSelecionado.itens[index].umidade1 = this.state.configuracaoSelecionada.umidade1;
                        state.itemSelecionado.itens[index].umidade2 = this.state.configuracaoSelecionada.umidade2;
                        state.itemSelecionado.itens[index].umidade3 = this.state.configuracaoSelecionada.umidade3;
                        state.itemSelecionado.itens[index].unidadeMedida =
                           this.state.configuracaoSelecionada.unidadeMedida;
                        state.itemSelecionado.itens[index].valorD = this.state.configuracaoSelecionada.valorD;
                        state.itemSelecionado.itens[index].vazamentoMaximo =
                           this.state.configuracaoSelecionada.vazamentoMaximo;

                        this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? (index) => {
                          updateState(this, (state) => {
                             state.itemSelecionado.itens.splice(index, 1);
                             state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
                          });
                       }
                     : null
               }
               colunas={() => {
                  return [
                     { titulo: 'Nº', width: '5%', className: null },
                     { titulo: 'Tipo de Teste', width: '30%', className: null },
                     { titulo: 'Tipo de Parâmetro', width: '50%', className: null },
                     { titulo: '', width: '20%', className: null },
                  ];
               }}
               linha={(item) => {
                  const lang = this.props.lang;

                  const formatarTempo = (t) => {
                     if (t === -1) return this.props.lang.cadastroDeSequenciaAQT.manual;
                     else if (t === 0) return this.props.lang.cadastroDeSequenciaAQT.desligado;
                     else if (t === 86400) return '24:00:00';
                     else return converterMinutosParaHora(t / 60, 'HH:mm:ss');
                  };

                  if (item.tipoDeTeste === TIPO_DE_TESTE.vazamento) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.pressaoInicial +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoMaximoParaEstabilidade +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoDuracao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.vazamentoMaximo +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.norma +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        item.pressaoInicial +
                        '\n' +
                        item.tempoMaximoParaEstabilidade +
                        '\n' +
                        item.tempoDuracao +
                        '\n' +
                        item.vazamentoMaximo +
                        '\n' +
                        '-' +
                        '\n' +
                        item.descTipoCarga;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.distribuicaoDeTemperatura) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.temperaturaOperacao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.deltaTemperatura +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.erroTemperatura +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoEntreMedidas +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase1Esperado +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase2Esperado +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase3Esperado +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase4Esperado;

                     let valor =
                        item.temperaturaOperacao +
                        '\n' +
                        item.deltaTemperatura +
                        '\n' +
                        item.erroTemperatura +
                        '\n' +
                        item.tempoEntreMedidas +
                        '\n' +
                        item.descTipoCarga +
                        '\n' +
                        formatarTempo(item.tempoFase1Esperado) +
                        '\n' +
                        formatarTempo(item.tempoFase2Esperado) +
                        '\n' +
                        formatarTempo(item.tempoFase3Esperado) +
                        '\n' +
                        formatarTempo(item.tempoFase4Esperado);

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.penetracaoDeTemperatura) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.temperaturaOperacao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.deltaTemperatura +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.erroTemperatura +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase1Esperado +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase2Esperado +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase3Esperado +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoFase4Esperado +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoEntreMedidas +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tipoLetalidade +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.populacaoInicialMantissa +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.valorD +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.temperaturaD +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.norma +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        item.temperaturaOperacao +
                        '\n' +
                        item.deltaTemperatura +
                        '\n' +
                        item.erroTemperatura +
                        '\n' +
                        formatarTempo(item.tempoFase1Esperado) +
                        '\n' +
                        formatarTempo(item.tempoFase2Esperado) +
                        '\n' +
                        formatarTempo(item.tempoFase3Esperado) +
                        '\n' +
                        formatarTempo(item.tempoFase4Esperado) +
                        '\n' +
                        item.tempoEntreMedidas +
                        '\n' +
                        item.tipoLetalidade +
                        '\n' +
                        numberToString(item.populacaoInicialMantissa, 1) +
                        '\n' +
                        item.valorD +
                        '\n' +
                        item.temperaturaD +
                        '\n' +
                        '16328' +
                        '\n' +
                        item.descTipoCarga;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.aberturaDePorta) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.temperaturaInicial +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.temperaturaLimite +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoLimite +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoRetorno;

                     let valor =
                        item.temperaturaInicial +
                        '\n' +
                        item.temperaturaLimite +
                        '\n' +
                        item.tempoLimite +
                        '\n' +
                        item.tempoRetorno;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.quedaDeEnergia) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.temperaturaInicial +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.temperaturaLimite +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoLimite +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.norma +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        item.temperaturaInicial +
                        '\n' +
                        item.temperaturaLimite +
                        '\n' +
                        item.tempoLimite +
                        '\n' +
                        '16328' +
                        '\n' +
                        item.descTipoCarga;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.manual) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.tempoDuracao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoEntreMedidas +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.temperaturaRef +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.limiteInferior +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.limiteSuperior +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        formatarTempo(item.tempoDuracao) +
                        '\n' +
                        item.tempoEntreMedidas +
                        '\n' +
                        item.temperaturaRef +
                        '\n' +
                        item.limiteInferior +
                        '\n' +
                        item.limiteSuperior +
                        '\n' +
                        item.descTipoCarga;
                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoDinamica) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.tempoDuracao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoEntreMedidas +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.vazamentoMaximo +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.unidadeMedida +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        formatarTempo(item.tempoDuracao) +
                        '\n' +
                        item.tempoEntreMedidas +
                        '\n' +
                        item.vazamentoMaximo +
                        '\n' +
                        this.getDescricao(getUnidadeMedidaAQT_PressaoDinamica(this.props.lang), item.unidadeMedida) +
                        '\n' +
                        item.descTipoCarga;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis && !item.indice) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.tempoDuracao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoEntreMedidas +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.vazamentoMaximo +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        formatarTempo(item.tempoDuracao) +
                        '\n' +
                        item.tempoEntreMedidas +
                        '\n' +
                        item.vazamentoMaximo +
                        '\n' +
                        this.getDescricao(getUnidadeMedidaAQT_PressaoDinamica(this.props.lang), item.unidadeMedida) +
                        '\n' +
                        item.descTipoCarga;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis && !item.numeroFases) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.tempoDuracao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.temperaturaOperacao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.deltaTemperatura +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.erroTemperatura +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        formatarTempo(item.tempoDuracao) +
                        '\n' +
                        item.temperaturaOperacao +
                        '\n' +
                        item.deltaTemperatura +
                        '\n' +
                        item.erroTemperatura +
                        '\n' +
                        item.descTipoCarga;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis && item.numeroFases) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.nomeCiclo +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoDuracao +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tempoEntreMedidas +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTipoCarga;

                     let valor =
                        item.nomeCiclo +
                        '\n' +
                        formatarTempo(item.tempoDuracao) +
                        '\n' +
                        item.tempoEntreMedidas +
                        '\n' +
                        item.descTipoCarga;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else if (
                     item.tipoDeTeste === TIPO_DE_TESTE.textoIntroducao ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoNormas ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoTermos ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoObjetivos ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoProcedimento ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoCritetioDeAceitacao ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoConclusao ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoIndicador ||
                     item.tipoDeTeste === TIPO_DE_TESTE.textoEquacao
                  ) {
                     let tipo =
                        lang.cadastroDeSequenciaAQT.descTitulo +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.descTexto +
                        '\n' +
                        lang.cadastroDeSequenciaAQT.tipoEquipamento;

                     let valor =
                        item.descTitulo +
                        '\n' +
                        item.descTexto +
                        '\n' +
                        getTipoEquipamentoAQT(this.props.lang).filter((i) => i.id === item.tipoEquipamento)[0]
                           .descricao;

                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), tipo, valor];
                  } else {
                     return [item.numero, this.getDescricaoDeTeste(item.tipoDeTeste), null, null];
                  }
               }}
               acoes={(subCadastro, index) => {
                  return (
                     <div style={{ display: 'table-cell', margin: 'auto', marginRight: 4, width: 25 }}>
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginBottom: -10 }}
                           cursor='pointer'
                           icon={faSortUp}
                           onClick={() => {
                              if (this.state.itemSelecionado.itens[index].numero > 1) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index - 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero - 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginTop: -10 }}
                           cursor='pointer'
                           icon={faSortDown}
                           onClick={() => {
                              if (
                                 this.state.itemSelecionado.itens[index].numero <
                                 this.state.itemSelecionado.itens.length
                              ) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index + 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero + 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                     </div>
                  );
               }}
               formulario={(subCadastro) => {
                  return this.state.configuracaoSelecionada ? (
                     <div>
                        <Row>
                        <Col sm={2} md={2} lg={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.numero}</Label>
                                 <IntegerInput
                                    defaultValue={this.state.configuracaoSelecionada.numero}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.numero = e.floatValue;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {/* <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.repeticoes}</Label>
                                 <IntegerInput
                                    // defaultValue={this.state.configuracaoSelecionada.repeticoes}
                                    defaultValue={1}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.repeticoes = e.floatValue;
                                       });
                                    }}
                                    readOnly={true}
                                 />
                              </FormGroup>
                           </Col> */}
                           <Col>
                              <FormGroup>
                                 <Label>{lang.cadastroDeSequenciaAQT.tipoDeTeste}</Label>
                                 <Select
                                    defaultValue={this.state.configuracaoSelecionada.tipoDeTeste}
                                    options={getTiposDeTestesAQT(lang)}
                                    getKeyValue={(i) => i.id}
                                    allowEmpty={false}
                                    getDescription={(i) => i.descricao}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i != null) {
                                             state.configuracaoSelecionada.tipoDeTeste = i.id;
                                          } else {
                                             state.configuracaoSelecionada.tipoDeTeste = 0;
                                          }
                                          let configuracaoSelecionada = state.configuracaoSelecionada;
                                          this.setValoresDefault(configuracaoSelecionada);
                                          state.configuracaoSelecionada = configuracaoSelecionada;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.vazamento && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={getNormasAQT_Vazamento(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.unidadeMedida}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeMedida}
                                          options={getUnidadeMedidaAQT_Vazamento(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeMedida = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeMedida = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTipoCarga = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.pressaoInicial}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.pressaoInicial}
                                          options={[
                                             { id: 0, descricao: '0' },
                                             { id: 1, descricao: '1' },
                                             { id: 2, descricao: '2' },
                                             { id: 3, descricao: '3' },
                                             { id: 4, descricao: '4' },
                                             { id: 5, descricao: '5' },
                                             { id: 6, descricao: '6' },
                                             { id: 7, descricao: '7' },
                                             { id: 8, descricao: '8' },
                                             { id: 9, descricao: '9' },
                                             { id: 10, descricao: '10' },
                                             { id: 11, descricao: '11' },
                                             { id: 12, descricao: '12' },
                                             { id: 13, descricao: '13' },
                                             { id: 14, descricao: '14' },
                                             { id: 15, descricao: '15' },
                                             { id: 16, descricao: '16' },
                                             { id: 17, descricao: '17' },
                                             { id: 18, descricao: '18' },
                                             { id: 19, descricao: '19' },
                                             { id: 20, descricao: '20' },
                                             { id: 21, descricao: '21' },
                                             { id: 22, descricao: '22' },
                                             { id: 23, descricao: '23' },
                                             { id: 24, descricao: '24' },
                                             { id: 25, descricao: '25' },
                                             { id: 26, descricao: '26' },
                                             { id: 27, descricao: '27' },
                                             { id: 28, descricao: '28' },
                                             { id: 29, descricao: '29' },
                                             { id: 30, descricao: '30' },
                                             { id: 31, descricao: '31' },
                                             { id: 32, descricao: '32' },
                                             { id: 33, descricao: '33' },
                                             { id: 34, descricao: '34' },
                                             { id: 35, descricao: '35' },
                                             { id: 36, descricao: '36' },
                                             { id: 37, descricao: '37' },
                                             { id: 38, descricao: '38' },
                                             { id: 39, descricao: '39' },
                                             { id: 40, descricao: '40' },
                                             { id: 41, descricao: '41' },
                                             { id: 42, descricao: '42' },
                                             { id: 43, descricao: '43' },
                                             { id: 44, descricao: '44' },
                                             { id: 45, descricao: '45' },
                                             { id: 46, descricao: '46' },
                                             { id: 47, descricao: '47' },
                                             { id: 48, descricao: '48' },
                                             { id: 49, descricao: '49' },
                                             { id: 50, descricao: '50' },
                                             { id: 51, descricao: '51' },
                                             { id: 52, descricao: '52' },
                                             { id: 53, descricao: '53' },
                                             { id: 54, descricao: '54' },
                                             { id: 55, descricao: '55' },
                                             { id: 56, descricao: '56' },
                                             { id: 57, descricao: '57' },
                                             { id: 58, descricao: '58' },
                                             { id: 59, descricao: '59' },
                                             { id: 60, descricao: '60' },
                                             { id: 61, descricao: '61' },
                                             { id: 62, descricao: '62' },
                                             { id: 63, descricao: '63' },
                                             { id: 64, descricao: '64' },
                                             { id: 65, descricao: '65' },
                                             { id: 66, descricao: '66' },
                                             { id: 67, descricao: '67' },
                                             { id: 68, descricao: '68' },
                                             { id: 69, descricao: '69' },
                                             { id: 70, descricao: '70' },
                                             { id: 71, descricao: '71' },
                                             { id: 72, descricao: '72' },
                                             { id: 73, descricao: '73' },
                                             { id: 74, descricao: '74' },
                                             { id: 75, descricao: '75' },
                                             { id: 76, descricao: '76' },
                                             { id: 77, descricao: '77' },
                                             { id: 78, descricao: '78' },
                                             { id: 79, descricao: '79' },
                                             { id: 80, descricao: '80' },
                                             { id: 81, descricao: '81' },
                                             { id: 82, descricao: '82' },
                                             { id: 83, descricao: '83' },
                                             { id: 84, descricao: '84' },
                                             { id: 85, descricao: '85' },
                                             { id: 86, descricao: '86' },
                                             { id: 87, descricao: '87' },
                                             { id: 88, descricao: '88' },
                                             { id: 89, descricao: '89' },
                                             { id: 90, descricao: '90' },
                                             { id: 91, descricao: '91' },
                                             { id: 92, descricao: '92' },
                                             { id: 93, descricao: '93' },
                                             { id: 94, descricao: '94' },
                                             { id: 95, descricao: '95' },
                                             { id: 96, descricao: '96' },
                                             { id: 97, descricao: '97' },
                                             { id: 98, descricao: '98' },
                                             { id: 99, descricao: '99' },
                                             { id: 100, descricao: '100' },
                                             { id: 101, descricao: '101' },
                                             { id: 102, descricao: '102' },
                                             { id: 103, descricao: '103' },
                                             { id: 104, descricao: '104' },
                                             { id: 105, descricao: '105' },
                                             { id: 106, descricao: '106' },
                                             { id: 107, descricao: '107' },
                                             { id: 108, descricao: '108' },
                                             { id: 109, descricao: '109' },
                                             { id: 110, descricao: '110' },
                                             { id: 111, descricao: '111' },
                                             { id: 112, descricao: '112' },
                                             { id: 113, descricao: '113' },
                                             { id: 114, descricao: '114' },
                                             { id: 115, descricao: '115' },
                                             { id: 116, descricao: '116' },
                                             { id: 117, descricao: '117' },
                                             { id: 118, descricao: '118' },
                                             { id: 119, descricao: '119' },
                                             { id: 120, descricao: '120' },
                                             { id: 121, descricao: '121' },
                                             { id: 122, descricao: '122' },
                                             { id: 123, descricao: '123' },
                                             { id: 124, descricao: '124' },
                                             { id: 125, descricao: '125' },
                                             { id: 126, descricao: '126' },
                                             { id: 127, descricao: '127' },
                                             { id: 128, descricao: '128' },
                                             { id: 129, descricao: '129' },
                                             { id: 130, descricao: '130' },
                                             { id: 131, descricao: '131' },
                                             { id: 132, descricao: '132' },
                                             { id: 133, descricao: '133' },
                                             { id: 134, descricao: '134' },
                                             { id: 135, descricao: '135' },
                                             { id: 136, descricao: '136' },
                                             { id: 137, descricao: '137' },
                                             { id: 138, descricao: '138' },
                                             { id: 139, descricao: '139' },
                                             { id: 140, descricao: '140' },
                                             { id: 141, descricao: '141' },
                                             { id: 142, descricao: '142' },
                                             { id: 143, descricao: '143' },
                                             { id: 144, descricao: '144' },
                                             { id: 145, descricao: '145' },
                                             { id: 146, descricao: '146' },
                                             { id: 147, descricao: '147' },
                                             { id: 148, descricao: '148' },
                                             { id: 149, descricao: '149' },
                                             { id: 150, descricao: '150' },
                                             { id: 151, descricao: '151' },
                                             { id: 152, descricao: '152' },
                                             { id: 153, descricao: '153' },
                                             { id: 154, descricao: '154' },
                                             { id: 155, descricao: '155' },
                                             { id: 156, descricao: '156' },
                                             { id: 157, descricao: '157' },
                                             { id: 158, descricao: '158' },
                                             { id: 159, descricao: '159' },
                                             { id: 160, descricao: '160' },
                                             { id: 161, descricao: '161' },
                                             { id: 162, descricao: '162' },
                                             { id: 163, descricao: '163' },
                                             { id: 164, descricao: '164' },
                                             { id: 165, descricao: '165' },
                                             { id: 166, descricao: '166' },
                                             { id: 167, descricao: '167' },
                                             { id: 168, descricao: '168' },
                                             { id: 169, descricao: '169' },
                                             { id: 170, descricao: '170' },
                                             { id: 171, descricao: '171' },
                                             { id: 172, descricao: '172' },
                                             { id: 173, descricao: '173' },
                                             { id: 174, descricao: '174' },
                                             { id: 175, descricao: '175' },
                                             { id: 176, descricao: '176' },
                                             { id: 177, descricao: '177' },
                                             { id: 178, descricao: '178' },
                                             { id: 179, descricao: '179' },
                                             { id: 180, descricao: '180' },
                                             { id: 181, descricao: '181' },
                                             { id: 182, descricao: '182' },
                                             { id: 183, descricao: '183' },
                                             { id: 184, descricao: '184' },
                                             { id: 185, descricao: '185' },
                                             { id: 186, descricao: '186' },
                                             { id: 187, descricao: '187' },
                                             { id: 188, descricao: '188' },
                                             { id: 189, descricao: '189' },
                                             { id: 190, descricao: '190' },
                                             { id: 191, descricao: '191' },
                                             { id: 192, descricao: '192' },
                                             { id: 193, descricao: '193' },
                                             { id: 194, descricao: '194' },
                                             { id: 195, descricao: '195' },
                                             { id: 196, descricao: '196' },
                                             { id: 197, descricao: '197' },
                                             { id: 198, descricao: '198' },
                                             { id: 199, descricao: '199' },
                                             { id: 200, descricao: '200' },
                                             { id: 201, descricao: '201' },
                                             { id: 202, descricao: '202' },
                                             { id: 203, descricao: '203' },
                                             { id: 204, descricao: '204' },
                                             { id: 205, descricao: '205' },
                                             { id: 206, descricao: '206' },
                                             { id: 207, descricao: '207' },
                                             { id: 208, descricao: '208' },
                                             { id: 209, descricao: '209' },
                                             { id: 210, descricao: '210' },
                                             { id: 211, descricao: '211' },
                                             { id: 212, descricao: '212' },
                                             { id: 213, descricao: '213' },
                                             { id: 214, descricao: '214' },
                                             { id: 215, descricao: '215' },
                                             { id: 216, descricao: '216' },
                                             { id: 217, descricao: '217' },
                                             { id: 218, descricao: '218' },
                                             { id: 219, descricao: '219' },
                                             { id: 220, descricao: '220' },
                                             { id: 221, descricao: '221' },
                                             { id: 222, descricao: '222' },
                                             { id: 223, descricao: '223' },
                                             { id: 224, descricao: '224' },
                                             { id: 225, descricao: '225' },
                                             { id: 226, descricao: '226' },
                                             { id: 227, descricao: '227' },
                                             { id: 228, descricao: '228' },
                                             { id: 229, descricao: '229' },
                                             { id: 230, descricao: '230' },
                                             { id: 231, descricao: '231' },
                                             { id: 232, descricao: '232' },
                                             { id: 233, descricao: '233' },
                                             { id: 234, descricao: '234' },
                                             { id: 235, descricao: '235' },
                                             { id: 236, descricao: '236' },
                                             { id: 237, descricao: '237' },
                                             { id: 238, descricao: '238' },
                                             { id: 239, descricao: '239' },
                                             { id: 240, descricao: '240' },
                                             { id: 241, descricao: '241' },
                                             { id: 242, descricao: '242' },
                                             { id: 243, descricao: '243' },
                                             { id: 244, descricao: '244' },
                                             { id: 245, descricao: '245' },
                                             { id: 246, descricao: '246' },
                                             { id: 247, descricao: '247' },
                                             { id: 248, descricao: '248' },
                                             { id: 249, descricao: '249' },
                                             { id: 250, descricao: '250' },
                                             { id: 251, descricao: '251' },
                                             { id: 252, descricao: '252' },
                                             { id: 253, descricao: '253' },
                                             { id: 254, descricao: '254' },
                                             { id: 255, descricao: '255' },
                                             { id: 256, descricao: '256' },
                                             { id: 257, descricao: '257' },
                                             { id: 258, descricao: '258' },
                                             { id: 259, descricao: '259' },
                                             { id: 260, descricao: '260' },
                                             { id: 261, descricao: '261' },
                                             { id: 262, descricao: '262' },
                                             { id: 263, descricao: '263' },
                                             { id: 264, descricao: '264' },
                                             { id: 265, descricao: '265' },
                                             { id: 266, descricao: '266' },
                                             { id: 267, descricao: '267' },
                                             { id: 268, descricao: '268' },
                                             { id: 269, descricao: '269' },
                                             { id: 270, descricao: '270' },
                                             { id: 271, descricao: '271' },
                                             { id: 272, descricao: '272' },
                                             { id: 273, descricao: '273' },
                                             { id: 274, descricao: '274' },
                                             { id: 275, descricao: '275' },
                                             { id: 276, descricao: '276' },
                                             { id: 277, descricao: '277' },
                                             { id: 278, descricao: '278' },
                                             { id: 279, descricao: '279' },
                                             { id: 280, descricao: '280' },
                                             { id: 281, descricao: '281' },
                                             { id: 282, descricao: '282' },
                                             { id: 283, descricao: '283' },
                                             { id: 284, descricao: '284' },
                                             { id: 285, descricao: '285' },
                                             { id: 286, descricao: '286' },
                                             { id: 287, descricao: '287' },
                                             { id: 288, descricao: '288' },
                                             { id: 289, descricao: '289' },
                                             { id: 290, descricao: '290' },
                                             { id: 291, descricao: '291' },
                                             { id: 292, descricao: '292' },
                                             { id: 293, descricao: '293' },
                                             { id: 294, descricao: '294' },
                                             { id: 295, descricao: '295' },
                                             { id: 296, descricao: '296' },
                                             { id: 297, descricao: '297' },
                                             { id: 298, descricao: '298' },
                                             { id: 299, descricao: '299' },
                                             { id: 300, descricao: '300' },
                                             { id: 301, descricao: '301' },
                                             { id: 302, descricao: '302' },
                                             { id: 303, descricao: '303' },
                                             { id: 304, descricao: '304' },
                                             { id: 305, descricao: '305' },
                                             { id: 306, descricao: '306' },
                                             { id: 307, descricao: '307' },
                                             { id: 308, descricao: '308' },
                                             { id: 309, descricao: '309' },
                                             { id: 310, descricao: '310' },
                                             { id: 311, descricao: '311' },
                                             { id: 312, descricao: '312' },
                                             { id: 313, descricao: '313' },
                                             { id: 314, descricao: '314' },
                                             { id: 315, descricao: '315' },
                                             { id: 316, descricao: '316' },
                                             { id: 317, descricao: '317' },
                                             { id: 318, descricao: '318' },
                                             { id: 319, descricao: '319' },
                                             { id: 320, descricao: '320' },
                                             { id: 321, descricao: '321' },
                                             { id: 322, descricao: '322' },
                                             { id: 323, descricao: '323' },
                                             { id: 324, descricao: '324' },
                                             { id: 325, descricao: '325' },
                                             { id: 326, descricao: '326' },
                                             { id: 327, descricao: '327' },
                                             { id: 328, descricao: '328' },
                                             { id: 329, descricao: '329' },
                                             { id: 330, descricao: '330' },
                                             { id: 331, descricao: '331' },
                                             { id: 332, descricao: '332' },
                                             { id: 333, descricao: '333' },
                                             { id: 334, descricao: '334' },
                                             { id: 335, descricao: '335' },
                                             { id: 336, descricao: '336' },
                                             { id: 337, descricao: '337' },
                                             { id: 338, descricao: '338' },
                                             { id: 339, descricao: '339' },
                                             { id: 340, descricao: '340' },
                                             { id: 341, descricao: '341' },
                                             { id: 342, descricao: '342' },
                                             { id: 343, descricao: '343' },
                                             { id: 344, descricao: '344' },
                                             { id: 345, descricao: '345' },
                                             { id: 346, descricao: '346' },
                                             { id: 347, descricao: '347' },
                                             { id: 348, descricao: '348' },
                                             { id: 349, descricao: '349' },
                                             { id: 350, descricao: '350' },
                                             { id: 351, descricao: '351' },
                                             { id: 352, descricao: '352' },
                                             { id: 353, descricao: '353' },
                                             { id: 354, descricao: '354' },
                                             { id: 355, descricao: '355' },
                                             { id: 356, descricao: '356' },
                                             { id: 357, descricao: '357' },
                                             { id: 358, descricao: '358' },
                                             { id: 359, descricao: '359' },
                                             { id: 360, descricao: '360' },
                                             { id: 361, descricao: '361' },
                                             { id: 362, descricao: '362' },
                                             { id: 363, descricao: '363' },
                                             { id: 364, descricao: '364' },
                                             { id: 365, descricao: '365' },
                                             { id: 366, descricao: '366' },
                                             { id: 367, descricao: '367' },
                                             { id: 368, descricao: '368' },
                                             { id: 369, descricao: '369' },
                                             { id: 370, descricao: '370' },
                                             { id: 371, descricao: '371' },
                                             { id: 372, descricao: '372' },
                                             { id: 373, descricao: '373' },
                                             { id: 374, descricao: '374' },
                                             { id: 375, descricao: '375' },
                                             { id: 376, descricao: '376' },
                                             { id: 377, descricao: '377' },
                                             { id: 378, descricao: '378' },
                                             { id: 379, descricao: '379' },
                                             { id: 380, descricao: '380' },
                                             { id: 381, descricao: '381' },
                                             { id: 382, descricao: '382' },
                                             { id: 383, descricao: '383' },
                                             { id: 384, descricao: '384' },
                                             { id: 385, descricao: '385' },
                                             { id: 386, descricao: '386' },
                                             { id: 387, descricao: '387' },
                                             { id: 388, descricao: '388' },
                                             { id: 389, descricao: '389' },
                                             { id: 390, descricao: '390' },
                                             { id: 391, descricao: '391' },
                                             { id: 392, descricao: '392' },
                                             { id: 393, descricao: '393' },
                                             { id: 394, descricao: '394' },
                                             { id: 395, descricao: '395' },
                                             { id: 396, descricao: '396' },
                                             { id: 397, descricao: '397' },
                                             { id: 398, descricao: '398' },
                                             { id: 399, descricao: '399' },
                                             { id: 400, descricao: '400' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.pressaoInicial = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.pressaoInicial = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoMaximoParaEstabilidade}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoMaximoParaEstabilidade}
                                          options={[
                                             { id: 30, descricao: '30' },
                                             { id: 40, descricao: '40' },
                                             { id: 50, descricao: '50' },
                                             { id: 60, descricao: '60' },
                                             { id: 70, descricao: '70' },
                                             { id: 80, descricao: '80' },
                                             { id: 90, descricao: '90' },
                                             { id: 100, descricao: '100' },
                                             { id: 110, descricao: '110' },
                                             { id: 120, descricao: '120' },
                                             { id: 130, descricao: '130' },
                                             { id: 140, descricao: '140' },
                                             { id: 150, descricao: '150' },
                                             { id: 160, descricao: '160' },
                                             { id: 170, descricao: '170' },
                                             { id: 180, descricao: '180' },
                                             { id: 190, descricao: '190' },
                                             { id: 200, descricao: '200' },
                                             { id: 210, descricao: '210' },
                                             { id: 220, descricao: '220' },
                                             { id: 230, descricao: '230' },
                                             { id: 240, descricao: '240' },
                                             { id: 250, descricao: '250' },
                                             { id: 260, descricao: '260' },
                                             { id: 270, descricao: '270' },
                                             { id: 280, descricao: '280' },
                                             { id: 290, descricao: '290' },
                                             { id: 300, descricao: '300' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoMaximoParaEstabilidade = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoMaximoParaEstabilidade = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoDuracao}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoDuracao}
                                          options={[
                                             { id: 30, descricao: '30' },
                                             { id: 60, descricao: '60' },
                                             { id: 90, descricao: '90' },
                                             { id: 120, descricao: '120' },
                                             { id: 150, descricao: '150' },
                                             { id: 180, descricao: '180' },
                                             { id: 210, descricao: '210' },
                                             { id: 240, descricao: '240' },
                                             { id: 270, descricao: '270' },
                                             { id: 300, descricao: '300' },
                                             { id: 330, descricao: '330' },
                                             { id: 360, descricao: '360' },
                                             { id: 390, descricao: '390' },
                                             { id: 420, descricao: '420' },
                                             { id: 450, descricao: '450' },
                                             { id: 480, descricao: '480' },
                                             { id: 510, descricao: '510' },
                                             { id: 540, descricao: '540' },
                                             { id: 570, descricao: '570' },
                                             { id: 600, descricao: '600' },
                                             { id: 630, descricao: '630' },
                                             { id: 660, descricao: '660' },
                                             { id: 690, descricao: '690' },
                                             { id: 720, descricao: '720' },
                                             { id: 750, descricao: '750' },
                                             { id: 780, descricao: '780' },
                                             { id: 810, descricao: '810' },
                                             { id: 840, descricao: '840' },
                                             { id: 870, descricao: '870' },
                                             { id: 900, descricao: '900' },
                                             { id: 930, descricao: '930' },
                                             { id: 960, descricao: '960' },
                                             { id: 990, descricao: '990' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoDuracao = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoDuracao = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.vazamentoMaximo}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.vazamentoMaximo}
                                          options={[
                                             { id: 50, descricao: '50' },
                                             { id: 60, descricao: '60' },
                                             { id: 70, descricao: '70' },
                                             { id: 80, descricao: '80' },
                                             { id: 90, descricao: '90' },
                                             { id: 100, descricao: '100' },
                                             { id: 110, descricao: '110' },
                                             { id: 120, descricao: '120' },
                                             { id: 130, descricao: '130' },
                                             { id: 140, descricao: '140' },
                                             { id: 150, descricao: '150' },
                                             { id: 160, descricao: '160' },
                                             { id: 170, descricao: '170' },
                                             { id: 180, descricao: '180' },
                                             { id: 190, descricao: '190' },
                                             { id: 200, descricao: '200' },
                                             { id: 210, descricao: '210' },
                                             { id: 220, descricao: '220' },
                                             { id: 230, descricao: '230' },
                                             { id: 240, descricao: '240' },
                                             { id: 250, descricao: '250' },
                                             { id: 260, descricao: '260' },
                                             { id: 270, descricao: '270' },
                                             { id: 280, descricao: '280' },
                                             { id: 290, descricao: '290' },
                                             { id: 300, descricao: '300' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.vazamentoMaximo = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.vazamentoMaximo = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.distribuicaoDeTemperatura && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={getNormasAQT_DistribuicaoDeTemperatura(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.erroTemperatura}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.erroTemperatura}
                                          options={[
                                             { id: 0.1, descricao: '0,1' },
                                             { id: 0.2, descricao: '0,2' },
                                             { id: 0.3, descricao: '0,3' },
                                             { id: 0.4, descricao: '0,4' },
                                             { id: 0.5, descricao: '0,5' },
                                             { id: 0.6, descricao: '0,6' },
                                             { id: 0.7, descricao: '0,7' },
                                             { id: 0.8, descricao: '0,8' },
                                             { id: 0.9, descricao: '0,9' },
                                             { id: 1.0, descricao: '1' },
                                             { id: 1.1, descricao: '1,1' },
                                             { id: 1.2, descricao: '1,2' },
                                             { id: 1.3, descricao: '1,3' },
                                             { id: 1.4, descricao: '1,4' },
                                             { id: 1.5, descricao: '1,5' },
                                             { id: 1.6, descricao: '1,6' },
                                             { id: 1.7, descricao: '1,7' },
                                             { id: 1.8, descricao: '1,8' },
                                             { id: 1.9, descricao: '1,9' },
                                             { id: 2.0, descricao: '2' },
                                             { id: 2.1, descricao: '2,1' },
                                             { id: 2.2, descricao: '2,2' },
                                             { id: 2.3, descricao: '2,3' },
                                             { id: 2.4, descricao: '2,4' },
                                             { id: 2.5, descricao: '2,5' },
                                             { id: 2.6, descricao: '2,6' },
                                             { id: 2.7, descricao: '2,7' },
                                             { id: 2.8, descricao: '2,8' },
                                             { id: 2.9, descricao: '2,9' },
                                             { id: 3.0, descricao: '3' },
                                             { id: 3.1, descricao: '3,1' },
                                             { id: 3.2, descricao: '3,2' },
                                             { id: 3.3, descricao: '3,3' },
                                             { id: 3.4, descricao: '3,4' },
                                             { id: 3.5, descricao: '3,5' },
                                             { id: 3.6, descricao: '3,6' },
                                             { id: 3.7, descricao: '3,7' },
                                             { id: 3.8, descricao: '3,8' },
                                             { id: 3.9, descricao: '3,9' },
                                             { id: 4.0, descricao: '4' },
                                             { id: 4.1, descricao: '4,1' },
                                             { id: 4.2, descricao: '4,2' },
                                             { id: 4.3, descricao: '4,3' },
                                             { id: 4.4, descricao: '4,4' },
                                             { id: 4.5, descricao: '4,5' },
                                             { id: 4.6, descricao: '4,6' },
                                             { id: 4.7, descricao: '4,7' },
                                             { id: 4.8, descricao: '4,8' },
                                             { id: 4.9, descricao: '4,9' },
                                             { id: 5.0, descricao: '5' },
                                             { id: 5.1, descricao: '5,1' },
                                             { id: 5.2, descricao: '5,2' },
                                             { id: 5.3, descricao: '5,3' },
                                             { id: 5.4, descricao: '5,4' },
                                             { id: 5.5, descricao: '5,5' },
                                             { id: 5.6, descricao: '5,6' },
                                             { id: 5.7, descricao: '5,7' },
                                             { id: 5.8, descricao: '5,8' },
                                             { id: 5.9, descricao: '5,9' },
                                             { id: 6.0, descricao: '6' },
                                             { id: 6.1, descricao: '6,1' },
                                             { id: 6.2, descricao: '6,2' },
                                             { id: 6.3, descricao: '6,3' },
                                             { id: 6.4, descricao: '6,4' },
                                             { id: 6.5, descricao: '6,5' },
                                             { id: 6.6, descricao: '6,6' },
                                             { id: 6.7, descricao: '6,7' },
                                             { id: 6.8, descricao: '6,8' },
                                             { id: 6.9, descricao: '6,9' },
                                             { id: 7.0, descricao: '7' },
                                             { id: 7.1, descricao: '7,1' },
                                             { id: 7.2, descricao: '7,2' },
                                             { id: 7.3, descricao: '7,3' },
                                             { id: 7.4, descricao: '7,4' },
                                             { id: 7.5, descricao: '7,5' },
                                             { id: 7.6, descricao: '7,6' },
                                             { id: 7.7, descricao: '7,7' },
                                             { id: 7.8, descricao: '7,8' },
                                             { id: 7.9, descricao: '7,9' },
                                             { id: 8.0, descricao: '8' },
                                             { id: 8.1, descricao: '8,1' },
                                             { id: 8.2, descricao: '8,2' },
                                             { id: 8.3, descricao: '8,3' },
                                             { id: 8.4, descricao: '8,4' },
                                             { id: 8.5, descricao: '8,5' },
                                             { id: 8.6, descricao: '8,6' },
                                             { id: 8.7, descricao: '8,7' },
                                             { id: 8.8, descricao: '8,8' },
                                             { id: 8.9, descricao: '8,9' },
                                             { id: 9.0, descricao: '9' },
                                             { id: 9.1, descricao: '9,1' },
                                             { id: 9.2, descricao: '9,2' },
                                             { id: 9.3, descricao: '9,3' },
                                             { id: 9.4, descricao: '9,4' },
                                             { id: 9.5, descricao: '9,5' },
                                             { id: 9.6, descricao: '9,6' },
                                             { id: 9.7, descricao: '9,7' },
                                             { id: 9.8, descricao: '9,8' },
                                             { id: 9.9, descricao: '9,9' },
                                             { id: 10, descricao: '10' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.erroTemperatura = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.erroTemperatura = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaOperacao}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaOperacao}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.temperaturaOperacao = e.floatValue;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.deltaTemperatura}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.deltaTemperatura}
                                          options={[
                                             { id: 0.1, descricao: '0,1' },
                                             { id: 0.2, descricao: '0,2' },
                                             { id: 0.3, descricao: '0,3' },
                                             { id: 0.4, descricao: '0,4' },
                                             { id: 0.5, descricao: '0,5' },
                                             { id: 0.6, descricao: '0,6' },
                                             { id: 0.7, descricao: '0,7' },
                                             { id: 0.8, descricao: '0,8' },
                                             { id: 0.9, descricao: '0,9' },
                                             { id: 1.0, descricao: '1' },
                                             { id: 1.1, descricao: '1,1' },
                                             { id: 1.2, descricao: '1,2' },
                                             { id: 1.3, descricao: '1,3' },
                                             { id: 1.4, descricao: '1,4' },
                                             { id: 1.5, descricao: '1,5' },
                                             { id: 1.6, descricao: '1,6' },
                                             { id: 1.7, descricao: '1,7' },
                                             { id: 1.8, descricao: '1,8' },
                                             { id: 1.9, descricao: '1,9' },
                                             { id: 2.0, descricao: '2' },
                                             { id: 2.1, descricao: '2,1' },
                                             { id: 2.2, descricao: '2,2' },
                                             { id: 2.3, descricao: '2,3' },
                                             { id: 2.4, descricao: '2,4' },
                                             { id: 2.5, descricao: '2,5' },
                                             { id: 2.6, descricao: '2,6' },
                                             { id: 2.7, descricao: '2,7' },
                                             { id: 2.8, descricao: '2,8' },
                                             { id: 2.9, descricao: '2,9' },
                                             { id: 3.0, descricao: '3' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.deltaTemperatura = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.deltaTemperatura = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoEntreMedidas}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoEntreMedidas}
                                          options={[
                                             { id: 1, descricao: '1' },
                                             { id: 2, descricao: '2' },
                                             { id: 3, descricao: '3' },
                                             { id: 4, descricao: '4' },
                                             { id: 5, descricao: '5' },
                                             { id: 6, descricao: '6' },
                                             { id: 7, descricao: '7' },
                                             { id: 8, descricao: '8' },
                                             { id: 9, descricao: '9' },
                                             { id: 10, descricao: '10' },
                                             { id: 11, descricao: '11' },
                                             { id: 12, descricao: '12' },
                                             { id: 13, descricao: '13' },
                                             { id: 14, descricao: '14' },
                                             { id: 15, descricao: '15' },
                                             { id: 16, descricao: '16' },
                                             { id: 17, descricao: '17' },
                                             { id: 18, descricao: '18' },
                                             { id: 19, descricao: '19' },
                                             { id: 20, descricao: '20' },
                                             { id: 21, descricao: '21' },
                                             { id: 22, descricao: '22' },
                                             { id: 23, descricao: '23' },
                                             { id: 24, descricao: '24' },
                                             { id: 25, descricao: '25' },
                                             { id: 26, descricao: '26' },
                                             { id: 27, descricao: '27' },
                                             { id: 28, descricao: '28' },
                                             { id: 29, descricao: '29' },
                                             { id: 30, descricao: '30' },
                                             { id: 31, descricao: '31' },
                                             { id: 32, descricao: '32' },
                                             { id: 33, descricao: '33' },
                                             { id: 34, descricao: '34' },
                                             { id: 35, descricao: '35' },
                                             { id: 36, descricao: '36' },
                                             { id: 37, descricao: '37' },
                                             { id: 38, descricao: '38' },
                                             { id: 39, descricao: '39' },
                                             { id: 40, descricao: '40' },
                                             { id: 41, descricao: '41' },
                                             { id: 42, descricao: '42' },
                                             { id: 43, descricao: '43' },
                                             { id: 44, descricao: '44' },
                                             { id: 45, descricao: '45' },
                                             { id: 46, descricao: '46' },
                                             { id: 47, descricao: '47' },
                                             { id: 48, descricao: '48' },
                                             { id: 49, descricao: '49' },
                                             { id: 50, descricao: '50' },
                                             { id: 51, descricao: '51' },
                                             { id: 52, descricao: '52' },
                                             { id: 53, descricao: '53' },
                                             { id: 54, descricao: '54' },
                                             { id: 55, descricao: '55' },
                                             { id: 56, descricao: '56' },
                                             { id: 57, descricao: '57' },
                                             { id: 58, descricao: '58' },
                                             { id: 59, descricao: '59' },
                                             { id: 60, descricao: '60' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTipoCarga = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase1Esperado}</Label>
                                       {!this.state.esconderTempoFase1Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase1Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase1Esperado = i.id;
                                                      if (state.configuracaoSelecionada.tempoFase1Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                         state.esconderTempoFase2Esperado = true;
                                                         state.esconderTempoFase3Esperado = true;
                                                         state.esconderTempoFase4Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase2Esperado = false;
                                                      state.esconderTempoFase3Esperado = false;
                                                      state.esconderTempoFase4Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase2Esperado}</Label>
                                       {!this.state.esconderTempoFase2Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase2Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase2Esperado = i.id;
                                                      if (state.configuracaoSelecionada.tempoFase2Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                         state.esconderTempoFase1Esperado = true;
                                                         state.esconderTempoFase3Esperado = true;
                                                         state.esconderTempoFase4Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase1Esperado = false;
                                                      state.esconderTempoFase3Esperado = false;
                                                      state.esconderTempoFase4Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase3Esperado}</Label>
                                       {!this.state.esconderTempoFase3Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase3Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase3Esperado = i.id;
                                                      if (state.configuracaoSelecionada.tempoFase3Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                         state.esconderTempoFase1Esperado = true;
                                                         state.esconderTempoFase2Esperado = true;
                                                         state.esconderTempoFase4Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase1Esperado = false;
                                                      state.esconderTempoFase2Esperado = false;
                                                      state.esconderTempoFase4Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase4Esperado}</Label>
                                       {!this.state.esconderTempoFase4Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase4Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase4Esperado = i.id;

                                                      if (state.configuracaoSelecionada.tempoFase4Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                         state.esconderTempoFase1Esperado = true;
                                                         state.esconderTempoFase2Esperado = true;
                                                         state.esconderTempoFase3Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase1Esperado = false;
                                                      state.esconderTempoFase2Esperado = false;
                                                      state.esconderTempoFase3Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.penetracaoDeTemperatura && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={[{ id: 2, descricao: '16328' }]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTipoCarga = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaOperacao}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaOperacao}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.temperaturaOperacao = e.floatValue;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.deltaTemperatura}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.deltaTemperatura}
                                          options={[
                                             { id: 0.1, descricao: '0,1' },
                                             { id: 0.2, descricao: '0,2' },
                                             { id: 0.3, descricao: '0,3' },
                                             { id: 0.4, descricao: '0,4' },
                                             { id: 0.5, descricao: '0,5' },
                                             { id: 0.6, descricao: '0,6' },
                                             { id: 0.7, descricao: '0,7' },
                                             { id: 0.8, descricao: '0,8' },
                                             { id: 0.9, descricao: '0,9' },
                                             { id: 1.0, descricao: '1' },
                                             { id: 1.1, descricao: '1,1' },
                                             { id: 1.2, descricao: '1,2' },
                                             { id: 1.3, descricao: '1,3' },
                                             { id: 1.4, descricao: '1,4' },
                                             { id: 1.5, descricao: '1,5' },
                                             { id: 1.6, descricao: '1,6' },
                                             { id: 1.7, descricao: '1,7' },
                                             { id: 1.8, descricao: '1,8' },
                                             { id: 1.9, descricao: '1,9' },
                                             { id: 2.0, descricao: '2' },
                                             { id: 2.1, descricao: '2,1' },
                                             { id: 2.2, descricao: '2,2' },
                                             { id: 2.3, descricao: '2,3' },
                                             { id: 2.4, descricao: '2,4' },
                                             { id: 2.5, descricao: '2,5' },
                                             { id: 2.6, descricao: '2,6' },
                                             { id: 2.7, descricao: '2,7' },
                                             { id: 2.8, descricao: '2,8' },
                                             { id: 2.9, descricao: '2,9' },
                                             { id: 3.0, descricao: '3' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.deltaTemperatura = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.deltaTemperatura = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.erroTemperatura}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.erroTemperatura}
                                          options={[
                                             { id: 0.1, descricao: '0,1' },
                                             { id: 0.2, descricao: '0,2' },
                                             { id: 0.3, descricao: '0,3' },
                                             { id: 0.4, descricao: '0,4' },
                                             { id: 0.5, descricao: '0,5' },
                                             { id: 0.6, descricao: '0,6' },
                                             { id: 0.7, descricao: '0,7' },
                                             { id: 0.8, descricao: '0,8' },
                                             { id: 0.9, descricao: '0,9' },
                                             { id: 1.0, descricao: '1' },
                                             { id: 1.1, descricao: '1,1' },
                                             { id: 1.2, descricao: '1,2' },
                                             { id: 1.3, descricao: '1,3' },
                                             { id: 1.4, descricao: '1,4' },
                                             { id: 1.5, descricao: '1,5' },
                                             { id: 1.6, descricao: '1,6' },
                                             { id: 1.7, descricao: '1,7' },
                                             { id: 1.8, descricao: '1,8' },
                                             { id: 1.9, descricao: '1,9' },
                                             { id: 2.0, descricao: '2' },
                                             { id: 2.1, descricao: '2,1' },
                                             { id: 2.2, descricao: '2,2' },
                                             { id: 2.3, descricao: '2,3' },
                                             { id: 2.4, descricao: '2,4' },
                                             { id: 2.5, descricao: '2,5' },
                                             { id: 2.6, descricao: '2,6' },
                                             { id: 2.7, descricao: '2,7' },
                                             { id: 2.8, descricao: '2,8' },
                                             { id: 2.9, descricao: '2,9' },
                                             { id: 3.0, descricao: '3' },
                                             { id: 3.1, descricao: '3,1' },
                                             { id: 3.2, descricao: '3,2' },
                                             { id: 3.3, descricao: '3,3' },
                                             { id: 3.4, descricao: '3,4' },
                                             { id: 3.5, descricao: '3,5' },
                                             { id: 3.6, descricao: '3,6' },
                                             { id: 3.7, descricao: '3,7' },
                                             { id: 3.8, descricao: '3,8' },
                                             { id: 3.9, descricao: '3,9' },
                                             { id: 4.0, descricao: '4' },
                                             { id: 4.1, descricao: '4,1' },
                                             { id: 4.2, descricao: '4,2' },
                                             { id: 4.3, descricao: '4,3' },
                                             { id: 4.4, descricao: '4,4' },
                                             { id: 4.5, descricao: '4,5' },
                                             { id: 4.6, descricao: '4,6' },
                                             { id: 4.7, descricao: '4,7' },
                                             { id: 4.8, descricao: '4,8' },
                                             { id: 4.9, descricao: '4,9' },
                                             { id: 5.0, descricao: '5' },
                                             { id: 5.1, descricao: '5,1' },
                                             { id: 5.2, descricao: '5,2' },
                                             { id: 5.3, descricao: '5,3' },
                                             { id: 5.4, descricao: '5,4' },
                                             { id: 5.5, descricao: '5,5' },
                                             { id: 5.6, descricao: '5,6' },
                                             { id: 5.7, descricao: '5,7' },
                                             { id: 5.8, descricao: '5,8' },
                                             { id: 5.9, descricao: '5,9' },
                                             { id: 6.0, descricao: '6' },
                                             { id: 6.1, descricao: '6,1' },
                                             { id: 6.2, descricao: '6,2' },
                                             { id: 6.3, descricao: '6,3' },
                                             { id: 6.4, descricao: '6,4' },
                                             { id: 6.5, descricao: '6,5' },
                                             { id: 6.6, descricao: '6,6' },
                                             { id: 6.7, descricao: '6,7' },
                                             { id: 6.8, descricao: '6,8' },
                                             { id: 6.9, descricao: '6,9' },
                                             { id: 7.0, descricao: '7' },
                                             { id: 7.1, descricao: '7,1' },
                                             { id: 7.2, descricao: '7,2' },
                                             { id: 7.3, descricao: '7,3' },
                                             { id: 7.4, descricao: '7,4' },
                                             { id: 7.5, descricao: '7,5' },
                                             { id: 7.6, descricao: '7,6' },
                                             { id: 7.7, descricao: '7,7' },
                                             { id: 7.8, descricao: '7,8' },
                                             { id: 7.9, descricao: '7,9' },
                                             { id: 8.0, descricao: '8' },
                                             { id: 8.1, descricao: '8,1' },
                                             { id: 8.2, descricao: '8,2' },
                                             { id: 8.3, descricao: '8,3' },
                                             { id: 8.4, descricao: '8,4' },
                                             { id: 8.5, descricao: '8,5' },
                                             { id: 8.6, descricao: '8,6' },
                                             { id: 8.7, descricao: '8,7' },
                                             { id: 8.8, descricao: '8,8' },
                                             { id: 8.9, descricao: '8,9' },
                                             { id: 9.0, descricao: '9' },
                                             { id: 9.1, descricao: '9,1' },
                                             { id: 9.2, descricao: '9,2' },
                                             { id: 9.3, descricao: '9,3' },
                                             { id: 9.4, descricao: '9,4' },
                                             { id: 9.5, descricao: '9,5' },
                                             { id: 9.6, descricao: '9,6' },
                                             { id: 9.7, descricao: '9,7' },
                                             { id: 9.8, descricao: '9,8' },
                                             { id: 9.9, descricao: '9,9' },
                                             { id: 10, descricao: '10' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.erroTemperatura = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.erroTemperatura = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tipoLetalidade}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoLetalidade}
                                          options={[
                                             { id: 0, descricao: 'A0' },
                                             { id: 1, descricao: 'F0' },
                                             { id: 2, descricao: 'FH' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoLetalidade = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoLetalidade = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.populacaoInicialMantissa}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.populacaoInicialMantissa}
                                          options={[
                                             { id: 0.1, descricao: '0,1' },
                                             { id: 0.2, descricao: '0,2' },
                                             { id: 0.3, descricao: '0,3' },
                                             { id: 0.4, descricao: '0,4' },
                                             { id: 0.5, descricao: '0,5' },
                                             { id: 0.6, descricao: '0,6' },
                                             { id: 0.7, descricao: '0,7' },
                                             { id: 0.8, descricao: '0,8' },
                                             { id: 0.9, descricao: '0,9' },
                                             { id: 1.0, descricao: '1' },
                                             { id: 1.1, descricao: '1,1' },
                                             { id: 1.2, descricao: '1,2' },
                                             { id: 1.3, descricao: '1,3' },
                                             { id: 1.4, descricao: '1,4' },
                                             { id: 1.5, descricao: '1,5' },
                                             { id: 1.6, descricao: '1,6' },
                                             { id: 1.7, descricao: '1,7' },
                                             { id: 1.8, descricao: '1,8' },
                                             { id: 1.9, descricao: '1,9' },
                                             { id: 2.0, descricao: '2' },
                                             { id: 2.1, descricao: '2,1' },
                                             { id: 2.2, descricao: '2,2' },
                                             { id: 2.3, descricao: '2,3' },
                                             { id: 2.4, descricao: '2,4' },
                                             { id: 2.5, descricao: '2,5' },
                                             { id: 2.6, descricao: '2,6' },
                                             { id: 2.7, descricao: '2,7' },
                                             { id: 2.8, descricao: '2,8' },
                                             { id: 2.9, descricao: '2,9' },
                                             { id: 3.0, descricao: '3' },
                                             { id: 3.1, descricao: '3,1' },
                                             { id: 3.2, descricao: '3,2' },
                                             { id: 3.3, descricao: '3,3' },
                                             { id: 3.4, descricao: '3,4' },
                                             { id: 3.5, descricao: '3,5' },
                                             { id: 3.6, descricao: '3,6' },
                                             { id: 3.7, descricao: '3,7' },
                                             { id: 3.8, descricao: '3,8' },
                                             { id: 3.9, descricao: '3,9' },
                                             { id: 4.0, descricao: '4' },
                                             { id: 4.1, descricao: '4,1' },
                                             { id: 4.2, descricao: '4,2' },
                                             { id: 4.3, descricao: '4,3' },
                                             { id: 4.4, descricao: '4,4' },
                                             { id: 4.5, descricao: '4,5' },
                                             { id: 4.6, descricao: '4,6' },
                                             { id: 4.7, descricao: '4,7' },
                                             { id: 4.8, descricao: '4,8' },
                                             { id: 4.9, descricao: '4,9' },
                                             { id: 5.0, descricao: '5' },
                                             { id: 5.1, descricao: '5,1' },
                                             { id: 5.2, descricao: '5,2' },
                                             { id: 5.3, descricao: '5,3' },
                                             { id: 5.4, descricao: '5,4' },
                                             { id: 5.5, descricao: '5,5' },
                                             { id: 5.6, descricao: '5,6' },
                                             { id: 5.7, descricao: '5,7' },
                                             { id: 5.8, descricao: '5,8' },
                                             { id: 5.9, descricao: '5,9' },
                                             { id: 6.0, descricao: '6' },
                                             { id: 6.1, descricao: '6,1' },
                                             { id: 6.2, descricao: '6,2' },
                                             { id: 6.3, descricao: '6,3' },
                                             { id: 6.4, descricao: '6,4' },
                                             { id: 6.5, descricao: '6,5' },
                                             { id: 6.6, descricao: '6,6' },
                                             { id: 6.7, descricao: '6,7' },
                                             { id: 6.8, descricao: '6,8' },
                                             { id: 6.9, descricao: '6,9' },
                                             { id: 7.0, descricao: '7' },
                                             { id: 7.1, descricao: '7,1' },
                                             { id: 7.2, descricao: '7,2' },
                                             { id: 7.3, descricao: '7,3' },
                                             { id: 7.4, descricao: '7,4' },
                                             { id: 7.5, descricao: '7,5' },
                                             { id: 7.6, descricao: '7,6' },
                                             { id: 7.7, descricao: '7,7' },
                                             { id: 7.8, descricao: '7,8' },
                                             { id: 7.9, descricao: '7,9' },
                                             { id: 8.0, descricao: '8' },
                                             { id: 8.1, descricao: '8,1' },
                                             { id: 8.2, descricao: '8,2' },
                                             { id: 8.3, descricao: '8,3' },
                                             { id: 8.4, descricao: '8,4' },
                                             { id: 8.5, descricao: '8,5' },
                                             { id: 8.6, descricao: '8,6' },
                                             { id: 8.7, descricao: '8,7' },
                                             { id: 8.8, descricao: '8,8' },
                                             { id: 8.9, descricao: '8,9' },
                                             { id: 9.0, descricao: '9' },
                                             { id: 9.1, descricao: '9,1' },
                                             { id: 9.2, descricao: '9,2' },
                                             { id: 9.3, descricao: '9,3' },
                                             { id: 9.4, descricao: '9,4' },
                                             { id: 9.5, descricao: '9,5' },
                                             { id: 9.6, descricao: '9,6' },
                                             { id: 9.7, descricao: '9,7' },
                                             { id: 9.8, descricao: '9,8' },
                                             { id: 9.9, descricao: '9,9' },
                                             { id: 10, descricao: '10' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.populacaoInicialMantissa = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.populacaoInicialMantissa = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.populacaoInicialExpoente}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.populacaoInicialExpoente}
                                          options={[
                                             {
                                                id: 3,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '3',
                                             },
                                             {
                                                id: 4,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '4',
                                             },
                                             {
                                                id: 5,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '5',
                                             },
                                             {
                                                id: 6,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '6',
                                             },
                                             {
                                                id: 7,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '7',
                                             },
                                             {
                                                id: 8,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '8',
                                             },
                                             {
                                                id: 9,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '9',
                                             },
                                             {
                                                id: 10,
                                                descricao:
                                                   numberToString(
                                                      this.state.configuracaoSelecionada.populacaoInicialMantissa,
                                                      1
                                                   ) +
                                                   'e' +
                                                   '10',
                                             },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.populacaoInicialExpoente = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.populacaoInicialExpoente = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaD}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaD}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.temperaturaD = e.floatValue;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.valorD}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.valorD}
                                          options={[
                                             { id: 1, descricao: '1' },
                                             { id: 2, descricao: '2' },
                                             { id: 3, descricao: '3' },
                                             { id: 4, descricao: '4' },
                                             { id: 5, descricao: '5' },
                                             { id: 6, descricao: '6' },
                                             { id: 7, descricao: '7' },
                                             { id: 8, descricao: '8' },
                                             { id: 9, descricao: '9' },
                                             { id: 10, descricao: '10' },
                                             { id: 11, descricao: '11' },
                                             { id: 12, descricao: '12' },
                                             { id: 13, descricao: '13' },
                                             { id: 14, descricao: '14' },
                                             { id: 15, descricao: '15' },
                                             { id: 16, descricao: '16' },
                                             { id: 17, descricao: '17' },
                                             { id: 18, descricao: '18' },
                                             { id: 19, descricao: '19' },
                                             { id: 20, descricao: '20' },
                                             { id: 21, descricao: '21' },
                                             { id: 22, descricao: '22' },
                                             { id: 23, descricao: '23' },
                                             { id: 24, descricao: '24' },
                                             { id: 25, descricao: '25' },
                                             { id: 26, descricao: '26' },
                                             { id: 27, descricao: '27' },
                                             { id: 28, descricao: '28' },
                                             { id: 29, descricao: '29' },
                                             { id: 30, descricao: '30' },
                                             { id: 31, descricao: '31' },
                                             { id: 32, descricao: '32' },
                                             { id: 33, descricao: '33' },
                                             { id: 34, descricao: '34' },
                                             { id: 35, descricao: '35' },
                                             { id: 36, descricao: '36' },
                                             { id: 37, descricao: '37' },
                                             { id: 38, descricao: '38' },
                                             { id: 39, descricao: '39' },
                                             { id: 40, descricao: '40' },
                                             { id: 41, descricao: '41' },
                                             { id: 42, descricao: '42' },
                                             { id: 43, descricao: '43' },
                                             { id: 44, descricao: '44' },
                                             { id: 45, descricao: '45' },
                                             { id: 46, descricao: '46' },
                                             { id: 47, descricao: '47' },
                                             { id: 48, descricao: '48' },
                                             { id: 49, descricao: '49' },
                                             { id: 50, descricao: '50' },
                                             { id: 51, descricao: '51' },
                                             { id: 52, descricao: '52' },
                                             { id: 53, descricao: '53' },
                                             { id: 54, descricao: '54' },
                                             { id: 55, descricao: '55' },
                                             { id: 56, descricao: '56' },
                                             { id: 57, descricao: '57' },
                                             { id: 58, descricao: '58' },
                                             { id: 59, descricao: '59' },
                                             { id: 60, descricao: '60' },
                                             { id: 61, descricao: '61' },
                                             { id: 62, descricao: '62' },
                                             { id: 63, descricao: '63' },
                                             { id: 64, descricao: '64' },
                                             { id: 65, descricao: '65' },
                                             { id: 66, descricao: '66' },
                                             { id: 67, descricao: '67' },
                                             { id: 68, descricao: '68' },
                                             { id: 69, descricao: '69' },
                                             { id: 70, descricao: '70' },
                                             { id: 71, descricao: '71' },
                                             { id: 72, descricao: '72' },
                                             { id: 73, descricao: '73' },
                                             { id: 74, descricao: '74' },
                                             { id: 75, descricao: '75' },
                                             { id: 76, descricao: '76' },
                                             { id: 77, descricao: '77' },
                                             { id: 78, descricao: '78' },
                                             { id: 79, descricao: '79' },
                                             { id: 80, descricao: '80' },
                                             { id: 81, descricao: '81' },
                                             { id: 82, descricao: '82' },
                                             { id: 83, descricao: '83' },
                                             { id: 84, descricao: '84' },
                                             { id: 85, descricao: '85' },
                                             { id: 86, descricao: '86' },
                                             { id: 87, descricao: '87' },
                                             { id: 88, descricao: '88' },
                                             { id: 89, descricao: '89' },
                                             { id: 90, descricao: '90' },
                                             { id: 91, descricao: '91' },
                                             { id: 92, descricao: '92' },
                                             { id: 93, descricao: '93' },
                                             { id: 94, descricao: '94' },
                                             { id: 95, descricao: '95' },
                                             { id: 96, descricao: '96' },
                                             { id: 97, descricao: '97' },
                                             { id: 98, descricao: '98' },
                                             { id: 99, descricao: '99' },
                                             { id: 100, descricao: '100' },
                                             { id: 101, descricao: '101' },
                                             { id: 102, descricao: '102' },
                                             { id: 103, descricao: '103' },
                                             { id: 104, descricao: '104' },
                                             { id: 105, descricao: '105' },
                                             { id: 106, descricao: '106' },
                                             { id: 107, descricao: '107' },
                                             { id: 108, descricao: '108' },
                                             { id: 109, descricao: '109' },
                                             { id: 110, descricao: '110' },
                                             { id: 111, descricao: '111' },
                                             { id: 112, descricao: '112' },
                                             { id: 113, descricao: '113' },
                                             { id: 114, descricao: '114' },
                                             { id: 115, descricao: '115' },
                                             { id: 116, descricao: '116' },
                                             { id: 117, descricao: '117' },
                                             { id: 118, descricao: '118' },
                                             { id: 119, descricao: '119' },
                                             { id: 120, descricao: '120' },
                                             { id: 121, descricao: '121' },
                                             { id: 122, descricao: '122' },
                                             { id: 123, descricao: '123' },
                                             { id: 124, descricao: '124' },
                                             { id: 125, descricao: '125' },
                                             { id: 126, descricao: '126' },
                                             { id: 127, descricao: '127' },
                                             { id: 128, descricao: '128' },
                                             { id: 129, descricao: '129' },
                                             { id: 130, descricao: '130' },
                                             { id: 131, descricao: '131' },
                                             { id: 132, descricao: '132' },
                                             { id: 133, descricao: '133' },
                                             { id: 134, descricao: '134' },
                                             { id: 135, descricao: '135' },
                                             { id: 136, descricao: '136' },
                                             { id: 137, descricao: '137' },
                                             { id: 138, descricao: '138' },
                                             { id: 139, descricao: '139' },
                                             { id: 140, descricao: '140' },
                                             { id: 141, descricao: '141' },
                                             { id: 142, descricao: '142' },
                                             { id: 143, descricao: '143' },
                                             { id: 144, descricao: '144' },
                                             { id: 145, descricao: '145' },
                                             { id: 146, descricao: '146' },
                                             { id: 147, descricao: '147' },
                                             { id: 148, descricao: '148' },
                                             { id: 149, descricao: '149' },
                                             { id: 150, descricao: '150' },
                                             { id: 151, descricao: '151' },
                                             { id: 152, descricao: '152' },
                                             { id: 153, descricao: '153' },
                                             { id: 154, descricao: '154' },
                                             { id: 155, descricao: '155' },
                                             { id: 156, descricao: '156' },
                                             { id: 157, descricao: '157' },
                                             { id: 158, descricao: '158' },
                                             { id: 159, descricao: '159' },
                                             { id: 160, descricao: '160' },
                                             { id: 161, descricao: '161' },
                                             { id: 162, descricao: '162' },
                                             { id: 163, descricao: '163' },
                                             { id: 164, descricao: '164' },
                                             { id: 165, descricao: '165' },
                                             { id: 166, descricao: '166' },
                                             { id: 167, descricao: '167' },
                                             { id: 168, descricao: '168' },
                                             { id: 169, descricao: '169' },
                                             { id: 170, descricao: '170' },
                                             { id: 171, descricao: '171' },
                                             { id: 172, descricao: '172' },
                                             { id: 173, descricao: '173' },
                                             { id: 174, descricao: '174' },
                                             { id: 175, descricao: '175' },
                                             { id: 176, descricao: '176' },
                                             { id: 177, descricao: '177' },
                                             { id: 178, descricao: '178' },
                                             { id: 179, descricao: '179' },
                                             { id: 180, descricao: '180' },
                                             { id: 181, descricao: '181' },
                                             { id: 182, descricao: '182' },
                                             { id: 183, descricao: '183' },
                                             { id: 184, descricao: '184' },
                                             { id: 185, descricao: '185' },
                                             { id: 186, descricao: '186' },
                                             { id: 187, descricao: '187' },
                                             { id: 188, descricao: '188' },
                                             { id: 189, descricao: '189' },
                                             { id: 190, descricao: '190' },
                                             { id: 191, descricao: '191' },
                                             { id: 192, descricao: '192' },
                                             { id: 193, descricao: '193' },
                                             { id: 194, descricao: '194' },
                                             { id: 195, descricao: '195' },
                                             { id: 196, descricao: '196' },
                                             { id: 197, descricao: '197' },
                                             { id: 198, descricao: '198' },
                                             { id: 199, descricao: '199' },
                                             { id: 200, descricao: '200' },
                                             { id: 201, descricao: '201' },
                                             { id: 202, descricao: '202' },
                                             { id: 203, descricao: '203' },
                                             { id: 204, descricao: '204' },
                                             { id: 205, descricao: '205' },
                                             { id: 206, descricao: '206' },
                                             { id: 207, descricao: '207' },
                                             { id: 208, descricao: '208' },
                                             { id: 209, descricao: '209' },
                                             { id: 210, descricao: '210' },
                                             { id: 211, descricao: '211' },
                                             { id: 212, descricao: '212' },
                                             { id: 213, descricao: '213' },
                                             { id: 214, descricao: '214' },
                                             { id: 215, descricao: '215' },
                                             { id: 216, descricao: '216' },
                                             { id: 217, descricao: '217' },
                                             { id: 218, descricao: '218' },
                                             { id: 219, descricao: '219' },
                                             { id: 220, descricao: '220' },
                                             { id: 221, descricao: '221' },
                                             { id: 222, descricao: '222' },
                                             { id: 223, descricao: '223' },
                                             { id: 224, descricao: '224' },
                                             { id: 225, descricao: '225' },
                                             { id: 226, descricao: '226' },
                                             { id: 227, descricao: '227' },
                                             { id: 228, descricao: '228' },
                                             { id: 229, descricao: '229' },
                                             { id: 230, descricao: '230' },
                                             { id: 231, descricao: '231' },
                                             { id: 232, descricao: '232' },
                                             { id: 233, descricao: '233' },
                                             { id: 234, descricao: '234' },
                                             { id: 235, descricao: '235' },
                                             { id: 236, descricao: '236' },
                                             { id: 237, descricao: '237' },
                                             { id: 238, descricao: '238' },
                                             { id: 239, descricao: '239' },
                                             { id: 240, descricao: '240' },
                                             { id: 241, descricao: '241' },
                                             { id: 242, descricao: '242' },
                                             { id: 243, descricao: '243' },
                                             { id: 244, descricao: '244' },
                                             { id: 245, descricao: '245' },
                                             { id: 246, descricao: '246' },
                                             { id: 247, descricao: '247' },
                                             { id: 248, descricao: '248' },
                                             { id: 249, descricao: '249' },
                                             { id: 250, descricao: '250' },
                                             { id: 251, descricao: '251' },
                                             { id: 252, descricao: '252' },
                                             { id: 253, descricao: '253' },
                                             { id: 254, descricao: '254' },
                                             { id: 255, descricao: '255' },
                                             { id: 256, descricao: '256' },
                                             { id: 257, descricao: '257' },
                                             { id: 258, descricao: '258' },
                                             { id: 259, descricao: '259' },
                                             { id: 260, descricao: '260' },
                                             { id: 261, descricao: '261' },
                                             { id: 262, descricao: '262' },
                                             { id: 263, descricao: '263' },
                                             { id: 264, descricao: '264' },
                                             { id: 265, descricao: '265' },
                                             { id: 266, descricao: '266' },
                                             { id: 267, descricao: '267' },
                                             { id: 268, descricao: '268' },
                                             { id: 269, descricao: '269' },
                                             { id: 270, descricao: '270' },
                                             { id: 271, descricao: '271' },
                                             { id: 272, descricao: '272' },
                                             { id: 273, descricao: '273' },
                                             { id: 274, descricao: '274' },
                                             { id: 275, descricao: '275' },
                                             { id: 276, descricao: '276' },
                                             { id: 277, descricao: '277' },
                                             { id: 278, descricao: '278' },
                                             { id: 279, descricao: '279' },
                                             { id: 280, descricao: '280' },
                                             { id: 281, descricao: '281' },
                                             { id: 282, descricao: '282' },
                                             { id: 283, descricao: '283' },
                                             { id: 284, descricao: '284' },
                                             { id: 285, descricao: '285' },
                                             { id: 286, descricao: '286' },
                                             { id: 287, descricao: '287' },
                                             { id: 288, descricao: '288' },
                                             { id: 289, descricao: '289' },
                                             { id: 290, descricao: '290' },
                                             { id: 291, descricao: '291' },
                                             { id: 292, descricao: '292' },
                                             { id: 293, descricao: '293' },
                                             { id: 294, descricao: '294' },
                                             { id: 295, descricao: '295' },
                                             { id: 296, descricao: '296' },
                                             { id: 297, descricao: '297' },
                                             { id: 298, descricao: '298' },
                                             { id: 299, descricao: '299' },
                                             { id: 300, descricao: '300' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.valorD = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.valorD = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoEntreMedidas}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoEntreMedidas}
                                          options={[
                                             { id: 1, descricao: '1' },
                                             { id: 2, descricao: '2' },
                                             { id: 3, descricao: '3' },
                                             { id: 4, descricao: '4' },
                                             { id: 5, descricao: '5' },
                                             { id: 6, descricao: '6' },
                                             { id: 7, descricao: '7' },
                                             { id: 8, descricao: '8' },
                                             { id: 9, descricao: '9' },
                                             { id: 10, descricao: '10' },
                                             { id: 11, descricao: '11' },
                                             { id: 12, descricao: '12' },
                                             { id: 13, descricao: '13' },
                                             { id: 14, descricao: '14' },
                                             { id: 15, descricao: '15' },
                                             { id: 16, descricao: '16' },
                                             { id: 17, descricao: '17' },
                                             { id: 18, descricao: '18' },
                                             { id: 19, descricao: '19' },
                                             { id: 20, descricao: '20' },
                                             { id: 21, descricao: '21' },
                                             { id: 22, descricao: '22' },
                                             { id: 23, descricao: '23' },
                                             { id: 24, descricao: '24' },
                                             { id: 25, descricao: '25' },
                                             { id: 26, descricao: '26' },
                                             { id: 27, descricao: '27' },
                                             { id: 28, descricao: '28' },
                                             { id: 29, descricao: '29' },
                                             { id: 30, descricao: '30' },
                                             { id: 31, descricao: '31' },
                                             { id: 32, descricao: '32' },
                                             { id: 33, descricao: '33' },
                                             { id: 34, descricao: '34' },
                                             { id: 35, descricao: '35' },
                                             { id: 36, descricao: '36' },
                                             { id: 37, descricao: '37' },
                                             { id: 38, descricao: '38' },
                                             { id: 39, descricao: '39' },
                                             { id: 40, descricao: '40' },
                                             { id: 41, descricao: '41' },
                                             { id: 42, descricao: '42' },
                                             { id: 43, descricao: '43' },
                                             { id: 44, descricao: '44' },
                                             { id: 45, descricao: '45' },
                                             { id: 46, descricao: '46' },
                                             { id: 47, descricao: '47' },
                                             { id: 48, descricao: '48' },
                                             { id: 49, descricao: '49' },
                                             { id: 50, descricao: '50' },
                                             { id: 51, descricao: '51' },
                                             { id: 52, descricao: '52' },
                                             { id: 53, descricao: '53' },
                                             { id: 54, descricao: '54' },
                                             { id: 55, descricao: '55' },
                                             { id: 56, descricao: '56' },
                                             { id: 57, descricao: '57' },
                                             { id: 58, descricao: '58' },
                                             { id: 59, descricao: '59' },
                                             { id: 60, descricao: '60' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase1Esperado}</Label>
                                       {!this.state.esconderTempoFase1Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase1Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase1Esperado = i.id;
                                                      if (state.configuracaoSelecionada.tempoFase1Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                         state.esconderTempoFase2Esperado = true;
                                                         state.esconderTempoFase3Esperado = true;
                                                         state.esconderTempoFase4Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase2Esperado = false;
                                                      state.esconderTempoFase3Esperado = false;
                                                      state.esconderTempoFase4Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase2Esperado}</Label>
                                       {!this.state.esconderTempoFase2Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase2Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase2Esperado = i.id;
                                                      if (state.configuracaoSelecionada.tempoFase2Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                         state.esconderTempoFase1Esperado = true;
                                                         state.esconderTempoFase3Esperado = true;
                                                         state.esconderTempoFase4Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase1Esperado = false;
                                                      state.esconderTempoFase3Esperado = false;
                                                      state.esconderTempoFase4Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase3Esperado}</Label>
                                       {!this.state.esconderTempoFase3Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase3Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase3Esperado = i.id;
                                                      if (state.configuracaoSelecionada.tempoFase3Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                         state.esconderTempoFase1Esperado = true;
                                                         state.esconderTempoFase2Esperado = true;
                                                         state.esconderTempoFase4Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase1Esperado = false;
                                                      state.esconderTempoFase2Esperado = false;
                                                      state.esconderTempoFase4Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoFase4Esperado}</Label>
                                       {!this.state.esconderTempoFase4Esperado && (
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoFase4Esperado}
                                             options={[
                                                { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: this.props.lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoFase4Esperado = i.id;

                                                      if (state.configuracaoSelecionada.tempoFase4Esperado === 86400) {
                                                         state.configuracaoSelecionada.tempoFase1Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase2Esperado = 0;
                                                         state.configuracaoSelecionada.tempoFase3Esperado = 0;
                                                         state.esconderTempoFase1Esperado = true;
                                                         state.esconderTempoFase2Esperado = true;
                                                         state.esconderTempoFase3Esperado = true;
                                                      }
                                                   } else {
                                                      state.configuracaoSelecionada.tempoFase4Esperado = 0;
                                                   }
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderTempoFase1Esperado = false;
                                                      state.esconderTempoFase2Esperado = false;
                                                      state.esconderTempoFase3Esperado = false;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.aberturaDePorta && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={[{ id: 2, descricao: '16328' }]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTipoCarga = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaInicial}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaInicial}
                                          options={[
                                             { id: -90, descricao: '-90' },
                                             { id: -85, descricao: '-85' },
                                             { id: -80, descricao: '-80' },
                                             { id: -75, descricao: '-75' },
                                             { id: -70, descricao: '-70' },
                                             { id: -65, descricao: '-65' },
                                             { id: -60, descricao: '-60' },
                                             { id: -55, descricao: '-55' },
                                             { id: -50, descricao: '-50' },
                                             { id: -45, descricao: '-45' },
                                             { id: -40, descricao: '-40' },
                                             { id: -35, descricao: '-35' },
                                             { id: -30, descricao: '-30' },
                                             { id: -25, descricao: '-25' },
                                             { id: -20, descricao: '-20' },
                                             { id: -15, descricao: '-15' },
                                             { id: -10, descricao: '-10' },
                                             { id: -5, descricao: '-5' },
                                             { id: 0, descricao: '0' },
                                             { id: 5, descricao: '5' },
                                             { id: 10, descricao: '10' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.temperaturaInicial = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.temperaturaInicial = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaLimite}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaLimite}
                                          options={[
                                             { id: 0, descricao: '0' },
                                             { id: 5, descricao: '5' },
                                             { id: 10, descricao: '10' },
                                             { id: 15, descricao: '15' },
                                             { id: 20, descricao: '20' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.temperaturaLimite = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.temperaturaLimite = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoLimite}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoLimite}
                                          options={[
                                             { id: 30, descricao: '30' },
                                             { id: 40, descricao: '40' },
                                             { id: 50, descricao: '50' },
                                             { id: 60, descricao: '60' },
                                             { id: 70, descricao: '70' },
                                             { id: 80, descricao: '80' },
                                             { id: 90, descricao: '90' },
                                             { id: 100, descricao: '100' },
                                             { id: 110, descricao: '110' },
                                             { id: 120, descricao: '120' },
                                             { id: 130, descricao: '130' },
                                             { id: 140, descricao: '140' },
                                             { id: 150, descricao: '150' },
                                             { id: 160, descricao: '160' },
                                             { id: 170, descricao: '170' },
                                             { id: 180, descricao: '180' },
                                             { id: 190, descricao: '190' },
                                             { id: 200, descricao: '200' },
                                             { id: 210, descricao: '210' },
                                             { id: 220, descricao: '220' },
                                             { id: 230, descricao: '230' },
                                             { id: 240, descricao: '240' },
                                             { id: 250, descricao: '250' },
                                             { id: 260, descricao: '260' },
                                             { id: 270, descricao: '270' },
                                             { id: 280, descricao: '280' },
                                             { id: 290, descricao: '290' },
                                             { id: 300, descricao: '300' },
                                             { id: 310, descricao: '310' },
                                             { id: 320, descricao: '320' },
                                             { id: 330, descricao: '330' },
                                             { id: 340, descricao: '340' },
                                             { id: 350, descricao: '350' },
                                             { id: 360, descricao: '360' },
                                             { id: 370, descricao: '370' },
                                             { id: 380, descricao: '380' },
                                             { id: 390, descricao: '390' },
                                             { id: 400, descricao: '400' },
                                             { id: 410, descricao: '410' },
                                             { id: 420, descricao: '420' },
                                             { id: 430, descricao: '430' },
                                             { id: 440, descricao: '440' },
                                             { id: 450, descricao: '450' },
                                             { id: 460, descricao: '460' },
                                             { id: 470, descricao: '470' },
                                             { id: 480, descricao: '480' },
                                             { id: 490, descricao: '490' },
                                             { id: 500, descricao: '500' },
                                             { id: 510, descricao: '510' },
                                             { id: 520, descricao: '520' },
                                             { id: 530, descricao: '530' },
                                             { id: 540, descricao: '540' },
                                             { id: 550, descricao: '550' },
                                             { id: 560, descricao: '560' },
                                             { id: 570, descricao: '570' },
                                             { id: 580, descricao: '580' },
                                             { id: 590, descricao: '590' },
                                             { id: 600, descricao: '600' },
                                             { id: 610, descricao: '610' },
                                             { id: 620, descricao: '620' },
                                             { id: 630, descricao: '630' },
                                             { id: 640, descricao: '640' },
                                             { id: 650, descricao: '650' },
                                             { id: 660, descricao: '660' },
                                             { id: 670, descricao: '670' },
                                             { id: 680, descricao: '680' },
                                             { id: 690, descricao: '690' },
                                             { id: 700, descricao: '700' },
                                             { id: 710, descricao: '710' },
                                             { id: 720, descricao: '720' },
                                             { id: 730, descricao: '730' },
                                             { id: 740, descricao: '740' },
                                             { id: 750, descricao: '750' },
                                             { id: 760, descricao: '760' },
                                             { id: 770, descricao: '770' },
                                             { id: 780, descricao: '780' },
                                             { id: 790, descricao: '790' },
                                             { id: 800, descricao: '800' },
                                             { id: 810, descricao: '810' },
                                             { id: 820, descricao: '820' },
                                             { id: 830, descricao: '830' },
                                             { id: 840, descricao: '840' },
                                             { id: 850, descricao: '850' },
                                             { id: 860, descricao: '860' },
                                             { id: 870, descricao: '870' },
                                             { id: 880, descricao: '880' },
                                             { id: 890, descricao: '890' },
                                             { id: 900, descricao: '900' },
                                             { id: 910, descricao: '910' },
                                             { id: 920, descricao: '920' },
                                             { id: 930, descricao: '930' },
                                             { id: 940, descricao: '940' },
                                             { id: 950, descricao: '950' },
                                             { id: 960, descricao: '960' },
                                             { id: 970, descricao: '970' },
                                             { id: 980, descricao: '980' },
                                             { id: 990, descricao: '990' },
                                             { id: 1000, descricao: '1000' },
                                             { id: 1010, descricao: '1010' },
                                             { id: 1020, descricao: '1020' },
                                             { id: 1030, descricao: '1030' },
                                             { id: 1040, descricao: '1040' },
                                             { id: 1050, descricao: '1050' },
                                             { id: 1060, descricao: '1060' },
                                             { id: 1070, descricao: '1070' },
                                             { id: 1080, descricao: '1080' },
                                             { id: 1090, descricao: '1090' },
                                             { id: 1100, descricao: '1100' },
                                             { id: 1110, descricao: '1110' },
                                             { id: 1120, descricao: '1120' },
                                             { id: 1130, descricao: '1130' },
                                             { id: 1140, descricao: '1140' },
                                             { id: 1150, descricao: '1150' },
                                             { id: 1160, descricao: '1160' },
                                             { id: 1170, descricao: '1170' },
                                             { id: 1180, descricao: '1180' },
                                             { id: 1190, descricao: '1190' },
                                             { id: 1200, descricao: '1200' },
                                             { id: 1210, descricao: '1210' },
                                             { id: 1220, descricao: '1220' },
                                             { id: 1230, descricao: '1230' },
                                             { id: 1240, descricao: '1240' },
                                             { id: 1250, descricao: '1250' },
                                             { id: 1260, descricao: '1260' },
                                             { id: 1270, descricao: '1270' },
                                             { id: 1280, descricao: '1280' },
                                             { id: 1290, descricao: '1290' },
                                             { id: 1300, descricao: '1300' },
                                             { id: 1310, descricao: '1310' },
                                             { id: 1320, descricao: '1320' },
                                             { id: 1330, descricao: '1330' },
                                             { id: 1340, descricao: '1340' },
                                             { id: 1350, descricao: '1350' },
                                             { id: 1360, descricao: '1360' },
                                             { id: 1370, descricao: '1370' },
                                             { id: 1380, descricao: '1380' },
                                             { id: 1390, descricao: '1390' },
                                             { id: 1400, descricao: '1400' },
                                             { id: 1410, descricao: '1410' },
                                             { id: 1420, descricao: '1420' },
                                             { id: 1430, descricao: '1430' },
                                             { id: 1440, descricao: '1440' },
                                             { id: 1450, descricao: '1450' },
                                             { id: 1460, descricao: '1460' },
                                             { id: 1470, descricao: '1470' },
                                             { id: 1480, descricao: '1480' },
                                             { id: 1490, descricao: '1490' },
                                             { id: 1500, descricao: '1500' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoLimite = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoLimite = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoRetorno}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoRetorno}
                                          options={[
                                             { id: 30, descricao: '30' },
                                             { id: 40, descricao: '40' },
                                             { id: 50, descricao: '50' },
                                             { id: 60, descricao: '60' },
                                             { id: 70, descricao: '70' },
                                             { id: 80, descricao: '80' },
                                             { id: 90, descricao: '90' },
                                             { id: 100, descricao: '100' },
                                             { id: 110, descricao: '110' },
                                             { id: 120, descricao: '120' },
                                             { id: 130, descricao: '130' },
                                             { id: 140, descricao: '140' },
                                             { id: 150, descricao: '150' },
                                             { id: 160, descricao: '160' },
                                             { id: 170, descricao: '170' },
                                             { id: 180, descricao: '180' },
                                             { id: 190, descricao: '190' },
                                             { id: 200, descricao: '200' },
                                             { id: 210, descricao: '210' },
                                             { id: 220, descricao: '220' },
                                             { id: 230, descricao: '230' },
                                             { id: 240, descricao: '240' },
                                             { id: 250, descricao: '250' },
                                             { id: 260, descricao: '260' },
                                             { id: 270, descricao: '270' },
                                             { id: 280, descricao: '280' },
                                             { id: 290, descricao: '290' },
                                             { id: 300, descricao: '300' },
                                             { id: 310, descricao: '310' },
                                             { id: 320, descricao: '320' },
                                             { id: 330, descricao: '330' },
                                             { id: 340, descricao: '340' },
                                             { id: 350, descricao: '350' },
                                             { id: 360, descricao: '360' },
                                             { id: 370, descricao: '370' },
                                             { id: 380, descricao: '380' },
                                             { id: 390, descricao: '390' },
                                             { id: 400, descricao: '400' },
                                             { id: 410, descricao: '410' },
                                             { id: 420, descricao: '420' },
                                             { id: 430, descricao: '430' },
                                             { id: 440, descricao: '440' },
                                             { id: 450, descricao: '450' },
                                             { id: 460, descricao: '460' },
                                             { id: 470, descricao: '470' },
                                             { id: 480, descricao: '480' },
                                             { id: 490, descricao: '490' },
                                             { id: 500, descricao: '500' },
                                             { id: 510, descricao: '510' },
                                             { id: 520, descricao: '520' },
                                             { id: 530, descricao: '530' },
                                             { id: 540, descricao: '540' },
                                             { id: 550, descricao: '550' },
                                             { id: 560, descricao: '560' },
                                             { id: 570, descricao: '570' },
                                             { id: 580, descricao: '580' },
                                             { id: 590, descricao: '590' },
                                             { id: 600, descricao: '600' },
                                             { id: 610, descricao: '610' },
                                             { id: 620, descricao: '620' },
                                             { id: 630, descricao: '630' },
                                             { id: 640, descricao: '640' },
                                             { id: 650, descricao: '650' },
                                             { id: 660, descricao: '660' },
                                             { id: 670, descricao: '670' },
                                             { id: 680, descricao: '680' },
                                             { id: 690, descricao: '690' },
                                             { id: 700, descricao: '700' },
                                             { id: 710, descricao: '710' },
                                             { id: 720, descricao: '720' },
                                             { id: 730, descricao: '730' },
                                             { id: 740, descricao: '740' },
                                             { id: 750, descricao: '750' },
                                             { id: 760, descricao: '760' },
                                             { id: 770, descricao: '770' },
                                             { id: 780, descricao: '780' },
                                             { id: 790, descricao: '790' },
                                             { id: 800, descricao: '800' },
                                             { id: 810, descricao: '810' },
                                             { id: 820, descricao: '820' },
                                             { id: 830, descricao: '830' },
                                             { id: 840, descricao: '840' },
                                             { id: 850, descricao: '850' },
                                             { id: 860, descricao: '860' },
                                             { id: 870, descricao: '870' },
                                             { id: 880, descricao: '880' },
                                             { id: 890, descricao: '890' },
                                             { id: 900, descricao: '900' },
                                             { id: 910, descricao: '910' },
                                             { id: 920, descricao: '920' },
                                             { id: 930, descricao: '930' },
                                             { id: 940, descricao: '940' },
                                             { id: 950, descricao: '950' },
                                             { id: 960, descricao: '960' },
                                             { id: 970, descricao: '970' },
                                             { id: 980, descricao: '980' },
                                             { id: 990, descricao: '990' },
                                             { id: 1000, descricao: '1000' },
                                             { id: 1010, descricao: '1010' },
                                             { id: 1020, descricao: '1020' },
                                             { id: 1030, descricao: '1030' },
                                             { id: 1040, descricao: '1040' },
                                             { id: 1050, descricao: '1050' },
                                             { id: 1060, descricao: '1060' },
                                             { id: 1070, descricao: '1070' },
                                             { id: 1080, descricao: '1080' },
                                             { id: 1090, descricao: '1090' },
                                             { id: 1100, descricao: '1100' },
                                             { id: 1110, descricao: '1110' },
                                             { id: 1120, descricao: '1120' },
                                             { id: 1130, descricao: '1130' },
                                             { id: 1140, descricao: '1140' },
                                             { id: 1150, descricao: '1150' },
                                             { id: 1160, descricao: '1160' },
                                             { id: 1170, descricao: '1170' },
                                             { id: 1180, descricao: '1180' },
                                             { id: 1190, descricao: '1190' },
                                             { id: 1200, descricao: '1200' },
                                             { id: 1210, descricao: '1210' },
                                             { id: 1220, descricao: '1220' },
                                             { id: 1230, descricao: '1230' },
                                             { id: 1240, descricao: '1240' },
                                             { id: 1250, descricao: '1250' },
                                             { id: 1260, descricao: '1260' },
                                             { id: 1270, descricao: '1270' },
                                             { id: 1280, descricao: '1280' },
                                             { id: 1290, descricao: '1290' },
                                             { id: 1300, descricao: '1300' },
                                             { id: 1310, descricao: '1310' },
                                             { id: 1320, descricao: '1320' },
                                             { id: 1330, descricao: '1330' },
                                             { id: 1340, descricao: '1340' },
                                             { id: 1350, descricao: '1350' },
                                             { id: 1360, descricao: '1360' },
                                             { id: 1370, descricao: '1370' },
                                             { id: 1380, descricao: '1380' },
                                             { id: 1390, descricao: '1390' },
                                             { id: 1400, descricao: '1400' },
                                             { id: 1410, descricao: '1410' },
                                             { id: 1420, descricao: '1420' },
                                             { id: 1430, descricao: '1430' },
                                             { id: 1440, descricao: '1440' },
                                             { id: 1450, descricao: '1450' },
                                             { id: 1460, descricao: '1460' },
                                             { id: 1470, descricao: '1470' },
                                             { id: 1480, descricao: '1480' },
                                             { id: 1490, descricao: '1490' },
                                             { id: 1500, descricao: '1500' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoRetorno = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoRetorno = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.quedaDeEnergia && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={[{ id: 2, descricao: '16328' }]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTipoCarga = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaInicial}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaInicial}
                                          options={[
                                             { id: -90, descricao: '-90' },
                                             { id: -85, descricao: '-85' },
                                             { id: -80, descricao: '-80' },
                                             { id: -75, descricao: '-75' },
                                             { id: -70, descricao: '-70' },
                                             { id: -65, descricao: '-65' },
                                             { id: -60, descricao: '-60' },
                                             { id: -55, descricao: '-55' },
                                             { id: -50, descricao: '-50' },
                                             { id: -45, descricao: '-45' },
                                             { id: -40, descricao: '-40' },
                                             { id: -35, descricao: '-35' },
                                             { id: -30, descricao: '-30' },
                                             { id: -25, descricao: '-25' },
                                             { id: -20, descricao: '-20' },
                                             { id: -15, descricao: '-15' },
                                             { id: -10, descricao: '-10' },
                                             { id: -5, descricao: '-5' },
                                             { id: 0, descricao: '0' },
                                             { id: 5, descricao: '5' },
                                             { id: 10, descricao: '10' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.temperaturaInicial = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.temperaturaInicial = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaLimite}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaLimite}
                                          options={[
                                             { id: 5, descricao: '5' },
                                             { id: 10, descricao: '10' },
                                             { id: 15, descricao: '15' },
                                             { id: 20, descricao: '20' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.temperaturaLimite = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.temperaturaLimite = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoLimite}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoLimite}
                                          options={[
                                             { id: 30, descricao: '30' },
                                             { id: 40, descricao: '40' },
                                             { id: 50, descricao: '50' },
                                             { id: 60, descricao: '60' },
                                             { id: 70, descricao: '70' },
                                             { id: 80, descricao: '80' },
                                             { id: 90, descricao: '90' },
                                             { id: 100, descricao: '100' },
                                             { id: 110, descricao: '110' },
                                             { id: 120, descricao: '120' },
                                             { id: 130, descricao: '130' },
                                             { id: 140, descricao: '140' },
                                             { id: 150, descricao: '150' },
                                             { id: 160, descricao: '160' },
                                             { id: 170, descricao: '170' },
                                             { id: 180, descricao: '180' },
                                             { id: 190, descricao: '190' },
                                             { id: 200, descricao: '200' },
                                             { id: 210, descricao: '210' },
                                             { id: 220, descricao: '220' },
                                             { id: 230, descricao: '230' },
                                             { id: 240, descricao: '240' },
                                             { id: 250, descricao: '250' },
                                             { id: 260, descricao: '260' },
                                             { id: 270, descricao: '270' },
                                             { id: 280, descricao: '280' },
                                             { id: 290, descricao: '290' },
                                             { id: 300, descricao: '300' },
                                             { id: 310, descricao: '310' },
                                             { id: 320, descricao: '320' },
                                             { id: 330, descricao: '330' },
                                             { id: 340, descricao: '340' },
                                             { id: 350, descricao: '350' },
                                             { id: 360, descricao: '360' },
                                             { id: 370, descricao: '370' },
                                             { id: 380, descricao: '380' },
                                             { id: 390, descricao: '390' },
                                             { id: 400, descricao: '400' },
                                             { id: 410, descricao: '410' },
                                             { id: 420, descricao: '420' },
                                             { id: 430, descricao: '430' },
                                             { id: 440, descricao: '440' },
                                             { id: 450, descricao: '450' },
                                             { id: 460, descricao: '460' },
                                             { id: 470, descricao: '470' },
                                             { id: 480, descricao: '480' },
                                             { id: 490, descricao: '490' },
                                             { id: 500, descricao: '500' },
                                             { id: 510, descricao: '510' },
                                             { id: 520, descricao: '520' },
                                             { id: 530, descricao: '530' },
                                             { id: 540, descricao: '540' },
                                             { id: 550, descricao: '550' },
                                             { id: 560, descricao: '560' },
                                             { id: 570, descricao: '570' },
                                             { id: 580, descricao: '580' },
                                             { id: 590, descricao: '590' },
                                             { id: 600, descricao: '600' },
                                             { id: 610, descricao: '610' },
                                             { id: 620, descricao: '620' },
                                             { id: 630, descricao: '630' },
                                             { id: 640, descricao: '640' },
                                             { id: 650, descricao: '650' },
                                             { id: 660, descricao: '660' },
                                             { id: 670, descricao: '670' },
                                             { id: 680, descricao: '680' },
                                             { id: 690, descricao: '690' },
                                             { id: 700, descricao: '700' },
                                             { id: 710, descricao: '710' },
                                             { id: 720, descricao: '720' },
                                             { id: 730, descricao: '730' },
                                             { id: 740, descricao: '740' },
                                             { id: 750, descricao: '750' },
                                             { id: 760, descricao: '760' },
                                             { id: 770, descricao: '770' },
                                             { id: 780, descricao: '780' },
                                             { id: 790, descricao: '790' },
                                             { id: 800, descricao: '800' },
                                             { id: 810, descricao: '810' },
                                             { id: 820, descricao: '820' },
                                             { id: 830, descricao: '830' },
                                             { id: 840, descricao: '840' },
                                             { id: 850, descricao: '850' },
                                             { id: 860, descricao: '860' },
                                             { id: 870, descricao: '870' },
                                             { id: 880, descricao: '880' },
                                             { id: 890, descricao: '890' },
                                             { id: 900, descricao: '900' },
                                             { id: 910, descricao: '910' },
                                             { id: 920, descricao: '920' },
                                             { id: 930, descricao: '930' },
                                             { id: 940, descricao: '940' },
                                             { id: 950, descricao: '950' },
                                             { id: 960, descricao: '960' },
                                             { id: 970, descricao: '970' },
                                             { id: 980, descricao: '980' },
                                             { id: 990, descricao: '990' },
                                             { id: 1000, descricao: '1000' },
                                             { id: 1010, descricao: '1010' },
                                             { id: 1020, descricao: '1020' },
                                             { id: 1030, descricao: '1030' },
                                             { id: 1040, descricao: '1040' },
                                             { id: 1050, descricao: '1050' },
                                             { id: 1060, descricao: '1060' },
                                             { id: 1070, descricao: '1070' },
                                             { id: 1080, descricao: '1080' },
                                             { id: 1090, descricao: '1090' },
                                             { id: 1100, descricao: '1100' },
                                             { id: 1110, descricao: '1110' },
                                             { id: 1120, descricao: '1120' },
                                             { id: 1130, descricao: '1130' },
                                             { id: 1140, descricao: '1140' },
                                             { id: 1150, descricao: '1150' },
                                             { id: 1160, descricao: '1160' },
                                             { id: 1170, descricao: '1170' },
                                             { id: 1180, descricao: '1180' },
                                             { id: 1190, descricao: '1190' },
                                             { id: 1200, descricao: '1200' },
                                             { id: 1210, descricao: '1210' },
                                             { id: 1220, descricao: '1220' },
                                             { id: 1230, descricao: '1230' },
                                             { id: 1240, descricao: '1240' },
                                             { id: 1250, descricao: '1250' },
                                             { id: 1260, descricao: '1260' },
                                             { id: 1270, descricao: '1270' },
                                             { id: 1280, descricao: '1280' },
                                             { id: 1290, descricao: '1290' },
                                             { id: 1300, descricao: '1300' },
                                             { id: 1310, descricao: '1310' },
                                             { id: 1320, descricao: '1320' },
                                             { id: 1330, descricao: '1330' },
                                             { id: 1340, descricao: '1340' },
                                             { id: 1350, descricao: '1350' },
                                             { id: 1360, descricao: '1360' },
                                             { id: 1370, descricao: '1370' },
                                             { id: 1380, descricao: '1380' },
                                             { id: 1390, descricao: '1390' },
                                             { id: 1400, descricao: '1400' },
                                             { id: 1410, descricao: '1410' },
                                             { id: 1420, descricao: '1420' },
                                             { id: 1430, descricao: '1430' },
                                             { id: 1440, descricao: '1440' },
                                             { id: 1450, descricao: '1450' },
                                             { id: 1460, descricao: '1460' },
                                             { id: 1470, descricao: '1470' },
                                             { id: 1480, descricao: '1480' },
                                             { id: 1490, descricao: '1490' },
                                             { id: 1500, descricao: '1500' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoLimite = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoLimite = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.manual && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTipoCarga = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoDuracao}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoDuracao}
                                          options={[
                                             { id: -1, descricao: this.props.lang.cadastroDeSequenciaAQT.manual },
                                             { id: 30, descricao: '00:00:30' },
                                             { id: 60, descricao: '00:01:00' },
                                             { id: 90, descricao: '00:01:30' },
                                             { id: 120, descricao: '00:02:00' },
                                             { id: 150, descricao: '00:02:30' },
                                             { id: 180, descricao: '00:03:00' },
                                             { id: 210, descricao: '00:03:30' },
                                             { id: 240, descricao: '00:04:00' },
                                             { id: 270, descricao: '00:04:30' },
                                             { id: 300, descricao: '00:05:00' },
                                             { id: 330, descricao: '00:05:30' },
                                             { id: 360, descricao: '00:06:00' },
                                             { id: 390, descricao: '00:06:30' },
                                             { id: 420, descricao: '00:07:00' },
                                             { id: 450, descricao: '00:07:30' },
                                             { id: 480, descricao: '00:08:00' },
                                             { id: 510, descricao: '00:08:30' },
                                             { id: 540, descricao: '00:09:00' },
                                             { id: 570, descricao: '00:09:30' },
                                             { id: 600, descricao: '00:10:00' },
                                             { id: 630, descricao: '00:10:30' },
                                             { id: 660, descricao: '00:11:00' },
                                             { id: 690, descricao: '00:11:30' },
                                             { id: 720, descricao: '00:12:00' },
                                             { id: 750, descricao: '00:12:30' },
                                             { id: 780, descricao: '00:13:00' },
                                             { id: 810, descricao: '00:13:30' },
                                             { id: 840, descricao: '00:14:00' },
                                             { id: 870, descricao: '00:14:30' },
                                             { id: 900, descricao: '00:15:00' },
                                             { id: 930, descricao: '00:15:30' },
                                             { id: 960, descricao: '00:16:00' },
                                             { id: 990, descricao: '00:16:30' },
                                             { id: 1020, descricao: '00:17:00' },
                                             { id: 1050, descricao: '00:17:30' },
                                             { id: 1080, descricao: '00:18:00' },
                                             { id: 1110, descricao: '00:18:30' },
                                             { id: 1140, descricao: '00:19:00' },
                                             { id: 1170, descricao: '00:19:30' },
                                             { id: 1200, descricao: '00:20:00' },
                                             { id: 1230, descricao: '00:20:30' },
                                             { id: 1260, descricao: '00:21:00' },
                                             { id: 1290, descricao: '00:21:30' },
                                             { id: 1320, descricao: '00:22:00' },
                                             { id: 1350, descricao: '00:22:30' },
                                             { id: 1380, descricao: '00:23:00' },
                                             { id: 1410, descricao: '00:23:30' },
                                             { id: 1440, descricao: '00:24:00' },
                                             { id: 1470, descricao: '00:24:30' },
                                             { id: 1500, descricao: '00:25:00' },
                                             { id: 1530, descricao: '00:25:30' },
                                             { id: 1560, descricao: '00:26:00' },
                                             { id: 1590, descricao: '00:26:30' },
                                             { id: 1620, descricao: '00:27:00' },
                                             { id: 1650, descricao: '00:27:30' },
                                             { id: 1680, descricao: '00:28:00' },
                                             { id: 1710, descricao: '00:28:30' },
                                             { id: 1740, descricao: '00:29:00' },
                                             { id: 1770, descricao: '00:29:30' },
                                             { id: 1800, descricao: '00:30:00' },
                                             { id: 1830, descricao: '00:30:30' },
                                             { id: 1860, descricao: '00:31:00' },
                                             { id: 1890, descricao: '00:31:30' },
                                             { id: 1920, descricao: '00:32:00' },
                                             { id: 1950, descricao: '00:32:30' },
                                             { id: 1980, descricao: '00:33:00' },
                                             { id: 2010, descricao: '00:33:30' },
                                             { id: 2040, descricao: '00:34:00' },
                                             { id: 2070, descricao: '00:34:30' },
                                             { id: 2100, descricao: '00:35:00' },
                                             { id: 2130, descricao: '00:35:30' },
                                             { id: 2160, descricao: '00:36:00' },
                                             { id: 2190, descricao: '00:36:30' },
                                             { id: 2220, descricao: '00:37:00' },
                                             { id: 2250, descricao: '00:37:30' },
                                             { id: 2280, descricao: '00:38:00' },
                                             { id: 2310, descricao: '00:38:30' },
                                             { id: 2340, descricao: '00:39:00' },
                                             { id: 2370, descricao: '00:39:30' },
                                             { id: 2400, descricao: '00:40:00' },
                                             { id: 2430, descricao: '00:40:30' },
                                             { id: 2460, descricao: '00:41:00' },
                                             { id: 2490, descricao: '00:41:30' },
                                             { id: 2520, descricao: '00:42:00' },
                                             { id: 2550, descricao: '00:42:30' },
                                             { id: 2580, descricao: '00:43:00' },
                                             { id: 2610, descricao: '00:43:30' },
                                             { id: 2640, descricao: '00:44:00' },
                                             { id: 2670, descricao: '00:44:30' },
                                             { id: 2700, descricao: '00:45:00' },
                                             { id: 2730, descricao: '00:45:30' },
                                             { id: 2760, descricao: '00:46:00' },
                                             { id: 2790, descricao: '00:46:30' },
                                             { id: 2820, descricao: '00:47:00' },
                                             { id: 2850, descricao: '00:47:30' },
                                             { id: 2880, descricao: '00:48:00' },
                                             { id: 2910, descricao: '00:48:30' },
                                             { id: 2940, descricao: '00:49:00' },
                                             { id: 2970, descricao: '00:49:30' },
                                             { id: 3000, descricao: '00:50:00' },
                                             { id: 3030, descricao: '00:50:30' },
                                             { id: 3060, descricao: '00:51:00' },
                                             { id: 3090, descricao: '00:51:30' },
                                             { id: 3120, descricao: '00:52:00' },
                                             { id: 3150, descricao: '00:52:30' },
                                             { id: 3180, descricao: '00:53:00' },
                                             { id: 3210, descricao: '00:53:30' },
                                             { id: 3240, descricao: '00:54:00' },
                                             { id: 3270, descricao: '00:54:30' },
                                             { id: 3300, descricao: '00:55:00' },
                                             { id: 3330, descricao: '00:55:30' },
                                             { id: 3360, descricao: '00:56:00' },
                                             { id: 3390, descricao: '00:56:30' },
                                             { id: 3420, descricao: '00:57:00' },
                                             { id: 3450, descricao: '00:57:30' },
                                             { id: 3480, descricao: '00:58:00' },
                                             { id: 3510, descricao: '00:58:30' },
                                             { id: 3540, descricao: '00:59:00' },
                                             { id: 3570, descricao: '00:59:30' },
                                             { id: 3600, descricao: '01:00:00' },
                                             { id: 5400, descricao: '01:30:00' },
                                             { id: 7200, descricao: '02:00:00' },
                                             { id: 9000, descricao: '02:30:00' },
                                             { id: 10800, descricao: '03:00:00' },
                                             { id: 12600, descricao: '03:30:00' },
                                             { id: 14400, descricao: '04:00:00' },
                                             { id: 16200, descricao: '04:30:00' },
                                             { id: 18000, descricao: '05:00:00' },
                                             { id: 19800, descricao: '05:30:00' },
                                             { id: 21600, descricao: '06:00:00' },
                                             { id: 23400, descricao: '06:30:00' },
                                             { id: 25200, descricao: '07:00:00' },
                                             { id: 27000, descricao: '07:30:00' },
                                             { id: 28800, descricao: '08:00:00' },
                                             { id: 30600, descricao: '08:30:00' },
                                             { id: 32400, descricao: '09:00:00' },
                                             { id: 34200, descricao: '09:30:00' },
                                             { id: 36000, descricao: '10:00:00' },
                                             { id: 37800, descricao: '10:30:00' },
                                             { id: 39600, descricao: '11:00:00' },
                                             { id: 41400, descricao: '11:30:00' },
                                             { id: 43200, descricao: '12:00:00' },
                                             { id: 45000, descricao: '12:30:00' },
                                             { id: 46800, descricao: '13:00:00' },
                                             { id: 48600, descricao: '13:30:00' },
                                             { id: 50400, descricao: '14:00:00' },
                                             { id: 52200, descricao: '14:30:00' },
                                             { id: 54000, descricao: '15:00:00' },
                                             { id: 55800, descricao: '15:30:00' },
                                             { id: 57600, descricao: '16:00:00' },
                                             { id: 59400, descricao: '16:30:00' },
                                             { id: 61200, descricao: '17:00:00' },
                                             { id: 63000, descricao: '17:30:00' },
                                             { id: 64800, descricao: '18:00:00' },
                                             { id: 66600, descricao: '18:30:00' },
                                             { id: 68400, descricao: '19:00:00' },
                                             { id: 70200, descricao: '19:30:00' },
                                             { id: 72000, descricao: '20:00:00' },
                                             { id: 73800, descricao: '20:30:00' },
                                             { id: 75600, descricao: '21:00:00' },
                                             { id: 77400, descricao: '21:30:00' },
                                             { id: 79200, descricao: '22:00:00' },
                                             { id: 81000, descricao: '22:30:00' },
                                             { id: 82800, descricao: '23:00:00' },
                                             { id: 84600, descricao: '23:30:00' },
                                             { id: 86400, descricao: '24:00:00' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoDuracao = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoDuracao = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoEntreMedidas}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoEntreMedidas}
                                          options={[
                                             { id: 1, descricao: '1' },
                                             { id: 2, descricao: '2' },
                                             { id: 3, descricao: '3' },
                                             { id: 4, descricao: '4' },
                                             { id: 5, descricao: '5' },
                                             { id: 6, descricao: '6' },
                                             { id: 7, descricao: '7' },
                                             { id: 8, descricao: '8' },
                                             { id: 9, descricao: '9' },
                                             { id: 10, descricao: '10' },
                                             { id: 11, descricao: '11' },
                                             { id: 12, descricao: '12' },
                                             { id: 13, descricao: '13' },
                                             { id: 14, descricao: '14' },
                                             { id: 15, descricao: '15' },
                                             { id: 16, descricao: '16' },
                                             { id: 17, descricao: '17' },
                                             { id: 18, descricao: '18' },
                                             { id: 19, descricao: '19' },
                                             { id: 20, descricao: '20' },
                                             { id: 21, descricao: '21' },
                                             { id: 22, descricao: '22' },
                                             { id: 23, descricao: '23' },
                                             { id: 24, descricao: '24' },
                                             { id: 25, descricao: '25' },
                                             { id: 26, descricao: '26' },
                                             { id: 27, descricao: '27' },
                                             { id: 28, descricao: '28' },
                                             { id: 29, descricao: '29' },
                                             { id: 30, descricao: '30' },
                                             { id: 31, descricao: '31' },
                                             { id: 32, descricao: '32' },
                                             { id: 33, descricao: '33' },
                                             { id: 34, descricao: '34' },
                                             { id: 35, descricao: '35' },
                                             { id: 36, descricao: '36' },
                                             { id: 37, descricao: '37' },
                                             { id: 38, descricao: '38' },
                                             { id: 39, descricao: '39' },
                                             { id: 40, descricao: '40' },
                                             { id: 41, descricao: '41' },
                                             { id: 42, descricao: '42' },
                                             { id: 43, descricao: '43' },
                                             { id: 44, descricao: '44' },
                                             { id: 45, descricao: '45' },
                                             { id: 46, descricao: '46' },
                                             { id: 47, descricao: '47' },
                                             { id: 48, descricao: '48' },
                                             { id: 49, descricao: '49' },
                                             { id: 50, descricao: '50' },
                                             { id: 51, descricao: '51' },
                                             { id: 52, descricao: '52' },
                                             { id: 53, descricao: '53' },
                                             { id: 54, descricao: '54' },
                                             { id: 55, descricao: '55' },
                                             { id: 56, descricao: '56' },
                                             { id: 57, descricao: '57' },
                                             { id: 58, descricao: '58' },
                                             { id: 59, descricao: '59' },
                                             { id: 60, descricao: '60' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.temperaturaRef}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.temperaturaRef}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.temperaturaOperacao = e.floatValue;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.limiteInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.floatValue;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.limiteSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.floatValue;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.pressaoDinamica && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={getNormasAQT_Vazamento(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.unidadeMedida}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeMedida}
                                          options={getUnidadeMedidaAQT_PressaoDinamica(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeMedida = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeMedida = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTipoCarga = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.vazamentoMaximo}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.vazamentoMaximo}
                                          options={[
                                             { id: 500, descricao: '500' },
                                             { id: 600, descricao: '600' },
                                             { id: 700, descricao: '700' },
                                             { id: 800, descricao: '800' },
                                             { id: 900, descricao: '900' },
                                             { id: 1000, descricao: '1000' },
                                             { id: 1100, descricao: '1100' },
                                             { id: 1200, descricao: '1200' },
                                             { id: 1300, descricao: '1300' },
                                             { id: 1400, descricao: '1400' },
                                             { id: 1500, descricao: '1500' },
                                             { id: 1600, descricao: '1600' },
                                             { id: 1700, descricao: '1700' },
                                             { id: 1800, descricao: '1800' },
                                             { id: 1900, descricao: '1900' },
                                             { id: 2000, descricao: '2000' },
                                             { id: 2100, descricao: '2100' },
                                             { id: 2200, descricao: '2200' },
                                             { id: 2300, descricao: '2300' },
                                             { id: 2400, descricao: '2400' },
                                             { id: 2500, descricao: '2500' },
                                             { id: 2600, descricao: '2600' },
                                             { id: 2700, descricao: '2700' },
                                             { id: 2800, descricao: '2800' },
                                             { id: 2900, descricao: '2900' },
                                             { id: 3000, descricao: '3000' },
                                             { id: 3100, descricao: '3100' },
                                             { id: 3200, descricao: '3200' },
                                             { id: 3300, descricao: '3300' },
                                             { id: 3400, descricao: '3400' },
                                             { id: 3500, descricao: '3500' },
                                             { id: 3600, descricao: '3600' },
                                             { id: 3700, descricao: '3700' },
                                             { id: 3800, descricao: '3800' },
                                             { id: 3900, descricao: '3900' },
                                             { id: 4000, descricao: '4000' },
                                             { id: 4100, descricao: '4100' },
                                             { id: 4200, descricao: '4200' },
                                             { id: 4300, descricao: '4300' },
                                             { id: 4400, descricao: '4400' },
                                             { id: 4500, descricao: '4500' },
                                             { id: 4600, descricao: '4600' },
                                             { id: 4700, descricao: '4700' },
                                             { id: 4800, descricao: '4800' },
                                             { id: 4900, descricao: '4900' },
                                             { id: 5000, descricao: '5000' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.vazamentoMaximo = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.vazamentoMaximo = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoDuracao}</Label>

                                       <NumberFormat
                                          className={'form-control'}
                                          format='##:##:##'
                                          defaultValue={converterMinutosParaHora(
                                             this.state.configuracaoSelecionada.tempoDuracao / 60,
                                             'HH:mm:ss'
                                          )}
                                          onValueChange={(t) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.tempoDuracao =
                                                   converterHorasParaMinutos(t.formattedValue) * 60;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tempoEntreMedidas}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tempoEntreMedidas}
                                          options={[
                                             { id: 1, descricao: '1' },
                                             { id: 2, descricao: '2' },
                                             { id: 3, descricao: '3' },
                                             { id: 4, descricao: '4' },
                                             { id: 5, descricao: '5' },
                                             { id: 6, descricao: '6' },
                                             { id: 7, descricao: '7' },
                                             { id: 8, descricao: '8' },
                                             { id: 9, descricao: '9' },
                                             { id: 10, descricao: '10' },
                                             { id: 11, descricao: '11' },
                                             { id: 12, descricao: '12' },
                                             { id: 13, descricao: '13' },
                                             { id: 14, descricao: '14' },
                                             { id: 15, descricao: '15' },
                                             { id: 16, descricao: '16' },
                                             { id: 17, descricao: '17' },
                                             { id: 18, descricao: '18' },
                                             { id: 19, descricao: '19' },
                                             { id: 20, descricao: '20' },
                                             { id: 21, descricao: '21' },
                                             { id: 22, descricao: '22' },
                                             { id: 23, descricao: '23' },
                                             { id: 24, descricao: '24' },
                                             { id: 25, descricao: '25' },
                                             { id: 26, descricao: '26' },
                                             { id: 27, descricao: '27' },
                                             { id: 28, descricao: '28' },
                                             { id: 29, descricao: '29' },
                                             { id: 30, descricao: '30' },
                                             { id: 31, descricao: '31' },
                                             { id: 32, descricao: '32' },
                                             { id: 33, descricao: '33' },
                                             { id: 34, descricao: '34' },
                                             { id: 35, descricao: '35' },
                                             { id: 36, descricao: '36' },
                                             { id: 37, descricao: '37' },
                                             { id: 38, descricao: '38' },
                                             { id: 39, descricao: '39' },
                                             { id: 40, descricao: '40' },
                                             { id: 41, descricao: '41' },
                                             { id: 42, descricao: '42' },
                                             { id: 43, descricao: '43' },
                                             { id: 44, descricao: '44' },
                                             { id: 45, descricao: '45' },
                                             { id: 46, descricao: '46' },
                                             { id: 47, descricao: '47' },
                                             { id: 48, descricao: '48' },
                                             { id: 49, descricao: '49' },
                                             { id: 50, descricao: '50' },
                                             { id: 51, descricao: '51' },
                                             { id: 52, descricao: '52' },
                                             { id: 53, descricao: '53' },
                                             { id: 54, descricao: '54' },
                                             { id: 55, descricao: '55' },
                                             { id: 56, descricao: '56' },
                                             { id: 57, descricao: '57' },
                                             { id: 58, descricao: '58' },
                                             { id: 59, descricao: '59' },
                                             { id: 60, descricao: '60' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tempoEntreMedidas = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis &&
                           !this.state.finalizandoCiclo && (
                              <Fragment>
                                 <Row>
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.indice}</Label>
                                          <IntegerInput
                                             defaultValue={this.state.configuracaoSelecionada.indice}
                                             readOnly={true}
                                          />
                                       </FormGroup>
                                    </Col>

                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.nomeFase}</Label>
                                          <TextInput
                                             defaultValue={this.state.configuracaoSelecionada.nomeFase}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.nomeFase = e.target.value;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.tempoEsperado}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoEsperado}
                                             options={[
                                                { id: -1, descricao: lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 4200, descricao: '01:10:00' },
                                                { id: 4800, descricao: '01:20:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 6000, descricao: '01:40:00' },
                                                { id: 6600, descricao: '01:50:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 7800, descricao: '02:10:00' },
                                                { id: 8400, descricao: '02:20:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 9600, descricao: '02:40:00' },
                                                { id: 10200, descricao: '02:50:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoEsperado = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.tempoEsperado = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.testarTemperatura}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.testarTemperatura}
                                             options={[
                                                { id: 0, descricao: this.props.lang.nao },
                                                { id: 1, descricao: this.props.lang.sim },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.testarTemperatura = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.testarTemperatura = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Row>

                                 {this.state.configuracaoSelecionada.testarTemperatura === 1 && (
                                    <Fragment>
                                       <Row>
                                          <Col>
                                             <FormGroup>
                                                <Label>{lang.cadastroDeSequenciaAQT.temperaturaOperacao}</Label>
                                                <IntegerInput
                                                   defaultValue={this.state.configuracaoSelecionada.temperaturaOperacao}
                                                   onChange={(e) => {
                                                      updateState(this, (state) => {
                                                         state.configuracaoSelecionada.temperaturaOperacao =
                                                            e.floatValue;
                                                      });
                                                   }}
                                                />
                                             </FormGroup>
                                          </Col>

                                          <Col>
                                             <FormGroup>
                                                <Label>{lang.cadastroDeSequenciaAQT.deltaTemperatura}</Label>
                                                <Select
                                                   defaultValue={this.state.configuracaoSelecionada.deltaTemperatura}
                                                   options={[
                                                      { id: 0.1, descricao: '0,1' },
                                                      { id: 0.2, descricao: '0,2' },
                                                      { id: 0.3, descricao: '0,3' },
                                                      { id: 0.4, descricao: '0,4' },
                                                      { id: 0.5, descricao: '0,5' },
                                                      { id: 0.6, descricao: '0,6' },
                                                      { id: 0.7, descricao: '0,7' },
                                                      { id: 0.8, descricao: '0,8' },
                                                      { id: 0.9, descricao: '0,9' },
                                                      { id: 1.0, descricao: '1' },
                                                      { id: 1.1, descricao: '1,1' },
                                                      { id: 1.2, descricao: '1,2' },
                                                      { id: 1.3, descricao: '1,3' },
                                                      { id: 1.4, descricao: '1,4' },
                                                      { id: 1.5, descricao: '1,5' },
                                                      { id: 1.6, descricao: '1,6' },
                                                      { id: 1.7, descricao: '1,7' },
                                                      { id: 1.8, descricao: '1,8' },
                                                      { id: 1.9, descricao: '1,9' },
                                                      { id: 2.0, descricao: '2' },
                                                      { id: 2.1, descricao: '2,1' },
                                                      { id: 2.2, descricao: '2,2' },
                                                      { id: 2.3, descricao: '2,3' },
                                                      { id: 2.4, descricao: '2,4' },
                                                      { id: 2.5, descricao: '2,5' },
                                                      { id: 2.6, descricao: '2,6' },
                                                      { id: 2.7, descricao: '2,7' },
                                                      { id: 2.8, descricao: '2,8' },
                                                      { id: 2.9, descricao: '2,9' },
                                                      { id: 3.0, descricao: '3' },
                                                   ]}
                                                   getKeyValue={(i) => i.id}
                                                   getDescription={(i) => i.descricao}
                                                   allowEmpty={false}
                                                   onSelect={(i) => {
                                                      updateState(this, (state) => {
                                                         if (i != null) {
                                                            state.configuracaoSelecionada.deltaTemperatura = i.id;
                                                         } else {
                                                            state.configuracaoSelecionada.deltaTemperatura = 0;
                                                         }
                                                      });
                                                   }}
                                                />
                                             </FormGroup>
                                          </Col>
                                       </Row>
                                       <Row>
                                          <Col>
                                             <FormGroup>
                                                <Label>{lang.cadastroDeSequenciaAQT.erroTemperatura}</Label>
                                                <Select
                                                   defaultValue={this.state.configuracaoSelecionada.erroTemperatura}
                                                   options={[
                                                      { id: 0.1, descricao: '0,1' },
                                                      { id: 0.2, descricao: '0,2' },
                                                      { id: 0.3, descricao: '0,3' },
                                                      { id: 0.4, descricao: '0,4' },
                                                      { id: 0.5, descricao: '0,5' },
                                                      { id: 0.6, descricao: '0,6' },
                                                      { id: 0.7, descricao: '0,7' },
                                                      { id: 0.8, descricao: '0,8' },
                                                      { id: 0.9, descricao: '0,9' },
                                                      { id: 1.0, descricao: '1' },
                                                      { id: 1.1, descricao: '1,1' },
                                                      { id: 1.2, descricao: '1,2' },
                                                      { id: 1.3, descricao: '1,3' },
                                                      { id: 1.4, descricao: '1,4' },
                                                      { id: 1.5, descricao: '1,5' },
                                                      { id: 1.6, descricao: '1,6' },
                                                      { id: 1.7, descricao: '1,7' },
                                                      { id: 1.8, descricao: '1,8' },
                                                      { id: 1.9, descricao: '1,9' },
                                                      { id: 2.0, descricao: '2' },
                                                      { id: 2.1, descricao: '2,1' },
                                                      { id: 2.2, descricao: '2,2' },
                                                      { id: 2.3, descricao: '2,3' },
                                                      { id: 2.4, descricao: '2,4' },
                                                      { id: 2.5, descricao: '2,5' },
                                                      { id: 2.6, descricao: '2,6' },
                                                      { id: 2.7, descricao: '2,7' },
                                                      { id: 2.8, descricao: '2,8' },
                                                      { id: 2.9, descricao: '2,9' },
                                                      { id: 3.0, descricao: '3' },
                                                      { id: 3.1, descricao: '3,1' },
                                                      { id: 3.2, descricao: '3,2' },
                                                      { id: 3.3, descricao: '3,3' },
                                                      { id: 3.4, descricao: '3,4' },
                                                      { id: 3.5, descricao: '3,5' },
                                                      { id: 3.6, descricao: '3,6' },
                                                      { id: 3.7, descricao: '3,7' },
                                                      { id: 3.8, descricao: '3,8' },
                                                      { id: 3.9, descricao: '3,9' },
                                                      { id: 4.0, descricao: '4' },
                                                      { id: 4.1, descricao: '4,1' },
                                                      { id: 4.2, descricao: '4,2' },
                                                      { id: 4.3, descricao: '4,3' },
                                                      { id: 4.4, descricao: '4,4' },
                                                      { id: 4.5, descricao: '4,5' },
                                                      { id: 4.6, descricao: '4,6' },
                                                      { id: 4.7, descricao: '4,7' },
                                                      { id: 4.8, descricao: '4,8' },
                                                      { id: 4.9, descricao: '4,9' },
                                                      { id: 5.0, descricao: '5' },
                                                      { id: 5.1, descricao: '5,1' },
                                                      { id: 5.2, descricao: '5,2' },
                                                      { id: 5.3, descricao: '5,3' },
                                                      { id: 5.4, descricao: '5,4' },
                                                      { id: 5.5, descricao: '5,5' },
                                                      { id: 5.6, descricao: '5,6' },
                                                      { id: 5.7, descricao: '5,7' },
                                                      { id: 5.8, descricao: '5,8' },
                                                      { id: 5.9, descricao: '5,9' },
                                                      { id: 6.0, descricao: '6' },
                                                      { id: 6.1, descricao: '6,1' },
                                                      { id: 6.2, descricao: '6,2' },
                                                      { id: 6.3, descricao: '6,3' },
                                                      { id: 6.4, descricao: '6,4' },
                                                      { id: 6.5, descricao: '6,5' },
                                                      { id: 6.6, descricao: '6,6' },
                                                      { id: 6.7, descricao: '6,7' },
                                                      { id: 6.8, descricao: '6,8' },
                                                      { id: 6.9, descricao: '6,9' },
                                                      { id: 7.0, descricao: '7' },
                                                      { id: 7.1, descricao: '7,1' },
                                                      { id: 7.2, descricao: '7,2' },
                                                      { id: 7.3, descricao: '7,3' },
                                                      { id: 7.4, descricao: '7,4' },
                                                      { id: 7.5, descricao: '7,5' },
                                                      { id: 7.6, descricao: '7,6' },
                                                      { id: 7.7, descricao: '7,7' },
                                                      { id: 7.8, descricao: '7,8' },
                                                      { id: 7.9, descricao: '7,9' },
                                                      { id: 8.0, descricao: '8' },
                                                      { id: 8.1, descricao: '8,1' },
                                                      { id: 8.2, descricao: '8,2' },
                                                      { id: 8.3, descricao: '8,3' },
                                                      { id: 8.4, descricao: '8,4' },
                                                      { id: 8.5, descricao: '8,5' },
                                                      { id: 8.6, descricao: '8,6' },
                                                      { id: 8.7, descricao: '8,7' },
                                                      { id: 8.8, descricao: '8,8' },
                                                      { id: 8.9, descricao: '8,9' },
                                                      { id: 9.0, descricao: '9' },
                                                      { id: 9.1, descricao: '9,1' },
                                                      { id: 9.2, descricao: '9,2' },
                                                      { id: 9.3, descricao: '9,3' },
                                                      { id: 9.4, descricao: '9,4' },
                                                      { id: 9.5, descricao: '9,5' },
                                                      { id: 9.6, descricao: '9,6' },
                                                      { id: 9.7, descricao: '9,7' },
                                                      { id: 9.8, descricao: '9,8' },
                                                      { id: 9.9, descricao: '9,9' },
                                                      { id: 10, descricao: '10' },
                                                   ]}
                                                   getKeyValue={(i) => i.id}
                                                   getDescription={(i) => i.descricao}
                                                   allowEmpty={false}
                                                   onSelect={(i) => {
                                                      updateState(this, (state) => {
                                                         if (i != null) {
                                                            state.configuracaoSelecionada.erroTemperatura = i.id;
                                                         } else {
                                                            state.configuracaoSelecionada.erroTemperatura = 0;
                                                         }
                                                      });
                                                   }}
                                                />
                                             </FormGroup>
                                          </Col>
                                          <Col>
                                             <FormGroup>
                                                <Label>{lang.cadastroDeSequenciaAQT.realizarCalculos}</Label>
                                                <Select
                                                   defaultValue={this.state.configuracaoSelecionada.realizarCalculos}
                                                   options={[
                                                      { id: 0, descricao: this.props.lang.nao },
                                                      { id: 1, descricao: this.props.lang.sim },
                                                   ]}
                                                   getKeyValue={(i) => i.id}
                                                   getDescription={(i) => i.descricao}
                                                   allowEmpty={false}
                                                   onSelect={(i) => {
                                                      updateState(this, (state) => {
                                                         if (i != null) {
                                                            state.configuracaoSelecionada.realizarCalculos = i.id;
                                                         } else {
                                                            state.configuracaoSelecionada.realizarCalculos = 0;
                                                         }
                                                      });
                                                   }}
                                                />
                                             </FormGroup>
                                          </Col>
                                       </Row>
                                    </Fragment>
                                 )}

                                 <Row>
                                    <Col>
                                       <Button
                                          text={lang.cadastroDeSequenciaAQT.finalizarCiclo}
                                          style={{ width: 250 }}
                                          onClick={() => {
                                             subCadastro.salvar().then(() => {
                                                subCadastro.novo();
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.numeroFases = state.quantidadeDeCiclos;
                                                   state.finalizandoCiclo = true;
                                                });
                                             });
                                          }}
                                       />
                                    </Col>
                                 </Row>
                              </Fragment>
                           )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis &&
                           this.state.finalizandoCiclo && (
                              <Fragment>
                                 <Row>
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.numeroFases}</Label>
                                          <IntegerInput
                                             defaultValue={this.state.configuracaoSelecionada.numeroFases}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.numeroFases = e.floatValue;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.nomeCiclo}</Label>
                                          <TextInput
                                             defaultValue={this.state.configuracaoSelecionada.nomeCiclo}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.nomeCiclo = e.target.value;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Row>

                                 <Row>
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.descTipoCarga}</Label>
                                          <TextInput
                                             defaultValue={this.state.configuracaoSelecionada.descTipoCarga}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.nomeCiclo = e.target.value;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>

                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.tempoDuracaoMax}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoDuracao}
                                             options={[
                                                { id: -1, descricao: lang.cadastroDeSequenciaAQT.manual },
                                                { id: 0, descricao: lang.cadastroDeSequenciaAQT.desligado },
                                                { id: 30, descricao: '00:00:30' },
                                                { id: 60, descricao: '00:01:00' },
                                                { id: 90, descricao: '00:01:30' },
                                                { id: 120, descricao: '00:02:00' },
                                                { id: 150, descricao: '00:02:30' },
                                                { id: 180, descricao: '00:03:00' },
                                                { id: 210, descricao: '00:03:30' },
                                                { id: 240, descricao: '00:04:00' },
                                                { id: 270, descricao: '00:04:30' },
                                                { id: 300, descricao: '00:05:00' },
                                                { id: 330, descricao: '00:05:30' },
                                                { id: 360, descricao: '00:06:00' },
                                                { id: 390, descricao: '00:06:30' },
                                                { id: 420, descricao: '00:07:00' },
                                                { id: 450, descricao: '00:07:30' },
                                                { id: 480, descricao: '00:08:00' },
                                                { id: 510, descricao: '00:08:30' },
                                                { id: 540, descricao: '00:09:00' },
                                                { id: 570, descricao: '00:09:30' },
                                                { id: 600, descricao: '00:10:00' },
                                                { id: 630, descricao: '00:10:30' },
                                                { id: 660, descricao: '00:11:00' },
                                                { id: 690, descricao: '00:11:30' },
                                                { id: 720, descricao: '00:12:00' },
                                                { id: 750, descricao: '00:12:30' },
                                                { id: 780, descricao: '00:13:00' },
                                                { id: 810, descricao: '00:13:30' },
                                                { id: 840, descricao: '00:14:00' },
                                                { id: 870, descricao: '00:14:30' },
                                                { id: 900, descricao: '00:15:00' },
                                                { id: 930, descricao: '00:15:30' },
                                                { id: 960, descricao: '00:16:00' },
                                                { id: 990, descricao: '00:16:30' },
                                                { id: 1020, descricao: '00:17:00' },
                                                { id: 1050, descricao: '00:17:30' },
                                                { id: 1080, descricao: '00:18:00' },
                                                { id: 1110, descricao: '00:18:30' },
                                                { id: 1140, descricao: '00:19:00' },
                                                { id: 1170, descricao: '00:19:30' },
                                                { id: 1200, descricao: '00:20:00' },
                                                { id: 1230, descricao: '00:20:30' },
                                                { id: 1260, descricao: '00:21:00' },
                                                { id: 1290, descricao: '00:21:30' },
                                                { id: 1320, descricao: '00:22:00' },
                                                { id: 1350, descricao: '00:22:30' },
                                                { id: 1380, descricao: '00:23:00' },
                                                { id: 1410, descricao: '00:23:30' },
                                                { id: 1440, descricao: '00:24:00' },
                                                { id: 1470, descricao: '00:24:30' },
                                                { id: 1500, descricao: '00:25:00' },
                                                { id: 1530, descricao: '00:25:30' },
                                                { id: 1560, descricao: '00:26:00' },
                                                { id: 1590, descricao: '00:26:30' },
                                                { id: 1620, descricao: '00:27:00' },
                                                { id: 1650, descricao: '00:27:30' },
                                                { id: 1680, descricao: '00:28:00' },
                                                { id: 1710, descricao: '00:28:30' },
                                                { id: 1740, descricao: '00:29:00' },
                                                { id: 1770, descricao: '00:29:30' },
                                                { id: 1800, descricao: '00:30:00' },
                                                { id: 1830, descricao: '00:30:30' },
                                                { id: 1860, descricao: '00:31:00' },
                                                { id: 1890, descricao: '00:31:30' },
                                                { id: 1920, descricao: '00:32:00' },
                                                { id: 1950, descricao: '00:32:30' },
                                                { id: 1980, descricao: '00:33:00' },
                                                { id: 2010, descricao: '00:33:30' },
                                                { id: 2040, descricao: '00:34:00' },
                                                { id: 2070, descricao: '00:34:30' },
                                                { id: 2100, descricao: '00:35:00' },
                                                { id: 2130, descricao: '00:35:30' },
                                                { id: 2160, descricao: '00:36:00' },
                                                { id: 2190, descricao: '00:36:30' },
                                                { id: 2220, descricao: '00:37:00' },
                                                { id: 2250, descricao: '00:37:30' },
                                                { id: 2280, descricao: '00:38:00' },
                                                { id: 2310, descricao: '00:38:30' },
                                                { id: 2340, descricao: '00:39:00' },
                                                { id: 2370, descricao: '00:39:30' },
                                                { id: 2400, descricao: '00:40:00' },
                                                { id: 2430, descricao: '00:40:30' },
                                                { id: 2460, descricao: '00:41:00' },
                                                { id: 2490, descricao: '00:41:30' },
                                                { id: 2520, descricao: '00:42:00' },
                                                { id: 2550, descricao: '00:42:30' },
                                                { id: 2580, descricao: '00:43:00' },
                                                { id: 2610, descricao: '00:43:30' },
                                                { id: 2640, descricao: '00:44:00' },
                                                { id: 2670, descricao: '00:44:30' },
                                                { id: 2700, descricao: '00:45:00' },
                                                { id: 2730, descricao: '00:45:30' },
                                                { id: 2760, descricao: '00:46:00' },
                                                { id: 2790, descricao: '00:46:30' },
                                                { id: 2820, descricao: '00:47:00' },
                                                { id: 2850, descricao: '00:47:30' },
                                                { id: 2880, descricao: '00:48:00' },
                                                { id: 2910, descricao: '00:48:30' },
                                                { id: 2940, descricao: '00:49:00' },
                                                { id: 2970, descricao: '00:49:30' },
                                                { id: 3000, descricao: '00:50:00' },
                                                { id: 3030, descricao: '00:50:30' },
                                                { id: 3060, descricao: '00:51:00' },
                                                { id: 3090, descricao: '00:51:30' },
                                                { id: 3120, descricao: '00:52:00' },
                                                { id: 3150, descricao: '00:52:30' },
                                                { id: 3180, descricao: '00:53:00' },
                                                { id: 3210, descricao: '00:53:30' },
                                                { id: 3240, descricao: '00:54:00' },
                                                { id: 3270, descricao: '00:54:30' },
                                                { id: 3300, descricao: '00:55:00' },
                                                { id: 3330, descricao: '00:55:30' },
                                                { id: 3360, descricao: '00:56:00' },
                                                { id: 3390, descricao: '00:56:30' },
                                                { id: 3420, descricao: '00:57:00' },
                                                { id: 3450, descricao: '00:57:30' },
                                                { id: 3480, descricao: '00:58:00' },
                                                { id: 3510, descricao: '00:58:30' },
                                                { id: 3540, descricao: '00:59:00' },
                                                { id: 3570, descricao: '00:59:30' },
                                                { id: 3600, descricao: '01:00:00' },
                                                { id: 4200, descricao: '01:10:00' },
                                                { id: 4800, descricao: '01:20:00' },
                                                { id: 5400, descricao: '01:30:00' },
                                                { id: 6000, descricao: '01:40:00' },
                                                { id: 6600, descricao: '01:50:00' },
                                                { id: 7200, descricao: '02:00:00' },
                                                { id: 7800, descricao: '02:10:00' },
                                                { id: 8400, descricao: '02:20:00' },
                                                { id: 9000, descricao: '02:30:00' },
                                                { id: 9600, descricao: '02:40:00' },
                                                { id: 10200, descricao: '02:50:00' },
                                                { id: 10800, descricao: '03:00:00' },
                                                { id: 12600, descricao: '03:30:00' },
                                                { id: 14400, descricao: '04:00:00' },
                                                { id: 16200, descricao: '04:30:00' },
                                                { id: 18000, descricao: '05:00:00' },
                                                { id: 19800, descricao: '05:30:00' },
                                                { id: 21600, descricao: '06:00:00' },
                                                { id: 23400, descricao: '06:30:00' },
                                                { id: 25200, descricao: '07:00:00' },
                                                { id: 27000, descricao: '07:30:00' },
                                                { id: 28800, descricao: '08:00:00' },
                                                { id: 30600, descricao: '08:30:00' },
                                                { id: 32400, descricao: '09:00:00' },
                                                { id: 34200, descricao: '09:30:00' },
                                                { id: 36000, descricao: '10:00:00' },
                                                { id: 37800, descricao: '10:30:00' },
                                                { id: 39600, descricao: '11:00:00' },
                                                { id: 41400, descricao: '11:30:00' },
                                                { id: 43200, descricao: '12:00:00' },
                                                { id: 45000, descricao: '12:30:00' },
                                                { id: 46800, descricao: '13:00:00' },
                                                { id: 48600, descricao: '13:30:00' },
                                                { id: 50400, descricao: '14:00:00' },
                                                { id: 52200, descricao: '14:30:00' },
                                                { id: 54000, descricao: '15:00:00' },
                                                { id: 55800, descricao: '15:30:00' },
                                                { id: 57600, descricao: '16:00:00' },
                                                { id: 59400, descricao: '16:30:00' },
                                                { id: 61200, descricao: '17:00:00' },
                                                { id: 63000, descricao: '17:30:00' },
                                                { id: 64800, descricao: '18:00:00' },
                                                { id: 66600, descricao: '18:30:00' },
                                                { id: 68400, descricao: '19:00:00' },
                                                { id: 70200, descricao: '19:30:00' },
                                                { id: 72000, descricao: '20:00:00' },
                                                { id: 73800, descricao: '20:30:00' },
                                                { id: 75600, descricao: '21:00:00' },
                                                { id: 77400, descricao: '21:30:00' },
                                                { id: 79200, descricao: '22:00:00' },
                                                { id: 81000, descricao: '22:30:00' },
                                                { id: 82800, descricao: '23:00:00' },
                                                { id: 84600, descricao: '23:30:00' },
                                                { id: 86400, descricao: '24:00:00' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoDuracao = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.tempoDuracao = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>

                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.tempoEntreMedidas}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.tempoEntreMedidas}
                                             options={[
                                                { id: 5, descricao: '5' },
                                                { id: 6, descricao: '6' },
                                                { id: 7, descricao: '7' },
                                                { id: 8, descricao: '8' },
                                                { id: 9, descricao: '9' },
                                                { id: 10, descricao: '10' },
                                                { id: 11, descricao: '11' },
                                                { id: 12, descricao: '12' },
                                                { id: 13, descricao: '13' },
                                                { id: 14, descricao: '14' },
                                                { id: 15, descricao: '15' },
                                                { id: 16, descricao: '16' },
                                                { id: 17, descricao: '17' },
                                                { id: 18, descricao: '18' },
                                                { id: 19, descricao: '19' },
                                                { id: 20, descricao: '20' },
                                                { id: 21, descricao: '21' },
                                                { id: 22, descricao: '22' },
                                                { id: 23, descricao: '23' },
                                                { id: 24, descricao: '24' },
                                                { id: 25, descricao: '25' },
                                                { id: 26, descricao: '26' },
                                                { id: 27, descricao: '27' },
                                                { id: 28, descricao: '28' },
                                                { id: 29, descricao: '29' },
                                                { id: 30, descricao: '30' },
                                                { id: 31, descricao: '31' },
                                                { id: 32, descricao: '32' },
                                                { id: 33, descricao: '33' },
                                                { id: 34, descricao: '34' },
                                                { id: 35, descricao: '35' },
                                                { id: 36, descricao: '36' },
                                                { id: 37, descricao: '37' },
                                                { id: 38, descricao: '38' },
                                                { id: 39, descricao: '39' },
                                                { id: 40, descricao: '40' },
                                                { id: 41, descricao: '41' },
                                                { id: 42, descricao: '42' },
                                                { id: 43, descricao: '43' },
                                                { id: 44, descricao: '44' },
                                                { id: 45, descricao: '45' },
                                                { id: 46, descricao: '46' },
                                                { id: 47, descricao: '47' },
                                                { id: 48, descricao: '48' },
                                                { id: 49, descricao: '49' },
                                                { id: 50, descricao: '50' },
                                                { id: 51, descricao: '51' },
                                                { id: 52, descricao: '52' },
                                                { id: 53, descricao: '53' },
                                                { id: 54, descricao: '54' },
                                                { id: 55, descricao: '55' },
                                                { id: 56, descricao: '56' },
                                                { id: 57, descricao: '57' },
                                                { id: 58, descricao: '58' },
                                                { id: 59, descricao: '59' },
                                                { id: 60, descricao: '60' },
                                             ]}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.tempoEntreMedidas = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.tempoEntreMedidas = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Row>
                              </Fragment>
                           )}

                        {(this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoIntroducao ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoNormas ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoTermos ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoObjetivos ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoProcedimento ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoCritetioDeAceitacao ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoConclusao ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoIndicador ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.textoEquacao) && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.descTitulo}</Label>
                                       <TextInput
                                          defaultValue={this.state.configuracaoSelecionada.descTitulo}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.descTitulo = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAQT.tipoEquipamento}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoEquipamento}
                                          options={getTipoEquipamentoAQT(this.props.lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoEquipamento = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoEquipamento = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              {!this.state.alterandoTipoDeTeste && (
                                 <Row>
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaAQT.descTexto}</Label>
                                          <TextArea
                                             defaultValue={this.state.configuracaoSelecionada.descTexto}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.descTexto = e.target.value;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Row>
                              )}
                           </Fragment>
                        )}
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            <Button
               text={this.props.lang.sequencia.duplicarSequencia}
               style={{ width: 200 }}
               onClick={() => {
                  let item = JSON.parse(JSON.stringify(this.state.itemSelecionado));
                  item.id = null;
                  if (item.itens != null) {
                     for (let i = 0; i < item.itens.length; i++) {
                        item.itens[i].id = null;
                     }
                  }
                  if (item.tiposDeEnsaio != null) {
                     for (let i = 0; i < item.tiposDeEnsaio.length; i++) {
                        item.tiposDeEnsaio[i].id = null;
                     }
                  }

                  updateState(this, (state) => {
                     state.incluindo = false;
                     state.alterando = false;
                     state.valorPadrao = item;
                  }).then(() => {
                     this.form.inserirClick();
                  });
               }}
            />
            <br />
         </div>
      );
   };

   aposSalvar = (subCadastro, inserindo, alterando) => {
      let ordem = this.state.ultimoItemCriado.numero;
      let finalizandoCiclo = this.state.finalizandoCiclo;
      updateState(this, (state) => {
         if (this.state.ultimoItemCriado.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis) {
            state.quantidadeDeCiclos = (this.state.quantidadeDeCiclos ? this.state.quantidadeDeCiclos : 0) + 1;
         }

         state.itemSelecionado.itens.sort((i, j) => {
            let result = i.numero < j.numero ? -1 : i.numero > j.numero ? 1 : 0;
            if (j.numero === ordem && i.numero === ordem) {
               result = -1;
            }
            return result;
         });
         state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));

         if (finalizandoCiclo) {
            state.finalizandoCiclo = false;
         }
      });
      if (inserindo && !finalizandoCiclo) {
         subCadastro.novo();
      }
   };

   setValoresDefault = (item) => {
      let padrao = null;

      if (this.state.ultimoItemCriado && !this.state.configuracaoSelecionada) {
         padrao = this.state.ultimoItemCriado;

         if (padrao.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis) {
            padrao.indice = this.state.ultimoItemCriado.indice + 1;
            if (this.state.finalizandoCiclo) {
               padrao.indice = this.state.quantidadeDeCiclos;
            }
         }
      } else if (item !== null) {
         if (item.tipoDeTeste === TIPO_DE_TESTE.vazamento) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               norma: 0,
               unidadeMedida: 0,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               pressaoInicial: 90,
               tempoMaximoParaEstabilidade: 30,
               vazamentoMaximo: 130,
               tempoDuracao: 300,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.distribuicaoDeTemperatura) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               norma: 0,
               erroTemperatura: 3,
               temperaturaOperacao: 127,
               deltaTemperatura: 1,
               tempoEntreMedidas: 1,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               tempoFase1Esperado: 30,
               tempoFase2Esperado: 30,
               tempoFase3Esperado: 30,
               tempoFase4Esperado: 30,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.penetracaoDeTemperatura) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               norma: 2,
               erroTemperatura: 3,
               temperaturaOperacao: 127,
               deltaTemperatura: 1,
               tempoEntreMedidas: 1,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               tempoFase1Esperado: 30,
               tempoFase2Esperado: 30,
               tempoFase3Esperado: 30,
               tempoFase4Esperado: 30,
               tipoLetalidade: 1,
               populacaoInicialMantissa: 1,
               populacaoInicialExpoente: 5,
               valorD: 30,
               temperaturaD: 127,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.aberturaDePorta) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               norma: 2,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               temperaturaInicial: 0,
               temperaturaLimite: 10,
               tempoLimite: 30,
               tempoRetorno: 30,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.quedaDeEnergia) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               norma: 2,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               temperaturaInicial: 0,
               temperaturaLimite: 10,
               tempoLimite: 30,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.manual) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               tempoDuracao: -1,
               tempoEntreMedidas: 1,
               temperaturaRef: 127,
               limiteInferior: 3,
               limiteSuperior: 3,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoDinamica) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               tempoDuracao: 30,
               tempoEntreMedidas: 3,
               vazamentoMaximo: 1000,
               unidadeMedida: 0,
               norma: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.ciclosAjustaveis && !this.state.finalizandoCiclo) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               indice: 1,
               descTipoCarga: this.props.lang.cadastroDeSequenciaAQT.vazio,
               tempoDuracao: 30,
               testarTemperatura: 0,
               temperaturaOperacao: 127,
               deltaTemperatura: 1,
               erroTemperatura: 3,
               realizarCalculos: 0,
               nomeCiclo: this.props.lang.cadastroDeSequenciaAQT.vazio,
               nomeFase: this.props.lang.cadastroDeSequenciaAQT.vazio,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoIntroducao) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoIntroducaoTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoIntroducao,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoNormas) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoNormaTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoNorma,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoTermos) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoTermosTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoTermos,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoObjetivos) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoObjetivoTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoObjetivo,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoProcedimento) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoProcedimentoTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoProcedimento,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoCritetioDeAceitacao) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoCriterioAceitacaoTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoCriterioAceitacao,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoConclusao) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoConclusaoTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoConclusao,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoIndicador) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoIndicadorTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoIndicador,
               tipoEquipamento: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.textoEquacao) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               descTitulo: this.props.lang.cadastroDeSequenciaAQT.textoEquacaoTitulo,
               descTexto: this.props.lang.cadastroDeSequenciaAQT.textoEquacao,
               tipoEquipamento: 0,
            };
         }
      } else {
         padrao = {
            tipoDeTeste: 0,
         };
      }

      if (padrao) {
         item.repeticoes = padrao.repeticoes ? padrao.repeticoes : 1;
         item.tipoDeTeste = padrao.tipoDeTeste;
         item.limiteSuperior = padrao.limiteSuperior;
         item.limiteInferior = padrao.limiteInferior;
         item.temperatura = padrao.temperatura;
         item.deltaE = padrao.deltaE;
         item.deltaT = padrao.deltaT;
         item.deltaTemperatura = padrao.deltaTemperatura;
         item.erroTemperatura = padrao.erroTemperatura;
         item.indice = padrao.indice;
         item.nomeCiclo = padrao.nomeCiclo;
         item.nomeFase = padrao.nomeFase;
         item.norma = padrao.norma;
         item.descTipoCarga = padrao.descTipoCarga;
         item.descTexto = padrao.descTexto;
         item.descTitulo = padrao.descTitulo;
         item.numeroFases = padrao.numeroFases;
         item.populacaoInicial = padrao.populacaoInicial;
         item.populacaoInicialExpoente = padrao.populacaoInicialExpoente;
         item.populacaoInicialMantissa = padrao.populacaoInicialMantissa;
         item.pressaoInicial = padrao.pressaoInicial;
         item.realizarCalculos = padrao.realizarCalculos;
         item.temperaturaD = padrao.temperaturaD;
         item.temperaturaInicial = padrao.temperaturaInicial;
         item.temperaturaLimite = padrao.temperaturaLimite;
         item.temperaturaOperacao = padrao.temperaturaOperacao;
         item.temperaturaRef = padrao.temperaturaRef;
         item.tempoDuracao = padrao.tempoDuracao;
         item.tempoEntreMedidas = padrao.tempoEntreMedidas;
         item.tempoEsperado = padrao.tempoEsperado;
         item.tempoEsterilizacao = padrao.tempoEsterilizacao;
         item.tempoEstimado = padrao.tempoEstimado;
         item.tempoEstimadoMinimo = padrao.tempoEstimadoMinimo;
         item.tempoFase1Esperado = padrao.tempoFase1Esperado;
         item.tempoFase2Esperado = padrao.tempoFase2Esperado;
         item.tempoFase3Esperado = padrao.tempoFase3Esperado;
         item.tempoFase4Esperado = padrao.tempoFase4Esperado;
         item.tempoLimite = padrao.tempoLimite;
         item.tempoMaximoParaEstabilidade = padrao.tempoMaximoParaEstabilidade;
         item.tempoRetorno = padrao.tempoRetorno;
         item.tempoSecagem = padrao.tempoSecagem;
         item.testarTemperatura = padrao.testarTemperatura;
         item.tipoDeTeste = padrao.tipoDeTeste;
         item.tipoEquipamento = padrao.tipoEquipamento;
         item.tipoLetalidade = padrao.tipoLetalidade;
         item.umidade1 = padrao.umidade1;
         item.umidade2 = padrao.umidade2;
         item.umidade3 = padrao.umidade3;
         item.unidadeMedida = padrao.unidadeMedida;
         item.valorD = padrao.valorD;
         item.vazamentoMaximo = padrao.vazamentoMaximo;
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={
               <div>
                  <Link
                     to={'/sequencia'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',
                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.sequencias}</span>                     
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.cadastroDeSequenciaAQT.titulo}</span>
               </div>
            }
            url={'/sequenciaaqt'}
            fastUrl={'/sequenciaaqt/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1201, 1202, 1203, 1204]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            definirValoresPadrao={(item) => {
               return this.state.valorPadrao ? this.state.valorPadrao : item;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{}}
         />
      );
   }
}
