import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import { buildQueryString, updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import SequenciaJAUMPController from '../../controllers/Sequencias/SequenciaJAUMPController';
import {
   getTiposDoTipoTesteJAUMP,
   getTiposDeCargasJAUMP,
   getTiposDeUnidadeDeDesvioJAUMP,
   getTiposDeFormaDeOndasJAUMP,
} from './../Descricoes';
import { showError } from '../../components/Messages';
import { Link } from 'react-router-dom';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';
import TipoDeEnsaioView from './TipoDeEnsaioView';
import Button from '../../components/Button';
import CheckListView from '../Empresa/CheckListView';

const TIPO_DE_TESTE = {
   JAUMP_FREQUENCIA: 0,
   JAUMP_AMPLITUDE: 1,
   JAUMP_LARGURA: 2,
   JAUMP_SENSIBILIDADE: 3,
   JAUMP_REFRATARIO: 4,
};

export default class SequenciaJAUMPView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new SequenciaJAUMPController(this);
   }

   setUltimoItemCriado = (item) => {
      updateState(this, (state) => {
         state.ultimoItemCriado = {};
         state.ultimoItemCriado.numero = item.numero;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.carga = item.carga;
         state.ultimoItemCriado.frequenciaMP = item.frequenciaMP;
         state.ultimoItemCriado.larguraMP = item.larguraMP;
         state.ultimoItemCriado.amplitudeMP = item.amplitudeMP;
         state.ultimoItemCriado.limiteSuperior = item.limiteSuperior;
         state.ultimoItemCriado.limiteInferior = item.limiteInferior;
         state.ultimoItemCriado.unidadeLimites = item.unidadeLimites;
         state.ultimoItemCriado.sensibilidade = item.sensibilidade;
         state.ultimoItemCriado.tempoPRPMaximo = item.tempoPRPMaximo;
         state.ultimoItemCriado.tempoSRPMaximo = item.tempoSRPMaximo;
         state.ultimoItemCriado.formaDeOnda = item.formaDeOnda;
         state.ultimoItemCriado.formaDeOndaLargura = item.formaDeOndaLargura;
         state.ultimoItemCriado.formaDeOndaAmplitude = item.formaDeOndaAmplitude;
      });
   };

   getDescricaoDotipoTesteJAUMP = (id) => {
      let result = getTiposDoTipoTesteJAUMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDaUnidadeDesvioJAUMP = (id) => {
      let result = getTiposDeUnidadeDeDesvioJAUMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{lang.sequencia.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.sequencia.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.sequencia.ativa },
                           { id: 2, descricao: lang.sequencia.inativa },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={lang.sequencia.tiposDeEnsaio}
               modal={true}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.tiposDeEnsaio}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = {
                           tipoDeEnsaio: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio) {
                        showError(lang.sequencia.mensagens.informeOTipoDeEnsaio);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.tiposDeEnsaio) {
                           state.itemSelecionado.tiposDeEnsaio = [];
                        }
                        state.itemSelecionado.tiposDeEnsaio.push({
                           tipoDeEnsaio: this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.tiposDeEnsaio.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Cargo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.tipoDeEnsaio.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{lang.sequencia.tipoDeEnsaio}</Label>
                           <Select
                              name={'tipoDeEnsaio'}
                              modal={true}
                              defaultValue={this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.tipoDeEnsaioSelecionado.tipoDeEnsaio = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <TipoDeEnsaioView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/tipodeensaio' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />

            <br />
            {!this.state.itemSelecionado.sequenciaNeos && (
               <SubCadastro
                  titulo={lang.sequencia.checkList}
                  modal={true}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.checkList}
                  lang={lang}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = {
                              checkList: null,
                           };
                        }).then(() => resolve());
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve, reject) => {
                        if (!this.state.checkListSelecionado.checkList) {
                           showError(lang.sequencia.mensagens.informeOCheckList);
                           reject();
                           return false;
                        }

                        updateState(this, (state) => {
                           if (!state.itemSelecionado.checkList) {
                              state.itemSelecionado.checkList = [];
                           }
                           state.itemSelecionado.checkList.push({
                              checkList: this.state.checkListSelecionado.checkList,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = null;
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.checkList.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: lang.sequencia.checkList, width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.checkList.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <Label>{lang.sequencia.checkList}</Label>
                              <Select
                                 name={'checkList'}
                                 modal={true}
                                 defaultValue={this.state.checkListSelecionado.checkList}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.checkListSelecionado.checkList = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <CheckListView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/checklist' + buildQueryString(2, null, 'descricao', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     );
                  }}
               />
            )}

            <br />

            <SubCadastro
               titulo={lang.sequencia.testes}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.itens}
               tituloDoBotaoCancelar={lang.sequencia.finalizarCadastroDeTeste}
               gradeSempreVisivel={true}
               lang={lang}
               novo={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? () => {
                          return new Promise((resolve) => {
                             let maiorNumero = 0;
                             if (this.state.itemSelecionado.itens) {
                                this.state.itemSelecionado.itens.map((i, index) => {
                                   if (i.numero > maiorNumero) {
                                      maiorNumero = i.numero;
                                   }
                                   return true;
                                });
                             }
                             maiorNumero++;
                             updateState(this, (state) => {
                                state.configuracaoSelecionada = null;
                                let configuracaoSelecionada = { tipoDeTeste: 0 };
                                this.setValoresDefault(configuracaoSelecionada);
                                configuracaoSelecionada.numero = maiorNumero;
                                state.configuracaoSelecionada = configuracaoSelecionada;
                             }).then(() => resolve());
                          });
                       }
                     : null
               }
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.configuracaoSelecionada.numero) {
                        showError(lang.sequencia.informeONumero);
                        reject();
                        return false;
                     }

                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (this.state.configuracaoSelecionada.frequenciaMP === 0) {
                        showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeAFrequenciaDeOperacao);
                        reject();
                        return false;
                     }
                     if (this.state.configuracaoSelecionada.larguraMP === 0) {
                        showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeALarguraDePulso);
                        reject();
                        return false;
                     }

                     if (this.state.configuracaoSelecionada.amplitudeMP === 0) {
                        showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeAAplDePulso);
                        reject();
                        return false;
                     }

                     if (this.state.configuracaoSelecionada.tipoDeTeste < 3) {
                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.limiteInferior === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === 3) {
                        if (this.state.configuracaoSelecionada.sensibilidade === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeASensibilidade);
                           reject();
                           return false;
                        }
                     }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === 4) {
                        if (this.state.configuracaoSelecionada.tempoPRPMaximo === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOTempoLimitePRP);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.tempoSRPMaximo === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOTempoLimiteSRP);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.formaDeOndaLargura === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeALarguraDoSinalGerado);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.formaDeOndaAmplitude === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeAAmplDoSinalGerado);
                           reject();
                           return false;
                        }
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.itens) {
                           state.itemSelecionado.itens = [];
                        }

                        let item = {
                           numero: this.state.configuracaoSelecionada.numero,
                           repeticoes: 1,
                           tipoDeTeste: this.state.configuracaoSelecionada.tipoDeTeste,
                           carga: this.state.configuracaoSelecionada.carga,
                           frequenciaMP: this.state.configuracaoSelecionada.frequenciaMP,
                           larguraMP: this.state.configuracaoSelecionada.larguraMP,
                           amplitudeMP: this.state.configuracaoSelecionada.amplitudeMP,
                           limiteSuperior: this.state.configuracaoSelecionada.limiteSuperior,
                           limiteInferior: this.state.configuracaoSelecionada.limiteInferior,
                           unidadeLimites: this.state.configuracaoSelecionada.unidadeLimites,
                           sensibilidade: this.state.configuracaoSelecionada.sensibilidade,
                           tempoPRPMaximo: this.state.configuracaoSelecionada.tempoPRPMaximo,
                           tempoSRPMaximo: this.state.configuracaoSelecionada.tempoSRPMaximo,
                           formaDeOnda: this.state.configuracaoSelecionada.formaDeOnda,
                           formaDeOndaLargura: this.state.configuracaoSelecionada.formaDeOndaLargura,
                           formaDeOndaAmplitude: this.state.configuracaoSelecionada.formaDeOndaAmplitude,
                        };

                        state.itemSelecionado.itens.push(item);
                        this.setUltimoItemCriado(item);
                     }).then(() => resolve());
                  });
               }}
               aposSalvar={this.aposSalvar}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.configuracaoSelecionada.numero) {
                        showError(lang.sequencia.informeONumero);
                        reject();
                        return false;
                     }

                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (this.state.configuracaoSelecionada.frequenciaMP === 0) {
                        showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeAFrequenciaDeOperacao);
                        reject();
                        return false;
                     }
                     if (this.state.configuracaoSelecionada.larguraMP === 0) {
                        showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeALarguraDePulso);
                        reject();
                        return false;
                     }

                     if (this.state.configuracaoSelecionada.amplitudeMP === 0) {
                        showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeAAplDePulso);
                        reject();
                        return false;
                     }

                     if (this.state.configuracaoSelecionada.tipoDeTeste < 3) {
                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.limiteInferior === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.JAUMP_SENSIBILIDADE) {
                        if (this.state.configuracaoSelecionada.sensibilidade === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeASensibilidade);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.formaDeOnda === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeAFormaDeOnda);
                           reject();
                           return false;
                        }
                     }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.JAUMP_REFRATARIO) {
                        if (this.state.configuracaoSelecionada.tempoPRPMaximo === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOTempoLimitePRP);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.tempoSRPMaximo === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeOTempoLimiteSRP);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.formaDeOndaLargura === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeALarguraDoSinalGerado);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.formaDeOndaAmplitude === 0) {
                           showError(lang.cadastroDeSequenciaJAUMP.mensagens.informeAAmplDoSinalGerado);
                           reject();
                           return false;
                        }
                     }

                     updateState(this, (state) => {
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;
                        state.itemSelecionado.itens[index].numero = this.state.configuracaoSelecionada.numero;
                        state.itemSelecionado.itens[index].repeticoes = this.state.configuracaoSelecionada.repeticoes;
                        state.itemSelecionado.itens[index].carga = this.state.configuracaoSelecionada.carga;
                        state.itemSelecionado.itens[index].frequenciaMP =
                           this.state.configuracaoSelecionada.frequenciaMP;
                        state.itemSelecionado.itens[index].larguraMP = this.state.configuracaoSelecionada.larguraMP;
                        state.itemSelecionado.itens[index].amplitudeMP = this.state.configuracaoSelecionada.amplitudeMP;
                        state.itemSelecionado.itens[index].limiteSuperior =
                           this.state.configuracaoSelecionada.limiteSuperior;
                        state.itemSelecionado.itens[index].limiteInferior =
                           this.state.configuracaoSelecionada.limiteInferior;
                        state.itemSelecionado.itens[index].unidadeLimites =
                           this.state.configuracaoSelecionada.unidadeLimites;
                        state.itemSelecionado.itens[index].sensibilidade =
                           this.state.configuracaoSelecionada.sensibilidade;
                        state.itemSelecionado.itens[index].tempoPRPMaximo =
                           this.state.configuracaoSelecionada.tempoPRPMaximo;
                        state.itemSelecionado.itens[index].tempoSRPMaximo =
                           this.state.configuracaoSelecionada.tempoSRPMaximo;
                        state.itemSelecionado.itens[index].formaDeOnda = this.state.configuracaoSelecionada.formaDeOnda;
                        state.itemSelecionado.itens[index].formaDeOndaLargura =
                           this.state.configuracaoSelecionada.formaDeOndaLargura;
                        state.itemSelecionado.itens[index].formaDeOndaAmplitude =
                           this.state.configuracaoSelecionada.formaDeOndaAmplitude;
                        this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? (index) => {
                          updateState(this, (state) => {
                             state.itemSelecionado.itens.splice(index, 1);
                             state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
                          });
                       }
                     : null
               }
               colunas={() => {
                  return [
                     { titulo: 'Nº', width: '3%', className: null },
                     { titulo: 'Tipo de Teste', width: '17%', className: null },
                     { titulo: 'Valor de referência', width: '23%', className: null },
                     { titulo: 'Desvio máximo superior', width: '17%', className: null },
                     { titulo: 'Desvio máximo inferior', width: '16%', className: null },
                     { titulo: 'Unid desvio', width: '25%', className: null },
                  ];
               }}
               linha={(item) => {
                  if (item.tipoDeTeste < 3) {
                     return [
                        item.numero,
                        this.getDescricaoDotipoTesteJAUMP(item.tipoDeTeste),
                        item.frequenciaMP + ' PPM',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvioJAUMP(item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.JAUMP_SENSIBILIDADE) {
                     return [
                        item.numero,
                        this.getDescricaoDotipoTesteJAUMP(item.tipoDeTeste),
                        item.sensibilidade + ' mV',
                        '-',
                        '-',
                        '-',
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.JAUMP_REFRATARIO) {
                     return [
                        item.numero,
                        this.getDescricaoDotipoTesteJAUMP(item.tipoDeTeste),
                        `$PRP: ${item.tempoPRPMaximo} / SRP: ${item.tempoSRPMaximo}`,
                        '-',
                        '-',
                        '-',
                     ];
                  } else {
                     return [];
                  }
               }}
               acoes={(subCadastro, index) => {
                  return (
                     <div style={{ display: 'table-cell', margin: 'auto', marginRight: 4, width: 25 }}>
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginBottom: -10 }}
                           cursor='pointer'
                           icon={faSortUp}
                           onClick={() => {
                              if (this.state.itemSelecionado.itens[index].numero > 1) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index - 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero - 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginTop: -10 }}
                           cursor='pointer'
                           icon={faSortDown}
                           onClick={() => {
                              if (
                                 this.state.itemSelecionado.itens[index].numero <
                                 this.state.itemSelecionado.itens.length
                              ) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index + 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero + 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                     </div>
                  );
               }}
               formulario={() => {
                  return this.state.configuracaoSelecionada ? (
                     <div>
                        <Row>
                        <Col sm={2} md={2} lg={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.numero}</Label>
                                 <IntegerInput
                                    defaultValue={this.state.configuracaoSelecionada.numero}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.numero = e.floatValue;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {/* <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.repeticoes}</Label>
                                 <IntegerInput
                                    // defaultValue={this.state.configuracaoSelecionada.repeticoes}
                                    defaultValue={1}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.repeticoes = e.floatValue;
                                       });
                                    }}
                                    readOnly={true}
                                 />
                              </FormGroup>
                           </Col> */}
                           <Col>
                              <FormGroup>
                                 <Label>{lang.cadastroDeSequenciaJAUMP.tipoDeTeste}</Label>
                                 <Select
                                    defaultValue={this.state.configuracaoSelecionada.tipoDeTeste}
                                    options={getTiposDoTipoTesteJAUMP(lang)}
                                    allowEmpty={false}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.descricao}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i != null) {
                                             state.configuracaoSelecionada.tipoDeTeste = i.id;
                                          } else {
                                             state.configuracaoSelecionada.tipoDeTeste = 0;
                                          }
                                          let configuracaoSelecionada = state.configuracaoSelecionada;
                                          this.setValoresDefault(configuracaoSelecionada);
                                          state.configuracaoSelecionada = configuracaoSelecionada;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {this.state.configuracaoSelecionada.tipoDeTeste === 4 && (
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.formaDeOndaGerada}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.formaDeOnda}
                                       options={getTiposDeFormaDeOndasJAUMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.formaDeOnda = i.id;
                                             } else {
                                                state.configuracaoSelecionada.formaDeOnda = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           )}
                           {this.state.configuracaoSelecionada.tipoDeTeste === 4 && (
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.carga}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.carga}
                                       options={getTiposDeCargasJAUMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.carga = i.id;
                                             } else {
                                                state.configuracaoSelecionada.carga = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           )}
                           {this.state.configuracaoSelecionada.tipoDeTeste < 4 && (
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.carga}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.carga}
                                       options={getTiposDeCargasJAUMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.carga = i.id;
                                             } else {
                                                state.configuracaoSelecionada.carga = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           )}
                        </Row>

                        {this.state.configuracaoSelecionada.tipoDeTeste < 3 && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.frequenciaDeOperacao}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.frequenciaMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.frequenciaMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.larguraDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.larguraMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.larguraMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.aplDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.amplitudeMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.amplitudeMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste < 3 && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.unidadeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={getTiposDeUnidadeDeDesvioJAUMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       allowEmpty={false}
                                       getDescription={(i) => i.descricao}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === 3 && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.frequenciaDeOperacao}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.frequenciaMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.frequenciaMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.larguraDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.larguraMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.larguraMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.aplDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.amplitudeMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.amplitudeMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}
                        {this.state.configuracaoSelecionada.tipoDeTeste === 3 && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.limiteDeSensibilidade}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.sensibilidade}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.sensibilidade = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.formaDeOndaGerada}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.formaDeOnda}
                                       options={getTiposDeFormaDeOndasJAUMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.formaDeOnda = i.id;
                                             } else {
                                                state.configuracaoSelecionada.formaDeOnda = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.larguraDeSinalGerado}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.formaDeOndaLargura}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.formaDeOndaLargura = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === 3 && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.frequenciaDeOperacao}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.frequenciaMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.frequenciaMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.larguraDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.larguraMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.larguraMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.aplDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.amplitudeMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.amplitudeMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}
                        {this.state.configuracaoSelecionada.tipoDeTeste === 4 && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.frequenciaDeOperacao}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.frequenciaMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.frequenciaMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.larguraDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.larguraMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.larguraMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.aplDePulso}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.amplitudeMP}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.amplitudeMP = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}
                        {this.state.configuracaoSelecionada.tipoDeTeste === 4 && (
                           <Row>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.tempoLimiteParaPRP}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.tempoPRPMaximo}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.tempoPRPMaximo = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.tempoLimiteParaSRP}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.tempoSRPMaximo}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.tempoSRPMaximo = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.larguraDeSinalGerado}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.formaDeOndaLargura}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.formaDeOndaLargura = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaJAUMP.amplDeSinalGerado}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.formaDeOndaAmplitude}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.formaDeOndaAmplitude = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />

            <br />
         </React.Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            <Button
               text={this.props.lang.sequencia.duplicarSequencia}
               style={{ width: 200 }}
               onClick={() => {
                  let item = JSON.parse(JSON.stringify(this.state.itemSelecionado));
                  item.id = null;
                  if (item.itens != null) {
                     for (let i = 0; i < item.itens.length; i++) {
                        item.itens[i].id = null;
                     }
                  }
                  if (item.tiposDeEnsaio != null) {
                     for (let i = 0; i < item.tiposDeEnsaio.length; i++) {
                        item.tiposDeEnsaio[i].id = null;
                     }
                  }

                  updateState(this, (state) => {
                     state.incluindo = false;
                     state.alterando = false;
                     state.valorPadrao = item;
                  }).then(() => {
                     this.form.inserirClick();
                  });
               }}
            />
            <br />
         </div>
      );
   };

   aposSalvar = (subCadastro, inserindo, alterando) => {
      let ordem = this.state.ultimoItemCriado.numero;
      updateState(this, (state) => {
         state.itemSelecionado.itens.sort((i, j) => {
            let result = i.numero < j.numero ? -1 : i.numero > j.numero ? 1 : 0;
            if (j.numero === ordem && i.numero === ordem) {
               result = -1;
            }
            return result;
         });
         state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
      });
      if (inserindo) {
         subCadastro.novo();
      }
   };

   setValoresDefault = (item) => {
      let padrao = null;

      if (this.state.ultimoItemCriado && !this.state.configuracaoSelecionada) {
         padrao = this.state.ultimoItemCriado;
      } else if (item !== null) {
         if (item.tipoDeTeste === TIPO_DE_TESTE.JAUMP_FREQUENCIA) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               carga: 0,
               frequenciaMP: 80,
               larguraMP: 50,
               amplitudeMP: 100,
               unidadeLimites: 0,
               limiteSuperior: 10,
               limiteInferior: 10,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.JAUMP_AMPLITUDE) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               carga: 0,
               frequenciaMP: 80,
               larguraMP: 50,
               amplitudeMP: 100,
               unidadeLimites: 0,
               limiteSuperior: 10,
               limiteInferior: 10,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.JAUMP_LARGURA) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               carga: 0,
               frequenciaMP: 80,
               larguraMP: 50,
               amplitudeMP: 100,
               unidadeLimites: 0,
               limiteSuperior: 10,
               limiteInferior: 10,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.JAUMP_SENSIBILIDADE) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               carga: 0,
               frequenciaMP: 80,
               larguraMP: 50,
               amplitudeMP: 100,
               sensibilidade: 1,
               formaDeOnda: 0,
               formaDeOndaLargura: 50,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.JAUMP_REFRATARIO) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               carga: 0,
               frequenciaMP: 80,
               larguraMP: 50,
               amplitudeMP: 100,
               tempoPRPMaximo: 200,
               tempoSRPMaximo: 200,
               formaDeOnda: 0,
               formaDeOndaLargura: 50,
               formaDeOndaAmplitude: 1,
            };
         }
      } else {
         padrao = {
            tipoDeTeste: 0,
         };
      }

      if (padrao) {
         item.repeticoes = 1;
         item.tipoDeTeste = padrao.tipoDeTeste;
         item.carga = padrao.carga;
         item.frequenciaMP = padrao.frequenciaMP;
         item.larguraMP = padrao.larguraMP;
         item.amplitudeMP = padrao.amplitudeMP;
         item.sensibilidade = padrao.sensibilidade;
         item.tempoPRPMaximo = padrao.tempoPRPMaximo;
         item.tempoSRPMaximo = padrao.tempoSRPMaximo;
         item.formaDeOnda = padrao.formaDeOnda;
         item.formaDeOndaLargura = padrao.formaDeOndaLargura;
         item.formaDeOndaAmplitude = padrao.formaDeOndaAmplitude;
         item.limiteSuperior = padrao.limiteSuperior;
         item.limiteInferior = padrao.limiteInferior;
         item.unidadeLimites = padrao.unidadeLimites;
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={
               <div>
                  <Link
                     to={'/sequencia'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',
                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.sequencias}</span>                     
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.cadastroDeSequenciaJAUMP.titulo}</span>
               </div>
            }
            url={'/sequenciajaump'}
            fastUrl={'/sequenciajaump/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1241, 1242, 1243, 1244]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            definirValoresPadrao={(item) => {
               return this.state.valorPadrao ? this.state.valorPadrao : item;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               acessos: [],
            }}
         />
      );
   }
}
