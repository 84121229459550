import { Row, Col, FormGroup, Modal, Tab, Tabs } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import OrdemDeServicoController from '../../controllers/Empresa/OrdemDeServicoController';
import { updateState, buildQueryString, dateToString, today, formatDate, downloadBase64File } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import ClienteView from './ClienteView';
import UsuarioDeEmpresaView from './UsuarioDeEmpresaView';
import EquipamentoDeClienteView from '../Cliente/EquipamentoDeClienteView';
import SequenciaABIView from '../Sequencias/SequenciaABIView';
import SequenciaASEView from '../Sequencias/SequenciaASEView';
import SequenciaAVMView from '../Sequencias/SequenciaAVMView';
import SequenciaAPEView from '../Sequencias/SequenciaAPEView';
import SequenciaJAUView from '../Sequencias/SequenciaJAUView';
import SequenciaJAUMPView from '../Sequencias/SequenciaJAUMPView';
import SequenciaSOPView from '../Sequencias/SequenciaSOPView';
import SequenciaSIMView from '../Sequencias/SequenciaSIMView';
import SequenciaSMPView from '../Sequencias/SequenciaSMPView';
import SequenciaSPKView from '../Sequencias/SequenciaSPKView';
import SequenciaMDCView from '../Sequencias/SequenciaMDCView';
import TipoDeEquipamentoView from '../Neos/TipoDeEquipamentoView';
import Button from '../../components/Button';
import { showError, showInfo } from '../../components/Messages';
import EquipamentoDeEmpresaView from '../Empresa/EquipamentoDeEmpresaView';
import TextArea from '../../components/TextArea';
import IntegerInput from '../../components/IntegerInput';
import ImportacaoDeTesteAqtView from './ImportacaoDeTesteAqtView';
import ImportacaoDeTesteTinView from './ImportacaoDeTesteTinView';
import { LayoutParams } from '../../config/LayoutParams';
import styled from 'styled-components';
import IconButton from '../../components/IconButton';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Constants = {
   AQT_VAZAMENTO: 0,
   AQT_DISTRIBUICAO_TEMPERATURA: 1,
   AQT_PENETRACAO_TEMPERATURA: 2,
   AQT_ABERTURA_PORTA: 3,
   AQT_QUEDA_ENERGIA: 4,
   AQT_MANUAL: 5,
   AQT_PRESSAO_DINAMICA: 6,
   AQT_CICLOS_AJUSTAVEIS: 7,
   AQT_DETECCAO_ESTABILIDADE: 8,
};

export default class OrdemDeServicoView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrar: true, abaSelecionada: 'JAU' };
      this.controller = new OrdemDeServicoController(this);
   }

   componentDidMount() {
      this.props.api.get('/parametrodosistema/NumeroDeMesesDeValidadeDoCertificado')
      .then((result) => {
         this.setState({
            numeroDeMesesDeValidadeDoCertificado: result ? parseInt(result) : null
         });
      });
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeOrdemDeServico = lang.telaDeCadastroDeOrdemDeServico;
      return !this.state.mostrar ? (
         <div />
      ) : (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col sm={3} xs={3} md={3}>
                  <Label>{telaDeCadastroDeOrdemDeServico.dataDeAgendamento}</Label>
                  <DateInput
                     defaultValue={
                        this.state.itemSelecionado.dataDeAgendamento
                           ? this.state.itemSelecionado.dataDeAgendamento
                           : today()
                     }
                     onChange={(value) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.dataDeAgendamento = value;
                        });
                     }}
                  />
               </Col>

               <Col sm={4} xs={4} md={4}>
                  <Label>{telaDeCadastroDeOrdemDeServico.numeroDeMesesDeValidadeDoCertificado}</Label>
                  <IntegerInput
                     defaultValue={this.state.itemSelecionado.numeroDeMesesDeValidadeDoCertificado}
                     onChange={(e) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.numeroDeMesesDeValidadeDoCertificado = e.floatValue;
                        });
                     }}
                  />
               </Col>

               <Col sm={3} xs={3} md={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.situacao}</Label>
                     <TextInput
                        readOnly
                        defaultValue={
                           this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.descricao
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.usuarioResponsavel}</Label>
                     <Select
                        name={'usuarioresponsavel'}
                        defaultValue={this.state.itemSelecionado.usuarioResponsavel}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.usuarioResponsavel = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <UsuarioDeEmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/usuariodeempresa/fast' + buildQueryString(7, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.usuarioExecutor}</Label>
                     <Select
                        name={'usuarioexecutor'}
                        defaultValue={this.state.itemSelecionado.usuarioExecutor}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.usuarioExecutor = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <UsuarioDeEmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/usuariodeempresa/fast' + buildQueryString(7, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.cliente}</Label>
                     <Select
                        name={'cliente'}
                        defaultValue={this.state.itemSelecionado.cliente}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) =>
                           i.apelidoOuNomeFantasia ? i.apelidoOuNomeFantasia : i.nomeCompletoOuRazaoSocial
                        }
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.cliente = i;
                              state.itemSelecionado.equipamentoSobTeste = null;
                              if (i) {
                                 state.itemSelecionado.nomeDoCliente = i.nomeCompletoOuRazaoSocial;
                                 state.itemSelecionado.enderecoDoCliente = i.enderecoPrincipalCompleto;
                                 state.itemSelecionado.telefoneDoCliente = i.telefones ? i.telefones.join(' / ') : null;
                                 state.itemSelecionado.cpfCnpjDoCliente = i.cpfCnpj;
                                 state.itemSelecionado.emailDoCliente = i.email;
                                 state.itemSelecionado.itens = [];
                              } else {
                                 state.itemSelecionado.nomeDoCliente = null;
                                 state.itemSelecionado.enderecoDoCliente = null;
                                 state.itemSelecionado.telefoneDoCliente = null;
                                 state.itemSelecionado.cpfCnpjDoCliente = null;
                                 state.itemSelecionado.emailDoCliente = null;
                                 state.itemSelecionado.itens = [];
                              }
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <ClienteView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/cliente/fast' + buildQueryString(7, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.state.itemSelecionado.cliente && (
               <React.Fragment>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.nomeDoCliente}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.nomeDoCliente}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.nomeDoCliente = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>

                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.cpfCnpjDoCliente}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.cpfCnpjDoCliente}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.cpfCnpjDoCliente = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.telefoneDoCliente}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.telefoneDoCliente}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.telefoneDoCliente = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.emailDoCliente}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.emailDoCliente}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.emailDoCliente = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.enderecoDoCliente}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.enderecoDoCliente}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.enderecoDoCliente = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </React.Fragment>
            )}

            <Row>
               {this.state.itemSelecionado.cliente && this.state.itemSelecionado.cliente.id > 0 && (
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeOrdemDeServico.equipamentoSobTeste}</Label>
                        <Select
                           name={'equipamentodecliente'}
                           defaultValue={this.state.itemSelecionado.equipamentoSobTeste}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) =>
                              i.tipoDeEquipamento + ' / ' + i.numeroDeSerie + ' / ' + i.numeroDePatrimonio
                           }
                           onSelect={(i) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.equipamentoSobTeste = i;
                              });
                           }}
                           formularioPadrao={(select) => {
                              return (
                                 <EquipamentoDeClienteView
                                    api={this.props.api}
                                    select={select}
                                    cliente={this.state.itemSelecionado.cliente}
                                    lang={this.props.lang}
                                    filtroExtra={() => {
                                       return {
                                          idDoCliente: this.state.itemSelecionado.cliente
                                             ? this.state.itemSelecionado.cliente.id
                                             : null,
                                       };
                                    }}
                                 />
                              );
                           }}
                           noDropDown={true}
                           readOnlyColor='#ffff'
                           getFilterUrl={(text) =>
                              '/equipamentodecliente' +
                              buildQueryString(7, null, 'id', {
                                 Searchable: text,
                                 idDoCliente: this.state.itemSelecionado.cliente
                                    ? this.state.itemSelecionado.cliente.id
                                    : 2,
                              })
                           }
                        />
                     </FormGroup>
                  </Col>
               )}

               {!this.state.itemSelecionado.cliente && (
                  <Col>
                     <FormGroup>
                        <Label>{telaDeCadastroDeOrdemDeServico.equipamentoSobTeste}</Label>
                        <Select
                           name={'equipamentodeempresa'}
                           defaultValue={this.state.itemSelecionado.equipamentoSobTeste}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.tipoDeEquipamento + ' / ' + i.numeroDeSerie}
                           onSelect={(i) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.equipamentoSobTeste = i;
                              });
                           }}
                           formularioPadrao={(select) => {
                              return (
                                 <EquipamentoDeEmpresaView
                                    api={this.props.api}
                                    select={select}
                                    lang={this.props.lang}
                                 />
                              );
                           }}
                           noDropDown={true}
                           readOnlyColor='#ffff'
                           getFilterUrl={(text) =>
                              '/equipamentodeempresa' + buildQueryString(7, null, 'id', { Searchable: text })
                           }
                        />
                     </FormGroup>
                  </Col>
               )}
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.observacoes}</Label>
                     <TextArea
                        defaultValue={this.state.itemSelecionado.observacao}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.observacao = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.itens()}

            <br />

            {(this.state.itemSelecionado.testeAQT || this.state.itemSelecionado.testeTIN) && (
               <Fragment>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.localDoEnsaio}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.localDoEnsaio}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.localDoEnsaio = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.umidade}</Label>
                           <IntegerInput
                              defaultValue={this.state.itemSelecionado.umidade}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.umidade = e.floatValue;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeOrdemDeServico.temperatura}</Label>
                           <IntegerInput
                              defaultValue={this.state.itemSelecionado.temperatura}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.temperatura = e.floatValue;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </Fragment>
            )}

            <Modal
               show={this.state.importandoTestesDoAQT}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ importandoTestesDoAQT: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Header style={{ backgroundColor: LayoutParams.colors.corDoTemaPrincipal }}>
                  <Modal.Title className='h4' style={{ color: LayoutParams.colors.corSecundaria }}>
                     {this.props.lang.telaDeImportacaoDeTesteAQT.titulo}
                  </Modal.Title>
               </Modal.Header>

               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  <ImportacaoDeTesteAqtView
                     lang={this.props.lang}
                     api={this.props.api}
                     fecharImportacaoDeTestesAQT={() => {
                        this.setState({ importandoTestesDoAQT: false });
                     }}
                     getTestesAQT={() =>
                        this.state.itemDaOsSelecionado && this.state.itemDaOsSelecionado.testesAQT
                           ? this.state.itemDaOsSelecionado.testesAQT
                           : null
                     }
                     setTestesAQT={(testesAQT) => {
                        updateState(this, (state) => {
                           let testes = testesAQT;
                           if (!testes) {
                              testes = [];
                           }

                           state.itemDaOsSelecionado.testesAQT = testes;

                           state.itemDaOsSelecionado.testeAqtVazamento = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_VAZAMENTO
                           );
                           state.itemDaOsSelecionado.testeAqtCiclosAjustaveis = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_CICLOS_AJUSTAVEIS
                           );
                           state.itemDaOsSelecionado.testeAqtDeteccaoDeEstabilidade = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_DETECCAO_ESTABILIDADE
                           );
                           state.itemDaOsSelecionado.testeAqtDistribuicaoDeTemperatura = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_DISTRIBUICAO_TEMPERATURA
                           );
                           state.itemDaOsSelecionado.testeAqtManual = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_MANUAL
                           );
                           state.itemDaOsSelecionado.testeAqtPenetracaoDeTemperatura = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_PENETRACAO_TEMPERATURA
                           );
                           state.itemDaOsSelecionado.testeAqtPressaoDinamica = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_PRESSAO_DINAMICA
                           );
                           state.itemDaOsSelecionado.testeAqtQuedaDeEnergia = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_QUEDA_ENERGIA
                           );
                           state.itemDaOsSelecionado.testeAqtAberturaDePorta = testes.filter(
                              (i) => i.tipoTeste === Constants.AQT_ABERTURA_PORTA
                           );
                        });
                     }}
                  />
               </Modal.Body>

               <Modal.Footer>
                  <Button
                     style={{ width: '130px' }}
                     onClick={() => {
                        updateState(this, (state) => {
                           state.importandoTestesDoAQT = false;
                           if (state.itemDaOsSelecionado) {
                              state.itemDaOsSelecionado.testesAQT = [];
                           }
                        });
                     }}
                     text={this.props.lang.cancelar}
                  />
                  <Button
                     text='OK'
                     style={{ width: '130px' }}
                     onClick={() => {
                        let testesAQT = this.state.itemDaOsSelecionado.testesAQT;
                        if (!testesAQT || (testesAQT && testesAQT.length === 0)) {
                           showError(this.props.lang.telaDeCadastroDeOrdemDeServico.selecionePeloMenosUmTeste);
                           return;
                        }
                        updateState(this, (state) => {
                           state.importandoTestesDoAQT = false;
                           state.itemSelecionado.testeAQT = true;
                        });
                     }}
                  />
               </Modal.Footer>
            </Modal>

            <Modal
               show={this.state.importandoTestesDoTIN}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ importandoTestesDoTIN: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Header style={{ backgroundColor: LayoutParams.colors.corDoTemaPrincipal }}>
                  <Modal.Title className='h4' style={{ color: LayoutParams.colors.corSecundaria }}>
                     {this.props.lang.telaDeImportacaoDeTesteTIN.titulo}
                  </Modal.Title>
               </Modal.Header>

               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  <ImportacaoDeTesteTinView
                     lang={this.props.lang}
                     api={this.props.api}
                     fecharImportacaoDeTestesTIN={() => {
                        this.setState({ importandoTestesDoTIN: false });
                     }}
                     getTestesTIN={() =>
                        this.state.itemDaOsSelecionado && this.state.itemDaOsSelecionado.testeTIN
                           ? this.state.itemDaOsSelecionado.testeTIN
                           : null
                     }
                     setTestesTIN={(testesTIN) => {
                        updateState(this, (state) => {
                           let testes = testesTIN;
                           if (!testes) {
                              testes = [];
                           }

                           state.itemDaOsSelecionado.testeTIN = testes;
                        });
                     }}
                  />
               </Modal.Body>

               <Modal.Footer>
                  <Button
                     style={{ width: '130px' }}
                     onClick={() => {
                        updateState(this, (state) => {
                           state.importandoTestesDoTIN = false;
                           if (state.itemDaOsSelecionado) {
                              state.itemDaOsSelecionado.testeTIN = [];
                           }
                        });
                     }}
                     text={this.props.lang.cancelar}
                  />
                  <Button
                     text='OK'
                     style={{ width: '130px' }}
                     onClick={() => {
                        let testesTIN = this.state.itemDaOsSelecionado.testeTIN;
                        if (!testesTIN || (testesTIN && testesTIN.length === 0)) {
                           showError(this.props.lang.telaDeCadastroDeOrdemDeServico.selecionePeloMenosUmTeste);
                           return;
                        }
                        updateState(this, (state) => {
                           state.importandoTestesDoTIN = false;
                           state.itemSelecionado.testeTIN = true;
                        });
                     }}
                  />
               </Modal.Footer>
            </Modal>
         </React.Fragment>
      );
   };

   itens = () => {
      let telaDeCadastroDeOrdemDeServico = this.props.lang.telaDeCadastroDeOrdemDeServico;
      return (
         <SubCadastro
            titulo={telaDeCadastroDeOrdemDeServico.testes}
            exibirTitulos={true}
            itens={this.state.itemSelecionado.itens}
            lang={this.props.lang}
            modal={true}
            novo={() => {
               return new Promise((resolve, reject) => {
                  if (
                     this.state.itemSelecionado.testeAQT &&
                     this.state.itemSelecionado.itens &&
                     this.state.itemSelecionado.itens.length > 0
                  ) {
                     showError(this.props.lang.telaDeCadastroDeOrdemDeServico.ordemDeServicoAqtSoPodeTerUmTeste);
                     reject();
                     return;
                  }

                  if (
                     this.state.itemSelecionado.testeTIN &&
                     this.state.itemSelecionado.itens &&
                     this.state.itemSelecionado.itens.length > 0
                  ) {
                     showError(this.props.lang.telaDeCadastroDeOrdemDeServico.ordemDeServicoTinSoPodeTerUmTeste);
                     reject();
                     return;
                  }

                  updateState(this, (state) => {
                     state.itemDaOsSelecionado = {
                        testesTIN: [],
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirItem}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarItem}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.itens.splice(index, 1);
                  state.itemSelecionado.testeAQT = false;
                  state.itemSelecionado.testeTIN = false;
               });
            }}
            colunas={() => {
               return [
                  { titulo: this.props.lang.codigo, className: 'codigo' },
                  { titulo: telaDeCadastroDeOrdemDeServico.padrao, width: '20%', className: null },
                  { titulo: telaDeCadastroDeOrdemDeServico.sequencia, width: '80%', className: null },
               ];
            }}
            linha={(item) => {
               return [
                  item.id,
                  item.tipoDeEquipamento ? item.tipoDeEquipamento.sigla : null,
                  item.sequencia ? item.sequencia.nome : '-',
               ];
            }}
            formulario={() => {
               return (
                  this.state.itemDaOsSelecionado && (
                     <Fragment>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{telaDeCadastroDeOrdemDeServico.tipoDeAnalisador}</Label>
                                 <Select
                                    name={'tipoDeEquipamento'}
                                    defaultValue={this.state.itemDaOsSelecionado.tipoDeEquipamento}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.nome}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          state.itemDaOsSelecionado.tipoDeEquipamento = i;
                                       });
                                    }}
                                    formularioPadrao={(select) => {
                                       return (
                                          <TipoDeEquipamentoView
                                             api={this.props.api}
                                             select={select}
                                             lang={this.props.lang}
                                          />
                                       );
                                    }}
                                    noDropDown={true}
                                    readOnlyColor='#ffff'
                                    getFilterUrl={(text) =>
                                       '/tipodeequipamento' +
                                       buildQueryString(7, null, 'id', {
                                          Searchable: text,
                                       })
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           {this.state.itemDaOsSelecionado.tipoDeEquipamento &&
                              this.state.itemDaOsSelecionado.tipoDeEquipamento.tipoDeSequencia !== 11 &&
                              this.state.itemDaOsSelecionado.tipoDeEquipamento.tipoDeSequencia !== 6 && (
                                 <Col>
                                    <FormGroup>
                                       {this.state.itemDaOsSelecionado.tipoDeEquipamento &&
                                       this.state.itemDaOsSelecionado.tipoDeEquipamento.tipoDeSequencia !== null &&
                                       this.state.itemDaOsSelecionado.tipoDeEquipamento.tipoDeSequencia !==
                                          undefined ? (
                                          this.selectSequencia()
                                       ) : (
                                          <TextInput readOnly />
                                       )}
                                    </FormGroup>
                                 </Col>
                              )}
                           {this.state.itemDaOsSelecionado.tipoDeEquipamento &&
                              this.state.itemDaOsSelecionado.tipoDeEquipamento.tipoDeSequencia === 11 && (
                                 <Col>
                                    <Button
                                       onClick={() => {
                                          this.setState({ importandoTestesDoAQT: true });
                                       }}
                                       text={telaDeCadastroDeOrdemDeServico.importarBancoAQT}
                                       style={{ width: 200 }}
                                    />
                                 </Col>
                              )}

                           {this.state.itemDaOsSelecionado.tipoDeEquipamento &&
                              this.state.itemDaOsSelecionado.tipoDeEquipamento.tipoDeSequencia === 6 && (
                                 <Col>
                                    <Button
                                       onClick={() => {
                                          this.setState({ importandoTestesDoTIN: true });
                                       }}
                                       text={telaDeCadastroDeOrdemDeServico.importarBancoTIN}
                                       style={{ width: 200 }}
                                    />
                                 </Col>
                              )}
                        </Row>
                     </Fragment>
                  )
               );
            }}
         />
      );
   };

   resultados = () => {
      let telaDeCadastroDeOrdemDeServico = this.props.lang.telaDeCadastroDeOrdemDeServico;
      return this.state.itemSelecionado.resultados && this.state.itemSelecionado.resultados.length ? (
         <Fragment>
            <SubCadastro
               titulo={telaDeCadastroDeOrdemDeServico.resultadosDeTesteDeOrdemDeServico}
               itens={this.state.itemSelecionado.resultados}
               lang={this.props.lang}
               podeInserir={false}
               novo={() => {}}
               cancelar={() => {}}
               inserir={() => {}}
               selecionarParaAlteracao={(index) => {}}
               podeExcluir={false}
               colunas={() => {
                  return [
                     { titulo: telaDeCadastroDeOrdemDeServico.data, width: '15%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.padrao, width: '10%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.sequencia, width: '45%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.situacao, width: '20%', className: null },
                     { titulo: 'CSV', width: '10%', className: null },
                  ];
               }}
               linha={(item) => {
                  return [
                     dateToString(item.data, this.props.lang),
                     item.tipoDeEquipamento.sigla,
                     item.sequencia ? item.sequencia.nome : '-',
                     <div>
                        {item.situacao.descricao}
                        {item.situacao.id === 4 && (
                           <div>
                              <span>{telaDeCadastroDeOrdemDeServico.motivo}:&nbsp;</span>
                              <span>{item.observacao}</span>
                           </div>
                        )}
                     </div>,
                     <div style={{ width: '100%', paddingTop: 2, textAlign: 'center' }}>
                        <IconButton
                           title={telaDeCadastroDeOrdemDeServico.baixarCSV}
                           style={{
                              fontSize: 16,
                              color: '#666',
                           }}
                           icon={faDownload}
                           onClick={() => this.baixarCSV(item)}
                        />
                     </div>,
                  ];
               }}
               formulario={() => {
                  return <div></div>;
               }}
            />
            <br />
         </Fragment>
      ) : null;
   };

   atualizar = () => {
      this.props.api.get('/ordemdeservico?id=' + this.state.itemSelecionado.id).then((data) => {
         updateState(this, (state) => {
            state.itemSelecionado = data.items[0];
            state.mostrar = false;
         }).then(() => {
            updateState(this, (state) => {
               state.mostrar = true;
            });
         });
      });
   };

   baixarCSV = (item) => {      
      this.props.api
         .get('/ordemdeservico/baixarcsv?testid=' + item.id)
         .then((data) => {
            downloadBase64File(data, item.id, 'text/csv');
         });
   };

   selectSequencia = () => {
      var map = new Map();
      map.set(0, this.selectABI);
      map.set(1, this.selectJauOrJaump);
      map.set(2, this.selectJauOrJaump);
      map.set(3, this.selectSMP);
      map.set(4, this.selectSIM);
      map.set(5, this.selectASE);
      map.set(6, () => {}); // TIN
      map.set(7, this.selectSEG);
      map.set(8, this.selectSOP);
      map.set(9, this.selectAVM);
      map.set(10, this.selectAPE);
      map.set(11, () => {}); // AQT
      map.set(12, this.selectMMD);
      map.set(13, this.selectSPK);
      map.set(14, this.selectMDC);

      let fn = map.get(this.state.itemDaOsSelecionado.tipoDeEquipamento.tipoDeSequencia);
      if (fn) {
         return fn();
      } else {
         return 'null';
      }
   };

   selectABI = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaABIView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciaabi' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectAPE = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaAPEView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciaape' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectASE = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaASEView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciaase' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectAVM = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaAVMView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciaavm' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectJauOrJaump = () => {
      return (
         <div style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <StyledNav>
               <Tabs
                  variant='tabs'
                  activeKey={this.state.abaSelecionada}
                  onSelect={(k) => {
                     console.log(k);
                     updateState(this, (state) => {
                        state.abaSelecionada = k;
                        state.itemDaOsSelecionado.sequencia = null;
                     });
                  }}
                  style={{ color: '#444' }}
               >
                  <Tab
                     eventKey='JAU'
                     title={'Sequência para Analisador de Desfibrilador'}
                     style={{ height: '100%' }}
                  ></Tab>
                  <Tab
                     eventKey='JAUMP'
                     title={'Sequência para Analisador de Marca-passo'}
                     style={{ height: '100%' }}
                  ></Tab>
               </Tabs>

               {this.state.abaSelecionada === 'JAU' && this.selectJAU()}
               {this.state.abaSelecionada === 'JAUMP' && this.selectJAUMP()}
            </StyledNav>
         </div>
      );
   };

   selectJAU = () => {
      return (
         <div style={{ display: 'block', width: '100%' }}>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.selecione}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaJAUView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciajau' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </div>
      );
   };

   selectJAUMP = () => {
      return (
         <div style={{ display: 'block', width: '100%' }}>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.selecione}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaJAUMPView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciajaump' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </div>
      );
   };

   selectSIM = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaSIMView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciasim' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectSOP = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaSOPView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciasop' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectSMP = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaSMPView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciasmp' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectSPK = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaSPKView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciaspk' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   selectMDC = () => {
      return (
         <Fragment>
            <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.sequencia}</Label>
            <Select
               name={'sequencia'}
               defaultValue={this.state.itemDaOsSelecionado.sequencia}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  updateState(this, (state) => {
                     state.itemDaOsSelecionado.sequencia = i;
                  });
               }}
               formularioPadrao={(select) => {
                  return <SequenciaMDCView api={this.props.api} select={select} lang={this.props.lang} />;
               }}
               noDropDown={true}
               readOnlyColor='#ffff'
               getFilterUrl={(text) =>
                  '/sequenciamdc' +
                  buildQueryString(7, null, 'id', {
                     Searchable: text,
                  })
               }
            />
         </Fragment>
      );
   };

   getFiltro = (filtro) => {
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (this.state.cliente) {
         result.cliente = this.state.cliente.id;
      }
      if (this.state.situacao) {
         result.situacao = this.state.situacao.id;
      }
      result.id = this.state.numeroDaOrdemDeServico;
      result.dataInicial = formatDate(this.state.dataInicial);
      result.dataFinal = formatDate(this.state.dataFinal);
      result.numeroDeSerie = this.state.numeroDeSerie;
      result.patrimonio = this.state.patrimonio;
      return result;
   };

   renderizarFiltros = (sender) => {
      let telaDeCadastroDeOrdemDeServico = this.props.lang.telaDeCadastroDeOrdemDeServico;
      return (
         <Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.numero}</Label>
                     <IntegerInput onChange={(e) => this.setState({ numeroDaOrdemDeServico: e.floatValue })} />
                  </FormGroup>
               </Col>

               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.situacao}
                        options={[
                           {
                              id: 1,
                              descricao: telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico.cadastrada,
                           },
                           {
                              id: 2,
                              descricao: telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico.executada,
                           },
                           {
                              id: 3,
                              descricao: telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico.liberada,
                           },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.situacao = i;
                           });
                        }}
                        nullText={''}
                     />
                  </FormGroup>
               </Col>

               <Col style={{ paddingRight: 0 }}>
                  <Label>{telaDeCadastroDeOrdemDeServico.dataDeAgendamento}</Label>
                  <div style={{ display: 'flex' }}>
                     <div style={{ display: 'table-cell', width: 150 }}>
                        <DateInput
                           defaultValue={formatDate(this.state.dataInicial)}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataInicial = value;
                              });
                           }}
                        />
                     </div>
                     <div
                        style={{
                           display: 'table-cell',
                           width: 20,
                           marginTop: 10,
                           marginLeft: 5,
                           marginRight: 5,
                        }}
                     >
                        <span>{telaDeCadastroDeOrdemDeServico.ate}</span>
                     </div>
                     <div style={{ display: 'table-cell', width: 150 }}>
                        <DateInput
                           defaultValue={formatDate(this.state.dataFinal)}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.dataFinal = value;
                              });
                           }}
                        />
                     </div>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.numeroDeSerie}</Label>
                     <TextInput onChange={(e) => this.setState({ numeroDeSerie: e.target.value })} />
                  </FormGroup>
               </Col>

               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeOrdemDeServico.patrimonio}</Label>
                     <TextInput onChange={(e) => this.setState({ patrimonio: e.target.value })} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeOrdemDeServico.cliente}</Label>
                     <Select
                        name={'cliente'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.apelidoOuNomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.cliente = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <ClienteView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/cliente/fast' + buildQueryString(7, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <Button onClick={sender.filtrar} text={this.props.lang.pesquisar} style={{ width: 200 }} />
                  <br />
               </Col>
            </Row>
         </Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <Fragment>

            {this.state.itemSelecionado.id && this.resultados()}

            <Row>
               {(this.state.itemSelecionado.situacao.id === 2 || this.state.itemSelecionado.situacao.id === 3) && (
                  <Button
                     style={{ width: 200, marginLeft: 14 }}
                     text='Relatório'
                     inProgressText='Gerando...'
                     onClickAsync={() =>
                        new Promise((resolve, reject) => {
                           this.props.api
                              .post('/ordemdeservico/baixarrelatorio', { id: this.state.itemSelecionado.id })
                              .then((data) => {
                                 var byteCharacters = atob(data);
                                 var byteNumbers = new Array(byteCharacters.length);
                                 for (var i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                 }
                                 var byteArray = new Uint8Array(byteNumbers);
                                 var file = new Blob([byteArray], { type: 'application/pdf;base64' });

                                 if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                    // For IE
                                    window.navigator.msSaveOrOpenBlob(file, 'mypdf.pdf');
                                 } else {
                                    // For non-IE
                                    var fileURL = URL.createObjectURL(file);
                                    window.open(fileURL);
                                 }
                                 resolve();
                              })
                              .catch((e) => reject(e));
                        })
                     }
                  />
               )}

               {this.state.itemSelecionado.situacao.id === 2 && (
                  <Button
                     style={{ width: 220 }}
                     text='Liberar'
                     inProgressText='Liberando...'
                     onClickAsync={() =>
                        new Promise((resolve, reject) => {
                           if (!this.state.itemSelecionado.cliente) {
                              showError('Informe o cliente antes de liberar a ordem de serviço.');
                              reject();
                              return;
                           }

                           this.props.api
                              .put('/ordemdeservico/liberar', { id: this.state.itemSelecionado.id })
                              .then((data) => {
                                 showInfo('Ordem de serviço liberada com suceso!');
                                 this.atualizar();
                                 resolve();
                              })
                              .catch((e) => reject(e));
                        })
                     }
                  />
               )}

               {this.state.itemSelecionado.situacao.id === 3 &&
                  !this.state.itemSelecionado.testeAqt &&
                  !this.state.itemSelecionado.testeTin && (
                     <Button
                        style={{ width: 220 }}
                        text='Certificado de Calibração'
                        inProgressText='Gerando...'
                        onClickAsync={() =>
                           new Promise((resolve, reject) => {
                              this.props.api
                                 .post('/ordemdeservico/baixarcertificado', { id: this.state.itemSelecionado.id })
                                 .then((data) => {
                                    var byteCharacters = atob(data);
                                    var byteNumbers = new Array(byteCharacters.length);
                                    for (var i = 0; i < byteCharacters.length; i++) {
                                       byteNumbers[i] = byteCharacters.charCodeAt(i);
                                    }
                                    var byteArray = new Uint8Array(byteNumbers);
                                    var file = new Blob([byteArray], { type: 'application/pdf;base64' });

                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                       // For IE
                                       window.navigator.msSaveOrOpenBlob(file, 'mypdf.pdf');
                                    } else {
                                       // For non-IE
                                       var fileURL = URL.createObjectURL(file);
                                       window.open(fileURL);
                                    }
                                    resolve();
                                 })
                                 .catch((e) => reject(e));
                           })
                        }
                     />
                  )}
            </Row>
            <br />
         </Fragment>
      );
   };  
   
   definirValoresPadrao = (item) => {
      item.numeroDeMesesDeValidadeDoCertificado = this.state.numeroDeMesesDeValidadeDoCertificado;
      return item;
   }

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeOrdemDeServico.titulo}
            url={'/ordemdeservico'}
            fastUrl={'/ordemdeservico/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            permissoes={[1311, 1312, 1313, 1314]}
            getFiltro={this.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            definirValoresPadrao={this.definirValoresPadrao}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            renderizarFiltros={this.renderizarFiltros}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1, descricao: 'Cadastrada' },
            }}
         />
      );
   }
}

const StyledNav = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;

   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
      background-color: ${LayoutParams.colors.corDoTemaPrincipal};
   }

   .tab-content {
      overflow-y: hidden;
   }

   .tab-pane.active {
      display: flex;
   }
`;
