import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import DecimalInput from '../../components/DecimalInput';
import { buildQueryString, numberToString, updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import TipoDeEnsaioView from './TipoDeEnsaioView';
import SequenciaSPKController from '../../controllers/Sequencias/SequenciaSPKController';
import {
   getTiposDeTestesSPK,
   getTiposDeTipoDeSinalSicronismoSMP,
   getTiposDeSinalSMP,
   getTiposDeAmplitudeSMP,
   getTiposDeTaxaDeInspSMP,
   getTiposDeAmplitudeRespiracaoSMP,
   getTiposDeUnidadeDesvioSPK,
   getTiposDeArritmiasPerformanceSMP,
   getTiposDeSinalArritimiaSMP,
   getTiposDeSinalSenoideSMP,
   getTiposDeSensibilidadeSMP,
   getTiposDeDinamicaSMP,
   getTiposDeFaixaEtariaSMP,
   getTiposDeFaixaEtariaCanalSMP,
   getUnidadeDeDesvioSMP_ECG_AMPLITUDE,
   getUnidadeDeDesvioSMP_RESPIRACAO_FREQUENCIA,
   getUnidadeDeDesvioSMP_RESPIRACAO_AMPLITUDE,
   getUnidadeDeDesvioSMP_APNEIA,
   getUnidadeDeDesvioSMP_TEMPERATURA,
   getUnidadeDeDesvioSMP_PRESSAO_INVASIVA,
   getUnidadeDeDesvioSMP_PRESSAO_NAO_INVASIVA,
   getUnidadeDeDesvioSMP_PRESSAO_ESTATICA,
   getUnidadeDeDesvioSMP_MANOMETRO,
   getUnidadeDeDesvioSMP_ECG_FREQUENCIA,
   getAmplitudeSMPPerformance,
   getAmplitudeSMPArritmia,
   getTiposDeUnidadeDeLimiteSOPFrequencia,
   getTiposDeUnidadeDeLimiteSOPSaturacao,
   getTiposDeTestesSOP,
   getTiposDeAmplitudeSOP,
   getTiposDeTransmissaoSOP,
} from '../Descricoes';
import { Link } from 'react-router-dom';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';
import { showError } from '../../components/Messages';
import Button from '../../components/Button';
import CheckListView from '../Empresa/CheckListView';

const TIPO_DE_TESTE = {
   ecgFrquencia: 0,
   ecgAmplitude: 1,
   arritmiasPerformance: 2,
   respiracaoFrequencia: 3,
   respiracaoAmplitude: 4,
   respiracaoApneia: 5,
   temperatura: 6,
   pressaoInvasiva: 7,
   pressaoNaoInvasiva: 8,
   vazamento: 9,
   pressaoEstatica: 10,
   manometro: 11,
   spO2Saturacao: 12,
   spO2Frequencia: 13,
};


export default class SequenciaSPKView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new SequenciaSPKController(this);
   }

   setUltimoItemCriado = (item) => {
      updateState(this, (state) => {
         state.ultimoItemCriado = {};
         state.ultimoItemCriado.numero = item.numero;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.limiteSuperior = item.limiteSuperior;
         state.ultimoItemCriado.limiteInferior = item.limiteInferior;
         state.ultimoItemCriado.unidadeLimites = item.unidadeLimites;
         state.ultimoItemCriado.tipoDeSinal = item.tipoDeSinal;
         state.ultimoItemCriado.sinal = item.sinal;
         state.ultimoItemCriado.amplitude = item.amplitude;
         state.ultimoItemCriado.respiracao_Frequencia = item.respiracao_Frequencia;
         state.ultimoItemCriado.respiracao_Taxa = item.respiracao_Taxa;
         state.ultimoItemCriado.respiracao_Amplitude = item.respiracao_Amplitude;
         state.ultimoItemCriado.respiracao_TempoApneia = item.respiracao_TempoApneia;
         state.ultimoItemCriado.temperatura = item.temperatura;
         state.ultimoItemCriado.sensibilidadePI = item.sensibilidadePI;
         state.ultimoItemCriado.tipoDeSimulacaoPI = item.tipoDeSimulacaoPI;
         state.ultimoItemCriado.pressao1PI = item.pressao1PI;
         state.ultimoItemCriado.pressao2PI = item.pressao2PI;
         state.ultimoItemCriado.canalPI = item.canalPI;
         state.ultimoItemCriado.volumePulsoPNI = item.volumePulsoPNI;
         state.ultimoItemCriado.faixaEtariaPNI = item.faixaEtariaPNI;
         state.ultimoItemCriado.pressaoPNI = item.pressaoPNI;
         state.ultimoItemCriado.pressaoSistolicaPNI = item.pressaoSistolicaPNI;
         state.ultimoItemCriado.pressaoDiastolicaPNI = item.pressaoDiastolicaPNI;
         state.ultimoItemCriado.pressaoPNIMedia = item.pressaoPNIMedia;
         state.ultimoItemCriado.vazamento_PressaoInicial = item.vazamento_PressaoInicial;
         state.ultimoItemCriado.vazamento_Tempo = item.vazamento_Tempo;
         state.ultimoItemCriado.vazamento = item.vazamento;
         state.ultimoItemCriado.pressaoPE = item.pressaoPE;
         state.ultimoItemCriado.manometro_Pressao = item.manometro_Pressao;
         state.ultimoItemCriado.spO2_NivelDePerfusao = item.spO2_NivelDePerfusao;
         state.ultimoItemCriado.spO2_CorLarguraDedo = item.spO2_CorLarguraDedo;
         state.ultimoItemCriado.spO2_Sensor = item.spO2_Sensor;
         state.ultimoItemCriado.spO2_Saturacao = item.spO2_Saturacao;
         state.ultimoItemCriado.spO2_Freq = item.spO2_Freq;
      });
   };

   getDescricaoDeTesteSPK = (id) => {
      let result = getTiposDeTestesSPK(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeSinalSPK = (id) => {
      let result = getTiposDeSinalSMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeAmplitudeSPK = (id) => {
      let result = getTiposDeAmplitudeSMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeAmplitudeRespiracaoSPK = (id) => {
      let result = getTiposDeAmplitudeRespiracaoSMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeSinalArritimiaSPK = (id) => {
      let result = getTiposDeSinalArritimiaSMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeSinalSenoideSPK = (id) => {
      let result = getTiposDeSinalSenoideSMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDoTipoDeTesteSOP = (id) => {
      let result = getTiposDeTestesSOP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDaUnidadeDesvioSOPFrequencia = (id) => {
      let result = getTiposDeUnidadeDeLimiteSOPFrequencia(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDaUnidadeDesvioSOPSaturacao = (id) => {
      let result = getTiposDeUnidadeDeLimiteSOPSaturacao(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getUnidadeDesvioSPK = (tipoDeTeste) => {
      let lang = this.props.lang;
      let result = [];
      switch (tipoDeTeste) {
         case TIPO_DE_TESTE.ecgFrquencia:
            result = getUnidadeDeDesvioSMP_ECG_FREQUENCIA(lang);
            break;
         case TIPO_DE_TESTE.ecgAmplitude:
            result = getUnidadeDeDesvioSMP_ECG_AMPLITUDE(lang);
            break;
         case TIPO_DE_TESTE.respiracaoFrequencia:
            result = getUnidadeDeDesvioSMP_RESPIRACAO_FREQUENCIA(lang);
            break;
         case TIPO_DE_TESTE.respiracaoAmplitude:
            result = getUnidadeDeDesvioSMP_RESPIRACAO_AMPLITUDE(lang);
            break;
         case TIPO_DE_TESTE.respiracaoApneia:
            result = getUnidadeDeDesvioSMP_APNEIA(lang);
            break;
         case TIPO_DE_TESTE.temperatura:
            result = getUnidadeDeDesvioSMP_TEMPERATURA(lang);
            break;
         case TIPO_DE_TESTE.pressaoInvasiva:
            result = getUnidadeDeDesvioSMP_PRESSAO_INVASIVA(lang);
            break;
         case TIPO_DE_TESTE.pressaoNaoInvasiva:
            result = getUnidadeDeDesvioSMP_PRESSAO_NAO_INVASIVA(lang);
            break;
         case TIPO_DE_TESTE.pressaoEstatica:
            result = getUnidadeDeDesvioSMP_PRESSAO_ESTATICA(lang);
            break;
         case TIPO_DE_TESTE.manometro:
            result = getUnidadeDeDesvioSMP_MANOMETRO(lang);
            break;
         case TIPO_DE_TESTE.spO2Frequencia:
            result = getTiposDeUnidadeDeLimiteSOPFrequencia(lang);
            break;
         case TIPO_DE_TESTE.spO2Saturacao:
            result = getTiposDeUnidadeDeLimiteSOPSaturacao(lang);
            break;
         default:
            break;
      }
      return result;
   };

   getDescricaoDaUnidadeDesvio = (tipoDeTeste, unidadeDeDesvio) => {
      let opcoes = this.getUnidadeDesvioSPK(tipoDeTeste);
      let opcao = opcoes.filter((i) => i.id === unidadeDeDesvio)[0];
      var result = null;
      if (opcao) {
         result = opcao.descricao;
      }
      return result;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{lang.sequencia.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.sequencia.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.sequencia.ativa },
                           { id: 2, descricao: lang.sequencia.inativa },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={lang.sequencia.tiposDeEnsaio}
               modal={true}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.tiposDeEnsaio}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = {
                           tipoDeEnsaio: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio) {
                        showError(lang.sequencia.mensagens.informeOTipoDeEnsaio);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.tiposDeEnsaio) {
                           state.itemSelecionado.tiposDeEnsaio = [];
                        }
                        state.itemSelecionado.tiposDeEnsaio.push({
                           tipoDeEnsaio: this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.tiposDeEnsaio.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Cargo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.tipoDeEnsaio.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{lang.sequencia.tipoDeEnsaio}</Label>
                           <Select
                              name={'tipoDeEnsaio'}
                              modal={true}
                              defaultValue={this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.tipoDeEnsaioSelecionado.tipoDeEnsaio = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <TipoDeEnsaioView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/tipodeensaio' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />
            <br />

            {!this.state.itemSelecionado.sequenciaNeos && (
               <SubCadastro
                  titulo={lang.sequencia.checkList}
                  modal={true}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.checkList}
                  lang={lang}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = {
                              checkList: null,
                           };
                        }).then(() => resolve());
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve, reject) => {
                        if (!this.state.checkListSelecionado.checkList) {
                           showError(lang.sequencia.mensagens.informeOCheckList);
                           reject();
                           return false;
                        }

                        updateState(this, (state) => {
                           if (!state.itemSelecionado.checkList) {
                              state.itemSelecionado.checkList = [];
                           }
                           state.itemSelecionado.checkList.push({
                              checkList: this.state.checkListSelecionado.checkList,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = null;
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.checkList.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: lang.sequencia.checkList, width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.checkList.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <Label>{lang.sequencia.checkList}</Label>
                              <Select
                                 name={'checkList'}
                                 modal={true}
                                 defaultValue={this.state.checkListSelecionado.checkList}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.checkListSelecionado.checkList = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <CheckListView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/checklist' + buildQueryString(2, null, 'descricao', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     );
                  }}
               />
            )}

            <br />
            <SubCadastro
               titulo={lang.sequencia.testes}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.itens}
               tituloDoBotaoCancelar={lang.sequencia.finalizarCadastroDeTeste}
               gradeSempreVisivel={true}
               lang={lang}
               novo={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? () => {
                          return new Promise((resolve) => {
                             let maiorNumero = 0;
                             if (this.state.itemSelecionado.itens) {
                                this.state.itemSelecionado.itens.map((i, index) => {
                                   if (i.numero > maiorNumero) {
                                      maiorNumero = i.numero;
                                   }
                                   return true;
                                });
                             }
                             maiorNumero++;
                             updateState(this, (state) => {
                              state.configuracaoSelecionada = null;
                              let configuracaoSelecionada = { tipoDeTeste: 0 };
                              this.setValoresDefault(configuracaoSelecionada);
                              configuracaoSelecionada.numero = maiorNumero;
                              state.configuracaoSelecionada = configuracaoSelecionada;
                             }).then(() => resolve());
                          });
                       }
                     : null
               }
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.itens) {
                           state.itemSelecionado.itens = [];
                        }

                        let item = {
                           numero: this.state.configuracaoSelecionada.numero,
                           repeticoes: 1,
                           tipoDeTeste: this.state.configuracaoSelecionada.tipoDeTeste,
                           limiteSuperior: this.state.configuracaoSelecionada.limiteSuperior,
                           limiteInferior: this.state.configuracaoSelecionada.limiteInferior,
                           unidadeLimites: this.state.configuracaoSelecionada.unidadeLimites,
                           tipoDeSinal: this.state.configuracaoSelecionada.tipoDeSinal,
                           sinal: this.state.configuracaoSelecionada.sinal,
                           amplitude: this.state.configuracaoSelecionada.amplitude,
                           respiracao_Frequencia: this.state.configuracaoSelecionada.respiracao_Frequencia,
                           respiracao_Taxa: this.state.configuracaoSelecionada.respiracao_Taxa,
                           respiracao_Amplitude: this.state.configuracaoSelecionada.respiracao_Amplitude,
                           respiracao_TempoApneia: this.state.configuracaoSelecionada.respiracao_TempoApneia,
                           temperatura: this.state.configuracaoSelecionada.temperatura,
                           sensibilidadePI: this.state.configuracaoSelecionada.sensibilidadePI,
                           tipoDeSimulacaoPI: this.state.configuracaoSelecionada.tipoDeSimulacaoPI,
                           pressao1PI: this.state.configuracaoSelecionada.pressao1PI,
                           pressao2PI: this.state.configuracaoSelecionada.pressao2PI,
                           canalPI: this.state.configuracaoSelecionada.canalPI,
                           volumePulsoPNI: this.state.configuracaoSelecionada.volumePulsoPNI,
                           faixaEtariaPNI: this.state.configuracaoSelecionada.faixaEtariaPNI,
                           pressaoPNI: this.state.configuracaoSelecionada.pressaoPNI,
                           pressaoSistolicaPNI: this.state.configuracaoSelecionada.pressaoSistolicaPNI,
                           pressaoDiastolicaPNI: this.state.configuracaoSelecionada.pressaoDiastolicaPNI,
                           pressaoPNIMedia: this.state.configuracaoSelecionada.pressaoPNIMedia,
                           vazamento_PressaoInicial: this.state.configuracaoSelecionada.vazamento_PressaoInicial,
                           vazamento_Tempo: this.state.configuracaoSelecionada.vazamento_Tempo,
                           vazamento: this.state.configuracaoSelecionada.vazamento,
                           pressaoPE: this.state.configuracaoSelecionada.pressaoPE,
                           manometro_Pressao: this.state.configuracaoSelecionada.manometro_Pressao,
                           spO2_NivelDePerfusao: this.state.configuracaoSelecionada.spO2_NivelDePerfusao,
                           spO2_CorLarguraDedo: this.state.configuracaoSelecionada.spO2_CorLarguraDedo,
                           spO2_Sensor: this.state.configuracaoSelecionada.spO2_Sensor,
                           spO2_Saturacao: this.state.configuracaoSelecionada.spO2_Saturacao,
                           spO2_Freq: this.state.configuracaoSelecionada.spO2_Freq,
                        };
                        state.itemSelecionado.itens.push(item);
                        this.setUltimoItemCriado(item);
                     }).then(() => resolve());
                  });
               }}
               aposSalvar={this.aposSalvar}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.itens[index].numero = this.state.configuracaoSelecionada.numero;
                        state.itemSelecionado.itens[index].repeticoes = this.state.configuracaoSelecionada.repeticoes;
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;
                        state.itemSelecionado.itens[index].limiteSuperior =
                           this.state.configuracaoSelecionada.limiteSuperior;
                        state.itemSelecionado.itens[index].limiteInferior =
                           this.state.configuracaoSelecionada.limiteInferior;
                        state.itemSelecionado.itens[index].unidadeLimites =
                           this.state.configuracaoSelecionada.unidadeLimites;
                        state.itemSelecionado.itens[index].tipoDeSinal = this.state.configuracaoSelecionada.tipoDeSinal;
                        state.itemSelecionado.itens[index].sinal = this.state.configuracaoSelecionada.sinal;
                        state.itemSelecionado.itens[index].amplitude = this.state.configuracaoSelecionada.amplitude;
                        state.itemSelecionado.itens[index].respiracao_Frequencia =
                           this.state.configuracaoSelecionada.respiracao_Frequencia;
                        state.itemSelecionado.itens[index].respiracao_Taxa =
                           this.state.configuracaoSelecionada.respiracao_Taxa;
                        state.itemSelecionado.itens[index].respiracao_Amplitude =
                           this.state.configuracaoSelecionada.respiracao_Amplitude;
                        state.itemSelecionado.itens[index].respiracao_TempoApneia =
                           this.state.configuracaoSelecionada.respiracao_TempoApneia;
                        state.itemSelecionado.itens[index].temperatura = this.state.configuracaoSelecionada.temperatura;
                        state.itemSelecionado.itens[index].sensibilidadePI =
                           this.state.configuracaoSelecionada.sensibilidadePI;
                        state.itemSelecionado.itens[index].tipoDeSimulacaoPI =
                           this.state.configuracaoSelecionada.tipoDeSimulacaoPI;
                        state.itemSelecionado.itens[index].pressao1PI = this.state.configuracaoSelecionada.pressao1PI;
                        state.itemSelecionado.itens[index].pressao2PI = this.state.configuracaoSelecionada.pressao2PI;
                        state.itemSelecionado.itens[index].canalPI = this.state.configuracaoSelecionada.canalPI;
                        state.itemSelecionado.itens[index].volumePulsoPNI =
                           this.state.configuracaoSelecionada.volumePulsoPNI;
                        state.itemSelecionado.itens[index].faixaEtariaPNI =
                           this.state.configuracaoSelecionada.faixaEtariaPNI;
                        state.itemSelecionado.itens[index].pressaoPNI = this.state.configuracaoSelecionada.pressaoPNI;
                        state.itemSelecionado.itens[index].pressaoSistolicaPNI =
                           this.state.configuracaoSelecionada.pressaoSistolicaPNI;
                        state.itemSelecionado.itens[index].pressaoDiastolicaPNI =
                           this.state.configuracaoSelecionada.pressaoDiastolicaPNI;
                        state.itemSelecionado.itens[index].pressaoPNIMedia =
                           this.state.configuracaoSelecionada.pressaoPNIMedia;
                        state.itemSelecionado.itens[index].vazamento_PressaoInicial =
                           this.state.configuracaoSelecionada.vazamento_PressaoInicial;
                        state.itemSelecionado.itens[index].vazamento_Tempo =
                           this.state.configuracaoSelecionada.vazamento_Tempo;
                        state.itemSelecionado.itens[index].vazamento = this.state.configuracaoSelecionada.vazamento;
                        state.itemSelecionado.itens[index].pressaoPE = this.state.configuracaoSelecionada.pressaoPE;
                        state.itemSelecionado.itens[index].manometro_Pressao =
                           this.state.configuracaoSelecionada.manometro_Pressao;
                        state.itemSelecionado.itens[index].spO2_NivelDePerfusao =
                           this.state.configuracaoSelecionada.spO2_NivelDePerfusao;
                        state.itemSelecionado.itens[index].spO2_CorLarguraDedo =
                           this.state.configuracaoSelecionada.spO2_CorLarguraDedo;
                        state.itemSelecionado.itens[index].spO2_Sensor = this.state.configuracaoSelecionada.spO2_Sensor;
                        state.itemSelecionado.itens[index].spO2_Saturacao =
                           this.state.configuracaoSelecionada.spO2_Saturacao;
                        state.itemSelecionado.itens[index].spO2_Freq = this.state.configuracaoSelecionada.spO2_Freq;
                        this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? (index) => {
                          updateState(this, (state) => {
                             state.itemSelecionado.itens.splice(index, 1);
                             state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
                          });
                       }
                     : null
               }
               colunas={() => {
                  return [
                     { titulo: 'Nº', width: '3%', className: null },
                     { titulo: 'Tipo de Teste', width: '17%', className: null },
                     { titulo: 'Valor de referência', width: '22%', className: null },
                     { titulo: 'Desvio máximo superior', width: '17%', className: null },
                     { titulo: 'Desvio máximo inferior', width: '16%', className: null },
                     { titulo: 'Unid desvio', width: '25%', className: null },
                  ];
               }}
               linha={(item) => {
                  if (item.tipoDeTeste === TIPO_DE_TESTE.ecgFrquencia) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        this.getDescricaoDeSinalSPK(item.sinal),
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.ecgAmplitude) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        this.getDescricaoDeAmplitudeSPK(item.amplitude),
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.arritmiasPerformance) {
                     if (item.tipoDeSinal === 1) {
                        return [
                           item.numero,
                           this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                           this.getDescricaoModosDeSinalArritimiaSPK(item.sinal),
                           '-',
                           '-',
                           '-',
                        ];
                     } else {
                        return [
                           item.numero,
                           this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                           this.getDescricaoModosDeSinalSenoideSPK(item.sinal),
                           '-',
                           '-',
                           '-',
                        ];
                     }
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoFrequencia) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.respiracao_Frequencia + ' BrPm',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoAmplitude) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.respiracao_Amplitude.toString() + ' Ω',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoApneia) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.respiracao_TempoApneia + ' s',
                        '-',
                        '-',
                        '-',
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.temperatura) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.temperatura + ' °C',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoInvasiva) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.tipoDeSimulacaoPI === 8
                           ? item.pressao1PI + ' mmHg'
                           : 'Artéria (' + item.pressao1PI + '/' + item.pressao2PI + ')',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoNaoInvasiva) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.pressaoSistolicaPNI + '/' + item.pressaoDiastolicaPNI + ' mmHg',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.vazamento) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.vazamento + ' mmHg/min (' + item.vazamento_PressaoInicial + ' mmHg)',
                        '-',
                        '-',
                        '-',
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoEstatica) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.pressaoPE + 'mmHg ',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.manometro) {
                     return [
                        item.numero,
                        this.getDescricaoDeTesteSPK(item.tipoDeTeste),
                        item.manometro_Pressao + ' mmHg',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (
                     item.tipoDeTeste === TIPO_DE_TESTE.spO2Frequencia ||
                     item.tipoDeTeste === TIPO_DE_TESTE.spO2Saturacao
                  ) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteSOP(item.tipoDeTeste - 12),
                        item.tipoDeTeste === TIPO_DE_TESTE.spO2Saturacao
                           ? item.spO2_Saturacao + ' %'
                           : item.spO2_Freq + ' BPM',
                        item.limiteSuperior,
                        item.limiteInferior,
                        item.tipoDeTeste === TIPO_DE_TESTE.spO2Saturacao
                           ? this.getDescricaoDaUnidadeDesvioSOPSaturacao(item.unidadeLimites)
                           : this.getDescricaoDaUnidadeDesvioSOPFrequencia(item.unidadeLimites),
                     ];
                  }
               }}
               acoes={(subCadastro, index) => {
                  return (
                     <div style={{ display: 'table-cell', margin: 'auto', marginRight: 4, width: 25 }}>
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginBottom: -10 }}
                           cursor='pointer'
                           icon={faSortUp}
                           onClick={() => {
                              if (this.state.itemSelecionado.itens[index].numero > 1) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index - 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero - 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginTop: -10 }}
                           cursor='pointer'
                           icon={faSortDown}
                           onClick={() => {
                              if (
                                 this.state.itemSelecionado.itens[index].numero <
                                 this.state.itemSelecionado.itens.length
                              ) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index + 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero + 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                     </div>
                  );
               }}
               formulario={() => {
                  return this.state.configuracaoSelecionada ? (
                     <div>
                        <Row>
                           <Col sm={2} md={2} lg={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.numero}</Label>
                                 <IntegerInput
                                    defaultValue={this.state.configuracaoSelecionada.numero}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.numero = e.floatValue;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {/* <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.repeticoes}</Label>
                                 <IntegerInput
                                    // defaultValue={this.state.configuracaoSelecionada.repeticoes}
                                    defaultValue={1}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.repeticoes = e.floatValue;
                                       });
                                    }}
                                    readOnly={true}
                                 />
                              </FormGroup>
                           </Col> */}
                           <Col>
                              <FormGroup>
                                 <Label>{lang.cadastroDeSequenciaSPK.tipoDeTeste}</Label>
                                 <Select
                                    defaultValue={this.state.configuracaoSelecionada.tipoDeTeste}
                                    options={getTiposDeTestesSPK(lang)}
                                    getKeyValue={(i) => i.id}
                                    allowEmpty={false}
                                    getDescription={(i) => i.descricao}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i != null) {
                                             state.configuracaoSelecionada.tipoDeTeste = i.id;
                                          } else {
                                             state.configuracaoSelecionada.tipoDeTeste = 0;
                                          }
                                          let configuracaoSelecionada = state.configuracaoSelecionada;
                                          this.setValoresDefault(configuracaoSelecionada);
                                          state.configuracaoSelecionada = configuracaoSelecionada;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ecgFrquencia && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.tipoDeSinal}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.tipoDeSinal}
                                       options={getTiposDeTipoDeSinalSicronismoSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.tipoDeSinal = i.id;
                                             } else {
                                                state.configuracaoSelecionada.tipoDeSinal = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.sinal}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.sinal}
                                       options={getTiposDeSinalSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.sinal = i.id;
                                             } else {
                                                state.configuracaoSelecionada.sinal = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.amplitude}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.amplitude}
                                       options={getTiposDeAmplitudeSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.amplitude = i.id;
                                             } else {
                                                state.configuracaoSelecionada.amplitude = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ecgFrquencia && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={this.getUnidadeDesvioSPK(
                                          this.state.configuracaoSelecionada.tipoDeTeste
                                       )}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ecgAmplitude && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.tipoDeSinal}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.tipoDeSinal}
                                       options={getTiposDeTipoDeSinalSicronismoSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.tipoDeSinal = i.id;
                                             } else {
                                                state.configuracaoSelecionada.tipoDeSinal = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.sinal}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.sinal}
                                       options={getTiposDeSinalSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.sinal = i.id;
                                             } else {
                                                state.configuracaoSelecionada.sinal = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.amplitude}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.amplitude}
                                       options={getTiposDeAmplitudeSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.amplitude = i.id;
                                             } else {
                                                state.configuracaoSelecionada.amplitude = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ecgAmplitude && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={this.getUnidadeDesvioSPK(
                                          this.state.configuracaoSelecionada.tipoDeTeste
                                       )}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.arritmiasPerformance && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.tipoDeSinal}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.tipoDeSinal}
                                       options={getTiposDeArritmiasPerformanceSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.tipoDeSinal = i.id;
                                             } else {
                                                state.configuracaoSelecionada.tipoDeSinal = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              {this.state.configuracaoSelecionada.tipoDeSinal === 1 && (
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSPK.sinal}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.sinal}
                                          options={getTiposDeSinalArritimiaSMP(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.sinal = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.sinal = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              )}

                              {this.state.configuracaoSelecionada.tipoDeSinal === 2 && (
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSPK.sinal}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.sinal}
                                          options={getTiposDeSinalSenoideSMP(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.sinal = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.sinal = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              )}

                              {this.state.configuracaoSelecionada.tipoDeSinal === 1 && (
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSPK.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.amplitude}
                                          options={getAmplitudeSMPArritmia(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              )}

                              {this.state.configuracaoSelecionada.tipoDeSinal === 2 && (
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSPK.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.amplitude}
                                          options={getAmplitudeSMPPerformance(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              )}
                           </Row>
                        )}
                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoFrequencia && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.frequenciaBRPM}</Label>

                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Frequencia}
                                       options={[
                                          { id: 10, descricao: '10' },
                                          { id: 15, descricao: '15' },
                                          { id: 20, descricao: '20' },
                                          { id: 25, descricao: '25' },
                                          { id: 30, descricao: '30' },
                                          { id: 35, descricao: '35' },
                                          { id: 40, descricao: '40' },
                                          { id: 45, descricao: '45' },
                                          { id: 50, descricao: '50' },
                                          { id: 55, descricao: '55' },
                                          { id: 60, descricao: '60' },
                                          { id: 65, descricao: '65' },
                                          { id: 70, descricao: '70' },
                                          { id: 75, descricao: '75' },
                                          { id: 80, descricao: '80' },
                                          { id: 85, descricao: '85' },
                                          { id: 90, descricao: '90' },
                                          { id: 95, descricao: '95' },
                                          { id: 100, descricao: '100' },
                                          { id: 105, descricao: '105' },
                                          { id: 110, descricao: '110' },
                                          { id: 115, descricao: '115' },
                                          { id: 120, descricao: '120' },
                                          { id: 125, descricao: '125' },
                                          { id: 130, descricao: '130' },
                                          { id: 135, descricao: '135' },
                                          { id: 140, descricao: '140' },
                                          { id: 145, descricao: '145' },
                                          { id: 150, descricao: '150' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Frequencia = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Frequencia = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.taxaInsp}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Taxa}
                                       options={getTiposDeTaxaDeInspSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Taxa = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Taxa = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.amplitude}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Amplitude}
                                       options={getTiposDeAmplitudeRespiracaoSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Amplitude = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Amplitude = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoFrequencia && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={this.getUnidadeDesvioSPK(
                                          this.state.configuracaoSelecionada.tipoDeTeste
                                       )}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoAmplitude && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.frequenciaBRPM}</Label>

                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Amplitude}
                                       options={[
                                          { id: 10, descricao: '10' },
                                          { id: 15, descricao: '15' },
                                          { id: 20, descricao: '20' },
                                          { id: 25, descricao: '25' },
                                          { id: 30, descricao: '30' },
                                          { id: 35, descricao: '35' },
                                          { id: 40, descricao: '40' },
                                          { id: 45, descricao: '45' },
                                          { id: 40, descricao: '40' },
                                          { id: 55, descricao: '55' },
                                          { id: 60, descricao: '60' },
                                          { id: 65, descricao: '65' },
                                          { id: 70, descricao: '70' },
                                          { id: 75, descricao: '75' },
                                          { id: 80, descricao: '80' },
                                          { id: 85, descricao: '85' },
                                          { id: 90, descricao: '90' },
                                          { id: 95, descricao: '95' },
                                          { id: 100, descricao: '100' },
                                          { id: 105, descricao: '105' },
                                          { id: 110, descricao: '110' },
                                          { id: 115, descricao: '115' },
                                          { id: 120, descricao: '120' },
                                          { id: 125, descricao: '125' },
                                          { id: 130, descricao: '130' },
                                          { id: 135, descricao: '135' },
                                          { id: 140, descricao: '140' },
                                          { id: 145, descricao: '145' },
                                          { id: 150, descricao: '150' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Amplitude = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Amplitude = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.taxaInsp}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Taxa}
                                       options={getTiposDeTaxaDeInspSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Taxa = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Taxa = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.amplitude}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Amplitude}
                                       options={getTiposDeAmplitudeRespiracaoSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Amplitude = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Amplitude = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoAmplitude && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={this.getUnidadeDesvioSPK(
                                          this.state.configuracaoSelecionada.tipoDeTeste
                                       )}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoApneia && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.tempodeApneia}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_TempoApneia}
                                       options={[
                                          { id: 1, descricao: '1' },
                                          { id: 2, descricao: '2' },
                                          { id: 3, descricao: '3' },
                                          { id: 4, descricao: '4' },
                                          { id: 5, descricao: '5' },
                                          { id: 6, descricao: '6' },
                                          { id: 7, descricao: '7' },
                                          { id: 8, descricao: '8' },
                                          { id: 9, descricao: '9' },
                                          { id: 10, descricao: '10' },
                                          { id: 11, descricao: '11' },
                                          { id: 12, descricao: '12' },
                                          { id: 13, descricao: '13' },
                                          { id: 14, descricao: '14' },
                                          { id: 15, descricao: '15' },
                                          { id: 16, descricao: '16' },
                                          { id: 17, descricao: '17' },
                                          { id: 18, descricao: '18' },
                                          { id: 19, descricao: '19' },
                                          { id: 20, descricao: '20' },
                                          { id: 21, descricao: '21' },
                                          { id: 22, descricao: '22' },
                                          { id: 23, descricao: '23' },
                                          { id: 24, descricao: '24' },
                                          { id: 25, descricao: '25' },
                                          { id: 26, descricao: '26' },
                                          { id: 27, descricao: '27' },
                                          { id: 28, descricao: '28' },
                                          { id: 29, descricao: '29' },
                                          { id: 30, descricao: '30' },
                                          { id: 31, descricao: '31' },
                                          { id: 32, descricao: '32' },
                                          { id: 33, descricao: '33' },
                                          { id: 34, descricao: '34' },
                                          { id: 35, descricao: '35' },
                                          { id: 36, descricao: '36' },
                                          { id: 37, descricao: '37' },
                                          { id: 38, descricao: '38' },
                                          { id: 39, descricao: '39' },
                                          { id: 40, descricao: '40' },
                                          { id: 41, descricao: '41' },
                                          { id: 42, descricao: '42' },
                                          { id: 43, descricao: '43' },
                                          { id: 44, descricao: '44' },
                                          { id: 45, descricao: '45' },
                                          { id: 46, descricao: '46' },
                                          { id: 47, descricao: '47' },
                                          { id: 48, descricao: '48' },
                                          { id: 49, descricao: '49' },
                                          { id: 50, descricao: '50' },
                                          { id: 51, descricao: '51' },
                                          { id: 52, descricao: '52' },
                                          { id: 53, descricao: '53' },
                                          { id: 54, descricao: '54' },
                                          { id: 55, descricao: '55' },
                                          { id: 56, descricao: '56' },
                                          { id: 57, descricao: '57' },
                                          { id: 58, descricao: '58' },
                                          { id: 59, descricao: '59' },
                                          { id: 60, descricao: '60' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_TempoApneia = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_TempoApneia = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.frequenciaBRPM}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Frequencia}
                                       options={[
                                          { id: 10, descricao: '10' },
                                          { id: 15, descricao: '15' },
                                          { id: 20, descricao: '20' },
                                          { id: 25, descricao: '25' },
                                          { id: 30, descricao: '30' },
                                          { id: 35, descricao: '35' },
                                          { id: 40, descricao: '40' },
                                          { id: 45, descricao: '45' },
                                          { id: 40, descricao: '40' },
                                          { id: 55, descricao: '55' },
                                          { id: 60, descricao: '60' },
                                          { id: 65, descricao: '65' },
                                          { id: 70, descricao: '70' },
                                          { id: 75, descricao: '75' },
                                          { id: 80, descricao: '80' },
                                          { id: 85, descricao: '85' },
                                          { id: 90, descricao: '90' },
                                          { id: 95, descricao: '95' },
                                          { id: 100, descricao: '100' },
                                          { id: 105, descricao: '105' },
                                          { id: 110, descricao: '110' },
                                          { id: 115, descricao: '115' },
                                          { id: 120, descricao: '120' },
                                          { id: 125, descricao: '125' },
                                          { id: 130, descricao: '130' },
                                          { id: 135, descricao: '135' },
                                          { id: 140, descricao: '140' },
                                          { id: 145, descricao: '145' },
                                          { id: 150, descricao: '150' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Frequencia = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Frequencia = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoApneia && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.taxaInsp}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Taxa}
                                       options={getTiposDeTaxaDeInspSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Taxa = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Taxa = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.amplitude}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.respiracao_Amplitude}
                                       options={getTiposDeAmplitudeRespiracaoSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.respiracao_Amplitude = i.id;
                                             } else {
                                                state.configuracaoSelecionada.respiracao_Amplitude = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.temperatura && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.temperatura}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.temperatura}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.temperatura = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={this.getUnidadeDesvioSPK(
                                          this.state.configuracaoSelecionada.tipoDeTeste
                                       )}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.temperatura && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.pressaoInvasiva && (
                           <Row>
                              <Col>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.tipoDeSimulacao}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.tipoDeSimulacaoPI}
                                       options={getTiposDeDinamicaSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.tipoDeSimulacaoPI = i.id;
                                             } else {
                                                state.configuracaoSelecionada.tipoDeSimulacaoPI = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.sensibilidade}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.sensibilidadePI}
                                       options={getTiposDeSensibilidadeSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.sensibilidadePI = i.id;
                                             } else {
                                                state.configuracaoSelecionada.sensibilidadePI = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              {this.state.configuracaoSelecionada.tipoDeSimulacaoPI !== 8 && (
                                 <Fragment>
                                    <Col xs={3} md={3} xl={3}>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaSPK.sistolicammhg}</Label>
                                          <IntegerInput
                                             defaultValue={this.state.configuracaoSelecionada.pressao1PI}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.pressao1PI = e.value;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                    <Col xs={3} md={3} xl={3}>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaSPK.diastolicammhg}</Label>
                                          <IntegerInput
                                             defaultValue={this.state.configuracaoSelecionada.pressao2PI}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.pressao2PI = e.value;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Fragment>
                              )}

                              {this.state.configuracaoSelecionada.tipoDeSimulacaoPI === 8 && (
                                 <Col xs={3} md={3} xl={3}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSPK.pressao + '(mmHg)'} </Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.pressao1PI}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.pressao1PI = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              )}
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.pressaoInvasiva && (
                           <Row>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.canal}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.canalPI}
                                       options={getTiposDeFaixaEtariaCanalSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.canalPI = i.id;
                                             } else {
                                                state.configuracaoSelecionada.canalPI = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.frequenciaBPM}</Label>

                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.sinal}
                                       options={[
                                          { id: 200, descricao: '20' },
                                          { id: 201, descricao: '30' },
                                          { id: 202, descricao: '40' },
                                          { id: 203, descricao: '50' },
                                          { id: 204, descricao: '60' },
                                          { id: 205, descricao: '70' },
                                          { id: 206, descricao: '80' },
                                          { id: 207, descricao: '90' },
                                          { id: 208, descricao: '100' },
                                          { id: 209, descricao: '110' },
                                          { id: 210, descricao: '120' },
                                          { id: 211, descricao: '130' },
                                          { id: 212, descricao: '140' },
                                          { id: 213, descricao: '150' },
                                          { id: 214, descricao: '160' },
                                          { id: 215, descricao: '170' },
                                          { id: 216, descricao: '180' },
                                          { id: 217, descricao: '190' },
                                          { id: 218, descricao: '200' },
                                          { id: 219, descricao: '210' },
                                          { id: 220, descricao: '220' },
                                          { id: 221, descricao: '230' },
                                          { id: 222, descricao: '240' },
                                          { id: 223, descricao: '250' },
                                          { id: 224, descricao: '260' },
                                          { id: 225, descricao: '270' },
                                          { id: 226, descricao: '280' },
                                          { id: 227, descricao: '290' },
                                          { id: 228, descricao: '300' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.sinal = i.id;
                                             } else {
                                                state.configuracaoSelecionada.sinal = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoM}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoI}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={1}
                                       options={getTiposDeUnidadeDesvioSPK(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.pressaoNaoInvasiva && (
                           <Row>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.frequenciaBPM}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.sinal}
                                       options={[
                                          { id: 200, descricao: '20' },
                                          { id: 201, descricao: '30' },
                                          { id: 202, descricao: '40' },
                                          { id: 203, descricao: '50' },
                                          { id: 204, descricao: '60' },
                                          { id: 205, descricao: '70' },
                                          { id: 206, descricao: '80' },
                                          { id: 207, descricao: '90' },
                                          { id: 208, descricao: '100' },
                                          { id: 209, descricao: '110' },
                                          { id: 210, descricao: '120' },
                                          { id: 211, descricao: '130' },
                                          { id: 212, descricao: '140' },
                                          { id: 213, descricao: '150' },
                                          { id: 214, descricao: '160' },
                                          { id: 215, descricao: '170' },
                                          { id: 216, descricao: '180' },
                                          { id: 217, descricao: '190' },
                                          { id: 218, descricao: '200' },
                                          { id: 219, descricao: '210' },
                                          { id: 220, descricao: '220' },
                                          { id: 221, descricao: '230' },
                                          { id: 222, descricao: '240' },
                                          { id: 223, descricao: '250' },
                                          { id: 224, descricao: '260' },
                                          { id: 225, descricao: '270' },
                                          { id: 226, descricao: '280' },
                                          { id: 227, descricao: '290' },
                                          { id: 228, descricao: '300' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.sinal = i.id;
                                             } else {
                                                state.configuracaoSelecionada.sinal = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.volumePulso}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.volumePulsoPNI / 100}
                                       options={[
                                          { id: 0.5, descricao: numberToString(0.5, 0, 2, lang) },
                                          { id: 0.75, descricao: numberToString(0.75, 0, 2, lang) },
                                          { id: 1, descricao: numberToString(1, 0, 2, lang) },
                                          { id: 1.25, descricao: numberToString(1.25, 0, 2, lang) },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.volumePulsoPNI = i.id * 100;
                                             } else {
                                                state.configuracaoSelecionada.volumePulsoPNI = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.sistolicammhg}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.pressaoSistolicaPNI}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.pressaoSistolicaPNI = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.diastolicammhg}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.pressaoDiastolicaPNI}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.pressaoDiastolicaPNI = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.pressaoNaoInvasiva && (
                           <Row>
                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.faixaetaria}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.faixaEtariaPNI}
                                       options={getTiposDeFaixaEtariaSMP(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.faixaEtariaPNI = i.id;
                                             } else {
                                                state.configuracaoSelecionada.faixaEtariaPNI = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoM}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoI}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col xs={3} md={3} xl={3}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={1}
                                       options={getTiposDeUnidadeDesvioSPK(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.vazamento && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.vazamentoPressaoInicial}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.vazamento_PressaoInicial}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.vazamento_PressaoInicial = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.tempoDeEspera}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.vazamento_Tempo}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.vazamento_Tempo = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.vazamentoMaximo}</Label>
                                    <DecimalInput
                                       lang={lang}
                                       defaultValue={this.state.configuracaoSelecionada.vazamento}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.vazamento = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.pressaoEstatica && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.pressaoDeEstatica}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.pressaoPE}
                                       options={[
                                          { id: 20, descricao: '20' },
                                          { id: 30, descricao: '30' },
                                          { id: 40, descricao: '40' },
                                          { id: 50, descricao: '50' },
                                          { id: 60, descricao: '60' },
                                          { id: 70, descricao: '70' },
                                          { id: 80, descricao: '80' },
                                          { id: 90, descricao: '90' },
                                          { id: 100, descricao: '100' },
                                          { id: 110, descricao: '110' },
                                          { id: 120, descricao: '120' },
                                          { id: 130, descricao: '130' },
                                          { id: 140, descricao: '140' },
                                          { id: 150, descricao: '150' },
                                          { id: 160, descricao: '160' },
                                          { id: 170, descricao: '170' },
                                          { id: 180, descricao: '180' },
                                          { id: 190, descricao: '190' },
                                          { id: 200, descricao: '200' },
                                          { id: 210, descricao: '210' },
                                          { id: 220, descricao: '220' },
                                          { id: 230, descricao: '230' },
                                          { id: 240, descricao: '240' },
                                          { id: 250, descricao: '250' },
                                          { id: 260, descricao: '260' },
                                          { id: 270, descricao: '270' },
                                          { id: 280, descricao: '280' },
                                          { id: 290, descricao: '290' },
                                          { id: 300, descricao: '300' },
                                          { id: 310, descricao: '310' },
                                          { id: 320, descricao: '320' },
                                          { id: 330, descricao: '330' },
                                          { id: 340, descricao: '340' },
                                          { id: 350, descricao: '350' },
                                          { id: 360, descricao: '360' },
                                          { id: 370, descricao: '370' },
                                          { id: 380, descricao: '380' },
                                          { id: 390, descricao: '390' },
                                          { id: 400, descricao: '400' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.pressaoPE = i.id;
                                             } else {
                                                state.configuracaoSelecionada.pressaoPE = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={this.getUnidadeDesvioSPK(
                                          this.state.configuracaoSelecionada.tipoDeTeste
                                       )}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.pressaoEstatica && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.manometro && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.pressaoDeEntrada}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.manometro_Pressao}
                                       options={[
                                          { id: 20, descricao: '20' },
                                          { id: 30, descricao: '30' },
                                          { id: 40, descricao: '40' },
                                          { id: 50, descricao: '50' },
                                          { id: 60, descricao: '60' },
                                          { id: 70, descricao: '70' },
                                          { id: 80, descricao: '80' },
                                          { id: 90, descricao: '90' },
                                          { id: 100, descricao: '100' },
                                          { id: 110, descricao: '110' },
                                          { id: 120, descricao: '120' },
                                          { id: 130, descricao: '130' },
                                          { id: 140, descricao: '140' },
                                          { id: 150, descricao: '150' },
                                          { id: 160, descricao: '160' },
                                          { id: 170, descricao: '170' },
                                          { id: 180, descricao: '180' },
                                          { id: 190, descricao: '190' },
                                          { id: 200, descricao: '200' },
                                          { id: 210, descricao: '210' },
                                          { id: 220, descricao: '220' },
                                          { id: 230, descricao: '230' },
                                          { id: 240, descricao: '240' },
                                          { id: 250, descricao: '250' },
                                          { id: 260, descricao: '260' },
                                          { id: 270, descricao: '270' },
                                          { id: 280, descricao: '280' },
                                          { id: 290, descricao: '290' },
                                          { id: 300, descricao: '300' },
                                          { id: 310, descricao: '310' },
                                          { id: 320, descricao: '320' },
                                          { id: 330, descricao: '330' },
                                          { id: 340, descricao: '340' },
                                          { id: 350, descricao: '350' },
                                          { id: 360, descricao: '360' },
                                          { id: 370, descricao: '370' },
                                          { id: 380, descricao: '380' },
                                          { id: 390, descricao: '390' },
                                          { id: 400, descricao: '400' },
                                       ]}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.manometro_Pressao = i.id;
                                             } else {
                                                state.configuracaoSelecionada.manometro_Pressao = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.unidadeDeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={this.getUnidadeDesvioSPK(
                                          this.state.configuracaoSelecionada.tipoDeTeste
                                       )}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.manometro && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaSPK.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {(this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.spO2Saturacao ||
                           this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.spO2Frequencia) && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSOP.frequenciaBPM}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.spO2_Freq}
                                          options={[
                                             { id: 20, descricao: '20' },
                                             { id: 30, descricao: '30' },
                                             { id: 40, descricao: '40' },
                                             { id: 50, descricao: '50' },
                                             { id: 60, descricao: '60' },
                                             { id: 70, descricao: '70' },
                                             { id: 80, descricao: '80' },
                                             { id: 90, descricao: '90' },
                                             { id: 100, descricao: '100' },
                                             { id: 110, descricao: '110' },
                                             { id: 120, descricao: '120' },
                                             { id: 130, descricao: '130' },
                                             { id: 140, descricao: '140' },
                                             { id: 150, descricao: '150' },
                                             { id: 160, descricao: '160' },
                                             { id: 170, descricao: '170' },
                                             { id: 180, descricao: '180' },
                                             { id: 190, descricao: '190' },
                                             { id: 200, descricao: '200' },
                                             { id: 210, descricao: '210' },
                                             { id: 220, descricao: '220' },
                                             { id: 230, descricao: '230' },
                                             { id: 240, descricao: '240' },
                                             { id: 250, descricao: '250' },
                                             { id: 260, descricao: '260' },
                                             { id: 270, descricao: '270' },
                                             { id: 280, descricao: '280' },
                                             { id: 290, descricao: '290' },
                                             { id: 300, descricao: '300' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.spO2_Freq = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.spO2_Freq = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSOP.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.spO2_NivelDePerfusao}
                                          options={getTiposDeAmplitudeSOP(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.spO2_NivelDePerfusao = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.spO2_NivelDePerfusao = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSOP.transmissao}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.spO2_CorLarguraDedo}
                                          options={getTiposDeTransmissaoSOP(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.spO2_CorLarguraDedo = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.spO2_CorLarguraDedo = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSOP.saturacao}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.spO2_Saturacao}
                                          options={[
                                             { id: 50, descricao: '50%' },
                                             { id: 51, descricao: '51%' },
                                             { id: 52, descricao: '52%' },
                                             { id: 53, descricao: '53%' },
                                             { id: 54, descricao: '54%' },
                                             { id: 55, descricao: '55%' },
                                             { id: 56, descricao: '56%' },
                                             { id: 57, descricao: '57%' },
                                             { id: 58, descricao: '58%' },
                                             { id: 59, descricao: '59%' },
                                             { id: 60, descricao: '60%' },
                                             { id: 61, descricao: '61%' },
                                             { id: 62, descricao: '62%' },
                                             { id: 63, descricao: '63%' },
                                             { id: 64, descricao: '64%' },
                                             { id: 65, descricao: '65%' },
                                             { id: 66, descricao: '66%' },
                                             { id: 67, descricao: '67%' },
                                             { id: 68, descricao: '68%' },
                                             { id: 69, descricao: '69%' },
                                             { id: 70, descricao: '70%' },
                                             { id: 71, descricao: '71%' },
                                             { id: 72, descricao: '72%' },
                                             { id: 73, descricao: '73%' },
                                             { id: 74, descricao: '74%' },
                                             { id: 75, descricao: '75%' },
                                             { id: 76, descricao: '76%' },
                                             { id: 77, descricao: '77%' },
                                             { id: 78, descricao: '78%' },
                                             { id: 79, descricao: '79%' },
                                             { id: 80, descricao: '80%' },
                                             { id: 81, descricao: '81%' },
                                             { id: 82, descricao: '82%' },
                                             { id: 83, descricao: '83%' },
                                             { id: 84, descricao: '84%' },
                                             { id: 85, descricao: '85%' },
                                             { id: 86, descricao: '86%' },
                                             { id: 87, descricao: '87%' },
                                             { id: 88, descricao: '88%' },
                                             { id: 89, descricao: '89%' },
                                             { id: 90, descricao: '90%' },
                                             { id: 91, descricao: '91%' },
                                             { id: 92, descricao: '92%' },
                                             { id: 93, descricao: '93%' },
                                             { id: 94, descricao: '94%' },
                                             { id: 95, descricao: '95%' },
                                             { id: 96, descricao: '96%' },
                                             { id: 97, descricao: '97%' },
                                             { id: 98, descricao: '98%' },
                                             { id: 99, descricao: '99%' },
                                             { id: 100, descricao: '100%' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.spO2_Saturacao = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.spO2_Saturacao = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col xs={3} md={3} xl={3}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSOP.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={3} md={3} xl={3}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSOP.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.spO2Saturacao && (
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaSOP.unidadeDeLimite}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                             options={getTiposDeUnidadeDeLimiteSOPSaturacao(lang)}
                                             getKeyValue={(i) => i.id}
                                             allowEmpty={false}
                                             getDescription={(i) => i.descricao}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.unidadeLimites = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.unidadeLimites = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                                 {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.spO2Frequencia && (
                                    <Col>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaSOP.unidadeDeLimite}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                             options={getTiposDeUnidadeDeLimiteSOPFrequencia(lang)}
                                             getKeyValue={(i) => i.id}
                                             allowEmpty={false}
                                             getDescription={(i) => i.descricao}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.unidadeLimites = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.unidadeLimites = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                              </Row>
                           </Fragment>
                        )}
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            <Button
               text={this.props.lang.sequencia.duplicarSequencia}
               style={{ width: 200 }}
               onClick={() => {
                  let item = JSON.parse(JSON.stringify(this.state.itemSelecionado));
                  item.id = null;
                  if (item.itens != null) {
                     for (let i = 0; i < item.itens.length; i++) {
                        item.itens[i].id = null;
                     }
                  }
                  if (item.tiposDeEnsaio != null) {
                     for (let i = 0; i < item.tiposDeEnsaio.length; i++) {
                        item.tiposDeEnsaio[i].id = null;
                     }
                  }

                  updateState(this, (state) => {
                     state.incluindo = false;
                     state.alterando = false;
                     state.valorPadrao = item;
                  }).then(() => {
                     this.form.inserirClick();
                  });
               }}
            />
            <br />
         </div>
      );
   };

   aposSalvar = (subCadastro, inserindo, alterando) => {
      let ordem = this.state.ultimoItemCriado.numero;
      updateState(this, (state) => {
         state.itemSelecionado.itens.sort((i, j) => {
            let result = i.numero < j.numero ? -1 : i.numero > j.numero ? 1 : 0;
            if (j.numero === ordem && i.numero === ordem) {
               result = -1;
            }
            return result;
         });
         state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
      });
      if (inserindo) {
         subCadastro.novo();
      }
   };

   setValoresDefault = (item) => {
      let padrao = null;

      if (this.state.ultimoItemCriado && !this.state.configuracaoSelecionada) {
         padrao = this.state.ultimoItemCriado;
      } else if (item !== null) {
         if (item.tipoDeTeste === TIPO_DE_TESTE.ecgFrquencia) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteSuperior: 3,
               limiteInferior: 3,
               tipoDeSinal: 0,
               sinal: 204,
               amplitude: 1,
               unidadeLimites: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.ecgAmplitude) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteSuperior: 10,
               limiteInferior: 10,
               tipoDeSinal: 0,
               sinal: 204,
               amplitude: 1,
               unidadeLimites: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.arritmiasPerformance) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               tipoDeSinal: 1,
               sinal: 31,
               amplitude: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoFrequencia) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               respiracao_Frequencia: 20,
               respiracao_Taxa: 1,
               amplitude: 1,
               limiteSuperior: 10,
               limiteInferior: 10,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoAmplitude) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               respiracao_Frequencia: 20,
               respiracao_Taxa: 1,
               respiracao_Amplitude: 1,
               limiteSuperior: 10,
               limiteInferior: 10,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoApneia) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               respiracao_TempoApneia: 12,
               respiracao_Taxa: 1,
               amplitude: 1,
               respiracao_Frequencia: 20,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.temperatura) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               temperatura: 37,
               limiteSuperior: 5,
               limiteInferior: 5,
               unidadeLimites: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoInvasiva) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               pressao1PI: 0,
               pressao2PI: 0,
               limiteSuperior: 4,
               limiteInferior: 4,
               unidadeLimites: 1,
               canalPI: 1,
               sensibilidadePI: 0,
               tipoDeSimulacaoPI: 1,
               sinal: 60,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoNaoInvasiva) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               pressaoSistolicaPNI: 0,
               pressaoDiastolicaPNI: 0,
               limiteSuperior: 4,
               limiteInferior: 4,
               unidadeLimites: 1,
               sinal: 60,
               volumePulsoPNI: 1,
               faixaEtariaPNI: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.vazamento) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               vazamento_PressaoInicial: 280,
               vazamento_Tempo: 300,
               vazamento: 20,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.pressaoEstatica) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteSuperior: 4,
               limiteInferior: 4,
               pressaoPE: 100,
               unidadeLimites: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.manometro) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteSuperior: 4,
               limiteInferior: 4,
               manometro_Pressao: 100,
               unidadeLimites: 1,
            };
         } else if (
            item.tipoDeTeste === TIPO_DE_TESTE.spO2Frequencia ||
            item.tipoDeTeste === TIPO_DE_TESTE.spO2Saturacao
         ) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteSuperior: 10,
               limiteInferior: 10,
               unidadeLimites: 0,
               spO2_Freq: 60,
               spO2_NivelDePerfusao: 2,
               spO2_CorLarguraDedo: 0,
               spO2_Saturacao: 98,
            };
         }
      } else {
         padrao = {
            tipoDeTeste: 0,
         };
      }

      if (padrao) {
         item.repeticoes = 1;
         item.tipoDeTeste = padrao.tipoDeTeste;
         item.limiteSuperior = padrao.limiteSuperior;
         item.limiteInferior = padrao.limiteInferior;
         item.unidadeLimites = padrao.unidadeLimites;
         item.tipoDeSinal = padrao.tipoDeSinal;
         item.sinal = padrao.sinal;
         item.amplitude = padrao.amplitude;
         item.respiracao_Frequencia = padrao.respiracao_Frequencia;
         item.respiracao_Taxa = padrao.respiracao_Taxa;
         item.respiracao_Amplitude = padrao.respiracao_Amplitude;
         item.respiracao_TempoApneia = padrao.respiracao_TempoApneia;
         item.temperatura = padrao.temperatura;
         item.sensibilidadePI = padrao.sensibilidadePI;
         item.tipoDeSimulacaoPI = padrao.tipoDeSimulacaoPI;
         item.pressao1PI = padrao.pressao1PI;
         item.pressao2PI = padrao.pressao2PI;
         item.canalPI = padrao.canalPI;
         item.volumePulsoPNI = padrao.volumePulsoPNI;
         item.faixaEtariaPNI = padrao.faixaEtariaPNI;
         item.pressaoPNI = padrao.pressaoPNI;
         item.pressaoSistolicaPNI = padrao.pressaoSistolicaPNI;
         item.pressaoDiastolicaPNI = padrao.pressaoDiastolicaPNI;
         item.pressaoPNIMedia = padrao.pressaoPNIMedia;
         item.vazamento_PressaoInicial = padrao.vazamento_PressaoInicial;
         item.vazamento_Tempo = padrao.vazamento_Tempo;
         item.vazamento = padrao.vazamento;
         item.pressaoPE = padrao.pressaoPE;
         item.manometro_Pressao = padrao.manometro_Pressao;
         item.spO2_NivelDePerfusao = padrao.spO2_NivelDePerfusao;
         item.spO2_CorLarguraDedo = padrao.spO2_CorLarguraDedo;
         item.spO2_Sensor = padrao.spO2_Sensor;
         item.spO2_Saturacao = padrao.spO2_Saturacao;
         item.spO2_Freq = padrao.spO2_Freq;
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={
               <div>
                  <Link
                     to={'/sequencia'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',
                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.sequencias}</span>                     
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.cadastroDeSequenciaSPK.titulo}</span>
               </div>
            }
            url={'/sequenciaspk'}
            fastUrl={'/sequenciaspk/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            permissoes={[1441, 1442, 1443, 1441]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            definirValoresPadrao={(item) => {
               return this.state.valorPadrao ? this.state.valorPadrao : item;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               acessos: [],
            }}
         />
      );
   }
}
