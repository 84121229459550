import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import DecimalInput from '../../components/DecimalInput';
import { buildQueryString, updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import { showError } from '../../components/Messages';
import SequenciaAPEController from '../../controllers/Sequencias/SequenciaAPEController';
import { getTiposDeTestesAPE, getTiposDeUnidadeDesvioAPE } from './../Descricoes';
import { Link } from 'react-router-dom';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';
import TipoDeEnsaioView from './TipoDeEnsaioView';
import Button from '../../components/Button';
import CheckListView from '../Empresa/CheckListView';

export default class SequenciaAPEView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new SequenciaAPEController(this);
   }

   setUltimoItemCriado = (item) => {
      updateState(this, (state) => {
         state.ultimoItemCriado = {};
         state.ultimoItemCriado.numero = item.numero;
         state.ultimoItemCriado.unidadeLimites = item.unidadeLimites;
         state.ultimoItemCriado.manometro_Pressao = item.manometro_Pressao;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.limiteSuperior = item.limiteSuperior;
         state.ultimoItemCriado.limiteInferior = item.limiteInferior;
         state.ultimoItemCriado.vazamentoTempo = item.vazamentoTempo;
         state.ultimoItemCriado.vazamento_PressaoInicial = item.vazamento_PressaoInicial;
         state.ultimoItemCriado.vazamento = item.vazamento;
         state.ultimoItemCriado.manometro_Pressao = item.manometro_Pressao;
      });
   };

   getDescricaoDoTipoDeTeste = (id) => {
      let result = getTiposDeTestesAPE(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDaUnidadeDesvio = (id) => {
      let result = getTiposDeUnidadeDesvioAPE(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{lang.sequencia.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.sequencia.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.sequencia.ativa },
                           { id: 2, descricao: lang.sequencia.inativa },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={lang.sequencia.tiposDeEnsaio} modal={true}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.tiposDeEnsaio}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = {
                           tipoDeEnsaio: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio) {
                        showError(lang.sequencia.mensagens.informeOTipoDeEnsaio);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.tiposDeEnsaio) {
                           state.itemSelecionado.tiposDeEnsaio = [];
                        }
                        state.itemSelecionado.tiposDeEnsaio.push({
                           tipoDeEnsaio: this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.tiposDeEnsaio.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Cargo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.tipoDeEnsaio.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{lang.sequencia.tipoDeEnsaio}</Label>
                           <Select
                              name={'tipoDeEnsaio'} modal={true}
                              defaultValue={this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.tipoDeEnsaioSelecionado.tipoDeEnsaio = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <TipoDeEnsaioView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/tipodeensaio' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />

            <br />

            {!this.state.itemSelecionado.sequenciaNeos && (
               <SubCadastro
                  titulo={lang.sequencia.checkList} modal={true}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.checkList}
                  lang={lang}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = {
                              checkList: null,
                           };
                        }).then(() => resolve());
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve, reject) => {
                        if (!this.state.checkListSelecionado.checkList) {
                           showError(lang.sequencia.mensagens.informeOCheckList);
                           reject();
                           return false;
                        }

                        updateState(this, (state) => {
                           if (!state.itemSelecionado.checkList) {
                              state.itemSelecionado.checkList = [];
                           }
                           state.itemSelecionado.checkList.push({
                              checkList: this.state.checkListSelecionado.checkList,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = null;
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.checkList.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: lang.sequencia.checkList, width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.checkList.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <Label>{lang.sequencia.checkList}</Label>
                              <Select
                                 name={'checkList'}  modal={true}
                                 defaultValue={this.state.checkListSelecionado.checkList}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.checkListSelecionado.checkList = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <CheckListView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/checklist' + buildQueryString(2, null, 'descricao', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     );
                  }}
               />
            )}

            <br />
            <SubCadastro
               titulo='Testes'
               exibirTitulos={true}
               itens={this.state.itemSelecionado.itens}
               gradeSempreVisivel={true}
               tituloDoBotaoCancelar={lang.sequencia.finalizarCadastroDeTeste}
               lang={lang}
               novo={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? () => {
                          return new Promise((resolve) => {
                             let maiorNumero = 0;
                             if (this.state.itemSelecionado.itens) {
                                this.state.itemSelecionado.itens.map((i, index) => {
                                   if (i.numero > maiorNumero) {
                                      maiorNumero = i.numero;
                                   }
                                   return true;
                                });
                             }
                             maiorNumero++;
                             updateState(this, (state) => {
                              state.configuracaoSelecionada = null;
                              let configuracaoSelecionada = { tipoDeTeste: 0 };
                              this.setValoresDefault(configuracaoSelecionada);
                              configuracaoSelecionada.numero = maiorNumero;
                              state.configuracaoSelecionada = configuracaoSelecionada;
                             }).then(() => resolve());
                          });
                       }
                     : null
               }
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.configuracaoSelecionada.numero) {
                        showError(lang.sequencia.informeONumero);
                        reject();
                        return false;
                     }

                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === 0) {
                        if (!this.state.configuracaoSelecionada.limiteSuperior) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeODesvioMaximoSuperior);
                           reject();
                           return false;
                        }

                        if (!this.state.configuracaoSelecionada.limiteSuperior) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeODesvioMaximoSuperior);
                           reject();
                           return false;
                        }

                        if (!this.state.configuracaoSelecionada.limiteInferior) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeODesvioMaximoInferior);
                           reject();
                           return false;
                        }

                     } else {
                        if (!this.state.configuracaoSelecionada.vazamento_PressaoInicial) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeAPressaoDeEntrada);
                           reject();
                           return false;
                        }

                        if (!this.state.configuracaoSelecionada.vazamentoTempo) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeOTempoDeEspera);
                           reject();
                           return false;
                        }
                        if (!this.state.configuracaoSelecionada.vazamento) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeOVazamentoMaximo);
                           reject();
                           return false;
                        }
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.itens) {
                           state.itemSelecionado.itens = [];
                        }

                        let item = {
                           numero: this.state.configuracaoSelecionada.numero,
                           repeticoes: 1,
                           unidadeLimites: this.state.configuracaoSelecionada.unidadeLimites,
                           tipoDeTeste: this.state.configuracaoSelecionada.tipoDeTeste,
                           limiteSuperior: this.state.configuracaoSelecionada.limiteSuperior,
                           limiteInferior: this.state.configuracaoSelecionada.limiteInferior,
                           vazamentoTempo: this.state.configuracaoSelecionada.vazamentoTempo,
                           vazamento_PressaoInicial: this.state.configuracaoSelecionada.vazamento_PressaoInicial,
                           vazamento: this.state.configuracaoSelecionada.vazamento,
                           manometro_Pressao: this.state.configuracaoSelecionada.manometro_Pressao,
                        };

                        state.itemSelecionado.itens.push(item);
                        this.setUltimoItemCriado(item);
                     }).then(() => resolve());
                  });
               }}
               aposSalvar={this.aposSalvar}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.configuracaoSelecionada.numero) {
                        showError(lang.sequencia.informeONumero);
                        reject();
                        return false;
                     }

                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === 0) {
                        if (!this.state.configuracaoSelecionada.limiteSuperior) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeODesvioMaximoSuperior);
                           reject();
                           return false;
                        }

                        if (!this.state.configuracaoSelecionada.limiteInferior) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeODesvioMaximoInferior);
                           reject();
                           return false;
                        }

                        if (!this.state.configuracaoSelecionada.unidadeLimites) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeAUnidadeDesvio);
                           reject();
                           return false;
                        }
                     } else {
                        if (this.state.configuracaoSelecionada.vazamento_PressaoInicial === 0) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeAPressaoDeEntrada);
                           reject();
                           return false;
                        }

                        if (!this.state.configuracaoSelecionada.vazamentoTempo) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeOTempoDeEspera);
                           reject();
                           return false;
                        }
                        if (!this.state.configuracaoSelecionada.vazamento) {
                           showError(lang.cadastroDeSequenciaAPE.mensagens.informeOVazamento);
                           reject();
                           return false;
                        }
                     }

                     updateState(this, (state) => {
                        state.itemSelecionado.itens[index].numero = this.state.configuracaoSelecionada.numero;
                        state.itemSelecionado.itens[index].repeticoes = this.state.configuracaoSelecionada.repeticoes;
                        state.itemSelecionado.itens[index].unidadeLimites =
                           this.state.configuracaoSelecionada.unidadeLimites;
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;
                        state.itemSelecionado.itens[index].limiteSuperior =
                           this.state.configuracaoSelecionada.limiteSuperior;
                        state.itemSelecionado.itens[index].limiteInferior =
                           this.state.configuracaoSelecionada.limiteInferior;
                        state.itemSelecionado.itens[index].vazamentoTempo =
                           this.state.configuracaoSelecionada.vazamentoTempo;
                        state.itemSelecionado.itens[index].vazamento_PressaoInicial =
                           this.state.configuracaoSelecionada.vazamento_PressaoInicial;
                        state.itemSelecionado.itens[index].vazamento = this.state.configuracaoSelecionada.vazamento;
                        state.itemSelecionado.itens[index].manometro_Pressao =
                           this.state.configuracaoSelecionada.manometro_Pressao;
                        this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? (index) => {
                          updateState(this, (state) => {
                             state.itemSelecionado.itens.splice(index, 1);
                             state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
                          });
                       }
                     : null
               }
               colunas={() => {
                  return [
                     { titulo: 'Nº', width: '3%', className: null },
                     { titulo: lang.cadastroDeSequenciaAPE.tipoDeTeste, width: '27%', className: null },
                     { titulo: lang.cadastroDeSequenciaAPE.valorDeReferencia, width: '15%', className: null },
                     { titulo: lang.cadastroDeSequenciaAPE.desvioMaximoSuperior, width: '20%', className: null },
                     { titulo: lang.cadastroDeSequenciaAPE.desvioMaximoInferior, width: '20%', className: null },
                     { titulo: lang.cadastroDeSequenciaAPE.unidadeDesvio, width: '30%', className: null },
                  ];
               }}
               linha={(item) => {
                  if (item.tipoDeTeste === 0) {
                     return [
                        item.numero,

                        this.getDescricaoDoTipoDeTeste(item.tipoDeTeste),
                        item.manometro_Pressao + ' mmHg',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.unidadeLimites),
                     ];
                  } else {
                     return [
                        item.numero,

                        this.getDescricaoDoTipoDeTeste(item.tipoDeTeste),
                        item.vazamento + ' mmHg/min (' + item.vazamento_PressaoInicial + ' mmHg)',
                        0,
                        0,
                        '-',
                     ];
                  }
               }}
               acoes={(subCadastro, index) => {
                  return (
                     <div style={{ display: 'table-cell', margin: 'auto', marginRight: 4, width: 25 }}>
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginBottom: -10 }}
                           cursor='pointer'
                           icon={faSortUp}
                           onClick={() => {
                              if (this.state.itemSelecionado.itens[index].numero > 1) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index - 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero - 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginTop: -10 }}
                           cursor='pointer'
                           icon={faSortDown}
                           onClick={() => {
                              if (
                                 this.state.itemSelecionado.itens[index].numero <
                                 this.state.itemSelecionado.itens.length
                              ) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index + 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero + 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                     </div>
                  );
               }}
               formulario={() => {
                  return this.state.configuracaoSelecionada ? (
                     <div>
                        <Row>
                        <Col sm={2} md={2} lg={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.numero}</Label>
                                 <IntegerInput
                                    defaultValue={this.state.configuracaoSelecionada.numero}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.numero = e.floatValue;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {/* <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.repeticoes}</Label>
                                 <IntegerInput
                                    // defaultValue={this.state.configuracaoSelecionada.repeticoes}
                                    defaultValue={1}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.repeticoes = e.floatValue;
                                       });
                                    }}
                                    readOnly={true}
                                 />
                              </FormGroup>
                           </Col> */}
                           <Col>
                              <FormGroup>
                                 <Label>{lang.cadastroDeSequenciaAPE.tipoDeTeste}</Label>
                                 <Select
                                    defaultValue={this.state.configuracaoSelecionada.tipoDeTeste}
                                    options={getTiposDeTestesAPE(lang)}
                                    getKeyValue={(i) => i.id}
                                    allowEmpty={false}
                                    getDescription={(i) => i.descricao}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i != null) {
                                             state.configuracaoSelecionada.tipoDeTeste = i.id;
                                          } else {
                                             state.configuracaoSelecionada.tipoDeTeste = 0;
                                          }
                                          let configuracaoSelecionada = state.configuracaoSelecionada;
                                          this.setValoresDefault(configuracaoSelecionada);
                                          state.configuracaoSelecionada = configuracaoSelecionada;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {this.state.configuracaoSelecionada.tipoDeTeste === 0 && (
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.pressaoDeEntrada}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.manometro_Pressao}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.manometro_Pressao = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           )}
                           {this.state.configuracaoSelecionada.tipoDeTeste === 2 && (
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.vazamentoPressaoInicial}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.vazamento_PressaoInicial}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.vazamento_PressaoInicial = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           )}
                        </Row>

                        {this.state.configuracaoSelecionada.tipoDeTeste === 0 && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.desvioMaximoSuperior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteSuperior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.desvioMaximoInferior}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.limiteInferior = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.unidadeDesvio}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                       options={getTiposDeUnidadeDesvioAPE(lang)}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.descricao}
                                       allowEmpty={false}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.unidadeLimites = i.id;
                                             } else {
                                                state.configuracaoSelecionada.unidadeLimites = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === 2 && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.tempoDeEspera}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.configuracaoSelecionada.vazamentoTempo}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.vazamentoTempo = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaAPE.vazamentoMaximo}</Label>
                                    <DecimalInput
                                       lang={lang}
                                       defaultValue={this.state.configuracaoSelecionada.vazamento}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.vazamento = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            <Button
               text={this.props.lang.sequencia.duplicarSequencia}
               style={{ width: 200 }}
               onClick={() => {
                  let item = JSON.parse(JSON.stringify(this.state.itemSelecionado));
                  item.id = null;
                  if (item.itens != null) {
                     for (let i = 0; i < item.itens.length; i++) {
                        item.itens[i].id = null;
                     }
                  }
                  if (item.tiposDeEnsaio != null) {
                     for (let i = 0; i < item.tiposDeEnsaio.length; i++) {
                        item.tiposDeEnsaio[i].id = null;
                     }
                  }

                  updateState(this, (state) => {
                     state.incluindo = false;
                     state.alterando = false;
                     state.valorPadrao = item;
                  }).then(() => {
                     this.form.inserirClick();
                  });
               }}
            />
            <br />
         </div>
      );
   };

   aposSalvar = (subCadastro, inserindo, alterando) => {
      let ordem = this.state.ultimoItemCriado.numero;
      updateState(this, (state) => {
         state.itemSelecionado.itens.sort((i, j) => {
            let result = i.numero < j.numero ? -1 : i.numero > j.numero ? 1 : 0;
            if (j.numero === ordem && i.numero === ordem) {
               result = -1;
            }
            return result;
         });
         state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
      });
      if (inserindo) {
         subCadastro.novo();
      }
   };

   setValoresDefault = (item) => {
      let padrao = null;

      if (this.state.ultimoItemCriado && !this.state.configuracaoSelecionada) {
         padrao = this.state.ultimoItemCriado;
      } else if (item !== null) {
         padrao = {
            tipoDeTeste: item.tipoDeTeste,
         };
      } else {
         padrao = {
            tipoDeTeste: 0,
         };
      }

      if (padrao) {
         item.repeticoes = 1;
         item.tipoDeTeste = padrao.tipoDeTeste;
      }
   };
   
   render() {
      return (
         <FormularioPadrao
            titulo={
               <div>
                  <Link
                     to={'/sequencia'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',

                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.sequencias}</span>                     
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.cadastroDeSequenciaAPE.titulo}</span>
               </div>
            }
            url={'/sequenciaape'}
            fastUrl={'/sequenciaape/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1191, 1192, 1193, 1194]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            definirValoresPadrao={(item) => {
               return this.state.valorPadrao ? this.state.valorPadrao : item;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               acessos: [],
            }}
         />
      );
   }
}
