import btoa from 'btoa'; 
import {
   buildQueryString,
   getEnderecoCompleto,
   updateState,
   rsaEncrypt,
   validarEndereco,
   validarTelefone,
} from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class DadosDaEmpresaController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'EmpresaController';
      this.props = props.props;
   }

   antesDeInserir = () => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cnpjParaNaoConsultar = null;
            resolve();
         });
      });
   };

   antesDeEditar = (formularioPadrao, empresa) => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cnpjParaNaoConsultar = empresa.cnpj;
            resolve();
         });
      });
   };

   getTitulosDaTabela = () => {
      return [
         {
            titulo: this.lang.telaDeCadastroDeTipoDeEmpresa.codigo,
            orderby: 'Id',
            className: 'codigo',
         },
         {
            titulo: this.lang.telaDeCadastroDeEmpresa.razaoSocial,
            width: '40%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeEmpresa.nomeFantasia,
            width: '40%',
            orderby: null,
         },
         { titulo: this.lang.telaDeCadastroDeEmpresa.cnpj, width: '20%', orderby: null },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.razaoSocial, item.nomeFantasia, item.cnpj];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;          

         this.api
            .get('/publickey')
            .then((publicKey) => {
               let senhaCriptografada = null;
               let novaSenha = null;
               if (item.senha) {
                  let blocks = item.senha.match(new RegExp('.{1,' + 64 + '}', 'g'));
                  for (let i = 0; i < blocks.length; i++) {
                     let buffer = rsaEncrypt(blocks[i], publicKey);
                     senhaCriptografada = senhaCriptografada ? Buffer.concat([senhaCriptografada, buffer]) : buffer;
                  }
                  novaSenha = btoa(senhaCriptografada);
               }

               var input = {
                  novaSenha: novaSenha,
                  senhaAlterada: item.senhaAlterada,
                  telefones: item.telefones,
                  enderecos: item.enderecos,
               };

               if (state.alterando) {
                  input.id = parseInt(item.id);
               }

               resolve(input);
            })
            .catch(reject);
      });
   };

   consultarCep = () => {
      if (
         this.view.state.enderecoSelecionado.endereco.cep &&
         this.view.state.enderecoSelecionado.endereco.cep.length === 9 &&
         this.view.state.enderecoSelecionado.endereco.cep !== this.view.state.cepParaNaoConsultar
      ) {
         this.props.consultaDeCep.get(this.view.state.enderecoSelecionado.endereco.cep).then((resultViaCep) => {
            if (resultViaCep && resultViaCep.localidade && resultViaCep.uf) {
               let url =
                  '/cidade' +
                  buildQueryString(null, null, null, {
                     uf: resultViaCep.uf.toUpperCase(),
                     localidade: resultViaCep.localidade.toUpperCase(),
                  });
               this.props.api
                  .getAll(url, false)
                  .then((result) => {
                     if (result.count === 1) {
                        let enderecoEmEdicao = { endereco: {} };
                        enderecoEmEdicao.cep = this.view.state.enderecoSelecionado.endereco.cep;
                        enderecoEmEdicao.logradouro = resultViaCep.logradouro
                           ? resultViaCep.logradouro.toUpperCase()
                           : null;
                        enderecoEmEdicao.bairro = resultViaCep.bairro ? resultViaCep.bairro.toUpperCase() : null;
                        enderecoEmEdicao.cidade = result.items[0];

                        updateState(this.view, (state) => {
                           state.enderecoSelecionado.endereco.logradouro = enderecoEmEdicao.logradouro;
                           state.enderecoSelecionado.endereco.bairro = enderecoEmEdicao.bairro;
                           state.enderecoSelecionado.endereco.cidade = enderecoEmEdicao.cidade;
                           state.mostrarEndereco = false;
                           state.cepParaNaoConsultar = this.view.state.enderecoSelecionado.endereco.cep;
                        }).then(() => {
                           updateState(this.view, (state) => {
                              state.mostrarEndereco = true;
                           });
                        });
                     }
                  })
                  .catch(() => {});
            }
         });
      }
   };

   inserirEndereco = () => {
      return new Promise((resolve, reject) => {
         if (!validarEndereco(true, this.view.state.enderecoSelecionado.endereco, this.lang, reject)) {
            return;
         }

         let endereco = {
            logradouro: this.view.state.enderecoSelecionado.endereco.logradouro,
            numero: this.view.state.enderecoSelecionado.endereco.numero,
            complemento: this.view.state.enderecoSelecionado.endereco.complemento,
            bairro: this.view.state.enderecoSelecionado.endereco.bairro,
            cep: this.view.state.enderecoSelecionado.endereco.cep,
            cidade: this.view.state.enderecoSelecionado.endereco.cidade,
            pais: this.view.state.enderecoSelecionado.endereco.pais,
            linha1: this.view.state.enderecoSelecionado.endereco.linha1,
            linha2: this.view.state.enderecoSelecionado.endereco.linha2,
            linha3: this.view.state.enderecoSelecionado.endereco.linha3,
         };

         endereco.enderecoCompleto = getEnderecoCompleto(endereco);

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.enderecos) {
               state.itemSelecionado.enderecos = [];
            }
            state.itemSelecionado.enderecos.push({
               endereco,
            });
         }).then(() => resolve());
      });
   };

   alterarEndereco = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarEndereco(true, this.view.state.enderecoSelecionado.endereco, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.enderecos[index].endereco.logradouro = state.enderecoSelecionado.endereco.logradouro;
            state.itemSelecionado.enderecos[index].endereco.numero = state.enderecoSelecionado.endereco.numero;
            state.itemSelecionado.enderecos[index].endereco.complemento =
               state.enderecoSelecionado.endereco.complemento;
            state.itemSelecionado.enderecos[index].endereco.bairro = state.enderecoSelecionado.endereco.bairro;
            state.itemSelecionado.enderecos[index].endereco.cep = state.enderecoSelecionado.endereco.cep;
            state.itemSelecionado.enderecos[index].endereco.cidade = state.enderecoSelecionado.endereco.cidade;
            state.itemSelecionado.enderecos[index].endereco.pais = state.enderecoSelecionado.endereco.pais;
            state.itemSelecionado.enderecos[index].endereco.linha1 = state.enderecoSelecionado.endereco.linha1;
            state.itemSelecionado.enderecos[index].endereco.linha2 = state.enderecoSelecionado.endereco.linha2;
            state.itemSelecionado.enderecos[index].endereco.linha3 = state.enderecoSelecionado.endereco.linha3;
            state.itemSelecionado.enderecos[index].endereco.enderecoCompleto = getEnderecoCompleto(
               state.itemSelecionado.enderecos[index].endereco
            );
         }).then(() => resolve());
      });
   };

   inserirTelefone = () => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.telefones) {
               state.itemSelecionado.telefones = [];
            }
            state.itemSelecionado.telefones.push({
               pais: this.view.state.telefoneSelecionado.pais,
               ddd: this.view.state.telefoneSelecionado.ddd,
               numero: this.view.state.telefoneSelecionado.numero,
               temWhatsApp: this.view.state.telefoneSelecionado.temWhatsApp ? true : false,
            });
         }).then(() => resolve());
      });
   };

   alterarTelefone = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.telefones[index].pais = this.view.state.telefoneSelecionado.pais;
            state.itemSelecionado.telefones[index].ddd = this.view.state.telefoneSelecionado.ddd;
            state.itemSelecionado.telefones[index].numero = this.view.state.telefoneSelecionado.numero;
            state.itemSelecionado.telefones[index].temWhatsApp = this.view.state.telefoneSelecionado.temWhatsApp;
         }).then(() => resolve());
      });
   };   

}
