import { BaseController } from '../BaseController';

export default class NotificacaoController extends BaseController {
    constructor(props) {
        super(props);
        this.name = 'NotificacaoController';
    }

    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.telaDeCadastroDeTipoDeEmpresa.codigo, orderby: 'Id', className: 'codigo' },            
            { titulo: 'Titulo', width: '30%', orderby: null },
            { titulo: 'Mensagem', width: '60%', orderby: null },
            { titulo: 'Status', width: '10%', orderby: null },
        ];
    };

    getDadosDaTabela = (item) => {
        return [item.id, item.titulo, item.mensagem, item.statusDaMensagem.descricao];
    };

    getObjetoDeDados = () => {        
    };
}