import axios from 'axios';
import Messages from './../components/Messages';
import SessionManager from './../models/SessionManager';
import { getLang } from './../models/Lang';
import { ConnectionParams } from './../config/ConnectionParams';
import btoa from 'btoa';
import { rsaEncrypt } from './Functions';

const urlBase = ConnectionParams.apiUrl;

export const DEBUG = false;

export default class Api {
   constructor() {
      this.cache = [];

      this.insertToCache = this.insertToCache.bind(this);
      this.getFromCache = this.getFromCache.bind(this);
      this.getAll = this.getAll.bind(this);
      this.startLoading = this.startLoading.bind(this);
      this.finishLoading = this.finishLoading.bind(this);
      this.loading = false;
   }

   insertToCache = (url, data) => {
      let indice = -1;
      this.cache.map((item, index) => {
         if (item.url === url) {
            indice = index;
         }
         return true;
      });

      if (indice === -1) {
         this.cache.push({
            url: url,
            data: data,
         });
      }
   };

   getFromCache = (url) => {
      let result = null;
      let indice = -1;
      this.cache.map((item, index) => {
         if (item.url === url) {
            indice = index;
         }
         return true;
      });

      if (indice >= 0) {
         result = this.cache[indice];
      }

      return result;
   };

   urlBase = () => urlBase;

   getOptions = (queryParams, responseType) => {
      let sessionManager = new SessionManager();
      let login = sessionManager.getLogin();
      let result = {
         responseType: responseType,
         headers: {
            Authorization: login ? login.token : null,
            UserId: login ? login.idDoUsuario : null,
            lang: login && login.idioma ? login.idioma : null,
         },
         params: queryParams,
      };
      return result;
   };

   startLoading = () => {
      this.loading = true;
      setTimeout(() => {
         if (this.loading) {
            document.getElementById('loading').className = 'loadingDiv';
         }
      }, 2000);
   };

   finishLoading = () => {
      this.loading = false;
      document.getElementById('loading').className = 'loadingDiv hide';
   };

   post = (url, data) => {
      return new Promise((resolve, reject) => {
         this.startLoading();
         axios
            .post(urlBase + url, data, this.getOptions())
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => this.handleErrorMessage(e, this, reject));
      });
   };

   put = (url, data) => {
      return new Promise((resolve, reject) => {
         this.startLoading();
         axios
            .put(urlBase + url, data, this.getOptions())
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => this.handleErrorMessage(e, this, reject));
      });
   };

   delete = (url) => {
      return new Promise((resolve, reject) => {
         this.startLoading();
         axios
            .delete(urlBase + url, this.getOptions())
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => this.handleErrorMessage(e, this, reject));
      });
   };

   get = (url, useCache = false, responseType = '') => {
      return new Promise((resolve, reject) => {
         let achouNoCache = false;
         let cachedResult = null;

         if (useCache) {
            cachedResult = this.getFromCache(url);
            achouNoCache = cachedResult ? true : false;
         }

         if (!achouNoCache) {
            this.startLoading();
            axios
               .get(urlBase + url, this.getOptions(null, responseType))
               .then((result) => {
                  this.finishLoading();
                  if (useCache) {
                     this.insertToCache(url, result.data);
                  }
                  resolve(result.data.lenght > 0 ? result.data[0] : result.data);
               })
               .catch((e) => this.handleErrorMessage(e, this, reject));
         } else {
            resolve(cachedResult.data);
         }
      });
   };

   getAll = (url, useCache = false, useProgress = true) => {
      return new Promise((resolve, reject) => {
         let achouNoCache = false;
         let cachedResult = null;

         if (useCache) {
            cachedResult = this.getFromCache(url);
            achouNoCache = cachedResult ? true : false;
         }

         if (!achouNoCache) {
            if (useProgress) {
               this.startLoading();
            }
            axios
               .get(urlBase + url, this.getOptions())
               .then((result) => {
                  this.finishLoading();
                  if (useCache) {
                     this.insertToCache(url, result.data);
                  }
                  resolve(result.data);
               })
               .catch((e) => {
                  this.handleErrorMessage(e, this, reject);
               });
         } else {
            resolve(cachedResult.data);
         }
      });
   };

   query = (url, queryParams) => {
      return new Promise((resolve, reject) => {
         this.startLoading();
         axios
            .get(urlBase + url, this.getOptions(queryParams))
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => this.handleErrorMessage(e, this, reject));
      });
   };

   handleErrorMessage = (e, sender, reject) => {
      sender.finishLoading();
      let mensagem = '';
      if (e.response && e.response.data && e.response.data.errorMessage) {
         mensagem = e.response.data.errorMessage;
      } else if (e.response && e.response.data && e.response.data.ExceptionMessage) {
         mensagem = e.response.request.response;
      } else if (e.response && e.response.request && e.response.request.response) {
         mensagem = e.response.request.response;
      } else if (e.response && e.response.statusText) {
         mensagem = e.response.statusText;
      } else {
         mensagem = e.message;
      }

      if (mensagem === 'Network Error') {
         mensagem = getLang('pt-BR').comunicacao.servidorIndisponivel;
      }
      new Messages().showError(mensagem.toString());
      if (reject) {
         reject(mensagem);
      }
   };

   protectedPost = (url, data) => {
      return new Promise((resolve, reject) => {
         this.get('/publickey').then((publicKey) => {
            let inputString = JSON.stringify(data);
            let blocks = inputString.match(new RegExp('.{1,' + 64 + '}', 'g'));
            let encryptedInput = null;
            for (let i = 0; i < blocks.length; i++) {
               let buffer = rsaEncrypt(blocks[i], publicKey);
               encryptedInput = encryptedInput ? Buffer.concat([encryptedInput, buffer]) : buffer;
            }
            this.post(url, { data: btoa(encryptedInput) })
               .then(resolve)
               .catch(reject);
         });
      });
   };

   protectedPut = (url, data) => {
      return new Promise((resolve, reject) => {
         this.get('/publickey').then((publicKey) => {
            let inputString = JSON.stringify(data);
            let blocks = inputString.match(new RegExp('.{1,' + 64 + '}', 'g'));
            let encryptedInput = null;
            for (let i = 0; i < blocks.length; i++) {
               let buffer = rsaEncrypt(blocks[i], publicKey);
               encryptedInput = encryptedInput ? Buffer.concat([encryptedInput, buffer]) : buffer;
            }
            this.put(url, { data: btoa(encryptedInput) })
               .then(resolve)
               .catch(reject);
         });
      });
   };
}
