import React, { Component } from 'react';
import { Row, Col, Form, ButtonGroup, InputGroup, Modal } from 'react-bootstrap';
import { faTrashAlt, faTimesCircle, faSave } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import {
   faPlusCircle,
   faSearch,
   faAngleDown,
   faAngleDoubleDown,
   faExternalLinkAlt,
   faEdit,
} from '@fortawesome/free-solid-svg-icons';
import SessionManager from './../models/SessionManager';
import { LayoutParams } from './../config/LayoutParams';
import { buildQueryString, isNumeric, replaceAll, retirarAcentos } from '../utils/Functions';
import { showError, showInfo, showConfirm } from '../components/Messages';
import Button from '../components/Button';
import '../contents/css/cabecalho-tabela-formulario-padrao.css';
import '../contents/css/tabela-formulario-padrao.css';
import IconButton from '../components/IconButton';

let timer = 0;
let delay = 200;
let prevent = false;

export default class FormularioPadrao extends Component {
   constructor(props) {
      super(props);
      const {
         renderizarFiltros,
         getFiltro,
         getTitulosDaTabela,
         getDadosDaTabela,
         renderizarFormulario,
         renderizarAcoesDoFormulario,
         getObjetoDeDados,
         antesDeInserir,
         aposInserir,
         antesDeEditar,
         aposEditar,
         antesDeSalvar,
         antesDeExcluir,
         definirValoresPadrao,
      } = props;

      this.setState = props.setFormState;
      this.getState = props.getFormState;

      this.salvar = this.salvar.bind(this);
      this.formConsultaSubmit = this.formConsultaSubmit.bind(this);
      this.editarClick = this.editarClick.bind(this);
      this.inserirClick = this.inserirClick.bind(this);
      this.cancelarClick = this.cancelarClick.bind(this);
      this.excluirClick = this.excluirClick.bind(this);
      this.excluir = this.excluir.bind(this);
      this.renderizarCodigo = this.renderizarCodigo.bind(this);
      this.renderizarAcoes = this.renderizarAcoes.bind(this);
      this.getTitulo = this.getTitulo.bind(this);
      this.getFiltros = this.getFiltros.bind(this);
      this.getLista = this.getLista.bind(this);
      this.getFormulario = this.getFormulario.bind(this);
      this.filtrar = this.filtrar.bind(this);
      this.navegar = this.navegar.bind(this);
      this.getCabecalhos = this.getCabecalhos.bind(this);
      this.getNavegador = this.getNavegador.bind(this);
      this.onRowClick = this.onRowClick.bind(this);
      this.definirValoresPadrao = this.definirValoresPadrao.bind(this);
      this.atualizarFormulario = this.atualizarFormulario.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.handleDoubleClick = this.handleDoubleClick.bind(this);

      this.renderizarFiltros = renderizarFiltros ? renderizarFiltros : this.renderizarFiltros;
      this.getFiltro = getFiltro ? getFiltro : this.getFiltro;
      this.getTitulosDaTabela = getTitulosDaTabela ? getTitulosDaTabela : this.getTitulosDaTabela;
      this.getDadosDaTabela = getDadosDaTabela ? getDadosDaTabela : this.getDadosDaTabela;
      this.renderizarFormulario = renderizarFormulario ? renderizarFormulario : this.renderizarFormulario;
      this.renderizarAcoesDoFormulario = renderizarAcoesDoFormulario
         ? renderizarAcoesDoFormulario
         : this.renderizarAcoesDoFormulario;
      this.getObjetoDeDados = getObjetoDeDados ? getObjetoDeDados : this.getObjetoDeDados;
      this.definirValoresPadrao = definirValoresPadrao ? definirValoresPadrao : this.definirValoresPadrao;

      this.antesDeInserir = antesDeInserir;
      this.aposInserir = aposInserir;
      this.antesDeEditar = antesDeEditar;
      this.aposEditar = aposEditar;
      this.antesDeSalvar = antesDeSalvar;
      this.antesDeExcluir = antesDeExcluir;
   }

   componentDidMount() {
      try {
         if (this.props.ref) {
            this.props.ref(this);
         }

         //let clientHeight = document.getElementById('formularioPadrao').clientHeight;

         let clientHeight = 800;

         let sessionManager = new SessionManager();
         let permissoes = this.props.permissoes;

         this.setState({
            itemSelecionado: null,
            itens: [],
            vazio: false,
            consultou: false,
            navegando: true,
            incluindo: false,
            alterando: false,
            itemVazio: JSON.stringify(this.props.itemVazio),
            filtro: {},
            botaoSalvarHabilitado: true,
            quantidadeDeDados: 0,
            podeAvancar: false,
            podeVoltar: false,
            podeConsultar: permissoes ? sessionManager.temAcessoARotina(permissoes[0]) : false,
            podeIncluir: permissoes ? sessionManager.temAcessoARotina(permissoes[1]) : false,
            podeAlterar: permissoes ? sessionManager.temAcessoARotina(permissoes[2]) : false,
            podeExcluir: permissoes ? sessionManager.temAcessoARotina(permissoes[3]) : false,
            mostrarFormulario: true,
            ordenacao: this.props.ordenacaoPadrao,
            tamanhoDaPagina: Math.trunc(clientHeight / 36.8),
            formularioHabilitado: false,
         })
            .then(() => {
               let searchText = null;
               if (this.props.select) {
                  searchText = this.props.select.getSearchText();
               }
               let filtro = this.getState().filtro;
               filtro.texto = searchText;
               this.setState({ filtro: filtro, mounted: true })
                  .then(() => {
                     if (this.props.apenasInserir) {
                        this.inserirClick();
                     } else if (this.props.apenasAlterar) {
                        this.props.api
                           .getAll(this.props.url + '?id=' + this.props.id.toString())
                           .then((data) => {
                              let item = data.items[0];
                              if (item) {
                                 this.editarClick(item);
                              } else {
                                 showError(
                                    this.props.lang.formularioPadrao.mensagens.registroNaoLocalizadoParaAlteracao
                                 );
                              }
                           })
                           .catch((e) => {
                              console.error(e);
                           });
                     } else {
                        this.setState({ navegando: true });
                        if (!this.props.iniciarVazio) {
                           this.filtrar();
                        }
                     }
                  })
                  .catch((e) => {
                     console.error(e);
                  });
            })
            .catch((e) => {
               console.error(e);
            });
      } catch (e) {
         console.error(e);
      }
   }

   /*
        Esse formulário padrão é um componente renderizado na tela.
        Para utilizado implemente os métodos a seguir e passe eles como propriedade no componente.
        Por exemplo:

        <FormularioPadrao 
            getFiltro={this.getFiltro}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.getObjetoDeDados}
        />      
    */

   getFiltro(filtro) {
      // Pode ser sobrescrito caso seja necessário ter mais algum filtro na tela.
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (filtro && filtro.texto) {
         let texto = filtro.texto.toString();
         if (texto[0] === '#') {
            if (!isNumeric(texto.substring(1))) {
               showError(this.props.lang.formularioPadrao.codigoInvalido + ': "' + texto.substring(1) + '"');
               return result;
            }
            result.id = texto.substring(1);
         } else {
            result.searchable = texto;
         }
      }
      return result;
   }

   getTitulosDaTabela() {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de string com os títulos para a tabela
   }
   getDadosDaTabela(item) {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de valores a ser usado na linha da tabela.
      // Este método é chamado passando registro por registro.
   }
   renderizarFormulario() {
      // * OBGRIGATÓRIO
      // Implemente esse método para retornar o formulário usado para inclusão e alteração de dados.
   }
   renderizarAcoesDoFormulario() {
      // Área para botôes de ação no final do formulário que estão disponíveis sem ter que clicar em alterar.
   }
   getObjetoDeDados() {
      // * OBGRIGATÓRIO
      // Implemente esse método para montar o objeto a ser usado no POST e PUT. O retorno deve ser uma Promise.
   }
   antesDeInserir() {
      // Implemente esse método para executar alguma ação antes de mudar a tela para o modo de inclusão.
   }
   aposInserir() {
      // Implemente esse método para executar alguma imediatamente após mudar a tela para o modo de inclusão.
   }
   antesDeEditar() {
      // Implemente esse método para executar alguma ação antes de mudar a tela para o modo de alteração.
   }
   aposEditar() {
      // Implemente esse método para executar alguma imediatamente após mudar a tela para o modo de alteração.
   }
   antesDeSalvar() {
      // Implemente esse método para executar alguma ação antes salvar.
   }
   antesDeExcluir() {
      // Implemente esse método para executar alguma ação antes exlcuir.
   }

   atualizarFormulario() {
      this.setState(
         {
            mostrarFormulario: false,
         },
         () => {
            this.setState({ mostrarFormulario: true });
         }
      );
   }

   onRowClick(item) {
      if (this.props.select) {
         this.props.select.aoSelecionar(item);
      }
   }

   doDoubleClickAction(item) {
      if (this.props.select || this.props.apenasInserir) {
         return;
      }
      this.editarClick(item);
   }

   handleClick(item) {
      let self = this;
      timer = setTimeout(function () {
         if (!prevent) {
            self.onRowClick(item);
         }
         prevent = false;
      }, delay);
   }

   handleDoubleClick(item) {
      clearTimeout(timer);
      prevent = true;
      this.doDoubleClickAction(item);
   }

   getItemVazio() {
      return JSON.parse(this.getState().itemVazio);
   }

   definirValoresPadrao(item) {
      return item;
   }

   formConsultaSubmit(event) {
      event.preventDefault();
      this.filtrar();
   }

   isMobile = () => {
      return window.screen.width <= 600;
   };

   filtrar() {
      return new Promise((resolve, reject) => {
         this.setState(
            {
               quantidadeDeDados: null,
               quantidadeTotalDeDados: null,
            },
            () => {
               let query = '';
               let filtro = this.getState().filtro;

               if (filtro && filtro.id) {
                  query = '?id=' + filtro.id.toString;
               } else {
                  var orderBy = this.getState().ordenacao;
                  query = buildQueryString(this.getState().tamanhoDaPagina, null, orderBy, this.getFiltro(filtro));
               }

               this.props.api
                  .getAll((this.props.fastUrl ? this.props.fastUrl : this.props.url) + query)
                  .then((data) => {
                     this.setState({
                        itens: data.items,
                        quantidadeDeDados: data.pageSize,
                        quantidadeTotalDeDados: data.count,
                        podeAvancar: data.count >= this.getState().tamanhoDaPagina,
                        podeCarregarTodos: data.count >= this.getState().tamanhoDaPagina,
                        vazio: !data || data.count === 0,
                     });
                     resolve();
                  })
                  .catch((e) => {
                     //if (reject) {
                     //   reject(e);
                     //}
                     console.error(e);
                  });
            }
         );
      });
   }

   navegar(opcao) {
      let query = '';
      let state = this.getState();
      var orderBy = state.ordenacao;

      let filtro = this.getState().filtro;

      if (filtro && filtro.id) {
         query = '?id=' + filtro.id.toString;
      } else {
         query = buildQueryString(
            this.getState().tamanhoDaPagina,
            state.itens.length.toString(),
            orderBy,
            this.getFiltro(filtro)
         );

         if (opcao === 1) {
            query = buildQueryString(
               this.getState().tamanhoDaPagina,
               state.itens.length.toString(),
               orderBy,
               this.getFiltro(filtro)
            );
         } else {
            query = buildQueryString(null, null, orderBy, this.getFiltro(filtro));
            state.itens = [];
         }
      }

      this.setState(
         {
            quantidadeDeDados: null,
            quantidadeTotalDeDados: null,
         },
         () => {
            this.props.api
               .getAll(this.props.fastUrl ? this.props.fastUrl : this.props.url + query)
               .then((data) => {
                  state.itens.push(...data.items);
                  this.setState({
                     itens: state.itens,
                     quantidadeDeDados: state.itens.length,
                     quantidadeTotalDeDados: data.count,
                     podeAvancar: data.count > state.itens.length,
                     podeCarregarTodos: data.count > state.itens.length,
                     vazio: !data || data.count === 0,
                  });
               })
               .catch((e) => console.error(e));
         }
      );
   }

   renderizarFiltros(sender) {
      return this.props.id ? null : (
         <Form.Group style={{ marginBottom: 8 }}>
            <InputGroup>
               <InputGroup.Prepend>
                  <InputGroup.Text style={{ padding: '1px 6px 1px 6px' }}>
                     <div
                        style={{ border: 0, outline: 'transparent', width: 25, cursor: 'pointer' }}
                        tabIndex={-1}
                        title={this.props.lang.formularioPadrao.limpar}
                        onClick={() => {
                           let filtro = sender.getState().filtro;
                           filtro.texto = null;
                           sender.setState({ filtro: filtro });
                           if (sender.textoFiltro) {
                              sender.textoFiltro.value = null;
                              sender.filtrar();
                           }
                        }}
                     >
                        x
                     </div>
                  </InputGroup.Text>
               </InputGroup.Prepend>

               <Form.Control
                  ref={(c) => {
                     this.textoFiltro = c;
                     if (c) {
                        c.focus();
                        if (this.props.select) {
                           let searchText = this.props.select.getSearchText();
                           if (searchText) {
                              c.selectionStart = searchText.length;
                              c.selectionEnd = searchText.length;
                           }
                        }
                     }
                  }}
                  type='text'
                  placeholder={`${this.props.lang.pesquisar}...`}
                  defaultValue={sender.getState().filtro.texto}
                  onChange={(e) => {
                     let filtro = sender.getState().filtro;
                     filtro.texto = e.target.value;
                     if (filtro.texto) {
                        filtro.texto = ('' + retirarAcentos(filtro.texto)).toUpperCase();
                        var charsToRemove = ['@', ',', '.', ';', "'", '\\', '/', '-', '_', '+'];
                        charsToRemove.forEach((c) => {
                           filtro.texto = replaceAll(filtro.texto, c, '');
                        });
                     }
                     sender.setState({ filtro: filtro });
                  }}
                  style={{ outline: 'none', boxShadow: 'none', borderColor: '#ced4da' }}
               />
               <InputGroup.Append>
                  <InputGroup.Text style={{ padding: '1px 6px 1px 6px' }}>
                     <button type='submit' style={{ border: 0, outline: 'transparent' }} tabIndex={-1}>
                        <IconButton icon={faSearch} style={{ fontSize: 26, color: '#666', marginBottom: -4 }} />
                     </button>
                  </InputGroup.Text>
               </InputGroup.Append>
            </InputGroup>
         </Form.Group>
      );
   }

   inserirClick() {
      let fnInserir = () => {
         var item = this.getItemVazio();
         if (this.definirValoresPadrao) {
            item = this.definirValoresPadrao(item);
         }
         this.setState({ itemSelecionado: item }, () => {
            this.setState({ navegando: false, alterando: false, incluindo: true }, () =>
               this.aposInserir ? this.aposInserir(this) : null
            );
         });
      };

      if (this.antesDeInserir) {
         this.antesDeInserir(this).then(() => {
            fnInserir();
         });
      } else {
         fnInserir();
      }
   }

   editarClick(clickedItem) {
      this.getItem(clickedItem.id).then((result) => {
         if (!result) {
            showError(this.props.lang.formularioPadrao.registroNaoLocalizadoParaAlteracao);
            return;
         }
         let item = JSON.parse(JSON.stringify(result));
         if (this.antesDeEditar) {
            this.antesDeEditar(this, item).then(() => {
               this.setState({ itemSelecionado: item, versaoAnterior: item }, () => {
                  this.setState({ navegando: false, alterando: true, incluindo: false }, () =>
                     this.aposEditar ? this.aposEditar(this) : null
                  );
               });
            });
         } else {
            this.setState({ itemSelecionado: item, versaoAnterior: item }, () =>
               this.setState({ navegando: false, alterando: true, incluindo: false }, () =>
                  this.aposEditar ? this.aposEditar(this) : null
               )
            );
         }
      });
   }

   getItem(id) {
      return new Promise((resolve, reject) => {
         this.props.api
            .get(this.props.url + '?id=' + id.toString())
            .then((data) => {
               if (data.items.length > 1) {
                  showError(this.props.lang.formularioPadrao.mensagens.maisDeUmItemEncontradoParaOFiltroAplicado);
                  reject(this.props.lang.formularioPadrao.mensagens.maisDeUmItemEncontradoParaOFiltroAplicado);
               } else {
                  resolve(data.items[0]);
               }
            })
            .catch((e) => console.error(e));
      });
   }

   cancelarClick() {
      return new Promise((resolve) => {
         this.setState(
            {
               navegando: this.props.select ? false : true,
               alterando: false,
               incluindo: false,
               itemSelecionado: this.getItemVazio(),
               formularioHabilitado: false,
            },
            () => {
               if (this.props.select && this.props.select.aoCancelar) {
                  this.props.select.aoCancelar();
               }
               if (this.props.modal && this.props.aoCancelar) {
                  this.props.aoCancelar();
               }
               resolve();
            }
         );
      });
   }

   excluirClick(item) {
      if (this.antesDeExcluir) {
         this.antesDeExcluir(item)
            .then(() => {
               showConfirm(this.props.lang.formularioPadrao.mensagens.desejaRealmenteExcluirEsteRegistro, () => {
                  this.excluir(item);
               });
            })
            .catch((e) => {});
      } else {
         showConfirm(this.props.lang.formularioPadrao.mensagens.desejaRealmenteExcluirEsteRegistro, () => {
            this.excluir(item);
         });
      }
   }

   excluir(item) {
      this.props.api.delete(this.props.url + '?id=' + item.id).then(() => {
         showInfo(this.props.lang.formularioPadrao.mensagens.excluidoComSucesso);
         this.setState({
            navegando: true,
            alterando: false,
            incluindo: false,
            items: [],
            itemSelecionado: this.getItemVazio(),
         });
         this.filtrar(this);
      });
   }

   renderizarCabecaolhoAcoes() {
      return this.props.select ? null : (
         <td className='acoes'>
            <div>{this.props.lang.formularioPadrao.acoes}</div>
         </td>
      );
   }

   renderizarCodigo(codigo) {
      return (
         <td className='codigo'>
            <div>{codigo}</div>
         </td>
      );
   }

   renderizarAcoes(item) {
      let result = [];
      if (!this.props.select) {
         result.push(
            <td key='acoes' className='acoes'>
               <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {
                     <div style={{ display: 'table-cell' }}>
                        <BotaoAlterarItemDeCadastro
                           onClick={() => this.editarClick(item)}
                           title={this.props.lang.formularioPadrao.visualizar}
                        />
                     </div>
                  }
                  {this.getState().podeExcluir && (
                     <div style={{ display: 'table-cell' }}>
                        <BotaoExcluirItemDeCadastro
                           onClick={() => this.excluirClick(item)}
                           title={this.props.lang.formularioPadrao.excluir}
                        />
                     </div>
                  )}
               </div>
            </td>
         );
      }
      return result;
   }

   salvar() {
      return new Promise((resolve, reject) => {
         if (this.antesDeSalvar) {
            this.antesDeSalvar(this);
         }

         let self = this;
         this.setState({ botaoSalvarHabilitado: false });
         this.getObjetoDeDados(this)
            .then((input) => {
               this.setState({ botaoSalvarHabilitado: true });
               if (self.getState().incluindo) {
                  let postFn = self.props.protected ? self.props.api.protectedPost : self.props.api.post;
                  postFn(self.props.url, input)
                     .then((id) => {
                        this.setState({ formularioHabilitado: false });
                        if (
                           (self.props.select && self.props.select.aoSelecionar) ||
                           (self.props.modal && self.props.aoSelecionar)
                        ) {
                           self.props.api
                              .getAll(self.props.url + '?id=' + id.toString())
                              .then((result) => {
                                 if (self.props.select && self.props.select.aoSelecionar) {
                                    self.props.select.aoSelecionar(result.items[0], true);
                                 }
                                 if (self.props.modal && self.props.aoSelecionar) {
                                    self.props.aoSelecionar(result.items[0], true);
                                 }
                              })
                              .catch((e) => console.error(e));
                        } else {
                           if (!this.props.id) {
                              self.setState({
                                 navegando: true,
                                 alterando: false,
                                 incluindo: false,
                                 items: [],
                                 itemSelecionado: self.getItemVazio(),
                              });
                              self.filtrar(self);
                           }
                        }
                     })
                     .catch(reject);
               } else if (self.getState().alterando) {
                  let putFn = self.props.protected ? self.props.api.protectedPut : self.props.api.put;
                  putFn(self.props.url, input)
                     .then((result) => {
                        this.setState({ formularioHabilitado: false });
                        if (
                           (self.props.select && self.props.select.aoSelecionar) ||
                           (self.props.modal && self.props.aoSelecionar)
                        ) {
                           self.props.api
                              .getAll(self.props.url + '?id=' + input.id.toString())
                              .then((result) => {
                                 if (self.props.select && self.props.select.aoSelecionar) {
                                    self.props.select.aoSelecionar(result.items[0], true);
                                 }
                                 if (self.props.modal && self.props.aoSelecionar) {
                                    self.props.aoSelecionar(result.items[0], true);
                                 }
                              })
                              .catch((e) => console.error(e));
                        } else {
                           showInfo(this.props.lang.formularioPadrao.mensagens.salvoComSucesso).then(() => {
                              if (!this.props.id) {
                                 self.setState({
                                    navegando: true,
                                    alterando: false,
                                    incluindo: false,
                                    items: [],
                                    itemSelecionado: self.getItemVazio(),
                                    consultou: true,
                                 });
                              }
                              self.filtrar(self);
                           });
                        }
                     })
                     .catch(reject);
               }
               resolve();
            })
            .catch((e) => {
               this.setState({ botaoSalvarHabilitado: true });
               reject(e);
            });
      });
   }

   getTitulo() {
      return (
         <div
            id='div-titulo-formulario'
            style={{
               paddingTop: 0,
               paddingLeft: 10,
               paddingRight: 10,
               height: 45,
               display: 'flex',
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               color: LayoutParams.colors.corSecundaria,
               borderRadius: 0,
               marginLeft: 0,
            }}
         >
            <div
               style={{
                  display: 'table-cell',
                  width: '0',
                  overflowX: 'visible',
                  fontSize: 30,
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
               }}
            >
               <div>{this.props.titulo}</div>
            </div>

            {this.getState().navegando && this.getState().podeIncluir && (
               <div style={{ display: 'table-cell', marginLeft: '80%', width: '100%' }}>
                  <div style={{ textAlign: 'right', paddingRight: 5, paddingTop: 8 }}>
                     <IconButton
                        onClick={this.inserirClick}
                        icon={faPlusCircle}
                        style={{ fontSize: 30, color: '', marginBottom: -4, cursor: 'pointer' }}
                     />
                  </div>
               </div>
            )}
            {(this.getState().incluindo || this.getState().alterando) && (
               <div
                  style={{
                     display: 'table-cell',
                     width: '100%',
                     maxWidth: this.props.maxWidth ? this.props.maxWidth : 800,
                     textAlign: 'right',
                     margin: 'auto',
                     paddingBottom: 6,
                     paddingRight: 15,
                  }}
               >
                  <ButtonGroup
                     style={{
                        marginLeft: 'auto',
                        marginRight: 0,
                        marginTop: 5,
                        borderRadius: 4,
                     }}
                  >
                     {!this.props.id && (
                        <Button
                           id={'btnCancelar'}
                           icon={<IconButton icon={faTimesCircle} />}
                           onClick={this.cancelarClick}
                           style={{ width: 130, display: 'flex', padding: '4px 2px 2px 2px', height: 33 }}
                           title={
                              this.props.lang.formularioPadrao.cancelar +
                              ' ' +
                              (this.getState().incluindo
                                 ? this.props.lang.formularioPadrao.inclusao
                                 : this.props.lang.formularioPadrao.alteracoes)
                           }
                           text={
                              (this.getState().alterando && this.getState().formularioHabilitado) ||
                              this.getState().incluindo
                                 ? this.props.lang.formularioPadrao.cancelar
                                 : this.props.lang.formularioPadrao.fechar
                           }
                        />
                     )}

                     {this.getState().alterando &&
                        this.getState().podeAlterar &&
                        !this.getState().formularioHabilitado && (
                           <Button
                              id={'btnAlterar'}
                              icon={<IconButton icon={faEdit} />}
                              onClick={() => {
                                 this.setState({ formularioHabilitado: true });
                              }}
                              style={{ width: 130, display: 'flex', padding: '4px 2px 2px 2px', height: 33 }}
                              text={this.props.lang.formularioPadrao.alterar}
                              inProgressText={this.props.lang.formularioPadrao.salvando}
                           />
                        )}

                     {((this.getState().incluindo && this.getState().podeIncluir) ||
                        (this.getState().alterando &&
                           this.getState().podeAlterar &&
                           this.getState().formularioHabilitado)) && (
                        <Button
                           id={'btnSalvar'}
                           icon={<IconButton icon={faSave} />}
                           disabled={!this.getState().botaoSalvarHabilitado}
                           onClickAsync={this.salvar}
                           style={{ width: 130, display: 'flex', padding: '4px 2px 2px 2px', height: 33 }}
                           text={this.props.lang.formularioPadrao.salvar}
                           inProgressText={this.props.lang.formularioPadrao.salvando}
                        />
                     )}
                  </ButtonGroup>
               </div>
            )}
         </div>
      );
   }

   getFiltros() {
      return (
         <form
            style={{
               paddingTop: 8,
               paddingLeft: 10,
               paddingRight: 10,
            }}
            onSubmit={this.formConsultaSubmit}
            action='/'
            name='formConsulta'
            id='formConsulta'
         >
            {this.renderizarFiltros(this)}
            {this.props.renderizarFiltrosAdicionais && this.props.renderizarFiltrosAdicionais()}
         </form>
      );
   }

   getCabecalhos() {
      const isMobile = this.isMobile();

      var titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();
      if (!titulos || isMobile) {
         return null;
      }
      return (
         <div className='div-cabecalho-tabela-formulario-padrao'>
            <table className='cabecalho-tabela-formulario-padrao'>
               <thead>
                  <tr>
                     {titulos.map((item, index) => {
                        return (
                           <td
                              key={index}
                              className={item.className}
                              onClick={() => {
                                 if (item.orderby) {
                                    let ordenacao = item.orderby;
                                    if (ordenacao === this.getState().ordenacao) {
                                       ordenacao += ' desc';
                                    }
                                    this.setState({ ordenacao: ordenacao }, () => {
                                       this.filtrar();
                                    });
                                 }
                              }}
                              style={{ cursor: item.orderby ? 'pointer' : 'default', width: item.width }}
                           >
                              {item.titulo}
                           </td>
                        );
                     })}
                     {this.renderizarCabecaolhoAcoes()}
                  </tr>
               </thead>
            </table>
         </div>
      );
   }

   getLista() {
      const isMobile = this.isMobile();
      const titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();

      if (!titulos) {
         return null;
      }

      const tamanhos = titulos.map((i) => i.width);
      const classes = titulos.map((i) => i.className);
      const textoDosTitulos = titulos.map((i) => i.titulo);
      let result = null;

      if (isMobile) {
         result = this.getState().vazio ? (
            <table className='tabela-formulario-padrao'>
               <tbody>
                  <tr>
                     <td style={{ width: '100%', textAlign: 'center' }}>
                        <span>{this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}</span>
                     </td>
                  </tr>
               </tbody>
            </table>
         ) : (
            (result = this.getState().itens.map((item, rowIndex) => {
               return !item ? null : (
                  <div
                     key={rowIndex}
                     className={this.props.select ? 'noselect' : null}
                     style={{
                        cursor: this.props.select ? 'pointer' : 'default',
                        border: '1px solid #999',
                        borderRadius: 5,
                        marginBottom: 7,
                        marginRight: 10,
                        padding: 3,
                     }}
                     onDoubleClick={() => this.handleDoubleClick(item)}
                     onClick={() => this.handleClick(item)}
                  >
                     {[
                        this.getDadosDaTabela(item).map((dado, campoIndex) => {
                           return (
                              <div key={campoIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ display: 'table-cell' }}>
                                    <strong>{textoDosTitulos[campoIndex]}:&nbsp;</strong>
                                 </div>
                                 <div style={{ display: 'table-cell' }}>
                                    <span style={{ wordWrap: 'anywhere' }}>{dado}</span>
                                 </div>
                              </div>
                           );
                        }),
                        this.renderizarAcoes(item),
                     ]}
                  </div>
               );
            }))
         );
      } else {
         result = this.getState().vazio ? (
            <table className='tabela-formulario-padrao'>
               <tbody>
                  <tr>
                     <td style={{ width: '100%', textAlign: 'center' }}>
                        <span>{this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}</span>
                     </td>
                  </tr>
               </tbody>
            </table>
         ) : (
            <table className='tabela-formulario-padrao table-hover'>
               <tbody>
                  {this.getState().itens.map((item, rowIndex) => {
                     return !item ? null : (
                        <tr
                           key={rowIndex}
                           className={this.props.select ? 'noselect' : null}
                           style={{ cursor: this.props.select ? 'pointer' : 'default' }}
                           onDoubleClick={() => this.handleDoubleClick(item)}
                           onClick={() => this.handleClick(item)}
                        >
                           {[
                              this.getDadosDaTabela(item).map((dado, campoIndex) => {
                                 return (
                                    <td
                                       key={campoIndex}
                                       className={classes[campoIndex]}
                                       style={{ width: tamanhos[campoIndex] }}
                                    >
                                       {dado}
                                    </td>
                                 );
                              }),
                              this.renderizarAcoes(item),
                           ]}
                        </tr>
                     );
                  })}
               </tbody>
            </table>
         );
      }
      return result;
   }

   getFormulario() {
      return (
         <Row
            className={
               this.props.formularioClassName ? this.props.formularioClassName : 'justify-content-md-center mx-0'
            }
            style={{
               overflowY: this.props.overflowY ? this.props.overflowY : 'auto',
               overflowX: this.props.overflowX ? this.props.overflowX : 'hidden',
               paddingTop: 5,
            }}
         >
            <Col
               style={{
                  maxWidth: this.props.maxWidth ? this.props.maxWidth : 800,
                  minHeight: this.props.minHeight ? this.props.minHeight : 400,
               }}
            >
               <FieldsetStyled
                  disabled={!this.getState().incluindo && !this.getState().formularioHabilitado}
                  id={'fs-renderizarFormulario'}
                  className={this.getState().formularioHabilitado || this.getState().incluindo ? '' : 'disabled-form'}
               >
                  {this.renderizarFormulario(this)}
               </FieldsetStyled>
               <fieldset
                  disabled={this.getState().incluindo || this.getState().formularioHabilitado}
                  id={'fs-acoesDoFormulario'}
               >
                  {this.renderizarAcoesDoFormulario(this)}
               </fieldset>
            </Col>
         </Row>
      );
   }

   getNavegador() {
      return (
         <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px 2px 10px 10px' }}>
            {this.props.select && !this.getState().incluindo && (
               <div style={{ width: 150, textAlign: 'left' }}>
                  <Button
                     variant='secondary'
                     onClick={this.cancelarClick}
                     style={{ width: 150 }}
                     text={this.props.lang.formularioPadrao.fechar}
                  />
               </div>
            )}
            {this.getState().itens ? (
               <React.Fragment>
                  <div style={{ width: '100%', textAlign: 'left', paddingTop: 10, paddingLeft: 5 }}>
                     {this.getState().quantidadeTotalDeDados ? (
                        <div>
                           <span>Mostrando </span>
                           <span>{this.getState().quantidadeDeDados}</span>
                           <span> de </span>
                           <span>{this.getState().quantidadeTotalDeDados}</span>
                        </div>
                     ) : null}
                  </div>
                  <ButtonGroup className='mr-2' style={{ minWidth: 120 }}>
                     <Button
                        title={this.props.lang.formularioPadrao.carregarMais}
                        text=''
                        icon={<IconButton icon={faAngleDown} />}
                        variant='secondary'
                        onClick={() => this.navegar(1)}
                        disabled={!this.getState().podeAvancar}
                        style={{ cursor: this.getState().podeAvancar ? 'pointer' : 'not-allowed', padding: 0 }}
                     />
                     <Button
                        title={this.props.lang.formularioPadrao.carregarTodos}
                        text=''
                        icon={<IconButton icon={faAngleDoubleDown} />}
                        variant='secondary'
                        onClick={() => this.navegar(2)}
                        disabled={!this.getState().podeCarregarTodos}
                        style={{
                           cursor: this.getState().podeCarregarTodos ? 'pointer' : 'not-allowed',
                           padding: 0,
                        }}
                     />
                  </ButtonGroup>
               </React.Fragment>
            ) : null}
         </div>
      );
   }

   render() {
      var fn = () => {
         return (
            <div
               id='formularioPadrao'
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '100%',
                  overflowX: 'hidden',
                  width: '100%',
                  maxWidth: '100%',
                  height: '100%',
               }}
               onKeyDown={(e) => {
                  if (e.keyCode === 112) {
                     this.inserirClick();
                  }

                  if (e.keyCode === 115) {
                     document.getElementById('btnSalvar').click();
                  }
               }}
            >
               {!this.props.esconderTitulo && this.getTitulo()}
               {this.getState().navegando && this.getFiltros()}

               {this.getState().navegando && this.getCabecalhos()}

               {this.getState().navegando && <div className='div-tabela-formulario-padrao'>{this.getLista()}</div>}

               {(this.getState().incluindo || this.getState().alterando) &&
                  this.getState().mostrarFormulario &&
                  this.getFormulario &&
                  this.getFormulario()}
               {this.getState().navegando && this.getNavegador()}
            </div>
         );
      };

      if (this.props.modal) {
         return (
            <Modal
               show={true}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ inserindo: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  {fn()}
               </Modal.Body>
            </Modal>
         );
      } else {
         return fn();
      }
   }
}

export const BotaoAlterarItemDeCadastro = ({ onClick, title }) => {
   return (
      <IconButton
         title={title}
         style={{
            fontSize: 23,
            paddingTop: 2,
            marginLeft: 5,
            marginRight: 5,
            color: LayoutParams.colors.corDoTextoPadrao,
         }}
         cursor='pointer'
         icon={faExternalLinkAlt}
         onClick={onClick}
      />
   );
};

export const BotaoExcluirItemDeCadastro = ({ onClick, title }) => {
   return (
      <IconButton
         title={title}
         style={{
            fontSize: 23,
            paddingTop: 2,
            marginLeft: 5,
            marginRight: 5,
            color: LayoutParams.colors.corDoTextoPadrao,
         }}
         cursor='pointer'
         icon={faTrashAlt}
         onClick={onClick}
      />
   );
};

const FieldsetStyled = styled.fieldset`
   input:disabled,
   input[disabled='disabled'],
   button:disabled,
   button[disabled='disabled'],
   select:disabled,
   select[disabled='disabled'],
   img:disabled,
   img[disabled='disabled'] {
      cursor: not-allowed !important;
   }
`;
