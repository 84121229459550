import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from './../FormularioPadrao';
import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import { updateState } from '../../utils/Functions';
import TipoDeContaDeEmpresaController from '../../controllers/Neos/TipoDeContaDeEmpresaController';

export default class TipoDeContaDeEmpresaView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true };
      this.controller = new TipoDeContaDeEmpresaController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeTipoDeContaDeEmpresa = lang.telaDeCadastroDeTipoDeContaDeEmpresa;
      return (
         <React.Fragment>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               {/* <Col sm={5} md={5} lg={5}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeContaDeEmpresa.situacao}</Label>
                     <Select
                        as='select'
                        name='situacao'
                        defaultValue={
                           this.state.itemSelecionado.situacao ? this.state.itemSelecionado.situacao.id : null
                        }
                        options={[
                           { id: 1, descricaoDaSituacao: lang.situacaoDeTipoDeEquipamento.ativo },
                           { id: 2, descricaoDaSituacao: lang.situacaoDeTipoDeEquipamento.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricaoDaSituacao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
                */}
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeContaDeEmpresa.nivel}</Label>
                     <Select
                        as='select'
                        name='nivel'
                        defaultValue={this.state.itemSelecionado.nivel ? this.state.itemSelecionado.nivel.id : null}
                        options={[
                           { id: 1, descricaoDoNivel: lang.nivelDeTipoDeContaDeEmpresa.acessoCompleto },
                           { id: 2, descricaoDoNivel: lang.nivelDeTipoDeContaDeEmpresa.acessoLimitado },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricaoDoNivel}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nivel = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeTipoDeContaDeEmpresa.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.state.itemSelecionado.nivel && this.state.itemSelecionado.nivel.id !== 1 && (
               <Fragment>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeTipoDeContaDeEmpresa.quantidadeMaximaDeLaudos}</Label>
                           <IntegerInput
                              defaultValue={this.state.itemSelecionado.quantidadeMaximaDeLaudosQuePodemSerEmitidos}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.quantidadeMaximaDeLaudosQuePodemSerEmitidos = e.value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeTipoDeContaDeEmpresa.quantidadeDeDiasDeValidade}</Label>
                           <IntegerInput
                              defaultValue={this.state.itemSelecionado.quantidadeDeDiasDeValidade}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.quantidadeDeDiasDeValidade = e.value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeTipoDeContaDeEmpresa.renovacaoDaQuantidadeDeDiasDeValidade}</Label>
                           <IntegerInput
                              defaultValue={this.state.itemSelecionado.renovacaoDaQuantidadeDeDiasDeValidade}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.renovacaoDaQuantidadeDeDiasDeValidade = e.value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </Fragment>
            )}
            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeTipoDeContaDeEmpresa.titulo}
            url={'/tipodecontadeempresa'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1081, 1082, 1083, 1084]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
               nivel: { id: 0 },
            }}
         />
      );
   }
}
