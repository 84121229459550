import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class TipoDeEnsaioController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'TipoDeEnsaioController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeTipoDeEnsaio.nome, width: '70%', orderby: 'Nome' },
         { titulo: this.lang.telaDeCadastroDeTipoDeEnsaio.categria, width: '30%', orderby: 'Sigla' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nome, item.categoria ? item.categoria.descricao : null];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.categoria) {
            showError(this.lang.telaDeCadastroDeTipoDeEnsaio.mensagens.informeACategoria);
            reject();
            return;
         }

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeTipoDeEnsaio.mensagens.informeONome);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            categoria: item.categoria ? item.categoria.id : null,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
