import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { LayoutParams } from '../../config/LayoutParams';
import LoginView from '../User/LoginView';
import LogoffView from '../User/LogoffView';
import NotFoundView from '../NotFoundView';
import ErrorView from '../ErrorView';
import MainLayout from '../../components/MainLayout';
import DemonstracaoDeComponentesView from '../DemonstracaoDeComponentesView';
import HomeView from '../HomeView';
import FilialView from './FilialView';
import DepartamentoView from './DepartamentoView';
//import EquipamentoDeClienteView from './EquipamentoDeClienteView';
import ParametroDoSistemaView from '../Cliente/ParametroDoSistemaView';
import AboutView from '../AboutView';
import TermosDeUsoView from '../TermosDeUso';
import OrdemDeServicoParaClienteView from './OrdemDeServicoParaClienteView';
import AccountView from '../AccountView';
import EquipamentosSobTeste from './EquipamentoSobTesteView';

export default class LayoutCliente extends React.Component {
   render() {
      let sessionManager = this.props.sessionManager;
      var lang = this.props.lang;
      this.api = this.props.api;
      return (
         <HashRouter>
            <MainLayout
               menuItems={this.props.menus.getMenusDeCliente(sessionManager, lang)}
               imgHomeLogo={LayoutParams.imgHomeLogo}
               login={this.props.login}
               lang={lang}
               mostrarDadosDaConta={true}
               alterarIdioma={this.props.alterarIdioma}
               api={this.api}
            >
               <Switch>
                  <Route exact path='/' render={() => <HomeView api={this.api} lang={lang} />} />
                  <Route
                     path='/login'
                     render={() => <LoginView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route path='/logoff' render={() => <LogoffView api={this.api} lang={lang} />} />
                  <Route
                     path='/error'
                     render={() => <ErrorView api={this.api} lang={lang} error={this.state.error} />}
                  />
                  <Route path='/about' render={() => <AboutView api={this.api} lang={lang} />} />
                  <Route
                     path='/9999'
                     render={() => (
                        <DemonstracaoDeComponentesView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  {/* <Route
                     path='/1131'
                     render={() => (
                        <EquipamentoDeClienteView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  /> */}

                  <Route
                     path='/account'
                     render={() => (
                        <AccountView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />
                  <Route
                     path='/1041'
                     render={() => (
                        <ParametroDoSistemaView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1161'
                     render={() => (
                        <FilialView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1171'
                     render={() => <DepartamentoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1311'
                     render={() => (
                        <OrdemDeServicoParaClienteView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/90003'
                     render={() => <TermosDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1131'
                     render={() => <EquipamentosSobTeste api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route render={() => <NotFoundView lang={lang} />} />
               </Switch>
            </MainLayout>
         </HashRouter>
      );
   }
}
