export const getParametrosParaCadastroDeCertificado = (analisador) => {
   if (!analisador) {
      return;
   }

   let result = [];
   let sigla = analisador.tipoDeEquipamento.sigla;

   if (sigla === 'ASE100') {
      result = [
         { id: 0, descricao: 'Tensão (V)' },
         { id: 1, descricao: 'Corrente Funcional (A)' },
         { id: 2, descricao: 'Corrente de Fuga (uA)' },
         { id: 3, descricao: 'Resistência de Aterramento @ 200mA (Ω)' },
         { id: 4, descricao: 'Resistência de Isolação @ 500V (MΩ)' },
      ];
   } else if (sigla === 'SMP100') {
      result = [
         { id: 0, descricao: 'Frequência do ECG (BPM)' },
         { id: 1, descricao: 'Amplitude do ECG (mV)' },
         { id: 2, descricao: 'Forma de Onda - Triangular (Hz)' },
         { id: 3, descricao: 'Forma de Onda - Quadrada (Hz)' },
         { id: 4, descricao: 'Forma de Onda - Senoidal (Hz)' },
         { id: 5, descricao: 'Frequência Respiratória (BrPM)' },
         { id: 6, descricao: 'Temperatura (ºC)' },
         { id: 7, descricao: 'Pressão Não-Invasiva Manômetro(mmHg)' },
         { id: 8, descricao: 'Tempo (s)' },
         { id: 9, descricao: 'Pressão Invasiva IBP (mmHg)' },
      ];
   } else if (sigla === 'SOP100' || sigla === 'MOX100') {
      result = [
         { id: 0, descricao: 'Oximetria (%)' },
         { id: 1, descricao: 'Frequência do ECG (BPM)' },
      ];
   } else if (sigla === 'AVM100') {
      result = [
         { id: 0, descricao: 'Fluxo (L/min)' },
         { id: 1, descricao: 'Pressão no Canal (mbar)' },
         { id: 2, descricao: 'Pressão Altar (bar)' },
         { id: 3, descricao: 'Pressão Diferencial (mbar)' },
         { id: 4, descricao: 'Volume (L)' },
         { id: 5, descricao: 'Tempo Expiratório (s)' },
         { id: 6, descricao: 'Tempo Inspiratório (s)' },
         { id: 7, descricao: 'Oxigênio (%)' },
      ];
   } else if (sigla === 'SIM200' || sigla === 'SIM300') {
      result = [
         { id: 0, descricao: 'Frequência do ECG (BPM)' },
         { id: 1, descricao: 'Amplitude do ECG (mV)' },
         { id: 2, descricao: 'Forma de Onda - Triangular (Hz)' },
         { id: 3, descricao: 'Forma de Onda - Quadrada (Hz)' },
         { id: 4, descricao: 'Forma de Onda - Senoidal (Hz)' },
         { id: 5, descricao: 'Frequência Respiratória (BrPM)' },
      ];
   } else if (sigla === 'JAU200') {
      result = [
         { id: 0, descricao: 'Energia (J)' },
         { id: 1, descricao: 'Sincronismo (ms)' },
         { id: 2, descricao: 'Tempo de carga (s)' },
         { id: 3, descricao: 'Frequência do ECG (BPM)' },
         { id: 4, descricao: 'Amplitude do ECG (mV)' },
         { id: 5, descricao: 'Frequência do Marcapasso (ppm)' },
         { id: 6, descricao: 'Amplitude do Marcapasso (mA)' },
         { id: 7, descricao: 'Largura do Pulso do Marcapasso (ms)' },
         { id: 8, descricao: 'Resistência de Carga (Ω)' },
         { id: 9, descricao: 'Forma de Onda - Triangular (Hz)' },
         { id: 10, descricao: 'Forma de Onda - Quadrada (Hz)' },
         { id: 11, descricao: 'Forma de Onda - Senoidal (Hz)' },
         { id: 12, descricao: 'Frequência Respiratória (BrPM)' },
      ];
   } else if (sigla === 'JAU130') {
      result = [
         { id: 0, descricao: 'Energia (J)' },
         { id: 1, descricao: 'Sincronismo (ms)' },
         { id: 2, descricao: 'Tempo de carga (s)' },
         { id: 3, descricao: 'Frequência do ECG (BPM)' },
         { id: 4, descricao: 'Amplitude do ECG (mV)' },
         { id: 5, descricao: 'Frequência do Marcapasso (ppm)' },
         { id: 6, descricao: 'Amplitude do Marcapasso (mA)' },
         { id: 7, descricao: 'Largura do Pulso do Marcapasso (ms)' },
         { id: 8, descricao: 'Resistência de Carga (Ω)' },
         { id: 9, descricao: 'Forma de Onda - Triangular (Hz)' },
         { id: 10, descricao: 'Forma de Onda - Quadrada (Hz)' },
         { id: 11, descricao: 'Forma de Onda - Senoidal (Hz)' },
      ];
   } else if (sigla === 'APE100') {
      result = [
         { id: 0, descricao: 'Pressão (mmHg)' },
         { id: 1, descricao: 'Pressão de Avanço (mmHg)' },
         { id: 2, descricao: 'Pressão de Retorno (mmHg)' },
         { id: 3, descricao: 'Tempo (s)' },
      ];
   } else if (sigla === 'ABI100' || sigla === 'ABI200') {
      result = [
         { id: 0, descricao: 'Potência de Corte (W)' },
         { id: 1, descricao: 'Potência de Coagulação (W)' },
         { id: 2, descricao: 'Resistência (Ω)' },
         { id: 3, descricao: 'Corrente (mA)' },
      ];
   } else if (sigla === 'SPK100' || sigla === 'SMP200') {
      result = [
         { id: 0, descricao: 'Frequência do ECG (BPM)' },
         { id: 1, descricao: 'Amplitude do ECG (mV)' },
         { id: 2, descricao: 'Forma de Onda - Triangular (Hz)' },
         { id: 3, descricao: 'Forma de Onda - Quadrada (Hz)' },
         { id: 4, descricao: 'Forma de Onda - Senoidal (Hz)' },
         { id: 5, descricao: 'Frequência Respiratória (BrPM)' },
         { id: 6, descricao: 'Temperatura (ºC)' },
         { id: 7, descricao: 'Pressão Não-Invasiva Manômetro(mmHg)' },
         { id: 8, descricao: 'Tempo (s)' },
         { id: 9, descricao: 'Pressão Invasiva IBP (mmHg)' },
      ];
   } else if (sigla === 'MDC100') {
      const parametros = ['L/min', 'Temperatura corporal', 'Temperatura do injetável'];
      result = [
         { id: 0, descricao: '2.5 L/min', parametros: parametros },
         { id: 1, descricao: '5.0 L/min' },
         { id: 2, descricao: '7.5 L/min' },
         { id: 3, descricao: '10.0 L/min' },
      ];
   }
   return result;
};
