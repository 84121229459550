import IconButton from './IconButton';
import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import '../contents/css/text-input.css';

const corDoIcone = '#666';

export default class PasswordInput extends React.Component {
    render() {
        return (
            <InputGroup style={{ flexWrap: 'nowrap' }}>
                <FormControl
                    type='password'
                    className={'text-input' + (this.props.className ? ' ' + this.props.className : '')}
                    style={this.props.style}
                    name={this.props.name}
                    id={this.props.id}
                    key={this.props.key}
                    onChange={this.props.onChange}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.defaultValue}
                    readOnly={this.props.readOnly}
                />
                {(this.props.appendText || this.props.appendIcon) && (
                    <InputGroup.Append    
                    className='hide-when-readonly'                    
                    >
                        <InputGroup.Text onClick={this.props.onAppendClick}
                            title={this.props.appendTitle}
                            style={{ cursor: this.props.onAppendClick ? 'pointer' : null}}>
                            <React.Fragment>
                                {this.props.appendIcon &&
                                <div style={{marginTop: 0}}>
                                    <IconButton
                                        style={{
                                            fontSize: 20,
                                            paddingTop: 3,
                                            color: corDoIcone
                                        }}
                                        icon={this.props.appendIcon}
                                    /></div>
                                }
                                {this.props.appendText && <span>{this.props.appendText}</span>}
                            </React.Fragment>
                        </InputGroup.Text>
                    </InputGroup.Append>
                )}
            </InputGroup>
        );
    }
}
