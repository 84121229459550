import { BaseController } from '../BaseController';

export default class EquipamentoController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'EquipamentoController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.tipoDeEquipamento,
            width: '15%',
            orderby: 'tipoDeEquipamento',
         },
         { titulo: this.lang.telaDeCadastroDeEquipamento.numeroDeSerie, width: '15%', orderby: 'numeroDeSerie' },
         { titulo: this.lang.telaDeCadastroDeEquipamento.fabricante, width: '15%', orderby: 'fabricante' },
         { titulo: this.lang.telaDeCadastroDeEquipamento.modelo, width: '15%', orderby: 'modelo' },
         { titulo: this.lang.telaDeCadastroDeEquipamento.empresa, width: '20%', orderby: 'empresa' },
         { titulo: this.lang.telaDeCadastroDeEquipamento.cliente, width: '20%', orderby: 'cliente' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.tipoDeEquipamento,
         item.numeroDeSerie,
         item.fabricante,
         item.modelo,
         item.empresa ? item.empresa.nomeFantasia : null,
         item.cliente ? item.cliente.apelidoOuNomeFantasia : null,
      ];
   };  
}
