import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { LayoutParams } from '../../config/LayoutParams';
import LoginView from '../User/LoginView';
import LogoffView from '../User/LogoffView';
import NotFoundView from '../NotFoundView';
import ErrorView from '../ErrorView';
import MainLayout from '../../components/MainLayout';
import DemonstracaoDeComponentesView from '../DemonstracaoDeComponentesView';
import HomeView from '../HomeView';
import PerfilDeUsuarioView from './PerfilDeUsuarioView';
import RotinaDoSistemaView from './../RotinaDoSistemaView';
import ParametroDoSistemaView from './ParametroDoSistemaView';
import TipoDeEquipamentoView from './TipoDeEquipamentoView';
import CargoNeosView from './CargoNeosView';
import TipoDeEmpresaView from './TipoDeEmpresaView';
import TipoDeContaDeEmpresaView from './TipoDeContaDeEmpresaView';
import EmpresaView from './EmpresaView';
import CidadeView from '../CidadeView';
import FuncionarioNeosView from './FuncionarioNeosView';
import SequenciaView from './../Sequencias/SequenciaView';
import SequenciaABIView from './../Sequencias/SequenciaABIView';
import SequenciaAPEView from './../Sequencias/SequenciaAPEView';
import SequenciaSOPView from './../Sequencias/SequenciaSOPView';
import SequenciaJAUMPView from './../Sequencias/SequenciaJAUMPView';
import SequenciaAVMView from './../Sequencias/SequenciaAVMView';
import SequenciaJAUView from './../Sequencias/SequenciaJAUView';
import SequenciaSIMView from './../Sequencias/SequenciaSIMView';
import SequenciaASEView from './../Sequencias/SequenciaASEView';
import SequenciaSMPView from './../Sequencias/SequenciaSMPView';
import SequenciaSPKView from './../Sequencias/SequenciaSPKView';
import SequenciaMDCView from './../Sequencias/SequenciaMDCView';
import TermoDeUsoView from './TermoDeUsoView';
import AboutView from '../AboutView';
import LogView from '../LogView';
import TermosDeUsoView from '../TermosDeUso';
import AnalisadorSimuladorNeosView from './AnalisadorSimuladorNeosView';
import RelatorioDeEquipamentoDeCliente from './Relatorios/RelatorioDeEquipamentoDeClienteView';
import RelatoriosView from './Relatorios/Relatorios';
import RelatorioDeUtilizacaoView from './Relatorios/RelatorioDeUtilizacaoView';
import RelatorioDeCertificadosAVencerView from './Relatorios/RelatorioDeCertificadosAVencerView';
import TipoDeEnsaioView from './../Sequencias/TipoDeEnsaioView';
import EquipamentoView from './EquipamentoView';

export default class LayoutNeos extends React.Component {
   render() {
      let sessionManager = this.props.sessionManager;
      var lang = this.props.lang;
      this.api = this.props.api;
      return (
         <HashRouter>
            <MainLayout
               menuItems={this.props.menus.getMenusNeos(sessionManager, lang)}
               imgHomeLogo={LayoutParams.imgHomeLogo}
               login={this.props.login}
               lang={lang}
               alterarIdioma={this.props.alterarIdioma}
               mostrarDadosDaConta={false}
               api={this.api}
            >
               <Switch>
                  <Route exact path='/' render={() => <HomeView api={this.api} lang={lang} />} />
                  <Route
                     path='/login'
                     render={() => <LoginView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route path='/logoff' render={() => <LogoffView api={this.api} lang={lang} />} />
                  <Route
                     path='/error'
                     render={() => <ErrorView api={this.api} lang={lang} error={this.state.error} />}
                  />
                  <Route path='/components' render={() => <DemonstracaoDeComponentesView api={this.api} />} />
                  <Route path='/about' render={() => <AboutView api={this.api} lang={lang} />} />

                  <Route
                     path='/1011'
                     render={() => <PerfilDeUsuarioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1021'
                     render={() => (
                        <FuncionarioNeosView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1031'
                     render={() => <RotinaDoSistemaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1041'
                     render={() => (
                        <ParametroDoSistemaView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1051'
                     render={() => <TipoDeEquipamentoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1061'
                     render={() => (
                        <CargoNeosView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1071'
                     render={() => <TipoDeEmpresaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1081'
                     render={() => (
                        <TipoDeContaDeEmpresaView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1101'
                     render={() => (
                        <EmpresaView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1151'
                     render={() => (
                        <AnalisadorSimuladorNeosView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1111'
                     render={() => <CidadeView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/9999'
                     render={() => (
                        <DemonstracaoDeComponentesView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1041'
                     render={() => (
                        <CargoNeosView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/sequencia'
                     render={() => <SequenciaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1381'
                     render={() => <TermoDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1181'
                     render={() => <SequenciaABIView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1191'
                     render={() => <SequenciaAPEView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1221'
                     render={() => <SequenciaAVMView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1281'
                     render={() => <SequenciaSOPView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1241'
                     render={() => <SequenciaJAUMPView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1231'
                     render={() => <SequenciaJAUView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1261'
                     render={() => <SequenciaSIMView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1211'
                     render={() => <SequenciaASEView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1271'
                     render={() => <SequenciaSMPView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1441'
                     render={() => <SequenciaSPKView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1451'
                     render={() => <SequenciaMDCView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/1381'
                     render={() => <TermosDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/9001'
                     render={() => <LogView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/report'
                     render={() => <RelatoriosView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/equipamento-de-cliente-report'
                     render={() => (
                        <RelatorioDeEquipamentoDeCliente api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/utilizacao-report'
                     render={() => (
                        <RelatorioDeUtilizacaoView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/certificados-a-vencer-report'
                     render={() => (
                        <RelatorioDeCertificadosAVencerView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                        />
                     )}
                  />

                  <Route
                     path='/1411'
                     render={() => <TipoDeEnsaioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1411'
                     render={() => <TipoDeEnsaioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1421'
                     render={() => <EquipamentoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route render={() => <NotFoundView lang={lang} />} />
               </Switch>
            </MainLayout>
         </HashRouter>
      );
   }
}
