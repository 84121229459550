import React from 'react';
import { LayoutParams } from '../config/LayoutParams';
import './../contents/css/home-logo.css';

export const LogoHeaderHome = () => {
   return (
      <div className='div-home-logo'>
         <img alt='' src={LayoutParams.imgLoginLogo} className='home-logo' />
      </div>
   );
};
