import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import IconButton from './IconButton';
import { faTrashAlt, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { showConfirm } from './Messages';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadBase64File } from '../utils/Functions';

export default class Document extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         descricao: this.props.defaultValue ? this.props.defaultValue.descricao : null,
      };
   }

   baixar = () => {
      console.log(this.props.defaultValue);
      this.props.api
         .get('/file/base64/' + this.props.defaultValue.nome)
         .then((result) => {
            downloadBase64File(
               result,
               this.props.defaultValue.descricao ? this.props.defaultValue.descricao : this.props.defaultValue.nome,
               this.props.defaultValue.tipo
            );
         })
         .catch((e) => {});
   };

   render() {
      return (
         <div>
            <InputGroup>
               <Form.Control type='text' defaultValue={this.state.descricao} disabled={true} />
               <InputGroup.Append style={{}} className='hide-when-readonly'>
                  {!this.props.defaultValue ? (
                     <InputGroup.Text style={{ margin: '0', padding: '0' }}>
                        <Form.Label style={{ display: 'flex', height: 36, width: 48.5, cursor: 'pointer', margin: 0 }}>
                           <Form.File
                              accept={
                                 this.props.accept
                                    ? this.props.accept
                                    : 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                              }
                              onChange={(e) => this.onChange(e)}
                              style={{ position: 'absolute', top: -1000 }}
                              tabIndex={-1}
                           />

                           <FontAwesomeIcon
                              style={{
                                 fontSize: 20,
                                 margin: 'auto',
                                 color: '#666'
                              }}
                              icon={faFolderOpen}
                           />
                        </Form.Label>
                     </InputGroup.Text>
                  ) : (
                     <InputGroup.Text style={{ cursor: 'pointer', padding: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                           <div
                              title={this.props.lang ? this.props.lang.excluirArquivo : null}
                              onClick={(e) => this.excluir(e)}
                              style={{ display: 'flex', height: '100%', width: 40 }}
                           >
                              <div
                                 style={{ height: 24, width: 30, display: 'table-cell', paddingTop: 1, margin: 'auto' }}
                              >
                                 <IconButton
                                    style={{
                                       fontSize: 20,
                                       color: '#666',
                                       margin: 'auto',
                                    }}
                                    icon={faTrashAlt}
                                 />
                              </div>
                           </div>
                           <div
                              title={this.props.lang ? this.props.lang.baixarArquivo : null}
                              onClick={(e) => this.baixar()}
                              style={{
                                 display: 'flex',
                                 borderLeftStyle: 'solid',
                                 borderLeftWidth: 1,
                                 borderLeftColor: '#ced4da',
                                 height: '100%',
                                 width: 40,
                              }}
                           >
                              <div
                                 style={{ height: 24, width: 30, display: 'table-cell', paddingTop: 1, margin: 'auto' }}
                              >
                                 <IconButton
                                    style={{
                                       fontSize: 20,
                                       color: '#666',
                                    }}
                                    icon={faDownload}
                                 />
                              </div>
                           </div>
                        </div>
                     </InputGroup.Text>
                  )}
               </InputGroup.Append>
            </InputGroup>
         </div>
      );
   }

   onChange = (event) => {
      var self = this;
      var tg = event.target;
      if (event.target.files.length > 0) {
         let file = event.target.files[0];
         var fileReader = new FileReader();
         fileReader.addEventListener(
            'load',
            () => {
               var input = {
                  tipo: this.props.mimeType ? this.props.mimeType : file.type,
                  base64: fileReader.result,
                  descricao: file.name,
                  temporario: this.props.temporary,
               };
               self.props.api.post('/file', input).then((result) => {
                  this.setState({
                     descricao: result.descricao,
                  });
                  if (self.props.onChange) {
                     self.props.onChange(result);
                  }
                  tg.value = null;
               });
            },
            false
         );
         fileReader.readAsDataURL(file);
      }
   };

   excluir = (event) => {
      var tg = event.target;
      showConfirm(this.props.lang.desejaRealmenteExcluirOArquivo, () => {
         if (this.props.onChange) {
            this.props.onChange(null);
         }
         this.setState({
            descricao: null,
         });
         tg.value = null;
      });
   };
}
