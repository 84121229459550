import { Row, Col, FormGroup, InputGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from './../FormularioPadrao';
import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import CepInput from '../../components/CepInput';
import DateInput from '../../components/DateInput';
import SubCadastro from '../../components/SubCadastro';
import Avatar from '../../components/Avatar';
import IntegerInput from '../../components/IntegerInput';
import Document from '../../components/Document';
import { Filler } from '../../components/Filler';
import {
   updateState,
   buildQueryString,
   getEnderecoCompleto,
   generatePassword,
   dateToString,
   numberToCurrencyString,
} from '../../utils/Functions';
import EmpresaController from '../../controllers/Neos/EmpresaController';
import TipoDeEmpresaView from './TipoDeEmpresaView';
import TipoDeContaDeEmpresaView from './TipoDeContaDeEmpresaView';
import CheckBox from '../../components/CheckBox';
import { IconeWhatsApp } from '../../components/IconeWhatsApp';
import CidadeView from '../CidadeView';
import PaisView from '../PaisView';
import PasswordInput from '../../components/PasswordInput';
import { faKey, faTrash } from '@fortawesome/free-solid-svg-icons';
import EmailInput from '../../components/EmailInput';
import { showConfirm } from '../../components/Messages';
import TipoDeEquipamentoView from './TipoDeEquipamentoView';
import DecimalInput from '../../components/DecimalInput';
import Button from '../../components/Button';
import AnalisadorSimuladorNeosView from '../Neos/AnalisadorSimuladorNeosView';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { getParametrosParaCadastroDeCertificado } from '../../utils/ParametrosParaCadastroDeCertificado';
import SessionManager from './../../models/SessionManager';
import CnpjInput from '../../components/CnpjInput';
import IconButton from '../../components/IconButton';

const url = '/empresa';

export default class EmpresaView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         foto: null,
         mostrarEndereco: true,
         url: url,
         enviarNovaSenhaPorEmail: false,
         mostrarPlanilha: true,
         mostrarDadosDoEquipamento: true,
         mostrarTendencia: true,
      };
      this.controller = new EmpresaController(this);
      this.lang = this.props.lang;
      let sessionManager = new SessionManager();
      this.login = sessionManager.getLogin();
   }

   camposPrincipais = () => {
      return (
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col sm={2} md={2} lg={2}>
                        <Label>{this.lang.codigo}</Label>
                        <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                     </Col>
                     <Col sm={2} md={2} lg={2}>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.situacao}</Label>
                           <Select
                              as='select'
                              name='situacao'
                              defaultValue={
                                 this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id
                              }
                              options={[
                                 { id: 1, descricao: this.lang.situacaoDeTipoDeEquipamento.ativo },
                                 { id: 2, descricao: this.lang.situacaoDeTipoDeEquipamento.inativo },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = i;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{this.lang.endereco.pais}</Label>
                           <Select
                              name={'pais'}
                              defaultValue={this.state.itemSelecionado.pais}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.pais = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return <PaisView api={this.props.api} select={select} lang={this.props.lang} />;
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) => '/pais' + buildQueryString(2, null, 'id', { Searchable: text })}
                           />
                        </FormGroup>
                     </Col>

                     <Col sm={3} xs={3} md={3}>
                        <Label>{this.lang.telaDeCadastroDeEmpresa.dataDeCadastro}</Label>
                        <DateInput
                           defaultValue={
                              this.state.itemSelecionado.dataDeCadastro
                                 ? this.state.itemSelecionado.dataDeCadastro
                                 : new Date()
                           }
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.dataDeCadastro = value;
                              });
                           }}
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col sm={12} md={12} lg={12}>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.razaoSocial}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.razaoSocial}
                              upperCase={true}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.razaoSocial = e.target.value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </Col>

               <Col style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20 }}>
                  <Avatar
                     lang={this.props.lang}
                     api={this.props.api}
                     image={this.state.itemSelecionado.foto}
                     onChange={(image) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.foto = image;
                        });
                     }}
                  />
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.nomeFantasia}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nomeFantasia}
                        upperCase={true}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nomeFantasia = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>
                        {!this.state.itemSelecionado.pais || this.state.itemSelecionado.pais.codigo === '+55'
                           ? this.lang.telaDeCadastroDeEmpresa.cnpj
                           : this.lang.telaDeCadastroDeEmpresa.documentoDeIdentificacaoDaEmpresa}
                     </Label>

                     {!this.state.itemSelecionado.pais || this.state.itemSelecionado.pais.codigo === '+55' ? (
                        <CnpjInput
                           defaultValue={this.state.itemSelecionado.cnpj}
                           name={'tipoDeDocumento'}
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.cnpj = e.formattedValue;
                              });
                           }}
                        />
                     ) : (
                        <TextInput
                           defaultValue={this.state.itemSelecionado.cnpj}
                           name={'tipoDeDocumento'}
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.cnpj = e.target.value;
                              });
                           }}
                           onBlur={this.controller.verificarSeCnpjJaExiste}
                        />
                     )}
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeEmpresa.senha}</Label>
                     {!this.state.esconderSenha && (
                        <React.Fragment>
                           <PasswordInput
                              defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                              readOnly={this.state.itemSelecionado.senha && !this.state.itemSelecionado.senhaAlterada}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.senha = e.target.value;
                                    state.itemSelecionado.senhaAlterada = true;
                                    state.itemSelecionado.enviarNovaSenhaPorEmail = false;
                                 });
                              }}
                              appendIcon={this.state.itemSelecionado.senha ? faTrash : faKey}
                              appendTitle={this.props.lang.gerarNovaSenha}
                              onAppendClick={() => {
                                 if (this.state.itemSelecionado.senha) {
                                    showConfirm(
                                       this.props.lang.desejaLimparASenha,
                                       () => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.senha = null;
                                             state.itemSelecionado.senhaAlterada = true;
                                             state.esconderSenha = true;
                                          }).then(() => {
                                             updateState(this, (state) => {
                                                state.esconderSenha = false;
                                             });
                                          });
                                       },
                                       null,
                                       this.props.lang.sim,
                                       this.props.lang.nao
                                    );
                                 } else {
                                    showConfirm(
                                       this.props.lang.desejaRealmenteGerarUmaNovaSenha,
                                       () => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.senha = generatePassword(true, 8);
                                             state.itemSelecionado.senhaAlterada = true;
                                             state.itemSelecionado.enviarNovaSenhaPorEmail = true;
                                             state.esconderSenha = true;
                                          }).then(() => {
                                             updateState(this, (state) => {
                                                state.esconderSenha = false;
                                             });
                                          });
                                       },
                                       null,
                                       this.props.lang.sim,
                                       this.props.lang.nao
                                    );
                                 }
                              }}
                           />
                           {this.state.itemSelecionado.senhaAlterada && this.state.itemSelecionado.senha && (
                              <CheckBox
                                 defaultChecked={this.state.itemSelecionado.enviarNovaSenhaPorEmail ? true : false}
                                 name='enviarNovaSenhaPorEmail'
                                 label={this.props.lang.enviarANovaSenhaPorEmail}
                                 onChange={(checked) => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.enviarNovaSenhaPorEmail = checked;
                                    });
                                 }}
                              />
                           )}
                        </React.Fragment>
                     )}
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.email}</Label>
                     <EmailInput
                        defaultValue={this.state.itemSelecionado.email}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.email = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.tipoDeEmpresa}</Label>
                     <Select
                        name={'tipodeempresa'}
                        defaultValue={
                           this.state.itemSelecionado.tipoDeEmpresa ? this.state.itemSelecionado.tipoDeEmpresa : null
                        }
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.tipoDeEmpresa = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <TipoDeEmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/tipodeempresa' + buildQueryString(2, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.quantidadeMaximaDeUsuarios}</Label>
                     <IntegerInput
                        defaultValue={this.state.itemSelecionado.quantidadeMaximaDeUsuarios}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.quantidadeMaximaDeUsuarios = e.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </React.Fragment>
      );
   };

   telefones = () => {
      return (
         <SubCadastro
            titulo={this.lang.telaDeCadastroDeEmpresa.telefones}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.telefones}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = {
                        pais: null,
                        ddd: null,
                        numero: null,
                        temWhatsApp: false,
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirTelefone}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.telefones[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarTelefone}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.telefones.splice(index, 1);
               });
            }}
            colunas={() => {
               return [{ titulo: '', width: '100%', className: null }];
            }}
            linha={(item) => {
               return [
                  <div style={{ display: 'flex' }}>
                     <div>{item.pais + ' ' + item.ddd + ' ' + item.numero + ' '} </div>
                     <div>{item.temWhatsApp ? <IconeWhatsApp key={'iconeWhatsApp'} /> : null}</div>
                  </div>,
               ];
            }}
            formulario={() => {
               return (
                  <div>
                     <Row>
                        <Col>
                           <div style={{ display: 'flex' }}>
                              <FormGroup>
                                 <Label>{this.lang.telefone.pais}</Label>
                                 <TextInput
                                    name='pais'
                                    defaultValue={this.state.telefoneSelecionado.pais}
                                    style={{ width: '60px' }}
                                    maxLength={3}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.pais = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.ddd}</Label>
                                 <TextInput
                                    name='ddd'
                                    defaultValue={this.state.telefoneSelecionado.ddd}
                                    style={{ width: '60px' }}
                                    maxLength={2}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.ddd = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.numero}</Label>
                                 <TextInput
                                    name='telefone'
                                    defaultValue={this.state.telefoneSelecionado.numero}
                                    maxLength={9}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.numero = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup
                                 controlId='chktemWhatsApp'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={this.state.telefoneSelecionado.temWhatsApp ? true : false}
                                    name='WhatsApp'
                                    label='WhatsApp'
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.temWhatsApp = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </div>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   enderecos = () => {
      return (
         <SubCadastro
            titulo={this.lang.telaDeCadastroDeEmpresa.enderecos}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.enderecos}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = {
                        endereco: {},
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirEndereco}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.enderecos[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarEndereco}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.enderecos.splice(index, 1);
               });
            }}
            colunas={() => {
               return [
                  {
                     titulo: this.lang.telaDeCadastroDeEmpresa.enderecoCompleto,
                     width: '100%',
                     className: 'multilineTableData',
                  },
               ];
            }}
            linha={(item) => {
               return [getEnderecoCompleto(item.endereco)];
            }}
            formulario={() => {
               return this.state.mostrarEndereco && this.state.enderecoSelecionado ? (
                  <div>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{this.lang.endereco.pais}</Label>
                              <Select
                                 name={'pais'}
                                 defaultValue={this.state.enderecoSelecionado.endereco.pais}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.enderecoSelecionado.endereco.pais = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return <PaisView api={this.props.api} select={select} lang={this.props.lang} />;
                                 }}
                                 asws={true}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/pais' + buildQueryString(2, null, 'id', { Searchable: text })
                                 }
                              />
                           </FormGroup>
                        </Col>
                        {(!this.state.enderecoSelecionado.endereco.pais ||
                           this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                           <Fragment>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.cep}</Label>
                                    <CepInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.cep}
                                       onChange={(value) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cep = value.formattedValue;
                                          });
                                       }}
                                       onBlur={() => {
                                          this.controller.consultarCep();
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.logradouro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.logradouro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.logradouro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col style={{ maxWidth: 100 }}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.numero}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.numero}
                                       maxLength={10}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.numero = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.complemento}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.complemento}
                                       maxLength={30}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.complemento = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                           </Fragment>
                        )}
                     </Row>
                     {(!this.state.enderecoSelecionado.endereco.pais ||
                        this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                        <Fragment>
                           <Row>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.bairro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.bairro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.bairro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.cidade}</Label>
                                    <Select
                                       name={'cidade'}
                                       defaultValue={this.state.enderecoSelecionado.endereco.cidade}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cidade = i;
                                          });
                                       }}
                                       formularioPadrao={(select) => {
                                          return (
                                             <CidadeView api={this.props.api} select={select} lang={this.props.lang} />
                                          );
                                       }}
                                       noDropDown={true}
                                       readOnlyColor='#ffff'
                                       getFilterUrl={(text) =>
                                          '/cidade' + buildQueryString(2, null, 'id', { Searchable: text })
                                       }
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        </Fragment>
                     )}

                     {this.state.enderecoSelecionado.endereco.pais &&
                        this.state.enderecoSelecionado.endereco.pais.codigo !== '+55' && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha1}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha1}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha1 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha2}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha2 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha3}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha3 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                  </div>
               ) : (
                  <div />
               );
            }}
         />
      );
   };

   analisadores = () => {
      return (
         <SubCadastro
            titulo={this.lang.telaDeCadastroDeAnalisadorSimulador.titulo}
            itens={this.state.itemSelecionado.analisadores}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.analisadorSelecionado = {
                        analisador: {},
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirAnalisador}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.analisadorSelecionado = JSON.parse(
                        JSON.stringify(state.itemSelecionado.analisadores[index])
                     );
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarAnalisador}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.analisadorSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.analisadores.splice(index, 1);
               });
            }}
            colunas={() => {
               return [
                  { titulo: this.lang.codigo, className: 'codigo' },
                  { titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.modelo, width: '10%' },
                  { titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDeSerie, width: '60%' },
                  { titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeFabricacao, width: '30%' },
               ];
            }}
            linha={(item) => {
               return [
                  item.id,
                  item.tipoDeEquipamento.sigla,
                  item.numeroDeSerie,
                  dateToString(item.dataDeFabricacao, this.props.lang),
               ];
            }}
            formulario={() => {
               return this.state.analisadorSelecionado ? (
                  <Fragment>
                     <Row>
                        <Col xs={4} md={4} xl={4}>
                           <FormGroup>
                              <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDeSerie}</Label>
                              <InputGroup style={{ flexWrap: 'nowrap' }}>
                                 <TextInput
                                    defaultValue={this.state.analisadorSelecionado.numeroDeSerie}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.analisadorSelecionado.numeroDeSerie = e.target.value;
                                       });
                                    }}
                                    upperCase={true}
                                 />

                                 <InputGroup.Append>
                                    <InputGroup.Text style={{ padding: 0, cursor: 'pointer' }}>
                                       <Select
                                          name={'analisador'}
                                          searchOnly={true}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.nome}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                state.analisadorSelecionado.id = i.id;
                                                state.analisadorSelecionado.numeroDeSerie = i.numeroDeSerie;
                                                state.analisadorSelecionado.tipoDeEquipamento = i.tipoDeEquipamento;
                                                state.analisadorSelecionado.calibradoPor = i.calibradoPor;
                                                state.analisadorSelecionado.dataDeFabricacao = i.dataDeFabricacao;
                                                state.analisadorSelecionado.dataDeCalibracao = i.dataDeCalibracao;
                                                state.analisadorSelecionado.numeroDoCertificado = i.numeroDoCertificado;
                                                state.analisadorSelecionado.tempoDeDuracaoDaCalibracao =
                                                   i.tempoDeDuracaoDaCalibracao;
                                                state.analisadorSelecionado.certificadosDeCalibracao =
                                                   i.certificadosDeCalibracao;

                                                state.mostrarDadosDoEquipamento = false;
                                             }).then(() => {
                                                updateState(this, (state) => {
                                                   state.mostrarDadosDoEquipamento = true;
                                                });
                                             });
                                          }}
                                          formularioPadrao={(select) => {
                                             return (
                                                <AnalisadorSimuladorNeosView
                                                   api={this.props.api}
                                                   select={select}
                                                   lang={this.props.lang}
                                                />
                                             );
                                          }}
                                          noDropDown={true}
                                          readOnlyColor='#ffff'
                                          getFilterUrl={(text) =>
                                             '/analisadorneos' +
                                             buildQueryString(2, null, 'id', {
                                                Searchable: text,
                                             })
                                          }
                                       />
                                    </InputGroup.Text>
                                 </InputGroup.Append>
                              </InputGroup>
                           </FormGroup>
                        </Col>
                        {this.state.mostrarDadosDoEquipamento && (
                           <Col>
                              <FormGroup>
                                 <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.tipoDeEquipamento}</Label>
                                 <Select
                                    name={'tipoDeEquipamento'}
                                    defaultValue={this.state.analisadorSelecionado.tipoDeEquipamento}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          state.analisadorSelecionado.tipoDeEquipamento = i;
                                       });
                                    }}
                                    formularioPadrao={(select) => {
                                       return (
                                          <TipoDeEquipamentoView
                                             api={this.props.api}
                                             select={select}
                                             lang={this.props.lang}
                                          />
                                       );
                                    }}
                                    noDropDown={true}
                                    readOnlyColor='#ffff'
                                    getFilterUrl={(text) =>
                                       '/tipodeequipamento' + buildQueryString(2, null, 'id', { Searchable: text })
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        )}
                     </Row>

                     {this.state.mostrarDadosDoEquipamento && (
                        <Fragment>
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeFabricacao}</Label>
                                    <DateInput
                                       defaultValue={this.state.analisadorSelecionado.dataDeFabricacao}
                                       onChange={(value) => {
                                          updateState(this, (state) => {
                                             state.analisadorSelecionado.dataDeFabricacao = value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.tipoDeConta}</Label>
                                    <Select
                                       name={'tipodeconta'}
                                       defaultValue={
                                          this.state.analisadorSelecionado.tipoDeConta
                                             ? this.state.analisadorSelecionado.tipoDeConta
                                             : null
                                       }
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => i.nome}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             state.analisadorSelecionado.tipoDeConta = i;
                                          });
                                       }}
                                       formularioPadrao={(select) => {
                                          return (
                                             <TipoDeContaDeEmpresaView
                                                api={this.props.api}
                                                select={select}
                                                lang={this.props.lang}
                                             />
                                          );
                                       }}
                                       noDropDown={true}
                                       readOnlyColor='#ffff'
                                       getFilterUrl={(text) =>
                                          '/tipodecontadeempresa' +
                                          buildQueryString(2, null, 'id', { Searchable: text })
                                       }
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDePatrimonio}</Label>
                                    <TextInput
                                       defaultValue={this.state.analisadorSelecionado.numeroDePatrimonio}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.analisadorSelecionado.numeroDePatrimonio = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                       readOnly={true}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.calibradoPor}</Label>
                                    <TextInput
                                       defaultValue={this.state.analisadorSelecionado.calibradoPor}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.analisadorSelecionado.calibradoPor = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeCalibracao}</Label>
                                    <DateInput
                                       defaultValue={this.state.analisadorSelecionado.dataDeCalibracao}
                                       onChange={(value) => {
                                          updateState(this, (state) => {
                                             state.analisadorSelecionado.dataDeCalibracao = value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col>
                                 <FormGroup>
                                    <Label>
                                       {this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDoCertificadoVigente}
                                    </Label>
                                    <TextInput
                                       defaultValue={this.state.analisadorSelecionado.numeroDoCertificado}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.analisadorSelecionado.numeroDoCertificado = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>

                              <Col>
                                 <FormGroup>
                                    <Label>
                                       {this.lang.telaDeCadastroDeAnalisadorSimulador.tempoDeDuracaoDaCalibracao}
                                    </Label>
                                    <IntegerInput
                                       defaultValue={this.state.analisadorSelecionado.tempoDeDuracaoDaCalibracao}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.analisadorSelecionado.tempoDeDuracaoDaCalibracao = e.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                           {this.certificados()}
                        </Fragment>
                     )}
                  </Fragment>
               ) : (
                  <div />
               );
            }}
         />
      );
   };

   certificados = () => {
      return (
         <Row
            style={{
               lineHeight: 1,
            }}
         >
            <Col>
               <FormGroup>
                  <SubCadastro
                     titulo={this.lang.telaDeCadastroDeAnalisadorSimulador.certificadosDeCalibracao}
                     exibirTitulos={true}
                     itens={this.state.analisadorSelecionado.certificadosDeCalibracao}
                     lang={this.lang}
                     modal={true}
                     tableStyle={{ backgroundColor: 'white' }}
                     formStyle={{ backgroundColor: 'white' }}
                     novo={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.certificadoDeCalibracaoSelecionado = {
                                 certificadoDeCalibracao: {
                                    situacao: { id: 1 },
                                 },
                              };
                           }).then(resolve);
                        });
                     }}
                     cancelar={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.certificadoDeCalibracaoSelecionado = null;
                           }).then(resolve);
                        });
                     }}
                     inserir={this.controller.inserirCertificadoDeCalibracao}
                     selecionarParaAlteracao={(index) => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.certificadoDeCalibracaoSelecionado = JSON.parse(
                                 JSON.stringify(state.analisadorSelecionado.certificadosDeCalibracao[index])
                              );
                           }).then(() => resolve());
                        });
                     }}
                     alterar={this.controller.alterarCertificadoDeCalibracao}
                     excluir={(index) => {
                        updateState(this, (state) => {
                           state.analisadorSelecionado.certificadosDeCalibracao.splice(index, 1);
                        });
                     }}
                     colunas={() => {
                        return [
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.arquivo,
                              width: '60%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeValidade,
                              width: '20%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.situacao,
                              width: '20%',
                              className: null,
                           },
                        ];
                     }}
                     linha={(item) => {
                        return [
                           item.certificadoDeCalibracao && item.certificadoDeCalibracao.arquivo
                              ? item.certificadoDeCalibracao.arquivo.descricao
                              : null,
                           dateToString(item.certificadoDeCalibracao.dataDeValidade, this.props.lang),
                           this.controller.getDescricaoDaSituacaoDoCertificado(
                              item.certificadoDeCalibracao.dataDeValidade
                           ),
                        ];
                     }}
                     formulario={() => {
                        return (
                           <div>
                              <Row>
                                 <Col sm={9} xs={9} md={9}>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.arquivo}</Label>
                                       <Document
                                          lang={this.props.lang}
                                          api={this.props.api}
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado &&
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao &&
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .arquivo
                                          }
                                          onChange={(file) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.arquivo =
                                                   file;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col sm={3} xs={3} md={3}>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeValidade}</Label>
                                       <DateInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .dataDeValidade
                                          }
                                          onChange={(value) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDeValidade =
                                                   value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.calibradoPor}</Label>
                                       <TextInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .calibradoPor
                                          }
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.calibradoPor =
                                                   e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col sm={3} xs={3} md={3}>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeCalibracao}</Label>
                                       <DateInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .dataDaCalibracao
                                          }
                                          onChange={(value) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDaCalibracao =
                                                   value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.localDaCalibracao}</Label>
                                       <TextInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .localDaCalibracao
                                          }
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.localDaCalibracao =
                                                   e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 {this.state.mostrarPlanilha && (
                                    <Col>
                                       <FormGroup>
                                          <Label>
                                             {
                                                this.lang.telaDeCadastroDeAnalisadorSimulador
                                                   .importarResultadosDePlanilha
                                             }
                                             &nbsp;&nbsp;
                                             <IconButton
                                                style={{ fontSize: 20, color: 'green' }}
                                                cursor='pointer'
                                                icon={faFileExcel}
                                                title={
                                                   this.lang.telaDeCadastroDeAnalisadorSimulador.baixarPlanilhaModelo
                                                }
                                                onClick={() => {
                                                   let sigla = this.state.analisadorSelecionado.tipoDeEquipamento.sigla;
                                                   let idioma = this.login.idioma ? this.login.idioma : 'pt-BR';
                                                   let a = document.createElement('a');
                                                   a.download = sigla;
                                                   a.href =
                                                      this.props.api.urlBase() +
                                                      '/publicfile/download/' +
                                                      sigla +
                                                      '_' +
                                                      idioma +
                                                      '.xlsx';
                                                   document.body.appendChild(a);
                                                   a.click();
                                                   document.body.removeChild(a);
                                                }}
                                             />
                                          </Label>
                                          <Document
                                             lang={this.props.lang}
                                             api={this.props.api}
                                             accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                             defaultValue={
                                                this.state.certificadoDeCalibracaoSelecionado &&
                                                this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao &&
                                                this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                   .planilha
                                             }
                                             onChange={(file) => {
                                                updateState(this, (state) => {
                                                   state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha =
                                                      file;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                                 {this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha && (
                                    <Col md={2} lg={2} xl={2}>
                                       <FormGroup>
                                          <Label>&nbsp;</Label>
                                          <Button
                                             onClick={this.controller.importarResultados}
                                             text={this.props.lang.telaDeCadastroDeAnalisadorSimulador.importar}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                              </Row>

                              {this.resultados()}

                              <br />
                           </div>
                        );
                     }}
                  />
               </FormGroup>
            </Col>
         </Row>
      );
   };

   resultados = () => {
      return (
         <Row>
            <Col>
               <FormGroup>
                  <SubCadastro
                     titulo={this.lang.telaDeCadastroDeAnalisadorSimulador.resultadosDoCertificadosDeCalibracao}
                     exibirTitulos={true}
                     itens={this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados}
                     lang={this.lang}
                     novo={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.resultadoSelecionado = {};
                           }).then(resolve);
                        });
                     }}
                     cancelar={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.resultadoSelecionado = null;
                           }).then(resolve);
                        });
                     }}
                     inserir={this.controller.inserirResultadoDeCertificadoDeCalibracao}
                     selecionarParaAlteracao={(index) => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.resultadoSelecionado = JSON.parse(
                                 JSON.stringify(
                                    this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[
                                       index
                                    ]
                                 )
                              );
                           }).then(() => resolve());
                        });
                     }}
                     alterar={this.controller.alterarResultadoDeCertificadoDeCalibracao}
                     excluir={(index) => {
                        updateState(this, (state) => {
                           this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados.splice(
                              index,
                              1
                           );
                        });
                     }}
                     colunas={() => {
                        return [
                           {
                              titulo: this.lang.codigo,
                              width: '10%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.parametro,
                              width: '20%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.valorDeReferencia,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.valorMedido,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.tendencia,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.tendenciaPercentual,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.incerteza,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.fatorDeAbrangencia,
                              width: '15%',
                              className: null,
                           },
                        ];
                     }}
                     linha={(item) => {
                        return [
                           item.id > 0 ? item.id : null,
                           item.parameterTypeDesc,
                           numberToCurrencyString(item.referenceValue, this.lang),
                           numberToCurrencyString(item.measuredValue, this.lang),
                           numberToCurrencyString(item.tendency, this.lang),
                           numberToCurrencyString(item.percentTendency, this.lang),
                           numberToCurrencyString(item.uncertainty, this.lang),
                           numberToCurrencyString(item.coverageFactor, this.lang),
                        ];
                     }}
                     formulario={() => {
                        return (
                           <div
                              style={{
                                 fontSize: 13,
                              }}
                           >
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.parametro}</Label>
                                       <Select
                                          defaultValue={this.state.resultadoSelecionado.parameterType}
                                          options={getParametrosParaCadastroDeCertificado(
                                             this.state.analisadorSelecionado
                                          )}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          acceptZero={true}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.resultadoSelecionado.parameterType = i.id;
                                                   state.resultadoSelecionado.parameterTypeDesc = i.descricao;
                                                } else {
                                                   state.resultadoSelecionado.parameterType = 0;
                                                   state.resultadoSelecionado.parameterTypeDesc = null;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.referenceValue}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.referenceValue = e.value;
                                                this.calcularTendencia(state);
                                             }).then(() => {
                                                updateState(this, (state) => {
                                                   state.mostrarTendencia = true;
                                                });
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.valorMedido}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.measuredValue}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.measuredValue = e.value;
                                                this.calcularTendencia(state);
                                             }).then(() => {
                                                updateState(this, (state) => {
                                                   state.mostrarTendencia = true;
                                                });
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.tendencia}</Label>
                                       {this.state.mostrarTendencia && (
                                          <DecimalInput
                                             lang={this.lang}
                                             defaultValue={this.state.resultadoSelecionado.tendency}
                                             decimalPlaces={0}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.resultadoSelecionado.tendency = e.value;
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>
                                          {this.lang.telaDeCadastroDeAnalisadorSimulador.tendenciaPercentual}
                                       </Label>
                                       {this.state.mostrarTendencia && (
                                          <DecimalInput
                                             lang={this.lang}
                                             defaultValue={this.state.resultadoSelecionado.percentTendency}
                                             decimalPlaces={0}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.resultadoSelecionado.percentTendency = e.value;
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.incerteza}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.uncertainty}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.uncertainty = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.fatorDeAbrangencia}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.coverageFactor}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.coverageFactor = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </div>
                        );
                     }}
                  />
               </FormGroup>
            </Col>
         </Row>
      );
   };

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            {this.camposPrincipais()}

            {this.telefones()}

            <br />

            {this.enderecos()}

            <br />

            {this.analisadores()}

            <br />

            <Filler height={50} />

            <br />
         </React.Fragment>
      );
   };

   calcularTendencia = (state) => {
      try {
         state.resultadoSelecionado.tendency = parseFloat(
            Math.abs(state.resultadoSelecionado.referenceValue - state.resultadoSelecionado.measuredValue).toFixed(6)
         );
         state.resultadoSelecionado.percentTendency = parseFloat(
            ((state.resultadoSelecionado.tendency * 100) / state.resultadoSelecionado.referenceValue).toFixed(6)
         );
      } catch (e) {}
      state.mostrarTendencia = false;
   };

   editar = (item) => {
      if (this.form) {
         this.form.editarClick(item);
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.lang.telaDeCadastroDeEmpresa.titulo}
            url={url}
            fastUrl={url + '/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            permissoes={[1101, 1102, 1103, 1104]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            ref={(c) => (this.form = c)}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               telefones: [],
               enderecos: [],
               situacao: { id: 1 },
               /*razaoSocial: 'CALIBRADORA LTDA',
               nomeFantasia: 'CALIBRADORA',
               cnpj: '47.188.420/0001-07',
               tipoDeDePessoa: 1,
               email: 'calibradora@email.com',
               quantidadeMaximaDeUsuarios: 10,*/
            }}
         />
      );
   }
}
