import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from './../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import TipoDeEquipamentoController from '../../controllers/Empresa/TipoDeEquipamentoController';
import {updateState } from '../../utils/Functions';

export default class TipoDeEquipamentoView extends Component {
    constructor(props) {
        super(props);
        this.state = { mostrarAcessos: true };
        this.controller = new TipoDeEquipamentoController(this);
    }

    renderizarFormulario = () => {
        let lang = this.props.lang;
        let telaDeCadastroDeTipoDeEquipamento = lang.telaDeCadastroDeTipoDeEquipamento;
        return (
            <React.Fragment>
                <Row>
                    <Col sm={2} md={2} lg={2}>
                        <FormGroup>
                            <Label>{lang.codigo}</Label>
                            <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                    </Col>

                    <Col sm={8} md={8} lg={8}>
                      <FormGroup>
                          <Label>{telaDeCadastroDeTipoDeEquipamento.nome}</Label>
                          <TextInput
                              defaultValue={this.state.itemSelecionado.nome}
                              onChange={(e) => {
                                  updateState(this, (state) => {
                                      state.itemSelecionado.nome = e.target.value;
                                  });
                               }}
                               upperCase={true}
                            />
                        </FormGroup>
                    </Col> 

                    <Col sm={2} md={2} lg={2}>
                       <FormGroup>
                           <Label>{telaDeCadastroDeTipoDeEquipamento.sigla}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.sigla}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                 state.itemSelecionado.sigla = e.target.value;
                                });
                            }}
                            upperCase={true}
                           />
                        </FormGroup>
                    </Col>  

                   {/* <Col sm={9} md={9} lg={9}>
                        <FormGroup>
                            <Label>{telaDeCadastroDeTipoDeEquipamento.situacao}</Label>
                            <Select
                                as='select'
                                name='situacao'
                                defaultValue={
                                    this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id
                                }
                                options={[
                                    { id: 1, descricaoDaSituacao: lang.SituacaoDeTipoDeEquipamento.ativo },
                                    { id: 2, descricaoDaSituacao: lang.SituacaoDeTipoDeEquipamento.inativo },
                                ]}
                                getKeyValue={(i) => i.id}
                                getDescription={(i) => i.descricaoDaSituacao}
                                onSelect={(i) => {
                                    updateState(this, (state) => {
                                        state.itemSelecionado.situacao = i;
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>  */}
                </Row>

                 

                <FormGroup>
                    <Label>{telaDeCadastroDeTipoDeEquipamento.descricao}</Label>
                    <TextArea
                        defaultValue={this.state.itemSelecionado.descricao}
                        onChange={(e) => {
                            updateState(this, (state) => {
                                state.itemSelecionado.descricao = e.target.value;
                            });
                        }}
                        upperCase={true}
                    />
                </FormGroup>
                <br />
            </React.Fragment>
        );
    };
    
    render() {
        return (
            <FormularioPadrao
                titulo={this.props.lang.telaDeCadastroDeTipoDeEquipamento.titulo}
                url={'/tipodeequipamento'}
                api={this.props.api}
                lang={this.props.lang}
                ordenacaoPadrao={'nome'}
                permissoes={[1051, null, null, null]}
                getFiltro={this.controller.getFiltro}
                getTitulosDaTabela={this.controller.getTitulosDaTabela}
                getDadosDaTabela={this.controller.getDadosDaTabela}
                renderizarFormulario={this.renderizarFormulario}
                getObjetoDeDados={this.controller.getObjetoDeDados}
                antesDeInserir={this.controller.antesDeInserir}
                antesDeEditar={this.controller.antesDeEditar}
                antesDeSalvar={this.controller.antesDeSalvar}
                antesDeExcluir={this.controller.antesDeExcluir}
                getFormState={() => {
                    return this.state;
                }}
                setFormState={(state, callback) => {
                    return new Promise((resolve) => {
                        this.setState(state, () => {
                            resolve();
                            if (callback) {
                                callback();
                            }
                        });
                    });
                }}
                select={this.props.select}
                itemVazio={{
                    situacao: { id: 1 },
                    acessos: [],
                }}
            />
        );
    }
}
