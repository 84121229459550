import { showError } from '../../components/Messages';
import cryptoJs from 'crypto-js';
import btoa from 'btoa';
import { rsaEncrypt } from '../../utils/Functions';

export default class LoginController {
   constructor(view) {
      this.view = view;
      this.api = view.api;
      this.lang = view.lang;
      this.sessionManager = view.sessionManager;
   }

   efetuarLogin = () => {
      var result = new Promise((resolve, reject) => {
         let nomeDeUsuario = this.view.state.nomeDeUsuario;
         let senha = this.view.state.senha;
         let tipoDeAcesso = this.view.props.tipoDeAcesso;
         let identificacaoComplementar = this.view.state.identificacaoComplementar;

         if (tipoDeAcesso === 'EMPRESA' && !identificacaoComplementar) {
            showError(this.lang.telaDeLogin.mensagens.informeAIdentificacaoDaEmpresa);
            reject();
            return;
         }

         if (tipoDeAcesso === 'CLIENTE' && !identificacaoComplementar) {
            identificacaoComplementar = nomeDeUsuario;
         }

         if (!nomeDeUsuario) {
            showError(this.lang.telaDeLogin.mensagens.informeONomeDeUsuario);
            reject();
            return;
         }

         if (!senha) {
            showError(this.lang.telaDeLogin.mensagens.informeASenha);
            reject();
            return;
         }

         let input = {
            nomeDeUsuario: nomeDeUsuario,
            senha: senha,
            tipoDeAcesso: this.view.props.tipoDeAcesso,
            identificacaoComplementar: identificacaoComplementar,
            origem: 'WEB',
         };

         this.api
            .get('/publickey')
            .then((publicKey) => {
               var key = cryptoJs.enc.Base64.stringify(cryptoJs.lib.WordArray.random(32));
               var iv = cryptoJs.enc.Base64.stringify(cryptoJs.lib.WordArray.random(16));

               let symmetricKey = {
                  key: key,
                  iv: iv,
               };

               input.symmetricKey = symmetricKey;

               let inputString = JSON.stringify(input);

               let blocks = inputString.match(new RegExp('.{1,' + 64 + '}', 'g'));
               let encryptedInput = null;
               for (let i = 0; i < blocks.length; i++) {
                  let buffer = rsaEncrypt(blocks[i], publicKey);
                  encryptedInput = encryptedInput ? Buffer.concat([encryptedInput, buffer]) : buffer;
               }

               this.api
                  .post('/login/token', {
                     data: btoa(encryptedInput),
                  })
                  .then((result) => {
                     let decryptedToken = cryptoJs.AES.decrypt(result.token, cryptoJs.enc.Base64.parse(key), {
                        iv: cryptoJs.enc.Base64.parse(iv),
                     }).toString(cryptoJs.enc.Utf8);
                     result.token = decryptedToken;
                     this.sessionManager.setLogin(result);
                     window.location = './';
                     resolve();
                  })
                  .catch(reject);
            })
            .catch(reject);
      });
      return result;
   };
}
