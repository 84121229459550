import IconButton from './IconButton';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { showConfirm } from './Messages';

export default class Avatar extends Component {
   constructor(props) {
      super(props);
      this.state = { base64: null };
   }

   componentDidMount() {
       this.load();
   }

   load = () => {
      if (this.props.image && this.props.image.nome) {
         this.props.api.get('/file?filename=' + this.props.image.nome, true, 'arraybuffer').then((data) => {
            const base64 = Buffer.from(data, 'binary').toString('base64');
            this.setState({ base64: 'data:image/png;base64, ' + base64 });
         });
      }
   };

   render() {
      return (
         <div style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20, position: 'relative' }}>
            <Form.Label
               style={{ cursor: this.props.readOnly ? 'default' : 'pointer', marginBottom: 13 }}
               title={this.props.image && this.props.image ? this.props.lang.alterarFoto : this.props.lang.carregarFoto}
            >
               <Form.File
                  accept={'image/png, image/jpeg'}
                  onChange={(e) => this.onChange(e)}
                  style={{ position: 'absolute', top: -1000 }}
                  tabIndex={-1}
                  disabled={this.props.readOnly}
               />
               {this.props.image && this.props.image.nome ? (
                  <ImgRounded alt='' url={() => this.state.base64} width={this.props.width} />
               ) : (
                  <ImgNone alt='' width={this.props.width}>
                     <IconButton style={{ color: '#999' }} icon={faUserCircle} />
                  </ImgNone>
               )}
            </Form.Label>
            {this.props.image && this.props.image.nome && !this.props.readOnly && (
               <div
               className='hide-when-readonly'
                  style={{
                     color: 'initial',
                     position: 'absolute',
                     right: 3,
                     top: 85,
                     fontSize: 20,
                     cursor: 'pointer',
                  }}
                  title={this.props.lang.excluirFoto}
                  onClick={(e) => this.excluir(e)}
               >
                  <IconButton style={{ color: '#999' }} icon={faTrashAlt} />
               </div>
            )}
         </div>
      );
   }

   onChange = (event) => {
      var self = this;
      var tg = event.target;
      if (event.target.files.length > 0) {
        this.setState({base64: null});
         let file = event.target.files[0];
         var fileReader = new FileReader();
         fileReader.addEventListener(
            'load',
            function () {
               var input = {
                  tipo: file.type,
                  base64: fileReader.result,
               };
               self.props.api.post('/file', input).then((result) => {
                  if (self.props.onChange) {
                     self.props.onChange({
                        id: result.id,
                        nome: result.nome,
                     });
                  }
                  tg.value = null;
                  self.load();
               });
            },
            false
         );
         fileReader.readAsDataURL(file);
      }
   };

   excluir = (event) => {
      var tg = event.target;
      showConfirm(this.props.lang.desejaRealmenteExcluirAFoto, () => {
         if (this.props.onChange) {
            this.props.onChange(null);
         }
         tg.value = null;
         this.setState({base64: null});
      });
   };
}

const ImgRounded = styled.div`
   border-radius: 50%;
   height: ${(props) => (props.width ? props.width : 100)}px;
   width: ${(props) => (props.width ? props.width : 100)}px;
   background-position-x: center;
   background-size: cover;
   background-image: url(${(props) => props.url});
`;

const ImgNone = styled.div`
   object-fit: cover;
   border-radius: 50%;
   height: ${(props) => (props.width ? props.width : 100)}px;
   width: ${(props) => (props.width ? props.width : 100)}px;
   font-size: 110px;
   line-height: 0px;

   i {
      text-decoration: none;
      color: #888;
   }
`;
