import { showConfirm, showError, showInfo } from '../../components/Messages';
import {
   buildQueryString,
   getEnderecoCompleto,
   updateState,
   rsaEncrypt,
   validarEndereco,
   validarTelefone,
   stringToDate,
   dateToString,
} from '../../utils/Functions';
import { BaseController } from '../BaseController';
import btoa from 'btoa';

export default class EmpresaController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'EmpresaController';
      this.props = props.props;
   }

   antesDeInserir = () => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cnpjParaNaoConsultar = null;
            resolve();
         });
      });
   };

   antesDeEditar = (formularioPadrao, empresa) => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cnpjParaNaoConsultar = empresa.cnpj;
            resolve();
         });
      });
   };

   getTitulosDaTabela = () => {
      return [
         {
            titulo: this.lang.telaDeCadastroDeTipoDeEmpresa.codigo,
            orderby: 'Id',
            className: 'codigo',
         },
         {
            titulo: this.lang.telaDeCadastroDeEmpresa.razaoSocial,
            width: '40%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeEmpresa.nomeFantasia,
            width: '40%',
            orderby: null,
         },
         { titulo: this.lang.telaDeCadastroDeEmpresa.cnpj, width: '20%', orderby: null },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.razaoSocial, item.nomeFantasia, item.cnpj];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.pais) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.informeOPaisDaEmpresa);
            reject();
            return;
         }

         if (!item.razaoSocial) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.informeARazaoSocialDaEmpresa);
            reject();
            return;
         }

         if (!item.nomeFantasia) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.informeONomeFantasiaDaEmpresa);
            reject();
            return;
         }

         if (item.razaoSocial.length > 100) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.nomeDaEmpresaExcedeOLimitede100Caracteres);
            reject();
            return;
         }

         if (!item.tipoDeEmpresa) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.informeOTipoDeEmpresa);
            reject();
            return;
         }

         if (!item.cnpj) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.informeOCnpj);
            reject();
            return;
         }

         if (!item.email) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.informeOEmail);
            reject();
            return;
         }

         if (!item.quantidadeMaximaDeUsuarios) {
            showError(this.lang.telaDeCadastroDeEmpresa.mensagens.informeAQuantidadeMaximaDeUsuarios);
            reject();
            return;
         }

         this.api
            .get('/publickey')
            .then((publicKey) => {
               let senhaCriptografada = null;
               let novaSenha = null;
               if (item.senha) {
                  let blocks = item.senha.match(new RegExp('.{1,' + 64 + '}', 'g'));
                  for (let i = 0; i < blocks.length; i++) {
                     let buffer = rsaEncrypt(blocks[i], publicKey);
                     senhaCriptografada = senhaCriptografada ? Buffer.concat([senhaCriptografada, buffer]) : buffer;
                  }
                  novaSenha = btoa(senhaCriptografada);
               }
               var input = {
                  razaoSocial: item.razaoSocial,
                  tipoDeEmpresa: item.tipoDeEmpresa ? { id: item.tipoDeEmpresa.id } : null,
                  cnpj: item.cnpj,
                  telefones: item.telefones,
                  email: item.email,
                  foto: item.foto && item.foto.id ? item.foto : null,
                  enderecos: item.enderecos,
                  quantidadeMaximaDeUsuarios: item.quantidadeMaximaDeUsuarios,
                  dataDeCadastro: item.dataDeCadastro ? item.dataDeCadastro : new Date(),
                  situacao: item.situacao ? item.situacao.id : 0,
                  nomeFantasia: item.nomeFantasia,
                  novaSenha: novaSenha,
                  senhaAlterada: item.senhaAlterada,
                  enviarNovaSenhaPorEmail: item.enviarNovaSenhaPorEmail,
                  analisadores: item.analisadores,
                  pais: item.pais,
               };

               if (state.alterando) {
                  input.id = parseInt(item.id);
               }
               resolve(input);
            })
            .catch((e) => {
               console.error(e);
               reject();
            });
      });
   };

   consultarCep = () => {
      if (
         this.view.state.enderecoSelecionado.endereco.cep &&
         this.view.state.enderecoSelecionado.endereco.cep.length === 9 &&
         this.view.state.enderecoSelecionado.endereco.cep !== this.view.state.cepParaNaoConsultar
      ) {
         this.props.consultaDeCep.get(this.view.state.enderecoSelecionado.endereco.cep).then((resultViaCep) => {
            if (resultViaCep && resultViaCep.localidade && resultViaCep.uf) {
               let url =
                  '/cidade' +
                  buildQueryString(null, null, null, {
                     uf: resultViaCep.uf.toUpperCase(),
                     localidade: resultViaCep.localidade.toUpperCase(),
                  });
               this.props.api
                  .getAll(url, false)
                  .then((result) => {
                     if (result.count === 1) {
                        let enderecoEmEdicao = { endereco: {} };
                        enderecoEmEdicao.cep = this.view.state.enderecoSelecionado.endereco.cep;
                        enderecoEmEdicao.logradouro = resultViaCep.logradouro
                           ? resultViaCep.logradouro.toUpperCase()
                           : null;
                        enderecoEmEdicao.bairro = resultViaCep.bairro ? resultViaCep.bairro.toUpperCase() : null;
                        enderecoEmEdicao.cidade = result.items[0];

                        updateState(this.view, (state) => {
                           state.enderecoSelecionado.endereco.logradouro = enderecoEmEdicao.logradouro;
                           state.enderecoSelecionado.endereco.bairro = enderecoEmEdicao.bairro;
                           state.enderecoSelecionado.endereco.cidade = enderecoEmEdicao.cidade;
                           state.mostrarEndereco = false;
                           state.cepParaNaoConsultar = this.view.state.enderecoSelecionado.endereco.cep;
                        }).then(() => {
                           updateState(this.view, (state) => {
                              state.mostrarEndereco = true;
                           });
                        });
                     }
                  })
                  .catch(() => {});
            }
         });
      }
   };

   inserirEndereco = () => {
      return new Promise((resolve, reject) => {
         if (!validarEndereco(true, this.view.state.enderecoSelecionado.endereco, this.lang, reject)) {
            return;
         }

         let endereco = {
            logradouro: this.view.state.enderecoSelecionado.endereco.logradouro,
            numero: this.view.state.enderecoSelecionado.endereco.numero,
            complemento: this.view.state.enderecoSelecionado.endereco.complemento,
            bairro: this.view.state.enderecoSelecionado.endereco.bairro,
            cep: this.view.state.enderecoSelecionado.endereco.cep,
            cidade: this.view.state.enderecoSelecionado.endereco.cidade,
            pais: this.view.state.enderecoSelecionado.endereco.pais,
            linha1: this.view.state.enderecoSelecionado.endereco.linha1,
            linha2: this.view.state.enderecoSelecionado.endereco.linha2,
            linha3: this.view.state.enderecoSelecionado.endereco.linha3,
         };

         endereco.enderecoCompleto = getEnderecoCompleto(endereco);

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.enderecos) {
               state.itemSelecionado.enderecos = [];
            }
            state.itemSelecionado.enderecos.push({
               endereco,
            });
         }).then(() => resolve());
      });
   };

   alterarEndereco = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarEndereco(true, this.view.state.enderecoSelecionado.endereco, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.enderecos[index].endereco.logradouro = state.enderecoSelecionado.endereco.logradouro;
            state.itemSelecionado.enderecos[index].endereco.numero = state.enderecoSelecionado.endereco.numero;
            state.itemSelecionado.enderecos[index].endereco.complemento =
               state.enderecoSelecionado.endereco.complemento;
            state.itemSelecionado.enderecos[index].endereco.bairro = state.enderecoSelecionado.endereco.bairro;
            state.itemSelecionado.enderecos[index].endereco.cep = state.enderecoSelecionado.endereco.cep;
            state.itemSelecionado.enderecos[index].endereco.cidade = state.enderecoSelecionado.endereco.cidade;
            state.itemSelecionado.enderecos[index].endereco.pais = state.enderecoSelecionado.endereco.pais;
            state.itemSelecionado.enderecos[index].endereco.linha1 = state.enderecoSelecionado.endereco.linha1;
            state.itemSelecionado.enderecos[index].endereco.linha2 = state.enderecoSelecionado.endereco.linha2;
            state.itemSelecionado.enderecos[index].endereco.linha3 = state.enderecoSelecionado.endereco.linha3;
            state.itemSelecionado.enderecos[index].endereco.enderecoCompleto = getEnderecoCompleto(
               state.itemSelecionado.enderecos[index].endereco
            );
         }).then(() => resolve());
      });
   };

   inserirTelefone = () => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.telefones) {
               state.itemSelecionado.telefones = [];
            }
            state.itemSelecionado.telefones.push({
               pais: this.view.state.telefoneSelecionado.pais,
               ddd: this.view.state.telefoneSelecionado.ddd,
               numero: this.view.state.telefoneSelecionado.numero,
               temWhatsApp: this.view.state.telefoneSelecionado.temWhatsApp ? true : false,
            });
         }).then(() => resolve());
      });
   };

   alterarTelefone = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.telefones[index].pais = this.view.state.telefoneSelecionado.pais;
            state.itemSelecionado.telefones[index].ddd = this.view.state.telefoneSelecionado.ddd;
            state.itemSelecionado.telefones[index].numero = this.view.state.telefoneSelecionado.numero;
            state.itemSelecionado.telefones[index].temWhatsApp = this.view.state.telefoneSelecionado.temWhatsApp;
         }).then(() => resolve());
      });
   };

   verificarSeCnpjJaExiste = () => {
      let item = this.view.state.itemSelecionado;
      if (item && item.cnpj && item.cnpj.length === 18 && item.cnpj !== this.view.state.cnpjParaNaoConsultar) {
         this.props.api
            .getAll(
               this.view.state.url +
                  buildQueryString(null, null, null, {
                     cnpj: this.view.state.itemSelecionado.cnpj,
                  })
            )
            .then((result) => {
               if (result && result.count > 0) {
                  let empresa = result.items[0];
                  let mensagem = 'Empresa já está cadastrada, deseja visualizar o cadastro?';
                  showConfirm(
                     mensagem,
                     () => {
                        updateState(this.view, (state) => {
                           state.incluindo = false;
                           state.alterando = false;
                        }).then(() => this.view.editar(empresa));
                     },
                     () => {
                        updateState(this.view, (state) => {
                           state.cnpjParaNaoConsultar = item.cnpj;
                        });
                     },
                     this.lang.sim,
                     this.lang.nao
                  );
               } else {
                  updateState(this.view, (state) => {
                     state.cnpjParaNaoConsultar = item.cnpj;
                  });
               }
            });
      }
   };

   inserirAnalisador = () => {
      return new Promise((resolve, reject) => {
         var item = this.view.state.analisadorSelecionado;

         if (!item.numeroDeSerie) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeONumeroDeSerie);
            reject();
            return;
         }

         if (item.numeroDeSerie.length > 50) {
            showError(
               this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens
                  .informeUmTamanhoMenorQue50CaracteresNoNumeroDeSerie
            );
            reject();
            return;
         }

         if (!item.tipoDeEquipamento) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeOTipoDeEquipamento);
            reject();
            return;
         }

         if (!item.calibradoPor) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeQuemCalibrou);
            reject();
            return;
         }

         if (!item.dataDeCalibracao) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeADataDeCalibracao);
            reject();
            return;
         }

         if (!item.tempoDeDuracaoDaCalibracao) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeOTempoDeDuracaoDaCalibracao);
            reject();
            return;
         }

         if (!item.numeroDoCertificado) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeONumeroDoCertificadoDeCalibracao);
            reject();
            return;
         }

         if (!item.tipoDeConta) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeOTipoDeConta);
            reject();
            return;
         }

         let analisador = {
            id: this.view.state.analisadorSelecionado.id,
            numeroDeSerie: this.view.state.analisadorSelecionado.numeroDeSerie,
            numeroDePatrimonio: this.view.state.analisadorSelecionado.numeroDePatrimonio,
            dataDeFabricacao: this.view.state.analisadorSelecionado.dataDeFabricacao,
            tipoDeEquipamento: this.view.state.analisadorSelecionado.tipoDeEquipamento,
            calibradoPor: this.view.state.analisadorSelecionado.calibradoPor,
            numeroDoCertificado: this.view.state.analisadorSelecionado.numeroDoCertificado,
            dataDeCalibracao: this.view.state.analisadorSelecionado.dataDeCalibracao,
            tempoDeDuracaoDaCalibracao: this.view.state.analisadorSelecionado.tempoDeDuracaoDaCalibracao,
            certificadosDeCalibracao: this.view.state.analisadorSelecionado.certificadosDeCalibracao,
            tipoDeConta: this.view.state.analisadorSelecionado.tipoDeConta,
         };

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.analisadores) {
               state.itemSelecionado.analisadores = [];
            }
            state.itemSelecionado.analisadores.push(analisador);
         }).then(() => resolve());
      });
   };

   alterarAnalisador = (index) => {
      return new Promise((resolve, reject) => {
         var item = this.view.state.analisadorSelecionado;

         if (!item.numeroDeSerie) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeONumeroDeSerie);
            reject();
            return;
         }

         if (item.numeroDeSerie.length > 50) {
            showError(
               this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens
                  .informeUmTamanhoMenorQue50CaracteresNoNumeroDeSerie
            );
            reject();
            return;
         }

         if (!item.tipoDeEquipamento) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeOTipoDeEquipamento);
            reject();
            return;
         }

         if (!item.calibradoPor) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeQuemCalibrou);
            reject();
            return;
         }

         if (!item.dataDeCalibracao) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeADataDeCalibracao);
            reject();
            return;
         }

         if (!item.tempoDeDuracaoDaCalibracao) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeOTempoDeDuracaoDaCalibracao);
            reject();
            return;
         }

         if (!item.numeroDoCertificado) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.informeONumeroDoCertificadoDeCalibracao);
            reject();
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.analisadores[index].id = state.analisadorSelecionado.id;
            state.itemSelecionado.analisadores[index].numeroDeSerie = state.analisadorSelecionado.numeroDeSerie;
            state.itemSelecionado.analisadores[index].numeroDePatrimonio =
               state.analisadorSelecionado.numeroDePatrimonio;
            state.itemSelecionado.analisadores[index].dataDeFabricacao = state.analisadorSelecionado.dataDeFabricacao;
            state.itemSelecionado.analisadores[index].tipoDeEquipamento = state.analisadorSelecionado.tipoDeEquipamento;
            state.itemSelecionado.analisadores[index].calibradoPor = state.analisadorSelecionado.calibradoPor;
            state.itemSelecionado.analisadores[index].numeroDoCertificado =
               state.analisadorSelecionado.numeroDoCertificado;
            state.itemSelecionado.analisadores[index].dataDeCalibracao = state.analisadorSelecionado.dataDeCalibracao;
            state.itemSelecionado.analisadores[index].tempoDeDuracaoDaCalibracao =
               state.analisadorSelecionado.tempoDeDuracaoDaCalibracao;
            state.itemSelecionado.analisadores[index].certificadosDeCalibracao =
               state.analisadorSelecionado.certificadosDeCalibracao;
            state.itemSelecionado.analisadores[index].tipoDeConta = state.analisadorSelecionado.tipoDeConta;
         }).then(() => resolve());
      });
   };

   inserirCertificadoDeCalibracao = () => {
      return new Promise((resolve, reject) => {
         if (!this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.arquivo) {
            showError(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.selecioneOArquivo);
            reject();
            return false;
         }

         updateState(this.view, (state) => {
            if (!state.analisadorSelecionado.certificadosDeCalibracao) {
               state.analisadorSelecionado.certificadosDeCalibracao = [];
            }
            let certificado = this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao;
            state.analisadorSelecionado.certificadosDeCalibracao.push({
               certificadoDeCalibracao: {
                  numeroDoCertificado: certificado.numeroDoCertificado,
                  arquivo: certificado.arquivo,
                  dataDeValidade: certificado.dataDeValidade,
                  calibradoPor: certificado.calibradoPor,
                  dataDaCalibracao: certificado.dataDaCalibracao,
                  localDaCalibracao: certificado.localDaCalibracao,
                  resultados: certificado.resultados,
               },
            });
         }).then(() => resolve());
      });
   };

   alterarCertificadoDeCalibracao = (index) => {
      return new Promise((resolve) => {
         let certificado = this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao;
         updateState(this.view, (state) => {
            state.analisadorSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.numeroDoCertificado =
               certificado.numeroDoCertificado;
            state.analisadorSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.arquivo =
               certificado.arquivo;
            state.analisadorSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.dataDeValidade =
               certificado.dataDeValidade;
            state.analisadorSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.calibradoPor =
               certificado.calibradoPor;
            state.analisadorSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.dataDaCalibracao =
               certificado.dataDaCalibracao;
            state.analisadorSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.localDaCalibracao =
               certificado.localDaCalibracao;
            state.analisadorSelecionado.certificadosDeCalibracao[index].certificadoDeCalibracao.resultados =
               certificado.resultados;
         }).then(() => resolve());
      });
   };

   getDescricaoDaSituacaoDoCertificado = (dataDeValidade) => {
      let dataAtual = new Date();
      let result;
      if (dataAtual > stringToDate(dateToString(dataDeValidade, this.lang)))
         result = this.lang.telaDeCadastroDeAnalisadorSimulador.vencido;
      else result = this.lang.telaDeCadastroDeAnalisadorSimulador.ativo;
      return result;
   };

   inserirResultadoDeCertificadoDeCalibracao = () => {
      return new Promise((resolve, reject) => {
         updateState(this.view, (state) => {
            if (!state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados) {
               state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados = [];
            }
            let resultado = state.resultadoSelecionado;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados.push({
               parameterType: resultado.parameterType,
               parameterTypeDesc: resultado.parameterTypeDesc,
               referenceValue: resultado.referenceValue,
               tendency: resultado.tendency,
               measuredValue: resultado.measuredValue,
               percentTendency: resultado.percentTendency,
               uncertainty: resultado.uncertainty,
               coverageFactor: resultado.coverageFactor,
            });
         }).then(() => resolve());
      });
   };

   alterarResultadoDeCertificadoDeCalibracao = (index) => {
      return new Promise((resolve) => {
         let resultado = this.view.state.resultadoSelecionado;
         updateState(this.view, (state) => {
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].parameterType =
               resultado.parameterType;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].parameterTypeDesc =
               resultado.parameterTypeDesc;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].referenceValue =
               resultado.referenceValue;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].measuredValue =
               resultado.measuredValue;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].tendency =
               resultado.tendency;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].percentTendency =
               resultado.percentTendency;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].uncertainty =
               resultado.uncertainty;
            state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[index].coverageFactor =
               resultado.coverageFactor;
         }).then(() => resolve());
      });
   };

   importarResultados = () => {
      if (this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha != null) {
         this.api
            .get(
               '/analisador/lerplanilhadecertificados?file=' +
                  this.view.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha.id +
                  '&sigla=' +
                  this.view.state.analisadorSelecionado.tipoDeEquipamento.sigla
            )
            .then((result) => {
               updateState(this.view, (state) => {
                  state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados = result;
               }).then(() => {
                  showInfo(this.lang.telaDeCadastroDeAnalisadorSimulador.mensagens.resultadosImportadosComSucesso).then(
                     () => {
                        updateState(this.view, (state) => {
                           state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha = null;
                           state.mostrarPlanilha = false;
                        }).then(() => {
                           updateState(this.view, (state) => {
                              state.mostrarPlanilha = true;
                           });
                        });
                     }
                  );
               });
            });
      }
   };
}
