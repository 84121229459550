import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class SequenciaASEController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'SequenciaASEController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.cadastroDeSequenciaASE.nome, width: '100%', orderby: 'Nome' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nome];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.situacao) {
            showError(this.lang.sequencia.mensagens.informeASituacao);
            reject();
            return;
         }

         if (!item.nome) {
            showError(this.lang.sequencia.mensagens.informeONome);
            reject();
            return;
         }

         if (!item.itens || (item.itens && item.itens.length === 0)) {
            showError(this.lang.sequencia.mensagens.cadastrePeloMenosUmTeste);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            tiposDeEnsaio: item.tiposDeEnsaio,
            situacao: item.situacao ? item.situacao.id : null,
            itens: item.itens,
            checkList: item.checkList,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
