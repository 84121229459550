import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import ImportacaoDeTesteController from '../../controllers/Empresa/ImportacaoDeTesteController';
import { buildQueryString, updateState } from '../../utils/Functions';
import AnalisadorSimuladorView from './AnalisadorSimuladorView';
import Select from '../../components/Select';
import styled from 'styled-components';
import ListaPadraoOffline from '../ListaPadraoOffline';
import DecimalInput from '../../components/DecimalInput';
import { getUnidadesDeDesvio } from '../UnidadesDeDesvio';
import Document from '../../components/Document';
import CheckBox from '../../components/CheckBox';
import Button from '../../components/Button';
import { TIPO_DE_TESTE_AVM } from '../TiposDeTeste';
import Line from '../../components/Line';
import { getUnidadesDeDesvioAVM } from '../UnidadesDeDesvioAVM';

let LANG = {};

export default class ImportacaoDeTestesView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         selecionados: new Map(),
         arquivo: { id: 1163, nome: '1163.db', tipo: 'application/vnd.sqlite3', descricao: 'TIN100_02.db' },
      };
      this.controller = new ImportacaoDeTesteController(this);
      LANG = props.lang;
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeImportacaoDeTeste = lang.telaDeImportacaoDeTeste;
      let testes = this.getTestes();

      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeImportacaoDeTeste.analisador}</Label>
                     <Select
                        name={'analisador'}
                        defaultValue={this.state.itemSelecionado.analisador}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) =>
                           (i.sigla ? i.sigla : i.tipoDeEquipamento.sigla) + ' - ' + i.numeroDeSerie
                        }
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.analisador = i;
                              if (i) {
                                 state.itemSelecionado.analisador.sigla =
                                    state.itemSelecionado.analisador.tipoDeEquipamento.sigla;
                              }
                           });
                        }}
                        formularioPadrao={(select) => {
                           return (
                              <AnalisadorSimuladorView api={this.props.api} select={select} lang={this.props.lang} />
                           );
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/analisador' + buildQueryString(7, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>

               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeImportacaoDeTeste.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao}
                        options={[
                           {
                              id: 1,
                              descricao: telaDeImportacaoDeTeste.situacaoDeDownloadDeTeste.cadastrada,
                           },
                           {
                              id: 2,
                              descricao: telaDeImportacaoDeTeste.situacaoDeDownloadDeTeste.processado,
                           },
                           {
                              id: 3,
                              descricao: telaDeImportacaoDeTeste.situacaoDeDownloadDeTeste.descartado,
                           },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        allowEmpty={false}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.situacao = i;
                           });
                        }}
                        nullText={''}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.state.itemSelecionado.analisador && this.state.itemSelecionado.analisador.sigla === 'AVM100' && (
               <Row>
                  <Col>
                     <FormGroup>
                        <Label>{telaDeImportacaoDeTeste.arquivoDeBancoDeDados}</Label>
                        <Document
                           lang={LANG}
                           api={this.props.api}
                           accept='.db'
                           mimeType='application/vnd.sqlite3'
                           defaultValue={this.state.arquivo}
                           temporary={true}
                           onChange={(file) => {
                              updateState(this, (state) => {
                                 state.arquivo = file;
                              });
                           }}
                        />
                     </FormGroup>
                  </Col>

                  {this.state.arquivo && (
                     <Col sm={3} xs={3} md={3}>
                        <FormGroup>
                           <Label>&nbsp;</Label>
                           <Button
                              onClickAsync={this.importarEnsaios}
                              text={telaDeImportacaoDeTeste.importar}
                              inProgressText={telaDeImportacaoDeTeste.importando}
                              //disabled={!this.state.arquivo}
                           />
                        </FormGroup>
                     </Col>
                  )}
               </Row>
            )}

            <Row>
               <Col sm={3} xs={3} md={3}>
                  <Button
                     onClickAsync={this.gerarOrdemDeServico}
                     text={telaDeImportacaoDeTeste.gerarOrdemDeServico}
                     inProgressText={telaDeImportacaoDeTeste.gerando}
                     //disabled={!this.state.arquivo}
                  />
               </Col>
            </Row>

            {this.state.itemSelecionado.analisador && testes && testes.length > 0 && (
               <StyledParametros>
                  <Row>
                     <Col style={{ paddingLeft: 6, paddingRight: 6 }}>
                        <ListaPadraoOffline
                           esconderTitulo={true}
                           esconderBotaoFechar={true}
                           esconderConsulta={true}
                           api={this.props.api}
                           lang={LANG}
                           getItens={() => testes}
                           getTitulosDaTabela={() => {
                              return [
                                 {
                                    titulo: 'N°',
                                    width: '5%',
                                 },
                                 {
                                    titulo: LANG.telaDeImportacaoDeTeste.parametro,
                                    width: '25%',
                                 },
                                 {
                                    titulo: LANG.telaDeImportacaoDeTeste.resultado,
                                    width: '20%',
                                 },
                                 {
                                    titulo: LANG.telaDeImportacaoDeTeste.referencia,
                                    width: '14%',
                                 },
                                 {
                                    titulo: LANG.telaDeImportacaoDeTeste.desvioMaximoInferior,
                                    width: '13%',
                                 },
                                 {
                                    titulo: LANG.telaDeImportacaoDeTeste.desvioMaximoSuperior,
                                    width: '13%',
                                 },
                                 {
                                    titulo: LANG.telaDeImportacaoDeTeste.unidadeDeDesvio,
                                    width: '20%',
                                 },
                                 {
                                    titulo: (
                                       <div>
                                          <CheckBox
                                             defaultChecked={this.state.selecionarTudo}
                                             onChange={(checked) => {
                                                let selecionados = this.state.selecionados;

                                                let quantidadeDeTestes = this.getTestes().length;
                                                for (let i = 0; i < quantidadeDeTestes; i++) {
                                                   selecionados.set(i, checked);
                                                }

                                                updateState(this, (state) => {
                                                   state.selecionarTudo = checked;
                                                   state.selecionados = null;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.selecionados = selecionados;
                                                   });
                                                });
                                             }}
                                          />
                                       </div>
                                    ),
                                    width: 30,
                                 },
                              ];
                           }}
                           getDadosDaTabela={(item, index) => {
                              let campoUnidadeLimites = this.getControlesParaOCampoUnidadeLimites(index, item);

                              let campoLimiteInferior = campoUnidadeLimites
                                 ? this.getControlesParaOCampoLimiteInferior(index, item)
                                 : null;
                              let campoLimiteSuperior = campoUnidadeLimites
                                 ? this.getControlesParaOCampoLimiteSuperior(index, item)
                                 : null;

                              return [
                                 <div style={{ display: 'flex', height: '100%' }}>
                                    <span style={{ marginTop: 8 }}>{item.testNumber}</span>
                                 </div>,
                                 <div style={{ display: 'flex', height: '100%' }}>
                                    <span style={{ marginTop: 8 }}>{item.testTypeDesc}</span>
                                 </div>,
                                 this.getValorMedido(index, item),
                                 this.getControlesParaOCampoReferencia(index, item),
                                 campoLimiteInferior,
                                 campoLimiteSuperior,
                                 campoUnidadeLimites,
                                 <div>
                                    {this.state.selecionados && (
                                       <CheckBox
                                          defaultChecked={this.state.selecionados.get(index)}
                                          onChange={(checked) => {
                                             updateState(this, (state) => {
                                                state.selecionados.set(index, checked);
                                             });
                                          }}
                                       />
                                    )}
                                 </div>,
                              ];
                           }}
                        />
                     </Col>
                  </Row>
               </StyledParametros>
            )}

            <br />
         </React.Fragment>
      );
   };

   getTestes = () => {
      let result = [];

      if (!this.state.itemSelecionado.analisador) return;

      if (this.state.itemSelecionado.analisador.sigla === 'ASE100') {
         result = this.state.itemSelecionado.testesASE;
      } else if (this.state.itemSelecionado.analisador.sigla === 'AVM100') {
         result = this.state.itemSelecionado.testesAVM;
      }

      return result;
   };

   getValorMedido = (index, item) => {
      let result = '';

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <TextInput defaultValue={item.measuredValueDesc1} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc2} readOnly={true} />

               <Line marginTop={2} marginBottom={2} />

               <TextInput defaultValue={item.measuredValueDesc3} readOnly={true} />
            </Fragment>
         );
      } else {
         result = (
            <div>
               <TextInput defaultValue={item.measuredValueDesc} readOnly={true} />
            </div>
         );
      }

      return result;
   };

   getControlesParaOCampoReferencia = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.referenceValue1}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].referenceValue1 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.referenceValue2}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].referenceValue2 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.referenceValue3}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].referenceValue3 = e.floatValue;
                     });
                  }}
               />
            </Fragment>
         );
      } else {
         result = (
            <DecimalInput
               decimalPlaces={0}
               defaultValue={item.referenceValue}
               onChange={(e) => this.setReferencia(index, e.floatValue)}
            />
         );
      }

      return result;
   };

   getControlesParaOCampoLimiteSuperior = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteSuperior1}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteSuperior1 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteSuperior2}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteSuperior2 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteSuperior3}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteSuperior3 = e.floatValue;
                     });
                  }}
               />
            </Fragment>
         );
      } else {
         result = (
            <DecimalInput
               decimalPlaces={0}
               defaultValue={item.limiteSuperior}
               onChange={(e) => this.setLimiteSuperior(index, e.floatValue)}
            />
         );
      }

      return result;
   };

   getControlesParaOCampoLimiteInferior = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         result = (
            <Fragment>
               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteInferior1}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteInferior1 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteInferior2}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteInferior2 = e.floatValue;
                     });
                  }}
               />

               <Line marginTop={2} marginBottom={2} />

               <DecimalInput
                  decimalPlaces={0}
                  defaultValue={item.limiteInferior3}
                  onChange={(e) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.testesAVM[index].limiteInferior3 = e.floatValue;
                     });
                  }}
               />
            </Fragment>
         );
      } else {
         result = (
            <DecimalInput
               decimalPlaces={0}
               defaultValue={item.limiteInferior}
               onChange={(e) => this.setLimiteInferior(index, e.floatValue)}
            />
         );
      }

      return result;
   };

   getControlesParaOCampoUnidadeLimites = (index, item) => {
      let result = null;

      if (
         this.state.itemSelecionado.analisador.sigla === 'AVM100' &&
         (item.testType === TIPO_DE_TESTE_AVM.normaDeVolume || item.testType === TIPO_DE_TESTE_AVM.normaDePressao)
      ) {
         let unidadesDeDesvioDoTeste1 = getUnidadesDeDesvioAVM(this.props.lang, item, 1);
         let unidadesDeDesvioDoTeste2 = getUnidadesDeDesvioAVM(this.props.lang, item, 2);
         let unidadesDeDesvioDoTeste3 = getUnidadesDeDesvioAVM(this.props.lang, item, 3);
         result = unidadesDeDesvioDoTeste1 && (
            <Fragment>
               <div>
                  {unidadesDeDesvioDoTeste1 && (
                     <Select
                        defaultValue={item.unidadeLimites1}
                        options={unidadesDeDesvioDoTeste1}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        allowEmpty={false}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.testesAVM[index].unidadeLimites1 = i ? i.id : null;
                           });
                        }}
                     />
                  )}
               </div>

               <Line marginTop={2} marginBottom={2} />

               <div>
                  {unidadesDeDesvioDoTeste2 && (
                     <Select
                        defaultValue={item.unidadeLimites2}
                        options={unidadesDeDesvioDoTeste2}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        allowEmpty={false}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.testesAVM[index].unidadeLimites2 = i ? i.id : null;
                           });
                        }}
                     />
                  )}
               </div>

               <Line marginTop={2} marginBottom={2} />

               <div>
                  {unidadesDeDesvioDoTeste3 && (
                     <Select
                        defaultValue={item.unidadeLimites3}
                        options={unidadesDeDesvioDoTeste3}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        allowEmpty={false}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.testesAVM[index].unidadeLimites3 = i ? i.id : null;
                           });
                        }}
                     />
                  )}
               </div>
            </Fragment>
         );
      } else {
         let unidadesDeDesvioDoTeste = this.getUnidadesDeDesvioDoTeste(item);
         result = unidadesDeDesvioDoTeste && (
            <Select
               defaultValue={item.unidadeLimites}
               options={unidadesDeDesvioDoTeste}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.descricao}
               allowEmpty={false}
               onSelect={(i) => {
                  this.setUnidadeLimites(index, i ? i.id : null);
               }}
            />
         );
      }

      return result;
   };

   getUnidadesDeDesvioDoTeste = (item) => {
      let result = [];

      if (!this.state.itemSelecionado.analisador) return;

      result = getUnidadesDeDesvio(LANG, this.state.itemSelecionado.analisador.sigla, item);

      return result;
   };

   setReferencia = (index, referenceValue) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].referenceValue = referenceValue;
         } else if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesAVM[index].referenceValue = referenceValue;
         }
      });
   };

   setLimiteSuperior = (index, limiteSuperior) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].limiteSuperior = limiteSuperior;
         } else if (state.itemSelecionado.analisador.sigla === 'AVM100') {
            state.itemSelecionado.testesAVM[index].limiteSuperior = limiteSuperior;
         }
      });
   };

   setLimiteInferior = (index, limiteInferior) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].limiteInferior = limiteInferior;
         } else if (state.itemSelecionado.analisador.sigla === 'AVM100') {
            state.itemSelecionado.testesAVM[index].limiteInferior = limiteInferior;
         }
      });
   };

   setUnidadeLimites = (index, unidadeLimites) => {
      if (!this.state.itemSelecionado.analisador) return;

      updateState(this, (state) => {
         if (state.itemSelecionado.analisador.sigla === 'ASE100') {
            state.itemSelecionado.testesASE[index].unidadeLimites = unidadeLimites;
         } else if (state.itemSelecionado.analisador.sigla === 'AVM100') {
            state.itemSelecionado.testesAVM[index].unidadeLimites = unidadeLimites;
         }
      });
   };

   importarEnsaios = () => {
      return new Promise((resolve, reject) => {
         if (this.state.itemSelecionado.analisador.sigla === 'AVM100') {
            this.props.api
               .get('/downloaddeteste/baixartestesavm?fileId=' + this.state.arquivo.id)
               .then((result) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.testesAVM = result;
                  });
                  resolve();
               })
               .catch(() => reject());
         } else {
            resolve();
         }
      });
   };

   gerarOrdemDeServico = () => {};

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeImportacaoDeTeste.titulo}
            url={'/downloaddeteste'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            maxWidth={'100%'}
            permissoes={[1461, 1462, 1463, 1464]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               parametrosInformados: [],
               situacao: { id: 1 },
            }}
         />
      );
   }
}

const StyledParametros = styled.div`
   .div-tabela-formulario-padrao {
      overflow-y: hidden;
   }

   .div-cabecalho-tabela-formulario-padrao {
      overflow-y: hidden;
   }
`;
