import React, { Component } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Label from '../../../components/Label';
import Select from '../../../components/Select';
import { buildQueryString, updateState } from '../../../utils/Functions';
import ClienteView from '../../Empresa/ClienteView';
import ListaPadrao from '../../ListaPadrao';

export default class RelatorioDeEquipamentoDeCliente extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   getFiltro = () => {
      return new Promise((resolve, reject) => {
         resolve({
            cliente: this.state.cliente ? this.state.cliente.id : null,
         });
      });
   };

   renderizarFiltros = () => {
      return (
         <div style={{ maxWidth: 800 }}>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeEquipamentoDeCliente.cliente}</Label>
                     <Select
                        name={'cliente'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.apelidoOuNomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.cliente = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <ClienteView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/cliente/fast' + buildQueryString(2, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </div>
      );
   };

   getTitulosDaTabela = () => {
      return [
         { titulo: this.props.lang.codigo, orderby: 'Id', className: 'codigo' },
         {
            titulo: this.props.lang.relatorioDeEquipamentoDeCliente.tipoDeEquipamento,
            width: '25%',
            orderby: 'tipoDeEquipamento',
         },
         {
            titulo: this.props.lang.relatorioDeEquipamentoDeCliente.numeroDeSerie,
            width: '15%',
            orderby: 'numeroDeSerie',
         },
         { titulo: this.props.lang.relatorioDeEquipamentoDeCliente.fabricante, width: '30%', orderby: 'fabricante' },
         { titulo: this.props.lang.relatorioDeEquipamentoDeCliente.modelo, width: '30%', orderby: 'modelo' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.tipoDeEquipamento, item.numeroDeSerie, item.fabricante, item.modelo];
   };

   render() {
      return (
         <ListaPadrao
            titulo={
               <div>
                  <Link
                     to={'/report'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',

                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.relatorios.titulo}</span>                     
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.relatorioDeEquipamentoDeCliente.titulo}</span>
               </div>
            }
            url={'/relatorio/RelatorioDeEquipamentoDeCliente'}
            api={this.props.api}
            lang={this.props.lang}
            getFiltro={this.getFiltro}
            estruturaPadrao={false}
            iniciarVazio={true}
            renderizarFiltros={this.renderizarFiltros}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            getFormState={() => this.state}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
         />
      );
   }
}
