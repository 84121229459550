import React from 'react';

export default class Line extends React.Component {
    render() {
        return (
            <React.Fragment>
                <hr
                    style={{
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: this.props.marginTop ? this.props.marginTop : 0,
                        marginBottom: this.props.marginBottom ? this.props.marginBottom : 0,
                        borderColor: this.props.color,
                        width: this.props.width,
                        borderWidth: this.props.height,
                    }}
                />
            </React.Fragment>
        );
    }
}
