export default class SessionManager {
   getLogin() {
      let result = JSON.parse(sessionStorage.getItem('usuario'));
      return result;
   }

   setLogin(usuario) {
      sessionStorage.setItem('usuario', JSON.stringify(usuario));
   }

   isAuthenticated() {
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      return usuario && usuario.token && window.location.pathname !== '/login';
   }

   temAcessoARotina(rotina) {
      let result = this.isAuthenticated() && this.getLogin().rotinasAcessiveis.some((i) => i.id === rotina);
      return result;
   }

   isUsuarioNeos() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result = usuario.isUsuarioNeos;
      }
      return result;
   }

   isUsuarioDeEmpresa() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result =
            usuario.isUsuarioDeEmpresa ||
            (usuario.isUsuarioNeos && usuario.impersonationBy && usuario.impersonationBy.isUsuarioDeEmpresa);
      }
      return result;
   }

   isUsuarioDeCliente() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result =
            usuario.isUsuarioDeCliente ||
            (usuario.isUsuarioNeos && usuario.impersonationBy && usuario.impersonationBy.isUsuarioDeEmpresa) ||
            (usuario.isUsuarioDeEmpresa && usuario.impersonationBy && usuario.impersonationBy.isUsuarioDeCliente);
      }
      return result;
   }

   aceitouTermosDeUso() {
      let result = false;
      let usuario = JSON.parse(sessionStorage.getItem('usuario'));
      if (usuario && usuario.token && window.location.pathname !== '/login') {
         result = usuario.aceitouTermosDeUso;
      }
      return result;
   }
}
