import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from './../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import TipoDeEmpresaController from '../../controllers/Neos/TipoDeEmpresaController';
import { updateState } from '../../utils/Functions';

export default class TipoDeEmpresaView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.controller = new TipoDeEmpresaController(this);
    }

    renderizarFormulario = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                            <Label>{this.props.lang.telaDeCadastroDeTipoDeEmpresa.codigo}</Label>
                            <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{this.props.lang.telaDeCadastroDeTipoDeEmpresa.descricao}</Label>
                            <TextInput
                                defaultValue={this.state.itemSelecionado.descricao}
                                onChange={(e) => {
                                    updateState(this, (state) => {
                                        state.itemSelecionado.descricao = e.target.value;
                                    });
                                }}
                                upperCase={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    render() {
        return (
            <FormularioPadrao
                titulo={this.props.lang.telaDeCadastroDeTipoDeEmpresa.titulo}
                url={'/tipodeempresa'}
                api={this.props.api}
                lang={this.props.lang}
                ordenacaoPadrao={'Id'}
                permissoes={[1071, 1072, 1073, 1074]}
                getFiltro={this.controller.getFiltro}
                getTitulosDaTabela={this.controller.getTitulosDaTabela}
                getDadosDaTabela={this.controller.getDadosDaTabela}
                renderizarFormulario={this.renderizarFormulario}
                getObjetoDeDados={this.controller.getObjetoDeDados}
                antesDeInserir={this.controller.antesDeInserir}
                antesDeEditar={this.controller.antesDeEditar}
                antesDeSalvar={this.controller.antesDeSalvar}
                antesDeExcluir={this.controller.antesDeExcluir}
                getFormState={() => {
                    return this.state;
                }}
                setFormState={(state, callback) => {
                    return new Promise((resolve) => {
                        this.setState(state, () => {
                            resolve();
                            if (callback) {
                                callback();
                            }
                        });
                    });
                }}
                select={this.props.select}
                itemVazio={{}}
            />
        );
    }
}
