import React from 'react';
import { Col, Container, Row, ListGroup } from 'react-bootstrap';
import { Filler } from '../components/Filler';
import { IconeWhatsApp } from '../components/IconeWhatsApp';

export default class AboutView extends React.Component {  
    render() {
        const itens = [
            { key: "Versão", value: process.env.REACT_APP_VERSION },
            { key: "Suporte pelo telefone", value: (<div>(31)99942-3943<IconeWhatsApp /></div>) },
            { key: "Suporte pelo e-mail ", value: 'ramonpacheco@outlook.com' },
        ]

        return (
            <Container className='container-fluid'>
                <Filler height={5} />
                <ListGroup>
                    {itens.map((i, index) => {
                        return (
                            <ListGroup.Item key={index} style={{ padding: 0 }}>
                                <Row style={{ overFlow: 'hidden', margin: 0, height: 35 }}>
                                    <Col style={{ backgroundColor: '#e9ecef', minHeight: '100%', display: 'flex' }}>
                                        <span style={{ margin: 'auto 0 auto 0' }}>{i.key}</span>
                                    </Col>
                                    <Col style={{minHeight: '100%', display: 'flex'}}>
                                        <span style={{ margin: 'auto 0 auto 0' }}>{i.value}</span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>)
                    })}
                </ListGroup>
            </Container >
        )
    }
}
