import {
   getTiposDeUnidadeDeDesvioAVM_manualPressao,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_complacencia,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_fluxos,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_oxigenio,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_relacao_IE,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_taxaDeResp,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoDeInsp_tempoDeExp,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoInspCiclo,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_vazamento,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeDeMinuto,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeInsp_volumeExp,
   getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro1,
   getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro2,
   getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro3,
   getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro1,
   getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro2,
   getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro3,
} from './Descricoes';
import { TIPO_DE_TESTE_AVM } from './TiposDeTeste';

export const getUnidadesDeDesvioAVM = (lang, teste, parametro) => {
   let result = null;
   switch (teste.testType) {
      case TIPO_DE_TESTE_AVM.manualDePressao:
         result = getTiposDeUnidadeDeDesvioAVM_manualPressao(lang);
         break;
      case TIPO_DE_TESTE_AVM.manualDeVentilador:
         result = getUnidadeDeDesvioManualVentilador(lang, teste.parameterType);
         break;
      case TIPO_DE_TESTE_AVM.normaDeFiO2:
         break;
      case TIPO_DE_TESTE_AVM.normaDePressao:
         if (parametro === 1) {
            result = getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro1(lang);
         } else if (parametro === 2) {
            result = getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro2(lang);
         } else if (parametro === 3) {
            result = getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro3(lang);
         }
         break;
      case TIPO_DE_TESTE_AVM.normaDeVolume:
         if (parametro === 1) {
            result = getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro1(lang);
         } else if (parametro === 2) {
            result = getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro2(lang);
         } else if (parametro === 3) {
            result = getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro3(lang);
         }
         break;

      default:
         break;
   }
   return result;
};

export const getUnidadeDeDesvioManualVentilador = (lang, tipoDeParametro) => {    
   let result = null;
   switch (tipoDeParametro) {
      case 5:
      case 6:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeInsp_volumeExp(lang);
         break;
      case 7:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeDeMinuto(lang);
         break;
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa(lang);
         break;
      case 15:
      case 16:
      case 17:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_fluxos(lang);
         break;
      case 18:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_vazamento(lang);
         break;
      case 19:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_taxaDeResp(lang);
         break;
      case 20:
      case 21:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoDeInsp_tempoDeExp(lang);
         break;
      case 22:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_relacao_IE(lang);
         break;
      case 23:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoInspCiclo(lang);
         break;
      case 24:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_oxigenio(lang);
         break;
      case 25:
         result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_complacencia(lang);
         break;
      default:
         break;
   }

   return result;
};
