import React, { Component } from 'react';
import FormularioPadrao from './FormularioPadrao';
import LogController from '../controllers/LogController';
import { Form } from 'react-bootstrap';

export default class LogView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.controller = new LogController(this);
    }
    
    render() {
        return (
            <FormularioPadrao
                titulo={'Log'}
                url={'/log'}
                api={this.props.api}
                lang={this.props.lang}
                ordenacaoPadrao={'date desc'}
                permissoes={[9001,null,null,null]}
                getFiltro={this.controller.getFiltro}
                getTitulosDaTabela={this.controller.getTitulosDaTabela}
                getDadosDaTabela={this.controller.getDadosDaTabela}
                renderizarFormulario={this.renderizarFormulario}
                getObjetoDeDados={this.controller.getObjetoDeDados}
                antesDeInserir={this.controller.antesDeInserir}
                antesDeEditar={this.controller.antesDeEditar}
                antesDeSalvar={this.controller.antesDeSalvar}
                antesDeExcluir={this.controller.antesDeExcluir}
                renderizarFiltro={this.renderizarFiltro}
                getFormState={() => {
                    return this.state;
                }}
                setFormState={(state, callback) => {
                    return new Promise((resolve) => {
                        this.setState(state, () => {
                            resolve();
                            if (callback) {
                                callback();
                            }
                        });
                    });
                }}
                select={this.props.select}
                itemVazio={{}}
            />
        );
    }

    renderizarFiltros(sender) {
        return (
           <Form.Group>
              <span>filtro</span>
           </Form.Group>
        );
     }
}
