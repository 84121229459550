import { Row, Col, FormGroup, Container, Tab, Tabs } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import Label from '../../components/Label';
import { dateTimeToString, updateState } from '../../utils/Functions';
import Document from '../../components/Document';
import { LayoutParams } from '../../config/LayoutParams';
import styled from 'styled-components';
import ListaPadraoOffline from '../ListaPadraoOffline';
import IntegerInput from '../../components/IntegerInput';
import Button from '../../components/Button';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import Line from '../../components/Line';
import IconButton from '../../components/IconButton';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { showInfo } from '../../components/Messages';
import CheckBox from '../../components/CheckBox';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Line as ChartLine } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

let LANG = {};

const Constants = {
   AQT_VAZAMENTO: 0,
   AQT_DISTRIBUICAO_TEMPERATURA: 1,
   AQT_PENETRACAO_TEMPERATURA: 2,
   AQT_ABERTURA_PORTA: 3,
   AQT_QUEDA_ENERGIA: 4,
   AQT_MANUAL: 5,
   AQT_PRESSAO_DINAMICA: 6,
   AQT_CICLOS_AJUSTAVEIS: 7,
   AQT_DETECCAO_ESTABILIDADE: 8,

   AQT_TIPO_AUTOCLAVE: 0,
   AQT_TIPO_LAVADORA: 1,
   AQT_TIPO_ESTUFA: 2,
   AQT_TIPO_INCUBADORA: 3,
   AQT_TIPO_REFRIGERADOR: 4,
   AQT_TIPO_CAMARA: 5,
};

export default class ImportacaoDeTesteAqtView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         abaSelecionada: 'ensaios',
         filtro: {},
         // ensaioSelecionado: {
         //    id: 511,
         //    descricao: 'HOSP.SEARA-BOWEIDICK-PHOENIX',
         //    dataHora: '01/07/2022 08:38:57',
         // },
         // ensaioSelecionado: { id: 0, descricao: 'dt', dataHora: '2023-04-12T10:07:51' },
         //arquivo: {
         //   id: 891,
         //   nome: '891.db',
         //   tipo: '',
         //   descricao: 'AQT110_00(10).db',
         //},
         //ensaios: [{ id: 2, descricao: 'dt', dataHora: '2023-04-12T10:07:51' }],
         // ensaios: [
         //    {
         //       id: 511,
         //       descricao: 'HOSP.SEARA-BOWEIDICK-PHOENIX',
         //       dataHora: '01/07/2022 08:38:57',
         //    },
         // ],
         testes: null,
         testesSelecionados: props.getTestesAQT(),
      };
      LANG = props.lang;
   }

   importarEnsaios = () => {
      return new Promise((resolve, reject) => {
         this.props.api
            .get('/testeaqt/lerensaiosdobanco?fileId=' + this.state.arquivo.id)
            .then((result) => {
               this.setState(
                  {
                     ensaios: result,
                     ensaioSelecionadoHighlightIndex: null,
                  },
                  () => resolve()
               );
            })
            .catch(() => reject());
      });
   };

   importarTestes = () => {
      if (this.importandoTestes) {
         return new Promise((resolve) => resolve());
      }
      this.importandoTestes = true;
      return new Promise((resolve, reject) => {
         this.props.api
            .get(
               '/testeaqt/lertestesdobanco?fileId=' +
                  this.state.arquivo.id +
                  '&iddoensaio=' +
                  this.state.ensaioSelecionado.id
            )
            .then((result) => {
               this.setState(
                  {
                     testes: result,
                     testeSelecionadoHighlightIndex: null,
                  },
                  () => resolve()
               );
            })
            .catch(() => reject())
            .finally(() => {
               this.importandoTestes = false;
            });
      });
   };

   render() {
      return (
         <div style={{ margin: 8, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div
               style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  padding: 5,
               }}
            >
               {!this.state.ensaios && (
                  <Fragment>
                     <div>
                        <Label>{LANG.telaDeImportacaoDeTesteAQT.bancoDeDados}</Label>
                        <Document
                           lang={LANG}
                           api={this.props.api}
                           accept='.db'
                           mimeType='application/vnd.sqlite3'
                           defaultValue={this.state.arquivo}
                           temporary={true}
                           onChange={(file) => {
                              updateState(this, (state) => {
                                 state.arquivo = file;
                              });
                           }}
                        />
                        <br />
                     </div>

                     {this.state.arquivo && (
                        <div>
                           <Row>
                              <Col sm={4} xs={4} md={4}>
                                 <Button
                                    onClickAsync={this.importarEnsaios}
                                    text={LANG.telaDeImportacaoDeTesteAQT.importar}
                                    inProgressText={LANG.telaDeImportacaoDeTesteAQT.importando}
                                    //disabled={!this.state.arquivo}
                                 />
                              </Col>
                           </Row>
                           <br />
                        </div>
                     )}
                  </Fragment>
               )}
               {this.state.ensaios && this.state.ensaios.length > 0 && (
                  <div style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}>
                     <StyledNav>
                        <Tabs
                           variant='tabs'
                           activeKey={this.state.abaSelecionada}
                           onSelect={(k) => this.setState({ abaSelecionada: k })}
                           style={{ color: '#444' }}
                        >
                           <Tab
                              eventKey='ensaios'
                              title={LANG.telaDeImportacaoDeTesteAQT.ensaios}
                              style={{
                                 height: '100%',
                              }}
                           >
                              {this.ensaios()}
                           </Tab>
                           {this.state.ensaioSelecionado && (
                              <Tab
                                 eventKey='testesDoEnsaio'
                                 title={LANG.telaDeImportacaoDeTesteAQT.testes}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.testesDoEnsaio()}
                              </Tab>
                           )}

                           {this.state.ensaioSelecionado && this.state.testeSelecionado && (
                              <Tab
                                 eventKey='entradaDeDados'
                                 title={LANG.telaDeImportacaoDeTesteAQT.entradaDeDados}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.entradaDeDados()}
                              </Tab>
                           )}

                           {this.state.ensaioSelecionado && this.state.testeSelecionado && (
                              <Tab
                                 eventKey='selecaoDeCanais'
                                 title={LANG.telaDeImportacaoDeTesteAQT.selecaoDeCanais}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.selecaoDeCanais()}
                              </Tab>
                           )}

                           {this.state.ensaioSelecionado && this.state.testeSelecionado && (
                              <Tab
                                 eventKey='imagensEAnexos'
                                 title={LANG.telaDeImportacaoDeTesteAQT.imagensEAnexos}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.imagensEAnexos()}
                              </Tab>
                           )}

                           {this.state.testesSelecionados && this.state.testesSelecionados.length > 0 && (
                              <Tab
                                 eventKey='testesSelecionados'
                                 title={LANG.telaDeImportacaoDeTesteAQT.testesSelecionados}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.testesSelecionados()}
                              </Tab>
                           )}
                        </Tabs>
                     </StyledNav>
                  </div>
               )}
            </div>
         </div>
      );
   }

   ensaios = () => {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'hidden',
               }}
            >
               <ListaPadraoOffline
                  esconderTitulo={true}
                  esconderBotaoFechar={true}
                  api={this.props.api}
                  lang={LANG}
                  getItens={() => {
                     let result = this.state.ensaios;

                     if (this.state.filtro) {
                        if (this.state.filtro.numeroDoEnsaio) {
                           result = result.filter((i) => i.id === this.state.filtro.numeroDoEnsaio);
                        }
                     }
                     updateState(this, (state) => {
                        state.ensaioSelecionado = null;
                        state.ensaioSelecionadoHighlightIndex = null;
                        state.testeSelecionado = null;
                        state.testeSelecionadoHighlightIndex = null;
                     });

                     return result;
                  }}
                  getTitulosDaTabela={() => {
                     return [
                        { titulo: LANG.codigo, orderby: 'Id', className: 'codigo' },
                        { titulo: LANG.telaDeImportacaoDeTesteAQT.descricao, width: '100%' },
                        { titulo: LANG.telaDeImportacaoDeTesteAQT.data, width: '100%' },
                     ];
                  }}
                  getDadosDaTabela={(item) => {
                     return [item.id, item.descricao, dateTimeToString(item.dataHora)];
                  }}
                  getFormState={() => this.state}
                  estruturaPadrao={true}
                  setFormState={(state, callback) => {
                     return new Promise((resolve) => {
                        this.setState(state, () => {
                           resolve();
                           if (callback) {
                              callback();
                           }
                        });
                     });
                  }}
                  onRowClick={(item, index) => {
                     updateState(this, (state) => {
                        state.testes = null;
                        state.ensaioSelecionado = item && item === state.ensaioSelecionado ? null : item;
                        state.ensaioSelecionadoHighlightIndex = state.ensaioSelecionado ? index : null;
                     });
                  }}
                  highlightIndex={this.state.ensaioSelecionadoHighlightIndex}
                  renderizarFiltros={() => {
                     return (
                        <div>
                           <Row>
                              <Col sm={4} xs={4} md={4}>
                                 <FormGroup>
                                    <Label>{LANG.telaDeImportacaoDeTesteAQT.numeroDoEnsaio}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.filtro.numeroDoEnsaio}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.filtro.numeroDoEnsaio = e.floatValue;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        </div>
                     );
                  }}
               />
            </div>
         </Fragment>
      );
   };

   testesDoEnsaio = () => {
      if (this.state.testes == null) {
         this.importarTestes();
      }

      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'hidden',
               }}
            >
               {this.state.testes && (
                  <ListaPadraoOffline
                     esconderTitulo={true}
                     esconderBotaoFechar={true}
                     esconderConsulta={true}
                     api={this.props.api}
                     lang={LANG}
                     getItens={() => {
                        let result = this.state.testes;
                        return result;
                     }}
                     getTitulosDaTabela={() => {
                        return [
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.ensaio, className: 'codigo' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.teste, width: '10%' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.tipoDeTeste, width: '45%' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.norma, width: '15%' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.data, width: '30%' },
                        ];
                     }}
                     getDadosDaTabela={(item) => {
                        return [item.numeroEnsaio, item.numTeste, item.descTipoTeste, item.descNorma, item.dataHora];
                     }}
                     getFormState={() => this.state}
                     setFormState={(state, callback) => {
                        return new Promise((resolve) => {
                           this.setState(state, () => {
                              resolve();
                              if (callback) {
                                 callback();
                              }
                           });
                        });
                     }}
                     onRowClick={(item, index) => {
                        updateState(this, (state) => {
                           state.testeSelecionado = item && item === state.testeSelecionado ? null : item;
                           state.testeSelecionadoHighlightIndex = state.testeSelecionado ? index : null;

                           if (state.testeSelecionado) {
                              this.setTextoDoProcedimento(Constants.AQT_AUTOCLAVE);

                              if (state.testeSelecionado.tipoTeste === 1 || state.testeSelecionado.tipoTeste === 2) {
                                 state.testeSelecionado.indiceFase1 = 0;
                                 state.testeSelecionado.descFase1 = 'Tempo de Pré-Vácuo';
                                 state.testeSelecionado.indiceFase2 = 1;
                                 state.testeSelecionado.descFase2 = 'Tempo de Aquecimento';
                                 state.testeSelecionado.indiceFase3 = 2;
                                 state.testeSelecionado.descFase3 = 'Tempo de Esterilização';
                                 state.testeSelecionado.indiceFase4 = 3;
                                 state.testeSelecionado.descFase4 = 'Tempo de Secagem';
                              } else {
                                 state.testeSelecionado.indiceFase1 = null;
                                 state.testeSelecionado.indiceFase2 = null;
                                 state.testeSelecionado.indiceFase3 = null;
                                 state.testeSelecionado.indiceFase4 = null;
                              }

                              state.testeSelecionado.introductionDescription =
                                 LANG.telaDeImportacaoDeTesteAQT.textoIntroducao;
                              state.testeSelecionado.normDescription = LANG.telaDeImportacaoDeTesteAQT.textoNorma;
                              state.testeSelecionado.termDescription = LANG.telaDeImportacaoDeTesteAQT.textoTermos;
                              state.testeSelecionado.objectiveDescription =
                                 LANG.telaDeImportacaoDeTesteAQT.textoObjetivo;

                              state.testeSelecionado.acceptanceCriteria =
                                 LANG.telaDeImportacaoDeTesteAQT.textoCriterioAceitacao;
                              state.testeSelecionado.resultDescription = LANG.telaDeImportacaoDeTesteAQT.textoConclusao;
                              state.testeSelecionado.indicatorDescription =
                                 LANG.telaDeImportacaoDeTesteAQT.textoIndicador;
                              state.testeSelecionado.equationDescription = LANG.telaDeImportacaoDeTesteAQT.textoEquacao;
                              state.testeSelecionado.tipoCarga = LANG.telaDeImportacaoDeTesteAQT.instrumentais;
                           }
                        });
                     }}
                     highlightIndex={this.state.testeSelecionadoHighlightIndex}
                     getFiltro={() => {}}
                     renderizarFiltros={() => {
                        return <div></div>;
                     }}
                  />
               )}
            </div>
         </Fragment>
      );
   };

   setTextoDoProcedimento = (tipoEquipamento) => {
      updateState(this, (state) => {
         switch (tipoEquipamento) {
            case Constants.AQT_AUTOCLAVE:
               state.testeSelecionado.tituloProcedimento =
                  LANG.telaDeImportacaoDeTesteAQT.textoProcedimentoTitulo_AUTOCLAVE;
               state.testeSelecionado.procedureDescription =
                  LANG.telaDeImportacaoDeTesteAQT.textoProcedimento_AUTOCLAVE;
               state.testeSelecionado.tituloProcedimento2 =
                  LANG.telaDeImportacaoDeTesteAQT.textoProcedimento2Titulo_AUTOCLAVE;
               state.testeSelecionado.procedureDescription2 =
                  LANG.telaDeImportacaoDeTesteAQT.textoProcedimento2_AUTOCLAVE;
               state.testeSelecionado.tituloProcedimento3 = null;
               state.testeSelecionado.procedureDescription3 = null;
               state.mostrarProcedimento3 = false;
               break;

            case Constants.AQT_TIPO_LAVADORA:
               state.testeSelecionado.procedureDescription =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_LAVADORA_1;
               state.testeSelecionado.procedureDescription2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_LAVADORA_2;
               state.testeSelecionado.tituloProcedimento =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_LAVADORA_1;
               state.testeSelecionado.tituloProcedimento2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_LAVADORA_2;
               state.testeSelecionado.acceptanceCriteria =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_CRITERIO_ACEITACAO_LAVADORA;
               state.testeSelecionado.equationDescription = LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_EQUACAO_LAVADORA;
               state.testeSelecionado.tituloProcedimento3 = null;
               state.testeSelecionado.procedureDescription3 = null;
               state.mostrarProcedimento3 = false;
               break;

            case Constants.AQT_TIPO_ESTUFA:
               state.testeSelecionado.procedureDescription =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_ESTUFA_1;
               state.testeSelecionado.procedureDescription2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_ESTUFA_2;
               state.testeSelecionado.tituloProcedimento =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_ESTUFA_1;
               state.testeSelecionado.tituloProcedimento2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_ESTUFA_2;
               state.testeSelecionado.acceptanceCriteria =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_CRITERIO_ACEITACAO_AUTOCLAVE;
               state.testeSelecionado.tituloProcedimento3 = null;
               state.testeSelecionado.procedureDescription3 = null;
               state.mostrarProcedimento3 = false;
               break;

            case Constants.AQT_TIPO_INCUBADORA:
            case Constants.AQT_TIPO_REFRIGERADOR:
               state.testeSelecionado.procedureDescription =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_REFRIGERADOR_INCUBADORA_1;
               state.testeSelecionado.procedureDescription2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_REFRIGERADOR_INCUBADORA_2;
               state.testeSelecionado.procedureDescription3 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_REFRIGERADOR_INCUBADORA_3;
               state.testeSelecionado.tituloProcedimento =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_REFRIGERADOR_INCUBADORA_1;
               state.testeSelecionado.tituloProcedimento2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_REFRIGERADOR_INCUBADORA_2;
               state.testeSelecionado.tituloProcedimento3 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_REFRIGERADOR_INCUBADORA_3;
               state.testeSelecionado.acceptanceCriteria =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_CRITERIO_ACEITACAO_REFRIGERADOR;
               state.mostrarProcedimento3 = true;
               break;

            case Constants.AQT_TIPO_CAMARA:
               state.testeSelecionado.procedureDescription =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_CAMARA_1;
               state.testeSelecionado.procedureDescription2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_CAMARA_2;
               state.testeSelecionado.procedureDescription3 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_PROCEDIMENTO_CAMARA_3;
               state.testeSelecionado.tituloProcedimento =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_CAMARA_1;
               state.testeSelecionado.tituloProcedimento2 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_CAMARA_2;
               state.testeSelecionado.tituloProcedimento3 =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TITULO_PROCEDIMENTO_CAMARA_3;
               state.testeSelecionado.acceptanceCriteria =
                  LANG.telaDeImportacaoDeTesteAQT.AQT_TEXTO_CRITERIO_ACEITACAO_REFRIGERADOR;
               state.mostrarProcedimento3 = true;
               break;

            default:
               break;
         }
      });
   };

   entradaDeDados = () => {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'auto',
                  width: '100%',
               }}
            >
               <Container fluid>
                  <div style={{ marginTop: 8 }}>
                     <strong style={{ fontSize: 14 }}>
                        {LANG.telaDeImportacaoDeTesteAQT.procedimentoDeQualificacaoTermica}
                     </strong>
                  </div>

                  <Line marginTop={8} marginBottom={0} />

                  <Row>
                     <Col>
                        <br />
                        <FormGroup>
                           <strong>{LANG.telaDeImportacaoDeTesteAQT.nomeDoEnsaio}:&nbsp;</strong>
                           <span>{this.state.ensaioSelecionado.descricao}</span>
                        </FormGroup>
                     </Col>
                     <Col>
                        <br />
                        <FormGroup>
                           <strong>{LANG.telaDeImportacaoDeTesteAQT.tipoDeTeste}:&nbsp;</strong>
                           <span>{this.state.testeSelecionado.descTipoTeste}</span>
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <strong>{LANG.telaDeImportacaoDeTesteAQT.tempoDaFase1}:&nbsp;</strong>
                           <br />
                           <span>{this.state.testeSelecionado.descTempoFase1}</span>
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <strong>{LANG.telaDeImportacaoDeTesteAQT.tempoDaFase2}:&nbsp;</strong>
                           <br />
                           <span>{this.state.testeSelecionado.descTempoFase2}</span>
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <strong>{LANG.telaDeImportacaoDeTesteAQT.tempoDaFase3}:&nbsp;</strong>
                           <br />
                           <span>{this.state.testeSelecionado.descTempoFase3}</span>
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <strong>{LANG.telaDeImportacaoDeTesteAQT.tempoDaFase4}:&nbsp;</strong>
                           <br />
                           <span>{this.state.testeSelecionado.descTempoFase4}</span>
                        </FormGroup>
                     </Col>
                  </Row>

                  <Line marginTop={8} marginBottom={12} />

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{LANG.telaDeImportacaoDeTesteAQT.nomeDaFase1}</Label>
                           <Select
                              defaultValue={this.state.testeSelecionado.indiceFase1}
                              options={[
                                 { id: 0, descricao: 'Tempo de Pré-Vácuo' },
                                 { id: 1, descricao: 'Tempo de Aquecimento' },
                                 { id: 2, descricao: 'Tempo de Esterilização' },
                                 { id: 3, descricao: 'Tempo de Secagem' },
                                 { id: 4, descricao: 'Tempo de Conservação' },
                                 { id: 5, descricao: 'Tempo de Despressurização' },
                                 { id: 6, descricao: 'Tempo de Enxágue' },
                                 { id: 7, descricao: 'Tempo de Lavagem' },
                                 { id: 8, descricao: 'Tempo de Desinfecção' },
                                 { id: 9, descricao: 'Tempo de Umectação' },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              allowEmpty={false}
                              acceptZero={true}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.descFase1 = i.descricao;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{LANG.telaDeImportacaoDeTesteAQT.nomeDaFase2}</Label>
                           <Select
                              defaultValue={this.state.testeSelecionado.indiceFase2}
                              options={[
                                 { id: 0, descricao: 'Tempo de Pré-Vácuo' },
                                 { id: 1, descricao: 'Tempo de Aquecimento' },
                                 { id: 2, descricao: 'Tempo de Esterilização' },
                                 { id: 3, descricao: 'Tempo de Secagem' },
                                 { id: 4, descricao: 'Tempo de Conservação' },
                                 { id: 5, descricao: 'Tempo de Despressurização' },
                                 { id: 6, descricao: 'Tempo de Enxágue' },
                                 { id: 7, descricao: 'Tempo de Lavagem' },
                                 { id: 8, descricao: 'Tempo de Desinfecção' },
                                 { id: 9, descricao: 'Tempo de Umectação' },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              allowEmpty={false}
                              acceptZero={true}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.descFase2 = i.descricao;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{LANG.telaDeImportacaoDeTesteAQT.nomeDaFase3}</Label>
                           <Select
                              defaultValue={this.state.testeSelecionado.indiceFase3}
                              options={[
                                 { id: 0, descricao: 'Tempo de Pré-Vácuo' },
                                 { id: 1, descricao: 'Tempo de Aquecimento' },
                                 { id: 2, descricao: 'Tempo de Esterilização' },
                                 { id: 3, descricao: 'Tempo de Secagem' },
                                 { id: 4, descricao: 'Tempo de Conservação' },
                                 { id: 5, descricao: 'Tempo de Despressurização' },
                                 { id: 6, descricao: 'Tempo de Enxágue' },
                                 { id: 7, descricao: 'Tempo de Lavagem' },
                                 { id: 8, descricao: 'Tempo de Desinfecção' },
                                 { id: 9, descricao: 'Tempo de Umectação' },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              allowEmpty={false}
                              acceptZero={true}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.descFase3 = i.descricao;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{LANG.telaDeImportacaoDeTesteAQT.nomeDaFase4}</Label>
                           <Select
                              defaultValue={this.state.testeSelecionado.indiceFase4}
                              options={[
                                 { id: 0, descricao: 'Tempo de Pré-Vácuo' },
                                 { id: 1, descricao: 'Tempo de Aquecimento' },
                                 { id: 2, descricao: 'Tempo de Esterilização' },
                                 { id: 3, descricao: 'Tempo de Secagem' },
                                 { id: 4, descricao: 'Tempo de Conservação' },
                                 { id: 5, descricao: 'Tempo de Despressurização' },
                                 { id: 6, descricao: 'Tempo de Enxágue' },
                                 { id: 7, descricao: 'Tempo de Lavagem' },
                                 { id: 8, descricao: 'Tempo de Desinfecção' },
                                 { id: 9, descricao: 'Tempo de Umectação' },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              allowEmpty={false}
                              acceptZero={true}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.descFase4 = i.descricao;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{LANG.telaDeImportacaoDeTesteAQT.tipoDeEquipamento}</Label>
                           <Select
                              defaultValue={this.state.testeSelecionado.tipoEquipamento}
                              options={[
                                 { id: 0, descricao: 'Autoclaves' },
                                 { id: 1, descricao: 'Lavadoras e Desinfetadoras' },
                                 { id: 2, descricao: 'Estufas de esterilização e despirogenização' },
                                 { id: 3, descricao: 'Estufas de incubação' },
                                 { id: 4, descricao: 'Refrigeradores, Congeladores e Câmaras frias' },
                                 { id: 5, descricao: 'Câmaras climáticas' },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              allowEmpty={false}
                              acceptZero={true}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.tipoEquipamento = i.id;
                                 }).then(() => {
                                    this.setTextoDoProcedimento(this.state.testeSelecionado.tipoEquipamento);
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{LANG.telaDeImportacaoDeTesteAQT.tipoDeEquipamento}</Label>
                           <TextInput
                              defaultValue={this.state.testeSelecionado.tipoCarga}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.tipoCarga = e.target.value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <CheckBox
                           label={LANG.telaDeImportacaoDeTesteAQT.imprimirTextos}
                           onChange={(checked) => {
                              updateState(this, (state) => {
                                 state.testeSelecionado.imprimirTextos = checked;
                              });
                           }}
                        />
                        <br />
                     </Col>
                  </Row>

                  {this.state.testeSelecionado.imprimirTextos && (
                     <Fragment>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoIntroducaoTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.introductionDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.introductionDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoObjetivoTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.objectiveDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.objectiveDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoNormaTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.normDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.normDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoTermosTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.termDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.termDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Line marginTop={8} marginBottom={12} />

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.procedimentoDeQualificacaoTermica}</Label>
                                 <TextInput
                                    defaultValue={this.state.testeSelecionado.tituloProcedimento}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.tituloProcedimento = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.procedimentoDeQualificacao}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.procedureDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.procedureDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Line marginTop={8} marginBottom={12} />

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.procedimentoDeQualificacaoTermica}</Label>
                                 <TextInput
                                    defaultValue={this.state.testeSelecionado.tituloProcedimento2}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.tituloProcedimento2 = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.procedimentoDeQualificacao}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.procedureDescription2}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.procedureDescription2 = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Line marginTop={8} marginBottom={12} />

                        {this.state.mostrarProcedimento3 && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>
                                          {LANG.telaDeImportacaoDeTesteAQT.procedimentoDeQualificacaoTermica}
                                       </Label>
                                       <TextInput
                                          defaultValue={this.state.testeSelecionado.tituloProcedimento3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.testeSelecionado.tituloProcedimento3 = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{LANG.telaDeImportacaoDeTesteAQT.procedimentoDeQualificacao}</Label>
                                       <TextArea
                                          defaultValue={this.state.testeSelecionado.procedureDescription3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.testeSelecionado.procedureDescription3 = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Line marginTop={8} marginBottom={12} />
                           </Fragment>
                        )}

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoCriterioAceitacaoTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.acceptanceCriteria}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.acceptanceCriteria = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoConclusaoTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.resultDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.resultDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoIndicadorTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.indicatorDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.indicatorDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{LANG.telaDeImportacaoDeTesteAQT.textoEquacaoTitulo}</Label>
                                 <TextArea
                                    defaultValue={this.state.testeSelecionado.equationDescription}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.equationDescription = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     </Fragment>
                  )}

                  <br />

                  <Button
                     onClick={() => {
                        updateState(this, (state) => {
                           state.abaSelecionada = 'selecaoDeCanais';
                        });
                     }}
                     text={LANG.telaDeImportacaoDeTesteAQT.proximo}
                     style={{ width: 200 }}
                  />
                  <br />
               </Container>
            </div>
         </Fragment>
      );
   };

   testesSelecionados = () => {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'hidden',
               }}
            >
               {this.state.testesSelecionados && (
                  <ListaPadraoOffline
                     esconderTitulo={true}
                     esconderBotaoFechar={true}
                     esconderConsulta={true}
                     api={this.props.api}
                     lang={LANG}
                     getItens={() => {
                        let result = this.state.testesSelecionados;
                        return result;
                     }}
                     getTitulosDaTabela={() => {
                        return [
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.ensaio, className: 'codigo' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.teste, width: '10%' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.tipoDeTeste, width: '40%' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.norma, width: '15%' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.data, width: '30%' },
                           { titulo: '', width: '5%' },
                        ];
                     }}
                     getDadosDaTabela={(item, index) => {
                        return [
                           item.numeroEnsaio,
                           item.numTeste,
                           item.descTipoTeste,
                           item.descNorma,
                           item.dataHora,
                           <div style={{ display: 'flex' }}>
                              <IconButton
                                 icon={faTrashAlt}
                                 style={{ margin: 'auto', fontSize: 18 }}
                                 title={LANG.excluir}
                                 onClick={() => {
                                    updateState(this, (state) => {
                                       state.testesSelecionados.splice(index, 1);
                                       if (state.testesSelecionados.length === 0) {
                                          state.abaSelecionada = 'ensaios';
                                       }
                                       this.props.setTestesAQT(state.testesSelecionados);
                                    });
                                 }}
                              />
                           </div>,
                        ];
                     }}
                  />
               )}
            </div>
         </Fragment>
      );
   };

   selecaoDeCanais = () => {
      return (
         <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>
            <div style={{ paddingTop: 10, paddingLeft: 10, paddingRigth: 10 }}>
               <Select
                  defaultValue={this.state.numeroDoModuloSelecionado}
                  options={[
                     { id: 0, descricao: 'Módulo A' },
                     { id: 1, descricao: 'Módulo B' },
                  ]}
                  getKeyValue={(i) => i.id}
                  getDescription={(i) => i.descricao}
                  allowEmpty={false}
                  acceptZero={true}
                  onSelect={(i) => {
                     updateState(this, (state) => {
                        state.numeroDoModuloSelecionado = i.id;
                     });
                  }}
               />
            </div>
            {this.renderizarModulo(0)}
            {this.renderizarModulo(1)}
         </div>
      );
   };

   renderizarModulo = (numeroDoModulo) => {
      let item = this.state.testeSelecionado;
      let modulo = numeroDoModulo === 0 ? item.dadosDoModuloA : numeroDoModulo === 1 ? item.dadosDoModuloB : null;

      if (!modulo) {
         return null;
      }

      let descTemp = [];
      let numeroDeCanais = item.numeroDeCanais;

      let i = 0;

      switch (item.tipoTeste) {
         case Constants.AQT_DISTRIBUICAO_TEMPERATURA:
         case Constants.AQT_PENETRACAO_TEMPERATURA:
         case Constants.AQT_CICLOS_AJUSTAVEIS:
         case Constants.AQT_DETECCAO_ESTABILIDADE:
            for (i = 0; i < numeroDeCanais; i++) {
               descTemp.push(
                  modulo.descTemperaturaMinima[i] +
                     ' / ' +
                     modulo.descTemperaturaMedia[i] +
                     ' / ' +
                     modulo.descTemperaturaMaxima[i]
               );
            }
            break;

         case Constants.AQT_MANUAL:
            for (let i = 0; i < numeroDeCanais; i++) {
               descTemp.push(modulo.descTemperaturaMedida[i]);
            }
            break;

         case Constants.AQT_VAZAMENTO:
            for (let i = 0; i < numeroDeCanais; i++) {
               descTemp.push('');
            }
            break;

         default:
            break;
      }

      if (!modulo.canaisSelecionados) {
         updateState(this, (state) => {
            modulo.canaisSelecionados = [];
            modulo.canaisSelecionados.push(descTemp.map(() => true));

            let index = 0;
            let temperatura = null;
            for (index = 0; index < numeroDeCanais; index++) {
               switch (item.tipoTeste) {
                  case Constants.AQT_DISTRIBUICAO_TEMPERATURA:
                     if (modulo.tempoFase3 !== 0) temperatura = modulo.temperaturaMedia[index];
                     else {
                        temperatura = 121;
                     }
                     break;

                  case Constants.AQT_PENETRACAO_TEMPERATURA:
                     temperatura = modulo.temperaturaMedia[index];
                     break;

                  case Constants.AQT_MANUAL:
                     temperatura = modulo.temperaturaMedida[index];
                     break;

                  case Constants.AQT_CICLOS_AJUSTAVEIS:
                     temperatura = modulo.temperaturaMedia[index];
                     break;

                  case Constants.AQT_DETECCAO_ESTABILIDADE:
                     temperatura = modulo.temperaturaMedia[index];
                     break;

                  case Constants.AQT_VAZAMENTO:
                     temperatura = -400;
                     break;

                  default:
                     break;
               }
               this.setCanalSelecionado(numeroDoModulo, index + 1, temperatura !== -400);
            }
         });
      }

      let dados = [];

      for (i = 0; i < numeroDeCanais; i++) {
         dados.push({ parametro: `Temperatura ${i + 1} (min/med/max) (ºC)`, resultado: descTemp[i] });
      }

      return (
         this.state.numeroDoModuloSelecionado === numeroDoModulo && (
            <Fragment>
               <div
                  style={{
                     display: 'flex',
                     overflowY: 'auto',
                     width: '100%',
                     flexDirection: 'column',
                  }}
               >
                  <ListaPadraoOffline
                     esconderTitulo={true}
                     esconderBotaoFechar={true}
                     esconderConsulta={true}
                     api={this.props.api}
                     lang={LANG}
                     getItens={() => dados}
                     getTitulosDaTabela={() => {
                        return [
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.parametro, width: '50%' },
                           { titulo: LANG.telaDeImportacaoDeTesteAQT.resultado, width: '50%' },
                        ];
                     }}
                     getDadosDaTabela={(item, index) => {
                        return [item.parametro, item.resultado];
                     }}
                  />
                  {modulo.canaisSelecionados && (
                     <Container fluid>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                           {descTemp.map((x, index) => {
                              let canal = index + 1;
                              return (
                                 <div style={{ display: 'block', width: 160 }}>
                                    <CheckBox
                                       defaultChecked={modulo.canaisSelecionados[index]}
                                       label={LANG.telaDeImportacaoDeTesteAQT.temperaturaDoCanal + ' ' + canal}
                                       onChange={(checked) => {
                                          this.setCanalSelecionado(numeroDoModulo, canal, checked);
                                       }}
                                    />
                                 </div>
                              );
                           })}
                        </div>

                        <br />

                        <Button
                           onClick={() => {
                              updateState(this, (state) => {
                                 state.abaSelecionada = 'imagensEAnexos';
                              });
                           }}
                           text={LANG.telaDeImportacaoDeTesteAQT.proximo}
                           style={{ width: 200 }}
                        />
                        <br />
                     </Container>
                  )}
               </div>
            </Fragment>
         )
      );
   };

   setCanalSelecionado = (numeroDoModulo, canal, selecionado) => {
      let item = this.state.testeSelecionado;
      let modulo = numeroDoModulo === 0 ? item.dadosDoModuloA : numeroDoModulo === 1 ? item.dadosDoModuloB : null;

      if (!modulo) {
         return null;
      }
      updateState(this, (state) => {
         modulo.canaisSelecionados[canal - 1] = selecionado;
         state.testeSelecionado.anexarImagemDoGrafico = false;
         state.testeSelecionado.dadosDoGrafico0 = null;
         state.testeSelecionado.dadosDoGrafico1 = null;
         state.testeSelecionado.esconderCheckboxAnexarImagemDoGrafico = true;
      }).then(() => {
         updateState(this, (state) => {
            state.testeSelecionado.esconderCheckboxAnexarImagemDoGrafico = false;
         });
      });
   };

   imagensEAnexos = () => {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'auto',
                  width: '100%',
                  flexDirection: 'column',
               }}
            >
               <Container>
                  <br />
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>
                              {
                                 LANG.telaDeImportacaoDeTesteAQT
                                    .insiraUmaFotoDoEquipamentoAntesDoProcedimentoDeCicloVazio
                              }
                           </Label>
                           <Document
                              api={this.props.api}
                              lang={this.props.lang}
                              defaultValue={this.state.testeSelecionado.imagemDeAntesDoCicloVazio}
                              accept={'image/bmp, image/jpg, image/jpeg, image/png'}
                              onChange={(file) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.imagemDeAntesDoCicloVazio = file;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>
                              {
                                 LANG.telaDeImportacaoDeTesteAQT
                                    .insiraUmaFotoDoEquipamentoAntesDoProcedimentoDeCicloComCarga
                              }
                           </Label>
                           <Document
                              api={this.props.api}
                              lang={this.props.lang}
                              defaultValue={this.state.testeSelecionado.imagemDeAntesDoCicloComCarga}
                              accept={'image/bmp, image/jpg, image/jpeg, image/png'}
                              onChange={(file) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.imagemDeAntesDoCicloComCarga = file;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <CheckBox
                              defaultChecked={this.state.testeSelecionado.anexarTabela}
                              label={LANG.telaDeImportacaoDeTesteAQT.anexarTabela}
                              onChange={(checked) => {
                                 updateState(this, (state) => {
                                    state.testeSelecionado.anexarTabela = checked;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           {!this.state.esconderCheckboxAnexarImagemDoGrafico && (
                              <CheckBox
                                 defaultChecked={this.state.testeSelecionado.anexarImagemDoGrafico}
                                 label={LANG.telaDeImportacaoDeTesteAQT.anexarImagemDoGrafico}
                                 onChange={(checked) => {
                                    updateState(this, (state) => {
                                       state.testeSelecionado.anexarImagemDoGrafico = checked;
                                    });
                                    if (checked) {
                                       this.gerarImagemDoGrafico(0);
                                       this.gerarImagemDoGrafico(1);
                                    } else {
                                       updateState(this, (state) => {
                                          state.testeSelecionado.dadosDoGrafico0 = null;
                                          state.testeSelecionado.dadosDoGrafico1 = null;
                                       });
                                    }
                                 }}
                              />
                           )}
                        </FormGroup>
                     </Col>
                  </Row>

                  {this.state.testeSelecionado.dadosDoGrafico0 && (
                     <Fragment>
                        <ChartLine
                           ref={(c) => (this.chartRef0 = c)}
                           options={{
                              responsive: true,
                              plugins: {
                                 legend: {
                                    position: 'right',
                                 },
                                 title: {
                                    display: true,
                                    text:
                                       'Gráfico AQT - ' +
                                       this.state.ensaioSelecionado.descricao +
                                       ' (' +
                                       this.state.testeSelecionado.descTipoTeste +
                                       ')',
                                 },
                              },
                              elements: {
                                 point: {
                                    radius: 0,
                                 },
                              },
                              scales: {
                                 x: {
                                    title: {
                                       display: true,
                                       text: 'Tempo (s)',
                                    },
                                 },
                                 y: {
                                    type: 'linear',
                                    display: true,
                                    position: 'left',
                                    title: {
                                       display: true,
                                       text: 'Temperatura (ºC)',
                                    },
                                 },
                                 y1: {
                                    type: 'linear',
                                    display: true,
                                    position: 'right',
                                    title: {
                                       display: true,
                                       text: 'Pressão (KPa)',
                                    },
                                    grid: {
                                       drawOnChartArea: false,
                                    },
                                 },
                              },
                           }}
                           data={this.state.testeSelecionado.dadosDoGrafico0}
                        />
                     </Fragment>
                  )}

                  {this.state.testeSelecionado.dadosDoGrafico1 && (
                     <Fragment>
                        <ChartLine
                           ref={(c) => (this.chartRef1 = c)}
                           options={{
                              responsive: true,
                              plugins: {
                                 legend: {
                                    position: 'right',
                                 },
                                 title: {
                                    display: true,
                                    text:
                                       'Gráfico AQT - ' +
                                       this.state.ensaioSelecionado.descricao +
                                       ' (' +
                                       this.state.testeSelecionado.descTipoTeste +
                                       ')',
                                 },
                              },
                              elements: {
                                 point: {
                                    radius: 0,
                                 },
                              },
                              scales: {
                                 x: {
                                    title: {
                                       display: true,
                                       text: 'Tempo (s)',
                                    },
                                 },
                                 y: {
                                    type: 'linear',
                                    display: true,
                                    position: 'left',
                                    title: {
                                       display: true,
                                       text: 'Temperatura (ºC)',
                                    },
                                 },
                                 y1: {
                                    type: 'linear',
                                    display: true,
                                    position: 'right',
                                    title: {
                                       display: true,
                                       text: 'Pressão (KPa)',
                                    },
                                    grid: {
                                       drawOnChartArea: false,
                                    },
                                 },
                              },
                           }}
                           data={this.state.testeSelecionado.dadosDoGrafico1}
                        />
                     </Fragment>
                  )}

                  <br />
                  <Button
                     onClick={() => {
                        updateState(this, (state) => {
                           if (state.testeSelecionado.dadosDoModuloA) {
                              if (state.testeSelecionado.anexarImagemDoGrafico) {
                                 let base64 = this.chartRef0.toBase64Image();
                                 base64 = base64.replace('data:image/png;base64,', '');
                                 state.testeSelecionado.dadosDoModuloA.imagemDoGraficoEmBase64 = base64;
                              } else {
                                 state.testeSelecionado.dadosDoModuloA.imagemDoGraficoEmBase64 = null;
                              }
                           }

                           if (state.testeSelecionado.dadosDoModuloB) {
                              if (state.testeSelecionado.anexarImagemDoGrafico) {
                                 let base64 = this.chartRef1.toBase64Image();
                                 base64 = base64.replace('data:image/png;base64,', '');
                                 state.testeSelecionado.dadosDoModuloB.imagemDoGraficoEmBase64 = base64;
                              } else {
                                 state.testeSelecionado.dadosDoModuloB.imagemDoGraficoEmBase64 = null;
                              }
                           }

                           if (!state.testesSelecionados) {
                              state.testesSelecionados = [];
                           }

                           state.testesSelecionados.push(this.state.testeSelecionado);
                           this.props.setTestesAQT(state.testesSelecionados);
                        }).then(() => {
                           showInfo(LANG.telaDeImportacaoDeTesteAQT.testeAdicionadoComSucesso).then(() => {
                              updateState(this, (state) => {
                                 state.ensaioSelecionado = null;
                                 state.ensaioSelecionadoHighlightIndex = null;
                                 state.testeSelecionado = null;
                                 state.testeSelecionadoHighlightIndex = null;
                                 state.abaSelecionada = 'testesSelecionados';
                              });
                           });
                        });
                     }}
                     text={LANG.telaDeImportacaoDeTesteAQT.adicionarTeste}
                     style={{ width: 200 }}
                  />
                  <br />
               </Container>
            </div>
         </Fragment>
      );
   };

   gerarImagemDoGrafico = (numeroDoModulo) => {
      let item = this.state.testeSelecionado;

      let modulo = numeroDoModulo === 0 ? item.dadosDoModuloA : numeroDoModulo === 1 ? item.dadosDoModuloB : null;

      if (!modulo) {
         return null;
      }

      let numeroDeAmostras = modulo.tempo.length;
      let labels = [];
      for (var i = 0; i < numeroDeAmostras; i++) {
         labels.push(modulo.tempo[i].toString());
      }

      const getDadosDoCanal = (canal) => {
         let dados = [];
         for (let i = 0; i < numeroDeAmostras; i++) {
            let x = i * item.numeroDeCanais + canal;
            let v = modulo.temperaturaInstantanea[x - 1];
            dados.push(v);
         }
         return dados;
      };

      const colors =
         item.numeroDeCanais === 15
            ? [
                 '#FF0000',
                 '#FFFF00',
                 '#00EAFF',
                 '#AA00FF',
                 '#FF7F00',
                 '#BFFF00',
                 '#0095FF',
                 '#FF00AA',
                 '#FFD400',
                 '#6AFF00',
                 '#0040FF',
                 '#EDB9B9',
                 '#B9D7ED',
                 '#E7E9B9',
                 '#DCB9ED',
              ]
            : [
                 //'green',
                 //'blue',
                 //'red',
                 //'yellow',
                 //'orange',
                 //'black',
                 //'violet',
                 //'purple',
                 //'cyan',
                 //'burlywood',
                 //'lughtblue',
                 //'darkolivegreen',
                 //'pink',
                 //'brown',
                 //'gold',

                 '#FF0000',
                 '#FFFF00',
                 '#00EAFF',
                 '#AA00FF',
                 '#FF7F00',
                 '#BFFF00',
                 '#0095FF',
                 '#FF00AA',
                 '#FFD400',
                 '#6AFF00',
                 '#0040FF',
                 '#EDB9B9',
                 '#B9D7ED',
                 '#E7E9B9',
                 '#DCB9ED',
                 '#B9EDE0',
                 '#8F2323',
                 '#23628F',
                 '#8F6A23',
                 '#6B238F',
                 '#4F8F23',
                 '#000000',
                 '#737373',
                 '#CCCCCC',
              ];

      const getDadasetDoCanal = (canal) => {
         return {
            label: 'Canal ' + canal,
            data: getDadosDoCanal(canal),
            borderColor: colors[canal - 1],
            yAxisID: 'y',
            borderWidth: 1.5,
         };
      };

      let datasets = [];
      for (var canal = 1; canal <= modulo.numeroDeCanais; canal++) {
         if (modulo.temperaturaInstantanea[canal - 1] !== -400 && modulo.canaisSelecionados[canal - 1]) {
            datasets.push(getDadasetDoCanal(canal));
         }
      }

      let dadosDePressao = [];

      if (modulo.pressaoInstantanea[0] !== -400) {
         for (let i = 0; i < numeroDeAmostras; i++) {
            dadosDePressao.push(modulo.pressaoInstantanea[i]);
         }

         datasets.push({
            label: 'Pressão',
            data: dadosDePressao,
            borderColor: 'gray',
            yAxisID: 'y1',
            borderWidth: 1.5,
         });
      }

      let data = {
         labels: labels,
         datasets: datasets,
      };

      if (numeroDoModulo === 0) {
         updateState(this, (state) => {
            state.testeSelecionado.dadosDoGrafico0 = data;
         });
      }

      if (numeroDoModulo === 1) {
         updateState(this, (state) => {
            state.testeSelecionado.dadosDoGrafico1 = data;
         });
      }
   };
}

const StyledNav = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;

   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
      background-color: ${LayoutParams.colors.corDoTemaPrincipal};
   }

   .tab-content {
      overflow-y: hidden;
   }

   .tab-pane.active {
      display: flex;
   }
`;
