import { BaseController } from '../BaseController';

export default class TipoDeEquipamentoController extends BaseController {
    constructor(props) {
        super(props);
        this.name = 'TipoDeEquipamentoController';
    }

    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
            { titulo: this.lang.telaDeCadastroDeTipoDeEquipamento.nome, width: '80%', orderby: 'Nome' },
            { titulo: this.lang.telaDeCadastroDeTipoDeEquipamento.sigla, width: '20%', orderby: 'Sigla' },
        ];
    };

    getDadosDaTabela = (item) => {
        return [item.id, item.nome, item.sigla];
    };
}
