import { showError } from '../../components/Messages';
import { BaseController } from './../BaseController';

export default class CargoController extends BaseController {
    constructor(props) {
        super(props);
        this.name = 'CargoController';
    }

    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
            { titulo: this.lang.telaDeCadastroDeCargo.nome, width: '100%', orderby: 'Nome' },
        ];
    };

    getDadosDaTabela = (item) => {
        return [item.id, item.nome ];
    };

    getObjetoDeDados = () => {
        return new Promise((resolve, reject) => {
            let state = this.view.state;
            let item = state.itemSelecionado;

            if (!item.nome) {
                showError(this.lang.telaDeCadastroDeCargo.mensagens.informeONomeDoCargo);
                reject();
                return;
            }

            var input = {
                nome: item.nome,
            };

            if (state.alterando) {
                input.id = parseInt(item.id);
            }
            resolve(input);
        });
    };
}
