import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class ParametroDoSistemaController extends BaseController {
    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
            { titulo: this.lang.telaDeCadastroDeParametrosDoSistema.descricao, width: '50%', orderby: 'Descricao' },
            { titulo: this.lang.telaDeCadastroDeParametrosDoSistema.valor, width: '50%', orderby: 'Valor' },
        ];
    };

    getDadosDaTabela = (item) => {
        return [
            item.id,
            item.descricao,
            item.protegido && item.preenchido ? this.lang.telaDeCadastroDeParametrosDoSistema.protegido : item.valor,
        ];
    };

    getObjetoDeDados = () => {
        return new Promise((resolve, reject) => {
            let item = this.view.state.itemSelecionado;

            if (!item.nome) {
                showError(this.lang.telaDeCadastroDeParametrosDoSistema.mensagens.selecioneOParametro);
                reject();
                return;
            }

            var input = {
                grupo: item.grupo,
                ordem: item.ordem ? item.ordem : 0,
                nome: item.nome,
                descricao: item.descricao,
                valor: item.valor,
                protegido: item.protegido,
            };
            if (this.view.state.alterando) {
                input.id = parseInt(item.id);
            }
            resolve(input);
        });
    };
}
