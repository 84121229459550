import { showError, showConfirm } from '../../components/Messages';
import {
   updateState,
   buildQueryString,
   validarEndereco,
   validarTelefone,
   getEnderecoCompleto,
   rsaEncrypt,
} from '../../utils/Functions';
import { BaseController } from '../BaseController';
import btoa from 'btoa';

export default class ClienteController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'ClienteController';
      this.props = props.props;
   }

   antesDeInserir = () => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cnpjParaNaoConsultar = null;
            state.cpfParaNaoConsultar = null;
            resolve();
         });
      });
   };

   antesDeEditar = (formularioPadrao, cliente) => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cnpjParaNaoConsultar = cliente.cnpj;
            state.cpfParaNaoConsultar = cliente.cpf;
            resolve();
         });
      });
   };

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.telaDeCadastroDeCliente.codigo, orderby: 'id', className: 'codigo' },
         {
            titulo: this.lang.telaDeCadastroDeCliente.nomeCompletoOuRazaoSocial,
            width: '30%',
            orderby: 'nomeCompletoOuRazaoSocial',
         },
         {
            titulo: this.lang.telaDeCadastroDeCliente.apelidoOuNomeFantasia,
            width: '30%',
            orderby: 'apelidoOuNomeFantasia',
         },
         { titulo: this.lang.telaDeCadastroDeCliente.cpfCnpj, width: '20%', orderby: 'cpfCnpj' },
         { titulo: this.lang.telaDeCadastroDeCliente.email, width: '20%', orderby: 'email' },
      ];
   };

   getDadosDaTabela(item) {
      return [
         item.id,
         item.nomeCompletoOuRazaoSocial,
         item.apelidoOuNomeFantasia,
         item.cpfCnpj,
         <div style={{ wordBreak: 'break-all' }}>{item.email}</div>,
      ];
   }

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.tipoDePessoa) {
            showError(this.lang.telaDeCadastroDeCliente.mensagens.informeOTipoDePessoa);
            reject();
            return;
         }

         if (item.tipoDePessoa && item.tipoDePessoa.id === 1) {
            if (!item.nomeCompleto) {
               showError(this.lang.telaDeCadastroDeCliente.mensagens.informeONomeDaPessoa);
               reject();
               return;
            }

            if (!item.cpf) {
               showError(this.lang.telaDeCadastroDeCliente.mensagens.informeOCpf);
               reject();
               return;
            }

            if (!item.sexo) {
               showError(this.lang.telaDeCadastroDeCliente.mensagens.informeOSexo);
               reject();
               return;
            }

            if (item.sexo.id !== 1 && item.sexo.id !== 2) {
               showError(this.lang.telaDeCadastroDeCliente.mensagens.informeOSexo);
               reject();
               return;
            }
         }

         if (item.tipoDePessoa && item.tipoDePessoa.id === 2) {
            if (!item.razaoSocial) {
               showError(this.lang.telaDeCadastroDeCliente.mensagens.informeARazaoSocial);
               reject();
               return;
            }

            if (!item.nomeFantasia) {
               showError(this.lang.telaDeCadastroDeCliente.mensagens.informeONomeFantasia);
               reject();
               return;
            }

            if (!item.cnpj) {
               showError(this.lang.telaDeCadastroDeCliente.mensagens.informeOCnpj);
               reject();
               return;
            }
         }

         this.api
            .get('/publickey')
            .then((publicKey) => {
               let senhaCriptografada = null;
               let novaSenha = null;
               if (item.senha) {
                  let blocks = item.senha.match(new RegExp('.{1,' + 64 + '}', 'g'));
                  for (let i = 0; i < blocks.length; i++) {
                     let buffer = rsaEncrypt(blocks[i], publicKey);
                     senhaCriptografada = senhaCriptografada ? Buffer.concat([senhaCriptografada, buffer]) : buffer;
                  }
                  novaSenha = btoa(senhaCriptografada);
               }
               var input = {
                  nomeCompleto: item.nomeCompleto,
                  email: item.email,
                  sexo: item.sexo ? item.sexo.id : 0,
                  cpf: item.cpf,
                  cnpj: item.cnpj,
                  razaoSocial: item.razaoSocial,
                  nomeFantasia: item.nomeFantasia,
                  documentoDeIdentidade: item.documentoDeIdentidade,
                  orgaoExpedidorDoDocumentoDeIdentidade: item.orgaoExpedidorDoDocumentoDeIdentidade,
                  dataDeNascimento: item.dataDeNascimento,
                  dataDeCadastro: item.dataDeCadastro ? item.dataDeCadastro : new Date(),
                  foto: item.foto && item.foto.id ? item.foto : null,
                  telefones: item.telefones,
                  enderecos: item.enderecos,
                  redesSociais: item.redesSociais,
                  tipoDePessoa: item.tipoDePessoa ? item.tipoDePessoa.id : 0,
                  situacao: item.situacao ? item.situacao.id : 0,
                  apelido: item.apelido,
                  novaSenha: novaSenha,
                  senhaAlterada: item.senhaAlterada,
                  enviarNovaSenhaPorEmail: item.enviarNovaSenhaPorEmail,
                  equipamentos: item.equipamentos,
                  pais: item.pais,
               };

               if (state.alterando) {
                  input.id = parseInt(item.id);
               }
               resolve(input);
            })
            .catch((e) => {
               console.error(e);
               reject();
            });
      });
   };

   consultarCep = () => {
      if (
         this.view.state.enderecoSelecionado.endereco.cep &&
         this.view.state.enderecoSelecionado.endereco.cep.length === 9 &&
         this.view.state.enderecoSelecionado.endereco.cep !== this.view.state.cepParaNaoConsultar
      ) {
         this.props.consultaDeCep.get(this.view.state.enderecoSelecionado.endereco.cep).then((resultViaCep) => {
            if (resultViaCep && resultViaCep.localidade && resultViaCep.uf) {
               let url =
                  '/cidade' +
                  buildQueryString(null, null, null, {
                     uf: resultViaCep.uf.toUpperCase(),
                     localidade: resultViaCep.localidade.toUpperCase(),
                  });
               this.props.api
                  .getAll(url, false)
                  .then((result) => {
                     if (result.count === 1) {
                        let enderecoEmEdicao = { endereco: {} };
                        enderecoEmEdicao.cep = this.view.state.enderecoSelecionado.endereco.cep;
                        enderecoEmEdicao.logradouro = resultViaCep.logradouro
                           ? resultViaCep.logradouro.toUpperCase()
                           : null;
                        enderecoEmEdicao.bairro = resultViaCep.bairro ? resultViaCep.bairro.toUpperCase() : null;
                        enderecoEmEdicao.cidade = result.items[0];

                        updateState(this.view, (state) => {
                           state.enderecoSelecionado.endereco.logradouro = enderecoEmEdicao.logradouro;
                           state.enderecoSelecionado.endereco.bairro = enderecoEmEdicao.bairro;
                           state.enderecoSelecionado.endereco.cidade = enderecoEmEdicao.cidade;
                           state.mostrarEndereco = false;
                           state.cepParaNaoConsultar = this.view.state.enderecoSelecionado.endereco.cep;
                        }).then(() => {
                           updateState(this.view, (state) => {
                              state.mostrarEndereco = true;
                           });
                        });
                     }
                  })
                  .catch(() => {});
            } else {
               updateState(this.view, (state) => {
                  state.enderecoSelecionado.endereco.logradouro = null;
                  state.enderecoSelecionado.endereco.bairro = null;
                  state.enderecoSelecionado.endereco.cidade = null;
                  state.mostrarEndereco = false;
                  state.cepParaNaoConsultar = null;
               }).then(() => {
                  updateState(this.view, (state) => {
                     state.mostrarEndereco = true;
                  });
               });
            }
         });
      }
   };

   verificarSeCnpjJaExiste = () => {
      let item = this.view.state.itemSelecionado;
      if (item && item.cnpj && item.cnpj.length === 18 && item.cnpj !== this.view.state.cnpjParaNaoConsultar) {
         this.props.api
            .getAll(
               this.view.state.url +
                  buildQueryString(null, null, null, {
                     cnpj: this.view.state.itemSelecionado.cnpj,
                  })
            )
            .then((result) => {
               if (result && result.count > 0) {
                  let empresa = result.items[0];
                  let mensagem = this.lang.telaDeCadastroDeCliente.mensagens.cnpjJaCadastrado;
                  showConfirm(
                     mensagem,
                     () => {
                        updateState(this.view, (state) => {
                           state.incluindo = false;
                           state.alterando = false;
                        }).then(() => this.view.editar(empresa));
                     },
                     () => {
                        updateState(this.view, (state) => {
                           state.cnpjParaNaoConsultar = item.cnpj;
                        });
                     },
                     this.lang.sim,
                     this.lang.nao
                  );
               } else {
                  updateState(this.view, (state) => {
                     state.cnpjParaNaoConsultar = item.cnpj;
                  });
               }
            });
      }
   };

   verificarSeCpfJaExiste = () => {
      let item = this.view.state.itemSelecionado;
      if (item && item.cpf && item.cpf.length === 14 && item.cpf !== this.view.state.cpfParaNaoConsultar) {
         this.props.api
            .getAll(
               this.view.state.url +
                  buildQueryString(null, null, null, {
                     cpf: this.view.state.itemSelecionado.cpf,
                  })
            )
            .then((result) => {
               if (result && result.count > 0) {
                  let empresa = result.items[0];
                  let mensagem = this.lang.telaDeCadastroDeCliente.mensagens.cpfJaCadastrado;
                  showConfirm(
                     mensagem,
                     () => {
                        updateState(this.view, (state) => {
                           state.incluindo = false;
                           state.alterando = false;
                        }).then(() => this.view.editar(empresa));
                     },
                     () => {
                        updateState(this.view, (state) => {
                           state.cpfParaNaoConsultar = item.cpf;
                        });
                     },
                     this.lang.sim,
                     this.lang.nao
                  );
               } else {
                  updateState(this.view, (state) => {
                     state.cpfParaNaoConsultar = item.cpf;
                  });
               }
            });
      }
   };

   inserirEndereco = () => {
      return new Promise((resolve, reject) => {
         if (!validarEndereco(true, this.view.state.enderecoSelecionado.endereco, this.lang, reject)) {
            return;
         }

         let endereco = {
            logradouro: this.view.state.enderecoSelecionado.endereco.logradouro,
            numero: this.view.state.enderecoSelecionado.endereco.numero,
            complemento: this.view.state.enderecoSelecionado.endereco.complemento,
            bairro: this.view.state.enderecoSelecionado.endereco.bairro,
            cep: this.view.state.enderecoSelecionado.endereco.cep,
            cidade: this.view.state.enderecoSelecionado.endereco.cidade,
            pais: this.view.state.enderecoSelecionado.endereco.pais,
            linha1: this.view.state.enderecoSelecionado.endereco.linha1,
            linha2: this.view.state.enderecoSelecionado.endereco.linha2,
            linha3: this.view.state.enderecoSelecionado.endereco.linha3,
         };

         endereco.enderecoCompleto = getEnderecoCompleto(endereco);

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.enderecos) {
               state.itemSelecionado.enderecos = [];
            }
            state.itemSelecionado.enderecos.push({
               endereco,
            });
         }).then(() => resolve());
      });
   };

   alterarEndereco = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarEndereco(true, this.view.state.enderecoSelecionado.endereco, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.enderecos[index].endereco.logradouro = state.enderecoSelecionado.endereco.logradouro;
            state.itemSelecionado.enderecos[index].endereco.numero = state.enderecoSelecionado.endereco.numero;
            state.itemSelecionado.enderecos[index].endereco.complemento =
               state.enderecoSelecionado.endereco.complemento;
            state.itemSelecionado.enderecos[index].endereco.bairro = state.enderecoSelecionado.endereco.bairro;
            state.itemSelecionado.enderecos[index].endereco.cep = state.enderecoSelecionado.endereco.cep;
            state.itemSelecionado.enderecos[index].endereco.cidade = state.enderecoSelecionado.endereco.cidade;
            state.itemSelecionado.enderecos[index].endereco.pais = state.enderecoSelecionado.endereco.pais;
            state.itemSelecionado.enderecos[index].endereco.linha1 = state.enderecoSelecionado.endereco.linha1;
            state.itemSelecionado.enderecos[index].endereco.linha2 = state.enderecoSelecionado.endereco.linha2;
            state.itemSelecionado.enderecos[index].endereco.linha3 = state.enderecoSelecionado.endereco.linha3;
            state.itemSelecionado.enderecos[index].endereco.enderecoCompleto = getEnderecoCompleto(
               state.itemSelecionado.enderecos[index].endereco
            );
         }).then(() => resolve());
      });
   };

   inserirTelefone = () => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.telefones) {
               state.itemSelecionado.telefones = [];
            }
            state.itemSelecionado.telefones.push({
               pais: this.view.state.telefoneSelecionado.pais,
               ddd: this.view.state.telefoneSelecionado.ddd,
               numero: this.view.state.telefoneSelecionado.numero,
               temWhatsApp: this.view.state.telefoneSelecionado.temWhatsApp ? true : false,
            });
         }).then(() => resolve());
      });
   };

   alterarTelefone = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.telefones[index].pais = this.view.state.telefoneSelecionado.pais;
            state.itemSelecionado.telefones[index].ddd = this.view.state.telefoneSelecionado.ddd;
            state.itemSelecionado.telefones[index].numero = this.view.state.telefoneSelecionado.numero;
            state.itemSelecionado.telefones[index].temWhatsApp = this.view.state.telefoneSelecionado.temWhatsApp;
         }).then(() => resolve());
      });
   };

   inserirEquipamento = () => {
      return new Promise((resolve, reject) => {
         let item = this.view.state.equipamentoSelecionado;

         if (!item.numeroDeSerie) {
            showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeONumeroDeSerie);
            reject();
            return;
         }

         if (item.numeroDeSerie.length > 50) {
            showError(
               this.lang.telaDeCadastroDeEquipamento.mensagens.informeUmTamanhoMenorQue50CaracteresNoNumeroDeSerie
            );
            reject();
            return;
         }

         if (!item.tipoDeEquipamento) {
            showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeOTipoDeEquipamento);
            reject();
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.equipamentos) {
               state.itemSelecionado.equipamentos = [];
            }

            state.itemSelecionado.equipamentos.push({
               numeroDeSerie: item.numeroDeSerie,
               numeroDePatrimonio: item.numeroDePatrimonio,
               fabricante: item.fabricante,
               modelo: item.modelo,
               dataDeFabricacao: item.dataDeFabricacao,
               tipoDeEquipamento: item.tipoDeEquipamento,
            });
         }).then(() => resolve());
      });
   };

   alterarEquipamento = (index) => {
      return new Promise((resolve, reject) => {
         updateState(this.view, (state) => {
            let item = state.equipamentoSelecionado;
            state.itemSelecionado.equipamentos[index].numeroDeSerie = item.numeroDeSerie;
            state.itemSelecionado.equipamentos[index].numeroDePatrimonio = item.numeroDePatrimonio;
            state.itemSelecionado.equipamentos[index].fabricante = item.fabricante;
            state.itemSelecionado.equipamentos[index].modelo = item.modelo;
            state.itemSelecionado.equipamentos[index].dataDeFabricacao = item.dataDeFabricacao;
            state.itemSelecionado.equipamentos[index].tipoDeEquipamento = item.tipoDeEquipamento;
         }).then(() => resolve());
      });
   };
}
