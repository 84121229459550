import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import FilialController from '../../controllers/Empresa/FilialController';
import { updateState } from '../../utils/Functions';

export default class FilialView extends Component {
    constructor(props) {
        super(props);
        this.state = { mostrarAcessos: true };
        this.controller = new FilialController(this);
    }

    renderizarFormulario = () => {
        let lang = this.props.lang;
        let telaDeCadastroDeFilial = lang.telaDeCadastroDeFilial;
        return (
            <React.Fragment>
                <Row>
                    <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                            <Label>{lang.codigo}</Label>
                            <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                    </Col>

                    <Col sm={9} md={9} lg={9}>
                    <FormGroup>
                          <Label>{telaDeCadastroDeFilial.nome}</Label>
                          <TextInput
                              defaultValue={this.state.itemSelecionado.nome}
                              onChange={(e) => {
                                  updateState(this, (state) => {
                                      state.itemSelecionado.nome = e.target.value;
                                  });
                               }}
                               upperCase={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <br />
            </React.Fragment>
        );
    };
    
    render() {
        return (
            <FormularioPadrao
                titulo={this.props.lang.telaDeCadastroDeFilial.titulo}
                url={'/filialdeempresa'}
                api={this.props.api}
                lang={this.props.lang}
                ordenacaoPadrao={'nome'}
                permissoes={[1161, 1162, 1163, 1164]}
                getFiltro={this.controller.getFiltro}
                getTitulosDaTabela={this.controller.getTitulosDaTabela}
                getDadosDaTabela={this.controller.getDadosDaTabela}
                renderizarFormulario={this.renderizarFormulario}
                getObjetoDeDados={this.controller.getObjetoDeDados}
                antesDeInserir={this.controller.antesDeInserir}
                antesDeEditar={this.controller.antesDeEditar}
                antesDeSalvar={this.controller.antesDeSalvar}
                antesDeExcluir={this.controller.antesDeExcluir}
                getFormState={() => {
                    return this.state;
                }}
                setFormState={(state, callback) => {
                    return new Promise((resolve) => {
                        this.setState(state, () => {
                            resolve();
                            if (callback) {
                                callback();
                            }
                        });
                    });
                }}
                select={this.props.select}
                itemVazio={{
                    situacao: { id: 1 },
                    acessos: [],
                }}
            />
        );
    }
}
