import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import DateInput from '../../components/DateInput';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import EquipamentoDeEmpresaController from '../../controllers/Empresa/EquipamentoDeEmpresaController';
import { updateState, buildQueryString } from '../../utils/Functions';
import { showConfirm, showInfo, showError } from '../../components/Messages';
import ClienteView from '../Empresa/ClienteView';
import FilialView from '../Empresa/FilialView';
import DepartamentoView from '../Empresa/DepartamentoView';

const CADASTRO = 0;
const TRANSFERENCIA = 1;

export default class EquipamentoDeEmpresaView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true, situacaoDaTela: CADASTRO };
      this.controller = new EquipamentoDeEmpresaController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeEquipamento = lang.telaDeCadastroDeEquipamento;
      return (
         <React.Fragment>
            {this.state.situacaoDaTela === CADASTRO && (
               <React.Fragment>
                  <Row>
                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>{lang.codigo}</Label>
                           <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeEquipamento.numeroDeSerie}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.numeroDeSerie}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.numeroDeSerie = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>

                     <Col>
                        <Label>{telaDeCadastroDeEquipamento.dataDeFabricacao}</Label>
                        <DateInput
                           defaultValue={this.state.itemSelecionado.dataDeFabricacao}
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.dataDeFabricacao = value;
                              });
                           }}
                        />
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeEquipamento.tipoDeEquipamento}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.tipoDeEquipamento}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.tipoDeEquipamento = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeEquipamento.modelo}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.modelo}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.modelo = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeEquipamento.fabricante}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.fabricante}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.fabricante = e.target.value;
                                 });
                              }}
                              upperCase={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeEquipamento.filial}</Label>
                           <Select
                              name={'filial'}
                              defaultValue={this.state.itemSelecionado.filial}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.filial = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return <FilialView api={this.props.api} select={select} lang={this.props.lang} />;
                              }}
                              asws={true}
                              noDropDown={true}
                              getFilterUrl={(text) =>
                                 '/filialdeempresa' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeEquipamento.departamento}</Label>
                           <Select
                              name={'departamento'}
                              defaultValue={this.state.itemSelecionado.departamento}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.departamento = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <DepartamentoView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              getFilterUrl={(text) =>
                                 '/departamentodeempresa' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <br />

                  {this.state.itemSelecionado.id && (
                     <Row>
                        <Button
                           style={{ width: 300, marginLeft: 14 }}
                           text={this.props.lang.telaDeCadastroDeEquipamento.transferenciaDeEquipamento}
                           onClick={() => this.setState({ situacaoDaTela: TRANSFERENCIA })}
                        />
                     </Row>
                  )}
               </React.Fragment>
            )}

            {this.state.situacaoDaTela === TRANSFERENCIA && (
               <React.Fragment>
                  <br />
                  <br />
                  <Row>
                     <Col>
                        <span>
                           {
                              this.props.lang.telaDeCadastroDeEquipamento.mensagens
                                 .selecioneOClienteParaQualDesejaTransferirOEquipamento
                           }
                        </span>
                     </Col>
                  </Row>
                  <br />
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{this.props.lang.telaDeCadastroDeEquipamento.cliente}</Label>
                           <Select
                              name={'cliente'}
                              defaultValue={this.state.itemSelecionado.cliente}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.apelidoOuNomeFantasia}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.clienteSelecionadoParaTransferencia = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return <ClienteView api={this.props.api} select={select} lang={this.props.lang} />;
                              }}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/cliente/fast' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Button
                        style={{ width: 200, marginLeft: 14 }}
                        text={this.props.lang.cancelar}
                        onClick={() => this.setState({ situacaoDaTela: CADASTRO })}
                     />
                     <Button
                        style={{ width: 200, marginLeft: 14 }}
                        text={this.props.lang.confirmar}
                        inProgressText={this.props.lang.processando}
                        onClickAsync={() => {
                           return new Promise((resolve, reject) => {
                              if (!this.state.clienteSelecionadoParaTransferencia) {
                                 showError(this.props.lang.telaDeCadastroDeEquipamento.mensagens.selecioneOCliente);
                                 reject();
                                 return;
                              }

                              showConfirm(
                                 this.props.lang.telaDeCadastroDeEquipamento.mensagens
                                    .confirmaRealmenteATransferenciaDoEquipamento,
                                 () => {
                                    this.props.api
                                       .post('/equipamentodeempresa/transferirparacliente', {
                                          idDoEquipamento: this.state.itemSelecionado.id,
                                          idDoCliente: this.state.clienteSelecionadoParaTransferencia.id,
                                       })
                                       .then(() => {
                                          showInfo(
                                             this.props.lang.telaDeCadastroDeEquipamento.mensagens
                                                .equipamentoTransferido
                                          ).then(() => {
                                             this.setState({ situacaoDaTela: CADASTRO });
                                             this.formRef
                                                .cancelarClick()
                                                .then(() => this.formRef.filtrar(this.formRef));
                                          });
                                       })
                                       .catch((e) => reject(e));
                                 },
                                 () => {},
                                 this.props.lang.sim,
                                 this.props.lang.nao
                              );
                           });
                        }}
                     />
                  </Row>
               </React.Fragment>
            )}
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeEquipamento.titulo}
            url={'/equipamentodeempresa'}
            ref={(formRef) => (this.formRef = formRef)}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'numeroDeSerie'}
            permissoes={[1391, 1392, 1393, 1394]}
            getFiltro={this.controller.getFiltro}
            filtroExtra={this.props.filtroExtra}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               tipoDeEquipamento: [],
               cliente: this.props.cliente,
            }}
         />
      );
   }
}
