import React, { Component, Fragment } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import Label from '../../components/Label';
import DateInput from '../../components/DateInput';
import { dateToString, updateState, formatDate } from '../../utils/Functions';
import ListaPadrao from '../ListaPadrao';
import SubCadastro from '../../components/SubCadastro';
import Button from '../../components/Button';
import Line from '../../components/Line';
import { showError } from '../../components/Messages';
import TextInput from '../../components/TextInput';

let LANG = {};

export default class EquipamentosSobTeste extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      LANG = props.lang;
   }

   getFiltro = () => {
      this.setState({ equipamentoSelecionado: null });
      return new Promise((resolve, reject) => {
         resolve({
            empresa: this.state.empresa ? this.state.empresa.id : null,
            dataInicial: formatDate(this.state.dataInicial),
            dataFinal: formatDate(this.state.dataFinal),
            numeroDeSerie: this.state.numeroDeSerie,
            numeroDePatrimonio: this.state.numeroDePatrimonio,
            modelo: this.state.modelo,
            fabricante: this.state.fabricante,
         });
      });
   };

   renderizarFiltros = () => {
      return (
         <div style={{ maxWidth: 800 }}>
            {/* <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.relatorioDeCertificadosAVencer.empresa}</Label>
                     <Select
                        name={'empresa'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.empresa = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <EmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/empresa/fast' + buildQueryString(2, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row> */}
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{LANG.telaDeConsultaEquipamentosSobTeste.numeroDeSerie}</Label>
                     <TextInput onChange={(e) => this.setState({ numeroDeSerie: e.target.value })} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{LANG.telaDeConsultaEquipamentosSobTeste.numeroDePatrimonio}</Label>
                     <TextInput onChange={(e) => this.setState({ patrimonio: e.target.value })} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{LANG.telaDeConsultaEquipamentosSobTeste.modelo}</Label>
                     <TextInput onChange={(e) => this.setState({ modelo: e.target.value })} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{LANG.telaDeConsultaEquipamentosSobTeste.fabricante}</Label>
                     <TextInput onChange={(e) => this.setState({ fabricante: e.target.fabricante })} />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col style={{ maxWidth: 250 }}>
                  <FormGroup>
                     <Label>{LANG.telaDeConsultaEquipamentosSobTeste.dataDeValidadeInicial}</Label>
                     <DateInput
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataInicial = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col style={{ maxWidth: 250 }}>
                  <FormGroup>
                     <Label>{LANG.telaDeConsultaEquipamentosSobTeste.dataDeValidadeFinal}</Label>
                     <DateInput
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataFinal = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </div>
      );
   };

   certificadosDaOrdemDeServico = () => {
      let tela = LANG.telaDeConsultaEquipamentosSobTeste;

      return (
         <div style={{ padding: 8 }}>
            <SubCadastro
               titulo={tela.certificados}
               itens={this.state.equipamentoSelecionado.certificados}
               lang={this.props.lang}
               podeInserir={false}
               novo={() => {}}
               cancelar={() => {}}
               inserir={() => {}}
               selecionarParaAlteracao={(index) => {}}
               podeExcluir={false}
               colunas={() => {
                  return [
                     { titulo: tela.numeroDoCertificado, width: '25%', className: null },
                     { titulo: tela.dataDeEmissaoDoCertificado, width: '25%', className: null },
                     { titulo: tela.validade, width: '25%', className: null },
                     { titulo: tela.ordemDeServico, width: '10%', className: null },
                     { titulo: 'PDF', width: '15%', className: 'center' },
                  ];
               }}
               linha={(item) => {
                  return [
                     item.numero,
                     dateToString(item.data),
                     dateToString(item.dataDeValidade),
                     item.idDaOrdemDeServico,
                     <Button
                        style={{ width: 60, margin: 'auto', height: 25 }}
                        text='PDF'
                        inProgressText='...'
                        onClickAsync={() => this.baixarCertificado(item.idDaOrdemDeServico)}
                     />,
                  ];
               }}
               formulario={() => {
                  return <div></div>;
               }}
            />
         </div>
      );
   };

   getTitulosDaTabela = () => {
      return [
         { titulo: LANG.telaDeConsultaEquipamentosSobTeste.descricao, width: '25%' },
         { titulo: LANG.telaDeConsultaEquipamentosSobTeste.numeroDeSerie, width: '15%' },
         { titulo: LANG.telaDeConsultaEquipamentosSobTeste.numeroDePatrimonio, width: '15%' },
         { titulo: LANG.telaDeConsultaEquipamentosSobTeste.modelo, width: '15%' },
         { titulo: LANG.telaDeConsultaEquipamentosSobTeste.fabricante, width: '15%' },
         { titulo: LANG.telaDeConsultaEquipamentosSobTeste.dataDeValidadeDoCertificadoVigente, width: '15%' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.tipoDeEquipamento,
         item.numeroDeSerie,
         item.numeroDePatrimonio,
         item.modelo,
         item.fabricante,
         dateToString(item.dataDeValidadeDoCertificadoVigente),
      ];
   };

   baixarCertificado = (idDaOrdemDeServico) => {
      return new Promise((resolve, reject) => {
         this.props.api
            .post('/ordemdeservico/baixarcertificado', { id: idDaOrdemDeServico })
            .then((data) => {
               var byteCharacters = atob(data);
               var byteNumbers = new Array(byteCharacters.length);
               for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
               }
               var byteArray = new Uint8Array(byteNumbers);
               var file = new Blob([byteArray], { type: 'application/pdf;base64' });

               if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  // For IE
                  window.navigator.msSaveOrOpenBlob(file, 'mypdf.pdf');
               } else {
                  // For non-IE
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
               resolve();
            })
            .catch((e) => {
               showError(e.toString());
               reject(e);
            });
      });
   };

   render() {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  height: this.state.equipamentoSelecionado ? '60%' : '95%',
                  maxHeight: this.state.equipamentoSelecionado ? '60%' : '95%',
                  overflowY: 'hidden',
               }}
            >
               <ListaPadrao
                  titulo={LANG.telaDeConsultaEquipamentosSobTeste.titulo}
                  estruturaPadrao={true}
                  url={'/equipamentosobteste'}
                  api={this.props.api}
                  lang={this.props.lang}
                  getFiltro={this.getFiltro}
                  esconderBotaoFechar={true}
                  iniciarVazio={true}
                  renderizarFiltros={this.renderizarFiltros}
                  getTitulosDaTabela={this.getTitulosDaTabela}
                  getDadosDaTabela={this.getDadosDaTabela}
                  getFormState={() => this.state}
                  setFormState={(state, callback) => {
                     return new Promise((resolve) => {
                        this.setState(state, () => {
                           resolve();
                           if (callback) {
                              callback();
                           }
                        });
                     });
                  }}
                  onRowClick={(item, index) => {
                     updateState(this, (state) => {
                        state.equipamentoSelecionado = item && item === state.equipamentoSelecionado ? null : item;
                        state.highlightIndex = state.equipamentoSelecionado ? index : null;
                     });
                  }}
                  highlightIndex={this.state.highlightIndex}
               />
            </div>
            {this.state.equipamentoSelecionado && (
               <Fragment>
                  <Line />
                  <div
                     style={{
                        display: 'flex',
                        height: '30%',
                        maxHeight: '30%',
                        overflowY: 'hidden',
                        flexDirection: 'column',
                     }}
                  >
                     {this.certificadosDaOrdemDeServico()}
                  </div>
               </Fragment>
            )}
         </Fragment>
      );
   }
}
