import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import AnalisadorSimuladorNeosController from '../../controllers/Neos/AnalisadorSimuladorNeosController';
import {
   updateState,
   dateToString,
   numberToString,
   buildQueryString,
   retirarAcentos,
   replaceAll,
   isNumeric,
} from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Document from '../../components/Document';
import { Filler } from '../../components/Filler';
import DecimalInput from '../../components/DecimalInput';
import Button from '../../components/Button';
import IntegerInput from '../../components/IntegerInput';
import TipoDeEquipamentoView from './TipoDeEquipamentoView';
import EmpresaView from './EmpresaView';
import { getParametrosParaCadastroDeCertificado } from '../../utils/ParametrosParaCadastroDeCertificado';
import { faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SessionManager from './../../models/SessionManager'; 
import { showError } from '../../components/Messages';
import IconButton from '../../components/IconButton';
import Line from '../../components/Line';

export default class AnalisadorSimuladorNeosView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true, mostrarPlanilha: true, mostrarTendencia: true };
      this.controller = new AnalisadorSimuladorNeosController(this);
      this.lang = this.props.lang;
      let sessionManager = new SessionManager();
      this.login = sessionManager.getLogin();
   }

   renderizarFiltros(sender) {
      return (
         <Fragment>
            <Row>
               <Col>
                  <Form.Group>
                     <Label>{this.props.lang.telaDeCadastroDeAnalisadorSimulador.filtro}</Label>
                     <TextInput
                        onChange={(e) => {
                           let filtro = sender.getState().filtro;
                           filtro.texto = e.target.value;
                           if (filtro.texto) {
                              filtro.texto = ('' + retirarAcentos(filtro.texto)).toUpperCase();
                              var charsToRemove = ['@', ',', '.', ';', "'", '\\', '/', '-', '_', '+'];
                              charsToRemove.forEach((c) => {
                                 filtro.texto = replaceAll(filtro.texto, c, '');
                              });
                           }
                           sender.setState({ filtro: filtro });
                        }}
                     />
                  </Form.Group>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeAnalisadorSimulador.tipoDeEquipamento}</Label>
                     <Select
                        name={'tipoDeEquipamento'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.tipoDeEquipamento = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <TipoDeEquipamentoView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/tipodeequipamento' + buildQueryString(7, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeAnalisadorSimulador.empresa}</Label>
                     <Select
                        name={'empresa'}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nomeFantasia}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.empresa = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <EmpresaView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) => '/empresa/fast' + buildQueryString(7, null, 'id', { Searchable: text })}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <Button
                     onClick={sender.filtrar}
                     text={this.props.lang.telaDeCadastroDeAnalisadorSimulador.pesquisar}
                     style={{ width: 200 }}
                  />
                  <br />
               </Col>
            </Row>
         </Fragment>
      );
   }

   getFiltro = (filtro) => {
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (filtro && filtro.texto) {
         let texto = filtro.texto.toString();
         if (texto[0] === '#') {
            if (!isNumeric(texto.substring(1))) {
               showError(this.props.lang.formularioPadrao.codigoInvalido + ': "' + texto.substring(1) + '"');
               return result;
            }
            result.id = texto.substring(1);
         } else {
            result.searchable = texto;
         }
      }
      if (this.state.empresa) {
         result.empresa = this.state.empresa.id;
      }
      if (this.state.tipoDeEquipamento) {
         result.tipoDeEquipamento = this.state.tipoDeEquipamento.id;
      }
      return result;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeAnalisadorSimulador = lang.telaDeCadastroDeAnalisadorSimulador;
      return (
         <React.Fragment>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.tipoDeEquipamento}</Label>
                     <Select
                        name={'tipoDeEquipamento'}
                        defaultValue={this.state.itemSelecionado.tipoDeEquipamento}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.tipoDeEquipamento = i;
                           });
                        }}
                        formularioPadrao={(select) => {
                           return <TipoDeEquipamentoView api={this.props.api} select={select} lang={this.props.lang} />;
                        }}
                        noDropDown={true}
                        readOnlyColor='#ffff'
                        getFilterUrl={(text) =>
                           '/tipodeequipamento' + buildQueryString(7, null, 'id', { Searchable: text })
                        }
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeAnalisadorSimulador.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.telaDeCadastroDeAnalisadorSimulador.ativo },
                           { id: 2, descricao: lang.telaDeCadastroDeAnalisadorSimulador.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDeSerie}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.numeroDeSerie}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.numeroDeSerie = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.fabricante}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.fabricante}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.fabricante = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col xs={3} md={3} xl={3}>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeFabricacao}</Label>
                     <DateInput
                        defaultValue={this.state.itemSelecionado.dataDeFabricacao}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.dataDeFabricacao = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.calibradoPor}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.calibradoPor}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.calibradoPor = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col sm={3} xs={3} md={3}>
                  <Label>{telaDeCadastroDeAnalisadorSimulador.dataDeCalibracao}</Label>
                  <DateInput
                     defaultValue={this.state.itemSelecionado.dataDeCalibracao}
                     onChange={(value) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.dataDeCalibracao = value;
                        });
                     }}
                  />
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeAnalisadorSimulador.tempoDeDuracaoDaCalibracao}</Label>
                     <IntegerInput
                        defaultValue={this.state.itemSelecionado.tempoDeDuracaoDaCalibracao}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.tempoDeDuracaoDaCalibracao = e.floatValue;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeAnalisadorSimulador.numeroDoCertificadoVigente}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.numeroDoCertificado}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.numeroDoCertificado = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            {this.certificados()}

            <Filler height={200} />
         </React.Fragment>
      );
   };

   certificados = () => {
      return (
         <Row
            style={{
               lineHeight: 1,
            }}
         >
            <Col>
               <FormGroup>
                  <SubCadastro
                     titulo={this.lang.telaDeCadastroDeAnalisadorSimulador.certificadosDeCalibracao}
                     exibirTitulos={true}
                     itens={this.state.itemSelecionado.certificadosDeCalibracao}
                     lang={this.lang}
                     modal={true}
                     tableStyle={{ backgroundColor: 'white' }}
                     formStyle={{ backgroundColor: 'white' }}
                     novo={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.certificadoDeCalibracaoSelecionado = {
                                 certificadoDeCalibracao: {
                                    situacao: { id: 1 },
                                 },
                              };
                           }).then(resolve);
                        });
                     }}
                     cancelar={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.certificadoDeCalibracaoSelecionado = null;
                           }).then(resolve);
                        });
                     }}
                     inserir={this.controller.inserirCertificadoDeCalibracao}
                     selecionarParaAlteracao={(index) => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.certificadoDeCalibracaoSelecionado = JSON.parse(
                                 JSON.stringify(state.itemSelecionado.certificadosDeCalibracao[index])
                              );
                           }).then(() => resolve());
                        });
                     }}
                     alterar={this.controller.alterarCertificadoDeCalibracao}
                     excluir={(index) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.certificadosDeCalibracao.splice(index, 1);
                        });
                     }}
                     colunas={() => {
                        return [
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDoCertificado,
                              width: '20%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.arquivo,
                              width: '40%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeValidade,
                              width: '20%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.situacao,
                              width: '20%',
                              className: null,
                           },
                        ];
                     }}
                     linha={(item) => {
                        return [
                           item.certificadoDeCalibracao.numeroDoCertificado,
                           item.certificadoDeCalibracao && item.certificadoDeCalibracao.arquivo
                              ? item.certificadoDeCalibracao.arquivo.descricao
                              : null,
                           dateToString(item.certificadoDeCalibracao.dataDeValidade, this.props.lang),
                           this.controller.getDescricaoDaSituacaoDoCertificado(
                              item.certificadoDeCalibracao.dataDeValidade
                           ),
                        ];
                     }}
                     formulario={() => {
                        return (
                           <div>
                              <Row>
                                 <Col sm={9} xs={9} md={9}>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.arquivo}</Label>
                                       <Document
                                          lang={this.props.lang}
                                          api={this.props.api}
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado &&
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao &&
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .arquivo
                                          }
                                          onChange={(file) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.arquivo =
                                                   file;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col sm={3} xs={3} md={3}>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeValidade}</Label>
                                       <DateInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .dataDeValidade
                                          }
                                          onChange={(value) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDeValidade =
                                                   value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.calibradoPor}</Label>
                                       <TextInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .calibradoPor
                                          }
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.calibradoPor =
                                                   e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col sm={3} xs={3} md={3}>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.dataDeCalibracao}</Label>
                                       <DateInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .dataDaCalibracao
                                          }
                                          onChange={(value) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.dataDaCalibracao =
                                                   value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.localDaCalibracao}</Label>
                                       <TextInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .localDaCalibracao
                                          }
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.localDaCalibracao =
                                                   e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col sm={3} xs={3} md={3}>
                                    <FormGroup>
                                       <Label>
                                          {this.lang.telaDeCadastroDeAnalisadorSimulador.numeroDoCertificado}
                                       </Label>
                                       <TextInput
                                          defaultValue={
                                             this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                .numeroDoCertificado
                                          }
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.numeroDoCertificado =
                                                   e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 {this.state.mostrarPlanilha && (
                                    <Col>
                                       <FormGroup>
                                          <Label>
                                             {
                                                this.lang.telaDeCadastroDeAnalisadorSimulador
                                                   .importarResultadosDePlanilha
                                             }
                                             &nbsp;&nbsp;
                                             <IconButton
                                                style={{ fontSize: 20, color: 'green' }}
                                                cursor='pointer'
                                                icon={faFileExcel}
                                                title={
                                                   this.lang.telaDeCadastroDeAnalisadorSimulador.baixarPlanilhaModelo
                                                }
                                                onClick={() => {
                                                   var sigla = this.state.itemSelecionado.tipoDeEquipamento.sigla;
                                                   let idioma = this.login.idioma ? this.login.idioma : 'pt-BR';
                                                   let a = document.createElement('a');
                                                   a.download = sigla;
                                                   a.href =
                                                      this.props.api.urlBase() +
                                                      '/publicfile/download/' +
                                                      sigla +
                                                      '_' +
                                                      idioma +
                                                      '.xlsx';
                                                   document.body.appendChild(a);
                                                   a.click();
                                                   document.body.removeChild(a);
                                                }}
                                             />
                                          </Label>
                                          <Document
                                             lang={this.props.lang}
                                             api={this.props.api}
                                             accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                             defaultValue={
                                                this.state.certificadoDeCalibracaoSelecionado &&
                                                this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao &&
                                                this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao
                                                   .planilha
                                             }
                                             onChange={(file) => {
                                                updateState(this, (state) => {
                                                   state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha =
                                                      file;
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                                 {this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.planilha && (
                                    <Col md={2} lg={2} xl={2}>
                                       <FormGroup>
                                          <Label>&nbsp;</Label>
                                          <Button
                                             onClick={this.controller.importarResultados}
                                             text={this.props.lang.telaDeCadastroDeAnalisadorSimulador.importar}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                              </Row>

                              {this.resultados()}

                              <br />
                           </div>
                        );
                     }}
                  />
               </FormGroup>
            </Col>
         </Row>
      );
   };

   resultados = () => {
      return (
         <Row>
            <Col>
               <FormGroup>
                  <SubCadastro
                     titulo={this.lang.telaDeCadastroDeAnalisadorSimulador.resultadosDoCertificadosDeCalibracao}
                     exibirTitulos={true}
                     itens={this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados}
                     lang={this.lang}
                     novo={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.resultadoSelecionado = {};
                           }).then(resolve);
                        });
                     }}
                     cancelar={() => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.resultadoSelecionado = null;
                           }).then(resolve);
                        });
                     }}
                     inserir={this.controller.inserirResultadoDeCertificadoDeCalibracao}
                     selecionarParaAlteracao={(index) => {
                        return new Promise((resolve) => {
                           updateState(this, (state) => {
                              state.resultadoSelecionado = JSON.parse(
                                 JSON.stringify(
                                    this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados[
                                       index
                                    ]
                                 )
                              );
                           }).then(() => resolve());
                        });
                     }}
                     alterar={this.controller.alterarResultadoDeCertificadoDeCalibracao}
                     excluir={(index) => {
                        updateState(this, (state) => {
                           this.state.certificadoDeCalibracaoSelecionado.certificadoDeCalibracao.resultados.splice(
                              index,
                              1
                           );
                        });
                     }}
                     colunas={() => {
                        return [
                           {
                              titulo: this.lang.codigo,
                              width: '10%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.parametro,
                              width: '20%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.valorDeReferencia,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.valorMedido,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.tendencia,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.tendenciaPercentual,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.incerteza,
                              width: '15%',
                              className: null,
                           },
                           {
                              titulo: this.lang.telaDeCadastroDeAnalisadorSimulador.fatorDeAbrangencia,
                              width: '15%',
                              className: null,
                           },
                        ];
                     }}
                     linha={(item) => {
                        return [
                           item.id > 0 ? item.id : null,
                           item.parameterTypeDesc,
                           numberToString(item.referenceValue, 0, 6, this.lang),
                           numberToString(item.measuredValue, 0, 6, this.lang),
                           numberToString(item.tendency, 0, 6, this.lang),
                           numberToString(item.percentTendency, 0, 6, this.lang),
                           numberToString(item.uncertainty, 0, 6, this.lang),
                           numberToString(item.coverageFactor, 0, 6, this.lang),
                        ];
                     }}
                     formulario={() => {
                        return (
                           <div
                              style={{
                                 fontSize: 13,
                              }}
                           >
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.parametro}</Label>
                                       <Select
                                          defaultValue={this.state.resultadoSelecionado.parameterType}
                                          options={getParametrosParaCadastroDeCertificado(this.state.itemSelecionado)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          acceptZero={true}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.resultadoSelecionado.parameterType = i.id;
                                                   state.resultadoSelecionado.parameterTypeDesc = i.descricao;
                                                } else {
                                                   state.resultadoSelecionado.parameterType = 0;
                                                   state.resultadoSelecionado.parameterTypeDesc = null;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.referenceValue}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.referenceValue = e.value;
                                                this.calcularTendencia(state);
                                             }).then(() => {
                                                updateState(this, (state) => {
                                                   state.mostrarTendencia = true;
                                                });
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.valorMedido}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.measuredValue}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.measuredValue = e.value;
                                                this.calcularTendencia(state);
                                             }).then(() => {
                                                updateState(this, (state) => {
                                                   state.mostrarTendencia = true;
                                                });
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.tendencia}</Label>
                                       {this.state.mostrarTendencia && (
                                          <DecimalInput
                                             lang={this.lang}
                                             defaultValue={this.state.resultadoSelecionado.tendency}
                                             decimalPlaces={0}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.resultadoSelecionado.tendency = e.value;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.mostrarTendencia = true;
                                                   });
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>
                                          {this.lang.telaDeCadastroDeAnalisadorSimulador.tendenciaPercentual}
                                       </Label>
                                       {this.state.mostrarTendencia && (
                                          <DecimalInput
                                             lang={this.lang}
                                             defaultValue={this.state.resultadoSelecionado.percentTendency}
                                             decimalPlaces={0}
                                             onChange={(e) => {
                                                updateState(this, (state) => {
                                                   state.resultadoSelecionado.percentTendency = e.value;
                                                });
                                             }}
                                          />
                                       )}
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.incerteza}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.uncertainty}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.uncertainty = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.telaDeCadastroDeAnalisadorSimulador.fatorDeAbrangencia}</Label>
                                       <DecimalInput
                                          lang={this.lang}
                                          defaultValue={this.state.resultadoSelecionado.coverageFactor}
                                          decimalPlaces={0}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.resultadoSelecionado.coverageFactor = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </div>
                        );
                     }}
                  />
               </FormGroup>
            </Col>
         </Row>
      );
   };

   calcularTendencia = (state) => {
      try {
         state.resultadoSelecionado.tendency = parseFloat(
            Math.abs(state.resultadoSelecionado.referenceValue - state.resultadoSelecionado.measuredValue).toFixed(6)
         );
         state.resultadoSelecionado.percentTendency = parseFloat(
            ((state.resultadoSelecionado.tendency * 100) / state.resultadoSelecionado.referenceValue).toFixed(6)
         );
      } catch (e) {}
      state.mostrarTendencia = false;
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            
            <span>{this.lang.telaDeCadastroDeAnalisadorSimulador.anexos}</span>
            <Line marginBottom={5}/>          

            <Label>
               {this.lang.telaDeCadastroDeAnalisadorSimulador.manual}
               &nbsp;&nbsp;
               <IconButton
                  style={{ fontSize: 20, color: 'red' }}
                  cursor='pointer'
                  icon={faFilePdf}
                  title={this.lang.telaDeCadastroDeAnalisadorSimulador.baixarManual}
                  onClick={() => {
                     var sigla = this.state.itemSelecionado.tipoDeEquipamento.sigla;
                     let a = document.createElement('a');
                     a.download = sigla;
                     a.href = this.props.api.urlBase() + '/publicfile/download/Manual' + sigla +'.pdf';
                     a.target = "_blank";
                     document.body.appendChild(a);
                     a.click();
                     document.body.removeChild(a);
                  }}
               />
            </Label>
         </div>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeAnalisadorSimulador.titulo}
            url={'/analisadorneos'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'numeroDeSerie'}
            permissoes={[1151, 1152, 1153, 1154]}
            renderizarFiltros={this.renderizarFiltros}
            getFiltro={this.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
            }}
         />
      );
   }
}
