import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import { showConfirm, showError } from '../../components/Messages';
import FormularioPadrao from './../FormularioPadrao';
import Label from '../../components/Label';
import Avatar from '../../components/Avatar';
import TextInput from '../../components/TextInput';
import FuncionarioNeosController from '../../controllers/Neos/FuncionarioNeosController';
import {
   updateState,
   getEnderecoCompleto,
   buildQueryString,
   generatePassword,
   validarEndereco,
} from '../../utils/Functions';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import CpfInput from '../../components/CpfInput';
import SubCadastro from '../../components/SubCadastro';
import CheckBox from '../../components/CheckBox';
import CepInput from '../../components/CepInput';
import { IconeWhatsApp } from '../../components/IconeWhatsApp';
import CidadeView from '../CidadeView';
import CargoNeosView from './CargoNeosView';
import { Filler } from '../../components/Filler';
import PasswordInput from '../../components/PasswordInput';
import PerfilDeUsuarioView from './PerfilDeUsuarioView';
import { faKey, faTrash } from '@fortawesome/free-solid-svg-icons';
import EmailInput from '../../components/EmailInput';

const url = '/funcionarioneos';

export default class FuncionarioNeosView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarEndereco: true, url: url, enviarNovaSenhaPorEmail: false };
      this.controller = new FuncionarioNeosController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeFuncionarioNeos = lang.telaDeCadastroDeFuncionarioNeos;

      return (
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col sm={2} xs={2} md={2}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeFuncionarioNeos.codigo}</Label>
                           <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{telaDeCadastroDeFuncionarioNeos.situacao}</Label>
                           <Select
                              as='select'
                              name='situacao'
                              defaultValue={
                                 this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id
                              }
                              options={[
                                 {
                                    id: 1,
                                    descricao: lang.situacaoDeFuncionarioNeos.ativo,
                                 },
                                 {
                                    id: 2,
                                    descricao: lang.situacaoDeFuncionarioNeos.inativo,
                                 },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = i;
                                 });
                              }}
                              readOnly={
                                 this.state.itemSelecionado.nome &&
                                 this.state.itemSelecionado.nome.toUpperCase() === 'MASTER'
                              }
                           />
                        </FormGroup>
                     </Col>

                     <Col sm={3} xs={3} md={3}>
                        <FormGroup>
                           <Label>{telaDeCadastroDeFuncionarioNeos.dataDeCadastro}</Label>
                           <DateInput
                              defaultValue={
                                 this.state.itemSelecionado.dataDeCadastro
                                    ? this.state.itemSelecionado.dataDeCadastro
                                    : new Date()
                              }
                              onChange={(value) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.dataDeCadastro = value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <Label>{telaDeCadastroDeFuncionarioNeos.nome}</Label>
                        <TextInput
                           defaultValue={this.state.itemSelecionado.nomeCompleto}
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.nomeCompleto = e.target.value;
                              });
                           }}
                           upperCase={true}
                        />
                     </Col>

                     <Col sm={4} md={4} lg={4}>
                        <Label>{telaDeCadastroDeFuncionarioNeos.apelido}</Label>
                        <TextInput
                           defaultValue={this.state.itemSelecionado.apelido}
                           onChange={(e) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.apelido = e.target.value;
                              });
                           }}
                           upperCase={true}
                        />
                     </Col>
                  </Row>
               </Col>

               <Col style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20 }}>
                  <Avatar
                     lang={this.props.lang}
                     api={this.props.api}
                     image={this.state.itemSelecionado.foto}
                     onChange={(image) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.foto = image;
                        });
                     }}
                  />
               </Col>
            </Row>

            <Filler marginTop={10} />

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.cpf}</Label>
                     <CpfInput
                        defaultValue={this.state.itemSelecionado.cpf}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.cpf = e.formattedValue;
                           });
                        }}
                        onBlur={this.controller.verificarSeCpfJaExiste}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.rg}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.documentoDeIdentidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.documentoDeIdentidade = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.orgaoExpedidor}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.orgaoExpedidorDoDocumentoDeIdentidade}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.orgaoExpedidorDoDocumentoDeIdentidade = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.dataDeNascimento}</Label>
                     <DateInput
                        defaultValue={this.state.itemSelecionado.dataDeNascimento}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.dataDeNascimento = value;
                              //state.setState({ itemSelecionado: state.state.itemSelecionado });
                              //state.atualizarFormulario();
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.sexo}</Label>
                     <Select
                        defaultValue={this.state.itemSelecionado.sexo ? this.state.itemSelecionado.sexo.id : 0}
                        options={[
                           { id: 1, descricao: lang.masculino },
                           { id: 2, descricao: lang.feminino },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.sexo = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.email}</Label>
                     <EmailInput
                        defaultValue={this.state.itemSelecionado.email}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.email = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col sm={6} md={6} lg={6}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.login}</Label>
                     <TextInput
                        readOnly={this.state.alterando}
                        defaultValue={this.state.itemSelecionado.nomeDeUsuario}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nomeDeUsuario = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col sm={6} md={6} lg={6}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeFuncionarioNeos.senha}</Label>
                     {!this.state.esconderSenha && (
                        <React.Fragment>
                           <PasswordInput
                              defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                              readOnly={this.state.itemSelecionado.senha && !this.state.itemSelecionado.senhaAlterada}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.senha = e.target.value;
                                    state.itemSelecionado.senhaAlterada = true;
                                    state.itemSelecionado.enviarNovaSenhaPorEmail = false;
                                 });
                              }}
                              appendIcon={this.state.itemSelecionado.senha ? faTrash : faKey}
                              appendTitle={this.props.lang.gerarNovaSenha}
                              onAppendClick={() => {
                                 if (this.state.itemSelecionado.senha) {
                                    showConfirm(
                                       this.props.lang.desejaLimparASenha,
                                       () => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.senha = null;
                                             state.itemSelecionado.senhaAlterada = true;
                                             state.esconderSenha = true;
                                          }).then(() => {
                                             updateState(this, (state) => {
                                                state.esconderSenha = false;
                                             });
                                          });
                                       },
                                       null,
                                       this.props.lang.sim,
                                       this.props.lang.nao
                                    );
                                 } else {
                                    showConfirm(
                                       this.props.lang.desejaRealmenteGerarUmaNovaSenha,
                                       () => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.senha = generatePassword(true, 8);
                                             state.itemSelecionado.senhaAlterada = true;
                                             state.itemSelecionado.enviarNovaSenhaPorEmail = true;
                                             state.esconderSenha = true;
                                          }).then(() => {
                                             updateState(this, (state) => {
                                                state.esconderSenha = false;
                                             });
                                          });
                                       },
                                       null,
                                       this.props.lang.sim,
                                       this.props.lang.nao
                                    );
                                 }
                              }}
                           />
                           {this.state.itemSelecionado.senhaAlterada && this.state.itemSelecionado.senha && (
                              <CheckBox
                                 defaultChecked={this.state.itemSelecionado.enviarNovaSenhaPorEmail ? true : false}
                                 name='enviarNovaSenhaPorEmail'
                                 label={this.props.lang.enviarANovaSenhaPorEmail}
                                 onChange={(checked) => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.enviarNovaSenhaPorEmail = checked;
                                    });
                                 }}
                              />
                           )}
                        </React.Fragment>
                     )}
                  </FormGroup>
               </Col>
            </Row>

            <SubCadastro
               titulo={telaDeCadastroDeFuncionarioNeos.perfisDeUsuario}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.perfis}
               lang={lang}
               modal={true}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.perfilSelecionado = null;
                     }).then(resolve);
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.perfilSelecionado = null;
                     }).then(resolve);
                  });
               }}
               inserir={this.controller.inserirPerfil}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.perfilSelecionado = index !== 0 ? state.itemSelecionado.perfis[index] : null;
                     }).then(resolve);
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.perfis.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: telaDeCadastroDeFuncionarioNeos.descricao, width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.perfil.nome];
               }}
               formulario={() => {
                  return (
                     <div>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{telaDeCadastroDeFuncionarioNeos.perfilDeUsuario}</Label>
                                 <Select
                                    name={'perfil'}
                                    defaultValue={this.state.perfilSelecionado}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.nome}
                                    onSelect={(i) => {
                                       this.setState({ perfilSelecionado: i });
                                    }}
                                    formularioPadrao={(select) => {
                                       return (
                                          <PerfilDeUsuarioView
                                             api={this.props.api}
                                             select={select}
                                             lang={this.props.lang}
                                             filtroExtra={() => {
                                                let filtro = {
                                                   tipoDePerfil: 1, // Néos
                                                };
                                                return filtro;
                                             }}
                                          />
                                       );
                                    }}
                                    noDropDown={true}
                                    readOnlyColor='#ffff'
                                    getFilterUrl={(text) =>
                                       '/perfildeusuario/fast' + buildQueryString(2, null, 'id', { Searchable: text })
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     </div>
                  );
               }}
            />
            <br />
            <SubCadastro
               titulo={telaDeCadastroDeFuncionarioNeos.cargos}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.cargos}
               modal={true}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.cargoSelecionado = {
                           cargo: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.cargoSelecionado.cargo) {
                        showError(telaDeCadastroDeFuncionarioNeos.mensagens.informeOCargo);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.cargos) {
                           state.itemSelecionado.cargos = [];
                        }
                        state.itemSelecionado.cargos.push({
                           cargo: this.state.cargoSelecionado.cargo,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.cargoSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.cargos.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Cargo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.cargo.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{telaDeCadastroDeFuncionarioNeos.cargo}</Label>
                           <Select
                              name={'cargo'}
                              defaultValue={this.state.cargoSelecionado.cargo}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.cargoSelecionado.cargo = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return <CargoNeosView api={this.props.api} select={select} lang={this.props.lang} />;
                              }}
                              noDropDown={true}
                              readOnlyColor={'#ffff'}
                              getFilterUrl={(text) =>
                                 '/cargoneos' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />
            <br />
            <SubCadastro
               titulo={lang.telefones}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.telefones}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.telefoneSelecionado = {
                           pais: null,
                           ddd: null,
                           numero: null,
                           temWhatsApp: false,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={this.controller.inserirTelefone}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.telefoneSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.telefones[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={this.controller.alterarTelefone}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.telefoneSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.telefones.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: '', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [
                     <div style={{ display: 'flex' }}>
                        <div>{item.pais + ' ' + item.ddd + ' ' + item.numero + ' '} </div>
                        <div>{item.temWhatsApp ? <IconeWhatsApp key={'iconeWhatsApp'} /> : null}</div>
                     </div>,
                  ];
               }}
               formulario={() => {
                  return (
                     this.state.telefoneSelecionado && (
                        <div>
                           <Row>
                              <Col>
                                 <div style={{ display: 'flex' }}>
                                    <FormGroup>
                                       <Label>{lang.telefone.pais}</Label>
                                       <TextInput
                                          name='pais'
                                          defaultValue={this.state.telefoneSelecionado.pais}
                                          placeholder={'+55'}
                                          style={{ width: '60px' }}
                                          maxLength={3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.telefoneSelecionado.pais = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>

                                    <FormGroup>
                                       <Label>{lang.telefone.ddd}</Label>
                                       <TextInput
                                          name='ddd'
                                          defaultValue={this.state.telefoneSelecionado.ddd}                                       
                                          style={{ width: '60px' }}
                                          maxLength={2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.telefoneSelecionado.ddd = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                    <FormGroup>
                                       <Label>{lang.telefone.numero}</Label>
                                       <TextInput
                                          name='telefone'
                                          defaultValue={this.state.telefoneSelecionado.numero}
                                          maxLength={9}                                        
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.telefoneSelecionado.numero = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                    <FormGroup
                                       controlId='chktemWhatsApp'
                                       style={{
                                          margin: '37px auto auto',
                                          paddingLeft: '5px',
                                          fontWeight: 'normal',
                                          width: '100%',
                                       }}
                                    >
                                       <CheckBox
                                          defaultChecked={this.state.telefoneSelecionado.temWhatsApp ? true : false}
                                          name='WhatsApp'
                                          label='WhatsApp'
                                          onChange={(checked) => {
                                             updateState(this, (state) => {
                                                state.telefoneSelecionado.temWhatsApp = checked;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </div>
                              </Col>
                           </Row>
                        </div>
                     )
                  );
               }}
            />
            <br />
            <SubCadastro
               titulo='Endereços'
               exibirTitulos={false}
               itens={this.state.itemSelecionado.enderecos}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.enderecoSelecionado = {
                           endereco: {
                              logradouro: null,
                              numero: null,
                              complemento: null,
                              bairro: null,
                              cep: null,
                              cidade: null,
                           },
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!validarEndereco(false, this.state.enderecoSelecionado.endereco, lang, reject)) {
                        return;
                     }

                     let endereco = {
                        logradouro: this.state.enderecoSelecionado.endereco.logradouro,
                        numero: this.state.enderecoSelecionado.endereco.numero,
                        complemento: this.state.enderecoSelecionado.endereco.complemento,
                        bairro: this.state.enderecoSelecionado.endereco.bairro,
                        cep: this.state.enderecoSelecionado.endereco.cep,
                        cidade: this.state.enderecoSelecionado.endereco.cidade,
                     };

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.enderecos) {
                           state.itemSelecionado.enderecos = [];
                        }
                        state.itemSelecionado.enderecos.push({
                           endereco: {
                              logradouro: this.state.enderecoSelecionado.endereco.logradouro,
                              numero: this.state.enderecoSelecionado.endereco.numero,
                              complemento: this.state.enderecoSelecionado.endereco.complemento,
                              bairro: this.state.enderecoSelecionado.endereco.bairro,
                              cep: this.state.enderecoSelecionado.endereco.cep,
                              cidade: this.state.enderecoSelecionado.endereco.cidade,
                              enderecoCompleto: getEnderecoCompleto(endereco),
                           },
                        });
                     }).then(() => resolve());
                  });
               }}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.enderecoSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.enderecos[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     if (!validarEndereco(false, this.state.enderecoSelecionado.endereco, lang, reject)) {
                        return;
                     }

                     let endereco = {
                        logradouro: this.state.enderecoSelecionado.endereco.logradouro,
                        numero: this.state.enderecoSelecionado.endereco.numero,
                        complemento: this.state.enderecoSelecionado.endereco.complemento,
                        bairro: this.state.enderecoSelecionado.endereco.bairro,
                        cep: this.state.enderecoSelecionado.endereco.cep,
                        cidade: this.state.enderecoSelecionado.endereco.cidade,
                     };

                     updateState(this, (state) => {
                        state.itemSelecionado.enderecos[index].endereco.logradouro =
                           this.state.enderecoSelecionado.endereco.logradouro;
                        state.itemSelecionado.enderecos[index].endereco.numero =
                           this.state.enderecoSelecionado.endereco.numero;
                        state.itemSelecionado.enderecos[index].endereco.complemento =
                           this.state.enderecoSelecionado.endereco.complemento;
                        state.itemSelecionado.enderecos[index].endereco.bairro =
                           this.state.enderecoSelecionado.endereco.bairro;
                        state.itemSelecionado.enderecos[index].endereco.cep =
                           this.state.enderecoSelecionado.endereco.cep;
                        state.itemSelecionado.enderecos[index].endereco.cidade =
                           this.state.enderecoSelecionado.endereco.cidade;
                        state.itemSelecionado.enderecos[index].endereco.enderecoCompleto =
                           getEnderecoCompleto(endereco);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.enderecoSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.enderecos.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Endereço Completo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [getEnderecoCompleto(item.endereco)];
               }}
               formulario={() => {
                  return this.state.mostrarEndereco && this.state.enderecoSelecionado ? (
                     <div>
                        <Row>
                           <Col xs={3} md={3} xl={3}>
                              <FormGroup>
                                 <Label>{lang.endereco.cep}</Label>
                                 <CepInput
                                    defaultValue={this.state.enderecoSelecionado.endereco.cep}
                                    onChange={(value) => {
                                       updateState(this, (state) => {
                                          state.enderecoSelecionado.endereco.cep = value.formattedValue;
                                       });
                                    }}
                                    onBlur={() => {
                                       this.controller.consultarCep();
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           <Col xs={5} md={5} xl={5}>
                              <FormGroup>
                                 <Label>{lang.endereco.logradouro}</Label>
                                 <TextInput
                                    defaultValue={this.state.enderecoSelecionado.endereco.logradouro}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.enderecoSelecionado.endereco.logradouro = e.target.value;
                                       });
                                    }}
                                    upperCase={true}
                                 />
                              </FormGroup>
                           </Col>
                           <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.endereco.numero}</Label>
                                 <TextInput
                                    defaultValue={this.state.enderecoSelecionado.endereco.numero}
                                    maxLength={10}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.enderecoSelecionado.endereco.numero = e.target.value;
                                       });
                                    }}
                                    upperCase={true}
                                 />
                              </FormGroup>
                           </Col>
                           <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.endereco.complemento}</Label>
                                 <TextInput
                                    defaultValue={this.state.enderecoSelecionado.endereco.complemento}
                                    maxLength={30}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.enderecoSelecionado.endereco.complemento = e.target.value;
                                       });
                                    }}
                                    upperCase={true}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{lang.endereco.bairro}</Label>
                                 <TextInput
                                    defaultValue={this.state.enderecoSelecionado.endereco.bairro}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.enderecoSelecionado.endereco.bairro = e.target.value;
                                       });
                                    }}
                                    upperCase={true}
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <Label>{lang.endereco.cidade}</Label>
                              <Select
                                 name={'cidade'}
                                 defaultValue={this.state.enderecoSelecionado.endereco.cidade}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.enderecoSelecionado.endereco.cidade = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return <CidadeView api={this.props.api} select={select} lang={this.props.lang} />;
                                 }}
                                 noDropDown={true}
                                 readOnlyColor={'#ffff'}
                                 getFilterUrl={(text) =>
                                    '/cidade' + buildQueryString(2, null, 'id', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   editar = (item) => {
      if (this.form) {
         this.form.editarClick(item);
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeFuncionarioNeos.titulo}
            url={url}
            fastUrl={url + '/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nomeCompleto'}
            permissoes={[1021, 1022, 1023, 1024]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            ref={(c) => (this.form = c)}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               telefones: [],
               enderecos: [],
               redesSociais: [],
               situacao: { id: 1 },
               perfis: [],
            }}
         />
      );
   }
}
