import { BaseController } from '../BaseController';
import { dateToString } from '../../utils/Functions';

export default class OrdemDeServicoParaClienteController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'OrdemDeServicoParaClienteController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.data,
            width: '10%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.usuarioResponsavel,
            width: '15%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.usuarioExecutor,
            width: '15%',
            orderby: null,
         },         
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.equipamentoSobTeste,
            width: '50%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.situacao,
            width: '10%',
            orderby: null,
         },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         dateToString(item.dataDeAgendamento, this.lang),
         item.usuarioResponsavel ? item.usuarioResponsavel.nome : null,
         item.usuarioExecutor ? item.usuarioExecutor.nome : null,        
         item.equipamentoSobTeste
            ? item.equipamentoSobTeste.tipoDeEquipamento +
              ' / ' +
              item.equipamentoSobTeste.numeroDeSerie +
              (item.equipamentoSobTeste.numeroDePatrimonio ? ' / ' + item.equipamentoSobTeste.numeroDePatrimonio : '')
            : null,

         item.situacao.descricao,
      ];
   };   
}
