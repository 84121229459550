import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Menus } from './models/Menus';
import SessionManager from './models/SessionManager';
import Api from './utils/Api';
import LoginView from './views/User/LoginView';
import ErrorView from './views/ErrorView';
import RecoverPasswordView from './views/User/RecoverPasswordView';
import ConsultaDeCep from './utils/ConsultaDeCep';
import LayoutNeos from './views/Neos/LayoutNeos';
import LayoutEmpresa from './views/Empresa/LayoutEmpresa';
import LayoutCliente from './views/Cliente/LayoutCliente';
import { getLang } from './models/Lang';
import LogoffView from './views/User/LogoffView';
import AboutView from './views/AboutView';
import TermosDeUsoView from './views/TermosDeUso';
import MainLayout from './components/MainLayout';
import { LayoutParams } from './config/LayoutParams';
import NotFoundView from './views/NotFoundView';
import SelectEnviromentView from './views/User/SelectEnviroment';

export default class App extends React.Component {
   constructor(props) {
      super(props);
      this.api = new Api();
      this.menus = new Menus();
      this.state = {
         error: null,
      };
      this.consultaDeCep = new ConsultaDeCep();
   }

   alterarIdioma = (idioma) => {
      var result = new Promise((resolve, reject) => {
         this.setState({ idioma: idioma });
         this.idioma = idioma;
         let sessionManager = new SessionManager();
         let login = sessionManager.getLogin();
         login.idioma = idioma;
         sessionManager.setLogin(login);
         let input = { idioma: idioma };
         this.api.post('/login/alteraridioma', input).then(resolve()).catch(reject);
      });
      return result;
   };

   render() {
      let result = null;
      let sessionManager = new SessionManager();
      let login = sessionManager.getLogin();
      this.idioma = login ? (login.idioma ? login.idioma : 'pt-BR') : 'pt-BR';
      var lang = getLang(this.idioma);
      if (sessionManager.isAuthenticated()) {
         if (!sessionManager.aceitouTermosDeUso()) {
            result = (
               <HashRouter>
                  <MainLayout
                     menuItems={this.menus.getMenusParaAceiteDeTermosDeUso(sessionManager, lang)}
                     imgHomeLogo={LayoutParams.imgHomeLogo}
                     login={login}
                     lang={lang}
                     mostrarDadosDaConta={false}
                     alterarIdioma={this.alterarIdioma}
                     api={this.api}
                  >
                     <Switch>
                        <Route
                           exact
                           path='/'
                           render={() => <TermosDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                           lang={lang}
                        />
                        <Route
                           path='/login'
                           render={() => <LoginView api={this.api} lang={lang} sessionManager={sessionManager} />}
                        />
                        <Route path='/logoff' render={() => <LogoffView api={this.api} lang={lang} />} />
                        <Route
                           path='/error'
                           render={() => <ErrorView api={this.api} lang={lang} error={this.state.error} />}
                        />
                        <Route path='/about' render={() => <AboutView api={this.api} lang={lang} />} />
                        <Route render={() => <NotFoundView lang={lang} />} />
                     </Switch>
                  </MainLayout>
               </HashRouter>
            );
         } else if (sessionManager.isUsuarioNeos()) {
            result = (
               <LayoutNeos
                  sessionManager={sessionManager}
                  lang={lang}
                  login={login}
                  consultaDeCep={this.consultaDeCep}
                  menus={this.menus}
                  api={this.api}
                  alterarIdioma={this.alterarIdioma}
               />
            );
         } else if (sessionManager.isUsuarioDeEmpresa()) {
            result = (
               <LayoutEmpresa
                  sessionManager={sessionManager}
                  lang={lang}
                  login={login}
                  consultaDeCep={this.consultaDeCep}
                  menus={this.menus}
                  api={this.api}
                  alterarIdioma={this.alterarIdioma}
               />
            );
         } else if (sessionManager.isUsuarioDeCliente()) {
            result = (
               <LayoutCliente
                  sessionManager={sessionManager}
                  lang={lang}
                  login={login}
                  consultaDeCep={this.consultaDeCep}
                  menus={this.menus}
                  api={this.api}
                  alterarIdioma={this.alterarIdioma}
               />
            );
         }
      } else {
         result = (
            <React.Fragment>
               <HashRouter>
                  <Switch>
                     <Route
                        path='/error'
                        render={() => <ErrorView api={this.api} lang={lang} error={this.state.error} />}
                     />
                     <Route
                        path='/recoverpassword/neos'
                        render={() => (
                           <RecoverPasswordView
                              api={this.api}
                              lang={lang}
                              sessionManager={sessionManager}
                              tipoDeAcesso='NEOS'
                           />
                        )}
                     />
                     <Route
                        path='/recoverpassword/empresa'
                        render={() => (
                           <RecoverPasswordView
                              api={this.api}
                              lang={lang}
                              sessionManager={sessionManager}
                              tipoDeAcesso='EMPRESA'
                           />
                        )}
                     />
                     <Route
                        path='/recoverpassword/cliente'
                        render={() => (
                           <RecoverPasswordView
                              api={this.api}
                              lang={lang}
                              sessionManager={sessionManager}
                              tipoDeAcesso='CLIENTE'
                           />
                        )}
                     />
                     <Route
                        path='/neos'
                        render={() => (
                           <LoginView api={this.api} lang={lang} sessionManager={sessionManager} tipoDeAcesso='NEOS' />
                        )}
                     />
                     <Route
                        path='/empresa'
                        render={() => (
                           <LoginView
                              api={this.api}
                              lang={lang}
                              sessionManager={sessionManager}
                              tipoDeAcesso='EMPRESA'
                           />
                        )}
                     />
                     <Route
                        path='/cliente'
                        render={() => (
                           <LoginView
                              api={this.api}
                              lang={lang}
                              sessionManager={sessionManager}
                              tipoDeAcesso='CLIENTE'
                           />
                        )}
                     />
                     <Route
                        render={() => (
                           <SelectEnviromentView api={this.api} lang={lang} sessionManager={sessionManager} />
                        )}
                     />
                  </Switch>
               </HashRouter>
            </React.Fragment>
         );
      }
      return result;
   }
}
