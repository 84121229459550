import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class TipoDeContaDeEmpresaController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'TipoDeContaDeEmpresaController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeTipoDeContaDeEmpresa.nome, width: '70%', orderby: 'Nome' },
         { titulo: this.lang.telaDeCadastroDeTipoDeContaDeEmpresa.situacao, width: '10%', orderby: null },
         { titulo: this.lang.telaDeCadastroDeTipoDeContaDeEmpresa.nivel, width: '20%', orderby: null },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.nome,
         item.situacao ? item.situacao.descricao : null,
         item.nivel ? item.nivel.descricao : null,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nivel) {
            showError(this.lang.telaDeCadastroDeTipoDeContaDeEmpresa.mensagens.informeONivelDeAcessoDoTipoDeConta);
            reject();
            return;
         }

         if (item.nivel.id === 2) {
            if (!item.nome) {
               showError(this.lang.telaDeCadastroDeTipoDeContaDeEmpresa.mensagens.informeoNomeDOTipoDeConta);
               reject();
               return;
            }

            if (item.nome.length > 100) {
               showError(this.lang.telaDeCadastroDeTipoDeContaDeEmpresa.mensagens.tamanhoMaximoDoNome);
               reject();
               return;
            }

            if (!item.quantidadeMaximaDeLaudosQuePodemSerEmitidos) {
               showError(this.lang.telaDeCadastroDeTipoDeContaDeEmpresa.mensagens.informeaQuantidadeMaximaDeLaudos);
               reject();
               return;
            }
         }

         var input = {
            nome: item.nome,
            situacao: item.situacao ? item.situacao.id : 0,
            nivel: item.nivel ? item.nivel.id : 0,
            quantidadeMaximaDeLaudosQuePodemSerEmitidos: item.quantidadeMaximaDeLaudosQuePodemSerEmitidos,
            quantidadeDeDiasDeValidade: item.quantidadeDeDiasDeValidade,
            renovacaoDaQuantidadeDeDiasDeValidade: item.renovacaoDaQuantidadeDeDiasDeValidade,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
