import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import DecimalInput from '../../components/DecimalInput';
import { buildQueryString, numberToString, updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import SequenciaASEController from '../../controllers/Sequencias/SequenciaASEController';
import {
   getASE_TipoDeTeste,
   getTiposDeNormaASE,
   getTiposDeUnidadeDeDesvioASE_RedeDeTensao,
   getTiposDeNorma2ASE,
   getTiposDeFigurasASE_NORMA_62353,
   getTiposDeFigurasASE_NORMA_60601,
   getTiposDeNorma3ASE,
   getTiposDeParteAplicadasPAASE,
   getTiposDeMDASECorrenteDeFugaManual,
   getFonteDeTensaoExtASECorrenteDeFugaManual,
   getTensaoExtASECorrenteDeFugaManual,
   getTiposDePAEKelvinASE,
   getASE_ResistenciaDeIsolacao_MD1,
   getASE_ResistenciaDeIsolacao_MD2,
} from './../Descricoes';
import { Link } from 'react-router-dom';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';
import CheckBox from '../../components/CheckBox';
import TipoDeEnsaioView from './TipoDeEnsaioView';
import { showError } from '../../components/Messages';
import Button from '../../components/Button';
import CheckListView from '../Empresa/CheckListView';
import { TIPO_DE_TESTE_ASE as TIPO_DE_TESTE } from './../TiposDeTeste';

export default class SequenciaASEView extends Component {
   constructor(props) {
      super(props);
      this.state = { 
         md2Options: [],
      };
      this.controller = new SequenciaASEController(this);      
   }

   setUltimoItemCriado = (item) => {
      updateState(this, (state) => {
         state.ultimoItemCriado = {};
         state.ultimoItemCriado.numero = item.numero;
         state.ultimoItemCriado.unidadeLimites = item.unidadeLimites;
         state.ultimoItemCriado.limiteSuperior = item.limiteSuperior;
         state.ultimoItemCriado.limiteInferior = item.limiteInferior;
         state.ultimoItemCriado.configuration = item.configuration;
         state.ultimoItemCriado.figura = item.figura;
         state.ultimoItemCriado.mD1 = item.mD1;
         state.ultimoItemCriado.mD2 = item.mD2;
         state.ultimoItemCriado.norma = item.norma;
         state.ultimoItemCriado.pa = item.pa;
         state.ultimoItemCriado.referencia = item.referencia;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.vext = item.vext;
         state.ultimoItemCriado.vextRede = item.vextRede;
         state.ultimoItemCriado.fase = item.fase;
         state.ultimoItemCriado.neutro = item.neutro;
         state.ultimoItemCriado.terra = item.terra;
         state.ultimoItemCriado.reverso = item.reverso;
         state.ultimoItemCriado.af = item.af;
         state.ultimoItemCriado.kelvin = item.kelvin;
         state.ultimoItemCriado.configPA = item.configPA;
         state.ultimoItemCriado.saveTest = item.saveTest;
      });
   };

   getDescricaoDoTipoDeTesteASE = (id) => {
      let result = getASE_TipoDeTeste(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDaNormaASE = (id) => {
      let result = getTiposDeNormaASE(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeUnidadeDeDesvioRedeDeTensaoASE = (id) => {
      let result = getTiposDeUnidadeDeDesvioASE_RedeDeTensao(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoResistenciaDeIsolacao_MD1 = (id) => {
      let result = getASE_ResistenciaDeIsolacao_MD1(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoResistenciaDeIsolacao_MD2 = (id) => {
      let result = getASE_ResistenciaDeIsolacao_MD2(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeMDASECorrenteDeFugaManual = (id) => {
      let result = getTiposDeMDASECorrenteDeFugaManual(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoMd2CorrentDeFugaManual = (item) => {
      let result = this.getMd2CorrentDeFugaManual(item).filter((i) => i.id === item.mD2);
      return result.length > 0 ? result[0].descricao : null;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{lang.sequencia.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.sequencia.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.sequencia.ativa },
                           { id: 2, descricao: lang.sequencia.inativa },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={lang.sequencia.tiposDeEnsaio} modal={true}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.tiposDeEnsaio}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = {
                           tipoDeEnsaio: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio) {
                        showError(lang.sequencia.mensagens.informeOTipoDeEnsaio);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.tiposDeEnsaio) {
                           state.itemSelecionado.tiposDeEnsaio = [];
                        }
                        state.itemSelecionado.tiposDeEnsaio.push({
                           tipoDeEnsaio: this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.tiposDeEnsaio.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Cargo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.tipoDeEnsaio.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{lang.sequencia.tipoDeEnsaio}</Label>
                           <Select
                              name={'tipoDeEnsaio'} modal={true}
                              defaultValue={this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.tipoDeEnsaioSelecionado.tipoDeEnsaio = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <TipoDeEnsaioView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/tipodeensaio' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />

            <br />
            {!this.state.itemSelecionado.sequenciaNeos && (
               <SubCadastro
                  titulo={lang.sequencia.checkList} modal={true}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.checkList}
                  lang={lang}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = {
                              checkList: null,
                           };
                        }).then(() => resolve());
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve, reject) => {
                        if (!this.state.checkListSelecionado.checkList) {
                           showError(lang.sequencia.mensagens.informeOCheckList);
                           reject();
                           return false;
                        }

                        updateState(this, (state) => {
                           if (!state.itemSelecionado.checkList) {
                              state.itemSelecionado.checkList = [];
                           }
                           state.itemSelecionado.checkList.push({
                              checkList: this.state.checkListSelecionado.checkList,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = null;
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.checkList.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: lang.sequencia.checkList, width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.checkList.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <Label>{lang.sequencia.checkList}</Label>
                              <Select
                                 name={'checkList'}  modal={true}
                                 defaultValue={this.state.checkListSelecionado.checkList}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.checkListSelecionado.checkList = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <CheckListView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/checklist' + buildQueryString(2, null, 'descricao', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     );
                  }}
               />
            )}
            <br />

            <SubCadastro
               titulo={lang.sequencia.testes}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.itens}
               lang={lang}
               gradeSempreVisivel={true}
               tituloDoBotaoCancelar={lang.sequencia.finalizarCadastroDeTeste}
               novo={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? () => {
                          return new Promise((resolve) => {
                             let maiorNumero = 0;
                             if (this.state.itemSelecionado.itens) {
                                this.state.itemSelecionado.itens.map((i, index) => {
                                   if (i.numero > maiorNumero) {
                                      maiorNumero = i.numero;
                                   }
                                   return true;
                                });
                             }
                             maiorNumero++;
                             updateState(this, (state) => {
                              state.configuracaoSelecionada = null;
                              let configuracaoSelecionada = { tipoDeTeste: 0 };
                              this.setValoresDefault(configuracaoSelecionada);
                              configuracaoSelecionada.numero = maiorNumero;
                              state.configuracaoSelecionada = configuracaoSelecionada;
                             }).then(() => resolve());
                          });
                       }
                     : null
               }
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.itens) {
                           state.itemSelecionado.itens = [];
                        }

                        let item = {
                           numero: this.state.configuracaoSelecionada.numero,
                           repeticoes: 1,
                           tipoDeTeste: this.state.configuracaoSelecionada.tipoDeTeste,
                           unidadeLimites: this.state.configuracaoSelecionada.unidadeLimites,
                           limiteSuperior: this.state.configuracaoSelecionada.limiteSuperior,
                           limiteInferior: this.state.configuracaoSelecionada.limiteInferior,
                           configuration: this.state.configuracaoSelecionada.configuration,
                           figura: this.state.configuracaoSelecionada.figura,
                           mD1: this.state.configuracaoSelecionada.mD1,
                           mD2: this.state.configuracaoSelecionada.mD2,
                           norma: this.state.configuracaoSelecionada.norma,
                           pa: this.state.configuracaoSelecionada.pa,
                           referencia: this.state.configuracaoSelecionada.referencia,
                           vext: this.state.configuracaoSelecionada.vext,
                           vextRede: this.state.configuracaoSelecionada.vextRede,
                           fase: this.state.configuracaoSelecionada.fase,
                           neutro: this.state.configuracaoSelecionada.neutro,
                           terra: this.state.configuracaoSelecionada.terra,
                           reverso: this.state.configuracaoSelecionada.reverso,
                           af: this.state.configuracaoSelecionada.af,
                           kelvin: this.state.configuracaoSelecionada.kelvin,
                           configPA: this.state.configuracaoSelecionada.configPA,
                           saveTest: this.state.configuracaoSelecionada.saveTest,
                        };

                        state.itemSelecionado.itens.push(item);
                        this.setUltimoItemCriado(item);
                     }).then(() => resolve());
                  });
               }}
               aposSalvar={this.aposSalvar}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.itens[index].numero = this.state.configuracaoSelecionada.numero;
                        state.itemSelecionado.itens[index].repeticoes = this.state.configuracaoSelecionada.repeticoes;
                        state.itemSelecionado.itens[index].unidadeLimites =
                           this.state.configuracaoSelecionada.unidadeLimites;
                        state.itemSelecionado.itens[index].limiteSuperior =
                           this.state.configuracaoSelecionada.limiteSuperior;
                        state.itemSelecionado.itens[index].limiteInferior =
                           this.state.configuracaoSelecionada.limiteInferior;
                        state.itemSelecionado.itens[index].configuration =
                           this.state.configuracaoSelecionada.configuration;
                        state.itemSelecionado.itens[index].figura = this.state.configuracaoSelecionada.figura;
                        state.itemSelecionado.itens[index].mD1 = this.state.configuracaoSelecionada.mD1;
                        state.itemSelecionado.itens[index].mD2 = this.state.configuracaoSelecionada.mD2;
                        state.itemSelecionado.itens[index].norma = this.state.configuracaoSelecionada.norma;
                        state.itemSelecionado.itens[index].pa = this.state.configuracaoSelecionada.pa;
                        state.itemSelecionado.itens[index].referencia = this.state.configuracaoSelecionada.referencia;
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;

                        state.itemSelecionado.itens[index].vext = this.state.configuracaoSelecionada.vext;
                        state.itemSelecionado.itens[index].vextRede = this.state.configuracaoSelecionada.vextRede;
                        state.itemSelecionado.itens[index].fase = this.state.configuracaoSelecionada.fase;
                        state.itemSelecionado.itens[index].neutro = this.state.configuracaoSelecionada.neutro;
                        state.itemSelecionado.itens[index].terra = this.state.configuracaoSelecionada.terra;
                        state.itemSelecionado.itens[index].reverso = this.state.configuracaoSelecionada.reverso;
                        state.itemSelecionado.itens[index].af = this.state.configuracaoSelecionada.af;
                        state.itemSelecionado.itens[index].kelvin = this.state.configuracaoSelecionada.kelvin;
                        state.itemSelecionado.itens[index].configPA = this.state.configuracaoSelecionada.configPA;
                        state.itemSelecionado.itens[index].saveTest = this.state.configuracaoSelecionada.saveTest;
                        this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? (index) => {
                          updateState(this, (state) => {
                             state.itemSelecionado.itens.splice(index, 1);
                             state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
                          });
                       }
                     : null
               }
               colunas={(item) => {
                  return [
                     { titulo: 'Nº', width: '3%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.tipoDeTeste, width: '15%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.referencia, width: '13%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.norma, width: '10%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.md1, width: '11%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.md2, width: '11%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.desvioMaximoSuperior, width: '13%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.desvioMaximoInferior, width: '13%', className: null },
                     { titulo: lang.cadastroDeSequenciaASE.unidadeDeDesvio, width: '13%', className: null },
                  ];
               }}
               linha={(item) => {
                  if (item.tipoDeTeste === TIPO_DE_TESTE.resistenciaDeTerra) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteASE(item.tipoDeTeste),
                        numberToString(item.referencia, 2, 6, this.lang) + ' Ω',
                        this.getDescricaoDaNormaASE(item.norma),
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.redeDeTensao) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteASE(item.tipoDeTeste),
                        numberToString(item.referencia, 0, 6, this.lang) + ' V',
                        '-',
                        '-',
                        '-',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoModosDeUnidadeDeDesvioRedeDeTensaoASE(item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.redeDeCorrente) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteASE(item.tipoDeTeste),
                        item.referencia + ' A',
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.resistenciaIsolacao) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteASE(item.tipoDeTeste),
                        item.referencia + ' MΩ',
                        this.getDescricaoDaNormaASE(item.norma),
                        this.getDescricaoResistenciaDeIsolacao_MD1(item.mD1),
                        this.getDescricaoResistenciaDeIsolacao_MD2(item.mD2),
                        '-',
                        '-',
                        '-',
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.correnteDeFugaManual) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteASE(item.tipoDeTeste),
                        item.referencia + ' µA',
                        '-',
                        this.getDescricaoModosDeMDASECorrenteDeFugaManual(item.mD1),
                        this.getDescricaoMd2CorrentDeFugaManual(item),
                        '-',
                        '-',
                        '-',
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.correnteDeFugaNorma) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteASE(item.tipoDeTeste),
                        item.referencia + ' µA',
                        this.getDescricaoDaNormaASE(item.norma),
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                     ];
                  }
               }}
               acoes={(subCadastro, index) => {
                  return (
                     <div style={{ display: 'table-cell', margin: 'auto', marginRight: 4, width: 25 }}>
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginBottom: -10 }}
                           cursor='pointer'
                           icon={faSortUp}
                           onClick={() => {
                              if (this.state.itemSelecionado.itens[index].numero > 1) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index - 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero - 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginTop: -10 }}
                           cursor='pointer'
                           icon={faSortDown}
                           onClick={() => {
                              if (
                                 this.state.itemSelecionado.itens[index].numero <
                                 this.state.itemSelecionado.itens.length
                              ) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index + 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero + 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                     </div>
                  );
               }}
               formulario={() => {
                  return this.state.configuracaoSelecionada ? (
                     <div>
                        <Row>
                        <Col sm={2} md={2} lg={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.numero}</Label>
                                 <IntegerInput
                                    defaultValue={this.state.configuracaoSelecionada.numero}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.numero = e.floatValue;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {/* <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.repeticoes}</Label>
                                 <IntegerInput
                                    // defaultValue={this.state.configuracaoSelecionada.repeticoes}
                                    defaultValue={1}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.repeticoes = e.floatValue;
                                       });
                                    }}
                                    readOnly={true}
                                 />
                              </FormGroup>
                           </Col> */}
                           <Col>
                              <FormGroup>
                                 <Label>{lang.cadastroDeSequenciaASE.tipoDeTeste}</Label>
                                 <Select
                                    defaultValue={this.state.configuracaoSelecionada.tipoDeTeste}
                                    options={getASE_TipoDeTeste(lang)}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.descricao}
                                    allowEmpty={false}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i != null) {
                                             state.configuracaoSelecionada.tipoDeTeste = i.id;
                                          } else {
                                             state.configuracaoSelecionada.tipoDeTeste = 0;
                                          }
                                          let configuracaoSelecionada = state.configuracaoSelecionada;
                                          this.setValoresDefault(configuracaoSelecionada);
                                          state.configuracaoSelecionada = configuracaoSelecionada;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.resistenciaDeTerra && (
                           <Row>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaASE.norma}</Label>
                                    <Select
                                       defaultValue={this.state.configuracaoSelecionada.norma}
                                       options={getTiposDeNormaASE(lang)}
                                       getKeyValue={(i) => i.id}
                                       allowEmpty={false}
                                       getDescription={(i) => i.descricao}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             if (i != null) {
                                                state.configuracaoSelecionada.norma = i.id;
                                             } else {
                                                state.configuracaoSelecionada.norma = 0;
                                             }
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={6} md={6} xl={6}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaASE.limiteMaximo}</Label>
                                    <DecimalInput
                                       lang={lang}
                                       defaultValue={this.state.configuracaoSelecionada.referencia}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.referencia = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.redeDeTensao && (
                           <Fragment>
                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.referencia + ' (V)'}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.referencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.referencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.unidadeDeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={getTiposDeUnidadeDeDesvioASE_RedeDeTensao(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.redeDeCorrente && (
                           <Row>
                              <Col xs={4} md={4} xl={4}>
                                 <FormGroup>
                                    <Label>{lang.cadastroDeSequenciaASE.referencia}</Label>
                                    <DecimalInput
                                       lang={lang}
                                       defaultValue={this.state.configuracaoSelecionada.referencia}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.configuracaoSelecionada.referencia = e.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.resistenciaIsolacao && (
                           <Fragment>
                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={getTiposDeNorma2ASE(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.limiteMaximoM}</Label>
                                       <DecimalInput
                                          defaultValue={this.state.configuracaoSelecionada.referencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.referencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.md1}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.mD1}
                                          name={'md1ResistenciaIsolacao'}
                                          options={getASE_ResistenciaDeIsolacao_MD1(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          acceptZero={true}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.mD1 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.mD1 = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.md2}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.mD2}
                                          options={getASE_ResistenciaDeIsolacao_MD2(lang)}
                                          acceptZero={true}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          name={'md2ResistenciaIsolacao'}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.mD2 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.mD2 = 0;
                                                }
                                             });
                                          }}
                                          filter={(i) => {
                                             return !(this.state.configuracaoSelecionada.mD1 === 1 && i.id === 1);
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.correnteDeFugaManual && (
                           <Fragment>
                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.md1}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.mD1}
                                          options={getTiposDeMDASECorrenteDeFugaManual(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          acceptZero={true}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.mD1 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.mD1 = 0;
                                                }
                                             }).then(() => {
                                                updateState(this, (state) => {
                                                   let md2Options = this.getMd2CorrentDeFugaManual(
                                                      state.configuracaoSelecionada
                                                   );
                                                   state.configuracaoSelecionada.mD2 = 0;
                                                   state.md2Options = md2Options;
                                                });
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.md2}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.mD2}
                                          options={this.state.md2Options}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          acceptZero={true}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.mD2 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.mD2 = 0;
                                                }
                                             });
                                          }}
                                          filter={(i) => {
                                             return !(this.state.configuracaoSelecionada.mD1 === 11 && i.id === 11);
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 25 }}>
                                       <div style={{ width: 100 }}>
                                          <CheckBox
                                             defaultChecked={this.state.configuracaoSelecionada.fase === 1}
                                             name='fase'
                                             label={lang.cadastroDeSequenciaASE.fase}
                                             onChange={(checked) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.fase = checked ? 1 : 0;
                                                });
                                             }}
                                          />
                                       </div>
                                       <div style={{ width: 100 }}>
                                          <CheckBox
                                             defaultChecked={this.state.configuracaoSelecionada.neutro === 1}
                                             name='neutro'
                                             label={lang.cadastroDeSequenciaASE.neutro}
                                             onChange={(checked) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.neutro = checked ? 1 : 0;
                                                });
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                       <div style={{ width: 100 }}>
                                          <CheckBox
                                             defaultChecked={this.state.configuracaoSelecionada.terra === 1}
                                             name='terra'
                                             label={lang.cadastroDeSequenciaASE.terra}
                                             onChange={(checked) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.terra = checked ? 1 : 0;
                                                });
                                             }}
                                          />
                                       </div>
                                       <div style={{ width: 100 }}>
                                          <CheckBox
                                             defaultChecked={this.state.configuracaoSelecionada.reverso === 1}
                                             name='reverso'
                                             label={lang.cadastroDeSequenciaASE.reverso}
                                             onChange={(checked) => {
                                                updateState(this, (state) => {
                                                   state.configuracaoSelecionada.reverso = checked ? 1 : 0;
                                                });
                                             }}
                                          />
                                       </div>
                                    </div>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.fonteDeTensaoExt}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.vext}
                                          options={getFonteDeTensaoExtASECorrenteDeFugaManual(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.vext = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.vext = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.fonteDeTensaoExtConf}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.vextRede}
                                          options={getTensaoExtASECorrenteDeFugaManual(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.vextRede = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.vextRede = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.af}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.af}
                                          options={getTiposDePAEKelvinASE(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.af = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.af = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.kelvin}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.kelvin}
                                          options={getTiposDePAEKelvinASE(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.kelvin = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.kelvin = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.pa}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.configPA}
                                          options={getTiposDePAEKelvinASE(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.configPA = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.configPA = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.limiteMaximoA2}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.referencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.referencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.correnteDeFugaNorma && (
                           <Fragment>
                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.norma}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.norma}
                                          options={getTiposDeNorma3ASE(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                console.log(i);
                                                if (i != null) {
                                                   state.configuracaoSelecionada.norma = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.norma = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 {this.state.configuracaoSelecionada.norma > 0 && (
                                    <Col xs={6} md={6} xl={6}>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaASE.figura}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.figura}
                                             options={
                                                this.state.configuracaoSelecionada.norma === 1
                                                   ? getTiposDeFigurasASE_NORMA_62353(lang)
                                                   : this.state.configuracaoSelecionada.norma === 2
                                                   ? getTiposDeFigurasASE_NORMA_60601(lang)
                                                   : []
                                             }
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.figura = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.figura = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                              </Row>

                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.limiteMaximoA2}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.referencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.referencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaASE.partesAplicadas}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.pa}
                                          options={getTiposDeParteAplicadasPAASE(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.pa = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.pa = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            <Button
               text={this.props.lang.sequencia.duplicarSequencia}
               style={{ width: 200 }}
               onClick={() => {
                  let item = JSON.parse(JSON.stringify(this.state.itemSelecionado));
                  item.id = null;
                  if (item.itens != null) {
                     for (let i = 0; i < item.itens.length; i++) {
                        item.itens[i].id = null;
                     }
                  }

                  if (item.tiposDeEnsaio != null) {
                     for (let i = 0; i < item.tiposDeEnsaio.length; i++) {
                        item.tiposDeEnsaio[i].id = null;
                     }
                  }

                  if (item.checkList != null) {
                     for (let i = 0; i < item.checkList.length; i++) {
                        item.checkList[i].id = null;
                     }
                  }

                  updateState(this, (state) => {
                     state.incluindo = false;
                     state.alterando = false;
                     state.valorPadrao = item;
                  }).then(() => {
                     this.form.inserirClick();
                  });
               }}
            />
            <br />
         </div>
      );
   };

   aposSalvar = (subCadastro, inserindo, alterando) => {
      let ordem = this.state.ultimoItemCriado.numero;
      updateState(this, (state) => {
         state.itemSelecionado.itens.sort((i, j) => {
            let result = i.numero < j.numero ? -1 : i.numero > j.numero ? 1 : 0;
            if (j.numero === ordem && i.numero === ordem) {
               result = -1;
            }
            return result;
         });
         state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
      });
      if (inserindo) {
         subCadastro.novo();
      }
   };

   setValoresDefault = (item) => {
      let padrao = null;

      if (this.state.ultimoItemCriado && !this.state.configuracaoSelecionada) {
         padrao = this.state.ultimoItemCriado;
      } else if (item !== null) {
         if (item.tipoDeTeste === TIPO_DE_TESTE.redeDeTensao) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteInferior: 10,
               limiteSuperior: 10,
               unidadeLimites: 0,
               referencia: 0,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.correnteDeFugaManual) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               referencia: 500,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.correnteDeFugaNorma) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               referencia: 500,
            };
         }
      } else {
         padrao = {
            tipoDeTeste: 0,
         };
      }

      if (padrao) {         
         item.unidadeLimites = padrao.unidadeLimites;
         item.limiteSuperior = padrao.limiteSuperior;
         item.limiteInferior = padrao.limiteInferior;
         item.configuration = padrao.configuration;
         item.figura = padrao.figura;
         item.mD1 = padrao.mD1;
         item.mD2 = padrao.mD2;
         item.norma = padrao.norma;
         item.pa = padrao.pa;
         item.referencia = padrao.referencia;
         item.tipoDeTeste = padrao.tipoDeTeste;
      }
   };

   getMd2CorrentDeFugaManual = (configuracaoSelecionada) => {
      let list = [];
      let tag = [];

      switch (configuracaoSelecionada.mD1) {
         case Constants.ASE_CF_MD_PA:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         default:
            break;

         case Constants.ASE_CF_MD_PA1:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.pa = 0;
            break;

         case Constants.ASE_CF_MD_PA2:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA3:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA4:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA5:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA6:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA7:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA8:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA9:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PA10:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD1_PAALL:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FN_BK_DPE_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_BK_DPE_AF],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
               Constants.ASE_CF_MD2_FASE_NEUTRO,
               Constants.ASE_CF_MD2_FN_BK_DPE_AF,
               Constants.ASE_CF_MD2_BK_DPE_AF,
            ];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_BK:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PEDUT],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_PEDUT,
               Constants.ASE_CF_MD2_PE,
            ];

            // Se kelvin selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            break;

         case Constants.ASE_CF_MD_PEDUT:
            list = [
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA1],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA2],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA3],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA4],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA5],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA6],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA7],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA8],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA9],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_PA10],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_AF],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD_BK],
               Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE],
            ];

            tag = [
               Constants.ASE_CF_MD_PA,
               Constants.ASE_CF_MD_PA1,
               Constants.ASE_CF_MD_PA2,
               Constants.ASE_CF_MD_PA3,
               Constants.ASE_CF_MD_PA4,
               Constants.ASE_CF_MD_PA5,
               Constants.ASE_CF_MD_PA6,
               Constants.ASE_CF_MD_PA7,
               Constants.ASE_CF_MD_PA8,
               Constants.ASE_CF_MD_PA9,
               Constants.ASE_CF_MD_PA10,
               Constants.ASE_CF_MD2_AF,
               Constants.ASE_CF_MD_BK,
               Constants.ASE_CF_MD2_PE,
            ];

            // Se PE DUT selecionado em MD1 abre terra
            //configuracaoSelecionada.terra = 0;
            break;

         case Constants.ASE_CF_MD1_PA_BK_DPE_AF:
            list = [Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_FASE_NEUTRO]];

            tag = [Constants.ASE_CF_MD2_FASE_NEUTRO];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            //configuracaoSelecionada.af = 0;
            //configuracaoSelecionada.kelvin = 0;
            break;

         case Constants.ASE_CF_MD1_PA_BK_AF:
            list = [Constants.ASE_CF_CONFIG_MD2[Constants.ASE_CF_MD2_PE]];

            tag = [Constants.ASE_CF_MD2_PE];

            // Se parte aplicada selecionada, nao pode ligar a terra
            //configuracaoSelecionada.configPA = 0;
            //configuracaoSelecionada.af = 0;
            //configuracaoSelecionada.kelvin = 0;
            //configuracaoSelecionada.vext = 0;
            break;
      }

      let md2Options = [];
      for (let i = 0; i < list.length; i++) {
         let id = tag[i];
         if (id === null) {
            console.error('Opção inválida');
         }
         md2Options.push({ id: tag[i], descricao: list[i] });
      }

      return md2Options;
   };

   render() {
      return (
         <FormularioPadrao
            titulo={
               <div>
                  <Link
                     to={'/sequencia'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',

                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.sequencias}</span>                     
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.cadastroDeSequenciaASE.titulo}</span>
               </div>
            }
            url={'/sequenciaase'}
            fastUrl={'/sequenciaase/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1211, 1212, 1213, 1214]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            definirValoresPadrao={(item) => {
               return this.state.valorPadrao ? this.state.valorPadrao : item;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               acessos: [],
            }}
         />
      );
   }
}

const Constants = {
   ASE_CF_CONFIG_MD2: [
      'PA - Parte Aplicada',
      'PA1 - Parte Aplicada',
      'PA2 - Parte Aplicada',
      'PA3 - Parte Aplicada',
      'PA4 - Parte Aplicada',
      'PA5 - Parte Aplicada',
      'PA6 - Parte Aplicada',
      'PA7 - Parte Aplicada',
      'PA8 - Parte Aplicada',
      'PA9 - Parte Aplicada',
      'PA10 - Parte Aplicada',
      'Aterramento Funcional (EST)',
      'Ponta Kelvin',
      'Aterramento de Proteção (EST)',
      'Aterramento de Proteção (REDE)',
      'Fase/Neutro (EST)',
      'Fase/Neutro/Kelvin/AP EST/Aterramento Funcional',
      'Kelvin/AP EST/Aterramento Funcional',
   ],
   ASE_CF_MD_PA: 0,
   ASE_CF_MD_PA1: 1,
   ASE_CF_MD_PA2: 2,
   ASE_CF_MD_PA3: 3,
   ASE_CF_MD_PA4: 4,
   ASE_CF_MD_PA5: 5,
   ASE_CF_MD_PA6: 6,
   ASE_CF_MD_PA7: 7,
   ASE_CF_MD_PA8: 8,
   ASE_CF_MD_PA9: 9,
   ASE_CF_MD_PA10: 10,
   ASE_CF_MD1_PAALL: 11,
   ASE_CF_MD2_AF: 11,
   ASE_CF_MD_BK: 12,
   ASE_CF_MD_PEDUT: 13,
   ASE_CF_MD1_PA_BK_DPE_AF: 14,
   ASE_CF_MD2_PE: 14,
   ASE_CF_MD1_PA_BK_AF: 15,
   ASE_CF_MD2_FASE_NEUTRO: 15,
   ASE_CF_MD2_FN_BK_DPE_AF: 16,
   ASE_CF_MD2_BK_DPE_AF: 17,
};
