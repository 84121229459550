import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LayoutParams } from './../config/LayoutParams';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import IconButton from '../components/IconButton';
import { faBars, faSignOutAlt, faUserCircle, faUser, faGlobeAmericas, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './../contents/css/home-logo.css';
import SelecionarIdiomaView from '../views/SelecionarIdiomaView';
import { LogoHeaderHome } from '../components/LogoHeaderHome';
import Avatar from './Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let corDoTemaPrincipal = LayoutParams.colors.corDoTemaPrincipal;
let corDaBarraSuperior = '#ffff';

export default class MainLayout extends Component {
   constructor(props) {
      super(props);

      this.state = {
         showMenu: true,
      };

      this.toggleMenu = this.toggleMenu.bind(this);
      this.showMenu = this.showMenu.bind(this);
   }

   toggleMenu() {
      this.setState({ showMenu: !this.state.showMenu });
      let evt = new CustomEvent('ontogglemenu', { detail: { showMenu: !this.state.showMenu } });
      window.dispatchEvent(evt);
   }

   showMenu(show) {
      this.setState({ showMenu: show });
   }

   render() {
      return (
         <div style={{ position: 'fixed', height: '100%', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
               <NavigationBar
                  toggleMenu={this.toggleMenu}
                  showMenu={this.showMenu}
                  imgHomeLogo={this.props.imgHomeLogo}
                  login={this.props.login}
                  api={this.props.api}
                  alterarIdioma={this.props.alterarIdioma}
                  lang={this.props.lang}
                  mostrarDadosDaConta={this.props.mostrarDadosDaConta}
               />
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     flexBasis: '100%',
                     overflow: 'hidden',
                     maxHeight: '100%',
                     padding: '0px 0px 0px 0px',
                  }}
               >
                  <SideMenu menuItems={this.props.menuItems} showMenu={this.state.showMenu} />
                  <ContentStyled>
                     <div id='div-conteudo' style={{ overflow: 'auto', width: '100%' }}>{this.props.children}</div>
                  </ContentStyled>
               </div>
            </div>
         </div>
      );
   }
}

class NavigationBar extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }
   componentDidMount() {
      window.addEventListener('resize', () => {
         if (this.navigationBar && this.navigationBar) {
            if (this.navigationBar.offsetWidth < 900) {
               this.props.showMenu(false);
            }
         }
      });

      if (this.navigationBar && this.navigationBar) {
         if (this.navigationBar.offsetWidth < 900) {
            this.props.showMenu(false);
         }
      }
   }

   render() {
      return (
         <div className='' style={NavigationBarStyle} ref={(c) => (this.navigationBar = c)}>
            <SelecionarIdiomaView
               selecionandoIdioma={this.state.selecionandoIdioma}
               onClose={() => this.setState({ selecionandoIdioma: false })}
               alterarIdioma={this.props.alterarIdioma}
            />
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid ' + LayoutParams.colors.corSecundaria,
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               }}
            >
               <div
                  style={{
                     color: 'white',
                     fontSize: 30,
                     lineHeight: 1,
                     textAlign: 'center',
                     cursor: 'pointer',
                     zIndex: 0,
                     width: 70,
                  }}
               >
                  <div style={{ height: '100%', paddingTop: 10 }}>
                     <IconButton icon={faBars} onClick={this.props.toggleMenu} />
                  </div>
               </div>
               <LogoHeaderHome />
            </div>
            <div
               style={{
                  position: 'fixed',
                  top: 6,
                  right: 6,
                  color: LayoutParams.colors.corSecundaria,
                  fontSize: 12,
                  lineHeight: 1,
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: 45,
               }}
            >
               <OverlayTrigger
                  style={{ position: 'relative' }}
                  trigger='click'
                  placement='bottom'
                  rootClose={true}
                  overlay={
                     <Popover id='popover-basic'>
                        <Popover.Title>
                           <div className='noselect' style={{ color: LayoutParams.colors.corDoTemaPrincipal }}>
                              {this.props.login
                                 ? this.props.login.primeiroNome
                                    ? this.props.login.primeiroNome
                                    : this.props.login.nomeDeUsuario
                                 : null}
                           </div>
                        </Popover.Title>
                        <Popover.Content style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                           {this.props.mostrarDadosDaConta && (
                              <Link
                                 to={'/account'}
                                 replace={true}
                                 style={{
                                    color: LayoutParams.colors.corDoTemaPrincipal,
                                    textDecoration: 'none',
                                    padding: '5px 5px 5px 8px',
                                 }}
                              >
                                 <div style={{ display: 'inline-flex' }}>
                                    <div style={{ paddingRight: 10, fontSize: 25 }}>
                                       <IconButton icon={faUser} />
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                       <span
                                          style={{
                                             fontWeight: this.props.fontWeight,
                                             margin: 'auto',
                                          }}
                                       >
                                          {this.props.lang.meusDados}
                                       </span>
                                    </div>
                                 </div>
                              </Link>
                           )}

                           <div
                              style={{
                                 color: LayoutParams.colors.corDoTemaPrincipal,
                                 textDecoration: 'none',
                                 padding: '5px 5px 5px 8px',
                                 cursor: 'pointer',
                              }}
                              onClick={() => {
                                 this.setState({ selecionandoIdioma: true });
                              }}
                           >
                              <div style={{ display: 'inline-flex' }}>
                                 <div style={{ paddingRight: 10, fontSize: 25 }}>
                                    <IconButton icon={faGlobeAmericas} />
                                 </div>
                                 <div style={{ display: 'flex' }}>
                                    <span
                                       style={{
                                          fontWeight: this.props.fontWeight,
                                          margin: 'auto',
                                       }}
                                    >
                                       {this.props.lang.idioma}
                                    </span>
                                 </div>
                              </div>
                           </div>

                           <Link
                              to={'/about'}
                              replace={true}
                              style={{
                                 color: LayoutParams.colors.corDoTemaPrincipal,
                                 textDecoration: 'none',
                                 padding: '5px 5px 5px 8px',
                              }}
                           >
                              <div style={{ display: 'inline-flex' }}>
                                 <div style={{ paddingRight: 10, fontSize: 25 }}>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                 </div>
                                 <div style={{ display: 'flex' }}>
                                    <span
                                       style={{
                                          fontWeight: this.props.fontWeight,
                                          margin: 'auto',
                                       }}
                                    >
                                       {this.props.lang.sobreMenu}
                                    </span>
                                 </div>
                              </div>
                           </Link>
                           

                           <Link
                              to={'/logoff'}
                              replace={true}
                              style={{
                                 color: LayoutParams.colors.corDoTemaPrincipal,
                                 textDecoration: 'none',
                                 padding: '5px 5px 5px 8px',
                              }}
                           >
                              <div style={{ display: 'inline-flex' }}>
                                 <div style={{ paddingRight: 10, fontSize: 25 }}>
                                    <IconButton icon={faSignOutAlt} />
                                 </div>
                                 <div style={{ display: 'flex' }}>
                                    <span
                                       style={{
                                          fontWeight: this.props.fontWeight,
                                          margin: 'auto',
                                       }}
                                    >
                                        {this.props.lang.sair}
                                    </span>
                                 </div>
                              </div>
                           </Link>
                        </Popover.Content>
                     </Popover>
                  }
               >
                  <div className='noselect'>
                     {this.props.login && this.props.login.foto ? (
                        <Avatar
                           lang={this.props.lang}
                           api={this.props.api}
                           image={this.props.login.foto}
                           readOnly={true}
                           width={35}
                        />
                     ) : (
                        <IconButton icon={faUserCircle} style={{ fontSize: 35 }} />
                     )}
                  </div>
               </OverlayTrigger>
            </div>
         </div>
      );
   }
}

class SideMenu extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isSideMenuItemStyleHovered: false,
      };
   }

   render() {
      return (
         <SideMenuStyled className='' showMenu={this.props.showMenu} id='sideMenuStyled'>
            <div
               style={{
                  width: '100%',
                  height: '100%',
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               }}
            >
               <SimpleBar style={{ maxHeight: '100%' }}  >
                  <React.Fragment>
                     {this.props.menuItems.map((item, index) => {
                        if (!item.estaHabilitado) {
                           return null;
                        } else if (item.subMenu) {
                           return (
                              <React.Fragment key={index}>
                                 <SideMenuItem
                                    icone={item.icone}
                                    descricao={item.descricao}
                                    rota={null}
                                    isGrupo={true}
                                    subMenu={item.subMenu}
                                    fontWeight={item.fontWeight}
                                 />
                              </React.Fragment>
                           );
                        } else {
                           return (
                              <SideMenuItem
                                 key={index}
                                 icone={item.icone}
                                 descricao={item.descricao}
                                 rota={item.rota}
                                 isGrupo={false}
                                 fontWeight={item.fontWeight}
                              />
                           );
                        }
                     })}
                  </React.Fragment>
               </SimpleBar>
            </div>
         </SideMenuStyled>
      );
   }
}

class SideMenuItem extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isSideMenuItemStyleHovered: false,
         isColapsed: true,
      };
   }

   render() {
      return (
         <div
            style={this.state.isSideMenuItemStyleHovered ? SideMenuItemStyleHovered : SideMenuItemStyle}
            onMouseEnter={() => this.setState({ isSideMenuItemStyleHovered: true })}
            onMouseLeave={() => this.setState({ isSideMenuItemStyleHovered: false })}
            className='side-menu-item'
         >
            {this.props.isGrupo ? (
               <div>
                  <Button
                     variant='link'
                     style={SideMenuGroupStyle}
                     onClick={() => this.setState({ isColapsed: !this.state.isColapsed })}
                  >
                     <div style={{ display: 'inline-flex' }}>
                        <div style={SideMenuIconStyle}>
                           <IconButton icon={this.props.icone} />
                        </div>
                        <div style={{ display: 'flex' }}>
                           <div style={{ margin: 'auto' }}>
                              <span style={{ fontWeight: this.props.fontWeight }}>{this.props.descricao}</span>
                           </div>
                        </div>
                     </div>
                  </Button>
                  {!this.state.isColapsed &&
                     this.props.subMenu &&
                     this.props.subMenu.map((submenu) => {
                        return submenu.estaHabilitado ? (
                           <SideMenuItem
                              key={submenu.key}
                              icone={submenu.icone}
                              descricao={submenu.descricao}
                              rota={submenu.rota}
                              isSubItem={true}
                           />
                        ) : null;
                     })}
               </div>
            ) : (
               <Link to={this.props.rota} style={SideMenuLinkStyle}>
                  <div style={{ display: 'inline-flex', paddingLeft: this.props.isSubItem ? 12 : 0 }}>
                     <div style={SideMenuIconStyle}>
                        <IconButton icon={this.props.icone} />
                     </div>
                     <div style={{ display: 'flex' }}>
                        <div style={{ margin: 'auto' }}>
                           <span style={{ fontWeight: this.props.fontWeight }}>{this.props.descricao}</span>
                        </div>
                     </div>
                  </div>
               </Link>
            )}
         </div>
      );
   }
}

const SideMenuItemStyle = {
   display: 'inherit',
   minHeight: 30,
   fontSize: 13,
   backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
   color: LayoutParams.colors.corSecundaria,
};

const SideMenuItemStyleHovered = {
   display: 'inherit',
   minHeight: 30,
   fontSize: 13,
   color: LayoutParams.colors.corDoTemaPrincipal,
   backgroundColor: LayoutParams.colors.corSecundaria,
};

const SideMenuGroupStyle = {
   padding: 0,
   display: 'block',
   textDecoration: 'none',
   color: 'inherit',
   minHeight: 40,
   cursor: 'pointer',
   fontSize: 'inherit',
   width: '100%',
   textAlign: 'left',
   outline: 'transparent',
   boxShadow: 'none',
};

const SideMenuLinkStyle = {
   paddingTop: 4,
   display: 'block',
   textDecoration: 'none',
   color: 'inherit',
   minHeight: 30,
   cursor: 'pointer',
   fontSize: 'inherit',
};

const SideMenuIconStyle = {
   textAlign: 'center',
   width: 40,
   fontSize: 25,
};

const NavigationBarStyle = {
   backgroundColor: corDaBarraSuperior,
   color: corDoTemaPrincipal,
   margin: 'auto',
   width: '100%',
   overflow: 'hiden',
   display: 'flex',
   flexDirection: 'row',
};

const ContentStyled = styled.div`
   height: 100%;
   width: 100%;
   overflow-y: auto;
   display: flex;
   font-size: 13px;
`;

const SideMenuStyled = styled.div`
   color: ${LayoutParams.colors.corSecundaria};
   display: ${(props) => (props.showMenu ? 'flex' : 'none')};
   flex-direction: column;
   min-width: 200px;
   background-color: ${LayoutParams.colors.corDoTemaPrincipal};
   overflow-y: hidden;
   overflow-x: hidden;
   max-height: '100%';
   margin-bottom: 0px;
`;
