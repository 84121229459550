import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Button as BoottrapButton } from 'react-bootstrap';
import '../../contents/css/button-input.css';
import { LayoutParams } from '../../config/LayoutParams';

export default class SequenciaView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sequenciasDisponiveis: [],
      };
   }

   componentDidMount() {
      this.props.api
         .get('/sequencia/sequenciasdisponiveisparaempresa')
         .then((result) => {
            this.setState({ sequenciasDisponiveis: result });
         })
         .catch((e) => {});
   }

   render() {
      return (
         <div>
            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
               }}
            >
               <div style={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, height: 45, display: 'flex' }}>
                  <div
                     style={{
                        display: 'table-cell',
                        width: '0',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{this.props.lang.sequenciasDeTeste}</span>
                  </div>
               </div>
            </div>
            <div style={{ textAlign: 'center', maxWidth: 800, margin: 'auto' }}>
               {this.state.sequenciasDisponiveis.filter((i) => i === 0).length > 0 && (
                  <CustomButton rota='/1181' sigla='ABI' descricao='Analisador de Unidade Eletrocirúrgica' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 10).length > 0 && (
                  <CustomButton rota='/1191' sigla='APE' descricao='Analisador de Esfigmomanômetro' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 8).length > 0 && (
                  <CustomButton rota='/1281' sigla='SOP/MOX' descricao='Testador de Oximetria de Pulso' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 9).length > 0 && (
                  <CustomButton rota='/1221' sigla='AVM' descricao='Analisador de Ventilação Mecânica' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 2).length > 0 && (
                  <CustomButton rota='/1241' sigla='JAUMP' descricao='Analisador de Marca-Passo' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 1).length > 0 && (
                  <CustomButton rota='/1231' sigla='JAU' descricao='Analisador de Desfibrilador' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 4).length > 0 && (
                  <CustomButton rota='/1261' sigla='SIM' descricao='Simulador de Paciente' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 5).length > 0 && (
                  <CustomButton rota='/1211' sigla='ASE' descricao='Analisador de Segurança Elétrica' />
               )}
               {this.state.sequenciasDisponiveis.filter((i) => i === 3).length > 0 && (
                  <CustomButton rota='/1271' sigla='SMP' descricao='Simulador Multiparamétrico' />
               )}
               {/* {this.state.sequenciasDisponiveis.filter((i) => i === 3).length > 0 && (
                  <CustomButton rota='/1201' sigla='AQT' descricao='Analisador de Qualificação Térmica' />
               )} */}
               {this.state.sequenciasDisponiveis.filter((i) => i === 3).length > 0 && (
                  <CustomButton rota='/1441' sigla='SPK/SMP' descricao='Simulador Multiparamétrico' />
               )}
                {this.state.sequenciasDisponiveis.filter((i) => i === 3).length > 0 && (
                  <CustomButton rota='/1451' sigla='MDC' descricao='Módulo de Débito Cardíaco' />
               )}
            </div>
         </div>
      );
   }
}

const CustomButton = ({ rota, sigla, descricao }) => {
   return (
      <Link to={rota}>
         <BoottrapButton
            style={{
               height: 80,
               width: 200,
               margin: 4,
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               borderBlockColor: LayoutParams.colors.corSecundaria,
               color: LayoutParams.colors.corSecundaria,
               borderColor: LayoutParams.colors.corSecundaria,
               fontSize: 12,
            }}
         >
            <div>
               <div>{sigla}</div>
               <div>{descricao}</div>
            </div>
         </BoottrapButton>
      </Link>
   );
};
