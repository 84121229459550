import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from './../FormularioPadrao';
import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import PasswordInput from '../../components/PasswordInput';
import SubCadastro from '../../components/SubCadastro';
import Avatar from '../../components/Avatar';
import { updateState, buildQueryString, generatePassword } from '../../utils/Functions';
import CheckBox from '../../components/CheckBox';
import { IconeWhatsApp } from '../../components/IconeWhatsApp';
import UsuarioDeEmpresaController from '../../controllers/Empresa/UsuarioDeEmpresaController';
import PerfilDeUsuarioView from './PerfilDeUsuarioView';
// import CargoDeEmpresaView from './CargoDeEmpresaView';
import EmailInput from '../../components/EmailInput';
import { faKey, faTrash } from '@fortawesome/free-solid-svg-icons';
import { showConfirm } from '../../components/Messages';
import Image from '../../components/Image';

const url = '/usuariodeempresa';

export default class UsuarioDeEmpresaView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         foto: null,
         enviarNovaSenhaPorEmail: false,
      };
      this.controller = new UsuarioDeEmpresaController(this);
      this.lang = this.props.lang;
   }

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>{this.lang.codigo}</Label>
                           <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                        </FormGroup>
                     </Col>
                     <Col sm={9} md={9} lg={9}>
                        <FormGroup>
                           <Label>{this.lang.cadastroDeUsuarioDeEmpresa.situacao}</Label>
                           <Select
                              name='situacao'
                              defaultValue={
                                 this.state.itemSelecionado.situacao ? this.state.itemSelecionado.situacao.id : null
                              }
                              options={[
                                 { id: 1, descricao: this.lang.situacaoDeUsuarioDeEmpresa.ativo },
                                 { id: 2, descricao: this.lang.situacaoDeUsuarioDeEmpresa.inativo },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = i;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col sm={6} md={6} lg={6}>
                        <FormGroup>
                           <Label>{this.lang.cadastroDeUsuarioDeEmpresa.login}</Label>
                           <TextInput
                              readOnly={this.state.alterando}
                              defaultValue={this.state.itemSelecionado.nomeDeUsuario}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.nomeDeUsuario = e.target.value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                     <Col sm={6} md={6} lg={6}>
                        <FormGroup>
                           <Label>{this.lang.cadastroDeUsuarioDeEmpresa.senha}</Label>
                           {!this.state.esconderSenha && (
                              <React.Fragment>
                                 <PasswordInput
                                    defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                                    readOnly={
                                       this.state.itemSelecionado.senha && !this.state.itemSelecionado.senhaAlterada
                                    }
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.itemSelecionado.senha = e.target.value;
                                          state.itemSelecionado.senhaAlterada = true;
                                          state.itemSelecionado.enviarNovaSenhaPorEmail = false;
                                       });
                                    }}
                                    appendIcon={this.state.itemSelecionado.senha ? faTrash : faKey}
                                    appendTitle={this.props.lang.gerarNovaSenha}
                                    onAppendClick={() => {
                                       if (this.state.itemSelecionado.senha) {
                                          showConfirm(
                                             this.props.lang.desejaLimparASenha,
                                             () => {
                                                updateState(this, (state) => {
                                                   state.itemSelecionado.senha = null;
                                                   state.itemSelecionado.senhaAlterada = true;
                                                   state.esconderSenha = true;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderSenha = false;
                                                   });
                                                });
                                             },
                                             null,
                                             this.props.lang.sim,
                                             this.props.lang.nao
                                          );
                                       } else {
                                          showConfirm(
                                             this.props.lang.desejaRealmenteGerarUmaNovaSenha,
                                             () => {
                                                updateState(this, (state) => {
                                                   state.itemSelecionado.senha = generatePassword(true, 8);
                                                   state.itemSelecionado.senhaAlterada = true;
                                                   state.itemSelecionado.enviarNovaSenhaPorEmail = true;
                                                   state.esconderSenha = true;
                                                }).then(() => {
                                                   updateState(this, (state) => {
                                                      state.esconderSenha = false;
                                                   });
                                                });
                                             },
                                             null,
                                             this.props.lang.sim,
                                             this.props.lang.nao
                                          );
                                       }
                                    }}
                                 />
                                 {this.state.itemSelecionado.senhaAlterada && this.state.itemSelecionado.senha && (
                                    <CheckBox
                                       defaultChecked={
                                          this.state.itemSelecionado.enviarNovaSenhaPorEmail ? true : false
                                       }
                                       name='enviarNovaSenhaPorEmail'
                                       label={this.props.lang.enviarANovaSenhaPorEmail}
                                       onChange={(checked) => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.enviarNovaSenhaPorEmail = checked;
                                          });
                                       }}
                                    />
                                 )}
                              </React.Fragment>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>
               </Col>

               <Col style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20 }}>
                  <Avatar
                     lang={this.props.lang}
                     api={this.props.api}
                     image={this.state.itemSelecionado.foto}
                     onChange={(image) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.foto = image;
                        });
                     }}
                  />
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.cadastroDeUsuarioDeEmpresa.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               {/* <Col sm={4} md={4} lg={4}>
                  <FormGroup>
                     <Label>{this.lang.cadastroDeUsuarioDeEmpresa.cpf}</Label>
                     <CpfInput
                        defaultValue={this.state.itemSelecionado.cpf}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.cpf = e.formattedValue;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>  */}
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.cadastroDeUsuarioDeEmpresa.email}</Label>
                     <EmailInput
                        defaultValue={this.state.itemSelecionado.email}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.email = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col md={2} lg={2} xl={2}>
                  <CheckBox
                     defaultChecked={this.state.itemSelecionado.responsavel}
                     name='responsavel'
                     label={this.props.lang.cadastroDeUsuarioDeEmpresa.responsavel}
                     onChange={(checked) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.responsavel = checked;
                        });
                     }}
                  />
               </Col>
               <Col>
                  <CheckBox
                     defaultChecked={this.state.itemSelecionado.executante}
                     name='executante'
                     label={this.props.lang.cadastroDeUsuarioDeEmpresa.executante}
                     onChange={(checked) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.executante = checked;
                        });
                     }}
                  />
               </Col>
            </Row>
            <br />
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{`${this.lang.cadastroDeUsuarioDeEmpresa.assinatura} (100px / 250px)`}</Label>
                     <br />
                     <div
                        style={{
                           height: 102,
                           width: 252,
                           border: '1px solid black',                           
                           textAlign: 'center',
                           position: 'relative',
                           marginBottom: 40 ,
                           paddingBottom: 1,
                           paddingRight: 1,
                        }}
                     >
                        <Image
                           image={this.state.itemSelecionado.assinatura}
                           height={100}
                           maxHeight={100}
                           api={this.props.api}
                           lang={this.props.lang}
                           onChange={(image) => {
                              updateState(this, (state) => (state.itemSelecionado.assinatura = image));
                           }}
                        />
                     </div>
                  </FormGroup>
               </Col>
            </Row>

            <SubCadastro
               titulo={this.lang.cadastroDeUsuarioDeEmpresa.perfisDeUsuario}
               itens={this.state.itemSelecionado.perfis}
               exibirTitulos={false}
               lang={this.lang}
               modal={true}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.perfilSelecionado = null;
                     }).then(resolve);
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.perfilSelecionado = null;
                     }).then(resolve);
                  });
               }}
               inserir={this.controller.inserirPerfil}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.perfilSelecionado = index !== 0 ? state.itemSelecionado.perfis[index] : null;
                     }).then(resolve);
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.perfis.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: this.lang.cadastroDeUsuarioDeEmpresa.descricao, width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.perfil.nome];
               }}
               formulario={() => {
                  return (
                     <div>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{this.lang.cadastroDeUsuarioDeEmpresa.perfilDeUsuario}</Label>
                                 <Select
                                    name={'perfil'}
                                    defaultValue={this.state.perfilSelecionado}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.nome}
                                    onSelect={(i) => {
                                       this.setState({ perfilSelecionado: i });
                                    }}
                                    formularioPadrao={(select) => {
                                       return (
                                          <PerfilDeUsuarioView
                                             api={this.props.api}
                                             select={select}
                                             lang={this.props.lang}
                                          />
                                       );
                                    }}
                                    noDropDown={true}
                                    readOnlyColor='#ffff'
                                    getFilterUrl={(text) =>
                                       '/perfildeusuario/fast' + buildQueryString(2, null, 'id', { Searchable: text })
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     </div>
                  );
               }}
            />
            <br />

            <SubCadastro
               titulo={this.lang.cadastroDeUsuarioDeEmpresa.telefones}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.telefones}
               lang={this.lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.telefoneSelecionado = {
                           pais: null,
                           ddd: null,
                           numero: null,
                           temWhatsApp: false,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={this.controller.inserirTelefone}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.telefoneSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.telefones[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={this.controller.alterarTelefone}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.telefoneSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.telefones.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: '', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [
                     <div style={{ display: 'flex' }}>
                        <div>{item.pais + ' ' + item.ddd + ' ' + item.numero + ' '} </div>
                        <div>{item.temWhatsApp ? <IconeWhatsApp key={'iconeWhatsApp'} /> : null}</div>
                     </div>,
                  ];
               }}
               formulario={() => {
                  return (
                     <div>
                        <Row>
                           <Col>
                              <div style={{ display: 'flex' }}>
                                 <FormGroup>
                                    <Label>{this.lang.telefone.pais}</Label>
                                    <TextInput
                                       name='pais'
                                       defaultValue={this.state.telefoneSelecionado.pais}
                                       style={{ width: '60px' }}
                                       maxLength={3}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.pais = e.target.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                                 <FormGroup>
                                    <Label>{this.lang.telefone.ddd}</Label>
                                    <TextInput
                                       name='ddd'
                                       defaultValue={this.state.telefoneSelecionado.ddd}
                                       style={{ width: '60px' }}
                                       maxLength={2}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.ddd = e.target.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                                 <FormGroup>
                                    <Label>{this.lang.telefone.numero}</Label>
                                    <TextInput
                                       name='telefone'
                                       defaultValue={this.state.telefoneSelecionado.numero}
                                       maxLength={9}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.numero = e.target.value;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                                 <Form.Group
                                    controlId='chktemWhatsApp'
                                    style={{
                                       margin: 'auto',
                                       paddingLeft: '5px',
                                       fontWeight: 'normal',
                                       width: '100%',
                                    }}
                                 >
                                    <CheckBox
                                       defaultChecked={this.state.telefoneSelecionado.temWhatsApp ? true : false}
                                       name='WhatsApp'
                                       label='WhatsApp'
                                       onChange={(checked) => {
                                          updateState(this, (state) => {
                                             state.telefoneSelecionado.temWhatsApp = checked;
                                          });
                                       }}
                                    />
                                 </Form.Group>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.lang.cadastroDeUsuarioDeEmpresa.titulo}
            url={url}
            fastUrl={url + '/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            permissoes={[1121, 1122, 1123, 1124]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               telefones: [],
               perfis: [],
               cargos: [],
               situacao: { id: 1 },
            }}
         />
      );
   }
}
