import React, { Component } from 'react';

export default class HomeView extends Component {
	render() {
		return (
			<React.Fragment>
				{/* <iframe frameBorder="none" src="http://www.neos.ind.br" style={{height: '100%', width: '100%'}}></iframe> */}
			</React.Fragment>
		);
	}
}
