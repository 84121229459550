import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import { buildQueryString, updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import SequenciaSIMController from '../../controllers/Sequencias/SequenciaSIMController';
import {
   getTiposDeTipoDeSinalSicronismoJAU,
   getTiposDeSinalJAU,
   getTiposDeAmplitudeJAU,
   getTiposDeUnidadeDeDesvioECGFrequenciaJAU,
   getTiposDeTestesSIM,
   getTiposDeUnidadeDeDesvioECGAmplitudeJAU,
   getTiposDeUnidadeDeDesvioRespiracaoBRPMJAU,
   getTiposDeTaxaDeInspJAU,
   getTiposDeAmplitudeRespiracaoJAU,
   getTiposDeUnidadeDeDesvioRespiracaoJAU,
   getTiposDeArritmiasPerformanceJAU,
   getTiposDeSinalArritimiaSIM,
   getTiposDeSinalSenoideJAU,
   getTiposDeArritimiaJAU,
   getTiposDeSinalArritimiaSMP,
   getTiposDeSinalSenoideSMP,
   getUnidadeDeDesvioSMP_ECG_FREQUENCIA,
   getUnidadeDeDesvioSMP_ECG_AMPLITUDE,
   getUnidadeDeDesvioSMP_RESPIRACAO_FREQUENCIA,
   getUnidadeDeDesvioSMP_RESPIRACAO_AMPLITUDE,
   getUnidadeDeDesvioSMP_APNEIA,
   getTiposDeAmplitudeSIM,
} from './../Descricoes';
import { showError } from '../../components/Messages';
import { Link } from 'react-router-dom';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';
import TipoDeEnsaioView from './TipoDeEnsaioView';
import Button from '../../components/Button';
import CheckListView from '../Empresa/CheckListView';

const TIPO_DE_TESTE = {
   ecgFrquencia: 0,
   ecgAmplitude: 1,
   arritmiasPerformance: 2,
   respiracaoFrequencia: 3,
   respiracaoAmplitude: 4,
   respiracaoApneia: 5,
};

export default class SequenciaSIMView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new SequenciaSIMController(this);
   }

   setUltimoItemCriado = (item) => {
      updateState(this, (state) => {   
         state.ultimoItemCriado = {};      
         state.ultimoItemCriado.numero = item.numero;
         state.ultimoItemCriado.repeticoes = item.repeticoes;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.limiteSuperior = item.limiteSuperior;
         state.ultimoItemCriado.limiteInferior = item.limiteInferior;
         state.ultimoItemCriado.unidadeLimites = item.unidadeLimites;
         state.ultimoItemCriado.tipoDeSinal = item.tipoDeSinal;
         state.ultimoItemCriado.sinal = item.sinal;
         state.ultimoItemCriado.amplitude = item.amplitude;
         state.ultimoItemCriado.respiracao_Frequencia = item.respiracao_Frequencia;
         state.ultimoItemCriado.respiracao_Taxa = item.respiracao_Taxa;
         state.ultimoItemCriado.respiracao_Amplitude = item.respiracao_Amplitude;
         state.ultimoItemCriado.respiracao_TempoApneia = item.respiracao_TempoApneia;
      });
   };

   getDescricaoDeTeste = (id) => {
      let result = getTiposDeTestesSIM(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeSinal = (id) => {
      let result = getTiposDeSinalJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDaUnidadeDeDesvio = (id) => {
      let result = getTiposDeUnidadeDeDesvioECGFrequenciaJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeUnidadeDeDesvioECGAmplitudeSIM = (id) => {
      let result = getTiposDeUnidadeDeDesvioECGAmplitudeJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeAmplitude = (id) => {
      let result = getTiposDeAmplitudeJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeUnidadeDeDesvioRespiracaoBRPMJ = (id) => {
      let result = getTiposDeUnidadeDeDesvioRespiracaoBRPMJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeUnidadeDeDesvioRespiracao = (id) => {
      let result = getTiposDeUnidadeDeDesvioRespiracaoJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoDeAmplitudeRespiracao = (id) => {
      let result = getTiposDeAmplitudeRespiracaoJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeSinalArritimia = (id) => {
      let result = getTiposDeSinalArritimiaSIM(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeSinalSenoide = (id) => {
      let result = getTiposDeSinalSenoideJAU(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };


   getDescricaoModosDeSinalArritimia = (id) => {
      let result = getTiposDeSinalArritimiaSMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeSinalSenoide = (id) => {
      let result = getTiposDeSinalSenoideSMP(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getUnidadeDesvio = (tipoDeTeste) => {
      let lang = this.props.lang;
      let result = [];
      switch (tipoDeTeste) {
         case TIPO_DE_TESTE.ecgFrquencia:
            result = getUnidadeDeDesvioSMP_ECG_FREQUENCIA(lang);
            break;
         case TIPO_DE_TESTE.ecgAmplitude:
            result = getUnidadeDeDesvioSMP_ECG_AMPLITUDE(lang);
            break;
         case TIPO_DE_TESTE.respiracaoFrequencia:
            result = getUnidadeDeDesvioSMP_RESPIRACAO_FREQUENCIA(lang);
            break;
         case TIPO_DE_TESTE.respiracaoAmplitude:
            result = getUnidadeDeDesvioSMP_RESPIRACAO_AMPLITUDE(lang);
            break;
         case TIPO_DE_TESTE.respiracaoApneia:
            result = getUnidadeDeDesvioSMP_APNEIA(lang);
            break;
         default:
            break;
      }
      return result;
   };

   getDescricaoDaUnidadeDesvio = (tipoDeTeste, unidadeDeDesvio) => {
      let opcoes = this.getUnidadeDesvio(tipoDeTeste);
      let opcao = opcoes.filter((i) => i.id === unidadeDeDesvio)[0];
      var result = null;
      if (opcao) {
         result = opcao.descricao;
      }
      return result;
   };


   renderizarFormulario = () => {
      let lang = this.props.lang;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{lang.sequencia.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.sequencia.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.sequencia.ativa },
                           { id: 2, descricao: lang.sequencia.inativa },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={lang.sequencia.tiposDeEnsaio}
               modal={true}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.tiposDeEnsaio}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = {
                           tipoDeEnsaio: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio) {
                        showError(lang.sequencia.mensagens.informeOTipoDeEnsaio);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.tiposDeEnsaio) {
                           state.itemSelecionado.tiposDeEnsaio = [];
                        }
                        state.itemSelecionado.tiposDeEnsaio.push({
                           tipoDeEnsaio: this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.tiposDeEnsaio.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Cargo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.tipoDeEnsaio.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{lang.sequencia.tipoDeEnsaio}</Label>
                           <Select
                              name={'tipoDeEnsaio'}
                              modal={true}
                              defaultValue={this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.tipoDeEnsaioSelecionado.tipoDeEnsaio = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <TipoDeEnsaioView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/tipodeensaio' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />

            <br />
            {!this.state.itemSelecionado.sequenciaNeos && (
               <SubCadastro
                  titulo={lang.sequencia.checkList}
                  modal={true}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.checkList}
                  lang={lang}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = {
                              checkList: null,
                           };
                        }).then(() => resolve());
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve, reject) => {
                        if (!this.state.checkListSelecionado.checkList) {
                           showError(lang.sequencia.mensagens.informeOCheckList);
                           reject();
                           return false;
                        }

                        updateState(this, (state) => {
                           if (!state.itemSelecionado.checkList) {
                              state.itemSelecionado.checkList = [];
                           }
                           state.itemSelecionado.checkList.push({
                              checkList: this.state.checkListSelecionado.checkList,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = null;
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.checkList.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: lang.sequencia.checkList, width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.checkList.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <Label>{lang.sequencia.checkList}</Label>
                              <Select
                                 name={'checkList'}
                                 modal={true}
                                 defaultValue={this.state.checkListSelecionado.checkList}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.checkListSelecionado.checkList = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <CheckListView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/checklist' + buildQueryString(2, null, 'descricao', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     );
                  }}
               />
            )}

            <br />

            <SubCadastro
               titulo={lang.sequencia.testes}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.itens}
               gradeSempreVisivel={true}
               tituloDoBotaoCancelar={lang.sequencia.finalizarCadastroDeTeste}
               lang={lang}
               novo={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? () => {
                          return new Promise((resolve) => {
                             let maiorNumero = 0;
                             if (this.state.itemSelecionado.itens) {
                                this.state.itemSelecionado.itens.map((i, index) => {
                                   if (i.numero > maiorNumero) {
                                      maiorNumero = i.numero;
                                   }
                                   return true;
                                });
                             }
                             maiorNumero++;
                             updateState(this, (state) => {
                              state.configuracaoSelecionada = null;
                              let configuracaoSelecionada = { tipoDeTeste: 0 };
                              this.setValoresDefault(configuracaoSelecionada);
                              configuracaoSelecionada.numero = maiorNumero;
                              state.configuracaoSelecionada = configuracaoSelecionada;
                             }).then(() => resolve());
                          });
                       }
                     : null
               }
               inserir={() => {
                  return new Promise((resolve, reject) => {

                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (
                        this.state.configuracaoSelecionada.tipoDeTeste === 0 ||
                        this.state.configuracaoSelecionada.tipoDeTeste === 1 ||
                        this.state.configuracaoSelecionada.tipoDeTeste === 3
                     ) {
                        if (
                           !this.state.configuracaoSelecionada.limiteSuperior ||
                           this.state.configuracaoSelecionada.limiteSuperior === 0
                        ) {
                           showError(lang.cadastroDeSequenciaSIM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                        if (
                           !this.state.configuracaoSelecionada.limiteInferior ||
                           this.state.configuracaoSelecionada.limiteSuperior === 0
                        ) {
                           showError(lang.cadastroDeSequenciaSIM.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.itens) {
                           state.itemSelecionado.itens = [];
                        }

                        let item = {
                           numero: this.state.configuracaoSelecionada.numero,
                           repeticoes: 1,
                           tipoDeTeste: this.state.configuracaoSelecionada.tipoDeTeste,
                           limiteSuperior: this.state.configuracaoSelecionada.limiteSuperior,
                           limiteInferior: this.state.configuracaoSelecionada.limiteInferior,
                           unidadeLimites: this.state.configuracaoSelecionada.unidadeLimites,
                           tipoDeSinal: this.state.configuracaoSelecionada.tipoDeSinal,
                           sinal: this.state.configuracaoSelecionada.sinal,
                           amplitude: this.state.configuracaoSelecionada.amplitude,
                           respiracao_Frequencia: this.state.configuracaoSelecionada.respiracao_Frequencia,
                           respiracao_Taxa: this.state.configuracaoSelecionada.respiracao_Taxa,
                           respiracao_Amplitude: this.state.configuracaoSelecionada.respiracao_Amplitude,
                           respiracao_TempoApneia: this.state.configuracaoSelecionada.respiracao_TempoApneia,
                        };

                        state.itemSelecionado.itens.push(item);
                        this.setUltimoItemCriado(item);
                     }).then(() => resolve());
                  });
               }}
               aposSalvar={this.aposSalvar}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {

                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (
                        this.state.configuracaoSelecionada.tipoDeTeste === 0 ||
                        this.state.configuracaoSelecionada.tipoDeTeste === 1 ||
                        this.state.configuracaoSelecionada.tipoDeTeste === 3
                     ) {
                        if (
                           !this.state.configuracaoSelecionada.limiteSuperior ||
                           this.state.configuracaoSelecionada.limiteSuperior === 0
                        ) {
                           showError(lang.cadastroDeSequenciaSIM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                        if (
                           !this.state.configuracaoSelecionada.limiteInferior ||
                           this.state.configuracaoSelecionada.limiteSuperior === 0
                        ) {
                           showError(lang.cadastroDeSequenciaSIM.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }
                     updateState(this, (state) => {
                        state.itemSelecionado.itens[index].numero = this.state.configuracaoSelecionada.numero;
                        state.itemSelecionado.itens[index].repeticoes = this.state.configuracaoSelecionada.repeticoes;
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;
                        state.itemSelecionado.itens[index].limiteSuperior =
                           this.state.configuracaoSelecionada.limiteSuperior;
                        state.itemSelecionado.itens[index].limiteInferior =
                           this.state.configuracaoSelecionada.limiteInferior;
                        state.itemSelecionado.itens[index].unidadeLimites =
                           this.state.configuracaoSelecionada.unidadeLimites;
                        state.itemSelecionado.itens[index].tipoDeSinal = this.state.configuracaoSelecionada.tipoDeSinal;
                        state.itemSelecionado.itens[index].sinal = this.state.configuracaoSelecionada.sinal;
                        state.itemSelecionado.itens[index].amplitude = this.state.configuracaoSelecionada.amplitude;
                        state.itemSelecionado.itens[index].respiracao_Frequencia =
                           this.state.configuracaoSelecionada.respiracao_Frequencia;
                        state.itemSelecionado.itens[index].respiracao_Taxa =
                           this.state.configuracaoSelecionada.respiracao_Taxa;
                        state.itemSelecionado.itens[index].respiracao_Amplitude =
                           this.state.configuracaoSelecionada.respiracao_Amplitude;
                        state.itemSelecionado.itens[index].respiracao_TempoApneia =
                           this.state.configuracaoSelecionada.respiracao_TempoApneia;
                        this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? (index) => {
                          updateState(this, (state) => {
                             state.itemSelecionado.itens.splice(index, 1);
                             state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
                          });
                       }
                     : null
               }
               colunas={() => {
                  return [
                     { titulo: 'Nº', width: '3%', className: null },
                     { titulo: 'Tipo de Teste', width: '17%', className: null },
                     { titulo: 'Valor de referência', width: '22%', className: null },
                     { titulo: 'Desvio máximo superior', width: '17%', className: null },
                     { titulo: 'Desvio máximo inferior', width: '16%', className: null },
                     { titulo: 'Unid desvio', width: '25%', className: null },
                  ];
               }}
               linha={(item) => {
                  if (item.tipoDeTeste === TIPO_DE_TESTE.ecgFrquencia) {
                     return [
                        item.numero,
                        this.getDescricaoDeTeste(item.tipoDeTeste),
                        this.getDescricaoDeSinal(item.sinal),
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.ecgAmplitude) {
                     return [
                        item.numero,
                        this.getDescricaoDeTeste(item.tipoDeTeste),
                        this.getDescricaoDeAmplitude(item.amplitude),
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.arritmiasPerformance) {
                     if (item.tipoDeSinal === 1) {
                        return [
                           item.numero,
                           this.getDescricaoDeTeste(item.tipoDeTeste),
                           this.getDescricaoModosDeSinalArritimia(item.sinal),
                           '-',
                           '-',
                           '-',
                        ];
                     } else {
                        return [
                           item.numero,
                           this.getDescricaoDeTeste(item.tipoDeTeste),
                           this.getDescricaoModosDeSinalSenoide(item.sinal),
                           '-',
                           '-',
                           '-',
                        ];
                     }
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoFrequencia) {
                     return [
                        item.numero,
                        this.getDescricaoDeTeste(item.tipoDeTeste),
                        item.respiracao_Frequencia + ' BrPm',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoAmplitude) {
                     return [
                        item.numero,
                        this.getDescricaoDeTeste(item.tipoDeTeste),
                        item.respiracao_Amplitude.toString() + ' Ω',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoDaUnidadeDesvio(item.tipoDeTeste, item.unidadeLimites),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoApneia) {
                     return [
                        item.numero,
                        this.getDescricaoDeTeste(item.tipoDeTeste),
                        item.respiracao_TempoApneia + ' s',
                        '-',
                        '-',
                        '-',
                     ];
                  }
               }}
               acoes={(subCadastro, index) => {
                  return (
                     <div style={{ display: 'table-cell', margin: 'auto', marginRight: 4, width: 25 }}>
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginBottom: -10 }}
                           cursor='pointer'
                           icon={faSortUp}
                           onClick={() => {
                              if (this.state.itemSelecionado.itens[index].numero > 1) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index - 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero - 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginTop: -10 }}
                           cursor='pointer'
                           icon={faSortDown}
                           onClick={() => {
                              if (
                                 this.state.itemSelecionado.itens[index].numero <
                                 this.state.itemSelecionado.itens.length
                              ) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index + 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero + 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                     </div>
                  );
               }}
               formulario={() => {
                  return this.state.configuracaoSelecionada ? (
                     <div>
                        <Row>
                        <Col sm={2} md={2} lg={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.numero}</Label>
                                 <IntegerInput
                                    defaultValue={this.state.configuracaoSelecionada.numero}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.numero = e.floatValue;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {/* <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.repeticoes}</Label>
                                 <IntegerInput
                                    // defaultValue={this.state.configuracaoSelecionada.repeticoes}
                                    defaultValue={1}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.repeticoes = e.floatValue;
                                       });
                                    }}
                                    readOnly={true}
                                 />
                              </FormGroup>
                           </Col> */}
                           <Col>
                              <FormGroup>
                                 <Label>{lang.cadastroDeSequenciaSIM.tipoDeTeste}</Label>
                                 <Select
                                    defaultValue={this.state.configuracaoSelecionada.tipoDeTeste}
                                    options={getTiposDeTestesSIM(lang)}
                                    getKeyValue={(i) => i.id}
                                    allowEmpty={false}
                                    getDescription={(i) => i.descricao}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i != null) {
                                             state.configuracaoSelecionada.tipoDeTeste = i.id;
                                          } else {
                                             state.configuracaoSelecionada.tipoDeTeste = 0;
                                          }
                                          let configuracaoSelecionada = state.configuracaoSelecionada;
                                          this.setValoresDefault(configuracaoSelecionada);
                                          state.configuracaoSelecionada = configuracaoSelecionada;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ecgFrquencia && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.tipoDeSinal}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeSinal}
                                          options={getTiposDeTipoDeSinalSicronismoJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoDeSinal = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoDeSinal = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.sinal}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.sinal}
                                          options={getTiposDeSinalJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.sinal = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.sinal = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.amplitude}
                                          options={getTiposDeAmplitudeSIM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.unidadeDeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={this.getUnidadeDesvio(this.state.configuracaoSelecionada.tipoDeTeste)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.ecgAmplitude && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.tipoDeSinal}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeSinal}
                                          options={getTiposDeTipoDeSinalSicronismoJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoDeSinal = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoDeSinal = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.sinal}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.sinal}
                                          options={getTiposDeSinalJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.sinal = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.sinal = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.amplitude}
                                          options={getTiposDeAmplitudeSIM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.unidadeDeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={this.getUnidadeDesvio(this.state.configuracaoSelecionada.tipoDeTeste)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.arritmiasPerformance && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaJAU.tipoDeSinal}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeSinal}
                                          options={getTiposDeArritmiasPerformanceJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoDeSinal = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoDeSinal = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 {this.state.configuracaoSelecionada.tipoDeSinal === 1 && (
                                    <Col xs={4} md={4} xl={4}>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaJAU.sinal}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.sinal}
                                             options={getTiposDeSinalArritimiaSIM(lang)}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.sinal = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.sinal = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}

                                 {this.state.configuracaoSelecionada.tipoDeSinal === 2 && (
                                    <Col xs={4} md={4} xl={4}>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaJAU.sinal}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.sinal}
                                             options={getTiposDeSinalSenoideJAU(lang)}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.sinal = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.sinal = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}

                                 {this.state.configuracaoSelecionada.tipoDeSinal === 1 && (
                                    <Col xs={4} md={4} xl={4}>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaJAU.amplitude}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.amplitude}
                                             options={getTiposDeArritimiaJAU(lang)}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.amplitude = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.amplitude = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}

                                 {this.state.configuracaoSelecionada.tipoDeSinal === 2 && (
                                    <Col xs={4} md={4} xl={4}>
                                       <FormGroup>
                                          <Label>{lang.cadastroDeSequenciaJAU.amplitude}</Label>
                                          <Select
                                             defaultValue={this.state.configuracaoSelecionada.amplitude}
                                             options={getTiposDeAmplitudeJAU(lang)}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.descricao}
                                             allowEmpty={false}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   if (i != null) {
                                                      state.configuracaoSelecionada.amplitude = i.id;
                                                   } else {
                                                      state.configuracaoSelecionada.amplitude = 0;
                                                   }
                                                });
                                             }}
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoFrequencia && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.frequenciaBRPM}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Frequencia}
                                          options={[
                                             { id: 10, descricao: '10' },
                                             { id: 15, descricao: '15' },
                                             { id: 20, descricao: '20' },
                                             { id: 25, descricao: '25' },
                                             { id: 30, descricao: '30' },
                                             { id: 35, descricao: '35' },
                                             { id: 40, descricao: '40' },
                                             { id: 45, descricao: '45' },
                                             { id: 50, descricao: '50' },
                                             { id: 55, descricao: '55' },
                                             { id: 60, descricao: '60' },
                                             { id: 65, descricao: '65' },
                                             { id: 70, descricao: '70' },
                                             { id: 75, descricao: '75' },
                                             { id: 80, descricao: '80' },
                                             { id: 85, descricao: '85' },
                                             { id: 90, descricao: '90' },
                                             { id: 95, descricao: '95' },
                                             { id: 100, descricao: '100' },
                                             { id: 105, descricao: '105' },
                                             { id: 110, descricao: '110' },
                                             { id: 115, descricao: '115' },
                                             { id: 120, descricao: '120' },
                                             { id: 125, descricao: '125' },
                                             { id: 130, descricao: '130' },
                                             { id: 135, descricao: '135' },
                                             { id: 140, descricao: '140' },
                                             { id: 145, descricao: '145' },
                                             { id: 150, descricao: '150' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Frequencia = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Frequencia = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.taxaInsp}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Taxa}
                                          options={getTiposDeTaxaDeInspJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Taxa = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Taxa = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Amplitude}
                                          options={getTiposDeAmplitudeRespiracaoJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.unidadeDeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={this.getUnidadeDesvio(this.state.configuracaoSelecionada.tipoDeTeste)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoAmplitude && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.frequenciaBRPM}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Amplitude}
                                          options={[
                                             { id: 10, descricao: '10' },
                                             { id: 15, descricao: '15' },
                                             { id: 20, descricao: '20' },
                                             { id: 25, descricao: '25' },
                                             { id: 30, descricao: '30' },
                                             { id: 35, descricao: '35' },
                                             { id: 40, descricao: '40' },
                                             { id: 45, descricao: '45' },
                                             { id: 40, descricao: '40' },
                                             { id: 55, descricao: '55' },
                                             { id: 60, descricao: '60' },
                                             { id: 65, descricao: '65' },
                                             { id: 70, descricao: '70' },
                                             { id: 75, descricao: '75' },
                                             { id: 80, descricao: '80' },
                                             { id: 85, descricao: '85' },
                                             { id: 90, descricao: '90' },
                                             { id: 95, descricao: '95' },
                                             { id: 100, descricao: '100' },
                                             { id: 105, descricao: '105' },
                                             { id: 110, descricao: '110' },
                                             { id: 115, descricao: '115' },
                                             { id: 120, descricao: '120' },
                                             { id: 125, descricao: '125' },
                                             { id: 130, descricao: '130' },
                                             { id: 135, descricao: '135' },
                                             { id: 140, descricao: '140' },
                                             { id: 145, descricao: '145' },
                                             { id: 150, descricao: '150' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.taxaInsp}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Taxa}
                                          options={getTiposDeTaxaDeInspJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Taxa = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Taxa = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.amplitude}
                                          options={getTiposDeAmplitudeRespiracaoJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaSIM.unidadeDeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={this.getUnidadeDesvio(this.state.configuracaoSelecionada.tipoDeTeste)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.respiracaoApneia && (
                           <Fragment>
                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaJAU.tempodeApneia}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_TempoApneia}
                                          options={[
                                             { id: 1, descricao: '1' },
                                             { id: 2, descricao: '2' },
                                             { id: 3, descricao: '3' },
                                             { id: 4, descricao: '4' },
                                             { id: 5, descricao: '5' },
                                             { id: 6, descricao: '6' },
                                             { id: 7, descricao: '7' },
                                             { id: 8, descricao: '8' },
                                             { id: 9, descricao: '9' },
                                             { id: 10, descricao: '10' },
                                             { id: 11, descricao: '11' },
                                             { id: 12, descricao: '12' },
                                             { id: 13, descricao: '13' },
                                             { id: 14, descricao: '14' },
                                             { id: 15, descricao: '15' },
                                             { id: 16, descricao: '16' },
                                             { id: 17, descricao: '17' },
                                             { id: 18, descricao: '18' },
                                             { id: 19, descricao: '19' },
                                             { id: 20, descricao: '20' },
                                             { id: 21, descricao: '21' },
                                             { id: 22, descricao: '22' },
                                             { id: 23, descricao: '23' },
                                             { id: 24, descricao: '24' },
                                             { id: 25, descricao: '25' },
                                             { id: 26, descricao: '26' },
                                             { id: 27, descricao: '27' },
                                             { id: 28, descricao: '28' },
                                             { id: 29, descricao: '29' },
                                             { id: 30, descricao: '30' },
                                             { id: 31, descricao: '31' },
                                             { id: 32, descricao: '32' },
                                             { id: 33, descricao: '33' },
                                             { id: 34, descricao: '34' },
                                             { id: 35, descricao: '35' },
                                             { id: 36, descricao: '36' },
                                             { id: 37, descricao: '37' },
                                             { id: 38, descricao: '38' },
                                             { id: 39, descricao: '39' },
                                             { id: 40, descricao: '40' },
                                             { id: 41, descricao: '41' },
                                             { id: 42, descricao: '42' },
                                             { id: 43, descricao: '43' },
                                             { id: 44, descricao: '44' },
                                             { id: 45, descricao: '45' },
                                             { id: 46, descricao: '46' },
                                             { id: 47, descricao: '47' },
                                             { id: 48, descricao: '48' },
                                             { id: 49, descricao: '49' },
                                             { id: 50, descricao: '50' },
                                             { id: 51, descricao: '51' },
                                             { id: 52, descricao: '52' },
                                             { id: 53, descricao: '53' },
                                             { id: 54, descricao: '54' },
                                             { id: 55, descricao: '55' },
                                             { id: 56, descricao: '56' },
                                             { id: 57, descricao: '57' },
                                             { id: 58, descricao: '58' },
                                             { id: 59, descricao: '59' },
                                             { id: 60, descricao: '60' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_TempoApneia = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_TempoApneia = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaJAU.frequenciaBRPM}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Frequencia}
                                          options={[
                                             { id: 10, descricao: '10' },
                                             { id: 15, descricao: '15' },
                                             { id: 20, descricao: '20' },
                                             { id: 25, descricao: '25' },
                                             { id: 30, descricao: '30' },
                                             { id: 35, descricao: '35' },
                                             { id: 40, descricao: '40' },
                                             { id: 45, descricao: '45' },
                                             { id: 40, descricao: '40' },
                                             { id: 55, descricao: '55' },
                                             { id: 60, descricao: '60' },
                                             { id: 65, descricao: '65' },
                                             { id: 70, descricao: '70' },
                                             { id: 75, descricao: '75' },
                                             { id: 80, descricao: '80' },
                                             { id: 85, descricao: '85' },
                                             { id: 90, descricao: '90' },
                                             { id: 95, descricao: '95' },
                                             { id: 100, descricao: '100' },
                                             { id: 105, descricao: '105' },
                                             { id: 110, descricao: '110' },
                                             { id: 115, descricao: '115' },
                                             { id: 120, descricao: '120' },
                                             { id: 125, descricao: '125' },
                                             { id: 130, descricao: '130' },
                                             { id: 135, descricao: '135' },
                                             { id: 140, descricao: '140' },
                                             { id: 145, descricao: '145' },
                                             { id: 150, descricao: '150' },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Frequencia = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Frequencia = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaJAU.taxaInsp}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Taxa}
                                          options={getTiposDeTaxaDeInspJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Taxa = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Taxa = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaJAU.amplitude}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.respiracao_Amplitude}
                                          options={getTiposDeAmplitudeRespiracaoJAU(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.respiracao_Amplitude = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.respiracao_Amplitude = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            <Button
               text={this.props.lang.sequencia.duplicarSequencia}
               style={{ width: 200 }}
               onClick={() => {
                  let item = JSON.parse(JSON.stringify(this.state.itemSelecionado));
                  item.id = null;
                  if (item.itens != null) {
                     for (let i = 0; i < item.itens.length; i++) {
                        item.itens[i].id = null;
                     }
                  }
                  if (item.tiposDeEnsaio != null) {
                     for (let i = 0; i < item.tiposDeEnsaio.length; i++) {
                        item.tiposDeEnsaio[i].id = null;
                     }
                  }

                  updateState(this, (state) => {
                     state.incluindo = false;
                     state.alterando = false;
                     state.valorPadrao = item;
                  }).then(() => {
                     this.form.inserirClick();
                  });
               }}
            />
            <br />
         </div>
      );
   };

   aposSalvar = (subCadastro, inserindo, alterando) => {
      let ordem = this.state.ultimoItemCriado.numero;
      updateState(this, (state) => {
         state.itemSelecionado.itens.sort((i, j) => {
            let result = i.numero < j.numero ? -1 : i.numero > j.numero ? 1 : 0;
            if (j.numero === ordem && i.numero === ordem) {
               result = -1;
            }
            return result;
         });
         state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
      });
      if (inserindo) {
         subCadastro.novo();
      }
   };

   setValoresDefault = (item) => {
      let padrao = null;

      if (this.state.ultimoItemCriado && !this.state.configuracaoSelecionada) {
         padrao = this.state.ultimoItemCriado;
      } else if (item !== null) {
         if (item.tipoDeTeste === TIPO_DE_TESTE.ecgFrquencia) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteSuperior: 3,
               limiteInferior: 3,
               tipoDeSinal: 0,
               sinal: 204,
               amplitude: 1,
               unidadeLimites: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.ecgAmplitude) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               limiteSuperior: 10,
               limiteInferior: 10,
               tipoDeSinal: 0,
               sinal: 204,
               amplitude: 1,
               unidadeLimites: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.arritmiasPerformance) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               tipoDeSinal: 1,
               sinal: 31,
               amplitude: 1,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoFrequencia) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               respiracao_Frequencia: 20,
               respiracao_Taxa: 1,
               amplitude: 1,
               limiteSuperior: 10,
               limiteInferior: 10,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoAmplitude) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               respiracao_Frequencia: 20,
               respiracao_Taxa: 1,
               respiracao_Amplitude: 1,
               limiteSuperior: 10,
               limiteInferior: 10,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.respiracaoApneia) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               respiracao_TempoApneia: 12,
               respiracao_Taxa: 1,
               amplitude: 1,
               respiracao_Frequencia: 20,
            };
         }
      } else {
         padrao = {
            tipoDeTeste: 0,
         };
      }

      if (padrao) {         
         item.repeticoes = padrao.repeticoes ? padrao.repeticoes : 1;
         item.tipoDeTeste = padrao.tipoDeTeste;
         item.limiteSuperior = padrao.limiteSuperior;
         item.limiteInferior = padrao.limiteInferior;
         item.unidadeLimites = padrao.unidadeLimites;
         item.tipoDeSinal = padrao.tipoDeSinal;
         item.sinal = padrao.sinal;
         item.amplitude = padrao.amplitude;
         item.respiracao_Frequencia = padrao.respiracao_Frequencia;
         item.respiracao_Taxa = padrao.respiracao_Taxa;
         item.respiracao_Amplitude = padrao.respiracao_Amplitude;
         item.respiracao_TempoApneia = padrao.respiracao_TempoApneia;
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={
               <div>
                  <Link
                     to={'/sequencia'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',

                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.sequencias}</span>                     
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.cadastroDeSequenciaSIM.titulo}</span>
               </div>
            }
            url={'/sequenciasim'}
            fastUrl={'/sequenciasim/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1261, 1262, 1263, 1264]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            definirValoresPadrao={(item) => {
               return this.state.valorPadrao ? this.state.valorPadrao : item;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{}}
         />
      );
   }
}
