import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import IntegerInput from '../../components/IntegerInput';
import DecimalInput from '../../components/DecimalInput';
import { buildQueryString, updateState } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import SequenciaAVMController from '../../controllers/Sequencias/SequenciaAVMController';
import {
   getTiposDeTestesAVM,
   getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro1,
   getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro2,
   getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro3,
   getTiposDeParametrosManualDeVentiladoresAVM,
   getTiposDeParametrosDeManualDePressaoAVM,
   getTiposDeUnidadeDeMedidaAVM_manualPressao,
   getTiposDeUnidadeDeDesvioAVM_manualPressao,
   getTiposDeParametrosManualVolumeMLAVM,
   getTiposDeParametrosManualFIO2AVM,
   getTiposDeParametrosManualMBARAVM,
   getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro1,
   getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro2,
   getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro3,
   getTiposDeParametrosManualVolumePEPAVM,
   getTiposDeParametrosManualDeVentiladoresFIO2AVM,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeInsp_volumeExp,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_volumeInsp_volumeExp,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_volumeDeMinuto,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_fluxos,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_vazamento,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_taxaDeResp,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_tempoDeInsp_tempoDeExp,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_oxigenio,
   getTiposDeUnidadeDeMedidaAVM_manualVentilador_complacencia,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeDeMinuto,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_fluxos,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_vazamento,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_taxaDeResp,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoDeInsp_tempoDeExp,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoInspCiclo,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_oxigenio,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_complacencia,
   getTiposDeUnidadeDeDesvioAVM_manualVentilador_relacao_IE,
} from './../Descricoes';
import { showError } from '../../components/Messages';
import { Link } from 'react-router-dom';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';
import TipoDeEnsaioView from './TipoDeEnsaioView';
import Button from '../../components/Button';
import CheckListView from '../Empresa/CheckListView';
import Line from '../../components/Line';
import { TIPO_DE_TESTE_AVM as TIPO_DE_TESTE } from '../TiposDeTeste'; 

export default class SequenciaAVMView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new SequenciaAVMController(this);
   }

   setUltimoItemCriado = (item) => {
      updateState(this, (state) => {
         state.ultimoItemCriado = {};
         state.ultimoItemCriado.numero = item.numero;
         state.ultimoItemCriado.tipoDeTeste = item.tipoDeTeste;
         state.ultimoItemCriado.tipoDeParametro = item.tipoDeParametro;
         state.ultimoItemCriado.valorReferencia = item.valorReferencia;
         state.ultimoItemCriado.limiteSuperior = item.limiteSuperior;
         state.ultimoItemCriado.limiteInferior = item.limiteInferior;
         state.ultimoItemCriado.unidadeLimites = item.unidadeLimites;
         state.ultimoItemCriado.valorReferencia2 = item.valorReferencia2;
         state.ultimoItemCriado.limiteSuperior2 = item.limiteSuperior2;
         state.ultimoItemCriado.limiteInferior2 = item.limiteInferior2;
         state.ultimoItemCriado.unidadeLimites2 = item.unidadeLimites2;
         state.ultimoItemCriado.valorReferencia3 = item.valorReferencia3;
         state.ultimoItemCriado.limiteSuperior3 = item.limiteSuperior3;
         state.ultimoItemCriado.limiteInferior3 = item.limiteInferior3;
         state.ultimoItemCriado.unidadeLimites3 = item.unidadeLimites3;
         state.ultimoItemCriado.unidadeMedida = item.unidadeMedida;
         state.ultimoItemCriado.parametro1 = item.parametro1;
         state.ultimoItemCriado.parametro2 = item.parametro2;
         state.ultimoItemCriado.volume = item.volume;
         state.ultimoItemCriado.volume_Inspirado = item.volume_Inspirado;
         state.ultimoItemCriado.volume_Expirado = item.volume_Expirado;
         state.ultimoItemCriado.volume_Minuto = item.volume_Minuto;
         state.ultimoItemCriado.pressao_Alta = item.pressao_Alta;
         state.ultimoItemCriado.pressao_Diferencial = item.pressao_Diferencial;
         state.ultimoItemCriado.pressao_Canal = item.pressao_Canal;
         state.ultimoItemCriado.pressao_PIP = item.pressao_PIP;
         state.ultimoItemCriado.pressao_Media = item.pressao_Media;
         state.ultimoItemCriado.pressao_PEEP = item.pressao_PEEP;
         state.ultimoItemCriado.pressao_Pausa = item.pressao_Pausa;
         state.ultimoItemCriado.fluxo = item.fluxo;
         state.ultimoItemCriado.fluxo_PicoInspiratorio = item.fluxo_PicoInspiratorio;
         state.ultimoItemCriado.fluxo_PicoExpiratorio = item.fluxo_PicoExpiratorio;
         state.ultimoItemCriado.vazamento = item.vazamento;
         state.ultimoItemCriado.taxa_Respiracao = item.taxa_Respiracao;
         state.ultimoItemCriado.tempo_Inspiratorio = item.tempo_Inspiratorio;
         state.ultimoItemCriado.tempo_Expiratorio = item.tempo_Expiratorio;
         state.ultimoItemCriado.relacao_IE = item.Relacao_IE;
         state.ultimoItemCriado.tempo_InspiratorioCiclo = item.tempo_InspiratorioCiclo;
         state.ultimoItemCriado.oxigenio = item.oxigenio;
         state.ultimoItemCriado.complacencia = item.complacencia;
      });
   };

   getDescricaoDoTipoDeTesteAVM = (id) => {
      let result = getTiposDeTestesAVM(this.props.lang).filter((i) => i.id === id);
      return result.length > 0 ? result[0].descricao : null;
   };

   getDescricaoModosDeUnidadeDeMedida = (item) => {
      let result = '';
      const lang = this.props.lang;
      switch (item.tipoDeTeste) {
         case TIPO_DE_TESTE.manualDeVentilador:
            let unidadeDeMedidaManualVentilador = this.getUnidadeDeMedidaManualVentilador(item.tipoDeParametro).filter(
               (i) => i.id === item.unidadeMedida
            );
            result = unidadeDeMedidaManualVentilador.length > 0 ? unidadeDeMedidaManualVentilador[0].descricao : null;
            break;
         case TIPO_DE_TESTE.manualDePressao:
            let tiposDeUnidadeDeMedidaAVM_manualPressao = getTiposDeUnidadeDeMedidaAVM_manualPressao(lang).filter(
               (i) => i.id === item.unidadeMedida
            );
            result =
               tiposDeUnidadeDeMedidaAVM_manualPressao.length > 0
                  ? tiposDeUnidadeDeMedidaAVM_manualPressao[0].descricao
                  : null;
            break;
         case TIPO_DE_TESTE.normaDeVolume:
            result = [];
            break;
         case TIPO_DE_TESTE.normaDePressao:
            result = [];
            break;
         case TIPO_DE_TESTE.normaDeFiO2:
            result = [];
            break;
         default:
            break;
      }

      return result;
   };

   getDescricaoModosDeUnidadeDeDesvio = (item) => {
      let result = '';
      const lang = this.props.lang;
      switch (item.tipoDeTeste) {
         case TIPO_DE_TESTE.manualDeVentilador:
            let manualDeVentilador = this.getUnidadeDeDesvioManualVentilador(item.tipoDeParametro).filter(
               (i) => i.id === item.unidadeLimites
            );
            result = manualDeVentilador.length > 0 ? manualDeVentilador[0].descricao : null;
            break;
         case TIPO_DE_TESTE.manualDePressao:
            let manualDePressao = getTiposDeUnidadeDeDesvioAVM_manualPressao(lang).filter(
               (i) => i.id === item.unidadeLimites
            );
            result = manualDePressao.length > 0 ? manualDePressao[0].descricao : null;
            break;
         case TIPO_DE_TESTE.normaDeVolume:
            let normaVolume_parametro1 = getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro1(lang).filter(
               (i) => i.id === item.unidadeLimites
            );
            let normaVolume_parametro2 = getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro2(lang).filter(
               (i) => i.id === item.unidadeLimites2
            );
            let normaVolume_parametro3 = getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro3(lang).filter(
               (i) => i.id === item.unidadeLimites3
            );

            let nv1 = normaVolume_parametro1.length > 0 ? normaVolume_parametro1[0].descricao : null;
            let nv2 = normaVolume_parametro2.length > 0 ? normaVolume_parametro2[0].descricao : null;
            let nv3 = normaVolume_parametro3.length > 0 ? normaVolume_parametro3[0].descricao : null;

            result = `${nv1} / ${nv2} / ${nv3}`;

            break;
         case TIPO_DE_TESTE.normaDePressao:
            let normaPressao_parametro1 = getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro1(lang).filter(
               (i) => i.id === item.unidadeLimites
            );
            let normaPressao_parametro2 = getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro2(lang).filter(
               (i) => i.id === item.unidadeLimites2
            );
            let normaPressao_parametro3 = getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro3(lang).filter(
               (i) => i.id === item.unidadeLimites3
            );

            let np1 = normaPressao_parametro1.length > 0 ? normaPressao_parametro1[0].descricao : null;
            let np2 = normaPressao_parametro2.length > 0 ? normaPressao_parametro2[0].descricao : null;
            let np3 = normaPressao_parametro3.length > 0 ? normaPressao_parametro3[0].descricao : null;

            result = `${np1} / ${np2} / ${np3}`;
            break;
         case TIPO_DE_TESTE.normaDeFiO2:
            result = null;
            break;
         default:
            break;
      }

      return result;
   };

   getUnidadeDeMedidaManualVentilador = (tipoDeParametro) => {
      let lang = this.props.lang;
      let result = [];
      switch (tipoDeParametro) {
         case 5:
         case 6:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_volumeInsp_volumeExp(lang);
            break;
         case 7:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_volumeDeMinuto(lang);
            break;
         case 8:
         case 9:
         case 10:
         case 11:
         case 12:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa(lang);
            break;
         case 15:
         case 16:
         case 17:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_fluxos(lang);
            break;
         case 18:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_vazamento(lang);
            break;
         case 19:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_taxaDeResp(lang);
            break;
         case 20:
         case 21:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_tempoDeInsp_tempoDeExp(lang);
            break;
         case 22:
            result = []; // relacaoI_E
            break;
         case 23:
            result = []; // tempoInspCiclo
            break;
         case 24:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_oxigenio(lang);
            break;
         case 25:
            result = getTiposDeUnidadeDeMedidaAVM_manualVentilador_complacencia(lang);
            break;
         default:
            break;
      }

      return result;
   };

   getUnidadeDeDesvioManualVentilador = (tipoDeParametro) => {
      console.log(tipoDeParametro);
      let lang = this.props.lang;
      let result = [];
      switch (tipoDeParametro) {
         case 5:
         case 6:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeInsp_volumeExp(lang);
            break;
         case 7:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_volumeDeMinuto(lang);
            break;
         case 8:
         case 9:
         case 10:
         case 11:
         case 12:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_pressao_Canal_Pico_Media_PEEP_Pausa(lang);
            break;
         case 15:
         case 16:
         case 17:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_fluxos(lang);
            break;
         case 18:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_vazamento(lang);
            break;
         case 19:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_taxaDeResp(lang);
            break;
         case 20:
         case 21:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoDeInsp_tempoDeExp(lang);
            break;
         case 22:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_relacao_IE(lang);
            break;
         case 23:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_tempoInspCiclo(lang);
            break;
         case 24:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_oxigenio(lang);
            break;
         case 25:
            result = getTiposDeUnidadeDeDesvioAVM_manualVentilador_complacencia(lang);
            break;
         default:
            break;
      }

      return result;
   };

   renderizarFormulario = () => {
      let lang = this.props.lang;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{lang.sequencia.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.sequencia.situacao}</Label>
                     <Select
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.sequencia.ativa },
                           { id: 2, descricao: lang.sequencia.inativa },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={lang.sequencia.tiposDeEnsaio}
               modal={true}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.tiposDeEnsaio}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = {
                           tipoDeEnsaio: null,
                        };
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     if (!this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio) {
                        showError(lang.sequencia.mensagens.informeOTipoDeEnsaio);
                        reject();
                        return false;
                     }

                     updateState(this, (state) => {
                        if (!state.itemSelecionado.tiposDeEnsaio) {
                           state.itemSelecionado.tiposDeEnsaio = [];
                        }
                        state.itemSelecionado.tiposDeEnsaio.push({
                           tipoDeEnsaio: this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.tipoDeEnsaioSelecionado = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.tiposDeEnsaio.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: 'Cargo', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.tipoDeEnsaio.nome];
               }}
               formulario={() => {
                  return (
                     <Row>
                        <Col>
                           <Label>{lang.sequencia.tipoDeEnsaio}</Label>
                           <Select
                              name={'tipoDeEnsaio'}
                              modal={true}
                              defaultValue={this.state.tipoDeEnsaioSelecionado.tipoDeEnsaio}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.tipoDeEnsaioSelecionado.tipoDeEnsaio = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return (
                                    <TipoDeEnsaioView api={this.props.api} select={select} lang={this.props.lang} />
                                 );
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) =>
                                 '/tipodeensaio' + buildQueryString(2, null, 'id', { Searchable: text })
                              }
                           />
                        </Col>
                     </Row>
                  );
               }}
            />

            <br />
            {!this.state.itemSelecionado.sequenciaNeos && (
               <SubCadastro
                  titulo={lang.sequencia.checkList}
                  modal={true}
                  exibirTitulos={false}
                  itens={this.state.itemSelecionado.checkList}
                  lang={lang}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = {
                              checkList: null,
                           };
                        }).then(() => resolve());
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve, reject) => {
                        if (!this.state.checkListSelecionado.checkList) {
                           showError(lang.sequencia.mensagens.informeOCheckList);
                           reject();
                           return false;
                        }

                        updateState(this, (state) => {
                           if (!state.itemSelecionado.checkList) {
                              state.itemSelecionado.checkList = [];
                           }
                           state.itemSelecionado.checkList.push({
                              checkList: this.state.checkListSelecionado.checkList,
                           });
                        }).then(() => resolve());
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.checkListSelecionado = null;
                        }).then(() => resolve());
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.checkList.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [{ titulo: lang.sequencia.checkList, width: '100%', className: null }];
                  }}
                  linha={(item) => {
                     return [item.checkList.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <Label>{lang.sequencia.checkList}</Label>
                              <Select
                                 name={'checkList'}
                                 modal={true}
                                 defaultValue={this.state.checkListSelecionado.checkList}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.descricao}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.checkListSelecionado.checkList = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return (
                                       <CheckListView api={this.props.api} select={select} lang={this.props.lang} />
                                    );
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/checklist' + buildQueryString(2, null, 'descricao', { Searchable: text })
                                 }
                              />
                           </Col>
                        </Row>
                     );
                  }}
               />
            )}

            <br />

            <SubCadastro
               titulo={lang.sequencia.testes}
               exibirTitulos={true}
               itens={this.state.itemSelecionado.itens}
               lang={lang}
               gradeSempreVisivel={true}
               tituloDoBotaoCancelar={lang.sequencia.finalizarCadastroDeTeste}
               novo={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? () => {
                          return new Promise((resolve) => {
                             let maiorNumero = 0;
                             if (this.state.itemSelecionado.itens) {
                                this.state.itemSelecionado.itens.map((i, index) => {
                                   if (i.numero > maiorNumero) {
                                      maiorNumero = i.numero;
                                   }
                                   return true;
                                });
                             }
                             maiorNumero++;
                             updateState(this, (state) => {
                              state.configuracaoSelecionada = null;
                              let configuracaoSelecionada = { tipoDeTeste: 0 };
                              this.setValoresDefault(configuracaoSelecionada);
                              configuracaoSelecionada.numero = maiorNumero;
                              state.configuracaoSelecionada = configuracaoSelecionada;
                             }).then(() => resolve());
                          });
                       }
                     : null
               }
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === 0) {
                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.limiteInferior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }
                     if (this.state.configuracaoSelecionada.tipoDeTeste === 1) {
                        if (this.state.configuracaoSelecionada.valorReferencia === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOValorDeReferencia);
                           reject();
                           return false;
                        }

                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.limiteInferior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }
                     if (this.state.configuracaoSelecionada.tipoDeTeste === 2) {
                        if (this.state.configuracaoSelecionada.valorReferencia === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOValorDeReferencia);
                           reject();
                           return false;
                        }

                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                     }
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.itens) {
                           state.itemSelecionado.itens = [];
                        }
                        let item = {
                           numero: this.state.configuracaoSelecionada.numero,
                           repeticoes: 1,
                           tipoDeTeste: this.state.configuracaoSelecionada.tipoDeTeste,
                           tipoDeParametro: this.state.configuracaoSelecionada.tipoDeParametro,
                           valorReferencia: this.state.configuracaoSelecionada.valorReferencia,
                           limiteSuperior: this.state.configuracaoSelecionada.limiteSuperior,
                           limiteInferior: this.state.configuracaoSelecionada.limiteInferior,
                           unidadeLimites: this.state.configuracaoSelecionada.unidadeLimites,
                           valorReferencia2: this.state.configuracaoSelecionada.valorReferencia2,
                           limiteSuperior2: this.state.configuracaoSelecionada.limiteSuperior2,
                           limiteInferior2: this.state.configuracaoSelecionada.limiteInferior2,
                           unidadeLimites2: this.state.configuracaoSelecionada.unidadeLimites2,
                           valorReferencia3: this.state.configuracaoSelecionada.valorReferencia3,
                           limiteSuperior3: this.state.configuracaoSelecionada.limiteSuperior3,
                           limiteInferior3: this.state.configuracaoSelecionada.limiteInferior3,
                           unidadeLimites3: this.state.configuracaoSelecionada.unidadeLimites3,
                           unidadeMedida: this.state.configuracaoSelecionada.unidadeMedida,
                           parametro1: this.state.configuracaoSelecionada.parametro1,
                           parametro2: this.state.configuracaoSelecionada.parametro2,
                           volume: this.state.configuracaoSelecionada.volume,
                           volume_Inspirado: this.state.configuracaoSelecionada.volume_Inspirado,
                           volume_Expirado: this.state.configuracaoSelecionada.volume_Expirado,
                           volume_Minuto: this.state.configuracaoSelecionada.volume_Minuto,
                           pressao_Alta: this.state.configuracaoSelecionada.pressao_Alta,
                           pressao_Diferencial: this.state.configuracaoSelecionada.pressao_Diferencial,
                           pressao_Canal: this.state.configuracaoSelecionada.pressao_Canal,
                           pressao_PIP: this.state.configuracaoSelecionada.pressao_PIP,
                           pressao_Media: this.state.configuracaoSelecionada.pressao_Media,
                           pressao_PEEP: this.state.configuracaoSelecionada.pressao_PEEP,
                           pressao_Pausa: this.state.configuracaoSelecionada.pressao_Pausa,
                           fluxo: this.state.configuracaoSelecionada.fluxo,
                           fluxo_PicoInspiratorio: this.state.configuracaoSelecionada.fluxo_PicoInspiratorio,
                           fluxo_PicoExpiratorio: this.state.configuracaoSelecionada.fluxo_PicoExpiratorio,
                           vazamento: this.state.configuracaoSelecionada.vazamento,
                           taxa_Respiracao: this.state.configuracaoSelecionada.taxa_Respiracao,
                           tempo_Inspiratorio: this.state.configuracaoSelecionada.tempo_Inspiratorio,
                           tempo_Expiratorio: this.state.configuracaoSelecionada.tempo_Expiratorio,
                           relacao_IE: this.state.configuracaoSelecionada.Relacao_IE,
                           tempo_InspiratorioCiclo: this.state.configuracaoSelecionada.tempo_InspiratorioCiclo,
                           oxigenio: this.state.configuracaoSelecionada.oxigenio,
                           complacencia: this.state.configuracaoSelecionada.complacencia,
                        };

                        state.itemSelecionado.itens.push(item);
                        this.setUltimoItemCriado(item);
                     }).then(() => resolve());
                  });
               }}
               aposSalvar={this.aposSalvar}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(JSON.stringify(state.itemSelecionado.itens[index]));
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     // if (!this.state.configuracaoSelecionada.repeticoes) {
                     //    showError(lang.sequencia.informeONumeroDeRepeticoes);
                     //    reject();
                     //    return false;
                     // }

                     if (this.state.configuracaoSelecionada.tipoDeTeste === 0) {
                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }

                        if (this.state.configuracaoSelecionada.limiteInferior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }
                     if (this.state.configuracaoSelecionada.tipoDeTeste === 1) {
                        if (this.state.configuracaoSelecionada.valorReferencia === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOValorDeReferencia);
                           reject();
                           return false;
                        }

                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                        if (this.state.configuracaoSelecionada.limiteInferior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteInferior);
                           reject();
                           return false;
                        }
                     }
                     if (this.state.configuracaoSelecionada.tipoDeTeste === 2) {
                        if (this.state.configuracaoSelecionada.valorReferencia === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOValorDeReferencia);
                           reject();
                           return false;
                        }

                        if (this.state.configuracaoSelecionada.limiteSuperior === 0) {
                           showError(lang.cadastroDeSequenciaAVM.mensagens.informeOLimiteSuperior);
                           reject();
                           return false;
                        }
                     }

                     updateState(this, (state) => {
                        state.itemSelecionado.itens[index].numero = this.state.configuracaoSelecionada.numero;
                        state.itemSelecionado.itens[index].repeticoes = this.state.configuracaoSelecionada.repeticoes;
                        state.itemSelecionado.itens[index].tipoDeTeste = this.state.configuracaoSelecionada.tipoDeTeste;
                        state.itemSelecionado.itens[index].tipoDeParametro =
                           this.state.configuracaoSelecionada.tipoDeParametro;
                        state.itemSelecionado.itens[index].valorReferencia =
                           this.state.configuracaoSelecionada.valorReferencia;
                        state.itemSelecionado.itens[index].limiteSuperior =
                           this.state.configuracaoSelecionada.limiteSuperior;
                        state.itemSelecionado.itens[index].limiteInferior =
                           this.state.configuracaoSelecionada.limiteInferior;
                        state.itemSelecionado.itens[index].unidadeLimites =
                           this.state.configuracaoSelecionada.unidadeLimites;
                        state.itemSelecionado.itens[index].valorReferencia2 =
                           this.state.configuracaoSelecionada.valorReferencia2;
                        state.itemSelecionado.itens[index].limiteSuperior2 =
                           this.state.configuracaoSelecionada.limiteSuperior2;
                        state.itemSelecionado.itens[index].limiteInferior2 =
                           this.state.configuracaoSelecionada.limiteInferior2;
                        state.itemSelecionado.itens[index].unidadeLimites2 =
                           this.state.configuracaoSelecionada.unidadeLimites2;
                        state.itemSelecionado.itens[index].valorReferencia3 =
                           this.state.configuracaoSelecionada.valorReferencia3;
                        state.itemSelecionado.itens[index].limiteSuperior3 =
                           this.state.configuracaoSelecionada.limiteSuperior3;
                        state.itemSelecionado.itens[index].limiteInferior3 =
                           this.state.configuracaoSelecionada.limiteInferior3;
                        state.itemSelecionado.itens[index].unidadeLimites3 =
                           this.state.configuracaoSelecionada.unidadeLimites3;
                        state.itemSelecionado.itens[index].unidadeMedida =
                           this.state.configuracaoSelecionada.unidadeMedida;
                        state.itemSelecionado.itens[index].parametro1 = this.state.configuracaoSelecionada.parametro1;
                        state.itemSelecionado.itens[index].parametro2 = this.state.configuracaoSelecionada.parametro2;
                        state.itemSelecionado.itens[index].volume = this.state.configuracaoSelecionada.volume;
                        state.itemSelecionado.itens[index].volume_Inspirado =
                           this.state.configuracaoSelecionada.volume_Inspirado;
                        state.itemSelecionado.itens[index].volume_Expirado =
                           this.state.configuracaoSelecionada.volume_Expirado;
                        state.itemSelecionado.itens[index].volume_Minuto =
                           this.state.configuracaoSelecionada.volume_Minuto;
                        state.itemSelecionado.itens[index].pressao_Alta =
                           this.state.configuracaoSelecionada.pressao_Alta;
                        state.itemSelecionado.itens[index].pressao_Diferencial =
                           this.state.configuracaoSelecionada.pressao_Diferencial;
                        state.itemSelecionado.itens[index].pressao_Canal =
                           this.state.configuracaoSelecionada.pressao_Canal;
                        state.itemSelecionado.itens[index].pressao_PIP = this.state.configuracaoSelecionada.pressao_PIP;
                        state.itemSelecionado.itens[index].pressao_Media =
                           this.state.configuracaoSelecionada.pressao_Media;
                        state.itemSelecionado.itens[index].pressao_PEEP =
                           this.state.configuracaoSelecionada.pressao_PEEP;
                        state.itemSelecionado.itens[index].pressao_Pausa =
                           this.state.configuracaoSelecionada.pressao_Pausa;
                        state.itemSelecionado.itens[index].fluxo = this.state.configuracaoSelecionada.fluxo;
                        state.itemSelecionado.itens[index].fluxo_PicoInspiratorio =
                           this.state.configuracaoSelecionada.fluxo_PicoInspiratorio;
                        state.itemSelecionado.itens[index].fluxo_PicoExpiratorio =
                           this.state.configuracaoSelecionada.fluxo_PicoExpiratorio;
                        state.itemSelecionado.itens[index].vazamento = this.state.configuracaoSelecionada.vazamento;
                        state.itemSelecionado.itens[index].taxa_Respiracao =
                           this.state.configuracaoSelecionada.taxa_Respiracao;
                        state.itemSelecionado.itens[index].tempo_Inspiratorio =
                           this.state.configuracaoSelecionada.tempo_Inspiratorio;
                        state.itemSelecionado.itens[index].tempo_Expiratorio =
                           this.state.configuracaoSelecionada.tempo_Expiratorio;
                        state.itemSelecionado.itens[index].relacao_IE = this.state.configuracaoSelecionada.Relacao_IE;
                        state.itemSelecionado.itens[index].tempo_InspiratorioCiclo =
                           this.state.configuracaoSelecionada.tempo_InspiratorioCiclo;
                        state.itemSelecionado.itens[index].oxigenio = this.state.configuracaoSelecionada.oxigenio;
                        state.itemSelecionado.itens[index].complacencia =
                           this.state.configuracaoSelecionada.complacencia;
                        this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={
                  !this.state.itemSelecionado.sequenciaNeos ||
                  (this.state.itemSelecionado.sequenciaNeos && this.props.sessionManager.isUsuarioNeos())
                     ? (index) => {
                          updateState(this, (state) => {
                             state.itemSelecionado.itens.splice(index, 1);
                             state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
                          });
                       }
                     : null
               }
               colunas={() => {
                  return [
                     { titulo: 'Nº', width: '3%', className: null },
                     { titulo: 'Tipo de Teste', width: '22%', className: null },
                     { titulo: 'Valor de referência', width: '20%', className: null },
                     { titulo: 'Unid. medida', width: '15%', className: null },
                     { titulo: 'Desvio máximo superior', width: '10%', className: null },
                     { titulo: 'Desvio máximo inferior', width: '10%', className: null },
                     { titulo: 'Unid desvio', width: '20%', className: null },
                  ];
               }}
               linha={(item) => {
                  if (item.tipoDeTeste === TIPO_DE_TESTE.manualDeVentilador) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteAVM(item.tipoDeTeste),
                        item.valorReferencia,
                        this.getDescricaoModosDeUnidadeDeMedida(item),
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoModosDeUnidadeDeDesvio(item),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.manualDePressao) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteAVM(item.tipoDeTeste),
                        item.valorReferencia,
                        this.getDescricaoModosDeUnidadeDeMedida(item),
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoModosDeUnidadeDeDesvio(item),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.normaDeVolume) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteAVM(item.tipoDeTeste),
                        item.valorReferencia + '/' + item.valorReferencia2 + '/' + item.valorReferencia3,
                        '-',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoModosDeUnidadeDeDesvio(item),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.normaDePressao) {
                     return [
                        item.numero,
                        this.getDescricaoDoTipoDeTesteAVM(item.tipoDeTeste),
                        item.valorReferencia + '/' + item.valorReferencia2 + '/' + item.valorReferencia3,
                        '-',
                        item.limiteSuperior,
                        item.limiteInferior,
                        this.getDescricaoModosDeUnidadeDeDesvio(item),
                     ];
                  } else if (item.tipoDeTeste === TIPO_DE_TESTE.normaDeFiO2) {
                     return [
                        item.numero,
                        'Tempo Oxigênio (Norma)',
                        item.valorReferencia + '/' + item.parametro1 + '/' + item.parametro2,
                        's/s/%',
                        '-',
                        '-',
                        's',
                     ];
                  } else {
                     return [item.numero, 0, 0, 0, item.limiteSuperior, item.limiteInferior, 0];
                  }
               }}
               acoes={(subCadastro, index) => {
                  return (
                     <div style={{ display: 'table-cell', margin: 'auto', marginRight: 4, width: 25 }}>
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginBottom: -10 }}
                           cursor='pointer'
                           icon={faSortUp}
                           onClick={() => {
                              if (this.state.itemSelecionado.itens[index].numero > 1) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index - 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero - 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                        <IconButton
                           title={lang.formularioPadrao.alterar}
                           style={{ fontSize: 23, marginTop: -10 }}
                           cursor='pointer'
                           icon={faSortDown}
                           onClick={() => {
                              if (
                                 this.state.itemSelecionado.itens[index].numero <
                                 this.state.itemSelecionado.itens.length
                              ) {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.itens[index + 1].numero =
                                       state.itemSelecionado.itens[index].numero;
                                    state.itemSelecionado.itens[index].numero =
                                       state.itemSelecionado.itens[index].numero + 1;
                                 }).then(() => {
                                    this.setUltimoItemCriado(this.state.itemSelecionado.itens[index]);
                                    this.aposSalvar(subCadastro, false, true);
                                 });
                              }
                           }}
                        />
                     </div>
                  );
               }}
               formulario={() => {
                  return this.state.configuracaoSelecionada ? (
                     <div>
                        <Row>
                        <Col sm={2} md={2} lg={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.numero}</Label>
                                 <IntegerInput
                                    defaultValue={this.state.configuracaoSelecionada.numero}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.numero = e.floatValue;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           {/* <Col xs={2} md={2} xl={2}>
                              <FormGroup>
                                 <Label>{lang.sequencia.repeticoes}</Label>
                                 <IntegerInput
                                    // defaultValue={this.state.configuracaoSelecionada.repeticoes}
                                    defaultValue={1}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.repeticoes = e.floatValue;
                                       });
                                    }}
                                    readOnly={true}
                                 />
                              </FormGroup>
                           </Col> */}
                           <Col>
                              <FormGroup>
                                 <Label>{lang.cadastroDeSequenciaAVM.tipoDeTeste}</Label>
                                 <Select
                                    defaultValue={this.state.configuracaoSelecionada.tipoDeTeste}
                                    options={getTiposDeTestesAVM(lang)}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.descricao}
                                    allowEmpty={false}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i != null) {
                                             state.configuracaoSelecionada.tipoDeTeste = i.id;
                                          } else {
                                             state.configuracaoSelecionada.tipoDeTeste = 0;
                                          }
                                          let configuracaoSelecionada = state.configuracaoSelecionada;
                                          this.setValoresDefault(configuracaoSelecionada);
                                          state.configuracaoSelecionada = configuracaoSelecionada;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.manualDeVentilador && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeParametro}
                                          options={getTiposDeParametrosManualDeVentiladoresAVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoDeParametro = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoDeParametro = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDeMedida}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeMedida}
                                          options={this.getUnidadeDeMedidaManualVentilador(
                                             this.state.configuracaoSelecionada.tipoDeParametro
                                          )}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeMedida = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeMedida = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={this.getUnidadeDeDesvioManualVentilador(
                                             this.state.configuracaoSelecionada.tipoDeParametro
                                          )}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.manualDePressao && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeParametro}
                                          options={getTiposDeParametrosDeManualDePressaoAVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoDeParametro = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoDeParametro = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDeMedida}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeMedida}
                                          options={getTiposDeUnidadeDeMedidaAVM_manualPressao(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeMedida = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeMedida = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={getTiposDeUnidadeDeDesvioAVM_manualPressao(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.normaDeVolume && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.numeroDeCiclos}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.tempo_InspiratorioCiclo}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.tempo_InspiratorioCiclo = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Line marginBottom={6} />

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeParametro}
                                          options={getTiposDeParametrosManualVolumeMLAVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoDeParametro = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoDeParametro = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro1(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Line marginBottom={6} />

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro2}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.parametro1}
                                          options={getTiposDeParametrosManualVolumePEPAVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.parametro1 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.parametro1 = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia2 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior2 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior2 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites2}
                                          options={getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro2(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites2 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites2 = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Line marginBottom={6} />

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro3}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.parametro2}
                                          options={getTiposDeParametrosManualFIO2AVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.parametro2 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.parametro2 = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia3 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior3 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior3 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites3}
                                          options={getTiposDeUnidadeDeDesvioAVM_normaVolume_parametro3(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites3 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites3 = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.normaDePressao && (
                           <Fragment>
                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.numeroDeCiclos}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.parametro1}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.parametro1 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Line marginBottom={6} />

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeParametro}
                                          options={getTiposDeParametrosManualMBARAVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {}}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites}
                                          options={getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro1(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Line marginBottom={6} />

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro2}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.parametro2}
                                          options={getTiposDeParametrosManualVolumePEPAVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {}}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia2 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior2 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior2 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio2}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites2}
                                          options={getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro2(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites2 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites2 = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Line marginBottom={6} />

                              <Row>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro3}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.parametro2}
                                          options={getTiposDeParametrosManualFIO2AVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {}}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={4} md={4} xl={4}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.valorDeReferencia}</Label>
                                       <DecimalInput
                                          lang={lang}
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia3 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoSuperior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteSuperior3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteSuperior3 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.desvioMaximoInferior}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.limiteInferior3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.limiteInferior3 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.unidadeDesvio3}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.unidadeLimites3}
                                          options={getTiposDeUnidadeDeDesvioAVM_normaPressao_parametro3(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.unidadeLimites3 = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.unidadeLimites3 = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}

                        {this.state.configuracaoSelecionada.tipoDeTeste === TIPO_DE_TESTE.normaDeFiO2 && (
                           <Fragment>
                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tipoDeParametro}</Label>
                                       <Select
                                          defaultValue={this.state.configuracaoSelecionada.tipoDeParametro}
                                          options={getTiposDeParametrosManualDeVentiladoresFIO2AVM(lang)}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                if (i != null) {
                                                   state.configuracaoSelecionada.tipoDeParametro = i.id;
                                                } else {
                                                   state.configuracaoSelecionada.tipoDeParametro = 0;
                                                }
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tempoEsperado}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.valorReferencia}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.valorReferencia = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.tempoLimite}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.parametro1}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.parametro1 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col xs={6} md={6} xl={6}>
                                    <FormGroup>
                                       <Label>{lang.cadastroDeSequenciaAVM.oxigenio}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.configuracaoSelecionada.parametro2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.configuracaoSelecionada.parametro2 = e.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                     </div>
                  ) : (
                     <div />
                  );
               }}
            />
            <br />
         </React.Fragment>
      );
   };

   renderizarAcoesDoFormulario = () => {
      return (
         <div>
            <Button
               text={this.props.lang.sequencia.duplicarSequencia}
               style={{ width: 200 }}
               onClick={() => {
                  let item = JSON.parse(JSON.stringify(this.state.itemSelecionado));
                  item.id = null;
                  if (item.itens != null) {
                     for (let i = 0; i < item.itens.length; i++) {
                        item.itens[i].id = null;
                     }
                  }
                  if (item.tiposDeEnsaio != null) {
                     for (let i = 0; i < item.tiposDeEnsaio.length; i++) {
                        item.tiposDeEnsaio[i].id = null;
                     }
                  }

                  updateState(this, (state) => {
                     state.incluindo = false;
                     state.alterando = false;
                     state.valorPadrao = item;
                  }).then(() => {
                     this.form.inserirClick();
                  });
               }}
            />
            <br />
         </div>
      );
   };

   aposSalvar = (subCadastro, inserindo, alterando) => {
      let ordem = this.state.ultimoItemCriado.numero;
      updateState(this, (state) => {
         state.itemSelecionado.itens.sort((i, j) => {
            let result = i.numero < j.numero ? -1 : i.numero > j.numero ? 1 : 0;
            if (j.numero === ordem && i.numero === ordem) {
               result = -1;
            }
            return result;
         });
         state.itemSelecionado.itens.map((i, index) => (i.numero = index + 1));
      });
      if (inserindo) {
         subCadastro.novo();
      }
   };

   setValoresDefault = (item) => {
      let padrao = null;

      if (this.state.ultimoItemCriado && !this.state.configuracaoSelecionada) {
         padrao = this.state.ultimoItemCriado;
      } else if (item !== null) {
         if (item.tipoDeTeste === TIPO_DE_TESTE.manualDeVentilador) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               repeticoes: 1,
               limiteSuperior: 10,
               limiteInferior: 10,
               unidadeLimites: 0,
               tipoDeParametro: 5,
               valorReferencia: 500,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.manualDePressao) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               repeticoes: 1,
               limiteSuperior: 10,
               limiteInferior: 10,
               unidadeLimites: 0,
               tipoDeParametro: 0,
               valorReferencia: 5,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.normaDeVolume) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               repeticoes: 1,
               tempo_InspiratorioCiclo: 30,
               limiteSuperior: 10,
               limiteSuperior2: 10,
               limiteSuperior3: 10,
               limiteInferior: 10,
               limiteInferior2: 10,
               limiteInferior3: 10,
               unidadeLimites: 0,
               valorReferencia: 500,
               valorReferencia2: 5,
               valorReferencia3: 30,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.normaDePressao) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               repeticoes: 1,
               parametro1: 30,
               limiteSuperior: 10,
               limiteSuperior2: 10,
               limiteSuperior3: 10,
               limiteInferior: 10,
               limiteInferior2: 10,
               limiteInferior3: 10,
               unidadeLimites: 0,
               valorReferencia: 10,
               valorReferencia2: 5,
               valorReferencia3: 30,
            };
         } else if (item.tipoDeTeste === TIPO_DE_TESTE.normaDeFiO2) {
            padrao = {
               tipoDeTeste: item.tipoDeTeste,
               repeticoes: 1,
               limiteSuperior: 10,
               limiteInferior: 10,
               unidadeLimites: 0,
               valorReferencia: 10,
               parametro1: 20,
               parametro2: 90,
            };
         }
      } else {
         padrao = {
            tipoDeTeste: 0,
         };
      }

      if (padrao) {         
         item.repeticoes = padrao.repeticoes;
         item.tipoDeTeste = padrao.tipoDeTeste;
         item.tipoDeParametro = padrao.tipoDeParametro;
         item.valorReferencia = padrao.valorReferencia;
         item.limiteSuperior = padrao.limiteSuperior;
         item.limiteInferior = padrao.limiteInferior;
         item.unidadeLimites = padrao.unidadeLimites;
         item.valorReferencia2 = padrao.valorReferencia2;
         item.limiteSuperior2 = padrao.limiteSuperior2;
         item.limiteInferior2 = padrao.limiteInferior2;
         item.unidadeLimites2 = padrao.unidadeLimites2;
         item.valorReferencia3 = padrao.valorReferencia3;
         item.limiteSuperior3 = padrao.limiteSuperior3;
         item.limiteInferior3 = padrao.limiteInferior3;
         item.unidadeLimites3 = padrao.unidadeLimites3;
         item.unidadeMedida = padrao.unidadeMedida;
         item.parametro1 = padrao.parametro1;
         item.parametro2 = padrao.parametro2;
         item.volume = padrao.volume;
         item.volume_Inspirado = padrao.volume_Inspirado;
         item.volume_Expirado = padrao.volume_Expirado;
         item.volume_Minuto = padrao.volume_Minuto;
         item.pressao_Alta = padrao.pressao_Alta;
         item.pressao_Diferencial = padrao.pressao_Diferencial;
         item.pressao_Canal = padrao.pressao_Canal;
         item.pressao_PIP = padrao.pressao_PIP;
         item.pressao_Media = padrao.pressao_Media;
         item.pressao_PEEP = padrao.pressao_PEEP;
         item.pressao_Pausa = padrao.pressao_Pausa;
         item.fluxo = padrao.fluxo;
         item.fluxo_PicoInspiratorio = padrao.fluxo_PicoInspiratorio;
         item.fluxo_PicoExpiratorio = padrao.fluxo_PicoExpiratorio;
         item.vazamento = padrao.vazamento;
         item.taxa_Respiracao = padrao.taxa_Respiracao;
         item.tempo_Inspiratorio = padrao.tempo_Inspiratorio;
         item.tempo_Expiratorio = padrao.tempo_Expiratorio;
         item.relacao_IE = padrao.Relacao_IE;
         item.tempo_InspiratorioCiclo = padrao.tempo_InspiratorioCiclo;
         item.oxigenio = padrao.oxigenio;
         item.complacencia = padrao.complacencia;
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={
               <div>
                  <Link
                     to={'/sequencia'}
                     replace={true}
                     style={{
                        textDecoration: 'none',
                        padding: '5px 5px 5px 0px',

                        cursor: 'pointer',
                     }}
                  >
                     <span style={{ opacity: 0.7 }}>{this.props.lang.sequencias}</span>
                  </Link>
                  <span>{' - '}</span>
                  <span>{this.props.lang.cadastroDeSequenciaAVM.titulo}</span>
               </div>
            }
            url={'/sequenciaavm'}
            fastUrl={'/sequenciaavm/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1221, 1222, 1223, 122]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            renderizarAcoesDoFormulario={this.renderizarAcoesDoFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            ref={(c) => (this.form = c)}
            definirValoresPadrao={(item) => {
               return this.state.valorPadrao ? this.state.valorPadrao : item;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{              
            }}
         />
      );
   }
}
