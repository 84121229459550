import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class CheckListController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'CheckListController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeCheckList.descricao, width: '100%', orderby: 'Descricao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.descricao];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.descricao) {
            showError(this.lang.telaDeCadastroDeCheckList.mensagens.informeADescricao);
            reject();
            return;
         }

         var input = {
            descricao: item.descricao,
            itens: item.itens,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }

         resolve(input);
      });
   };
}
