import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';
import { dateToString, updateState } from '../../utils/Functions';

export default class OrdemDeServicoController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'OrdemDeServicoController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.data,
            width: '10%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.usuarioResponsavel,
            width: '15%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.usuarioExecutor,
            width: '15%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.cliente,
            width: '20%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.equipamentoSobTeste,
            width: '30%',
            orderby: null,
         },
         {
            titulo: this.lang.telaDeCadastroDeOrdemDeServico.situacao,
            width: '10%',
            orderby: null,
         },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         dateToString(item.dataDeAgendamento, this.lang),
         item.nomeDoUsuarioResponsavel,
         item.nomeDoUsuarioExecutor,
         item.nomeDoCliente,
         item.descricaoDoEquipamentoSobTeste,
         item.situacao.descricao,
      ];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.usuarioExecutor) {
            showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeOUsuarioExecutor);
            reject();
            return;
         }

         if (!item.equipamentoSobTeste) {
            showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeOEquipamentoSobTeste);
            reject();
            return;
         }

         if (item.testeAQT) {
            if (!item.localDoEnsaio) {
               showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeOLocalDoEnsaio);
               reject();
               return;
            }
            if (!item.temperatura) {
               showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeATemperatura);
               reject();
               return;
            }
            if (!item.umidade) {
               showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeAUmidade);
               reject();
               return;
            }
         }

         var input = {
            cliente: item.cliente ? { id: item.cliente.id } : null,
            nomeDoCliente: item.nomeDoCliente,
            cpfCnpjDoCliente: item.cpfCnpjDoCliente,
            emailDoCliente: item.emailDoCliente,
            telefoneDoCliente: item.telefoneDoCliente,
            enderecoDoCliente: item.enderecoDoCliente,
            usuarioExecutor: item.usuarioExecutor ? { id: item.usuarioExecutor.id } : null,
            usuarioResponsavel: item.usuarioResponsavel ? { id: item.usuarioResponsavel.id } : null,
            equipamentoSobTeste: item.equipamentoSobTeste ? { id: item.equipamentoSobTeste.id } : null,
            dataDeAgendamento: item.dataDeAgendamento ? item.dataDeAgendamento : new Date(),
            situacao: item.situacao ? item.situacao.id : 0,
            itens: item.itens,
            observacao: item.observacao,
            localDoEnsaio: item.localDoEnsaio,
            temperatura: item.temperatura,
            umidade: item.umidade,
            numeroDeMesesDeValidadeDoCertificado: item.numeroDeMesesDeValidadeDoCertificado,
            testeAQT: item.testeAQT,
            testeTIN: item.testeTIN
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   inserirItem = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemDaOsSelecionado;

         if (
            item.testesAQT &&
            item.testesAQT.length > 0 &&
            state.itemSelecionado.itens &&
            state.itemSelecionado.itens.length > 0
         ) {
            showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.somenteUmTesteAqtPorVez);
            reject();
            return;
         }

         if (
            item.testeTIN &&
            item.testeTIN.length > 0 &&
            state.itemSelecionado.itens &&
            state.itemSelecionado.itens.length > 0
         ) {
            showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.somenteUmTesteTinPorVez);
            reject();
            return;
         }

         if (!item.tipoDeEquipamento) {
            showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeOTipoDeAnalisador);
            reject();
            return;
         }

         if (item.tipoDeEquipamento.tipoDeSequencia !== 11 && item.tipoDeEquipamento.tipoDeSequencia !== 6) {
            if (!item.sequencia) {
               showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeASequenciaDeTeste);
               reject();
               return;
            }
         }

         if (item.tipoDeEquipamento.tipoDeSequencia === 11) {
            if (!item.testesAQT || (item.testesAQT && item.testesAQT.length === 0)) {
               showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeOTesteAQT);
               reject();
               return;
            }
         }

         if (item.tipoDeEquipamento.tipoDeSequencia === 6) {
            if (!item.testeTIN || (item.testeTIN && item.testeTIN.length === 0)) {
               showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeOTesteTIN);
               reject();
               return;
            }
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.itens) {
               state.itemSelecionado.itens = [];
            }
            state.itemSelecionado.itens.push({
               tipoDeEquipamento: item.tipoDeEquipamento,
               sequencia: item.sequencia,
               testesAQT: item.testesAQT,
               testeAqtVazamento: item.testeAqtVazamento,
               testeAqtCiclosAjustaveis: item.testeAqtCiclosAjustaveis,
               testeAqtDeteccaoDeEstabilidade: item.testeAqtDeteccaoDeEstabilidade,
               testeAqtDistribuicaoDeTemperatura: item.testeAqtDistribuicaoDeTemperatura,
               testeAqtManual: item.testeAqtManual,
               testeAqtPenetracaoDeTemperatura: item.testeAqtPenetracaoDeTemperatura,
               testeAqtPressaoDinamica: item.testeAqtPressaoDinamica,
               testeAqtQuedaDeEnergia: item.testeAqtQuedaDeEnergia,
               testeAqtAberturaDePorta: item.testeAqtAberturaDePorta,

               testeTIN: item.testeTIN

            });
         }).then(() => resolve());
      });
   };

   alterarItem = (index) => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemDaOsSelecionado;

         if (!item.tipoDeEquipamento) {
            showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeOTipoDeAnalisador);
            reject();
            return;
         }

         if (item.tipoDeEquipamento.tipoDeSequencia !== 11) {
            if (!item.sequencia) {
               showError(this.lang.telaDeCadastroDeOrdemDeServico.mensagens.informeASequenciaDeTeste);
               reject();
               return;
            }
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.itens[index].tipoDeEquipamento = item.tipoDeEquipamento;
            state.itemSelecionado.itens[index].sequencia = item.sequencia;
            state.itemSelecionado.itens[index].testesAQT = item.testesAQT;
            state.itemSelecionado.itens[index].testeTIN = item.testeTIN;
         }).then(() => resolve());
      });
   };
}
