import { Row, Col, FormGroup, Tab, Tabs } from 'react-bootstrap';
import React, { Fragment } from 'react';
import ListaPadrao from '../ListaPadrao';
import Select from '../../components/Select';
import DateInput from '../../components/DateInput';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import { updateState, buildQueryString, dateToString, formatDate } from '../../utils/Functions';
import SubCadastro from '../../components/SubCadastro';
import ClienteView from './ClienteView';
import UsuarioDeEmpresaView from './UsuarioDeEmpresaView';
import AnalisadorSimuladorView from './AnalisadorSimuladorView';
import EquipamentoDeClienteView from '../Cliente/EquipamentoDeClienteView';
import Button from '../../components/Button';
import { showError, showInfo } from '../../components/Messages';
import EquipamentoDeEmpresaView from '../Empresa/EquipamentoDeEmpresaView';
import { LayoutParams } from '../../config/LayoutParams';
import styled from 'styled-components';
import IntegerInput from '../../components/IntegerInput';

let LANG = {};

export default class PainelDeControleView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         abaSelecionada: 'acoesDaOrdemDeServico',
         filtro: {
            dataDeAgendamentoInicial: new Date(),
            dataDeAgendamentoFinal: new Date(),
         },
         mostrarFiltros: true,
         ordemSelecionada: null,
      };
      LANG = props.lang;
   }

   render() {
      const telaDePainelDeControle = LANG.telaDePainelDeControle;
      return (
         <Fragment>
            <div
               style={{
                  backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                  color: LayoutParams.colors.corSecundaria,
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderLeft: LayoutParams.colors.corSecundaria,
               }}
            >
               <div style={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, height: 45, display: 'flex' }}>
                  <div
                     style={{
                        display: 'table-cell',
                        width: '0',
                        overflowX: 'visible',
                        fontSize: 30,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                     }}
                  >
                     <span>{telaDePainelDeControle.ordensDeServico}</span>
                  </div>
               </div>
            </div>

            {this.consultaDeOrdensDeServico()}
         </Fragment>
      );
   }

   consultaDeOrdensDeServico = () => {
      const tela = LANG.telaDePainelDeControle;

      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  height: this.state.ordemSelecionada ? '60%' : '95%',
                  maxHeight: this.state.ordemSelecionada ? '60%' : '95%',
                  overflowY: 'hidden',
               }}
            >
               <ListaPadrao
                  esconderTitulo={true}
                  esconderBotaoFechar={true}
                  iniciarVazio={true}
                  url={'/ordemdeservico/consultar'}
                  api={this.props.api}
                  lang={this.props.lang}
                  getTitulosDaTabela={() => {
                     return [
                        { titulo: LANG.codigo, orderby: 'Id', className: 'codigo' },
                        {
                           titulo: LANG.telaDeCadastroDeOrdemDeServico.data,
                           width: '10%',
                           orderby: null,
                        },
                        {
                           titulo: LANG.telaDeCadastroDeOrdemDeServico.usuarioResponsavel,
                           width: '15%',
                           orderby: null,
                        },
                        {
                           titulo: LANG.telaDeCadastroDeOrdemDeServico.usuarioExecutor,
                           width: '15%',
                           orderby: null,
                        },
                        {
                           titulo: LANG.telaDeCadastroDeOrdemDeServico.cliente,
                           width: '20%',
                           orderby: null,
                        },
                        {
                           titulo: LANG.telaDeCadastroDeOrdemDeServico.equipamentoSobTeste,
                           width: '30%',
                           orderby: null,
                        },
                        {
                           titulo: LANG.telaDeCadastroDeOrdemDeServico.situacao,
                           width: '10%',
                           orderby: null,
                        },
                     ];
                  }}
                  getDadosDaTabela={(item) => {
                     return [
                        item.id,
                        dateToString(item.dataDeAgendamento, LANG),
                        item.usuarioResponsavel ? item.usuarioResponsavel.nome : null,
                        item.usuarioExecutor ? item.usuarioExecutor.nome : null,
                        item.nomeDoCliente,
                        item.equipamentoSobTeste
                           ? item.equipamentoSobTeste.tipoDeEquipamento +
                             ' / ' +
                             item.equipamentoSobTeste.numeroDeSerie +
                             (item.equipamentoSobTeste.numeroDePatrimonio
                                ? ' / ' + item.equipamentoSobTeste.numeroDePatrimonio
                                : '')
                           : null,

                        item.situacao.descricao,
                     ];
                  }}
                  getFormState={() => this.state}
                  estruturaPadrao={true}
                  setFormState={(state, callback) => {
                     return new Promise((resolve) => {
                        this.setState(state, () => {
                           resolve();
                           if (callback) {
                              callback();
                           }
                        });
                     });
                  }}
                  onRowClick={(item, index) => {
                     updateState(this, (state) => {
                        state.ordemSelecionada = item && item === state.ordemSelecionada ? null : item;
                        state.highlightIndex = state.ordemSelecionada ? index : null;
                     });
                  }}
                  highlightIndex={this.state.highlightIndex}
                  getFiltro={() => {
                     return new Promise((resolve, reject) => {
                        try {
                           let input = {
                              id: this.state.filtro.numeroDaOrdemDeServico,
                              dataDeAgendamentoInicial: formatDate(this.state.filtro.dataDeAgendamentoInicial),
                              dataDeAgendamentoFinal: formatDate(this.state.filtro.dataDeAgendamentoFinal),
                              situacao: this.state.filtro.situacao ? this.state.filtro.situacao.id : null,
                              responsavel: this.state.filtro.responsavel ? this.state.filtro.responsavel.id : null,
                              executante: this.state.filtro.executante ? this.state.filtro.executante.id : null,
                              cliente: this.state.filtro.cliente ? this.state.filtro.cliente.id : null,
                              equipamento: this.state.filtro.equipamento ? this.state.filtro.equipamento.id : null,
                              certificado: this.state.filtro.certificado,
                              analisador: this.state.filtro.analisador ? this.state.filtro.analisador.id : null,
                           };
                           resolve(input);
                        } catch (e) {
                           reject(e);
                        }
                     });
                  }}
                  renderizarFiltros={() => {
                     return (
                        <div style={{ maxWidth: 900 }}>
                           <div>
                              <Row>
                                 <Col style={{ maxWidth: 100, paddingRight: 0 }}>
                                    <FormGroup>
                                       <Label>{tela.numeroDaOrdemDeServico}</Label>
                                       <IntegerInput
                                          defaultValue={this.state.filtro.numeroDaOrdemDeServico}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.filtro.numeroDaOrdemDeServico = e.floatValue;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col style={{ maxWidth: 400, paddingRight: 0 }}>
                                    <Label>{tela.dataDeAgendamento}</Label>
                                    <div style={{ display: 'flex' }}>
                                       <div style={{ display: 'table-cell', width: 150 }}>
                                          <DateInput
                                             defaultValue={formatDate(this.state.filtro.dataDeAgendamentoInicial)}
                                             onChange={(value) => {
                                                updateState(this, (state) => {
                                                   state.filtro.dataDeAgendamentoInicial = value;
                                                });
                                             }}
                                          />
                                       </div>
                                       <div
                                          style={{
                                             display: 'table-cell',
                                             width: 20,
                                             marginTop: 10,
                                             marginLeft: 5,
                                             marginRight: 5,
                                          }}
                                       >
                                          <span>{tela.dataDeAgendamentoAte}</span>
                                       </div>
                                       <div style={{ display: 'table-cell', width: 150 }}>
                                          <DateInput
                                             defaultValue={formatDate(this.state.filtro.dataDeAgendamentoFinal)}
                                             onChange={(value) => {
                                                updateState(this, (state) => {
                                                   state.filtro.dataDeAgendamentoFinal = value;
                                                });
                                             }}
                                          />
                                       </div>
                                    </div>
                                 </Col>

                                 <Col style={{ maxWidth: 200, paddingRight: 0 }}>
                                    <FormGroup>
                                       <Label>{tela.situacao}</Label>
                                       <Select
                                          defaultValue={this.state.filtro.situacao}
                                          options={[
                                             {
                                                id: 1,
                                                descricao:
                                                   LANG.telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico
                                                      .cadastrada,
                                             },
                                             {
                                                id: 2,
                                                descricao:
                                                   LANG.telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico
                                                      .executada,
                                             },
                                             {
                                                id: 3,
                                                descricao:
                                                   LANG.telaDeCadastroDeOrdemDeServico.situacaoDeOrdemDeServico
                                                      .liberada,
                                             },
                                          ]}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                state.filtro.situacao = i;
                                             });
                                          }}
                                          nullText={''}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col style={{ maxWidth: 250, paddingRight: 0 }}>
                                    <FormGroup>
                                       <Label>{tela.usuarioResponsavel}</Label>
                                       <Select
                                          name={'usuarioresponsavel'}
                                          defaultValue={this.state.filtro.usuarioResponsavel}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.nome}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                state.filtro.usuarioResponsavel = i;
                                             });
                                          }}
                                          formularioPadrao={(select) => {
                                             return (
                                                <UsuarioDeEmpresaView
                                                   api={this.props.api}
                                                   select={select}
                                                   lang={this.props.lang}
                                                />
                                             );
                                          }}
                                          noDropDown={true}
                                          readOnlyColor='#ffff'
                                          getFilterUrl={(text) =>
                                             '/usuariodeempresa' + buildQueryString(2, null, 'id', { Searchable: text })
                                          }
                                       />
                                    </FormGroup>
                                 </Col>
                                 <Col style={{ maxWidth: 250, paddingRight: 0 }}>
                                    <FormGroup>
                                       <Label>{tela.usuarioExecutor}</Label>
                                       <Select
                                          name={'usuarioexecutor'}
                                          defaultValue={this.state.filtro.usuarioExecutor}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.nome}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                state.filtro.usuarioExecutor = i;
                                             });
                                          }}
                                          formularioPadrao={(select) => {
                                             return (
                                                <UsuarioDeEmpresaView
                                                   api={this.props.api}
                                                   select={select}
                                                   lang={this.props.lang}
                                                />
                                             );
                                          }}
                                          noDropDown={true}
                                          readOnlyColor='#ffff'
                                          getFilterUrl={(text) =>
                                             '/usuariodeempresa' + buildQueryString(2, null, 'id', { Searchable: text })
                                          }
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col style={{ maxWidth: 300, paddingRight: 0 }}>
                                    <FormGroup>
                                       <Label>{tela.cliente}</Label>
                                       <Select
                                          name={'cliente'}
                                          defaultValue={this.state.filtro.cliente}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.apelidoOuNomeFantasia}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                state.filtro.cliente = i;
                                             });
                                          }}
                                          formularioPadrao={(select) => {
                                             return (
                                                <ClienteView
                                                   api={this.props.api}
                                                   select={select}
                                                   lang={this.props.lang}
                                                />
                                             );
                                          }}
                                          noDropDown={true}
                                          readOnlyColor='#ffff'
                                          getFilterUrl={(text) =>
                                             '/cliente/fast' + buildQueryString(2, null, 'id', { Searchable: text })
                                          }
                                       />
                                    </FormGroup>
                                 </Col>

                                 {this.state.filtro.cliente && this.state.filtro.cliente.id > 0 && (
                                    <Col style={{ maxWidth: 300, paddingRight: 0 }}>
                                       <FormGroup>
                                          <Label>{tela.equipamentoSobTeste}</Label>
                                          <Select
                                             name={'equipamentodecliente'}
                                             defaultValue={this.state.filtro.equipamentoSobTeste}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) =>
                                                i.tipoDeEquipamento +
                                                ' / ' +
                                                i.numeroDeSerie +
                                                ' / ' +
                                                i.numeroDePatrimonio
                                             }
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   state.filtro.equipamentoSobTeste = i;
                                                });
                                             }}
                                             formularioPadrao={(select) => {
                                                return (
                                                   <EquipamentoDeClienteView
                                                      api={this.props.api}
                                                      select={select}
                                                      cliente={this.state.filtro.cliente}
                                                      lang={this.props.lang}
                                                      filtroExtra={() => {
                                                         return {
                                                            idDoCliente: this.state.filtro.cliente
                                                               ? this.state.filtro.cliente.id
                                                               : null,
                                                         };
                                                      }}
                                                   />
                                                );
                                             }}
                                             noDropDown={true}
                                             readOnlyColor='#ffff'
                                             getFilterUrl={(text) =>
                                                '/equipamentodecliente' +
                                                buildQueryString(2, null, 'id', {
                                                   Searchable: text,
                                                   idDoCliente: this.state.filtro.cliente
                                                      ? this.state.filtro.cliente.id
                                                      : 2,
                                                })
                                             }
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}

                                 {!this.state.filtro.cliente && (
                                    <Col style={{ maxWidth: 300, paddingRight: 0 }}>
                                       <FormGroup>
                                          <Label>{tela.equipamentoSobTeste}</Label>
                                          <Select
                                             name={'equipamentodeempresa'}
                                             defaultValue={this.state.filtro.equipamentoSobTeste}
                                             getKeyValue={(i) => i.id}
                                             getDescription={(i) => i.tipoDeEquipamento + ' / ' + i.numeroDeSerie}
                                             onSelect={(i) => {
                                                updateState(this, (state) => {
                                                   state.filtro.equipamentoSobTeste = i;
                                                });
                                             }}
                                             formularioPadrao={(select) => {
                                                return (
                                                   <EquipamentoDeEmpresaView
                                                      api={this.props.api}
                                                      select={select}
                                                      lang={this.props.lang}
                                                   />
                                                );
                                             }}
                                             noDropDown={true}
                                             readOnlyColor='#ffff'
                                             getFilterUrl={(text) =>
                                                '/equipamentodeempresa' +
                                                buildQueryString(2, null, 'id', { Searchable: text })
                                             }
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}

                                 <Col style={{ maxWidth: 150, paddingRight: 0 }}>
                                    <FormGroup>
                                       <Label>{tela.certificado}</Label>
                                       <TextInput
                                          defaultValue={this.state.filtro.certificado}
                                          onChange={(e) => {
                                             updateState(this, (state) => (state.filtro.certificado = e.target.value));
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>

                                 <Col style={{ maxWidth: 300, paddingRight: 0 }}>
                                    <FormGroup>
                                       <Label>{tela.analisador}</Label>
                                       <Select
                                          name={'cliente'}
                                          defaultValue={this.state.filtro.analisador}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.numeroDeSerie}
                                          onSelect={(i) => {
                                             updateState(this, (state) => {
                                                state.filtro.analisador = i;
                                             });
                                          }}
                                          formularioPadrao={(select) => {
                                             return (
                                                <AnalisadorSimuladorView
                                                   api={this.props.api}
                                                   select={select}
                                                   lang={this.props.lang}
                                                />
                                             );
                                          }}
                                          noDropDown={true}
                                          readOnlyColor='#ffff'
                                          getFilterUrl={(text) =>
                                             '/analisador' + buildQueryString(2, null, 'id', { Searchable: text })
                                          }
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </div>
                        </div>
                     );
                  }}
               />
            </div>
            {this.state.ordemSelecionada && (
               <div
                  style={{
                     display: 'flex',
                     height: '30%',
                     maxHeight: '30%',
                     overflowY: 'hidden',
                     flexDirection: 'column',
                  }}
               >
                  <StyledNav>
                     <Tabs variant='tabs' defaultActiveKey={this.state.abaSelecionada} style={{ color: '#444' }}>
                        <Tab
                           onClick={() => this.setState({ abaSelecionada: 'acoesDaOrdemDeServico' })}
                           eventKey='acoesDaOrdemDeServico'
                           title={tela.acoesDaOrdemDeServico}
                        >
                           {this.acoesDaOrdemDeServico()}
                        </Tab>

                        <Tab
                           onClick={() => this.setState({ abaSelecionada: 'resultadosDaOrdemDeServico' })}
                           eventKey='resultadosDaOrdemDeServico'
                           title={tela.resultadosDaOrdemDeServico}
                        >
                           {this.resultadosDaOrdemDeServico()}
                        </Tab>

                        <Tab
                           onClick={() => this.setState({ abaSelecionada: 'certificadosDaOrdemDeServico' })}
                           eventKey='certificadosDaOrdemDeServico'
                           title={tela.certificado}
                        >
                           {this.certificadosDaOrdemDeServico()}
                        </Tab>
                     </Tabs>
                  </StyledNav>
               </div>
            )}
         </Fragment>
      );
   };

   pesquisarOrdensDeServico = () => {
      return new Promise((resolve, reject) => {
         resolve();
      });
   };

   acoesDaOrdemDeServico = () => {
      return (
         <div style={{ padding: 8 }}>
            <Button
               style={{ width: 220 }}
               text='Visualizar/Alterar'
               onClick={() => updateState(this, (state) => (state.ordemSelecionada = null))}
            />

            {this.state.ordemSelecionada.resultados && this.state.ordemSelecionada.resultados.length ? (
               <Button
                  style={{ width: 220 }}
                  text='Imprimir Relatório'
                  inProgressText='Gerando...'
                  onClickAsync={() =>
                     new Promise((resolve, reject) => {
                        this.props.api
                           .post('/ordemdeservico/baixarrelatorio', { id: this.state.ordemSelecionada.id })
                           .then((data) => {
                              var byteCharacters = atob(data);
                              var byteNumbers = new Array(byteCharacters.length);
                              for (var i = 0; i < byteCharacters.length; i++) {
                                 byteNumbers[i] = byteCharacters.charCodeAt(i);
                              }
                              var byteArray = new Uint8Array(byteNumbers);
                              var file = new Blob([byteArray], { type: 'application/pdf;base64' });

                              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                 // For IE
                                 window.navigator.msSaveOrOpenBlob(file, 'mypdf.pdf');
                              } else {
                                 // For non-IE
                                 var fileURL = URL.createObjectURL(file);
                                 window.open(fileURL);
                              }
                              resolve();
                           })
                           .catch((e) => reject(e));
                     })
                  }
               />
            ) : null}

            {this.state.ordemSelecionada.situacao.id === 2 && (
               <Button
                  style={{ width: 220 }}
                  text='Liberar'
                  inProgressText='Liberando...'
                  onClickAsync={() =>
                     new Promise((resolve, reject) => {
                        if (!this.state.ordemSelecionada.cliente) {
                           showError('Informe o cliente antes de liberar a ordem de serviço.');
                           reject();
                           return;
                        }

                        this.props.api
                           .put('/ordemdeservico/liberar', { id: this.state.ordemSelecionada.id })
                           .then((data) => {
                              showInfo('Ordem de serviço liberada com suceso!');
                              this.atualizar();
                              resolve();
                           })
                           .catch((e) => reject(e));
                     })
                  }
               />
            )}

            {this.state.ordemSelecionada.situacao.id === 3 && (
               <Button
                  style={{ width: 220 }}
                  text='Certificado de Calibração'
                  inProgressText='Gerando...'
                  onClickAsync={this.baixarCertificado}
               />
            )}
         </div>
      );
   };

   baixarCertificado = () => {
      return new Promise((resolve, reject) => {
         this.props.api
            .post('/ordemdeservico/baixarcertificado', { id: this.state.ordemSelecionada.id })
            .then((data) => {
               var byteCharacters = atob(data);
               var byteNumbers = new Array(byteCharacters.length);
               for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
               }
               var byteArray = new Uint8Array(byteNumbers);
               var file = new Blob([byteArray], { type: 'application/pdf;base64' });

               if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  // For IE
                  window.navigator.msSaveOrOpenBlob(file, 'mypdf.pdf');
               } else {
                  // For non-IE
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
               resolve();
            })
            .catch((e) => reject(e));
      });
   };

   resultadosDaOrdemDeServico = () => {
      const telaDeCadastroDeOrdemDeServico = LANG.telaDeCadastroDeOrdemDeServico;
      return (
         <div style={{ padding: 8 }}>
            <SubCadastro
               titulo={telaDeCadastroDeOrdemDeServico.resultadosDeTesteDeOrdemDeServico}
               itens={this.state.ordemSelecionada.resultados}
               lang={this.props.lang}
               podeInserir={false}
               novo={() => {}}
               cancelar={() => {}}
               inserir={() => {}}
               selecionarParaAlteracao={(index) => {}}
               podeExcluir={false}
               colunas={() => {
                  return [
                     { titulo: telaDeCadastroDeOrdemDeServico.data, width: '10%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.padrao, width: '10%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.sequencia, width: '50%', className: null },
                     { titulo: telaDeCadastroDeOrdemDeServico.situacao, width: '30%', className: null },
                  ];
               }}
               linha={(item) => {
                  return [
                     dateToString(item.data, this.props.lang),
                     item.tipoDeEquipamento.sigla,
                     item.sequencia.nome,
                     <div>
                        {item.situacao.descricao}
                        {item.situacao.id === 4 && (
                           <div>
                              <span>{telaDeCadastroDeOrdemDeServico.motivo}:&nbsp;</span>
                              <span>{item.observacao}</span>
                           </div>
                        )}
                     </div>,
                  ];
               }}
               formulario={() => {
                  return <div></div>;
               }}
            />
         </div>
      );
   };

   certificadosDaOrdemDeServico = () => {
      const telaDePainelDeControle = LANG.telaDePainelDeControle;
      return (
         <div style={{ padding: 8 }}>
            {!this.state.ordemSelecionada.certificadoNeos && <span>{'O certificado ainda não disponível.'}</span>}

            {this.state.ordemSelecionada.certificadoNeos && (
               <SubCadastro
                  titulo={telaDePainelDeControle.certificado}
                  itens={[this.state.ordemSelecionada.certificadoNeos]}
                  lang={this.props.lang}
                  podeInserir={false}
                  novo={() => {}}
                  cancelar={() => {}}
                  inserir={() => {}}
                  selecionarParaAlteracao={(index) => {}}
                  podeExcluir={false}
                  colunas={() => {
                     return [
                        { titulo: telaDePainelDeControle.numeroDoCertificado, width: '25%', className: null },
                        { titulo: telaDePainelDeControle.dataDeEmissaoDoCertificado, width: '25%', className: null },
                        { titulo: telaDePainelDeControle.validade, width: '25%', className: null },
                        { titulo: telaDePainelDeControle.pdf, width: '25%', className: 'center' },
                     ];
                  }}
                  linha={(item) => {
                     return [
                        item.numero,
                        dateToString(item.data),
                        dateToString(this.state.ordemSelecionada.dataDeValidadeDoCertificado),
                        <Button
                           style={{ width: 60, margin: 'auto', height: 25 }}
                           text='PDF'
                           inProgressText='...'
                           onClickAsync={this.baixarCertificado}
                        />,
                     ];
                  }}
                  formulario={() => {
                     return <div></div>;
                  }}
               />
            )}
         </div>
      );
   };
}

const StyledNav = styled.div`
   width: 100%;

   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
      background-color: ${LayoutParams.colors.corDoTemaPrincipal};
   }
`;
