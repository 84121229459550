import { Row, Col, FormGroup, Tab, Tabs, Container } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import Label from '../../components/Label';
import { dateTimeToString, numberToString, updateState } from '../../utils/Functions';
import Document from '../../components/Document';
import { LayoutParams } from '../../config/LayoutParams';
import styled from 'styled-components';
import ListaPadraoOffline from '../ListaPadraoOffline';
import IntegerInput from '../../components/IntegerInput';
import Button from '../../components/Button';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';
import IconButton from '../../components/IconButton';
import { Filler } from '../../components/Filler';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { showInfo } from '../../components/Messages';
import DecimalInput from '../../components/DecimalInput';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

let LANG = {};

const TIPO_DE_PARAMETRO = {
   TIN_MANUAL_TEMPERATURA_CENTRAL: 0,
   TIN_MANUAL_TEMPERATURA_A: 1,
   TIN_MANUAL_TEMPERATURA_B: 2,
   TIN_MANUAL_TEMPERATURA_C: 3,
   TIN_MANUAL_TEMPERATURA_D: 4,
   TIN_MANUAL_TEMPERATURA_PELE: 5,
   TIN_MANUAL_TEMPERATURA_AMBIENTE: 6,
   TIN_MANUAL_VELOCIDADE_AR_CENTRAL: 7,
   TIN_MANUAL_VELOCIDADE_AR_A: 8,
   TIN_MANUAL_VELOCIDADE_AR_B: 9,
   TIN_MANUAL_VELOCIDADE_AR_C: 10,
   TIN_MANUAL_VELOCIDADE_AR_D: 11,
   TIN_MANUAL_VELOCIDADE_AR_EXTERNA: 12,
   TIN_MANUAL_PRESSAO_SONORA_INT: 13,
   TIN_MANUAL_PRESSAO_SONORA_EXT: 14,
   TIN_MANUAL_UMIDADE_INT: 15,
   TIN_MANUAL_UMIDADE_AMB: 16,
   TIN_MANUAL_OXIGENIO: 17,
};


const ordemDosTiposDeParametroManual = 
   {
      TIN_MANUAL_TEMPERATURA_CENTRAL: 0,
      TIN_MANUAL_TEMPERATURA_A: 1,
      TIN_MANUAL_TEMPERATURA_B: 2,
      TIN_MANUAL_TEMPERATURA_C: 3,
      TIN_MANUAL_TEMPERATURA_D: 4,
      TIN_MANUAL_VELOCIDADE_AR_CENTRAL: 7,
      TIN_MANUAL_VELOCIDADE_AR_A: 8,
      TIN_MANUAL_VELOCIDADE_AR_B: 9,
      TIN_MANUAL_VELOCIDADE_AR_C: 10,
      TIN_MANUAL_VELOCIDADE_AR_D: 11,
      TIN_MANUAL_TEMPERATURA_PELE: 5,
      TIN_MANUAL_PRESSAO_SONORA_INT: 13,
      TIN_MANUAL_OXIGENIO: 17,
      TIN_MANUAL_UMIDADE_INT: 15,
      TIN_MANUAL_VELOCIDADE_AR_EXTERNA: 12,
      TIN_MANUAL_PRESSAO_SONORA_EXT: 14,
      TIN_MANUAL_TEMPERATURA_AMBIENTE: 6,
      TIN_MANUAL_UMIDADE_AMB: 16,   
   };

export default class ImportacaoDeTesteTinView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         abaSelecionada: 'ensaios',
         filtro: {},
         arquivo: null,
         testes: null,
         testesSelecionados: props.getTestesTIN(),
      };
      LANG = props.lang;
   }

   importarEnsaios = () => {
      return new Promise((resolve, reject) => {
         this.props.api
            .get('/testetin/lerensaiosdobanco?fileId=' + this.state.arquivo.id)
            .then((result) => {
               this.setState(
                  {
                     ensaios: result,
                     ensaioSelecionadoHighlightIndex: null,
                  },
                  () => resolve()
               );
            })
            .catch(() => reject());
      });
   };

   importarTestes = () => {
      if (this.importandoTestes) {
         return new Promise((resolve) => resolve());
      }
      this.importandoTestes = true;
      return new Promise((resolve, reject) => {
         this.props.api
            .get(
               '/testetin/lertestesdobanco?fileId=' +
                  this.state.arquivo.id +
                  '&iddoensaio=' +
                  this.state.ensaioSelecionado.id
            )
            .then((result) => {
               this.setState(
                  {
                     testes: result,
                     testeSelecionadoHighlightIndex: null,
                  },
                  () => resolve()
               );
            })
            .catch(() => reject())
            .finally(() => {
               this.importandoTestes = false;
            });
      });
   };

   render() {
      return (
         <div
            style={{
               margin: 8,
               width: '100%',
               height: '100%',
               display: 'flex',
               flexDirection: 'column',
            }}
         >
            <div
               style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  padding: 5,
               }}
            >
               {!this.state.ensaios && (
                  <Fragment>
                     <div>
                        <Label>{LANG.telaDeImportacaoDeTesteTIN.bancoDeDados}</Label>
                        <Document
                           lang={LANG}
                           api={this.props.api}
                           accept='.db'
                           mimeType='application/vnd.sqlite3'
                           defaultValue={this.state.arquivo}
                           temporary={true}
                           onChange={(file) => {
                              updateState(this, (state) => {
                                 state.arquivo = file;
                              });
                           }}
                        />
                        <br />
                     </div>

                     {this.state.arquivo && (
                        <div>
                           <Row>
                              <Col>
                                 <Button
                                    onClickAsync={this.importarEnsaios}
                                    text={LANG.telaDeImportacaoDeTesteTIN.importar}
                                    inProgressText={LANG.telaDeImportacaoDeTesteTIN.importando}
                                    style={{ width: 200 }}
                                 />
                              </Col>
                           </Row>
                           <br />
                        </div>
                     )}
                  </Fragment>
               )}
               {this.state.ensaios && this.state.ensaios.length > 0 && (
                  <div
                     style={{
                        overflow: 'hidden',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                     }}
                  >
                     <StyledNav>
                        <Tabs
                           variant='tabs'
                           activeKey={this.state.abaSelecionada}
                           onSelect={(k) => this.setState({ abaSelecionada: k })}
                           style={{ color: '#444' }}
                        >
                           <Tab
                              eventKey='ensaios'
                              title={LANG.telaDeImportacaoDeTesteTIN.ensaios}
                              style={{
                                 height: '100%',
                              }}
                           >
                              {this.ensaios()}
                           </Tab>
                           {this.state.ensaioSelecionado && (
                              <Tab
                                 eventKey='testesDoEnsaio'
                                 title={LANG.telaDeImportacaoDeTesteTIN.testes}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.testesDoEnsaio()}
                              </Tab>
                           )}

                           {this.state.ensaioSelecionado && this.state.testeSelecionado && (
                              <Tab
                                 eventKey='entradaDeDados'
                                 title={LANG.telaDeImportacaoDeTesteTIN.dadosDoTeste}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.dadosDoTeste()}
                              </Tab>
                           )}

                           {this.state.testesSelecionados && this.state.testesSelecionados.length > 0 && (
                              <Tab
                                 eventKey='testesSelecionados'
                                 title={LANG.telaDeImportacaoDeTesteTIN.testesSelecionados}
                                 style={{
                                    height: '100%',
                                 }}
                              >
                                 {this.testesSelecionados()}
                              </Tab>
                           )}
                        </Tabs>
                     </StyledNav>
                  </div>
               )}
            </div>
         </div>
      );
   }

   ensaios = () => {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'hidden',
               }}
            >
               <ListaPadraoOffline
                  esconderTitulo={true}
                  esconderBotaoFechar={true}
                  api={this.props.api}
                  lang={LANG}
                  getItens={() => {
                     let result = this.state.ensaios;

                     if (this.state.filtro) {
                        if (this.state.filtro.numeroDoEnsaio) {
                           result = result.filter((i) => i.id === this.state.filtro.numeroDoEnsaio);
                        }
                     }
                     updateState(this, (state) => {
                        state.ensaioSelecionado = null;
                        state.ensaioSelecionadoHighlightIndex = null;
                        state.testeSelecionado = null;
                        state.testeSelecionadoHighlightIndex = null;
                     });

                     return result;
                  }}
                  getTitulosDaTabela={() => {
                     return [
                        { titulo: LANG.codigo, orderby: 'Id', className: 'codigo' },
                        {
                           titulo: LANG.telaDeImportacaoDeTesteTIN.descricao,
                           width: '100%',
                        },
                        { titulo: LANG.telaDeImportacaoDeTesteTIN.data, width: '100%' },
                     ];
                  }}
                  getDadosDaTabela={(item) => {
                     return [item.id, item.descricao, dateTimeToString(item.dataHora)];
                  }}
                  getFormState={() => this.state}
                  estruturaPadrao={true}
                  setFormState={(state, callback) => {
                     return new Promise((resolve) => {
                        this.setState(state, () => {
                           resolve();
                           if (callback) {
                              callback();
                           }
                        });
                     });
                  }}
                  onRowClick={(item, index) => {
                     updateState(this, (state) => {
                        state.testes = null;
                        state.ensaioSelecionado = item && item === state.ensaioSelecionado ? null : item;
                        state.ensaioSelecionadoHighlightIndex = state.ensaioSelecionado ? index : null;
                     });
                  }}
                  highlightIndex={this.state.ensaioSelecionadoHighlightIndex}
                  renderizarFiltros={() => {
                     return (
                        <div>
                           <Row>
                              <Col sm={4} xs={4} md={4}>
                                 <FormGroup>
                                    <Label>{LANG.telaDeImportacaoDeTesteTIN.numeroDoEnsaio}</Label>
                                    <IntegerInput
                                       defaultValue={this.state.filtro.numeroDoEnsaio}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.filtro.numeroDoEnsaio = e.floatValue;
                                          });
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        </div>
                     );
                  }}
               />
            </div>
         </Fragment>
      );
   };

   testesDoEnsaio = () => {
      if (this.state.testes == null) {
         this.importarTestes();
      }

      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'hidden',
               }}
            >
               {this.state.testes && (
                  <Fragment>
                     <ListaPadraoOffline
                        esconderTitulo={true}
                        esconderBotaoFechar={true}
                        esconderConsulta={true}
                        api={this.props.api}
                        lang={LANG}
                        getItens={() => {
                           let result = this.state.testes;
                           return result;
                        }}
                        getTitulosDaTabela={() => {
                           return [
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.ensaio,
                                 className: 'codigo',
                              },
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.teste,
                                 width: '10%',
                              },
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.tipoDeTeste,
                                 width: '60%',
                              },
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.data,
                                 width: '30%',
                              },
                           ];
                        }}
                        getDadosDaTabela={(item) => {
                           return [item.numeroEnsaio, item.numeroTeste, item.descTipoTeste, item.dataHora];
                        }}
                        getFormState={() => this.state}
                        setFormState={(state, callback) => {
                           return new Promise((resolve) => {
                              this.setState(state, () => {
                                 resolve();
                                 if (callback) {
                                    callback();
                                 }
                              });
                           });
                        }}
                        onRowClick={(item, index) => {
                           updateState(this, (state) => {
                              state.testeSelecionado = null;
                              state.testeSelecionadoHighlightIndex = null;
                           }).then(() => {
                              updateState(this, (state) => {
                                 state.testeSelecionado = item && item === state.testeSelecionado ? null : item;
                                 state.testeSelecionadoHighlightIndex = state.testeSelecionado ? index : null;

                                 if (state.testeSelecionado) {
                                 }
                              });
                           });
                        }}
                        highlightIndex={this.state.testeSelecionadoHighlightIndex}
                        getFiltro={() => {}}
                        renderizarFiltros={() => {
                           return <div></div>;
                        }}
                     />
                  </Fragment>
               )}
            </div>
         </Fragment>
      );
   };

   dadosDoTeste = () => {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'auto',
                  flexDirection: 'column',
               }}
            >
               {this.state.testeSelecionado && (
                  <Fragment>
                     {this.state.testeSelecionado.tipoDeTeste === 0 && (
                        <StyledParametros style={{ paddingRight: 10 }}>
                           <ListaPadraoOffline
                              esconderTitulo={true}
                              esconderBotaoFechar={true}
                              esconderConsulta={true}
                              api={this.props.api}
                              lang={LANG}
                              getItens={this.getDadosDoTesteManualSelecionado}
                              getTitulosDaTabela={() => {
                                 return [
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.parametro,
                                       width: '30%',
                                    },
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.valor,
                                       width: '10%',
                                    },
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.referencia,
                                       width: '14%',
                                    },
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.desvioMaximoSuperior,
                                       width: '13%',
                                    },
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.desvioMaximoInferior,
                                       width: '13%',
                                    },
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.unidadeDeDesvio,
                                       width: '25%',
                                    },
                                 ];
                              }}
                              getDadosDaTabela={(item, index) => {
                                 return [
                                    item.parametro,
                                    <div>
                                       <TextInput
                                          defaultValue={numberToString(item.resultado, null, 3, this.props.lang)}
                                          readOnly={true}
                                       />
                                    </div>,
                                    <div>
                                       <DecimalInput
                                          defaultValue={item.referencia}
                                          onChange={(e) => (this.parametrosInformados[index].referencia = e.floatValue)}
                                       />
                                    </div>,
                                    <div>
                                       <DecimalInput
                                          defaultValue={item.limiteSuperior}
                                          onChange={(e) =>
                                             (this.parametrosInformados[index].limiteSuperior = e.floatValue)
                                          }
                                       />
                                    </div>,
                                    <div>
                                       <DecimalInput
                                          defaultValue={item.limiteInferior}
                                          onChange={(e) =>
                                             (this.parametrosInformados[index].limiteInferior = e.floatValue)
                                          }
                                       />
                                    </div>,
                                    <div>
                                       <Select
                                          defaultValue={item.unidadeLimites}
                                          options={item.comboUnidades}
                                          getKeyValue={(i) => i.id}
                                          getDescription={(i) => i.descricao}
                                          allowEmpty={false}
                                          onSelect={(i) =>
                                             (this.parametrosInformados[index].unidadeLimites = i ? i.id : null)
                                          }
                                       />
                                    </div>,
                                 ];
                              }}
                           />
                        </StyledParametros>
                     )}

                     {this.state.testeSelecionado.tipoDeTeste !== 0 && (
                        <StyledParametros style={{ paddingRight: 10 }}>
                           <ListaPadraoOffline
                              esconderTitulo={true}
                              esconderBotaoFechar={true}
                              esconderConsulta={true}
                              api={this.props.api}
                              lang={LANG}
                              getItens={this.getDadosDoTesteSelecionado}
                              getTitulosDaTabela={() => {
                                 return [
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.parametro,
                                       width: '80%',
                                    },
                                    {
                                       titulo: LANG.telaDeImportacaoDeTesteTIN.valor,
                                       width: '20%',
                                    },
                                 ];
                              }}
                              getDadosDaTabela={(item) => {
                                 return [item.parametro, numberToString(item.resultado, null, 3, this.props.lang)];
                              }}
                           />
                        </StyledParametros>
                     )}

                     <br />

                     <Container fluid>
                        <Button
                           onClick={() => {
                              updateState(this, (state) => {
                                 if (!state.testesSelecionados) {
                                    state.testesSelecionados = [];
                                 }

                                 let item = this.state.testeSelecionado;
                                 item.status = 1;

                                 if (this.state.testeSelecionado.tipoDeTeste === 0) {
                                    item.dadosDeTesteManual.parametrosInformados = this.parametrosInformados;
                                 }

                                 state.testesSelecionados.push(item);
                                 this.props.setTestesTIN(state.testesSelecionados);
                              }).then(() => {
                                 showInfo(LANG.telaDeImportacaoDeTesteTIN.testeAdicionadoComSucesso).then(() => {
                                    updateState(this, (state) => {
                                       state.ensaioSelecionado = null;
                                       state.ensaioSelecionadoHighlightIndex = null;
                                       state.testeSelecionado = null;
                                       state.testeSelecionadoHighlightIndex = null;
                                       state.abaSelecionada = 'testesSelecionados';
                                    });
                                 });
                              });
                           }}
                           text={LANG.telaDeImportacaoDeTesteAQT.adicionarTeste}
                           style={{ width: 200 }}
                        />
                        <Filler height={15} />
                     </Container>
                  </Fragment>
               )}
            </div>
         </Fragment>
      );
   };

   getDadosDoTesteSelecionado = () => {
      let result = [];

      let teste = this.state.testeSelecionado;

      if (teste && teste.parametros) {
         let parametros = teste.parametros.parametro.split('\n');
         let resultados = teste.parametros.resultado.split('\n');

         let i = 0;
         for (i = 0; i < parametros.length; i++) {
            let resultado = Number(resultados[i].replace(',', '.'));
            result.push({
               parametro: parametros[i],
               resultado: resultado,
            });
         }
      }
      return result;
   };

   getDadosDoTesteManualSelecionado = () => {
      let result = [];

      let teste = this.state.testeSelecionado;
   
      
      if (teste && teste.parametros) {
         let parametros = teste.parametros.parametro.split('\n');
         let resultados = teste.parametros.resultado.split('\n');
         let tiposDeParametro = Object.keys(ordemDosTiposDeParametroManual).map((p) => ordemDosTiposDeParametroManual[p]);
        
         let i = 0;
         for (i = 0; i < parametros.length; i++) {
            let tipoDeParametro = i;
            let resultado = Number(resultados[i].replace(',', '.'));
            let item = {
               tipoDeParametro: tiposDeParametro[i],
               parametro: parametros[i],
               resultado: resultado,
               referencia: 0,
               limiteSuperior: this.getLimiteSuperiorDefault(tipoDeParametro),
               limiteInferior: this.getLimiteInferiorDefault(tipoDeParametro),
               unidadeLimites: 0,
               comboUnidades: this.getComboUnidades(tipoDeParametro),
            };
            result.push(item); 
         }
      }

      this.parametrosInformados = result;

      return result;
   };

   getComboUnidades = (tipoDeParametro) => {
      let result = [];

      switch (tipoDeParametro) {
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_CENTRAL:
            result.push({ id: 0, descricao: 'ºC - Celsius' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_A:
            result.push({ id: 0, descricao: 'ºC - Celsius' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_B:
            result.push({ id: 0, descricao: 'ºC - Celsius' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_C:
            result.push({ id: 0, descricao: 'ºC - Celsius' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_D:
            result.push({ id: 0, descricao: 'ºC - Celsius' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_PELE:
            result.push({ id: 0, descricao: 'ºC - Celsius' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_AMBIENTE:
            result.push({ id: 0, descricao: 'ºC - Celsius' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_CENTRAL:
            result.push({ id: 0, descricao: 'm/s - Metro/seg' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_A:
            result.push({ id: 0, descricao: 'm/s - Metro/seg' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_B:
            result.push({ id: 0, descricao: 'm/s - Metro/seg' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_C:
            result.push({ id: 0, descricao: 'm/s - Metro/seg' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_D:
            result.push({ id: 0, descricao: 'm/s - Metro/seg' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_EXTERNA:
            result.push({ id: 0, descricao: 'm/s - Metro/seg' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_PRESSAO_SONORA_INT:
            result.push({ id: 0, descricao: 'dB - Decibel' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_PRESSAO_SONORA_EXT:
            result.push({ id: 0, descricao: 'dB - Decibel' }, { id: 1, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_UMIDADE_INT:
            result.push({ id: 0, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_UMIDADE_AMB:
            result.push({ id: 0, descricao: '% - Porcentagem' });
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_OXIGENIO:
            result.push({ id: 0, descricao: '% - Porcentagem' });
            break;
         default:
            break;
      }

      return result;
   };

   getLimiteSuperiorDefault = (tipoDeParametro) => {
      let result = 0;

      switch (tipoDeParametro) {
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_CENTRAL:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_A:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_B:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_C:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_D:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_PELE:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_AMBIENTE:
            result = 0.5;
            break;

         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_CENTRAL:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_A:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_B:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_C:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_D:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_EXTERNA:
            result = 0.35;
            break;

         case TIPO_DE_PARAMETRO.TIN_MANUAL_PRESSAO_SONORA_INT:
            result = 80;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_PRESSAO_SONORA_EXT:
            result = 80;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_UMIDADE_INT:
            result = 80;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_UMIDADE_AMB:
            result = 80;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_OXIGENIO:
            result = 100;
            break;
         default:
            break;
      }

      return result;
   };

   getLimiteInferiorDefault = (tipoDeParametro) => {
      let result = 0;

      switch (tipoDeParametro) {
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_CENTRAL:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_A:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_B:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_C:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_D:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_PELE:
            result = 0.5;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_TEMPERATURA_AMBIENTE:
            result = 0.5;
            break;

         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_CENTRAL:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_A:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_B:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_C:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_D:
            result = 0.35;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_VELOCIDADE_AR_EXTERNA:
            result = 0.35;
            break;

         case TIPO_DE_PARAMETRO.TIN_MANUAL_PRESSAO_SONORA_INT:
            result = 65;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_PRESSAO_SONORA_EXT:
            result = 65;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_UMIDADE_INT:
            result = 40;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_UMIDADE_AMB:
            result = 40;
            break;
         case TIPO_DE_PARAMETRO.TIN_MANUAL_OXIGENIO:
            result = 0;
            break;
         default:
            break;
      }

      return result;
   };

   testesSelecionados = () => {
      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  overflowY: 'hidden',
                  flexDirection: 'column',
               }}
            >
               {this.state.testesSelecionados && (
                  <Fragment>
                     <ListaPadraoOffline
                        esconderTitulo={true}
                        esconderBotaoFechar={true}
                        esconderConsulta={true}
                        api={this.props.api}
                        lang={LANG}
                        getItens={() => {
                           let result = this.state.testesSelecionados;
                           return result;
                        }}
                        getTitulosDaTabela={() => {
                           return [
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.ensaio,
                                 className: 'codigo',
                              },
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.teste,
                                 width: '10%',
                              },
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.tipoDeTeste,
                                 width: '50%',
                              },
                              {
                                 titulo: LANG.telaDeImportacaoDeTesteTIN.data,
                                 width: '30%',
                              },
                              { titulo: '', width: '5%' },
                           ];
                        }}
                        getDadosDaTabela={(item, index) => {
                           return [
                              item.numeroEnsaio,
                              item.numeroTeste,
                              item.descTipoTeste,
                              item.dataHora,
                              <div style={{ display: 'flex' }}>
                                 <IconButton
                                    icon={faTrashAlt}
                                    style={{ margin: 'auto', fontSize: 18 }}
                                    title={LANG.excluir}
                                    onClick={() => {
                                       updateState(this, (state) => {
                                          state.testesSelecionados.splice(index, 1);
                                          if (state.testesSelecionados.length === 0) {
                                             state.abaSelecionada = 'ensaios';
                                          }
                                          this.props.setTestesTIN(state.testesSelecionados);
                                       });
                                    }}
                                 />
                              </div>,
                           ];
                        }}
                     />
                  </Fragment>
               )}
            </div>
         </Fragment>
      );
   };
}

const StyledNav = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;

   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
      background-color: ${LayoutParams.colors.corDoTemaPrincipal};
   }

   .tab-content {
      overflow-y: hidden;
   }

   .tab-pane.active {
      display: flex;
   }
`;

const StyledParametros = styled.div`
   .div-tabela-formulario-padrao {
      overflow-y: hidden;
   }

   .div-cabecalho-tabela-formulario-padrao {
      overflow-y: hidden;
   }
`;
